


















import ConfirmSidePanelFooterButtonsFrame from '../atoms/ConfirmSidePanelFooterButtonsFrame.vue'
import SignInButton from '@/components/molecules/SignInButton.vue'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

type OnlySignInButtonState = {
  type: 'only_signin_button'
}
type OnlyConfirmButtonState = {
  type: 'only_confirm_button'
  confirmationDisabled: boolean
  onConfirmClick: () => void
}
type AllButtonsState = {
  type: 'all'
  confirmationDisabled: boolean
  onConfirmClick: () => void
}
export type FooterButtonsState = OnlyConfirmButtonState | OnlySignInButtonState | AllButtonsState

export default defineComponent({
  components: {
    ConfirmSidePanelFooterButtonsFrame,
    SignInButton
  },
  props: {
    buttonState: {
      type: Object as PropType<FooterButtonsState>,
      required: true
    }
  },
  setup(props) {
    const { buttonState } = toRefs(props)
    const signInButtonState = computed(() => {
      const type = buttonState.value.type
      return { isShowed: type === 'only_signin_button' || type === 'all' }
    })
    const confirmButtonState = computed(() => {
      if (buttonState.value.type === 'only_confirm_button' || buttonState.value.type === 'all') {
        const state: OnlyConfirmButtonState | AllButtonsState = buttonState.value
        return {
          isShowed: true,
          confirmationDisabled: state.confirmationDisabled,
          handleConfirmClick: () => state.onConfirmClick()
        }
      } else {
        return {
          isShowed: false
        }
      }
    })
    return {
      signInButtonState,
      confirmButtonState
    }
  }
})
