





















import DateTimeInput from '@/components/molecules/DateTimeInput.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { addHours, addMinutes, startOfHour } from 'date-fns'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DateTimeInput,
    FormItemSpacer
  }
})
export default class DateTimeForm extends Vue {
  @PropSync('startDate', { type: Date }) startDateSynced!: Date
  @PropSync('endDate', { type: Date }) endDateSynced!: Date
  @Prop({ default: null }) minEndDate: Date
  @Prop({ default: null }) splitEventsByDuration: number
  @Prop({ default: false }) allDay: boolean
  @Prop({ default: false }) disabledPast: boolean
  @Prop() minInterval

  created() {
    if (!this.startDateSynced) {
      this.startDateSynced = this.defaultDate.start
      this.endDateSynced = this.defaultDate.end
    }
  }
  get defaultDate() {
    const start = startOfHour(addHours(new Date(), 1))
    const end = this.splitEventsByDuration
      ? addMinutes(start, this.splitEventsByDuration)
      : addHours(new Date(start), 1)
    return {
      start,
      end
    }
  }

  get makePastDisabld() {
    if (!this.disabledPast) {
      return null
    }
    return new Date()
  }
}
