
















import { defineComponent, PropType } from '@vue/composition-api'
import Avatar from 'vue-avatar'
import { MemberCalendarType } from '../types'

export default defineComponent({
  name: 'MemberItem',
  components: {
    Avatar
  },
  props: {
    item: {
      type: Object as PropType<MemberCalendarType>,
      required: true
    }
  },
  setup(props) {
    return {
      photoUrl: props.item.photoUrl,
      name: props.item.fullName,
      backgroundColor: props.item.backgroundColor
    }
  }
})
