import { PollModel } from '@/models/data/poll'
import CalendarControlModule from '@/store/modules/calendarControl'
import EditPollModule from '@/store/modules/editPoll'
import { computed, ref, watch } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { useDeviceInfo } from './useDeviceInfo'
import { useTranslation } from './useTranslation'

export const usePollMixin = () => {
  const route = useRoute()
  const i18n = useTranslation()
  const id = computed(() => route.params.id)
  const { isNarrow } = useDeviceInfo()

  const firstDate = ref(new Date())
  function setCurrentDateToFirstCandidate() {
    const firstCandidate = EditPollModule.getEditingEventByCalendarFormat[0]
    if (!firstCandidate) {
      return
    }
    firstDate.value = firstCandidate.start
    CalendarControlModule.setCurrentDate(firstCandidate.start)
  }

  function setEditingPoll() {
    setCurrentDateToFirstCandidate()
  }

  const editingPoll = computed((): PollModel => {
    return EditPollModule.editingPoll
  })
  function previewEditingPoll(): PollModel {
    const newModel = new PollModel({ ...EditPollModule.editingPoll })
    newModel.title = newModel.title || i18n.t('labels.notInputted').toString()
    return newModel
  }
  const pollId = computed(() => {
    return route.params.id
  })
  const isVotePage = computed(() => {
    return route.name === 'VotePoll'
  })

  watch(id, () => {
    setEditingPoll()
  })
  return {
    firstDate,
    isVotePage,
    isNarrow,
    editingPoll,
    previewEditingPoll,
    pollId,
    setEditingPoll
  }
}
