import { searchAddress } from '@/lib/api/util'
import { LocationResult } from './location'

export const useLocationSearch = () => {
  async function searchLocation(location, searchKey): Promise<{ searchKey?: string; results: LocationResult[] }> {
    if (!location || !location.length) {
      return { results: [] }
    }
    const response = await searchAddress(location, searchKey)
    if (response.status === 'ok') {
      return {
        searchKey: response.search_key,
        results: response.predictions
      }
    }
  }

  function openGoogleMaps(location) {
    if (!location) {
      return
    }
    window.open(`https://www.google.com/maps?q=${location}`, '_blank')
  }

  return {
    searchLocation,
    openGoogleMaps
  }
}
