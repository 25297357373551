























import { Component, Prop, Vue } from 'vue-property-decorator'
import ScheduleTab from './ScheduleTab.vue'
import ConfirmedScheduleList from '../scheduleList/ConfirmedScheduleList.vue'
import UnconfirmedScheduleList from '../scheduleList/UnconfirmedScheduleList.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import TeamUnconfirmedScheduleItem from '@/components/schedule/list/scheduleList/item/TeamUnconfirmedScheduleItem.vue'
import TeamConfirmedScheduleDateItems from '../scheduleList/item/TeamConfirmedScheduleDateItems.vue'
import { SignalType } from '@/lib/analytics'
import ConfirmDeleteModal from '@/components/modal/ConfirmDelete.vue'
import ConfirmedScheduleEventModule from '@/store/modules/confirmedScheduleEvent'

@Component({
  components: {
    ScheduleTab,
    ConfirmedScheduleList,
    UnconfirmedScheduleList,
    LoadingSpinner,
    TeamUnconfirmedScheduleItem,
    TeamConfirmedScheduleDateItems
  }
})
export default class TeamSchedulePanel extends Vue {
  @Prop() teamId: string

  visibleCalendars: string[] = []

  get teamScheduleListInfo() {
    return TeamScheduleListModule.scheduleListByTeamId(this.teamId)
  }
  get unconfirmedSchedules() {
    return this.teamScheduleListInfo?.models || []
  }
  get isUnconfirmedListLoading() {
    return this.teamScheduleListInfo?.isLoading
  }
  get teamConfirmedScheduleListInfo() {
    return TeamScheduleListModule.confirmedScheduleListByTeamId(this.teamId)
  }
  get confirmedSchedules() {
    return this.teamConfirmedScheduleListInfo?.models || []
  }
  get isConfirmedListLoading() {
    return this.teamConfirmedScheduleListInfo?.isLoading || ConfirmedScheduleEventModule.isLoading
  }

  get unconfirmedSchedulesCount() {
    return this.unconfirmedSchedules.length
  }
  async handleDeleteSchedule(payload: { teamId: string; scheduleId: string }) {
    const { teamId, scheduleId } = payload
    await this.$analytics.send(SignalType.CLICK_DELETE_ON_LIST, { type: 'schedule' })
    const customTitle = this.$t('message.confirmDeleteWholeTitle', {
      type: this.$t(`labels.schedule`)
    }).toString()
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDeleteModal,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props: {
        customTitle
      },
      events: {
        confirm: async () => {
          try {
            await TeamScheduleListModule.deleteSchedule({ teamId, scheduleId })
            this.$buefy.toast.open({
              type: 'is-primary',
              position: 'is-top',
              message: this.$t('message.success.deleted', {
                type: this.$t(`labels.schedule`).toString()
              }).toString()
            })
          } catch (e) {
            this.$buefy.toast.open({
              type: 'is-primary',
              position: 'is-top',
              message: this.$t('message.errorCommon').toString()
            })
          }
        }
      }
    })
  }
}
