











import { Component, Prop, Vue } from 'vue-property-decorator'
import DatePicker from '@/components/ui/spir/SpirDatePicker.vue'

@Component({
  components: {
    DatePicker
  }
})
export default class DatepickerButton extends Vue {
  $refs: {
    datePicker: any
  }
  @Prop() eventDate
  @Prop() headerDateFormat

  changeCurrentDate(selectedDate) {
    this.$emit('setCurrentDate', new Date(selectedDate))
  }
}
