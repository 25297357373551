












import { defineComponent } from '@vue/composition-api'
import AuthButtonsFrame from '@/components/auth/AuthButtonsFrame.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { useSendSignal } from '@/composables/useSendSignal'

export default defineComponent({
  props: {
    onGoogleSelected: {
      type: Function,
      required: true
    },
    signUpWithMicrosoft: {
      type: Function,
      required: true
    }
  },
  components: {
    AuthButtonsFrame,
    AuthFormSpacer,
    IconButton
  },
  setup() {
    const { sendSignal } = useSendSignal()
    const sendSignUpSignal = (type: 'microsoft' | 'google') => {
      return sendSignal(SignalType.CLICK_SIGNUP_TOP, { type })
    }
    return {
      sendSignUpSignal
    }
  }
})
