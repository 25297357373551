























import Divider from '@/components/atoms/Divider.vue'
import CopyUrlAndHtml from '@/components/organisms/CopyUrlAndHtml.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SpirModalFrame from '@/components/ui/spir/SpirModal/SpirModalFrame.vue'
import { PollModel, ScheduleModelCommon } from '@/models/data'
import { ListType } from '@/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'ScheduleDetailModal',
  components: {
    CopyUrlAndHtml,
    Divider,
    ClosingPageheadTitleBox,
    SpirModalFrame,
    ListItemSpacer
  },
  props: {
    schedule: {
      type: Object as PropType<ScheduleModelCommon | PollModel>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { schedule } = toRefs(props)
    const type = computed(() => {
      return schedule.value.type
    })
    const canHtmlCopy = computed(() => {
      return schedule.value.type === ListType.SCHEDULE
    })
    const activeCandidates = computed(() => {
      if (schedule.value instanceof ScheduleModelCommon) {
        return schedule.value.activeCandidates
      }
      return []
    })
    const urlForConfirm = computed(() => {
      return schedule.value.urlForConfirmer
    })
    function handleClose() {
      emit('close')
    }
    return {
      type,
      canHtmlCopy,
      activeCandidates,
      urlForConfirm,
      handleClose
    }
  }
})
