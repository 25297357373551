







import { formatEventTimes } from '@/lib/utils/date'
import { validateCandidate } from '@/lib/utils'
import { FullCalendarEvent } from '@/types'
import ConfirmText from './atoms/ConfirmText.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmCandidateSelectButton, { NormalCandidateSelectButtonInfo } from './atoms/ConfirmCandidateSelectButton.vue'

@Component({
  components: {
    ConfirmText,
    ConfirmCandidateSelectButton
  }
})
export default class ConfirmCandidateSelectRow extends Vue {
  @Prop({ required: true }) event: FullCalendarEvent
  @Prop({ default: false }) disabled: boolean
  @Prop() onEventClick: (event: FullCalendarEvent) => Promise<void>
  @Prop() onMouseHover: (event: FullCalendarEvent) => Promise<void>

  isCandidateValid = true
  timeRange = ''

  mounted() {
    this.isCandidateValid = validateCandidate(this.event)
    this.timeRange = formatEventTimes(this.event)
  }

  get info(): NormalCandidateSelectButtonInfo {
    return {
      type: 'normal',
      disabled: this.disabled,
      onClick: this.handleEventClick,
      onHover: this.handleMouseHover
    }
  }
  async handleEventClick() {
    if (!validateCandidate(this.event)) return
    await this.onEventClick(this.event)
  }

  async handleMouseHover() {
    if (!validateCandidate(this.event)) return
    await this.onMouseHover(this.event)
  }
}
