



































import { computed, defineComponent } from '@vue/composition-api'
import { debounce } from 'lodash'
import { ValidationObserver } from 'vee-validate'
import BasicInfo from './panels/BasicInfo.vue'
import Condition from './panels/Condition.vue'
import OrganizerAndAttendee from './panels/OrganizerAndAttendee.vue'
import AvatarWithName from '@/components/patterns/molecules/AvatarWithName.vue'
import RequiredSection from './panels/Required.vue'
import MemberPickers from './panels/MemberPickers.vue'
import ConfirmationForm from './panels/ConfirmationForm.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import CalendarsModule from '@/store/modules/calendars'
import CalendarControlModule from '@/store/modules/calendarControl'
import { AvailabilityModelForTeam } from '@/models/data'

export default defineComponent({
  components: {
    ValidationObserver,
    RequiredSection,
    BasicInfo,
    Condition,
    OrganizerAndAttendee,
    AvatarWithName,
    MemberPickers,
    ConfirmationForm
  },
  setup() {
    const { editingObject, editingObjectType, isLoading, updateEditingObject, updateCondition } =
      useAvailabilitySharingForm()

    const accountWithcalendars = computed(() => CalendarsModule.getAccountWithcalendars)
    const allCalendarsButNotResource = computed(() => CalendarsModule.allCalendarsButNotResource)
    const startWeekDay = computed(() => CalendarControlModule.startWeek)
    const hasConfirmationForm = computed(() => {
      if (editingObject.value instanceof AvailabilityModelForTeam) {
        return editingObject.value.confirmationForm?.properties
      } else {
        return undefined
      }
    })

    const updateEditingObjectDebounce = debounce(updateEditingObject, 300)

    return {
      accountWithcalendars,
      allCalendarsButNotResource,
      startWeekDay,
      hasConfirmationForm,

      editingObject,
      editingObjectType,
      isLoading,

      updateEditingObjectDebounce,
      updateCondition
    }
  }
})
