
































import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'

@Component({
  components: {
    Avatar
  }
})
export default class MemberItem extends Vue {
  @Prop() name: string
  @Prop() photoUrl?: string
  @Prop() helper?: { icon: string; message: string; type: 'has-text-danger' | 'has-text-info' }
  @Prop() showDotsButton: boolean
  @Prop() disableDotsButton: boolean
}
