























import { Component, Vue, Prop } from 'vue-property-decorator'
import AttendeeInvitationButton from './AttendeeInvitationButton.vue'

type Item = {
  email: string
  name: string
}

@Component({
  components: {
    AttendeeInvitationButton
  }
})
export default class AttendeerItem extends Vue {
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false
  items: Item[] = []

  get getIsDropdownActive() {
    return this.isDropdownActive
  }
  get getItems() {
    return this.items
  }

  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm(item: Item) {
    this.items = [...this.items, item]
    this.activeChanged(false)
    this.$emit('confirm', item)
  }
  handleDelete(index: number) {
    this.items = [...this.items.slice(0, index), ...this.items.slice(index + 1, this.items.length)]
    this.activeChanged(false)
    this.$emit('delete', index)
  }
}
