









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormSubItemInItem extends Vue {
  @Prop() label: string
}
