






























import { Component, Vue, Watch } from 'vue-property-decorator'
import ScheduleTab from './ScheduleTab.vue'
import ConfirmedScheduleList from '../scheduleList/ConfirmedScheduleList.vue'
import UnconfirmedScheduleList from '../scheduleList/UnconfirmedScheduleList.vue'
import CalendarFilter from './CalendarFilter.vue'
import CalendarsModule from '@/store/modules/calendars'
import ListPanelModule, { ListType } from '@/store/modules/listPanel'
import { SignalType } from '@/lib/analytics'
import ConfirmDeleteModal from '@/components/modal/ConfirmDelete.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import PrivateUnconfirmedScheduleItem from '../scheduleList/item/PrivateUnconfirmedScheduleItem.vue'
import PrivateConfirmedScheduleDateItems from '../scheduleList/item/PrivateConfirmedScheduleDateItems.vue'

@Component({
  components: {
    ScheduleTab,
    ConfirmedScheduleList,
    UnconfirmedScheduleList,
    CalendarFilter,
    LoadingSpinner,
    PrivateUnconfirmedScheduleItem,
    PrivateConfirmedScheduleDateItems
  }
})
export default class PrivateSchedulePanel extends Vue {
  visibleCalendars: string[] = []

  get writableCalendars() {
    return CalendarsModule.writableCalendars
  }

  get unconfirmedSchedules() {
    return ListPanelModule.listForListPanel.filter((model) => this.visibleCalendars.indexOf(model.calendarKey) >= 0)
  }

  get confirmedSchedules() {
    return ListPanelModule.confirmedList.filter((model) => this.visibleCalendars.indexOf(model.calendarKey) >= 0)
  }

  get isUnconfirmedListLoading() {
    return ListPanelModule.isLoading
  }

  get isConfirmedListLoading() {
    return ListPanelModule.isConfirmListLoading
  }

  get unconfirmedSchedulesCount() {
    return this.unconfirmedSchedules.length
  }

  mounted() {
    ListPanelModule.fetchPastList()
    this.setVisibleCalendars()
  }

  // Writableカレンダーが更新された時（アカウントが追加された時
  @Watch('writableCalendars')
  updateVisibleCalendars() {
    this.setVisibleCalendars()
  }
  handleCalendarToggle(calendarKey: string) {
    this.visibleCalendars = this.visibleCalendars.filter((vc) => vc !== calendarKey)
  }
  setVisibleCalendars() {
    this.visibleCalendars = this.writableCalendars.map((c) => c.key)
  }

  async handleDeleteSchedule(data: { id: string; type: ListType }) {
    await this.$analytics.send(SignalType.CLICK_DELETE_ON_LIST, { type: data.type })
    const customTitle = this.$t('message.confirmDeleteWholeTitle', {
      type: this.$t(`labels.${data.type}`)
    }).toString()
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDeleteModal,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props: {
        customTitle
      },
      events: {
        confirm: async () => {
          try {
            await ListPanelModule.deleteSchedule(data.id)
            this.$buefy.toast.open({
              type: 'is-primary',
              position: 'is-top',
              message: this.$t('message.success.deleted', {
                type: this.$t(`labels.${data.type}`).toString()
              }).toString()
            })
          } catch (e) {
            this.$buefy.toast.open({
              type: 'is-primary',
              position: 'is-top',
              message: this.$t('message.errorCommon').toString()
            })
          }
        }
      }
    })
  }
}
