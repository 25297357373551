









import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<'google' | 'microsoft' | 'mail'>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
