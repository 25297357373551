
















import { defineComponent, computed } from '@vue/composition-api'
import AuthButtonsFrame from '@/components/auth/AuthButtonsFrame.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { useRouter, useRoute } from 'vue2-helpers/vue-router'
import { useAnalytics } from '@/composables/useAnalytics'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { isEnabled } from 'vue-feature-flipping'
import { TogglableFeatures } from '@/types/feature'

export default defineComponent({
  props: {
    signInWithGoogle: {
      type: Function,
      required: true
    },
    signInWithMicrosoft: {
      type: Function,
      required: true
    }
  },
  components: {
    AuthButtonsFrame,
    AuthFormSpacer,
    IconButton,
    LoadingSpinner
  },
  setup() {
    const analytics = useAnalytics()
    const router = useRouter()
    const route = useRoute()
    const sendSignal = async (type: 'microsoft' | 'google') => {
      await analytics.send(SignalType.CLICK_SIGNIN, { type })
    }
    const toSignInWithPassword = () => {
      const redirect = (route.query.redirect || '/') as string
      router.push({ name: 'SignInWithPassword', query: { redirect } })
    }
    return {
      sendSignal,
      toSignInWithPassword,
      isEmailLoginEnabled: computed(
        () => process.env.VUE_APP_MODE === 'development' || isEnabled(TogglableFeatures.accountLink.featureName)
      )
    }
  }
})
