export type MenuInfoKey = 'arrangements' | 'availabilitySharing' | 'event'
export type MenuInfo = {
  key: MenuInfoKey
  type: 'schedule' | 'event'
  targetLinkName: string
  svg: string
  label: string
  tip?: {
    line1: string
    line2: string
  }
  hideOnMobile?: boolean
  hideOnCalendar?: boolean
  disabledInPast?: boolean
  onMenuClick?: (key: MenuInfoKey) => void
}

export const menus: MenuInfo[] = [
  {
    key: 'arrangements',
    type: 'schedule',
    targetLinkName: 'CreateArrangement',
    svg: require('@/assets/icons/event-3.svg'),
    label: 'createMode.menus.arrangement.label',
    tip: {
      line1: 'createMode.menus.arrangement.tip.line1',
      line2: 'createMode.menus.arrangement.tip.line2'
    },
    disabledInPast: true
  },
  {
    key: 'availabilitySharing',
    type: 'schedule',
    targetLinkName: 'AvailabilityList',
    svg: require('@/assets/icons/link.svg'),
    label: 'createMode.menus.availabilitySharing.label',
    tip: {
      line1: 'createMode.menus.availabilitySharing.tip.line1',
      line2: 'createMode.menus.availabilitySharing.tip.line2'
    },
    hideOnCalendar: true,
    hideOnMobile: true
  },
  {
    key: 'event',
    type: 'event',
    targetLinkName: 'CreateEvent',
    svg: require('@/assets/icons/add_event.svg'),
    label: 'createMode.menus.event.label'
  }
]

export const defaultScheduleMenus = menus.filter((m) => m.type === 'schedule')
export const defaultEventMenu = menus.find((m) => m.type === 'event')
