






































import { Vue, Component } from 'vue-property-decorator'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'

@Component({
  components: {
    WithValidationInput
  }
})
export default class YourInfoForm extends Vue {
  nameValue = ''
  emailValue = ''

  updateNameValue(newValue) {
    this.$emit('changeName', newValue)
  }

  updateEmailValue(newValue) {
    this.$emit('changeEmail', newValue)
  }
}
