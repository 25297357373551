















import { computed, defineComponent, PropType } from '@vue/composition-api'
import Avatar from 'vue-avatar'
import { MemberCalendar } from '@spirinc/contracts'

export default defineComponent({
  name: 'MemberItem',
  components: {
    Avatar
  },
  props: {
    memberCalendar: {
      type: Object as PropType<MemberCalendar>,
      required: true
    }
  },
  setup(props) {
    const photoUrl = computed(() => props.memberCalendar.photoUrl)
    const name = computed(() => props.memberCalendar.fullName)
    const backgroundColor = computed(() => props.memberCalendar.backgroundColor)
    return {
      photoUrl,
      name,
      backgroundColor
    }
  }
})
