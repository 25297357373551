




import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import TimeAxisMixin from '@/components/calendar/TimeAxis'

@Component
export default class TimeAxisItem extends mixins(TimeAxisMixin) {
  @Prop() dateToShow: Date
  @Prop() gridHeight: string

  $refs: {
    resourceViewTimeAxis: HTMLElement
  }
  mounted() {
    this.setLabel()
  }
  setLabel() {
    this.$refs.resourceViewTimeAxis.appendChild(this.timeAxisBody(this.dateToShow))
  }
}
