
























import Divider from '@/components/atoms/Divider.vue'
import FormCollapseBorderedCard from '@/components/ui/form/FormCollapseBorderedCard.vue'
import DateSlotListItemTitle from '@/components/ui/form/FormDatetimeSlotsBox/DateSlotListItemTitle.vue'
import { dateSlotPeriodTitle, VotingDateSlot } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import VotingDatetimeSlotListItem from '@/components/ui/form/FormDatetimeSlotsBox/VotingDatetimeSlotListItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { useTranslation } from '@/composables/useTranslation'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'VotingPeekCandidateList',
  components: {
    FormCollapseBorderedCard,
    DateSlotListItemTitle,
    FormItemSpacer,
    Divider,
    VotingDatetimeSlotListItem
  },
  props: {
    dateSlots: {
      type: Array as PropType<VotingDateSlot[]>,
      default: () => []
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const title = computed(() => {
      return dateSlotPeriodTitle(props.dateSlots, i18n.t('forms.sections.peekCandidates.list.empty').toString())
    })
    return {
      title
    }
  }
})
