




import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModelCommon, PollModel } from '@/models/data'
import { SignalType } from '@/lib/analytics/LogEntry'
import { ListType } from '@/types'
import ShareTextModal from '@/components/modal/shareText/ShareTextModal.vue'
import { copyClipboard } from '@/lib/utils'

@Component
export default class ShareButton extends Vue {
  @Prop() currentSchedule: ScheduleModelCommon | PollModel

  handleUrlCopy() {
    if (this.currentSchedule.type === ListType.POLL) {
      this.copyUrl()
    } else {
      this.openShareModal()
    }
  }

  async openShareModal() {
    await this.$analytics.send(SignalType.CLICK_HTML_SHARE_ON_CANDIDATE_MODAL, { type: this.currentSchedule.type })
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ShareTextModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        schedule: this.currentSchedule
      },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
  async copyUrl() {
    await this.$analytics.send(SignalType.CLICK_URL_SHARE_ON_CANDIDATE_MODAL, { type: this.currentSchedule.type })
    copyClipboard(this.currentSchedule.urlForConfirmer)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }
}
