




















import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import SpirDropdown from '@/components/ui/spir/SpirDropdown/SpirDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import { FrontSupportCountryCode, frontSupportCountryCodes } from '@/types/frontSupportCountry'
import { holidayExclusion } from '@/lib/utils'

export default defineComponent({
  name: 'HolidayDropdown',
  components: {
    SpirDropdown,
    SpirDropdownSection,
    SpirDropdownItem
  },
  props: {
    triggerLabel: {
      type: String,
      required: true
    },
    selectedCountryCodes: {
      type: Array as PropType<FrontSupportCountryCode[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { selectedCountryCodes } = toRefs(props)

    const options = computed(() =>
      frontSupportCountryCodes.map((code) => ({
        code,
        selected: selectedCountryCodes.value.some((country) => code === country),
        label: holidayExclusion.getI18nCountryName(code)
      }))
    )

    function handleChange(newValue: string) {
      emit('change', newValue)
    }

    return {
      options,
      handleChange
    }
  }
})
