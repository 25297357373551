














































































import { orderBy } from 'lodash'
import { PersonalCalendar } from '@spirinc/contracts'
import CollapseWithChevronHeader from '@/components/atoms/CollapseWithChevronHeader.vue'
import CalendarAvatarAndName from '@/components/calendarList/atoms/CalendarAvatarAndName.vue'
import PersonalCalendarListItem from '@/components/calendarList/molecules/PersonalCalendarListItem.vue'
import CalendarAddButton from '@/components/calendarList/molecules/CalendarAddButton.vue'
import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import { useTranslation } from '@/composables/useTranslation'
import { useModal } from '@/composables/useModal'
import { useDeferredFeature } from '@/composables/useDeferredFeature'

export default defineComponent({
  components: {
    CollapseWithChevronHeader,
    CalendarAvatarAndName,
    PersonalCalendarListItem,
    CalendarAddButton
  },
  props: {
    account: {
      type: Object as PropType<PersonalCalendar>,
      default: {},
      required: true
    },
    accountCount: {
      type: Number,
      required: true
    },
    onDeleteAccount: {
      type: Function as PropType<(account: PersonalCalendar) => void>,
      required: true
    },
    onCalendarBackgroundColorChange: {
      type: Function as PropType<(payload: any) => Promise<void>>
    }
  },
  setup(props) {
    const { account, accountCount } = toRefs(props)
    const i18n = useTranslation()
    const { openRemoveCalendarModal } = useModal()
    const otherCalendarsSearch = ref('')
    const collapseId = computed(() => `private_${account.value.accountId}`)
    const myCalendars = computed(() => account.value.calendars.filter((c) => c.owner))
    const allOtherCalendars = computed(() =>
      orderBy(
        account.value.calendars.filter((c) => !c.owner),
        'visible',
        'desc'
      )
    )
    const otherCalendars = computed(() => {
      const search = otherCalendarsSearch.value.toLowerCase()
      if (!search) {
        return allOtherCalendars.value
      }
      return allOtherCalendars.value.filter((c) => c.title.replace(/\s/g, '').toLowerCase().includes(search))
    })
    const isDisabled = computed(() => account.value.isAccessTokenInvalid)
    const accountType = computed(() => account.value.accountType)
    const showMenu = computed(() => isDisabled.value || accountCount.value > 1)
    const canRemove = computed(() => accountCount.value > 1)

    function handleRemoveCalendar() {
      openRemoveCalendarModal({
        customTitle: i18n.t('message.confirmDeleteAccountTitle').toString(),
        customMessage: i18n.t('message.confirmDeleteAccountDescription').toString().replace(/\n/g, '<br/>'),
        onDeleteAccount: () => {
          props.onDeleteAccount(account.value)
          return
        }
      })
    }

    const { defer } = useDeferredFeature(myCalendars.value.length + otherCalendars.value.length)

    return {
      accountType,
      showMenu,
      canRemove,
      otherCalendars,
      myCalendars,
      collapseId,
      allOtherCalendars,
      isDisabled,
      otherCalendarsSearch,
      handleCalendarBackgroundColorChange: props.onCalendarBackgroundColorChange,
      handleRemoveCalendar,
      defer
    }
  }
})
