







import { defineComponent } from '@vue/composition-api'
import ConfirmParagraph from '@/components/sidePanels/confirm/atoms/ConfirmText.vue'

export default defineComponent({
  components: {
    ConfirmParagraph
  }
})
