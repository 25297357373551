






import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'

type SpaceName = 'vb16' | 'vt16' | 'vb8' | 'vt8'
export default defineComponent({
  components: {
    FormItemSpacer
  },
  props: {
    spaceName: {
      type: String as PropType<SpaceName>,
      required: true
    }
  },
  setup(props) {
    const { spaceName } = toRefs(props)
    const sName = spaceName.value.slice(1, spaceName.value.length)
    return {
      sName
    }
  }
})
