



























import Divider from '@/components/atoms/Divider.vue'
import SidePanelBox from '@/components/ui/page/SidePanelBox.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import Common from '@/views/calendar/Common.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import PersonalTeamTypeSection from '@/components/ui/domain/section/availabilitySharing/PersonalTeamTypeSection.vue'
import BasicInfoSection from '@/components/ui/domain/section/availabilitySharing/BasicInfoSection.vue'
import OrganaizerAndAttendeeSectionForPersonal from '@/components/ui/domain/section/availabilitySharing/OrganaizerAndAttendeeSectionForPersonal.vue'
import OrganaizerAndAttendeeSectionForTeam from '@/components/ui/domain/section/availabilitySharing/OrganaizerAndAttendeeSectionForTeam.vue'
import CandidateSection from '@/components/ui/domain/section/availabilitySharing/CandidateSection.vue'
import ConfirmationFormMessageSection from '@/components/ui/domain/section/availabilitySharing/ConfirmationFormMessageSection.vue'
import OtherInfoSection from '@/components/ui/domain/section/availabilitySharing/OtherInfoSection.vue'
import FooterButtons from './_FooterButtons.vue'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'SidePanel',
  components: {
    ValidationObserver,
    Common,
    SidePanelBox,
    ClosingPageheadTitleBox,
    ListItemSpacer,
    Divider,
    PersonalTeamTypeSection,
    BasicInfoSection,
    OrganaizerAndAttendeeSectionForPersonal,
    OrganaizerAndAttendeeSectionForTeam,
    CandidateSection,
    ConfirmationFormMessageSection,
    OtherInfoSection,
    FooterButtons
  },
  props: {
    suggestions: {
      type: Object as PropType<{ private: string[]; candidate: string[] }>,
      default: () => ({ private: [], candidate: [] })
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const { editingObjectType, editingObject } = useAvailabilitySharingForm()

    const isPersonalType = computed(() => editingObjectType.value === 'personal')
    const isTeamType = computed(() => editingObjectType.value === 'team')

    const hasConfirmationFormMessage = computed(() => editingObject.value.confirmationForm?.properties['message'])
    const isModelValid = computed(() => editingObject.value?.isValidToFetchCandidates)

    return {
      isPersonalType,
      isTeamType,
      hasConfirmationFormMessage,
      isModelValid,

      handleClose: () => props.onCancel()
    }
  }
})
