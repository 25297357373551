import { useTranslation } from './useTranslation'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { PollModel, ScheduleModel, ScheduleModelTeam } from '@/models/data'
import AppModule from '@/store/modules/app'
import ConfirmedScheduleEventModule from '@/store/modules/confirmedScheduleEvent'
import {
  ConfirmedPrivateCardInfo,
  ConfirmedPrivateCardType,
  ConfirmedTeamCardInfo,
  ConfirmedTeamCardType
} from '@/types'
import { useModal } from './useModal'
import { useToast } from './useToast'

type ConfirmedInfoHaving = { confirmedInfo?: { start?: string; end?: string } }
function scheduleDateComparator<T extends ConfirmedInfoHaving>(lhs: T, rhs: T) {
  return new Date(lhs.confirmedInfo?.start).getTime() - new Date(rhs.confirmedInfo?.start).getTime()
}

export function formatDateForDateHeader(date: Date): string {
  return `${spirDateFormat(date, spirDateFormatTypes.yyyymmddweekday)}`
}
function aggregateSchedulesByDate<T extends ConfirmedInfoHaving>(adjustments: T[]): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {}
  for (const adjustment of adjustments) {
    if (!adjustment.confirmedInfo || !adjustment.confirmedInfo?.start) {
      continue
    }
    const start = new Date(adjustment.confirmedInfo.start)
    const formattedDate = formatDateForDateHeader(start)
    if (!(formattedDate in result)) {
      result[formattedDate] = []
    }
    result[formattedDate].push(adjustment)
  }
  return result
}

type CardInfo<T extends 'private' | 'team'> = T extends 'private' ? ConfirmedPrivateCardInfo : ConfirmedTeamCardInfo
type CardType<T extends 'private' | 'team'> = T extends 'private' ? ConfirmedPrivateCardType : ConfirmedTeamCardType

function toInfos(grouped, converter) {
  return Object.keys(grouped).map((date) => {
    const cardInfos = grouped[date].map((adjustment) => converter(adjustment))
    return { dateKey: date, cardInfos }
  })
}

function aggregateToDateGroups<T extends 'private' | 'team', M extends ConfirmedInfoHaving>(
  aggregateType: T,
  models: M[],
  converter: (T) => CardInfo<T>,
  today: Date = new Date()
): CardType<T> {
  const { past, future } = models.reduce(
    (acc, model) => {
      if (model.confirmedInfo && new Date(model.confirmedInfo.end) < today) {
        acc.past.push(model)
      } else {
        acc.future.push(model)
      }
      return acc
    },
    { past: [], future: [] }
  )
  const sortedPastInfos = past.sort(scheduleDateComparator)
  const groupedPast = aggregateSchedulesByDate(sortedPastInfos)
  const pastInfos = toInfos(groupedPast, converter)
  const sortedFutureInfos = future.sort(scheduleDateComparator)
  const groupedFuture = aggregateSchedulesByDate(sortedFutureInfos)
  const futureInfos = toInfos(groupedFuture, converter)
  const isEmpty = pastInfos.length + futureInfos.length === 0
  return {
    type: aggregateType,
    isEmpty,
    infos: {
      past: pastInfos,
      future: futureInfos
    }
  } as CardType<T>
}

type ReturnTypes = {
  getPrivateCardTypes: (models: (PollModel | ScheduleModel)[]) => ConfirmedPrivateCardType
  getTeamCardTypes: (models: ScheduleModelTeam[]) => ConfirmedTeamCardType
  getTeamLoading: (model: { isLoading: boolean }) => boolean
}

export const useConfirmedConverters = (): ReturnTypes => {
  const { openEventFormModal } = useModal()
  const { openDangerTopToast } = useToast()
  const i18n = useTranslation()

  function showItem(model: ScheduleModel | PollModel) {
    AppModule.closeQuickView()
    EventBus.emit(EVENTS.SHOW_EVENT, {
      eventId: model.confirmedInfo?.eventId,
      accountId: model.accountId,
      calendarId: model.calendarId,
      moveDate: true
    })
  }

  async function showTeam(item: ScheduleModelTeam) {
    AppModule.closeQuickView()
    if (!item.organizer) {
      openDangerTopToast({ message: i18n.t('event.list.toast.cannotShowDeletedMemmberEvent').toString() })
      return
    }
    const confirmedEvent = await ConfirmedScheduleEventModule.getConfirmedEvent({
      teamId: item.teamId,
      scheduleId: item.id
    })
    openEventFormModal({ currentEvent: confirmedEvent })
  }

  function convertToConfirmedCardInfo(model: ScheduleModel | PollModel): ConfirmedPrivateCardInfo {
    return {
      id: model.id,
      type: model.type,
      groupType: 'private',
      title: model.title,
      timeRange: { start: model.confirmedInfo?.start, end: model.confirmedInfo?.end },
      authorName: model.calendarInfo?.calendarName,
      backgroundColor: model.calendarInfo?.backgroundColor,
      handleClick: () => showItem(model)
    }
  }

  function convertToConfirmedTeamCardInfo(model: ScheduleModelTeam): ConfirmedTeamCardInfo {
    return {
      id: model.id,
      type: 'schedule',
      groupType: 'team',
      title: model.title,
      timeRange: { start: model.start, end: model.end },
      organizer: model?.organizer,
      handleClick: () => showTeam(model)
    }
  }
  return {
    getPrivateCardTypes: (models: (PollModel | ScheduleModel)[]): ConfirmedPrivateCardType => {
      return aggregateToDateGroups('private', models, convertToConfirmedCardInfo)
    },
    getTeamCardTypes: (models: ScheduleModelTeam[]): ConfirmedTeamCardType => {
      return aggregateToDateGroups('team', models, convertToConfirmedTeamCardInfo)
    },
    getTeamLoading: (model: { isLoading: boolean }): boolean => {
      return model?.isLoading || ConfirmedScheduleEventModule.isLoading
    }
  }
}
