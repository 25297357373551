
























import SidePanel from '@/components/page/availabilitySharing/_SidePanel/index.vue'
import Skeleton from '@/components/sidePanels/edit/Skeleton.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useHolidayCountryRemover } from '@/composables/useHolidayCountryRemover'
import { useScheduleEventListPanel } from '@/composables/useScheduleEventListPanel'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import getErrorMessage from '@/lib/utils/getErrorMessage'
import { FullCalendarEvent } from '@/types'
import { AvailabilitySharingTitleSuggestionSetup, FormType } from '@/types/availabilitySharingForm'
import Common from '@/views/calendar/Common.vue'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { defineComponent, onMounted, onUnmounted, PropType, toRefs } from '@vue/composition-api'
import { Prop } from 'vue/types/options'

export default defineComponent({
  name: 'AvailabilitySharingEditPage',
  components: {
    Common,
    Skeleton,
    SidePanel
  },
  props: {
    type: {
      type: String as PropType<FormType>,
      required: true
    },
    suggestionSetup: {
      type: Object as PropType<AvailabilitySharingTitleSuggestionSetup>,
      required: true
    },
    teamId: {
      type: String
    },
    availabilitySharingId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { type, teamId, availabilitySharingId } = toRefs(props)
    const { getHolidayCountryRemover } = useHolidayCountryRemover()

    useScheduleEventListPanel()
    const i18n = useTranslation()
    const { openPrimaryTopToast, openDangerBottomToast } = useToast()

    const {
      editingObject,
      isLoading,
      eventsFromEditingObject,
      setupEditingObject,
      updateEditingObject,
      updateCondition,
      addException,
      removeException,
      addHolidayException,
      removeHolidayException,
      confirmEditingObject,
      resetEditingObject
    } = useAvailabilitySharingForm()

    function removeHolidayExceptionWithConfirmationModal(event: FullCalendarEvent) {
      const remover = getHolidayCountryRemover(removeHolidayException)
      remover(event)
    }

    async function handleConfirm() {
      try {
        // copy title, candidateTitle and teamId because confirmEditingObject('create') resets them.
        const title = editingObject.value.title
        const candidateTitle = editingObject.value.candidateTitle
        await confirmEditingObject('edit')
        props.suggestionSetup.save({ privateTitle: title, candidateTitle })
        openPrimaryTopToast({
          message: `${i18n.t('message.success.updated', { type: i18n.t('availabilitySharing.editLabel').toString() })}`
        })
        emit('leavePageByConfirm')
      } catch (e) {
        const message = getErrorMessage(e, {
          handleKeys: [
            {
              errorCode: ERROR_CODE.DISCONNECTED_ZOOM_INTEGRATION,
              i18nKey: 'availabilitySharing.messages.error.DISCONNECTED_ZOOM_INTEGRATION'
            }
          ]
        })
        openDangerBottomToast({ message })
      }
    }

    function handleCancel() {
      emit('leavePageByCancel')
    }

    onMounted(async () => {
      await setupEditingObject({
        mode: 'edit',
        type: type.value,
        teamId: teamId.value,
        availabilitySharingId: availabilitySharingId.value
      })
    })
    onUnmounted(() => resetEditingObject())

    return {
      editingObject,
      isLoading,
      eventsFromEditingObject,
      addException,
      updateEditingObject,
      updateCondition,
      removeException,
      addHolidayException,
      removeHolidayExceptionWithConfirmationModal,
      handleConfirm,
      handleCancel
    }
  }
})
