import { ListType, FullCalendarEvent } from '@/types'
import { usePeekCandidates } from './_usePeekCandidates'
import { usePeekCandidatesConditionForm } from './_usePeekCandidatesConditionForm'

type Props = {
  listType: ListType
}
export const usePeekCandidatesFormInit = ({ listType }: Props) => {
  const peekCandidates = usePeekCandidates({ listType })
  const peekCandidatesConditionForm = usePeekCandidatesConditionForm()
  const copyTemporaryCandidatesToCandidates = (
    fromCandidates: FullCalendarEvent[],
    replaceCandidates: (newCandidates: { start: string; end: string; id: string }[]) => void
  ) => {
    const copyingCandidates = fromCandidates.map((c) => {
      return { start: c.start.toISOString(), end: c.end.toISOString(), id: c.id, status: c.extendedProps.status }
    })
    replaceCandidates(copyingCandidates)
  }
  return {
    peekCandidates,
    peekCandidatesConditionForm,
    copyTemporaryCandidatesToCandidates
  }
}
