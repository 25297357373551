









import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'

@Component({
  components: {
    Avatar
  }
})
export default class ComponentName extends Vue {
  @Prop() photoUrl: string
  @Prop() name: string
}
