










import { defineComponent, PropType } from '@vue/composition-api'
import ButtonToWrapIcon from '@/components/atoms/ButtonToWrapIcon.vue'

export default defineComponent({
  name: 'SuggestAlternateButtons',
  components: { ButtonToWrapIcon },
  props: {
    onDelete: {
      type: Function as PropType<(id: string) => void>,
      required: true
    },
    onUpdate: {
      type: Function as PropType<(payload: any) => void>,
      required: true
    }
  }
})
