









import { defineComponent, computed } from '@vue/composition-api'
import AsideMenuItemWithIcon from './molecules/AsideMenuItemWithIcon.vue'
import LoggedInUserBadge from './UserBadge/Index.vue'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  components: {
    AsideMenuItemWithIcon,
    LoggedInUserBadge
  },
  props: {
    expanded: {
      type: Boolean
    }
  },
  setup() {
    const isShowHelpMenu = computed(() => {
      return ProfileModule.getLanguage === 'ja'
    })
    return {
      isShowHelpMenu
    }
  }
})
