











import CollapseIconButton from './CollapseIconButton.vue'
import ConfirmText from './ConfirmText.vue'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmText,
    CollapseIconButton
  },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup() {
    const isOpen = ref(false)

    const handleToggle = () => {
      isOpen.value = !isOpen.value
    }
    return {
      isOpen,
      handleToggle
    }
  }
})
