




























import { computed, defineComponent, PropType, Ref, ref, toRefs, watch } from '@vue/composition-api'
import CommonLayoutModal from '../../modal/CommonLayout.vue'
import ResouceView from '@/components/calendar/resouceView/Index.vue'
import { useModal } from '@/composables/useModal'
import { isBefore } from 'date-fns'
import { PickDayOfWeekDefaultEvent } from '@/lib/utils/peekCandidates'
import { checkOverlap, noop } from '@/lib/utils'
import {
  isSameDayByUserTimeZone,
  addDays,
  startOfWeekByUserStartWeekday,
  startOfDay,
  parseHourAndMinToDate
} from '@/lib/utils/timezone'
import { ResourceEvent } from '@/types'
import { DayOfTheWeekRules } from '@spirinc/contracts'
import { spirDateFormatCustom, spirDateFormatCustomTypes } from '@/lib/utils/dateFormat'

const gridHeight = 52
const MIDNIGHT_FORMAT = '24:00'

const parseToTime = (date: Date, baseDate?: Date): string => {
  if (baseDate && !isSameDayByUserTimeZone(date, baseDate)) {
    return MIDNIGHT_FORMAT
  }
  return spirDateFormatCustom(date, spirDateFormatCustomTypes['HH:mm'])
}
export const parseFromTime = (s: string, baseDate?: Date): Date => {
  const defaultDate = baseDate || new Date()
  if (s === MIDNIGHT_FORMAT) {
    return addDays(defaultDate, 1)
  }
  return parseHourAndMinToDate(s, defaultDate)
}
export default defineComponent({
  components: {
    CommonLayoutModal,
    ResouceView
  },
  props: {
    dayOfTheWeekRules: {
      type: Object as PropType<DayOfTheWeekRules>,
      default: () => ({})
    },
    calendarEvents: {
      type: Array as PropType<ResourceEvent[]>
    },
    isDisplayDay: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { openDiscardConfirmationModal } = useModal()
    const { calendarEvents } = toRefs(props)
    const events: Ref<ResourceEvent[]> = ref([...calendarEvents.value])
    const lastScrollHeight = ref(gridHeight * 8)
    const eventDate = computed(() => startOfDay(new Date()))
    const isEditingObjectDirty = ref(false)
    const columns = computed(() => {
      const startDay = startOfWeekByUserStartWeekday(new Date())
      return Array.from(Array(7)).map((_, i) => {
        const newDate = addDays(startOfDay(startDay), i)
        const columnKey = spirDateFormatCustom(newDate, spirDateFormatCustomTypes.cccc, { locale: 'en' }).toLowerCase()
        return {
          columnKey,
          title: spirDateFormatCustom(newDate, spirDateFormatCustomTypes.ccc, { locale: 'en' }),
          writable: true,
          date: newDate,
          hasEvent: !!events.value.find((e) => e.columnKey === columnKey)
        }
      })
    })
    const handleCreateEvent = (event) => {
      if (!checkOverlap(events.value, event.start, event.end)) {
        return
      }
      events.value.push(PickDayOfWeekDefaultEvent(event.start, event.end, event.columnKey))
    }
    const handleUpdateEvent = ({ event }) => {
      if (!checkOverlap(events.value, event.start, event.end, event.id)) {
        events.value = [...events.value]
        return
      }
      const updatedEventIndex = events.value.findIndex((e) => e.id === event.id)
      events.value[updatedEventIndex] = {
        ...events.value[updatedEventIndex],
        start: event.start,
        end: event.end
      }
      events.value = [...events.value]
    }
    const handleClickEvent = ({ event }) => {
      const clickedEventIndex = events.value.findIndex((e) => e.id === event.id)
      events.value.splice(clickedEventIndex, 1)
    }
    const cancel = () => {
      if (isEditingObjectDirty.value) {
        openDiscardConfirmationModal({
          confirm: () => emit('close'),
          cancel: noop
        })
      } else emit('close')
    }
    const confirm = () => {
      const selectedEvent = events.value
        .sort((a, b) => (isBefore(a.start, b.start) ? -1 : 1))
        .reduce((a, c: ResourceEvent) => {
          if (!a[c.columnKey]) {
            a[c.columnKey] = {
              rules: []
            }
          }
          a[c.columnKey]['rules'].push({
            start: parseToTime(c.start),
            end: parseToTime(c.end, c.start),
            type: 'include'
          })
          return a
        }, {})
      emit('confirm', selectedEvent)
    }

    watch(events, () => {
      isEditingObjectDirty.value = true
    })

    return {
      events,
      lastScrollHeight,
      eventDate,
      columns,
      cancel,
      handleCreateEvent,
      handleClickEvent,
      handleUpdateEvent,
      confirm
    }
  }
})
