
















import { Component, Prop, Vue } from 'vue-property-decorator'
import Body from './body/Index.vue'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { startOfDay, addDays } from '@/lib/utils/timezone'
import { ResourceColumn, ResourceEvent } from '@/types'

@Component({
  components: {
    Body
  }
})
export default class ResourceView extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: 52 }) gridHeight: number
  @Prop({ default: true }) selectable: boolean
  @Prop({ default: () => [] }) events: ResourceEvent[]
  @Prop({ default: () => [] }) fullWidthEvents: ResourceEvent[]
  @Prop() eventDate: Date
  @Prop() calendarHeight: number
  @Prop({ default: 0 }) lastScrollHeight: number
  @Prop() columns: ResourceColumn[]
  @Prop({ default: true }) withMinWidth: boolean
  @Prop({ default: false }) hideAllday: boolean
  @Prop() timezoneButtonAsLabel
  @Prop({ default: true }) isDisplayDay: boolean
  @Prop({ default: true }) isDisplayWeekDay: boolean

  private created() {
    EventBus.on(EVENTS.CLICKED_EVENT, this.clickEventListener)
    EventBus.on(EVENTS.UPDATE_EVENT, this.updateEventListener)
    EventBus.on(EVENTS.CREATE_EVENT, this.createEvent)
    EventBus.on(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
    this.$nextTick(() => {
      EventBus.emit(EVENTS.RESOURCE_TO_SCROLL, this.lastScrollHeight)
    })
  }
  beforeDestroy() {
    EventBus.off(EVENTS.CLICKED_EVENT, this.clickEventListener)
    EventBus.off(EVENTS.UPDATE_EVENT, this.updateEventListener)
    EventBus.off(EVENTS.CREATE_EVENT, this.createEvent)
    EventBus.off(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  clickEventListener({ event, jsEvent, el }) {
    return this.$emit('eventClick', { event, jsEvent, el })
  }
  updateEventListener({ event, jsEvent, el, revert }) {
    return this.$emit('updateEvent', { event, jsEvent, el, revert })
  }

  syncScroll(srcElement) {
    this.$emit('scrolled', srcElement.scrollTop)
  }
  createEvent({ columnKey, allDay, start, end, jsEvent }) {
    const payload: any = {
      columnKey,
      start,
      end,
      allDay,
      jsEvent
    }
    if (allDay) {
      payload.allDay = true
      payload.start = startOfDay(this.eventDate)
      payload.end = startOfDay(addDays(this.eventDate, 1))
    }
    this.$emit('createEvent', payload)
  }
}
