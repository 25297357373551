









import { CardItem } from '@/lib/utils'
import { CardItemTip } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardBodyItem from './CardBodyItem.vue'

@Component({
  components: {
    CardBodyItem
  }
})
export default class OwnerInfoCardBodyItem extends Vue {
  @Prop() name: string
  @Prop() backgroundColor: string | undefined

  get tipInfo(): CardItemTip {
    return CardItem.convertToCardItemTip(true, this.$t('labels.organizer').toString())
  }
}
