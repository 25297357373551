




import SignUpPage from '@/components/page/SignUpPage.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    SignUpPage
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'index,follow'
        }
      ]
    }
  }
})
