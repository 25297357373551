

























import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class ProfileBasicEditor extends Vue {
  @PropSync('fullName', { type: String }) fullNameSynced!: string
  @PropSync('companyName', { type: String }) companyNameSynced!: string
  @PropSync('jobTitle', { type: String }) jobTitleSynced!: string
  @PropSync('introduction', { type: String }) introductionSynced!: string
}
