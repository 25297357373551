


















import { defineComponent, PropType } from '@vue/composition-api'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import SignInButtons from '@/components/auth/SignInButtons.vue'
import AddCalendarDescription from './AddCalendarDescription.vue'
import AddCalendarButtons from './AddCalendarButtons.vue'

export default defineComponent({
  props: {
    isShowSignOut: {
      type: Boolean,
      default: true
    },
    signOut: {
      type: Function
    },
    addMicrosoftCalendar: {
      type: Function,
      required: true
    },
    addGoogleCalendar: {
      type: Function,
      required: true
    },
    title: {
      type: String
    },
    onlyConnectTo: {
      type: String as PropType<'google' | 'microsoft'>
    }
  },
  components: {
    AuthFormLayout,
    SignInButtons,
    AuthFormSpacer,
    AddCalendarDescription,
    AddCalendarButtons
  }
})
