












































































import { Vue, Component } from 'vue-property-decorator'
import firebase from 'firebase/app'
import SettingLayout from '@/components/layout/Setting.vue'

@Component({
  components: {
    SettingLayout
  }
})
export default class AccountLink extends Vue {
  email = ''
  newPassword = ''

  get currentUser() {
    return firebase.auth().currentUser
  }

  get providers() {
    return this.currentUser.providerData
  }

  get isPasswordActive() {
    return this.providers.filter((p) => p.providerId === 'password').length !== 0
  }

  get isGoogleActive() {
    return this.providers.filter((p) => p.providerId === 'google.com').length !== 0
  }

  updateEmail() {
    this.currentUser
      .updateEmail(this.email)
      .then(() => this.currentUser.sendEmailVerification())
      .then(() => location.reload())
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          alert('再ログインが必要です。')
        } else {
          alert(error)
        }
      })
  }

  updatePassword() {
    this.currentUser
      .updatePassword(this.newPassword)
      .then(() => location.reload())
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          alert('再ログインが必要です。')
        } else {
          alert(error)
        }
      })
  }

  linkToGoogle() {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    this.currentUser
      .linkWithRedirect(googleProvider)
      .then(() => location.reload())
      .catch((error) => alert(error))
  }

  unlinkFromGoogle() {
    if (this.isPasswordActive) {
      this.currentUser
        .unlink('google.com')
        .then(() => location.reload())
        .catch((error) => alert(error))
    } else {
      alert('passwordログインを有効にしてください。')
    }
  }

  unlink(providerId: string) {
    this.currentUser
      .unlink(providerId)
      .then(() => location.reload())
      .catch((error) => alert(error))
  }
}
