




import UnconfirmedListPage from '@/components/page/list/UnconfirmedListPage.vue'
import queryParams from '@/lib/queryParams'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    UnconfirmedListPage
  },
  setup() {
    return {
      teamIdQueryKey: queryParams.TEAM_ID
    }
  }
})
