







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TagWithIconAndTitle extends Vue {
  @Prop() icon
  @Prop() type
}
