import { useFormCalendarsAutoCompleteItem } from '@/components/ui/domain/sections/arrangement/peekCandidatesSectionComposables/_useFormCalendarsAutoCompleteItem'
import { ref } from '@vue/composition-api'
import { usePeekCandidatesCondition } from './usePeekCandidatesCondition'
type Props = { isAutoPeekCandidatesFetchEnabled: boolean }
/**
 * 役割。
 * 1. 自動抽出の条件がVuexになかったらFetchする。
 * 2. 自動抽出して候補にする設定がOnの場合は取得してリターンする。
 */
export const usePeekConditionAndFetchCandidates = () => {
  const { getCondition } = usePeekCandidatesCondition()
  const isLoading = ref(false)
  const calendars = useFormCalendarsAutoCompleteItem()
  const getConditionAndFetchCandidates = async (duration: number, fetchCandidatesFunction) => {
    try {
      isLoading.value = true
      const peekCandidateCondition = await getCondition()
      return await fetchCandidatesFunction({
        ...peekCandidateCondition,
        duration,
        calendars: calendars.asParameter
      })
    } catch (e) {
      return []
    } finally {
      isLoading.value = false
    }
  }
  return {
    isLoading,
    getConditionAndFetchCandidates
  }
}
