









import ConfirmText from './atoms/ConfirmText.vue'
import ConfirmCandidateSelectButton, {
  SkeltonCandidateSelectButtonInfo
} from './atoms/ConfirmCandidateSelectButton.vue'
import BarSkelton from '@/components/ui/BarSkelton.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmText,
    ConfirmCandidateSelectButton,
    BarSkelton
  },
  setup() {
    const info: SkeltonCandidateSelectButtonInfo = { type: 'skelton' }
    return {
      info
    }
  }
})
