











import CreateArrangementPage from '@/components/page/CreateArrangementPage/index.vue'
import { TitleSuggestionSetups } from '@/components/ui/domain/sections/arrangement/composables/types'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { useArrangementChangeGuard } from '@/composables/useArrangementChangeGuard'
import { GenTitleSuggestionSetup } from '@/lib/utils'
import { DoNotShowNextTimeCheck, doNotShowNextTimeKeys } from '@/models/localStorage/DoNotShowNextTimeCheck'
import { LastArrangementFormRequest } from '@/models/localStorage/LastArrangementFormRequest'
import { defineComponent, ref } from '@vue/composition-api'
import { NavigationGuard } from 'vue-router'

export default defineComponent({
  components: {
    CreateArrangementPage
  },
  setup() {
    const page = ref(null)
    const { guard } = useArrangementChangeGuard()
    const lastArrangementFormRequest = new LastArrangementFormRequest()
    const initialFormRequest = lastArrangementFormRequest.loadFromLocalStorage()
    const doNotShowNextTimeCheck = new DoNotShowNextTimeCheck(doNotShowNextTimeKeys.AUTO_PEEK_CANDIDATES_INFO)
    const suggestionSetups: TitleSuggestionSetups = GenTitleSuggestionSetup.genSuggestionSetupsForCreate()
    function guardBeforeRouteLeave(...args: Parameters<NavigationGuard>) {
      guard(page.value.formData.shouldStayHere, args)
    }
    return {
      page,
      initialFormRequest,
      suggestionSetups,
      handleLastFormRequestSave: (newFormRequest: ArrangementFormRequest) =>
        lastArrangementFormRequest.saveToLocalStorage(newFormRequest),
      canShowAutoCandidateInfo: doNotShowNextTimeCheck.canShow,
      handleDoNotShowNextTimeCheckSave: (doNotShowNextTime: boolean) => doNotShowNextTimeCheck.save(doNotShowNextTime),
      guardBeforeRouteLeave
    }
  },
  beforeRouteLeave(to, from, next) {
    this.guardBeforeRouteLeave(to, from, next)
  }
})
