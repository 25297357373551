




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DateTimeForm from '@/components/organisms/DateTimeForm.vue'
import { isEqual, differenceInMinutes, addMinutes } from 'date-fns'

const MIN_EVENT_DURATION = 15

@Component({
  components: {
    DateTimeForm
  }
})
export default class EventFormDateInput extends Vue {
  @Prop() startDate: Date
  @Prop() endDate: Date
  @Prop() allDay: boolean
  @Prop({ default: null }) minEndDate: Date

  editingStartDate: Date = null
  editingEndDate: Date = null

  created() {
    this.setEditingDate()
  }

  @Watch('startDate')
  onChangedStartDate(newVal, oldVal) {
    if (isEqual(newVal, oldVal)) {
      return
    }
    this.setEditingDate()
  }
  @Watch('endDate')
  onChangedEndDate(newVal, oldVal) {
    if (isEqual(newVal, oldVal)) {
      return
    }
    this.setEditingDate()
  }
  @Watch('editingStartDate')
  onChangedEditingStartDate() {
    if (!this.validateChangedDate) {
      this.editingEndDate = addMinutes(this.editingStartDate, MIN_EVENT_DURATION)
    }
    this.saveEditingEvent()
  }
  @Watch('editingEndDate')
  onChangedEditingEndDate() {
    if (!this.validateChangedDate) {
      this.editingStartDate = addMinutes(this.editingEndDate, -MIN_EVENT_DURATION)
    }
    this.saveEditingEvent()
  }
  get isSame() {
    return isEqual(this.editingStartDate, this.startDate) && isEqual(this.editingEndDate, this.endDate)
  }
  get validateChangedDate() {
    return differenceInMinutes(this.editingEndDate, this.editingStartDate) >= MIN_EVENT_DURATION
  }
  saveEditingEvent() {
    if (this.isSame) {
      return
    }
    this.$emit('updateDate', { start: this.editingStartDate, end: this.editingEndDate })
  }
  setEditingDate() {
    if (this.isSame) {
      return
    }
    this.editingStartDate = this.startDate
    this.editingEndDate = this.endDate
  }
}
