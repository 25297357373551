




import AvailabilitySharingConfirmPage from '@/components/page/AvailabilitySharingConfirmPage.vue'
import {
  PrivateAfterConfirmQueryParamsParts,
  PrivateSignalParams,
  useConfirmAvailabilitySetup
} from '@/composables/useConfirmAvailabilitySetup'
import * as availabilityAPI from '@/lib/api/availability'
import { AvailabilityOverviewModel, ConfirmAvailabilityModelData } from '@/models/data'
import { AfterConfirmModelForAvailability } from '@/models/data/afterConfirm'
import ConfirmAvailabilityModule from '@/store/modules/confirmAvailability'
import { AvailabilityConfirm, FullCalendarEvent } from '@/types'
import { computed, defineComponent } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { ConfirmationForm } from '@/models/data'
import { Confirmer } from '@/models/data/'

export default defineComponent({
  components: {
    AvailabilitySharingConfirmPage
  },
  setup() {
    const route = useRoute()
    const availabilityId = computed(() => {
      return route.params.id
    })
    const overview = computed(() => {
      return ConfirmAvailabilityModule.getOverview
    })

    async function getAvailabilityModel(): Promise<ConfirmAvailabilityModelData> {
      const response = await availabilityAPI.getCandidatesById(availabilityId.value)
      return {
        ...response,
        id: availabilityId.value
      }
    }

    const getAvailabilityOverviewModel = async (): Promise<AvailabilityOverviewModel> => {
      const overview = await availabilityAPI.getOverview(availabilityId.value)
      const overviewModel = new AvailabilityOverviewModel(overview)
      return overviewModel
    }

    function getConfirmPublicURLSignalParams(): PrivateSignalParams {
      const confirmAvailability = ConfirmAvailabilityModule.getModel
      return {
        id: availabilityId.value,
        type: 'private',
        authorUserId: confirmAvailability.organizer?.id
      }
    }

    function getAfterConfirmQueryParams(): PrivateAfterConfirmQueryParamsParts {
      return {
        id: availabilityId.value,
        't-or-p': 'private'
      }
    }

    function getConfirmAvailabilityModalOtherParams(): { confirmationForm: ConfirmationForm } {
      return {
        confirmationForm: overview.value.confirmationForm
      }
    }

    async function persistAvailability(params: {
      availabilityConfirm: AvailabilityConfirm
      eventToConfirm: FullCalendarEvent
      confirmer: Confirmer
    }): Promise<{ afterConfirmModel: AfterConfirmModelForAvailability; email: string }> {
      const payload = {
        ...params.availabilityConfirm,
        start: params.eventToConfirm.start.toISOString(),
        end: params.eventToConfirm.end.toISOString(),
        ...params.confirmer
      }
      const response = await availabilityAPI.confirm(availabilityId.value, payload)
      const afterConfirmModel = new AfterConfirmModelForAvailability({
        payload: response
      })

      return {
        afterConfirmModel,
        email: params.confirmer.email
      }
    }

    const { setEditMode, handleConfirmAvailability } = useConfirmAvailabilitySetup({
      getAvailabilityModel,
      getAvailabilityOverviewModel,
      getConfirmPublicURLSignalParams,
      getAfterConfirmQueryParams,
      persistAvailability,
      getConfirmAvailabilityModalOtherParams
    })

    return {
      setEditMode,
      handleConfirmAvailability
    }
  }
})
