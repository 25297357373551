










import { CardItem } from '@/lib/utils'
import { CardItemTip } from '@/types'
import { Component, Vue } from 'vue-property-decorator'
import CardBodyItem from './CardBodyItem.vue'

@Component({
  components: {
    CardBodyItem
  }
})
export default class OrganizerInfoCardBodyItemFrame extends Vue {
  get label(): string {
    return 'labels.organizer'
  }
  get tipInfo(): CardItemTip {
    return CardItem.convertToCardItemTip(true, this.$t(this.label).toString())
  }
}
