
















import SpirButton from '@/components/ui/Button.vue'
import VotingPeekCandidateList from '@/components/ui/domain/collapse/ArrangementPeekCandidateList/VotingPeekCandidateList.vue'
import CommonHeaderParts from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/_CommonHeaderParts.vue'
import { VotingDateSlot } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'VotingPeekCandidatesSection',
  components: {
    SpirButton,
    ListItemSpacer,
    CommonHeaderParts,
    VotingPeekCandidateList
  },
  props: {
    dateSlots: {
      type: Array as PropType<VotingDateSlot[]>,
      default: () => []
    }
  },
  setup() {
    const { toggleScheduleEventsList } = useCalendarMixin()
    return {
      handleCalendarOpen: () => toggleScheduleEventsList(false)
    }
  }
})
