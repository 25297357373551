
























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AutoCandidatesSettingsModal from '@/components/modal/AutoCandidatesSettings.vue'
import AboutAutoCandidateExtractionModal from '@/components/help/AboutAutoCandidateExtraction.vue'
import DurationInputButton from '@/components/molecules/DurationInputButton.vue'

@Component({
  components: {
    DurationInputButton
  }
})
export default class CreateCandidatesControls extends Vue {
  @Prop({ default: false }) inThePanel
  @Prop() hasCandidates: boolean
  @Prop() isAddEventFormOpen
  @Prop({ default: false }) separateCandidateByDuration: boolean
  @PropSync('duration', { default: 0 }) durationSynced!: number

  modal = null

  get buttonSize() {
    return this.inThePanel ? 'is-small' : ''
  }
  async openSettingsModal() {
    this.modal = this.$buefy.modal.open({
      parent: this,
      component: AutoCandidatesSettingsModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        hasCandidates: this.hasCandidates,
        currentDuration: this.durationSynced,
        separateCandidateByDuration: this.separateCandidateByDuration
      },
      events: {
        close: () => {
          this.modal.close()
        },
        setAutoGeneratedCandidateToSchedule: (newDuration: number) => {
          this.$emit('setAutoGeneratedCandidateToSchedule', newDuration)
        }
      }
    })
  }

  showAboutModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AboutAutoCandidateExtractionModal,
      hasModalCard: true,
      canCancel: ['escape', 'outside']
    })
  }
  handleUpdateDuration(duration) {
    this.$emit('updateDuration', duration)
  }
}
