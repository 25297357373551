















import { computed, defineComponent, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'OnlineMeetingItem',
  props: {
    index: {
      type: Number,
      required: true
    },
    canDrag: {
      type: Boolean,
      requried: true
    },
    checkStatus: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { type } = toRefs(props)
    const imageUrl = computed(() => require(`@/assets/icons/${type.value}.png`))

    function toggleSwitch() {
      emit('toggle')
    }

    return {
      imageUrl,
      toggleSwitch
    }
  }
})
