















import { Component, Vue, Prop } from 'vue-property-decorator'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

@Component({
  components: {
    Popper
  }
})
export default class Tooltip extends Vue {
  @Prop() icon?: string
  @Prop() text?: string
}
