


















import { Component, Prop, Vue } from 'vue-property-decorator'
import Layout from '../atoms/AsideMenuItemLayout.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import PlusButton from '@/components/CreateMenu/CreateMenu.vue'

@Component({
  components: {
    Layout,
    PlusButton
  }
})
export default class AsideMenuItemLogo extends Vue {
  @Prop() expanded: boolean

  hideToolTip = false

  async handleMenuShow(showMenuStatus: boolean) {
    if (showMenuStatus) {
      await this.$analytics.send(SignalType.CLICK_NAV_PLUS)
    }
    this.hideToolTip = showMenuStatus
  }
}
