



















import { UnconfirmedPollCardStatus, UnconfirmedScheduleCardStatus } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'
import Button from '@/components/ui/Button.vue'

export default defineComponent({
  components: {
    Button
  },
  props: {
    status: {
      type: Object as PropType<UnconfirmedScheduleCardStatus | UnconfirmedPollCardStatus>,
      required: true
    }
  }
})
