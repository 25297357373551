















import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from './molecules/Item.vue'
import CollapseWithChevronHeader from '@/components/atoms/CollapseWithChevronHeader.vue'
import { Attendee, ProfileOnOverview } from '@/types'
import AttendeeWithProfileButton from '@/components/molecules/AttendeeWithProfileButton.vue'

@Component({
  components: {
    DisplayItem,
    CollapseWithChevronHeader,
    AttendeeWithProfileButton
  }
})
export default class DisplayAttendees extends Vue {
  @Prop() item: Attendee[]

  get hasAttendee() {
    return this.item?.length > 0
  }
  convertAttendee(attendee: Attendee): ProfileOnOverview {
    return {
      fullName: attendee.fullName,
      photoURL: attendee.photoURL,
      email: attendee.email,
      userId: attendee.id,
      isAuthor: attendee.organizer,
      attendanceStatus: attendee.responseStatus,
      isLoading: false
    }
  }
}
