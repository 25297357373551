











import { Component, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import FormSelectBox from '@/components/newForm/atoms/SelectBox.vue'

@Component({
  components: {
    FormItem,
    FormSelectBox
  }
})
export default class FormInputBuffer extends Vue {
  items = []
  created() {
    this.items = [0, 15, 30, 45, 60, 90, 120].map((n) => {
      return {
        key: n,
        title: this.$t('forms.event.duration', { min: n }).toString(),
        value: n
      }
    })
  }
}
