















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Events from './Events.vue'
import FullWidthEvent from './FullWidthEvent.vue'
import TimeGrid from './TimeGrid.vue'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { EventPosition, EventsForCalendar } from './EventTree'
import { EventBus, EVENTS } from '@/lib/eventBus'
import elementResizeDetectorMaker from 'element-resize-detector'
import { ResourceColumn, ResourceEvent } from '@/types'
import { SLOT_DURATION } from '@/types/constants'

const erd = elementResizeDetectorMaker()

@Component({
  components: {
    TimeGrid,
    Events,
    FullWidthEvent
  }
})
export default class DailyViewCalendarColumn extends Vue {
  @Prop() gridHeight: string
  @Prop() events: ResourceEvent[]
  @Prop() columnsLength: number
  @Prop() eventDate: Date
  @Prop() column: ResourceColumn
  @Prop() selectable: boolean
  @Prop() fullWidthEvents: ResourceEvent[]

  eventTree: EventsForCalendar = null
  eventsForDisplay: EventPosition[] = []

  // for draging
  newEvent: {
    initTime: Date
    start: Date
    end: Date
    dragStartY: number
  } | null = null

  mySize: { height: number; width: number } = { height: 0, width: 0 }
  @Watch('events')
  resetEventTree() {
    this.eventTree = new EventsForCalendar(this.events)
    this.eventsForDisplay = this.eventTree.eventArrayForCalendar()
  }
  @Watch('columnsLength')
  resetMySize() {
    this.setSize()
  }
  get oneHourSlot() {
    return 60 / SLOT_DURATION
  }
  get isClickable(): boolean {
    return !!this.column.writable
  }
  get calendarHeightPixel(): number {
    return Number(this.gridHeight) * 24
  }
  get oneSlotPixel(): number {
    return Number(this.gridHeight) / this.oneHourSlot
  }
  setSize() {
    this.$nextTick(() => {
      const calendarBody = this.$refs.dailyViewCalendarBody as HTMLElement
      this.mySize = {
        height: calendarBody.scrollHeight,
        width: calendarBody.clientWidth
      }
    })
  }
  mounted() {
    this.setSize()
    window.addEventListener('mouseup', this.stopDrag)
    window.addEventListener('touchend', this.stopDrag)
    const calendarBody = this.$refs.dailyViewCalendarBody as HTMLElement
    erd.listenTo(calendarBody, this.setSize)
    this.eventTree = new EventsForCalendar(this.events)
    this.eventsForDisplay = this.eventTree.eventArrayForCalendar()
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.stopDrag)
    window.removeEventListener('touchend', this.stopDrag)
    const calendarBody = this.$refs.dailyViewCalendarBody as HTMLElement
    erd.removeListener(calendarBody, this.setSize)
  }
  // For Drag
  /*
    index: hour
  */
  startDrag(event, index) {
    if (!this.selectable) {
      return
    }
    if (!this.isClickable) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: `${this.$t('message.notAllowedCalendar')}`
      })
      return
    }
    const offset = index * this.oneHourSlot * this.oneSlotPixel
    const startPositionBy30Min = Math.floor((offset + event.offsetY) / this.oneSlotPixel)
    const startTime = addMinutes(this.eventDate, startPositionBy30Min * SLOT_DURATION)
    this.newEvent = {
      start: startTime,
      initTime: startTime,
      end: addMinutes(startTime, SLOT_DURATION),
      dragStartY: event.clientY
    }
  }
  doDrag(e) {
    if (!this.newEvent) {
      return
    }
    let clientY = 0
    if (e.type === 'touchmove') {
      clientY = e.touches[0].clientY
    } else {
      clientY = e.clientY
    }
    const dragOffset = clientY - this.newEvent.dragStartY
    const offsetMin = Math.round(dragOffset / this.oneSlotPixel) * SLOT_DURATION
    if (offsetMin === 0) {
      return
    }
    if (offsetMin < 0) {
      this.newEvent.start = addMinutes(this.newEvent.initTime, offsetMin)
      this.newEvent.end = this.newEvent.initTime
    } else {
      this.newEvent.start = this.newEvent.initTime
      this.newEvent.end = addMinutes(this.newEvent.initTime, offsetMin)
    }
    e.stopPropagation()
    e.preventDefault()
    return false
  }
  disableScroll(e) {
    if (!this.newEvent) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
  }
  stopDrag(e) {
    if (!this.eventTree || !this.newEvent || !this.newEvent.end || !this.newEvent.start) {
      this.newEvent = null
      return
    }
    if (differenceInMinutes(this.newEvent.end, this.newEvent.start) < SLOT_DURATION) {
      this.newEvent = null
      return
    }
    const payload = {
      columnKey: this.column.columnKey,
      start: this.newEvent.start,
      end: this.newEvent.end,
      jsEvent: e
    }
    EventBus.emit(EVENTS.CREATE_EVENT, payload)
    this.newEvent = null
  }
}
