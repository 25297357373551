








import { Component, PropSync, Vue } from 'vue-property-decorator'
import TimeZoneKeySelectorModal from '@/components/modal/timezone/TimeZoneKeySelectorModal.vue'
import TimezoneModule from '@/store/modules/timezones'
import TimezoneLabel from '@/components/molecules/TimezoneLabel.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'

@Component({
  components: {
    FormItem,
    TimezoneLabel
  }
})
export default class FormSelectTimezone extends Vue {
  @PropSync('timeZone', { type: String }) timeZoneSync!: string

  get thisTimezone() {
    return TimezoneModule.timezoneByKey({ key: this.timeZoneSync })
  }
  selectTimezone() {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: TimeZoneKeySelectorModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        timezoneKey: this.timeZoneSync,
        selectOnlyMode: true
      },
      events: {
        close: () => {
          modal.close()
        },
        updateTimezoneInfo: (timeZone: string) => {
          this.timeZoneSync = timeZone
          this.$emit('updateObject', timeZone)
          modal.close()
        }
      }
    })
  }
}
