import { updateProfile } from '@/lib/sdk/profile'
import { sendUserInfoToExternalServices } from '@/lib/externalServices/sendUserInfoToExternalServices'
import { TimeZones, UserInfoModel, WeekDay } from '@/models/data/userInfo'
import TempLocalStorage from '@/models/localStorage/Temp'
import store from '@/store'
import TeamModule from '@/store/modules/team'
import TimezoneModule from '@/store/modules/timezones'
import UserModule from '@/store/modules/user'
import { FrontSupportLanguage } from '@/types'
import * as Sentry from '@sentry/vue'
import { cloneDeep } from 'lodash'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getProfile, updateBasicProfile } from '../../lib/api/profile'
import AvailabilityPatternListModule from './availabilityPatternList'
import { default as CalendarControl, default as CalendarControlModule } from './calendarControl'
import { getUserLanguage } from '@/lib/utils/getUserLanguage'

const tempLocalStorage = new TempLocalStorage()
export const MODULE_NAME = 'Profile'

export interface IModuleProfile {
  userInfo: UserInfoModel
}
function canLanguageUseFromBackend(): boolean {
  return UserModule.isSignIn
}
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Profile extends VuexModule {
  userInfo: UserInfoModel = null
  _language: FrontSupportLanguage = 'ja'
  _isLoading = false

  get isLoading() {
    return this._isLoading
  }
  get myProfile(): UserInfoModel {
    return this.userInfo
  }
  get arrangementSettings(): UserInfoModel['arrangementSettings'] {
    return this.userInfo.arrangementSettings
  }
  get getLanguage() {
    return this._language
  }
  get primaryTimezoneKey() {
    return this.userInfo?.timeZones ? this.userInfo?.timeZones.primary.key : null
  }

  get startWeekDayNum(): WeekDay | undefined {
    return WeekDay[this.userInfo?.startWeekDay]
  }
  @Mutation
  RESET_STATE() {
    this.userInfo = null
    this._isLoading = false
  }
  @Mutation
  SET_LOADING(isLoading) {
    this._isLoading = isLoading
  }
  @Mutation
  SET_PROFILE(profile?: UserInfoModel) {
    this.userInfo = profile
    Sentry.setUser({
      id: this.userInfo.id,
      name: this.userInfo.fullName
    })
  }
  @Mutation
  SET_LANGUAGE(newLanguageCode) {
    this._language = newLanguageCode
  }
  @Action({ commit: 'SET_LANGUAGE' })
  async updateLanguage(newLanguage) {
    tempLocalStorage.saveToLocalStorage({ language: newLanguage })
    if (canLanguageUseFromBackend()) {
      const profile = cloneDeep(this.myProfile)
      const userModel = new UserInfoModel({ ...profile, language: newLanguage })
      await this.updateProfile(userModel)
    }
    return newLanguage
  }
  @Action
  async initProfileModule() {
    const currentTimeZone = TimezoneModule.userTimezoneKey
    const currentStartWeekDayNum = this.startWeekDayNum
    if (currentStartWeekDayNum) {
      CalendarControlModule.setDefaultDate()
    }
    return new Promise((resolve) => {
      this.fetchMyProfile().then(() => {
        const userTimeZone = TimezoneModule.userTimezoneKey
        if (currentTimeZone !== userTimeZone) {
          CalendarControlModule.UpdateEventStartByChangingTimezone({
            currentTimezone: currentTimeZone,
            newTimezone: userTimeZone,
            isInit: true
          })
        }
        if (currentStartWeekDayNum !== this.startWeekDayNum) {
          CalendarControlModule.setDefaultDate()
        }
        if (!currentStartWeekDayNum) {
          resolve('init profile')
        }
      })
      if (currentStartWeekDayNum) {
        resolve('has profile')
      }
    })
  }
  @Action
  async fetchMyProfile() {
    if (!UserModule.isSignIn) {
      return
    }
    this.SET_LOADING(true)
    try {
      const profile = (await getProfile()).data
      const userModel = new UserInfoModel({ ...profile, email: UserModule.currentUser.email })
      AvailabilityPatternListModule.fetchPatterns()
      TeamModule.setTeams(userModel.joiningTeams)
      this.SET_PROFILE(userModel)
      sendUserInfoToExternalServices.signedInUser(this.myProfile)
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async getProfile(id) {
    if (!id) {
      return
    }
    this.SET_LOADING(true)
    try {
      const profile = (await getProfile(id)).data
      return profile
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  updateBasicProfile(payload: UserInfoModel) {
    this.SET_LOADING(true)
    return updateBasicProfile(payload.asParameter())
      .then(async () => await this.fetchMyProfile())
      .catch((err: any) => {
        throw new Error(err)
      })
      .finally(() => this.SET_LOADING(false))
  }
  @Action
  async updateProfile(payload) {
    this.SET_LOADING(true)
    try {
      await updateProfile(payload)
      await this.fetchMyProfile()
    } finally {
      this.SET_LOADING(false)
    }
  }
  // fixme: patch用のAPIを作ってTimezoneのみ更新できるようにする
  @Action
  async updateTimezone(payload: TimeZones) {
    const newModel = cloneDeep(this.myProfile)
    newModel.timeZones = payload
    await this.updateProfile(newModel)
  }

  @Action
  async updateStartWeek(payload: string) {
    const newModel = cloneDeep(this.myProfile)
    newModel.startWeekDay = payload
    await this.updateProfile(newModel)
    CalendarControl.setDefaultDate()
  }
  @Action({ commit: 'SET_LANGUAGE' })
  setLanguage() {
    const getMyProfileLanguge = (): FrontSupportLanguage | undefined => {
      return canLanguageUseFromBackend() ? this.myProfile.language : undefined
    }
    return getUserLanguage(getMyProfileLanguge)
  }
}

export default getModule(Profile)
