import { render, staticRenderFns } from "./OrganizerAndAttendee.vue?vue&type=template&id=591c9044&scoped=true&"
import script from "./OrganizerAndAttendee.vue?vue&type=script&lang=ts&"
export * from "./OrganizerAndAttendee.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591c9044",
  null
  
)

export default component.exports