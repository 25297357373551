import { useRoute } from 'vue2-helpers/vue-router'
import { RouteNames, AllRouteNames } from '@/router/index'
import { SessionStorage, AuthAction, AuthType, AuthFrom } from '@/store/lib/sessionStorage'

export const useAuthSessionStorage = () => {
  const route = useRoute()
  const getAuthFrom = (): AuthFrom => {
    const routeName = route.name as RouteNames
    if (
      routeName === AllRouteNames.ConfirmSchedule ||
      routeName === AllRouteNames.SuggestAlternativeDate ||
      routeName === AllRouteNames.TeamScheduleConfirm
    ) {
      return 'Schedule'
    }
    if (routeName === AllRouteNames.VotePoll) {
      return 'GroupPoll'
    }
    if (routeName === AllRouteNames.AvailabilityConfirm || routeName === AllRouteNames.TeamAvailabilitySharingConfirm) {
      return 'PublicUrl'
    }
    if (routeName === AllRouteNames.TeamMemberInvited) {
      return 'TeamMemberInvited'
    }
    if (
      routeName === AllRouteNames.SignIn ||
      routeName === AllRouteNames.SignUp ||
      routeName === AllRouteNames.AddCalendar
    ) {
      return 'BeforeAuth'
    }
    return 'TopPage'
  }
  const setSession = (payload: { action: AuthAction; type: AuthType }) => {
    const redirectFrom = (route.query['redirect'] as string) || route.fullPath
    SessionStorage.setAuthAction({
      action: payload.action,
      id: route.params.id,
      from: getAuthFrom(),
      type: payload.type,
      redirectFrom: redirectFrom // google gapiの場合は、QueryParamがすべて消えるため。
    })
  }
  return {
    setSession
  }
}
