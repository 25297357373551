



























































































import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import vClickOutside from 'v-click-outside'
import padStart from 'lodash/padStart'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import AutoCandidatesTimeRangeSettingsModal from '@/components/modal/AutoCandidatesTimeRangeSettings.vue'
import AutoCandidatesModule from '@/store/modules/autoCandidate'
import ConfirmModal from '@/components/modal/Confirm.vue'
import DateRangePicker from '@/components/molecules/DateRangePicker.vue'
import AutoCandidateModule from '@/store/modules/autoCandidate'
import { SignalType } from '@/lib/analytics/LogEntry'
import { addDays, addMonths } from '@/lib/utils/timezone'
import { getStartWeekDayForVueCalendar, weekDays } from '@/lib/utils/calendars'
import ProfileModule from '@/store/modules/profile'
@Component({
  components: {
    CommonLayoutModal,
    DateRangePicker
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
})
export default class AutoCandidatesSettingsModal extends Vue {
  @Prop() hasCandidates: boolean
  @Prop() currentDuration: number
  @Prop() separateCandidateByDuration: boolean

  currentSettings = null
  duration = 0
  availableDurations = [30, 45, 60, 90, 120, 180]
  availableInitialTimes = [
    [0, '0 min'],
    [15, '15 min'],
    [30, '30 min'],
    [45, '45 min'],
    [60, '60 min'],
    [90, '90 min']
  ]
  get sortedAvailableDays() {
    return weekDays(ProfileModule.startWeekDayNum)
  }
  get isLoading() {
    return AutoCandidateModule.isLoading
  }
  get autoCandidates() {
    return AutoCandidateModule.autoGenratedCandidates
  }
  get availablePeriods() {
    return [
      ['thisWeekend', this.$t('scheduleCreationSettings.modal.periodThisWeekend')],
      ['nextWeekend', this.$t('scheduleCreationSettings.modal.periodNextWeekend')],
      ['nextMonth', this.$t('scheduleCreationSettings.modal.periodNextMonth')],
      ['custom', this.$t('scheduleCreationSettings.modal.custom')]
    ]
  }
  get autoCandidatesSettings() {
    return AutoCandidatesModule.autoCandidatesSettings
  }
  get selectedTimeRange() {
    const nullTimeRange = {
      title: '-',
      startTime: { hour: '-', min: '-' },
      endTime: { hour: '-', min: '-' }
    }

    const timeRange = this.autoCandidatesSettings.timeRanges.find(({ selected }) => selected)
    if (timeRange) {
      timeRange.startTime.hour = padStart(timeRange.startTime.hour, 2, '0')
      timeRange.startTime.min = padStart(timeRange.startTime.min, 2, '0')
      timeRange.endTime.hour = padStart(timeRange.endTime.hour, 2, '0')
      timeRange.endTime.min = padStart(timeRange.endTime.min, 2, '0')
      return timeRange
    }
    return nullTimeRange
  }
  get minAndMaxTargetDate() {
    const today = new Date()
    const twoMonthLater = addMonths(today, 2)
    return {
      minDate: today,
      maxDate: twoMonthLater
    }
  }

  get firstDayOfWeek() {
    return getStartWeekDayForVueCalendar()
  }

  created() {
    this.currentSettings = { ...this.autoCandidatesSettings }
    this.duration = this.currentDuration
    return AutoCandidateModule.fetchAutoCandidate({
      duration: this.duration,
      separateCandidateByDuration: this.separateCandidateByDuration
    })
  }

  isDaySelected(day) {
    const daySetting = this.currentSettings.weekday.find((daySetting) => daySetting.key === day)
    return daySetting && daySetting.enabled
  }
  updateDuration() {
    AutoCandidateModule.fetchAutoCandidate({
      duration: this.duration,
      separateCandidateByDuration: this.separateCandidateByDuration
    })
  }
  updateSettings() {
    return AutoCandidatesModule.updateAutoCandidatesSettings({
      setting: this.currentSettings,
      duration: this.duration,
      separateCandidateByDuration: this.separateCandidateByDuration
    })
  }
  toggleDay(day) {
    const daySetting = this.currentSettings.weekday.find((daySetting) => daySetting.key === day)
    daySetting.enabled = !daySetting.enabled
    this.updateSettings()
  }
  changePeriodStartDate(date) {
    this.currentSettings.customPeriod.start = date
    this.updateSettings()
  }
  changePeriodEndDate(date) {
    this.currentSettings.customPeriod.end = date
    this.updateSettings()
  }
  openTimeRangeModal() {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: AutoCandidatesTimeRangeSettingsModal,
      hasModalCard: true,
      canCancel: false,
      events: {
        close: () => {
          modal.close()
        },
        updateValue: (newTimeRange) => {
          this.currentSettings.timeRanges = newTimeRange
          this.updateSettings()
        }
      },
      props: {
        orgTimeRanges: this.currentSettings.timeRanges
      }
    })
  }
  get beforeEndDate() {
    if (this.currentSettings.customPeriod.end) {
      return {
        from: new Date(this.currentSettings.customPeriod.end),
        to: addDays(new Date(), -1)
      }
    }
    return null
  }
  get aftetStartDate() {
    if (this.currentSettings.customPeriod.start) {
      return {
        to: new Date(this.currentSettings.customPeriod.start)
      }
    }
    return null
  }
  handleSave() {
    if (this.hasCandidates) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        canCancel: false,
        props: {
          header: '自動抽出の候補日に変更してもよろしいですか？',
          body: 'この操作は取り消せません。'
        },
        events: {
          close: () => {
            modal.close()
          },
          cancel: () => {
            return
          },
          confirm: async () => {
            await this.$analytics.send(SignalType.CLICK_AUTOCANDIDATE)
            this.$emit('setAutoGeneratedCandidateToSchedule', this.duration)
            this.$emit('close')
          }
        }
      })
    } else {
      this.$emit('setAutoGeneratedCandidateToSchedule', this.duration)
      this.$emit('close')
    }
  }
}
