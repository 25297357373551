






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CustomColorPaletteButton extends Vue {
  @Prop() onCustomPalletOpen: () => void
  handleClick() {
    this.onCustomPalletOpen()
  }
}
