import * as teamApis from '../api/team'
import { APIError } from './error'
import { TeamsTeamIdInvitationsPostRequest } from '@spirinc/contracts'
import { MemberCalendarRequest } from '@spirinc/contracts'

export const invitationTeamMember = async (teamId: string, payload: TeamsTeamIdInvitationsPostRequest) => {
  try {
    return await teamApis.invitationTeamMember(teamId, payload)
  } catch (e: any) {
    if (e.response) {
      throw new APIError(e.response.status, e.response.data, e.response)
    }
    throw e
  }
}
export const acceptInvitation = async (
  teamId: string,
  invitationId: string,
  token: string,
  calendars: MemberCalendarRequest[]
) => {
  try {
    return await teamApis.acceptInvitation(teamId, invitationId, token, calendars)
  } catch (e: any) {
    if (e.response) {
      throw new APIError(e.response.status, e.response.data, e.response)
    }
    throw e
  }
}
export const cancelInvitation = async (teamId: string, invitationId: string) => {
  try {
    return await teamApis.cancelInvitation(teamId, invitationId)
  } catch (e: any) {
    if (e.response) {
      throw new APIError(e.response.status, e.response.data, e.response)
    }
    throw e
  }
}
export const resendInvitation = async (teamId: string, invitationId: string) => {
  try {
    return await teamApis.resendInvitation(teamId, invitationId)
  } catch (e: any) {
    if (e.response) {
      throw new APIError(e.response.status, e.response.data, e.response)
    }
    throw e
  }
}
