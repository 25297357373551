import { defineComponent } from '@vue/composition-api'
import { useConfirmation } from '@/composables/useConfirmation'

// TODO: PrivateScheduleConfirm.vueがcomposition API化できれば必要がなくなる
// ScheduleMixinとConfirmMixinがコンフリクトしていたの、コンフリクトしないものを作成
export default defineComponent({
  setup() {
    const {
      firstDate,
      showOverview,
      noConnectedAccount,
      handleProceedToNext,
      initCommonProcess,
      setCalendarDate,
      handleMouseLeave,
      handleEventHover,
      updateConfirmEvent,
      updateConfirmEventByMouseEvent
    } = useConfirmation()

    return {
      firstDate,
      showOverview,
      noConnectedAccount,
      handleProceedToNext,
      initCommonProcess,
      setCalendarDate,
      handleMouseLeave,
      handleEventHover,
      updateConfirmEvent,
      updateConfirmEventByMouseEvent
    }
  }
})
