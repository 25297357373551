















import ConfirmedAllListBox from '@/components/adjustmentList/ConfirmedAllListBox.vue'
import ConfirmedScheduleEmptyList from '@/components/adjustmentList/ConfirmedScheduleEmptyList.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import CalendarFilterButton from '@/components/ui/CalendarFilterButton.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import { ConfirmedTabContentType, FilteringCalendarInfo } from '@/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  components: {
    LoadingSpinner,
    ConfirmedAllListBox,
    CalendarFilterButton,
    ConfirmedScheduleEmptyList,
    ListItemSpacer
  },
  props: {
    filteringCalendars: {
      type: Array as PropType<Array<FilteringCalendarInfo>>,
      default: () => []
    },
    content: {
      type: Object as PropType<ConfirmedTabContentType>,
      required: true
    }
  },
  setup(props) {
    const { content } = toRefs(props)
    const infos = computed(() => {
      return content.value.cards.infos
    })
    const isEmpty = computed(() => content.value.cards.isEmpty)
    return {
      isEmpty,
      infos
    }
  }
})
