






















import PanelHeaderButton from '@/components/atoms/PanelHeaderButton.vue'
import AvatarWithName from '@/components/patterns/molecules/AvatarWithName.vue'
import SidePanelLayout from '@/components/sidePanels/edit/Layout.vue'
import { GoogleEvent } from '@/types'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import BasicInfoSection from './panels/BasicInfoSection.vue'

@Component({
  components: {
    SidePanelLayout,
    ValidationObserver,
    PanelHeaderButton,
    AvatarWithName,
    BasicInfoSection
  }
})
export default class EventNewFormCommon extends Vue {
  @PropSync('editingObject') editingObjectSynced!: GoogleEvent
  @Prop({ default: true }) isLoading: boolean
  @Prop() name: string
  @Prop() photoUrl: string
  @Prop() timeZone: string | undefined
  @Prop({ default: false }) edit: boolean
  @Prop({ required: true }) onDateUpdate: (data: { start: Date; end: Date }) => void

  get existCandidates(): boolean {
    return !!this.editingObjectSynced
  }

  handleDateUpdate(data: { start: Date; end: Date }) {
    this.onDateUpdate(data)
  }

  updateEditingObject() {
    this.$emit('updateEditingObject')
  }
}
