import { useScheduleMixin } from '@/composables/useScheduleMixin'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const {
      isNarrow,
      isScheduleEventListOpen,
      showControlWidget,
      isValid,
      getEditingEventByCalendarFormat,
      isSyncing,
      scheduleId,
      toggleScheduleEventsList,
      toggleViewOrConfirm,
      showToastIfScheduleIsSyncing,
      addNewCandidate
    } = useScheduleMixin()

    return {
      isNarrow,
      isScheduleEventListOpen,
      showControlWidget,
      isValid,
      getEditingEventByCalendarFormat,
      isSyncing,
      scheduleId,
      toggleScheduleEventsList,
      toggleViewOrConfirm,
      showToastIfScheduleIsSyncing,
      addNewCandidate
    }
  }
})
