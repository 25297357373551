


















import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import SignInButtons from '@/components/auth/SignInButtons.vue'
import AddCalendarDescription from './AddCalendarDescription.vue'
import SpirButton from '@/components/ui/Button.vue'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics/LogEntry'

export default defineComponent({
  props: {
    signOut: {
      type: Function
    },
    addCalendar: {
      type: Function,
      required: true
    },
    title: {
      type: String
    },
    errorCode: {
      type: String
    },
    authProvider: {
      type: String as PropType<'google' | 'microsoft'>,
      required: true
    }
  },
  components: {
    SpirButton,
    AuthFormLayout,
    SignInButtons,
    AuthFormSpacer,
    AddCalendarDescription
  },
  setup(props) {
    const i18n = useTranslation()
    const { errorCode } = toRefs(props)
    const errorString = computed(() => {
      if (!errorCode.value) {
        return null
      }
      if (i18n.tc(`message.error.addCalendar.${errorCode.value}`)) {
        return i18n.tc(`message.error.addCalendar.${errorCode.value}`)
      }
      return i18n.tc('message.errorCommon')
    })
    return {
      errorString,
      signalObject: {
        signal: SignalType.CLICK_ADD_INITIAL_CALENDAR,
        payload: {
          type: props.authProvider
        }
      }
    }
  }
})
