

























































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { PollAnswer, FullCalendarEvent, ISchedule } from '@/types'
import AppModule from '@/store/modules/app'
import PreviewModule from '@/store/modules/preview'
import CalendarLayout from '@/components/layout/CalendarForPreview.vue'
import Tooltip from '@/components/molecules/Tooltip.vue'
import RequestOtherOptionButton from '@/components/schedule/RequestOtherOptionButton.vue'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import PrivateVoteOperationBox from '@/components/groupPoll/PrivateVoteOperationBox.vue'
import { getHourByUserTimezone } from '@/lib/utils/dateFormat'

@Component({
  components: {
    CalendarLayout,
    Tooltip,
    RequestOtherOptionButton,
    ConfirmPageHeader,
    PrivateVoteOperationBox
  }
})
export default class PreviewScheduleModal extends Vue {
  @PropSync('open', { type: Boolean }) openSynced!: boolean
  @Prop() onClose?: () => void
  @Prop() schedule: ISchedule
  @Prop() candidates: FullCalendarEvent[]

  isPreview = true
  currentPreviewVotes: { [key: string]: PollAnswer } = {}

  mounted() {
    PreviewModule.initPreview(this.candidates[0].start)
  }

  get previewVotes() {
    return this.candidates.map((c) => {
      return {
        candidateId: c.id,
        answer: this.currentPreviewVotes[c.id] ? this.currentPreviewVotes[c.id] : 'no'
      }
    })
  }
  get additionalEvents() {
    return this.candidates.map((event: FullCalendarEvent) => ({
      ...event,
      editable: false,
      extendedProps: {
        ...event.extendedProps,
        source: this.currentPreviewVotes[event.id] === 'yes' ? 'pollAnswerYes' : 'pollAnswerNo'
      }
    }))
  }
  get getFirstHour() {
    if (this.candidates.length === 0) {
      return 0
    }
    return getHourByUserTimezone(this.candidates[0].start)
  }
  get isAllAnswerNo() {
    return !this.previewVotes.some((v) => v.answer === 'yes')
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  handleClose() {
    this.openSynced = false
  }
  handleClickedCandidate({ event }: { event: FullCalendarEvent; jsEvent: MouseEvent; el: HTMLElement }) {
    if (this.currentPreviewVotes[event.id]) {
      this.currentPreviewVotes = {
        ...this.currentPreviewVotes,
        [event.id]: this.currentPreviewVotes[event.id] === 'yes' ? 'no' : 'yes'
      }
      return
    }
    this.currentPreviewVotes = {
      ...this.currentPreviewVotes,
      [event.id]: 'yes'
    }
  }
  handleUpdateAnswer(candidateId: string, newAnswer: PollAnswer) {
    this.currentPreviewVotes = {
      ...this.currentPreviewVotes,
      [candidateId]: newAnswer
    }
  }
  onScheduleConfirmButtonClick(message: string) {
    this.$buefy.toast.open({
      type: 'is-danger',
      position: 'is-bottom',
      message
    })
  }
}
