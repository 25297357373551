import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { EventModel } from '@/models/data/event'
import { getConfirmedEvent } from '@/lib/api/scheduleTeam'

type confirmedEvent = {
  [key: string]: {
    event?: EventModel
  }
}

const encodeKey = (teamId: string, scheduleId: string) => {
  return `${teamId}$$$${scheduleId}`
}

const MODULE_NAME = 'ConfirmedScheduleEvent'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class ConfirmedScheduleEvent extends VuexModule {
  confirmedEventList: confirmedEvent = {}
  _isLoading = false
  get isLoading() {
    return this._isLoading
  }
  get confirmedEventByTeamAndScheduleId() {
    return (teamId: string, scheduleId: string) => {
      const key = encodeKey(teamId, scheduleId)
      return this.confirmedEventList[key]
    }
  }
  @Action
  async getConfirmedEvent(payload: { teamId: string; scheduleId: string }) {
    const { teamId, scheduleId } = payload
    const findFromState = this.confirmedEventByTeamAndScheduleId(teamId, scheduleId)
    if (findFromState?.event) {
      return findFromState.event
    }
    const key = encodeKey(teamId, scheduleId)
    this.SET_LOADING(true)
    try {
      const response = await getConfirmedEvent(teamId, scheduleId)
      const newEvent = new EventModel(response)
      this.SET_CONFIRMED_EVENT({ key, body: { event: newEvent } })
      return newEvent
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Mutation
  SET_LOADING(loadingStatus: boolean) {
    this._isLoading = loadingStatus
  }
  @Mutation
  SET_CONFIRMED_EVENT(payload: {
    key: string
    body: {
      event?: EventModel
    }
  }) {
    this.confirmedEventList = {
      ...this.confirmedEventList,
      [payload.key]: payload.body
    }
  }
}

export default getModule(ConfirmedScheduleEvent)
