














































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IPoll, ISchedule, CandidateVote, ISchedulePollCommon, ProfileOnOverview, SpirAttendeeStatus } from '@/types'
import CommonLayoutModal from './CommonLayout.vue'
import { differenceInMinutes, parseISO } from 'date-fns'
import LocationRead from '../molecules/LocationRead.vue'
import HtmlDisplay from '@/components/molecules/HtmlDisplay.vue'
import { ListType } from '@/store/modules/listPanel'
import candidateDates from '@/filters/candidateDates'
import oneDateRange from '@/filters/oneDateRange'
import VoteStatus from '@/components/groupPoll/VoteStatus.vue'
import AttendeeWithProfileButton from '@/components/molecules/AttendeeWithProfileButton.vue'
import CalendarsModule from '@/store/modules/calendars'
import ConfirmModal from '@/components/modal/Confirm.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import ShareButton from '@/components/molecules/ShareButton.vue'

interface IScheduleForm {
  readonly schedule: ISchedulePollCommon
  readonly isSyncing
  isValidCandidate(candidateId: string): boolean
  getId(): string
  readonly currentCandidate
  readonly dateRangeDisplay
  readonly attendees
}

// todo: これらはModelに移すべき。セットするタイミングも、BackendからGetしたらすぐに作るべき。
class ScheduleForm implements IScheduleForm {
  _schedule: ISchedule
  _candidateId: string
  constructor(schedule: ISchedule, candidateId: string) {
    this._schedule = schedule
    this._candidateId = candidateId
  }
  get schedule() {
    return this._schedule
  }
  get attendees() {
    // todo: scheduleをModel化してこういうコードはすべて削除すべき
    return this.currentCandidate && this.currentCandidate.attendees
      ? this.currentCandidate.attendees.filter(
          (attendee) => this.schedule.attendees.findIndex((a) => a.email === attendee.email) >= 0
        )
      : this.schedule.attendees || []
  }
  get dateRangeDisplay() {
    return candidateDates(this.schedule)
  }
  get isSyncing() {
    return !this.schedule.processed
  }
  get currentCandidate() {
    const candidates = this.schedule.candidates
    return candidates.find((c) => c.id === this._candidateId)
  }
  getId() {
    return this.schedule.id
  }
  isValidCandidate(candidateId) {
    const currentCandidate = this.schedule.candidates.find((c) => c.id === candidateId)
    if (!currentCandidate) {
      return false
    }
    return currentCandidate.status === 'suggestedByOrganizer' || currentCandidate.status === 'suggestedByConfirmer'
  }
}
class PollForm implements IScheduleForm {
  _schedule: IPoll
  _candidateId: string
  _currentCandidate: CandidateVote
  constructor(schedule: IPoll, candidateId: string) {
    this._schedule = schedule
    this._candidateId = candidateId
    const candidates: CandidateVote[] = this.schedule.candidates as CandidateVote[]
    const current = candidates.find((c) => c.id === this._candidateId)
    this._currentCandidate = current
  }
  get currentCandidate() {
    return this._currentCandidate
  }
  get schedule() {
    return this._schedule
  }
  get isSyncing() {
    return false
  }
  get attendees() {
    return this.schedule.attendees
  }
  get dateRangeDisplay() {
    return oneDateRange({
      startDate: parseISO(this.currentCandidate.start),
      endDate: parseISO(this.currentCandidate.end)
    })
  }
  getId() {
    return this._schedule.id
  }
  isValidCandidate(candidateId) {
    return true
  }
}

@Component({
  components: {
    CommonLayoutModal,
    LocationRead,
    AttendeeWithProfileButton,
    HtmlDisplay,
    VoteStatus,
    ShareButton
  }
})
export default class ScheduleFormModal extends Vue {
  @Prop() currentSchedule: IPoll | ISchedule
  @Prop() candidateId: string
  @Prop() type: ListType

  displayMode = true
  schedule: IScheduleForm = null

  created() {
    if (this.type === 'schedule') {
      this.schedule = new ScheduleForm(this.currentSchedule as ISchedule, this.candidateId)
    } else {
      this.schedule = new PollForm(this.currentSchedule as IPoll, this.candidateId)
    }
  }
  get dateRangeDisplay() {
    return this.schedule.dateRangeDisplay
  }
  get thisSchedule() {
    return this.schedule?.schedule
  }
  get organizer() {
    return this.thisSchedule.author
  }
  get attendees() {
    return this.thisSchedule?.attendees
  }
  get isSyncing() {
    return this.schedule.isSyncing
  }
  get minimumCandidate() {
    let minimunMinutes = 180
    this.currentSchedule.candidates.forEach((candiate) => {
      const diff = differenceInMinutes(new Date(candiate.end), new Date(candiate.start))
      if (diff < minimunMinutes) {
        minimunMinutes = diff
      }
    })
    return minimunMinutes
  }
  get isValidSchedule() {
    return this.schedule.isValidCandidate
  }
  get isValidCandidate() {
    return this.schedule.isValidCandidate(this.candidateId)
  }
  get showAttendee() {
    return this.type === ListType.SCHEDULE
  }
  showInputField(fieldName) {
    return !this.displayMode || !!this.currentSchedule[fieldName]
  }

  get isSchedule() {
    return this.type === 'schedule'
  }
  get showVote() {
    return this.type === 'poll' && !!this.candidateForVoteInfo
  }
  get candidateForVoteInfo() {
    const currentCandidate: CandidateVote = this.schedule.currentCandidate
    if (!currentCandidate) {
      return null
    }
    const yesCount = currentCandidate.votes.filter((v) => v.answer === 'yes').length
    const noCount = currentCandidate.votes.filter((v) => v.answer === 'no').length
    return {
      yesCount,
      noCount,
      votes: currentCandidate.votes
    }
  }
  get accountEmail() {
    const accounts = CalendarsModule._accountWithcalendars.filter((a) => a.accountId === this.currentSchedule.accountId)
    if (accounts.length === 0) {
      return ''
    }
    return accounts[0].email
  }
  get showAttendees(): ProfileOnOverview[] {
    const retValue: ProfileOnOverview[] = []
    retValue.push({
      fullName: this.organizer.name || this.organizer.fullName,
      photoURL: this.organizer.photoURL,
      email: this.accountEmail,
      userId: this.organizer.id,
      isAuthor: true
    })
    this.attendees.forEach((a) => {
      retValue.push({
        fullName: a.fullName,
        photoURL: a.photoURL,
        email: a.email,
        userId: a.id,
        isAuthor: false,
        attendanceStatus: a.responseStatus || SpirAttendeeStatus.TENTATIVE
      })
    })
    return retValue
  }
  get calendarName() {
    return CalendarsModule.getCalendarName({
      accountId: this.currentSchedule.accountId,
      calendarId: this.currentSchedule.calendarId
    })
  }
  handleEditSchedule() {
    if (this.thisSchedule.status === 'suggestedByConfirmer') {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        canCancel: true,
        props: {
          header: this.$t('alternativeCandidates.confirmationModal.title').toString(),
          body: this.$t('alternativeCandidates.confirmationModal.body').toString(),
          confirmBtn: this.$t('buttons.showConfirmation').toString()
        },
        events: {
          confirm: () => {
            this.$emit('confirmSchedule')
          }
        }
      })
    } else {
      this.$emit('editSchedule')
    }
  }
  async handleClickProfile() {
    await this.$analytics.send(SignalType.SEE_PROFILE_CONFIRM_MODAL)
  }
}
