import { getCurrentInstance } from '@vue/composition-api'
import { SignalRequestSender } from '@/lib/analytics/signal'

export const useAnalytics = (): SignalRequestSender => {
  const inst = getCurrentInstance()
  if (inst) {
    return inst.proxy.$analytics as SignalRequestSender
  }
  return undefined
}
