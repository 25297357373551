







import { Component, ProvideReactive, Vue } from 'vue-property-decorator'
import PrivateCalendarList from '@/components/calendarList/organisms/PrivateCalendarList.vue'
import TeamCalendarList from '@/components/calendarList/organisms/TeamCalendarList.vue'
import AppModule from '@/store/modules/app'

@Component({
  components: {
    PrivateCalendarList,
    TeamCalendarList
  }
})
export default class CalendarMenu extends Vue {
  @ProvideReactive()
  get isNarrow() {
    return AppModule.isLargeTabletWidth
  }
}
