import { useToast } from '../useToast'
import { AuthErrorTypes, DicAuthErrorAndi18nKey } from '@/types/authError'
import { useTranslation } from '../useTranslation'

export const useAuthErrorToast = () => {
  const { openDangerBottomToast } = useToast()
  const i18n = useTranslation()
  const showAuthErrorToast = (authErrorCode: AuthErrorTypes) => {
    openDangerBottomToast({ message: i18n.t(DicAuthErrorAndi18nKey(authErrorCode)).toString() })
  }
  const showErrorToast = () => {
    openDangerBottomToast({ message: i18n.t('message.errorCommon').toString() })
  }
  return {
    showAuthErrorToast,
    showErrorToast
  }
}
