import { ErrorState } from './../components/ui/spir/textErrorState'

// ValidationProviderのv-slotで取得できるobjectの型が公式にimportできなさそうなので作成
// ref: https://vee-validate.logaretm.com/v3/api/validation-provider.html#scoped-slot-props
// 必要なkeyは上を参照して追加
type VeeSlot = {
  errors: string[]
  valid: boolean | undefined
  validated: boolean
  touched: boolean
  dirty: boolean
}

export const useVeeValidationProviderSlot = () => {
  return {
    makeErrorMessage: (veeSlot: VeeSlot): ErrorState => {
      const isValid = veeSlot.valid
      const isDirty = veeSlot.touched || veeSlot.dirty || veeSlot.validated
      if (isValid || !isDirty) {
        return { type: 'no' }
      }
      return { type: 'hasError', text: veeSlot.errors[0] }
    }
  }
}
