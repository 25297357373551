import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { ICalendarListForUI, ITypeCalendarListForUI } from '../../types'
import * as calendarAPI from '@/lib/api/calendar'
import UserModule from './user'
import DailyViewModule from './dailyView'
import { PersonalCalendar, Calendar } from '@spirinc/contracts'
import TeamCalendarModule from './teamCalendar'
import EditAvailabilityModule from './editAvailability'
import EditAvailabilityTeamModule from './editAvailabilityTeam'

const KEY_SPLITTER = '$$$'

export const encodeCalendarKeyByAccountIdAndCalendarId = (accountId: string, calendarId: string) => {
  return [accountId, calendarId].join(KEY_SPLITTER)
}

export const decodeCalendarKey = (calendarKey: string) => {
  const split = calendarKey.split(KEY_SPLITTER)
  return {
    accountId: split[0],
    calendarId: split[1]
  }
}
const CONTACT_CALENDAR_ID = 'addressbook#contacts@group.v.calendar.google.com'

const MODULE_NAME = 'Calendars'

export type PrivateCalendar = Omit<PersonalCalendar, 'calendars'> & {
  calendars: Array<Calendar & { isLoading?: boolean }>
}
export interface IModuleCalendar {
  _accountWithcalendars: PrivateCalendar[]
  _isCalendarLoading: boolean
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Calendars extends VuexModule {
  _accountWithcalendars: PrivateCalendar[] = []
  _isCalendarLoading = false
  _hasInit = false

  get hasAccount() {
    return this.getAccountWithcalendars && this.getAccountWithcalendars.length > 0
  }
  get getIsCalendarLoading() {
    return this._isCalendarLoading
  }
  get getAccountWithcalendars() {
    return this._accountWithcalendars
  }
  get getAccountWithCalendarsButNotResource() {
    return this.getAccountWithcalendars.map((ac) => {
      return {
        ...ac,
        calendars: ac.calendars.filter((acc) => {
          return (
            acc.writable &&
            (acc.type === 'normal' || (acc.type === 'group' && acc.owner && acc.id !== CONTACT_CALENDAR_ID))
          )
        })
      }
    })
  }
  get getAccountWithPrimaryCalendar() {
    return this.getAccountWithcalendars.map((ac) => {
      return {
        ...ac,
        calendars: ac.calendars.filter((acc) => acc.primary)
      }
    })
  }
  get flattenCalendars(): ICalendarListForUI {
    const calendars: ICalendarListForUI = {}
    this.getAccountWithcalendars
      .filter((account) => !account.isAccessTokenInvalid)
      .forEach((account) => {
        account.calendars.forEach((calendar) => {
          const key = encodeCalendarKeyByAccountIdAndCalendarId(account.accountId, calendar.id)
          calendars[key] = {
            ...calendar,
            key,
            accountId: account.accountId,
            accountName: account.userName,
            calendarId: calendar.id,
            email: account.email
          }
        })
      })
    return calendars
  }
  get flattenCalendarsArray(): ITypeCalendarListForUI[] {
    const calendars: ITypeCalendarListForUI[] = []
    this.getAccountWithcalendars.forEach((account) => {
      account.calendars.forEach((calendar) => {
        const key = encodeCalendarKeyByAccountIdAndCalendarId(account.accountId, calendar.id)
        calendars.push({
          ...calendar,
          key,
          accountId: account.accountId,
          accountName: account.userName,
          calendarId: calendar.id,
          email: account.email
        })
      })
    })
    return calendars
  }
  get primaryCalendars(): ITypeCalendarListForUI[] {
    const primaryCalendars = []
    Object.keys(this.flattenCalendars).forEach((calendarKey) => {
      const calendar = this.flattenCalendars[calendarKey]
      if (calendar.primary) {
        primaryCalendars.push(calendar)
      }
    })
    return primaryCalendars
  }
  get writableCalendars(): ITypeCalendarListForUI[] {
    const writableCalendars: ITypeCalendarListForUI[] = []
    Object.keys(this.flattenCalendars).forEach((calendarKey) => {
      const calendar = this.flattenCalendars[calendarKey]
      if (calendar.writable) {
        writableCalendars.push(calendar)
      }
    })
    return writableCalendars
  }
  get allWritableCalendarsButNotResource(): ITypeCalendarListForUI[] {
    return this.flattenCalendarsArray.filter((calendar: ITypeCalendarListForUI) => {
      return calendar.writable && calendar.type !== 'resource'
    })
  }
  get allCalendarsButNotResource(): ITypeCalendarListForUI[] {
    return this.flattenCalendarsArray.filter((calendar: ITypeCalendarListForUI) => {
      return (
        calendar.type === 'normal' ||
        (calendar.type === 'group' && calendar.owner && calendar.id !== CONTACT_CALENDAR_ID)
      )
    })
  }
  get visibleCalendarsKeys(): string[] {
    // Team公開URLの編集モードでは、普通Calendarは表示しない。
    if (EditAvailabilityTeamModule.showMemberCalendars) {
      return []
    }
    if (EditAvailabilityModule.showCalendars) {
      return EditAvailabilityModule.showCalendars.map((c) =>
        encodeCalendarKeyByAccountIdAndCalendarId(c.accountId, c.calendarId)
      )
    }
    return Object.keys(this.flattenCalendars).filter((key) => {
      return !!this.flattenCalendars[key].visible
    })
  }
  get isVisibleByAccountAndCalendarId() {
    return (accountId: string, calendarId: string) => {
      const calendarKey = encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId)
      return this.visibleCalendarsKeys.indexOf(calendarKey) >= 0
    }
  }
  get getCalendarName() {
    return ({ accountId, calendarId }) => {
      const key = encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId)
      return this.flattenCalendars[key] ? this.flattenCalendars[key].title : ''
    }
  }
  get accountInfo() {
    return (accountId) => {
      return this.getAccountWithcalendars.find((a) => a.accountId === accountId)
    }
  }
  get getCalendar() {
    return (data: { accountId: string; calendarId: string }) => {
      return this.accountInfo(data.accountId)?.calendars?.find((c) => c.id === data.calendarId)
    }
  }
  get getCalendarsByEmail() {
    return (email: string): ITypeCalendarListForUI[] => {
      return this.flattenCalendarsArray.filter((ca) => ca.invitationAddress === email)
    }
  }
  @Action
  async initCalendars() {
    if (this.hasAccount) {
      this.fetchCalendars()
      return Promise.resolve()
    } else {
      return await this.fetchCalendars()
    }
  }
  @Action
  async fetchCalendars() {
    this.SET_CALENDAR_LOADING(true)
    try {
      if (UserModule.isSignIn) {
        const response = await calendarAPI.getCalendars()
        this.ON_GET_CALENDARS(response.personal)
        TeamCalendarModule.SET_TEAM_CALENDARS(response.teams)
      }
    } finally {
      this.SET_CALENDAR_LOADING(false)
    }
  }
  @Action
  visibleCalendarIfNotVisible({ accountId, calendarId }) {
    const visibleCalendars = this.visibleCalendarsKeys
    const calendarKey = encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId)
    if (visibleCalendars.indexOf(calendarKey) < 0) {
      this.toggleCalendar({ accountId, calendarId, visible: true })
    }
  }
  @Action
  async toggleCalendar(payload: { accountId: string; calendarId: string; visible?: boolean }) {
    const { accountId, calendarId, visible } = payload
    const nextVisibleStatus =
      visible !== undefined ? visible : !this.isVisibleByAccountAndCalendarId(accountId, calendarId)
    try {
      this.ON_CALENDAR_VISIBLE({
        accountId,
        calendarId,
        visible: nextVisibleStatus,
        isLoading: true
      })
      await calendarAPI.updateCalendar(accountId, calendarId, { visible: nextVisibleStatus })
      this.ON_CALENDAR_VISIBLE({
        accountId,
        calendarId,
        isLoading: false
      })
      DailyViewModule.fetchCalendarsForDailyView()
    } catch (e) {
      this.ON_CALENDAR_VISIBLE({
        accountId,
        calendarId,
        visible: nextVisibleStatus,
        isLoading: false
      })
    }
  }
  @Action
  async setCalendarBackgroundColor({ accountId, calendarId, backgroundColor }) {
    this.SET_CALENDAR_BACKGROUND_COLOR({
      accountId,
      calendarId,
      isLoading: true
    })
    try {
      const calendar = await calendarAPI.updateCalendar(accountId, calendarId, { backgroundColor })
      this.SET_CALENDAR_BACKGROUND_COLOR({
        accountId,
        calendarId,
        backgroundColor,
        foregroundColor: calendar.foregroundColor,
        isLoading: false
      })
      DailyViewModule.fetchCalendarsForDailyView()
    } catch (e) {
      this.SET_CALENDAR_BACKGROUND_COLOR({
        accountId,
        calendarId,
        isLoading: false
      })
    }
  }

  @Action
  async fetchAfterAddCalendar() {
    this.SET_CALENDAR_LOADING(true)
    try {
      await Promise.all([this.fetchCalendars(), DailyViewModule.fetchCalendarsForDailyView()])
    } finally {
      this.SET_CALENDAR_LOADING(false)
    }
  }
  @Mutation
  SET_CALENDAR_LOADING(isLoading: boolean) {
    this._isCalendarLoading = isLoading
  }
  @Mutation
  ON_GET_CALENDARS(accountWithcalendars: PersonalCalendar[]) {
    this._accountWithcalendars = accountWithcalendars
  }
  @Mutation
  ON_CALENDAR_VISIBLE({
    accountId,
    calendarId,
    visible,
    isLoading
  }: {
    accountId: string
    calendarId: string
    visible?: boolean
    isLoading?: boolean
  }) {
    const accountIndex = this._accountWithcalendars.findIndex((ac) => ac.accountId === accountId)
    if (accountIndex < 0) {
      return
    }
    const account = this._accountWithcalendars[accountIndex]
    const calendarIndex = account.calendars.findIndex((c) => c.id === calendarId)
    if (calendarIndex < 0) {
      return
    }
    const calendar = account.calendars[calendarIndex]
    this._accountWithcalendars[accountIndex].calendars.splice(calendarIndex, 1, {
      ...calendar,
      visible: visible ?? calendar.visible,
      isLoading: isLoading ?? calendar.isLoading
    })
  }

  @Mutation
  SET_CALENDAR_BACKGROUND_COLOR({
    accountId,
    calendarId,
    backgroundColor,
    foregroundColor,
    isLoading
  }: {
    accountId: string
    calendarId: string
    backgroundColor?: string
    foregroundColor?: string
    isLoading?: boolean
  }): string | undefined {
    const accountIndex = this._accountWithcalendars.findIndex((ac) => ac.accountId === accountId)
    if (accountIndex < 0) {
      return
    }
    const account = this._accountWithcalendars[accountIndex]
    const calendarIndex = account.calendars.findIndex((c) => c.id === calendarId)
    if (calendarIndex < 0) {
      return
    }
    const calendar = account.calendars[calendarIndex]
    this._accountWithcalendars[accountIndex].calendars.splice(calendarIndex, 1, {
      ...calendar,
      backgroundColor: backgroundColor ?? calendar.backgroundColor,
      foregroundColor: foregroundColor ?? calendar.foregroundColor,
      isLoading: isLoading ?? calendar.isLoading
    })
    return calendar.backgroundColor
  }

  @Mutation
  RESET_STATE() {
    this._accountWithcalendars = []
    this._isCalendarLoading = false
  }
}

export default getModule(Calendars)
