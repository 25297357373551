import { LocalStorage } from '@/models/localStorage/localStorage'
import { AvailabilityModelForPrivate } from '@/models/data/availability'
const LOCAL_STORAGE_KEY_AVAILABILITY = 'LOCAL_STORAGE_KEY_AVAILABILITY'

export default class AvailabilityStorage extends LocalStorage {
  constructor() {
    super(LOCAL_STORAGE_KEY_AVAILABILITY, ['organizer', 'duration', 'onlineMeeting', 'start', 'end'])
  }
  getSavedAttrs(): Partial<
    Pick<AvailabilityModelForPrivate, 'organizer' | 'duration' | 'onlineMeeting' | 'start' | 'end'>
  > {
    const getFromLocalStorage = this.loadFromLocalStorage()
    return {
      organizer: getFromLocalStorage?.organizer,
      duration: getFromLocalStorage?.duration,
      onlineMeeting: getFromLocalStorage?.onlineMeeting,
      start: getFromLocalStorage?.start,
      end: getFromLocalStorage?.end
    }
  }
}
