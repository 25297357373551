


































import debounce from 'lodash/debounce'
import Button from '@/components/ui/Button.vue'
import { defineComponent, ref, toRefs } from '@vue/composition-api'
import { LocationResult } from './location'
import { useLocationSearch } from './_useLocationSearch'

export default defineComponent({
  name: 'LocationInput',
  components: {
    Button
  },
  props: {
    location: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { location } = toRefs(props)
    const { searchLocation, openGoogleMaps } = useLocationSearch()

    const preSearchKey = ref<string>(null)
    const locationResults = ref<LocationResult[]>([])

    const isSearching = ref(false)
    const searchLocationDebounce = debounce(async (locationKeyWord) => {
      isSearching.value = true
      try {
        const { searchKey, results } = await searchLocation(locationKeyWord, preSearchKey.value)
        preSearchKey.value = searchKey
        locationResults.value = results
      } finally {
        isSearching.value = false
      }
    }, 300)

    function handleInput(newLocation?: string) {
      if (newLocation !== undefined) {
        emit('input', newLocation)
      }
    }

    function handleResultSelect(result?: LocationResult) {
      if (result) {
        emit('input', `${result.title}, ${result.description}`)
      }
    }

    function handleMapClick() {
      openGoogleMaps(location.value)
    }

    return {
      locationResults,
      searchLocationDebounce,
      isSearching,
      handleInput,
      handleResultSelect,
      handleMapClick
    }
  }
})
