






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirHelperText',
  props: {
    text: {
      type: String
    },
    type: {
      type: String as PropType<'normal' | 'danger'>,
      default: 'normal'
    }
  }
})
