














import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarColorSwatch from '../atoms/CalendarColorSwatch.vue'
import CalendarColorSwatchLayout from '../atoms/CalendarColorSwatchLayout.vue'

@Component({
  components: {
    CalendarColorSwatch,
    CalendarColorSwatchLayout
  }
})
export default class PreparedCalendarColors extends Vue {
  @Prop({ default: false }) isNarrow: boolean
  @Prop({ default: [] }) colors: { value: string; isCurrent: boolean }
  @Prop() onColorSelect: (color: string) => void

  handleSelect(color) {
    this.onColorSelect(color)
  }
}
