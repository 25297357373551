

























import { Component, Prop, Vue } from 'vue-property-decorator'
import ScheduleConfirmOperationBox from '@/components/sidePanels/confirm/schedule/ScheduleConfirmOperationBox.vue'
import ConfirmSidePanelFrame from '@/components/sidePanels/confirm/atoms/ConfirmSidePanelFrame.vue'
import ConfirmOperationFrame from '@/components/sidePanels/confirm/atoms/ConfirmOperationFrame.vue'
import ConfirmSidePanelFooterFrame from '@/components/sidePanels/confirm/atoms/ConfirmSidePanelFooterFrame.vue'
import ScheduleSidePanelFooter from '@/components/sidePanels/confirm/schedule/ScheduleSidePanelFooter.vue'
import { FullCalendarEvent, SpirUser } from '@/types'
import { ScheduleModel } from '@/models/data'
import CalendarControlModule from '@/store/modules/calendarControl'
import { SignalType } from '@/lib/analytics/LogEntry'

@Component({
  components: {
    ScheduleConfirmOperationBox,
    ConfirmSidePanelFrame,
    ConfirmOperationFrame,
    ConfirmSidePanelFooterFrame,
    ScheduleSidePanelFooter
  }
})
export default class ScheduleConfirmSidePanelBox extends Vue {
  @Prop() candidates: FullCalendarEvent[]
  @Prop() authorInfo: SpirUser
  @Prop() schedule: ScheduleModel
  @Prop({ default: true }) isOtherRequestOpen: boolean
  @Prop({ default: false }) isSignIn: boolean
  @Prop({ default: false }) isPreview: boolean
  @Prop({ default: false }) isOrganizer: boolean
  @Prop({ default: false }) isSyncing: boolean
  @Prop() onCandidateSelect: (payload: { start: Date; end: Date; candidateId: string }) => Promise<void>
  @Prop() onCandidateHover: (payload: { start: Date; end: Date; candidateId: string }) => Promise<void>

  get pageId() {
    return this.$route.params.id
  }

  async handleCandidateSelect(candidate: FullCalendarEvent) {
    if (this.isPreview) return
    const { start, end, id: candidateId } = candidate
    CalendarControlModule.setCurrentDate(new Date(start))
    await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_PANEL, { id: this.pageId })
    this.onCandidateSelect({ start, end, candidateId })
  }
  async handleCandidateHover(candidate: FullCalendarEvent) {
    if (this.isPreview) return
    const { start, end, id: candidateId } = candidate
    CalendarControlModule.setCurrentDate(new Date(start))
    await this.$analytics.send(SignalType.HOVER_CANDIDATE_ON_PANEL, { id: this.pageId })
    this.onCandidateHover({ start, end, candidateId })
  }
}
