import { ref } from '@vue/composition-api'

export const useSignUpSetup = () => {
  const isGoogleSelected = ref(false)

  return {
    isGoogleSelected,
    handleGoogleSelect: () => {
      isGoogleSelected.value = true
    },
    handleGoogleSignUpFailed: () => {
      isGoogleSelected.value = false
    }
  }
}
