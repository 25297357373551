















import DurationInputButton from '@/components/molecules/DurationInputButton.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'MobileCalendarCandidatesControls',
  components: {
    DurationInputButton
  },
  props: {
    duration: {
      type: Number,
      required: true
    },
    onAutoPeekCandidatesPanelOpen: {
      type: Function as PropType<() => void>,
      required: false
    },
    onDurationUpdate: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const canOpenAutoPeekCandidatesPanel = computed(() => !!props.onAutoPeekCandidatesPanelOpen)
    return {
      canOpenAutoPeekCandidatesPanel
    }
  }
})
