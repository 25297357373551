













import { Component, Prop, Vue } from 'vue-property-decorator'
import { sortBy, groupBy } from 'lodash'
import { FullCalendarEvent } from '@/types'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

@Component
export default class EventGroup extends Vue {
  @Prop() events: FullCalendarEvent[]
  @Prop({ default: false }) noBorders

  get groupedEventsByDate() {
    return groupBy(
      sortBy(this.events, (event) => event.start),
      (event) => spirDateFormat(event.start, spirDateFormatTypes.yyyymmddweekday)
    )
  }
}
