



























import { defineComponent, computed, toRefs, ref } from '@vue/composition-api'
import ProfileModule from '@/store/modules/profile'
import UserIcon from '@/components/atoms/UserIcon.vue'
import Layout from '../atoms/AsideMenuItemLayout.vue'
import TeamList from './molecules/TeamList.vue'
import SettingPersonal from './molecules/Setting.vue'
import ETC from './molecules/ETC.vue'
import Menus from './molecules/Menus.vue'
import Links from './molecules/Links.vue'
import TeamModule from '@/store/modules/team'

export default defineComponent({
  components: {
    UserIcon,
    Layout,
    TeamList,
    SettingPersonal,
    ETC,
    Menus,
    Links
  },
  props: {
    expanded: {
      type: Boolean
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isMobile } = toRefs(props)
    const dropdown = ref<{ toggle: () => void }>(null)
    const myProfile = computed(() => ProfileModule.myProfile)
    const iconWidth = computed(() => (isMobile.value ? 24 : 32))
    const fullName = computed(() => myProfile.value?.fullName || '')
    const teams = computed(() => TeamModule.myTeams)
    const hasTeams = computed(() => teams.value?.length > 0)
    const isShowEtcMenu = computed(() => {
      return ProfileModule.getLanguage === 'ja' && isMobile.value
    })
    const closeDropDown = () => {
      if (!dropdown.value) {
        return
      }
      dropdown.value.toggle()
    }
    return {
      isShowEtcMenu,
      dropdown,
      myProfile,
      iconWidth,
      fullName,
      teams,
      hasTeams,
      closeDropDown
    }
  }
})
