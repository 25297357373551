






























import ChangeAuthAction from '@/components/atoms/ChangeAuthAction.vue'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import GoogleSignUpBox from '@/components/auth/GoogleSignUpBox.vue'
import SignInButtons from '@/components/auth/SignInButtons.vue'
import SignUpButtons from '@/components/auth/SignUpButtons.vue'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import { useAuthSignUp } from '@/composables/auth/useAuthSignUpWithRedirect'
import { useAuthSignIn } from '@/composables/auth/useAuthSignInWithRedirect'
import { useSignUpSetup } from '@/composables/auth/useSignUpSetup'
import { defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    closeModal: {
      type: Function as PropType<() => void>
    }
  },
  components: {
    CommonLayoutModal,
    AuthFormSpacer,
    AuthFormLayout,
    ChangeAuthAction,
    SignUpButtons,
    SignInButtons,
    GoogleSignUpBox
  },
  setup() {
    const isSignUp = ref(true)
    const { isGoogleSelected, handleGoogleSelect } = useSignUpSetup()
    const { signUpWithGoogle, signUpWithMicrosoft } = useAuthSignUp()
    const { signInWithMicrosoft, signInWithGoogle } = useAuthSignIn()
    const linkChangeAction = () => {
      isSignUp.value = !isSignUp.value
    }
    const handleSignUpToGoogle = () => {
      return signUpWithGoogle()
    }
    const handleSignUpToMicrosoft = () => {
      return signUpWithMicrosoft()
    }
    const handleSignInToGoogle = () => {
      return signInWithGoogle()
    }
    const handleSignInToMicrosoft = () => {
      return signInWithMicrosoft()
    }
    return {
      isSignUp,
      handleSignUpToMicrosoft,
      linkChangeAction,
      isGoogleSelected,
      handleGoogleSelect,
      handleSignUpToGoogle,
      handleSignInToGoogle,
      handleSignInToMicrosoft
    }
  }
})
