



























import Divider from '@/components/atoms/Divider.vue'
import CopyBoxForUrlAndHtml from '@/components/copyToShare/CopyBoxForUrlAndHtml.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SpirModalFrame from '@/components/ui/spir/SpirModal/SpirModalFrame.vue'
import { SignalType } from '@/lib/analytics'
import { AvailabilityModel } from '@/models/data'
import SharingModule from '@/store/modules/availabilityPatternList'
import { CandidateForShare, FrontSupportLanguage, FullCalendarEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CopyBoxForUrlAndHtml,
    Divider,
    ClosingPageheadTitleBox,
    SpirModalFrame,
    ListItemSpacer
  }
})
export default class AvailabilitySharingLinkShareModal extends Vue {
  @Prop() availabilitySharing: AvailabilityModel
  @Prop({ required: true }) language: FrontSupportLanguage

  type = 'availabilitySharing'

  mounted() {
    SharingModule.fetchCandidatesByModel(this.availabilitySharing)
  }

  get isCandidatesLoading(): boolean {
    return SharingModule.isfetchedCandidateLoading
  }

  get candidates(): CandidateForShare[] {
    return SharingModule.fetchedCandidates.map((candidate: FullCalendarEvent) => {
      return {
        start: candidate.start.toISOString(),
        end: candidate.end.toISOString()
      }
    })
  }

  get urlCopyBoxInfo() {
    return {
      title: this.$t('shareTextModal.availabilitySharing.copyURLSubTitle'),
      headerTitle: this.$t('shareTextModal.availabilitySharing.copyURLText'),
      analytics: {
        signal: SignalType.CLICK_URL_SHARE_ON_CANDIDATE_MODAL,
        payload: { id: this.availabilitySharing.id, type: `${this.availabilitySharing.type}_${this.type}` }
      }
    }
  }
  get htmlCopyBoxInfo() {
    return {
      analytics: {
        signal: SignalType.CLICK_HTML_SHARE_ON_CANDIDATE_MODAL,
        payload: { id: this.availabilitySharing.id, type: `${this.availabilitySharing.type}_${this.type}` }
      }
    }
  }
  get title() {
    return this.availabilitySharing.candidateTitle
  }
  get timeZone() {
    return this.availabilitySharing.timeZone
  }
  get duration() {
    return this.availabilitySharing.duration
  }
  get url() {
    return this.availabilitySharing.confirmURL
  }
  handleClose() {
    this.$emit('close')
  }
}
