




import Component, { mixins } from 'vue-class-component'
import timezoneButtonMixin from './updateAllTimezone'

@Component
export default class TimezoneIconButton extends mixins(timezoneButtonMixin) {}
