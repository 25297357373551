


























import Vue from 'vue'
import Component from 'vue-class-component'
import { ValidationObserver } from 'vee-validate'
import firebase from 'firebase/app'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import SettingsSection from '@/components/organisms/settings/Section.vue'

@Component({
  components: {
    ValidationObserver,
    WithValidationInput,
    SettingsSection
  }
})
export default class EmailChangeSection extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }

  email = ''

  get currentUser() {
    return firebase.auth().currentUser
  }

  async handleSave() {
    this.currentUser
      .verifyBeforeUpdateEmail(this.email)
      .then(() => {
        this.email = ''
        this.$refs.observer.reset()
        this.$buefy.toast.open({
          message: this.$t('message.emailChange.success').toString(),
          type: 'is-success'
        })
      })
      .catch((error) => {
        let message: string
        if (error.code === 'auth/requires-recent-login') {
          message = this.$t('message.emailChange.requireRelogin').toString()
        } else {
          message = this.$t('message.emailChange.failed').toString()
        }
        this.$buefy.toast.open({
          message: message,
          type: 'is-danger'
        })
      })
  }
}
