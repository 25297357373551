






import EventMessage from '@/components/atoms/EventMessage.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    EventMessage
  },
  props: {
    desc: {
      type: String,
      required: true
    },
    clickedEvent: {
      type: Function,
      required: true
    }
  }
})
