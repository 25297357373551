import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { SESSION_STORAGE_TOGGLE_SCHEDULE_EVENTS_LIST } from '../../types'
import router, { RouteNames } from '@/router'

const MODULE_NAME = 'AppScreenControl'

export interface IModuleAppScreenControl {
  _isScheduleEventListOpen: boolean
  _showCalendarList: boolean
}
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class AppScreenControl extends VuexModule {
  private _isScheduleEventListOpen = false
  private _showCalendarList = true

  get isScheduleEventListOpen() {
    return this._isScheduleEventListOpen
  }
  get showCalendarList() {
    return !this.calendarLockMode && this._showCalendarList
  }
  get calendarLockMode() {
    // Routeレベルで判断しないと、描画に影響が出てくる
    const routerName: RouteNames = router.app.$route.name as RouteNames
    if (
      routerName === 'AvailabilitySharingCreate' ||
      routerName === 'PersonalAvailabilitySharingEdit' ||
      routerName === 'TeamAvailabilitySharingEdit'
    ) {
      return true
    }
    return false
  }
  @Action
  toggleScheduleEventsList(isOpen) {
    this.SET_SCHEDULE_EVENT_LIST_OPEN(isOpen)
  }

  @Mutation
  SET_SCHEDULE_EVENT_LIST_OPEN(open = null) {
    this._isScheduleEventListOpen = open === null ? false : open
    sessionStorage.setItem(SESSION_STORAGE_TOGGLE_SCHEDULE_EVENTS_LIST, this._isScheduleEventListOpen.toString())
  }

  @Mutation
  SET_SHOW_CALENDAR_LIST(showFlag: boolean) {
    this._showCalendarList = showFlag
  }
}

export default getModule(AppScreenControl)
