import { IAccount, IIntegrations } from '../../types'
import firebase from 'firebase/app'
import 'firebase/auth'
import { getAccounts, deleteAccount } from '../../lib/api/account'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { connectZoom, fetchIntegrations, disconnectZoom } from '@/lib/api/integrations'
import { createUser } from '@/lib/api/user'
import ProfileModule from '@/store/modules/profile'
import EventModule from '@/store/modules/event'
import './dailyView'
import './settings'
import ScheduleListModule from '@/store/modules/scheduleList'
import FeatureTogglerModule from './featureToggler'
import Vue from 'vue'
import CalendarsModule from './calendars'
import EventsModule from './events'
import CalendarControlModule from './calendarControl'
import { SessionStorage } from '../lib/sessionStorage'
import { SignalType } from '../../lib/analytics/LogEntry'
import TimezonesModule from './timezones'
import AppModule from './app'
import { getProfile } from '@/lib/api/profile'
import { sendUserInfoToExternalServices } from '@/lib/externalServices/sendUserInfoToExternalServices'
import { ERROR_CODE } from '@spirinc/message-catalog'

export type UserAuthProviders = 'google' | 'microsoft' | 'email'
@Module({
  dynamic: true,
  name: 'User',
  namespaced: true,
  store
})
export class User extends VuexModule {
  _currentUser: firebase.User | null = null // todo
  _initFB = false
  _accounts: IAccount[] = []
  _isAccountLoading = false
  _isRedirectedAfterSignIn: 'ok' | 'error' | 'noUser' | 'invalidInvitationCode' | 'userExists' | null = null
  _integrations: IIntegrations = {}
  _authProvider: UserAuthProviders = null // 初回連携したProviderを保存

  get myId() {
    return this.currentUser?.uid
  }
  get currentUser(): firebase.User | null {
    return this._currentUser
  }
  get isSignIn() {
    return this._currentUser && !!this._currentUser.uid
  }
  get accounts() {
    return this._accounts
  }
  get isRedirectedAfterSignIn() {
    return this._isRedirectedAfterSignIn
  }
  get integrations() {
    return this._integrations
  }
  get isConnectedZoom(): boolean {
    const zoomInfo = this._integrations.zoomInfo
    return zoomInfo?.accountId != null && zoomInfo?.email != null
  }
  get authProvider(): UserAuthProviders {
    return this._authProvider
  }
  @Mutation
  RESET_STATE() {
    this._currentUser = null
    this._accounts = []
    this._isAccountLoading = false
    this._isRedirectedAfterSignIn = null
  }
  @Mutation
  ON_AUTH_CHANGED(user: firebase.User | null) {
    this._currentUser = user
    if (user && user.providerData?.length > 0) {
      switch (user.providerData[0].providerId) {
        case 'google.com':
          this._authProvider = 'google'
          break
        case 'microsoft.com':
          this._authProvider = 'microsoft'
          break
        default:
          this._authProvider = 'email'
      }
    }
  }
  @Mutation
  ON_ACCOUNT_LOADING_CHANGED(loadingStatus: boolean) {
    this._isAccountLoading = loadingStatus
  }
  @Mutation
  ON_ACCOUNTS_CHANGED(accounts) {
    this._accounts = accounts
  }
  @Mutation
  ON_CHANGED_STATUS_REDIRECTED(result) {
    this._isRedirectedAfterSignIn = result
  }
  @Mutation
  SET_INTEGRATIONS(integrations: IIntegrations) {
    this._integrations = integrations
  }
  @Mutation
  ON_INIT_FB() {
    this._initFB = true
  }
  @Action
  async firebaseInit() {
    return new Promise<void>((resolve) => {
      try {
        firebase.auth().onAuthStateChanged(async (user: firebase.User) => {
          const currentUser = user && user.uid ? user : null
          this.ON_AUTH_CHANGED(currentUser)
          if (currentUser) {
            Vue.prototype.$analytics.setUserId(currentUser.uid)
            Vue.prototype.$analytics.send(SignalType.ACTIVE)
            // Google Signupの場合、SignUp処理中にInit処理するためここではやらない。
            if (this._initFB) {
              return resolve()
            }
            await AppModule.initApp()
          } else if (this.isRedirectedAfterSignIn === null) {
            this.RESET_STATE()
            Vue.prototype.$analytics.setUserId(null)
            ProfileModule.setLanguage()
            CalendarsModule.RESET_STATE()
            EventsModule.RESET_STATE()
            EventModule.RESET_STATE()
            ProfileModule.RESET_STATE()
            ScheduleListModule.RESET_STATE()
            sendUserInfoToExternalServices.unSignedInUser()
          }
          this.ON_INIT_FB()
          resolve()
        })
        // loginと関係なく必要なInit
        FeatureTogglerModule.initialize()
        CalendarControlModule.setDefaultCalendarView()
      } catch (e) {
        this.ON_INIT_FB()
        resolve()
      }
    })
  }
  // MS signin, signup, Google Signinの時、FirebaseAuthのRedirectを処理
  // Authcodeを取得する時は、firebase.auth().getRedirectResult()がないため何もおきない。
  @Action
  async checkRedirectFromSignIn() {
    try {
      const result = await firebase.auth().getRedirectResult()
      if (result.user) {
        const authAction = SessionStorage.getAuthAction()
        if (authAction) {
          switch (authAction.action) {
            case 'SignIn':
              // firebaseに新規作成されたユーザーの場合 -> firebaseのユーザーを削除
              if (result.additionalUserInfo?.isNewUser) {
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'noUser')
                await result.user.delete()
                await firebase.auth().signOut()
                break
              }
              // Backendで作成済のユーザーを確認
              try {
                await getProfile()
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'ok')
                await Vue.prototype.$analytics.send(SignalType.SIGNIN_SUCCESS, {
                  from: authAction.from,
                  id: authAction.id,
                  type: authAction.type
                })
              } catch (e) {
                this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
              }
              break
            case 'SignInOrSignUp':
            case 'SignUp':
              // 新規ユーザーの場合->ユーザー作成APIを呼び出してトップへ
              if (result.additionalUserInfo?.isNewUser) {
                try {
                  await createUser({
                    timeZone: TimezonesModule.localTimezoneInfo.key,
                    language: ProfileModule.getLanguage
                  })
                  this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'ok')
                  await Vue.prototype.$analytics.send(SignalType.SIGNUP_SUCCESS, {
                    from: authAction.from,
                    id: authAction.id,
                    type: authAction.type
                  })
                } catch (e: any) {
                  if (e.code === ERROR_CODE.ACCOUNT_DUPLICATED) {
                    this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'userExists')
                  } else {
                    this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
                  }
                  await result.user.delete()
                }
              } else {
                try {
                  await getProfile()
                  this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'ok')
                  await Vue.prototype.$analytics.send(SignalType.SIGNIN_SUCCESS, {
                    from: authAction.from,
                    id: authAction.id,
                    type: authAction.type
                  })
                } catch (e) {
                  this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
                  await result.user.delete()
                }
              }
              break
          }
        }
      }
    } catch (e: any) {
      if (e.code && e.code === 'auth/account-exists-with-different-credential') {
        const authAction = SessionStorage.getAuthAction()
        SessionStorage.setAuthAction(null)
        if (authAction?.action === 'SignIn') {
          this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'noUser')
        } else {
          this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'userExists')
        }
      } else {
        this.context.commit('ON_CHANGED_STATUS_REDIRECTED', 'error')
      }
    }
  }
  @Action
  async fetchAccount() {
    this.context.commit('ON_ACCOUNT_LOADING_CHANGED', true)
    try {
      const accounts = await getAccounts()
      this.context.commit('ON_ACCOUNTS_CHANGED', accounts)
    } finally {
      this.context.commit('ON_ACCOUNT_LOADING_CHANGED', false)
    }
  }
  @Action
  async deleteAccount(accountId) {
    await deleteAccount(accountId)
    return CalendarsModule.fetchCalendars()
  }
  @Action
  async signOut() {
    try {
      sendUserInfoToExternalServices.signedOut()
      await firebase.auth().signOut()
    } finally {
      AppModule.resetAllModules()
    }
  }
  @Action
  async connectZoom(authCode: string) {
    await connectZoom(authCode)
    await this.context.dispatch('fetchIntegrations')
  }
  @Action
  async fetchIntegrations(refreshingZoom = false) {
    const integrations = await fetchIntegrations(refreshingZoom)
    this.context.commit('SET_INTEGRATIONS', integrations)
  }
  @Action
  async disconnectZoom() {
    await disconnectZoom()
    await this.context.dispatch('fetchIntegrations')
  }
}

export default getModule(User)
