import { VuexModule, Action, getModule, Module, Mutation } from 'vuex-module-decorators'
import { FullCalendarEvent, Candidate, ScheduleStatusUpdateActionName } from '@/types'
import store from '@/store'
import { nanoid } from 'nanoid'
import { isEqual } from 'date-fns'
import * as scheduleAPI from '../../lib/api/schedule'
import { Confirmer } from '@/models/data/'
type AlternativeCandidatePayload = {
  id?: string
  start: Date
  end: Date
}

@Module({
  dynamic: true,
  name: 'SuggestAlternativeCandidate',
  namespaced: true,
  store
})
class SuggestAlternativeCandidate extends VuexModule {
  _alternativeCandidates: Candidate[] = []

  get alternativeCandidates(): Candidate[] {
    return this._alternativeCandidates
  }

  @Action
  reset() {
    this.RESET()
  }

  @Action
  addCandidate(payload: AlternativeCandidatePayload | FullCalendarEvent) {
    const sameTimeCandiate = this.alternativeCandidates.filter((candidate) => {
      return isEqual(payload.start, new Date(candidate.start)) && isEqual(payload.end, new Date(candidate.end))
    })
    if (sameTimeCandiate.length > 0) {
      return
    }
    if (payload.id) {
      this.UPDATE_CANDIDATE({
        id: payload.id,
        start: payload.start.toISOString(),
        end: payload.end.toISOString()
      })
    } else {
      this.ADD_CANDIDATE({
        id: nanoid(),
        start: payload.start.toISOString(),
        end: payload.end.toISOString()
      })
    }
  }

  @Action
  deleteCandidate(candidateId: string) {
    this.DELETE_CANDIDATE(candidateId)
  }

  @Action
  async suggestAlternativeCandidates({
    scheduleId,
    isOrganizer,
    confirmer
  }: {
    scheduleId: string
    isOrganizer: boolean
    confirmer: Confirmer
  }) {
    const alternativeCandidatePayload = this._alternativeCandidates.map((c: Candidate) => {
      return {
        start: c.start,
        end: c.end
      }
    })
    const candidatePayload = alternativeCandidatePayload
    const action = isOrganizer
      ? ScheduleStatusUpdateActionName.organizerSuggest
      : ScheduleStatusUpdateActionName.confirmerSuggest
    await scheduleAPI.executeScheduleStatusUpdateAction(scheduleId, action, candidatePayload, confirmer)
  }

  @Mutation
  ADD_CANDIDATE(candidate: Candidate) {
    this._alternativeCandidates.push(candidate)
  }

  @Mutation
  UPDATE_CANDIDATE(candidate: Candidate) {
    this._alternativeCandidates = this._alternativeCandidates.filter((c: Candidate) => {
      return c.id !== candidate.id
    })
    this._alternativeCandidates.push(candidate)
  }

  @Mutation
  DELETE_CANDIDATE(candidateId: string) {
    this._alternativeCandidates = this._alternativeCandidates.filter((c: Candidate) => {
      return c.id !== candidateId
    })
  }
  @Mutation
  RESET() {
    this._alternativeCandidates = []
  }
}

export default getModule(SuggestAlternativeCandidate)
