




import { useTimeZoneIntervalLabel } from '@/composables/useTimeZoneIntervalLabel'
import { defineComponent, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'TimeLabelWithInterval',
  props: {
    timeZoneKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { timeZoneKey } = toRefs(props)
    const { timeZoneIntervalLabel } = useTimeZoneIntervalLabel({ timeZoneKey })
    return {
      timeZoneIntervalLabel
    }
  }
})
