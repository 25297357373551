import { ref } from '@vue/composition-api'

export const useEventStyleStore = () => {
  const insetStore = ref<{ [key: string]: string }>({})

  function saveInset(key: string, inset: string) {
    insetStore.value[key] = inset
  }
  function getInset(key: string): string {
    return insetStore.value[key]
  }

  return { saveInset, getInset }
}
