





































import { Vue, Prop, PropSync, Component } from 'vue-property-decorator'
import ConfirmModal from '@/components/modal/Confirm.vue'
import EmailOrCalendarSelect from './EmailOrCalendarSelect.vue'
import ShowConfirmedDate from './ShowConfirmedDate.vue'
import CalendarsModule from '@/store/modules/calendars'
import { ValidationObserver } from 'vee-validate'
import YourInfoForm from '@/components/forms/YourInfoForm.vue'
import {
  defaultAccountAndCalendarId,
  SelectedCalendar,
  saveSelectedCalendar
} from '../data/defaultAccountAndCalendarId'
import { ConfirmData } from '../ConfirmSchedule.vue'

@Component({
  components: {
    ConfirmModal,
    EmailOrCalendarSelect,
    YourInfoForm,
    ValidationObserver,
    ShowConfirmedDate
  }
})
export default class ConfirmScheduleModal extends Vue {
  @Prop({ default: null }) formattedDate: string
  @Prop() isOrganizer: boolean
  @Prop() header
  @Prop() isSignIn
  @Prop({ default: false }) isVote: boolean
  @Prop() cancelBtn: string
  @PropSync('confirmData') confirmDataSynced!: ConfirmData

  selectedCalendar: SelectedCalendar = null

  mounted() {
    if (this.isSignIn) {
      this.selectedCalendar = defaultAccountAndCalendarId()
    }
  }
  changeName(newValue: string) {
    this.confirmDataSynced.name = newValue
  }
  changeEmail(newValue: string) {
    this.confirmDataSynced.email = newValue
  }
  register() {
    if (this.selectedCalendar) {
      saveSelectedCalendar(this.selectedCalendar)
      CalendarsModule.visibleCalendarIfNotVisible(this.selectedCalendar)
    }
    this.$emit('register')
    this.$emit('close')
  }
}
