









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FormItemLayout extends Vue {
  @Prop({ default: null }) icon
  @Prop({ default: null }) label: string
}
