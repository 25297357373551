

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { PollAnswer } from '@/types'
@Component
export default class YesOrNoSwitch extends Vue {
  @Prop() vote: PollAnswer
  @Prop() disabled: boolean

  get yesValue(): PollAnswer {
    return 'yes'
  }
  get noValue(): PollAnswer {
    return 'no'
  }
  updateValue(newValue) {
    this.$emit('updateAnswer', newValue)
  }
}
