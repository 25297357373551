






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CopyBoxLinkText extends Vue {
  @Prop({ required: true }) title: string
  @Prop() onClick: () => void

  handleClick() {
    if (this.onClick) {
      this.onClick()
    }
  }
}
