


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ISchedule, Candidate } from '@/types'

@Component
export default class ScheduleConfirmBox extends Vue {
  @Prop()
  newSchedule!: Pick<ISchedule, 'title' | 'duration' | 'calendarId'>

  @Prop()
  candidates!: Candidate[]
}
