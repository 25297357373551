import {
  personalId,
  PersonalTeamValue,
  TeamInfo
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import TeamRecordModule from '@/store/modules/teamRecord'
import { computed, ref } from '@vue/composition-api'

type UserOverview = { fullName?: string; photoURL: string }
export type TeamOverview = { id: string; name: string; photoUrl?: string }

export const usePersonalTeamSelectDropdownSetup = ({
  initialPersonalTeamValue = personalId,
  getMyProfile,
  getMyTeams
}: {
  initialPersonalTeamValue?: PersonalTeamValue
  getMyProfile: () => UserOverview
  getMyTeams: () => TeamOverview[]
}) => {
  const selectedPersonalTeamValue = ref<PersonalTeamValue>(initialPersonalTeamValue)
  const personalInfo = computed(() => {
    const myProfile = getMyProfile()
    return {
      id: personalId,
      name: myProfile.fullName,
      photoURL: myProfile.photoURL
    }
  })
  const teamInfos = computed((): TeamInfo[] => {
    return getMyTeams().map((teamOverview) => {
      return {
        disabled: !TeamRecordModule.teamByTeamId(teamOverview.id)?.myInfo?.id,
        id: teamOverview.id,
        name: teamOverview.name,
        photoURL: teamOverview.photoUrl
      }
    })
  })
  return {
    selectedPersonalTeamValue,
    personalInfo,
    teamInfos,
    handlePersonalTeamChange: (newValue: PersonalTeamValue) => {
      selectedPersonalTeamValue.value = newValue
    }
  }
}
