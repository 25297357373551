







import ArrangementPeekCandidatesConditionSidePanel from '@/components/ui/domain/sidePanel/arrangement/ArrangementPeekCandidatesConditionSidePanel.vue'
import SidePanelsWrapper from '@/components/ui/domain/sidePanel/arrangement/SidePanelsWrapper.vue'
import { defineComponent } from '@vue/composition-api'
import Sections from './_Sections.vue'

export default defineComponent({
  name: 'NewArrangementsPanel',
  components: {
    SidePanelsWrapper,
    Sections,
    ArrangementPeekCandidatesConditionSidePanel
  },
  props: {
    isPeekCandidateSectionOpened: {
      type: Boolean,
      default: false
    }
  }
})
