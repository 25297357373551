













import FullPageLayout from './FullPageLayout.vue'
import AppealBox from '@/components/auth/AppealBox.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    FullPageLayout,
    AppealBox
  }
})
