















import { Component, Vue } from 'vue-property-decorator'
import SpirLogo from '@/components/atoms/SpirLogo.vue'

@Component({
  components: {
    SpirLogo
  }
})
export default class FullPageLayout extends Vue {}
