



















import { SignalType } from '@/lib/analytics/LogEntry'
import { defaultScheduleMenus, MenuInfo } from '@/lib/menus'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'
import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator'
import CreateButtonBody from './CreateButtonBody.vue'

@Component({
  components: {
    CreateButtonBody
  }
})
export default class CreateMenu extends Vue {
  @Prop({ default: 'is-top-left' }) position
  @Prop({ default: false }) isShownOnCalendar: boolean
  @Prop({ default: false }) expanded: boolean
  @Prop({ default: () => defaultScheduleMenus }) scheduleMenus: MenuInfo[]

  @ProvideReactive()
  get teams() {
    return TeamModule.myTeams
  }

  @ProvideReactive()
  get myInfo() {
    return ProfileModule.myProfile
  }
  get appendToBody() {
    if (this.isShownOnCalendar) {
      return false
    }
    return true
  }
  showButton() {
    return this.$analytics.send(SignalType.CLICKMENU)
  }
  handleActiveChange(changedStatus: boolean) {
    this.$emit('menuShow', changedStatus)
  }
}
