import { FullCalendarEvent, GoogleEvent } from '@/types'
import { parseISO } from 'date-fns'
import { nanoid } from 'nanoid'
import { getAlldayEndDate, getAllDayStartDate } from '../event'

export const FromGoogleEvent = {
  convertToFullCalendarEvent(event: GoogleEvent): FullCalendarEvent {
    const accountId = event.accountId
    const calendarId = event.calendarId
    const start = getAllDayStartDate(parseISO(event.start), event.allDay)
    const end = getAlldayEndDate(parseISO(event.end), event.allDay)
    return {
      id: nanoid(),
      title: event.summary || '',
      start,
      end,
      allDay: event.allDay,
      extendedProps: {
        source: 'editingEvent',
        accountId,
        calendarId
      }
    }
  }
}
