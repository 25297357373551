














import { defineComponent, getCurrentInstance } from '@vue/composition-api'

export default defineComponent({
  name: 'SidePanelBox',
  setup() {
    const currentInstance = getCurrentInstance()
    const hasSlot = (name) => {
      return currentInstance.slots[name]
    }
    return {
      hasSlot
    }
  }
})
