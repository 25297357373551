




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Divider extends Vue {
  // TODO: layoutで縦ボーダーか横ボーダーか切り替えられるようにする
  @Prop({ default: 'horizontal' }) layout: 'horizontal' | 'vertical'
  @Prop({ default: 'normal' }) darkness: 'normal' | 'dark'
}
