














import { Component, Vue } from 'vue-property-decorator'
import UserIcon from '@/components/atoms/UserIcon.vue'
import TeamMenuSidePanel from '@/components/teamSettings/sidePanels/TeamMenuSidePanel.vue'

@Component({
  components: {
    TeamMenuSidePanel,
    UserIcon
  }
})
export default class TeamLayout extends Vue {
  showSidePanelDelayed = false

  mounted() {
    this.showSidePanelDelayed = true
  }
  get teamId() {
    return this.$route.params.id
  }
  sideBarClosed() {
    this.$router.push({ query: { ...this.$route.query, showMenu: 'false' } })
  }
  get open() {
    if (this.$route.query.showMenu) {
      return this.$route.query.showMenu == 'true'
    }
    return false
  }
  get delayedShowSidePanel() {
    return this.showSidePanelDelayed
  }
}
