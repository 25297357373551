import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { FullCalendarEvent } from '@/types'
import { computed, ComputedRef, provide, Ref, ref } from '@vue/composition-api'

// InjectionKey
export const PeekCandidatesControl = 'PeekCandidatesControl'
export const PeekCandidatesPanelHandler = 'PeekCandidatesPanelHandler'

export type CandidatesControlOnCalendar = {
  candidates: FullCalendarEvent[]
  handleUpdate: <T extends { start: Date; end: Date; id?: string }>(payload: T, revert?: Function) => void
  handleDelete: (id: string) => void
}
type UsePeekCandidatesPanelHandler = {
  formData: ComputedRef<ArrangementTypeFormData>
}
type Mode = 'sections' | 'peekCandidates'
export const usePeekCandidatesPanelHandler = ({
  formData
}: UsePeekCandidatesPanelHandler): {
  isPeekCandidateSectionOpened
  candidatesControls
  openPanel
} => {
  const i18n = useTranslation()
  const { openInfoBottomToast } = useToast()
  const mode: Ref<Mode> = ref('sections')
  const isPeekCandidateSectionOpened = computed(() => mode.value === 'peekCandidates')
  function openPanel() {
    mode.value = 'peekCandidates'
  }
  provide(PeekCandidatesPanelHandler, {
    handleOpen: () => {
      openPanel()
    },
    handleClose: () => {
      mode.value = 'sections'
    }
  })
  const candidatesControls = computed((): CandidatesControlOnCalendar => {
    if (mode.value === 'peekCandidates') {
      return {
        candidates: formData.value.peekCandidatesForm.mixTemporariesAndOthers(),
        handleUpdate: () => {
          openInfoBottomToast({
            message: i18n.t('peekCandidates.messages.canNotDragOrClickWhilePanelIsActive').toString()
          })
        },
        handleDelete: () => {
          openInfoBottomToast({
            message: i18n.t('peekCandidates.messages.canNotDragOrClickWhilePanelIsActive').toString()
          })
        }
      }
    }
    return {
      candidates: formData.value.candidatesInfo.candidates,
      handleUpdate: (event, revert) => {
        formData.value.candidatesInfo.addNewCandidate(event, revert)
      },
      handleDelete: (id) => {
        formData.value.candidatesInfo.removeCandidate(id)
      }
    }
  })
  return {
    isPeekCandidateSectionOpened,
    candidatesControls,
    openPanel
  }
}
