








































import Divider from '@/components/atoms/Divider.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ArrangementFormSidePanelFooterButtons from '@/components/ui/domain/footer/ArrangementFormSidePanelFooterButtons.vue'
import ArrangementTypeSection from '@/components/ui/domain/section/arrangement/ArrangementTypeSection.vue'
import PersonalScheduleSections from '@/components/ui/domain/sections/arrangement/PersonalScheduleSections.vue'
import ArrangementPeekCandidatesConditionSidePanel from '@/components/ui/domain/sidePanel/arrangement/ArrangementPeekCandidatesConditionSidePanel.vue'
import SidePanelsWrapper from '@/components/ui/domain/sidePanel/arrangement/SidePanelsWrapper.vue'
import SidePanelBodyFrame from '@/components/ui/page/SidePanelBodyFrame.vue'
import SidePanelFooterFrame from '@/components/ui/page/SidePanelFooterFrame.vue'
import SidePanelFrame from '@/components/ui/page/SidePanelFrame.vue'
import SidePanelHeaderFrame from '@/components/ui/page/SidePanelHeaderFrame.vue'
import { useAnalytics } from '@/composables/useAnalytics'
import { usePersonalTeamSelectDropdownSetup } from '@/composables/usePersonalTeamSelectDropdownSetup'
import { SignalType } from '@/lib/analytics'
import ProfileModule from '@/store/modules/profile'
import { ListType } from '@/types'
import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { PersonalScheduleEditSidePanelInfo } from './_personalScheduleEditPage'

export default defineComponent({
  props: {
    isPeekCandidateSectionOpened: {
      type: Boolean
    }
  },
  components: {
    SidePanelsWrapper,
    ArrangementPeekCandidatesConditionSidePanel,
    SidePanelFrame,
    SidePanelHeaderFrame,
    SidePanelBodyFrame,
    SidePanelFooterFrame,
    ClosingPageheadTitleBox,
    Divider,
    PersonalScheduleSections,
    ArrangementTypeSection,
    ArrangementFormSidePanelFooterButtons,
    ValidationObserver
  },
  setup() {
    const analytics = useAnalytics()
    const { getPersonalScheduleFormData } = inject(PersonalScheduleEditSidePanelInfo)
    const { selectedPersonalTeamValue, personalInfo } = usePersonalTeamSelectDropdownSetup({
      getMyProfile: () => ProfileModule.myProfile,
      getMyTeams: () => []
    })
    const currentArrangementType = ref<ListType>(ListType.SCHEDULE)
    const formData = computed(() => getPersonalScheduleFormData())

    return {
      currentArrangementType,
      formData,
      selectedPersonalTeamValue,
      personalInfo,
      handlePersonalTeamChange: () => ({}),
      handleArrangementTypeChange: () => ({}),
      handleClose: () => formData.value.pageDataControl.cancel(),
      handleSave: () => {
        formData.value.pageDataControl.confirm()
        analytics.send(SignalType.CLICK_ARRANGEMENT_SAVE, { arrangementType: currentArrangementType.value })
      },
      handlePreviewOpen: () => {
        formData.value.pageDataControl.preview()
        analytics.send(SignalType.CLICK_ARRANGEMENT_PREVIEW, { arrangementType: currentArrangementType.value })
      }
    }
  }
})
