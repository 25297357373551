

















import { Component, PropSync, Vue } from 'vue-property-decorator'
import Layout from '../atoms/AsideMenuItemLayout.vue'

@Component({
  components: {
    Layout
  }
})
export default class NavMenuOpenCloseButton extends Vue {
  @PropSync('open', { type: Boolean }) openSynced!: boolean
}
