















import TitleDropdown from '@/components/ui/domain/dropdown/TitleDropdown/index.vue'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { IconSetting, TitleSetting } from '@/components/ui/form/item/formItemSetting'
import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FormTextInputItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    SpirTextInput,
    TitleDropdown
  },
  props: {
    icon: {
      type: Object as PropType<IconSetting>,
      required: true
    },
    title: {
      type: Object as PropType<TitleSetting>,
      required: true
    },
    value: {
      type: String
    },
    suggestions: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    },
    onInput: {
      type: Function as PropType<(value: string) => void>
    }
  },
  setup(props, { emit }) {
    return {
      handleInput: (text) => {
        if (props.onInput) {
          props.onInput(text)
        } else {
          emit('input', text)
        }
      }
    }
  }
})
