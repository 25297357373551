











import { computed, defineComponent } from '@vue/composition-api'
import { TwoChoicesRadioState } from '@/components/ui/spir/twoChoicesRadioState'

export default defineComponent({
  name: 'SpirTwoChoicesRadioButton',
  props: {
    leftValue: {
      type: String,
      required: true
    },
    rightValue: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    function selectState(val: string, target: string): TwoChoicesRadioState {
      if (val === target) return 'selected'
      else {
        if (props.disabled) return 'disabled'
        else return 'not-selected'
      }
    }
    const leftState = computed(() => selectState(props.value, props.leftValue))
    const rightState = computed(() => selectState(props.value, props.rightValue))
    return {
      leftState,
      rightState,
      handleLeftClick: () => {
        if (leftState.value !== 'not-selected') return
        emit('input', props.leftValue)
      },
      handleRightClick: () => {
        if (rightState.value !== 'not-selected') return
        emit('input', props.rightValue)
      }
    }
  }
})
