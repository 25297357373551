











import { defineComponent, PropType } from '@vue/composition-api'
import SpirButton from '@/components/ui/Button.vue'

export default defineComponent({
  name: 'OperationHeader',
  components: {
    SpirButton
  },
  props: {
    addButtonLabel: {
      type: String,
      required: true
    },
    addButtonDisabled: {
      type: Boolean,
      default: false
    },
    deleteButtonDisabled: {
      type: Boolean,
      default: false
    },
    onAdd: {
      type: Function as PropType<() => void>,
      required: true
    },
    onDelete: {
      type: Function as PropType<() => void>,
      required: true
    }
  }
})
