



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Attendee } from '@/types'
import MemberAvatar from '@/components/molecules/MemberAvatar.vue'

@Component({
  components: {
    MemberAvatar
  }
})
export default class AttendeeLine extends Vue {
  @Prop() attendee: Attendee
  @Prop({ default: 'full-name-and-email' }) type: 'icon' | 'title-only' | 'full-name-and-email'
}
