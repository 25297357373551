import { render, staticRenderFns } from "./CustomColorPaletteButton.vue?vue&type=template&id=24440794&scoped=true&"
import script from "./CustomColorPaletteButton.vue?vue&type=script&lang=ts&"
export * from "./CustomColorPaletteButton.vue?vue&type=script&lang=ts&"
import style0 from "./CustomColorPaletteButton.vue?vue&type=style&index=0&id=24440794&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24440794",
  null
  
)

export default component.exports