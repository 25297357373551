




import { Vue, Component } from 'vue-property-decorator'
import UserModule from '@/store/modules/user'
import { LOCAL_STORAGE_KEY_FOR_CALLBACK_ZOOM } from '@/types'
import { SignalType } from '@/lib/analytics/LogEntry'

@Component
export default class CallbackFromZoom extends Vue {
  async created() {
    const authCode = this.$route.query.code
    if (!authCode || !this.isString(authCode)) {
      this.showError()
      this.$router.back()
      return
    }

    try {
      await UserModule.connectZoom(authCode)
      await this.$analytics.send(SignalType.CONNECT_ZOOM)
    } catch (_) {
      this.showError()
    } finally {
      const originPathName = window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_CALLBACK_ZOOM) || 'Settings'
      this.$router.push({ name: originPathName })
    }
  }

  private showError() {
    this.$buefy.toast.open({
      type: 'is-danger',
      position: 'is-bottom',
      message: this.$t('settings.integrations.zoomConnectionError').toString()
    })
  }

  private isString(any: any): any is string {
    return typeof any === 'string'
  }
}
