<template>
  <div class="title-language-container">
    <ConfirmTitle :title="title" />
    <div class="size-adjustment-container">
      <LanguageDropdown
        v-if="isShowLanguageSelector"
        position="bottom-left"
        :value="currentLanguage"
        size="small"
        @input="handleChangeLanguage"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import ConfirmTitle from '@/components/sidePanels/confirm/ConfirmTitle.vue'
import LanguageDropdown from '@/components/ui/domain/dropdown/LanguageDropdown/index.vue'
import UserModule from '@/store/modules/user'
import { useLanguageSetting } from '@/composables/useLanguageSetting'

export default defineComponent({
  components: {
    LanguageDropdown,
    ConfirmTitle
  },
  props: {
    title: {
      type: String
    }
  },
  setup() {
    const languageSetting = useLanguageSetting()
    return {
      currentLanguage: languageSetting.currentLanguage,
      isShowLanguageSelector: computed(() => !UserModule.isSignIn),
      handleChangeLanguage: languageSetting.handleChangeLanguage
    }
  }
})
</script>
<style scoped lang="scss">
.title-language-container {
  display: flex;
  justify-content: space-between;
}
</style>
