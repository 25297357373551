













































import Component, { mixins } from 'vue-class-component'
import { sortBy } from 'lodash'
import Common from '../../Common.vue'
import PrivateVoteOperationBox from '@/components/groupPoll/PrivateVoteOperationBox.vue'
import EditPollModule from '@/store/modules/editPoll'
import VoteModule, { CandidateForVote } from '@/store/modules/vote'
import SignInButton from '@/components/molecules/SignInButton.vue'
import Tooltip from '@/components/molecules/Tooltip.vue'
import CalendarSyncInfo from '@/components/molecules/CalendarSyncInfo.vue'
import UserModule from '@/store/modules/user'
import VoteConfirm from '@/components/modal/confirm/ConfirmVote.vue'
import ConfirmMixin from '../../ConfirmMixin'
import VoteConfirmedModal from '@/components/modal/VoteConfirmedModal.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import PollMixin from './PollMixin'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import PoweredBySpir from '@/components/sidePanels/confirm/atoms/PoweredBySpir.vue'
import { isAfter } from 'date-fns'
import { isActiveStatus } from '@/types'
import { SignalType } from '@/lib/analytics/LogEntry'
import ConfirmSidePanelFrame from '@/components/sidePanels/confirm/atoms/ConfirmSidePanelFrame.vue'
import ConfirmOperationFrame from '@/components/sidePanels/confirm/atoms/ConfirmOperationFrame.vue'
import ConfirmSidePanelFooterFrame from '@/components/sidePanels/confirm/atoms/ConfirmSidePanelFooterFrame.vue'
import Button from '@/components/ui/Button.vue'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { GuestConfirmer, SpirUserConfirmer } from '@/models/data/'

@Component({
  components: {
    Common,
    SignInButton,
    Tooltip,
    CalendarSyncInfo,
    ConfirmPageHeader,
    PrivateVoteOperationBox,
    PoweredBySpir,
    ConfirmSidePanelFrame,
    ConfirmOperationFrame,
    ConfirmSidePanelFooterFrame,
    Button
  }
})
export default class PrivateVote extends mixins(ConfirmMixin, PollMixin) {
  async mounted() {
    // mixin
    await this.setEditingPoll()
    await this.initVotePage()
  }
  // mixin
  get isSignIn() {
    return UserModule.isSignIn
  }
  get allCandidates() {
    return VoteModule.getCandidatesAsCalendarFormat
  }
  get currentVotes() {
    return VoteModule.getCurrentVotes
  }
  get sortedCurrentVotes(): CandidateForVote[] {
    return sortBy(this.currentVotes, (event: CandidateForVote) => event.start)
  }
  get author() {
    return this.editingPoll?.author
  }
  // ↑
  get isAllAnswerNo() {
    return VoteModule.isAllAnswerNo
  }
  get isLoading() {
    return VoteModule.getisLoading || EditPollModule.isLoading
  }
  async initVotePage() {
    this.initCommonProcess({
      startDate: this.allCandidates.filter(
        (c) => isActiveStatus(c.extendedProps?.status) && isAfter(c.start, new Date())
      )[0]?.start
    })
  }
  async vote() {
    const candidateStartDate = new Date(this.sortedCurrentVotes[0].start)
    const candidateEndDate = new Date(this.sortedCurrentVotes[this.sortedCurrentVotes.length - 1].end)

    const modal = this.$buefy.modal.open({
      parent: this,
      component: VoteConfirm,
      hasModalCard: true,
      canCancel: false,
      props: {
        candidateStartDate,
        candidateEndDate,
        isVote: true
      },
      events: {
        register: async ({ email, name }) => {
          modal.close()
          await this.sendVote({ email, name })
        }
      }
    })
  }
  async sendVote({ email, name }) {
    try {
      const confirmer = UserModule.isSignIn
        ? new SpirUserConfirmer({ email, user: ProfileModule.myProfile })
        : new GuestConfirmer({
            email,
            name,
            timeZone: TimezoneModule.userTimezoneInfo.key,
            language: ProfileModule.getLanguage
          })
      await VoteModule.vote({ confirmer })
      await this.$analytics.send(SignalType.VOTE_GROUPPOLL, {
        id: this.pollId,
        authorUserId: this.author.id
      })
      this.showConfirmModal()
    } catch (e: any) {
      if (e.response?.status === 400 && e.response?.data?.error?.toLowerCase() === 'badrequest') {
        this.showAlreadyExistErrorAndPutVote({
          email,
          name,
          language: UserModule.isSignIn ? undefined : ProfileModule.getLanguage
        })
      } else {
        this.$buefy.toast.open({
          message: this.$t('message.errorCommon').toString(),
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  }
  async updateVote({ email, name, language }) {
    try {
      await VoteModule.updateVote({
        email,
        name,
        language
      })
      this.showConfirmModal()
    } catch (e) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
    }
  }
  showAlreadyExistErrorAndPutVote({ email, name, language }) {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: this.$t('confirms.voteIsExist.header'),
        body: this.$t('confirms.voteIsExist.body')
      },
      events: {
        close: () => {
          modal.close()
        },
        confirm: () => {
          this.updateVote({ email, name, language })
        }
      }
    })
  }
  showConfirmModal() {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: VoteConfirmedModal,
      hasModalCard: true,
      canCancel: false,
      events: {
        backToHome: () => {
          modal.close()
          this.$router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
        }
      }
    })
  }
  async handleClickedCandidate({ event }) {
    await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_CALENDAR, { id: this.pollId })
    VoteModule.updateAnswer({
      candidateId: event.id
    })
  }
}
