










import { Prop, Vue, Component } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class CalendarSelector extends Vue {
  @Prop() options: Array<Record<'label' | 'value', string>>
  @Prop() title: string
  @Prop() settingData: string | number

  data = null
  mounted() {
    this.data = this.settingData
  }
}
