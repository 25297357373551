




import FormFooterButtons from '@/components/ui/form/FormFooterButtons.vue'
import { useTranslation } from '@/composables/useTranslation'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ArrangementFormSidePanelFooterButtons',
  components: {
    FormFooterButtons
  },
  props: {
    isFormValid: {
      type: Boolean,
      default: false
    },
    onSave: {
      type: Function as PropType<() => void>,
      required: true
    },
    isPreviewDisabled: {
      type: Boolean,
      default: true
    },
    onPreviewOpen: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const footerButtons = computed(() => {
      return [
        {
          type: 'basic',
          disabled: props.isPreviewDisabled,
          title: i18n.t('buttons.previewConfirmation'),
          onClick: () => {
            props.onPreviewOpen()
          }
        },
        {
          type: 'primary',
          disabled: !props.isFormValid,
          title: i18n.t('buttons.update'),
          onClick: () => {
            props.onSave()
          }
        }
      ]
    })
    return {
      footerButtons
    }
  }
})
