












import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmModal from '@/components/modal/Confirm.vue'

@Component({
  components: {
    ConfirmModal
  }
})
export default class ConfirmDeleteModal extends Vue {
  @Prop({ default: null }) customMessage
  @Prop({ default: null }) customTitle

  get confirmDeleteTitle() {
    return this.customTitle || this.$t('message.confirmDelete').toString()
  }

  get confirmDeleteBody() {
    return this.customMessage || this.$t('message.deleteOperationWarning').toString()
  }
}
