




















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import AppModule from '@/store/modules/app'
import { AllRouteNames } from '@/router'

@Component
export default class ScheduleListEmptyMessage extends Vue {
  @Prop()
  title: string

  @Prop()
  detail: string

  @Prop()
  showCreateScheduleMenu: boolean

  createSchedule() {
    AppModule.ON_CLOSE_QUICK_VIEW()
    this.$router.push({ name: AllRouteNames.CreateArrangement })
  }
}
