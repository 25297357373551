import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=6edbe8d5&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=ts&"
export * from "./Card.vue?vue&type=script&lang=ts&"
import style0 from "./Card.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Card.vue?vue&type=style&index=1&id=6edbe8d5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6edbe8d5",
  null
  
)

export default component.exports