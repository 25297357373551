




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'HelpParagraph',
  props: {
    help: {
      type: String
    }
  }
})
