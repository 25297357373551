import { render, staticRenderFns } from "./VoteCountWithIcon.vue?vue&type=template&id=b8421574&scoped=true&"
import script from "./VoteCountWithIcon.vue?vue&type=script&lang=ts&"
export * from "./VoteCountWithIcon.vue?vue&type=script&lang=ts&"
import style0 from "./VoteCountWithIcon.vue?vue&type=style&index=0&id=b8421574&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8421574",
  null
  
)

export default component.exports