














import CalendarListWithCheckStatus from '@/components/newForm/molecules/CalendarListWithCheckStatus.vue'
import SpirDropdown from '@/components/ui/spir/SpirDropdown/SpirDropdown.vue'
import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import CalendarsModule from '@/store/modules/calendars'
import { ICalendar, ITypeCalendarListForUI } from '@/types'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { groupBy } from 'lodash'
import MiniSearch from 'minisearch'

export default defineComponent({
  name: 'TargetCalendarsDropdown',
  components: {
    SpirDropdown,
    SpirTextInput,
    CalendarListWithCheckStatus
  },
  props: {
    calendars: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const searchText = ref('')
    const dropdown = ref(null)
    function isSearchHitCalendar(calendar: ICalendar, text: string): boolean {
      if (!text) {
        return true
      }

      const searcher = new MiniSearch<ICalendar>({
        fields: ['title', 'email', 'accountName'],
        storeFields: ['id'],
        idField: 'id'
      })
      searcher.add(calendar)
      const hits = searcher.search(text, { prefix: true })
      return hits.length > 0
    }

    const accountWithCalendars = computed(() => {
      const showableCalendars = CalendarsModule.flattenCalendarsArray.filter((calendar) =>
        isSearchHitCalendar(calendar, searchText.value)
      )
      const calendarMap: { [accountId: string]: ITypeCalendarListForUI[] } = groupBy(showableCalendars, 'accountId')
      return CalendarsModule.getAccountWithcalendars
        .map((account) => {
          const calendars = calendarMap[account.accountId]
          if (calendars) {
            return {
              accountId: account.accountId,
              userName: account.userName,
              userPhoto: account.userPhoto,
              ownerCalendars: calendars.filter((calendar) => calendar.owner),
              notOwnerCalendars: calendars.filter((calendar) => !calendar.owner)
            }
          }
        })
        .filter((c) => !!c)
    })

    function clearSearchText() {
      searchText.value = ''
    }
    return {
      dropdown,
      searchText,
      accountWithCalendars,
      clearSearchText,
      handleCalendarToggle(payload) {
        emit('toggleCalendar', payload)
        dropdown.value.toggle()
      }
    }
  }
})
