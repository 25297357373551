











import Component, { mixins } from 'vue-class-component'
import timezoneButtonMixin from './updateAllTimezone'
import TimezoneLabel from '../../molecules/TimezoneLabel.vue'

@Component({
  components: {
    TimezoneLabel
  }
})
export default class TimezoneButtonWithFullLabel extends mixins(timezoneButtonMixin) {}
