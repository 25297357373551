import { usePollMixin } from '@/composables/usePollMixin'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import { defineComponent, onBeforeUnmount } from '@vue/composition-api'
/**
 * Schedule Confirm, Poll Vote
 */
export default defineComponent({
  setup() {
    const { firstDate, isVotePage, isNarrow, editingPoll, previewEditingPoll, pollId, setEditingPoll } = usePollMixin()

    onBeforeUnmount(() => {
      AppScreenControlModule.toggleScheduleEventsList(false)
    })
    return {
      firstDate,
      isVotePage,
      isNarrow,
      editingPoll,
      previewEditingPoll,
      pollId,
      setEditingPoll
    }
  }
})
