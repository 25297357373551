import { render, staticRenderFns } from "./ConfirmSkeltonCandidateSelectRow.vue?vue&type=template&id=1408e0c8&scoped=true&"
import script from "./ConfirmSkeltonCandidateSelectRow.vue?vue&type=script&lang=ts&"
export * from "./ConfirmSkeltonCandidateSelectRow.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmSkeltonCandidateSelectRow.vue?vue&type=style&index=0&id=1408e0c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1408e0c8",
  null
  
)

export default component.exports