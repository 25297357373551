











import { defineComponent } from '@vue/composition-api'
import AsideMenuItemLayout from '@/components/NavMenu/atoms/AsideMenuItemLayout.vue'

export default defineComponent({
  components: {
    AsideMenuItemLayout
  },
  props: {
    expanded: {
      type: Boolean,
      required: true
    }
  }
})
