import {
  personalId,
  PersonalTeamValue
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import {
  ArrangementTypeFormData,
  FormDataForInitializing,
  OverwritingData
} from '@/components/ui/domain/sections/arrangement/composables/types'
import { TeamOverview } from '@/composables/usePersonalTeamSelectDropdownSetup'
import { FromStartAndEnd } from '@/lib/utils/converters'
import { ScheduleModelTeam } from '@/models/data'
import EditPollModule from '@/store/modules/editPoll'
import EditScheduleModule from '@/store/modules/editSchedule'
import EditScheduleTeamModule from '@/store/modules/editScheduleTeam'
import TeamRecordModule from '@/store/modules/teamRecord'
import { FullCalendarEvent, ListType } from '@/types/schedule'
import { nanoid } from 'nanoid'
import { ArrangementFormRequest, ArrangementFormStateType, ArrangementFormStateTypes, CurrentAllData } from './types'

type FullCalendarEventStr = Omit<FullCalendarEvent, 'start' | 'end'> & { start: string; end: string }
export function toMergedFullCalendarEventsFromPolls(
  fullCalendarEvents: FullCalendarEvent[],
  duration: number
): FullCalendarEvent[] {
  return FromStartAndEnd.convertToMerged<FullCalendarEventStr, FullCalendarEvent>({
    temps: fullCalendarEvents.map((event: FullCalendarEvent) => ({
      ...event,
      start: event.start.toISOString(),
      end: event.end.toISOString()
    })),
    mapper: (tc) => ({ ...tc, start: new Date(tc.start), end: new Date(tc.end) }),
    duration
  })
}

export function toSplittedFullCalendarEventsFromSchedule(
  fullCalendarEvents: FullCalendarEvent[],
  duration: number
): FullCalendarEvent[] {
  return FromStartAndEnd.convertToSplittedByDuration({ temps: fullCalendarEvents, duration })
}

export function mapNewNanoId(candidate: FullCalendarEvent): FullCalendarEvent {
  return { ...candidate, id: nanoid() }
}

export function getPersonalTeamSelectorInfoFromFormRequest(formRequest: ArrangementFormRequest): {
  listType: ListType
  personalTeamValue: PersonalTeamValue
} {
  switch (formRequest.type) {
    case 'personalPoll':
      return { listType: ListType.POLL, personalTeamValue: personalId }
    case 'personalSchedule':
      return { listType: ListType.SCHEDULE, personalTeamValue: personalId }
    case 'teamSchedule':
      return { listType: ListType.SCHEDULE, personalTeamValue: formRequest.id }
    default:
      throw new Error('You must not reach here')
  }
}

export function initPersonalEditingModule() {
  EditScheduleModule.startCreatingNewSchedule()
}
export function initPersonalPollModule() {
  EditPollModule.initEditingPoll()
  EditPollModule.startCreatingNewPoll()
}
export async function initEditScheduleTeamModule(teamId: string): Promise<ScheduleModelTeam> {
  let count = 0
  return new Promise((resolve, reject) => {
    // チームはmyInfoが無いとデータを取得できない。そのためTeamRecordModuleのロードが終わるまで待つ。
    // 200msを10回を超えるとエラーになり、toastを表示させ、個人確定型フォームに変更
    const id = setInterval(async () => {
      count++
      if (TeamRecordModule.teamByTeamId(teamId)?.myInfo) {
        await EditScheduleTeamModule.startCreatingNewSchedule(teamId)
        clearInterval(id)
        resolve(EditScheduleTeamModule.editingSchedule)
      }
      if (count >= 10) {
        clearInterval(id)
        reject()
      }
    }, 200)
  })
}

export function selectInitialFormRequest(
  formRequest: ArrangementFormRequest,
  getMyTeams: () => TeamOverview[]
): ArrangementFormRequest {
  if (formRequest.type === 'teamSchedule') {
    const teams = getMyTeams()
    if (teams.length === 0) {
      return { type: 'personalSchedule' }
    }
    return formRequest
  } else return { type: 'personalSchedule' }
}

export function isArrangementTypeFormDataAndNotInitializing(
  prop: (ArrangementTypeFormData & { isForInitializing?: false }) | FormDataForInitializing
): prop is ArrangementTypeFormData & { isForInitializing?: false } {
  return !prop.isForInitializing
}

export function getOverwriteData({
  from,
  getCurrentAllData,
  to
}: {
  from: ArrangementFormRequest
  getCurrentAllData: CurrentAllData
  to: ArrangementFormStateType
}) {
  if (from === null) {
    return {}
  }
  const data = getCurrentAllData()
  if (data.isForInitializing) return { candidatesInfo: { hasAtLeastOneCandidate: false, candidates: [] } }
  const allData = data as ArrangementTypeFormData
  const basicInfo = allData.basicInfo
  const othersInfo = allData.othersInfo
  const common: OverwritingData = {
    title: basicInfo.title?.value,
    duration: basicInfo.duration.value,
    description: othersInfo.description?.value,
    location: othersInfo.location?.value,
    visibility: othersInfo.visibility?.value,
    candidates: allData.candidatesInfo.candidates
  }
  if (
    // TeamSchedule => TeamSchedule
    (from.type === ArrangementFormStateTypes.teamSchedule && to === ArrangementFormStateTypes.teamSchedule) ||
    // TeamSchedule => PersonalSchedule
    (from.type === ArrangementFormStateTypes.teamSchedule && to === ArrangementFormStateTypes.personalSchedule) ||
    // PersonalSchedule => TeamSchedule
    (from.type === ArrangementFormStateTypes.personalSchedule && to === ArrangementFormStateTypes.teamSchedule)
  ) {
    common.candidates = common.candidates.map(mapNewNanoId)
    return common
  }
  if (
    // TeamSchedule => PersonalPoll
    (from.type === ArrangementFormStateTypes.teamSchedule && to === ArrangementFormStateTypes.personalPoll) ||
    // PersonalSchedule => PersonalPoll
    (from.type === ArrangementFormStateTypes.personalSchedule && to === ArrangementFormStateTypes.personalPoll)
  ) {
    common.candidates = toSplittedFullCalendarEventsFromSchedule(common.candidates, common.duration).map(mapNewNanoId)
    return common
  }
  if (
    // PersonalPoll => TeamSchedule
    (from.type === ArrangementFormStateTypes.personalPoll && to === ArrangementFormStateTypes.teamSchedule) ||
    // PersonalPoll => PersonalSchedule
    (from.type === ArrangementFormStateTypes.personalPoll && to === ArrangementFormStateTypes.personalSchedule)
  ) {
    common.candidates = toMergedFullCalendarEventsFromPolls(common.candidates, common.duration).map(mapNewNanoId)
    return common
  }
  return {}
}
