















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormSelectBox extends Vue {
  @Prop() items: Array<{
    key: string
    value: string
    title: string
  }>
  @Prop({ default: true }) isHalf: boolean
  @Prop({ default: false }) disabled: boolean
}
