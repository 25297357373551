








import ChangeAuthRoute from '@/components/atoms/ChangeAuthRoute.vue'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import SignUpButtons from '@/components/auth/SignUpButtons.vue'
import i18n from '@/i18n'
import { computed, defineComponent } from '@vue/composition-api'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'

export default defineComponent({
  props: {
    onGoogleSelected: {
      type: Function,
      required: true
    },
    signUpWithMicrosoft: {
      type: Function,
      required: true
    }
  },
  components: {
    AuthFormLayout,
    ChangeAuthRoute,
    AuthFormSpacer,
    SignUpButtons,
    LoadingSpinner
  },
  setup() {
    const title = computed(() => {
      return i18n.t('forms.auth.signUp.title')
    })
    return {
      title
    }
  }
})
