import Vue from 'vue'
import Router from 'vue-router'
import AuthGuard from './auth-guard'
import TeamInvitedGuard from './team-invited-guard'
import AppModule from '@/store/modules/app'
import { PageAccessSignalType } from '../lib/analytics/LogEntry'
import Component from 'vue-class-component'
import PollGuard from '@/views/calendar/schedule/poll/guard'
import TeamGuard from './team-guard'
import AuthRedirect from './authRedirect'
import { SignalType } from '@/lib/analytics'

// Register the router hooks with their names
Component.registerHooks(['beforeRouteUpdate'])

Vue.use(Router)

// todo: namingルールが揃ってないけど、別タスクでなおそう。
export const AllRouteNames = {
  NotFound: 'NotFound',
  SignIn: 'SignIn',
  AddCalendar: 'AddCalendar',
  ArrangementsOnCalendar: 'ArrangementsOnCalendar',
  SignInWithPassword: 'SignInWithPassword',
  SignUp: 'SignUp',
  SignOut: 'SignOut',
  SendPasswordResetEmail: 'SendPasswordResetEmail',
  Main: 'Main',
  CreateArrangement: 'CreateArrangement',
  CreatePoll: 'CreatePoll',
  EditPoll: 'EditPoll',
  VotePoll: 'VotePoll',
  CreateSchedule: 'CreateSchedule',
  EditSchedule: 'EditSchedule',
  ConfirmSchedule: 'ConfirmSchedule',
  SuggestAlternativeDate: 'SuggestAlternativeDate',
  CreateEvent: 'CreateEvent',
  EditEvent: 'EditEvent',
  ProfileView: 'ProfileView',
  ProfileDetails: 'ProfileDetails',
  ProfileEdit: 'ProfileEdit',
  CallbackFromMS: 'CallbackFromMS',
  CallbackFromGoogle: 'CallbackFromGoogle',
  Settings: 'Settings',
  AvailabilityList: 'AvailabilityList',

  AvailabilitySharingCreate: 'AvailabilitySharingCreate',
  PersonalAvailabilitySharingEdit: 'PersonalAvailabilitySharingEdit',
  AvailabilityConfirm: 'AvailabilityConfirm',
  CallbackFromZoom: 'CallbackFromZoom',
  Subscription: 'Subscription',
  AccountLink: 'AccountLink',
  ScheduleList: 'ScheduleList',
  ConfirmedList: 'ConfirmedList',
  UnconfirmedList: 'UnconfirmedList',
  TeamSettingRoot: 'TeamSettingRoot',
  TeamSettingMyCalendar: 'TeamSettingMyCalendar',
  TeamSettingMember: 'TeamSettingMember',
  TeamInfoSetting: 'TeamInfoSetting',
  TeamSettingDummy: 'TeamSettingDummy',
  TeamMemberInvited: 'TeamMemberInvited',
  TeamMemberTokenExpired: 'TeamMemberTokenExpired',
  TeamAvailabilitySharingEdit: 'TeamAvailabilitySharingEdit',
  TeamAvailabilitySharingConfirm: 'TeamAvailabilitySharingConfirm',
  TeamScheduleConfirm: 'TeamScheduleConfirm',
  TeamScheduleEdit: 'TeamScheduleEdit',
  AfterConfirm: 'AfterConfirm'
} as const
export type RouteNames = typeof AllRouteNames[keyof typeof AllRouteNames]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/404',
      name: AllRouteNames.NotFound,
      component: () => import('../views/NotFound.vue')
    },
    {
      path: '/t/:id/s/:scheduleId/c',
      redirect: { name: AllRouteNames.TeamScheduleConfirm }
    },
    {
      path: '/auth',
      name: 'AuthRoot',
      component: {
        render: (c) => c('router-view')
      },
      children: [
        {
          path: 'signin-password',
          name: AllRouteNames.SignInWithPassword,
          beforeEnter: AuthRedirect,
          component: () => import('../views/auth/SignInWithPassword.vue')
        },
        {
          path: 'send-password-reset-mail',
          name: AllRouteNames.SendPasswordResetEmail,
          beforeEnter: AuthRedirect,
          component: () => import('../views/auth/SendPasswordResetEmail.vue')
        },
        {
          path: 'signout',
          name: AllRouteNames.SignOut,
          component: () => import('../views/auth/SignOut.vue')
        },
        {
          path: 'signin',
          name: AllRouteNames.SignIn,
          beforeEnter: AuthRedirect,
          component: () => import('../views/auth/SignIn.vue')
        },
        {
          path: 'signup',
          name: AllRouteNames.SignUp,
          beforeEnter: AuthRedirect,
          component: () => import('../views/auth/SignUp.vue')
        },
        {
          path: 'add-calendar',
          name: AllRouteNames.AddCalendar,
          beforeEnter: AuthGuard,
          component: () => import('@/views/auth/AddCalendar.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('../views/Home.vue'),
      beforeEnter: AuthGuard,
      children: [
        {
          path: '/',
          name: AllRouteNames.Main,
          beforeEnter: (to, _, next) => {
            if (AppModule.isNarrow) {
              next({ name: AllRouteNames.AvailabilityList })
            } else {
              next()
            }
          },
          component: () => import('../views/calendar/Main.vue')
        },
        {
          path: '/calendar',
          name: 'CalendarRoot',
          component: {
            render: (c) => c('router-view')
          },
          children: [
            {
              path: 'arrangements',
              name: AllRouteNames.ArrangementsOnCalendar,
              beforeEnter: (to, _, next) => {
                if (AppModule.isNarrow) {
                  next()
                } else {
                  next({ name: AllRouteNames.Main })
                }
              },
              component: () => import('@/views/calendar/Arrangements.vue')
            }
          ]
        },
        {
          path: '/list',
          component: () => import('../views/calendar/list/Root.vue'),
          children: [
            {
              path: '',
              redirect: { name: AllRouteNames.ScheduleList }
            },
            {
              path: 'confirmed',
              name: AllRouteNames.ConfirmedList,
              component: () => import('@/views/list/ConfirmedList.vue')
            },
            {
              path: 'unconfirmed',
              name: AllRouteNames.UnconfirmedList,
              component: () => import('@/views/list/UnconfirmedList.vue')
            },
            {
              path: 'schedule',
              name: AllRouteNames.ScheduleList,
              component: () => import('../views/calendar/list/Schedule.vue')
            },
            {
              path: 'availability',
              name: AllRouteNames.AvailabilityList,
              component: () => import('@/views/list/Availability.vue')
            }
          ]
        },
        {
          path: '/arrangements',
          name: 'ArrangementsRoot',
          component: {
            render: (c) => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreateArrangement,
              component: () => import('@/views/arrangements/CreateArrangement.vue')
            }
          ]
        },
        {
          path: '/polls',
          name: 'PollsRoot',
          component: {
            render: (c) => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreatePoll,
              redirect: { name: AllRouteNames.CreateArrangement }
            },
            {
              path: ':id',
              component: {
                render: (c) => c('router-view')
              },
              beforeEnter: async (to, _, next) => {
                return PollGuard(to, _, next)
              },
              children: [
                {
                  path: 'edit',
                  name: AllRouteNames.EditPoll,
                  component: () => import('@/views/personal/poll/id/Edit.vue')
                },
                {
                  path: 'vote',
                  name: AllRouteNames.VotePoll,
                  component: () => import('../views/calendar/schedule/poll/PrivateVote.vue')
                }
              ]
            }
          ]
        },
        {
          path: '/schedules',
          component: {
            render: (c) => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreateSchedule,
              redirect: { name: AllRouteNames.CreateArrangement }
            },
            {
              path: ':id',
              component: () => import('@/views/calendar/schedule/private/id/Root.vue'),
              children: [
                {
                  path: '',
                  redirect: { name: AllRouteNames.ConfirmSchedule }
                },
                {
                  path: 'edit',
                  name: AllRouteNames.EditSchedule,
                  component: () => import('@/views/personal/schedule/scheduleId/Edit.vue')
                },
                {
                  path: 'confirm',
                  name: AllRouteNames.ConfirmSchedule,
                  component: () => import('../views/calendar/schedule/private/id/PrivateScheduleConfirm.vue')
                },
                {
                  path: 'suggest',
                  name: AllRouteNames.SuggestAlternativeDate,
                  component: () => import('../views/calendar/schedule/private/Suggest.vue')
                }
              ]
            }
          ]
        },
        {
          path: '/events',
          component: {
            render: (c) => c('router-view')
          },
          children: [
            {
              path: 'create',
              name: AllRouteNames.CreateEvent,
              component: () => import('../views/calendar/event/Create.vue')
            },
            {
              path: ':id/edit',
              name: AllRouteNames.EditEvent,
              component: () => import('../views/calendar/event/Edit.vue')
            }
          ]
        },
        {
          path: '/t/:teamId/as/:id/confirm',
          name: AllRouteNames.TeamAvailabilitySharingConfirm,
          component: () => import('@/views/calendar/patterns/availability/team/Confirm.vue')
        },
        {
          path: '/after-confirm',
          name: AllRouteNames.AfterConfirm,
          component: () => import('@/views/AfterConfirm.vue')
        },
        {
          path: '/availability-sharing/create',
          name: AllRouteNames.AvailabilitySharingCreate,
          component: () => import('@/views/availabilitySharing/CreateAvailabilitySharing.vue')
        },
        {
          path: '/patterns/availability-sharing/:id/confirm',
          name: AllRouteNames.AvailabilityConfirm,
          component: () => import('@/views/calendar/patterns/availability/private/Confirm.vue')
        },
        {
          path: '/callback-from-zoom',
          name: AllRouteNames.CallbackFromZoom,
          component: () => import('../views/callback/Zoom.vue')
        },
        {
          path: '/subscription',
          name: AllRouteNames.Subscription,
          component: () => import('../views/subscription/Subscription.vue')
        },
        {
          path: '/account-link',
          name: AllRouteNames.AccountLink,
          component: () => import('../views/accountLink/AccountLink.vue')
        },
        {
          path: '/personal',
          component: {
            render: (c) => c('router-view')
          },
          children: [
            {
              path: 'availability-sharing/:availabilitySharingId/edit',
              name: AllRouteNames.PersonalAvailabilitySharingEdit,
              component: () => import('@/views/personal/availabilitySharing/availabilitySharingId/Edit.vue')
            }
          ]
        },
        {
          path: '/teams/:id',
          beforeEnter: async (to, _, next) => {
            return TeamGuard(to, _, next)
          },
          component: () => import('@/views/teams/id/Root.vue'),
          children: [
            {
              path: 'schedules',
              component: {
                render: (c) => c('router-view')
              },
              children: [
                {
                  path: ':scheduleId',
                  component: () => import('@/views/teams/id/schedules/scheduleId/Root.vue'),
                  children: [
                    {
                      path: 'confirm',
                      name: AllRouteNames.TeamScheduleConfirm,
                      component: () => import('@/views/teams/id/schedules/scheduleId/Confirm.vue')
                    },
                    {
                      path: 'edit',
                      name: AllRouteNames.TeamScheduleEdit,
                      component: () => import('@/views/teams/id/schedule/scheduleId/Edit.vue')
                    }
                  ]
                }
              ]
            },
            {
              path: 'availability-sharing',
              component: {
                render: (c) => c('router-view')
              },
              children: [
                {
                  path: ':availabilitySharingId/edit',
                  name: AllRouteNames.TeamAvailabilitySharingEdit,
                  component: () => import('@/views/teams/id/availabilitySharing/availabilitySharingId/Edit.vue')
                }
              ]
            },
            {
              path: 'settings',
              component: () => import('@/views/teams/id/settings/TeamSettingsRoot.vue'),
              redirect: { name: AllRouteNames.TeamSettingMember },
              children: [
                {
                  path: '',
                  name: AllRouteNames.TeamSettingRoot,
                  redirect: { name: AllRouteNames.TeamSettingMember }
                },
                {
                  path: 'member',
                  name: AllRouteNames.TeamSettingMember,
                  component: () => import('@/views/teams/id/settings/TeamMember.vue')
                },
                {
                  path: 'my-calendar',
                  name: AllRouteNames.TeamSettingMyCalendar,
                  component: () => import('@/views/teams/id/settings/TeamMyCalendar.vue')
                },
                {
                  path: 'team-info',
                  name: AllRouteNames.TeamInfoSetting,
                  component: () => import('@/views/teams/id/settings/TeamDetails.vue')
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/teams/invitations/invited-token-expired',
      name: AllRouteNames.TeamMemberTokenExpired,
      component: () => import('@/views/teams/id/invitations/TeamMemberInvitedTokenExpired.vue')
    },
    {
      path: '/teams/:id/invitations/:invitationId',
      name: AllRouteNames.TeamMemberInvited,
      component: () => import('@/views/teams/id/invitations/TeamMemberInvited.vue'),
      beforeEnter: TeamInvitedGuard
    },
    {
      path: '/profile',
      name: AllRouteNames.ProfileView,
      component: () => import('../views/profile/ProfileView.vue')
    },
    {
      path: '/profile/:id',
      name: AllRouteNames.ProfileDetails,
      component: () => import('../views/profile/ProfileView.vue')
    },
    {
      path: '/profile-edit',
      name: AllRouteNames.ProfileEdit,
      component: () => import('../views/profile/ProfileEdit.vue')
    },
    {
      path: '/callback-from-ms',
      name: AllRouteNames.CallbackFromMS,
      component: () => import('../views/callback/MS.vue')
    },
    {
      path: '/callback-from-google',
      name: AllRouteNames.CallbackFromGoogle,
      component: () => import('../views/callback/google.vue')
    },
    {
      path: '/settings',
      name: AllRouteNames.Settings,
      component: () => import('@/views/settings/Settings.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

const pageToSendSignal: { [key in RouteNames]?: PageAccessSignalType } = {
  CreateArrangement: 'ACCESS_ARRANGEMENT_CREATE',
  EditSchedule: 'ACCESS_SCHEDULE_EDIT',
  TeamScheduleEdit: 'ACCESS_SCHEDULE_EDIT',
  ConfirmSchedule: 'ACCESS_SCHEDULE_CONFIRM',
  EditPoll: 'ACCESS_GROUPPOLL_EDIT',
  VotePoll: 'ACCESS_GROUPPOLL_VOTE',
  AvailabilitySharingCreate: 'ACCESS_PUBLICURL_CREATE',
  PersonalAvailabilitySharingEdit: 'ACCESS_PUBLICURL_EDIT',
  TeamAvailabilitySharingEdit: 'ACCESS_PUBLICURL_EDIT',
  AvailabilityConfirm: 'ACCESS_PUBLICURL_CONFIRM',
  CreateEvent: 'ACCESS_EVENT_CREATE',
  EditEvent: 'ACCESS_EVENT_EDIT',
  ProfileView: 'ACCESS_MYPROFILE',
  SignUp: 'ACCESS_SIGNUP',
  SignIn: 'ACCESS_SIGNIN'
}
router.beforeEach((to, from, next) => {
  AppModule.SET_PAGE_LOADING(true)
  next()
})
router.afterEach(async (to, _) => {
  AppModule.SET_PAGE_LOADING(false)
  const mySignal = pageToSendSignal[to.name]
  if (mySignal) {
    await Vue.prototype.$analytics.send(mySignal, { ...to.params })
  }
  await Vue.prototype.$analytics.send(SignalType.PAGE_ACCESS, { name: to.name, params: to.params, query: to.query })
  window.tracker.view()
})
export default router
