import { SendUserInfoToExternalService } from '@/models/data/userInfo'

const defaultChannelTalkSetting = {
  pluginKey: process.env.VUE_APP_CHANNEL_TALK_KEY,
  hideChannelButtonOnBoot: true,
  language: 'ja',
  profile: null
}

class ChannelService {
  sendUserInfo(userProfile?: SendUserInfoToExternalService) {
    if (userProfile) {
      // https://developers.channel.io/docs/web-boot-option
      window.ChannelIO('boot', {
        ...defaultChannelTalkSetting,
        memberId: userProfile.id,
        profile: userProfile
      })
    } else {
      window.ChannelIO('boot', defaultChannelTalkSetting)
    }
  }
  updateTeamInfo(teams: string[], roles: string[]) {
    // https://developers.channel.io/docs/web-channel-io#updateuser
    window.ChannelIO('updateUser', {
      profileOnce: {
        teams,
        roles
      }
    })
  }
  showMessenger() {
    window.ChannelIO('showMessenger')
  }
  shutdown() {
    window.ChannelIO('shutdown')
  }
  showChannelButton() {
    window.ChannelIO('showChannelButton')
  }
  hideChannelButton() {
    window.ChannelIO('hideChannelButton')
  }
}

export const channelTalkService = new ChannelService()
