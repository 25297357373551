







import { defineComponent } from '@vue/composition-api'
import TipMarkBase from './tipMark/TipMarkBase.vue'

export default defineComponent({
  components: {
    TipMarkBase
  },
  props: {
    line1: {
      type: String,
      required: true
    },
    line2: {
      type: String
    }
  }
})
