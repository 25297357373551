






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class List extends Vue {
  @Prop({ default: false }) rounded: boolean
  @Prop({ default: false }) outlined: boolean
  @Prop({ default: '#ffffff' }) backgroundColor: string // html color code
}
