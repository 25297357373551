











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NavigationButton extends Vue {
  @Prop() iconName: string
  @Prop() shortkey: string
}
