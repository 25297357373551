

























import IconButton from '@/components/atoms/IconButton.vue'
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ValidationObserver } from 'vee-validate'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import AuthenticationMixin from '@/components/molecules/auth/AuthenticationMixin'
import firebase from 'firebase/app'
import LinkMessage from '@/components/atoms/LinkMessage.vue'

@Component({
  components: {
    ValidationObserver,
    WithValidationInput,
    LinkMessage,
    IconButton
  }
})
export default class SignInWithPassword extends mixins(AuthenticationMixin) {
  email = ''
  password = ''

  async loginWithPassword() {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .then(() => (window.location.href = (this.$route.query.redirect || '/') as string))
      .catch((error) => {
        this.$buefy.toast.open({
          message: error,
          type: 'is-danger'
        })
      })
  }
}
