



import PersonalPollEditPage from '@/components/page/PersonalPollEditPage/index.vue'
import RouterGuard from '@/views/calendar/schedule/poll/guard'
import { defineComponent, ref } from '@vue/composition-api'
import { useArrangementChangeGuard } from '@/composables/useArrangementChangeGuard'
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import queryParams from '@/lib/queryParams'

export default defineComponent({
  components: {
    PersonalPollEditPage
  },
  setup() {
    const page = ref(null)
    const { guard } = useArrangementChangeGuard()
    function guardBeforeRouteLeave(...args: Parameters<NavigationGuard>) {
      guard(page.value.formData.shouldStayHere, args)
    }
    return {
      confirmId: queryParams.QUERY_PARAM_CONFIRMED_ID,
      page,
      guardBeforeRouteLeave
    }
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    return RouterGuard(to, from, next)
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.guardBeforeRouteLeave(to, from, next)
  }
})
