






















import FormCollapseBorderedCard from '@/components/ui/form/FormCollapseBorderedCard.vue'
import {
  DateSlot,
  dateSlotPeriodTitle,
  DatetimeSlotDeletePayload,
  DatetimeSlotSavePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import FormDatetimeSlotsBox from '@/components/ui/form/FormDatetimeSlotsBox/FormDatetimeSlotsBox.vue'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { useTranslation } from '@/composables/useTranslation'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import HelpParagraph from '@/components/ui/domain/item/_HelpParagraph.vue'

export default defineComponent({
  name: 'FormExceptionDatetimeListItem',
  components: {
    FormItemSpacer,
    FormItem,
    FormItemTitle,
    HelpParagraph,
    FormDatetimeSlotsBox,
    FormCollapseBorderedCard
  },
  props: {
    dateSlots: {
      type: Array as PropType<DateSlot[]>,
      default: () => []
    },
    onAllDelete: {
      type: Function as PropType<() => void>,
      required: true
    },
    onSave: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>,
      required: true
    },
    onDatetimeDelete: {
      type: Function as PropType<(payload: DatetimeSlotDeletePayload) => void>,
      required: true
    },
    onDatetimeUpdate: {
      type: Function as PropType<(payload: DatetimeSlotUpdatePayload) => void>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const title = computed(() => {
      return dateSlotPeriodTitle(props.dateSlots, i18n.t('availabilitySharing.form.exception.emptyList').toString())
    })
    return {
      title
    }
  }
})
