























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import Divider from '@/components/atoms/Divider.vue'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'

@Component({
  components: {
    ValidationObserver,
    CommonLayoutModal: () => Promise.resolve(CommonLayoutModal),
    Divider,
    WithValidationInput
  }
})
export default class InviteMemberModal extends Vue {
  @Prop({ default: [] }) existEmails: string[]

  inputValues = ['']

  get isEmptyLastValue() {
    const lastValue = this.inputValues[this.inputValues.length - 1]
    return !lastValue
  }

  get inputEmails() {
    return this.inputValues
  }

  get inviteEmails() {
    return this.inputValues.filter((value) => value)
  }

  get ignoreEmails() {
    return (index: number) => {
      const inputEmails = [...this.inputValues.slice(0, index), ...this.inputValues.slice(index + 1)]
      return [...this.existEmails, inputEmails]
    }
  }

  onChangeInviteEmail(index: number, email: string) {
    this.inputValues = [...this.inputValues.slice(0, index), email, ...this.inputValues.slice(index + 1)]
  }

  onSubmitInviteEmails() {
    this.$emit('submit', this.inviteEmails)
  }

  addInviteEmail() {
    this.inputValues = [...this.inputValues, '']
  }

  deleteInviteEmail(index: number) {
    this.inputValues = [...this.inputValues.slice(0, index), ...this.inputValues.slice(index + 1)]
  }

  close() {
    this.$emit('close')
  }
}
