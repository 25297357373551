import EditScheduleTeamModule from '@/store/modules/editScheduleTeam'
import { RouteNames, AllRouteNames } from '@/router'
import { ToastProgrammatic as Toast } from 'buefy'

const MAX_DURATION = 1000 * 60 * 10
const DURATION_FOR_SYNC_MESSAGE = 3000

let setTimer = null
let toastComponent = null

export const resetTimer = () => {
  if (toastComponent) {
    toastComponent.close()
  }
  if (setTimer) {
    clearTimeout(setTimer)
    setTimer = null
  }
}
export const syncScheduleCheck = (
  currentPathName: RouteNames,
  teamId: string,
  scheduleId: string,
  waitMessage: string
) => {
  const pollingSchedule = async () => {
    if (currentPathName === AllRouteNames.TeamScheduleEdit) {
      await EditScheduleTeamModule.setScheduleAsEditingSchedule({
        teamId: teamId,
        scheduleId: scheduleId,
        hideLoading: true
      })
    } else {
      await EditScheduleTeamModule.setScheduleForConfirm({
        teamId: teamId,
        scheduleId: scheduleId,
        hideLoading: true
      })
    }
    if (!EditScheduleTeamModule.areAllEventsSynced) {
      setTimer = setTimeout(pollingSchedule, DURATION_FOR_SYNC_MESSAGE)
    } else {
      toastComponent.close()
    }
  }
  if (!EditScheduleTeamModule.areAllEventsSynced) {
    toastComponent = Toast.open({
      message: waitMessage,
      type: 'is-info',
      position: 'is-bottom',
      duration: MAX_DURATION
    })
    pollingSchedule()
  }
}
