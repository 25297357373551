























































import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { AvailabilityOverviewModel, AvailabilityOverviewModelForTeam } from '@/models/data'
import AvailabilitySharingConfirmSkeltonOverview from '@/components/sidePanels/confirm/availabilitySharing/AvailabilitySharingConfirmSkeltonOverview.vue'
import ConfirmItemSpacer from '@/components/sidePanels/confirm/atoms/ConfirmItemSpacer.vue'
import CollapseText from '../atoms/CollapseText.vue'
import AvailabilitySharingHostMembersInfo from '@/components/sidePanels/confirm/availabilitySharing/AvailabilitySharingHostMembersInfo.vue'
import ConfirmDescription from '@/components/sidePanels/confirm/ConfirmDescription.vue'
import ConfirmMap from '@/components/sidePanels/confirm/ConfirmMap.vue'
import ConfirmToolLink from '@/components/sidePanels/confirm/ConfirmToolLink.vue'
import Divider from '@/components/atoms/Divider.vue'
import ConfirmCandidateBlockTitleBox from '@/components/sidePanels/confirm/atoms/ConfirmCandidateBlockTitleBox.vue'
import ConfirmTimeZoneSelect from '@/components/sidePanels/confirm/atoms/ConfirmTimeZoneSelect.vue'
import ConfirmCandidateListBox from '@/components/sidePanels/confirm/ConfirmCandidateListBox.vue'
import ConfirmSkeltonCandidateListBox from '@/components/sidePanels/confirm/ConfirmSkeltonCandidateListBox.vue'
import ConfirmEmptyCandidateListBox from '@/components/sidePanels/confirm/ConfirmEmptyCandidateListBox.vue'
import candidateDates from '@/filters/candidateDates'
import { useDeviceInfo } from '@/composables/useDeviceInfo'
import { useAppScreenInfo } from '@/composables/useAppScreenInfo'
import { FullCalendarEvent } from '@/types'
import TitleAndLanguage from '@/components/sidePanels/confirm/TitleAndLanguage.vue'

export type LoadingOverviewState = {
  type: 'loading'
}
export type ReadingOverviewState =
  | ({
      type: 'reading'
    } & AvailabilityOverviewModel)
  | ({
      type: 'reading'
    } & AvailabilityOverviewModelForTeam)

export type OverviewState = LoadingOverviewState | ReadingOverviewState

export type LoadingAvailabilitySharingListState = {
  type: 'loading'
}
export type EmptygAvailabilitySharingListState = {
  type: 'empty'
}
export type SelectingAvailabilitySharingListState = {
  type: 'selecting'
  candidates: FullCalendarEvent[]
}
export type AvailabilitySharingListState =
  | LoadingAvailabilitySharingListState
  | SelectingAvailabilitySharingListState
  | EmptygAvailabilitySharingListState

export default defineComponent({
  components: {
    AvailabilitySharingConfirmSkeltonOverview,
    ConfirmItemSpacer,
    CollapseText,
    AvailabilitySharingHostMembersInfo,
    ConfirmDescription,
    ConfirmMap,
    ConfirmToolLink,
    Divider,
    ConfirmCandidateBlockTitleBox,
    ConfirmTimeZoneSelect,
    ConfirmCandidateListBox,
    ConfirmSkeltonCandidateListBox,
    ConfirmEmptyCandidateListBox,
    TitleAndLanguage
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    overviewState: {
      type: Object as PropType<OverviewState>,
      required: true
    },
    availabilitySharingListState: {
      type: Object as PropType<AvailabilitySharingListState>,
      required: true
    },
    onCandidateSelect: {
      type: Function as PropType<(payload: FullCalendarEvent) => Promise<void>>,
      required: true
    },
    onCandidateHover: {
      type: Function as PropType<(payload: FullCalendarEvent) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const { overviewState, availabilitySharingListState } = toRefs(props)
    const overviewReadState = computed(() => {
      let hasOnlineMeeting
      if (overviewState.value.type !== 'loading') {
        if ('onlineMeeting' in overviewState.value) {
          hasOnlineMeeting = overviewState.value?.onlineMeeting.type !== 'none'
        } else {
          hasOnlineMeeting = overviewState.value?.prioritizedOnlineMeetings?.length > 0
        }
      }
      const state =
        overviewState.value.type === 'loading'
          ? {
              isOverviewSkeltonOpen: true
            }
          : {
              isOverviewSkeltonOpen: false,
              title: overviewState.value.title,
              candidateDescription: overviewState.value?.candidateDescription,
              author: overviewState.value?.organizer,
              attendees: overviewState.value?.attendees || [],
              description: overviewState.value?.description,
              location: overviewState.value?.location,
              hasOnlineMeeting: hasOnlineMeeting,
              dateRange: candidateDates(overviewState.value),
              duration: overviewState.value?.duration
            }
      return state
    })
    const { isNarrow } = useDeviceInfo()
    const { openCalendar } = useAppScreenInfo()
    const candidateListState = computed(() => {
      switch (availabilitySharingListState.value.type) {
        case 'loading':
          return {
            type: 'skelton'
          }
        case 'empty':
          return {
            type: 'empty'
          }
        default:
          return {
            type: 'normal',
            candidates: availabilitySharingListState.value.candidates
          }
      }
    })
    const isTimezoneOpen = computed(
      () => !overviewReadState.value.isOverviewSkeltonOpen && candidateListState.value.type === 'normal'
    )

    return {
      overviewReadState,
      isNarrow,
      candidateListState,
      isTimezoneOpen,
      handleCalendarOpen: openCalendar,
      handleCandidateSelect: props.onCandidateSelect,
      handleCandidateHover: props.onCandidateHover
    }
  }
})
