















import InlineTimeZoneSelector from '@/components/organisms/timezoneSelector/InlineTimezoneSelector.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import LanguageDropdown from '@/components/ui/domain/dropdown/LanguageDropdown/index.vue'
import { FrontSupportLanguage } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxLanguageAndTimeZone',
  components: {
    LanguageDropdown,
    InlineTimeZoneSelector,
    FormItemTitle,
    FormItemSpacer
  },
  props: {
    timeZone: {
      type: String,
      required: true
    },
    language: {
      type: String as PropType<FrontSupportLanguage>,
      required: true
    },
    onTimeZoneChange: {
      type: Function as PropType<(newTZ: string) => void>,
      required: true
    },
    onLanguageUpdate: {
      type: Function as PropType<(lang: FrontSupportLanguage) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const currentLanguage = computed({
      get: () => props.language,
      set: (l: FrontSupportLanguage) => props.onLanguageUpdate(l)
    })
    return {
      currentLanguage
    }
  }
})
