




import { parseISO } from 'date-fns'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export default defineComponent({
  props: {
    date: {
      type: [String, Date] as PropType<string | Date>,
      required: true
    }
  },
  setup(props) {
    const { date } = toRefs(props)
    const titleDate = computed(() => {
      const d = typeof date.value === 'object' ? date.value : parseISO(date.value)
      return spirDateFormat(d, spirDateFormatTypes.yyyymmddweekday)
    })
    return {
      titleDate
    }
  }
})
