











































import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import { startSelects, endSelects, EndSelects, StartSelects } from '@/lib/utils/peekCandidates'
import DatePicker from '@/components/ui/spir/SpirDatePicker.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import SubItemInItem from '@/components/newForm/atoms/SubItemInItem.vue'

@Component({
  components: {
    FormItem,
    DatePicker,
    SubItemInItem
  }
})
export default class PatternFormConditionDate extends Vue {
  @PropSync('start', { type: String }) startSynced: StartSelects | string
  @PropSync('end', { type: String }) endSynced: EndSelects | string
  @PropSync('maxNumPerDay', { type: Number }) maxNumPerDaySynced: number[]
  @Prop({ default: false }) disabledMaxCount: boolean
  @Prop({ default: () => new Date() }) minEndDate: Date
  @Prop({ default: false }) hideMaxDay: boolean

  endSelected: EndSelects | 'custom' | null = null

  startSelections: Array<string> = [...startSelects]
  endSelections: Array<string> = [...endSelects]

  mounted() {
    if (this.isEndDateFormat) {
      this.endSelected = 'custom'
    } else {
      this.endSelected = this.endSynced as EndSelects
    }
  }
  get endDateAsDateFormat() {
    const endDate = new Date(this.endSynced)
    return endDate
  }
  get isEndDateFormat() {
    return this.endSynced === 'custom' || this.endDateAsDateFormat.toString() !== 'Invalid Date'
  }
  startUpdated(newValue) {
    if (newValue === this.startSynced) {
      return
    }
    this.$emit('input')
  }
  endUpdated(newValue) {
    if (newValue === this.endSynced) {
      return
    }

    if (newValue === 'custom') {
      if (!this.isEndDateFormat) {
        this.endSynced = this.minEndDate.toISOString()
      }
    } else {
      this.endSynced = newValue
    }
    this.$emit('input')
  }
  dateUpdated(updatedEndDate: string) {
    this.endSynced = updatedEndDate
    this.$emit('input')
  }
  updateMaxDayCount(e) {
    this.$emit('input', e)
  }
}
