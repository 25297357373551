










import ConfirmedScheduleTimeInfoCardBodyItem from '@/components/card/atoms/ConfirmedScheduleTimeInfoCardBodyItem.vue'
import OwnerInfoCardBodyItem from '@/components/card/atoms/OwnerInfoCardBodyItem.vue'
import CardTitle from '@/components/ui/CardTitle.vue'
import { ConfirmedPrivateCardInfo } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    CardTitle,
    OwnerInfoCardBodyItem,
    ConfirmedScheduleTimeInfoCardBodyItem
  },
  props: {
    info: {
      type: Object as PropType<ConfirmedPrivateCardInfo>,
      required: true
    }
  }
})
