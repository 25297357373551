





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICalendar } from '@/types/schedule'
import { validate } from 'vee-validate'

@Component
export default class FormCalendarSelector extends Vue {
  @Prop() placeholder: string
  @Prop() icon: string
  @Prop({ default: () => [] }) targetCalendars: ICalendar[]
  @Prop({ default: false }) allowDirectInput: boolean
  @Prop({ default: () => [] }) extractList: string[]

  inputedValue = ''
  isValidEmail = false
  listForAutoComplete = []

  $refs: {
    autocomplete: any
  }

  async updateList() {
    if (!this.inputedValue) {
      this.listForAutoComplete = this.targetCalendars
      return
    }
    if (this.extractList.indexOf(this.inputedValue) >= 0) {
      this.listForAutoComplete = []
      return
    }
    const filterdList = this.targetCalendars.filter((t) => {
      const searchTargetAttrs = [t.accountId, t.id, t.invitationAddress, t.title]
      return searchTargetAttrs.join(' ').toLowerCase().indexOf(this.inputedValue.toLowerCase()) >= 0
    })
    if (filterdList.length > 0) {
      this.listForAutoComplete = filterdList
      return
    }
    if (!this.allowDirectInput) {
      this.listForAutoComplete = []
      return
    }
    const validResult = await validate(this.inputedValue, 'required|email')
    if (validResult.valid) {
      this.listForAutoComplete = [
        {
          title: this.inputedValue,
          invitationAddress: this.inputedValue
        }
      ]
    } else {
      this.listForAutoComplete = []
    }
  }
  selected(selectedItem: ICalendar) {
    if (!selectedItem) {
      return
    }
    this.$emit('addCalendar', {
      accountId: selectedItem.accountId,
      calendarId: selectedItem.id,
      email: selectedItem.invitationAddress
    })
    // hack: 選択後、リストの一番目のDataが選択されるBugを回避するため。
    if (this.$refs.autocomplete) {
      this.$refs.autocomplete.isActive = false
    }
    this.inputedValue = ''
  }
  async onBlur() {
    setTimeout(() => {
      this.inputedValue = ''
    }, 200)
  }
  onFocus() {
    this.listForAutoComplete = this.targetCalendars
  }
}
