















import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import MemberItem from './_MemberItem.vue'
import { MemberCalendar } from '@spirinc/contracts'

export default defineComponent({
  name: 'TeamMemberDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem,
    MemberItem
  },
  props: {
    value: {
      type: String // memberId
    },
    memberCalendars: {
      type: Array as PropType<MemberCalendar[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { value, memberCalendars } = toRefs(props)

    const selectedMemberCalendar = computed(() => memberCalendars.value.find((mc) => mc.id === value.value))

    function handleChange(newValue: string) {
      emit('input', newValue)
    }

    return {
      selectedMemberCalendar,
      handleChange
    }
  }
})
