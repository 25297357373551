/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-undef */
;(function () {
  let t, e, n, r, a
  for (
    t = function () {
      let t
      return (
        (t = []),
        (function () {
          let e, n, r, a
          for (
            n = [
              'init',
              'start',
              'stop',
              'user',
              'track',
              'action',
              'event',
              'goal',
              'chat',
              'buy',
              'page',
              'view',
              'admin',
              'group',
              'alias',
              'ready',
              'link',
              'form',
              'click',
              'submit',
              'cmd',
              'emit',
              'on',
              'send',
              'css',
              'js',
              'style',
              'option',
              'get',
              'set',
              'collection'
            ],
              e = function (e) {
                return function () {
                  return t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                }
              },
              r = 0,
              a = [];
            r < n.length;

          )
            (t[n[r]] = e(n[r])), a.push(r++)
          return a
        })(),
        (t.init = function (e, n) {
          let r, a
          return (
            (t.api_key = e),
            (t.options = n || {}),
            (a = document.createElement('script')),
            (a.type = 'text/javascript'),
            (a.async = !0),
            (a.charset = 'utf-8'),
            (a.src = t.options.tracker_url || 'https://static.karte.io/libs/tracker.js'),
            (r = document.getElementsByTagName('script')[0]),
            r.parentNode.insertBefore(a, r)
          )
        }),
        t
      )
    },
      r = window.karte_tracker_names || ['tracker'],
      e = 0,
      n = r.length;
    n > e;
    e++
  )
    (a = r[e]), window[a] || (window[a] = t())
  tracker.init(`${process.env.VUE_APP_KARTE_ID}`, {
    spa_mode: { reset_past_actions: true, close_actions: true }
  })
}.call(this))
