

















import { formatEventTimes } from '@/lib/utils/date'
import { validateCandidate } from '@/lib/utils'
import { FullCalendarEvent } from '@/types'
import ConfirmText from '../atoms/ConfirmText.vue'
import ConfirmCandidateSelectButton from '../atoms/ConfirmCandidateSelectButton.vue'
import SuggestAlternateButtons from '../atoms/SuggestAlternateDate/SuggestAlternateButtons.vue'
import DatetimeSlotEditor from '@/components/ui/form/FormDatetimeSlotsBox/DatetimeSlotEditor.vue'
import { computed, defineComponent, onMounted, PropType, ref, toRefs } from '@vue/composition-api'
import { DatetimeSlotSavePayload } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'

export default defineComponent({
  components: {
    ConfirmText,
    ConfirmCandidateSelectButton,
    SuggestAlternateButtons,
    DatetimeSlotEditor
  },
  props: {
    event: {
      type: Object as PropType<FullCalendarEvent>,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    onEventUpdate: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>
    },
    onEventDelete: {
      type: Function as PropType<(eventId: string) => void>
    }
  },
  setup(props) {
    const { duration } = props
    const { event } = toRefs(props)
    const timeRange = ref('')
    const isCandidateValid = ref(true)
    const isDatetimeSlotEditorOpen = ref(false)
    const eventToEdit = { start: event.value.start, end: event.value.end, id: event.value.id }
    const splitEventsByDuration = computed(() => duration)

    function handleDelete() {
      props.onEventDelete(event.value.id)
    }

    function handleEdit() {
      isDatetimeSlotEditorOpen.value = true
    }

    function handleCancel() {
      isDatetimeSlotEditorOpen.value = false
    }

    function handleSave(payload: DatetimeSlotSavePayload) {
      delete payload.allDay
      isDatetimeSlotEditorOpen.value = false
      props.onEventUpdate(payload)
    }

    onMounted(() => {
      timeRange.value = formatEventTimes(event.value)
      isCandidateValid.value = validateCandidate(event.value)
    })

    return {
      timeRange,
      isCandidateValid,
      isDatetimeSlotEditorOpen,
      eventToEdit,
      splitEventsByDuration,
      handleEdit,
      handleCancel,
      handleSave,
      handleDelete
    }
  }
})
