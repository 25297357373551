



























import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from '../CommonLayout.vue'
import TimezoneEdit from '@/components/organisms/TimezoneEdit.vue'
import { TimeZones } from '@/models/data/userInfo'
import { cloneDeep } from 'lodash'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    ValidationObserver,
    CommonLayoutModal,
    TimezoneEdit
  }
})
export default class TimeZoneSelectModal extends Vue {
  @Prop() timeZones: TimeZones

  isDirty = false
  timeZonesForEdit: TimeZones = null

  timeZoneForEdit = ''
  timeZoneDisplayNameForEdit = ''

  created() {
    this.timeZonesForEdit = cloneDeep(this.timeZones)
  }
  handleUpdatedTimezone() {
    this.isDirty = true
  }
  async selectTimezone() {
    this.$emit('updateTimezoneInfo', this.timeZonesForEdit)
  }
}
