





















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import DateTimeForm from '@/components/organisms/DateTimeForm.vue'
import { addMinutes, subMinutes, differenceInMinutes, isBefore, isAfter, isEqual } from 'date-fns'

@Component({
  components: {
    DateTimeForm
  }
})
export default class DateRangeInput extends Vue {
  @Prop() eventToEdit
  @Prop() splitEventsByDuration: number
  @Prop({ default: false }) fixDuration: boolean
  @Prop({ default: true }) disabledPast
  @Prop() minInterval
  startDate: Date = null
  endDate: Date = null

  created() {
    if (this.eventToEdit) {
      this.startDate = this.eventToEdit.start
      this.endDate = this.eventToEdit.end
    }
  }
  @Watch('endDate')
  updatedEndDate() {
    if (!this.endDate) {
      return
    }
    this.setStartDate()
  }
  @Watch('startDate')
  updatedStartDate() {
    if (!this.startDate) return
    if (this.startDate === this.eventToEdit?.start && this.endDate === this.eventToEdit?.end) return
    this.setEndDate()
  }
  @Watch('splitEventsByDuration')
  updateEndDateAsDuration() {
    if (!this.startDate) {
      return
    }
    const minutesEndDateAndStarDate = differenceInMinutes(this.endDate, this.startDate)
    if (minutesEndDateAndStarDate < this.splitEventsByDuration) {
      this.setEndDate()
    }
  }
  setEndDate() {
    const newEndDate = addMinutes(this.startDate, this.splitEventsByDuration)
    if ((this.fixDuration && !isEqual(this.endDate, newEndDate)) || isBefore(this.endDate, newEndDate)) {
      this.endDate = newEndDate
    }
  }
  setStartDate() {
    const newStartdate = subMinutes(this.endDate, this.splitEventsByDuration)
    if ((this.fixDuration && !isEqual(this.startDate, newStartdate)) || isAfter(this.startDate, newStartdate)) {
      this.startDate = newStartdate
    }
  }
  reset() {
    this.startDate = this.endDate = null
  }

  cancel() {
    this.reset()
    this.$emit('cancel')
  }
  get minEndDate() {
    if (!this.startDate) {
      return null
    }
    return addMinutes(this.startDate, this.splitEventsByDuration)
  }
  get isValid() {
    return this.startDate && this.endDate
  }
  save() {
    const payload = {
      id: this.eventToEdit?.id,
      allDay: false,
      end: this.endDate,
      start: this.startDate
    }
    this.$emit('save', payload)
    this.reset()
  }
}
