






import { defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'

export default defineComponent({
  components: {
    ListItemSpacer
  },
  props: {
    top: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    }
  }
})
