




















import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import PrimaryCalendarSelector from '@/components/newForm/molecules/WritableCalendarWithAccountInfo.vue'
import { AvailabilityModelForPrivate } from '@/models/data'
import AttendeeSelector from '@/components/newForm/molecules/AttendeeSelector.vue'
import { ITypeCalendarListForUI } from '@/types'
import { AvailabilityCalendarType } from '@spirinc/contracts'
import TargetCalendars from './TargetCalendars.vue'

@Component({
  components: {
    FromGroup,
    PrimaryCalendarSelector,
    AttendeeSelector,
    TargetCalendars
  }
})
export default class PatternSidePanelOrganizerAndAttendee extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: AvailabilityModelForPrivate
  @Prop() accountWithcalendars
  @Prop() allCalendarsButNotResource: ITypeCalendarListForUI[]

  updateCondition() {
    this.$emit('updateCondition')
  }
  get availableAttendeeList() {
    return this.allCalendarsButNotResource.filter(
      (c) =>
        c.invitationAddress &&
        !(
          c.accountId === this.editingObjectSynced.organizer.accountId &&
          c.calendarId === this.editingObjectSynced.organizer.calendarId
        )
    )
  }
  deleteFromCalendars(targetCalendar: AvailabilityCalendarType) {
    const findIndex = this.editingObjectSynced.calendars.findIndex(
      (s) => s.accountId === targetCalendar.accountId && s.calendarId === targetCalendar.calendarId
    )
    if (findIndex >= 0) {
      this.editingObjectSynced.calendars.splice(findIndex, 1)
    }
  }
  deleteFromAttendees(targetCalendar: AvailabilityCalendarType) {
    const findIndex = this.editingObjectSynced.attendees.findIndex(
      (s) => s.accountId === targetCalendar.accountId && s.calendarId === targetCalendar.calendarId
    )
    if (findIndex >= 0) {
      this.editingObjectSynced.attendees.splice(findIndex, 1)
    }
  }
  addToCalendar(targetCalendar: AvailabilityCalendarType) {
    if (
      !this.editingObjectSynced.calendars.some(
        (s) => s.accountId === targetCalendar.accountId && s.calendarId === targetCalendar.calendarId
      )
    ) {
      this.editingObjectSynced.calendars.push(targetCalendar)
    }
  }
  selectedNewPrimaryCalendar(selectedCalendar: AvailabilityCalendarType) {
    this.deleteFromCalendars(this.editingObjectSynced.organizer)
    this.deleteFromAttendees(selectedCalendar)
    this.addToCalendar(selectedCalendar)
    this.editingObjectSynced.organizer = selectedCalendar
    this.updateCondition()
  }
  addedAttendee(addedAttendee: AvailabilityCalendarType) {
    this.addToCalendar(addedAttendee)
    this.updateCondition()
  }
  deletedAttendee(deletedAttendee: AvailabilityCalendarType) {
    this.deleteFromCalendars(deletedAttendee)
    this.updateCondition()
  }
}
