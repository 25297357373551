import { render, staticRenderFns } from "./NotificationButton.vue?vue&type=template&id=755c6148&scoped=true&"
import script from "./NotificationButton.vue?vue&type=script&lang=ts&"
export * from "./NotificationButton.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationButton.vue?vue&type=style&index=0&id=755c6148&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755c6148",
  null
  
)

export default component.exports