
























import { Component, Prop, Vue } from 'vue-property-decorator'
import TeamHeader from '@/components/teamSettings/headers/TeamHeader.vue'

@Component({
  components: {
    TeamHeader
  }
})
export default class TeamSettingsLayout extends Vue {
  @Prop() title: string

  goBack() {
    this.$router.push({ query: { ...this.$route.query, showMenu: 'true' } })
  }
}
