


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { AvailabilityModelForPrivate } from '@/models/data'
import CalendarsModule from '@/store/modules/calendars'
import WeekdayInfoCardBodyItem from '@/components/card/atoms/WeekdayInfoCardBodyItem.vue'
import OwnerInfoCardBodyItem from '@/components/card/atoms/OwnerInfoCardBodyItem.vue'
import AttendeesCardBodyItem from '@/components/card/molecules/AttendeesCardBodyItem.vue'
import LastUpdateInfoCardBodyItem from '@/components/card/atoms/LastUpdateInfoCardBodyItem.vue'

@Component({
  components: {
    WeekdayInfoCardBodyItem,
    OwnerInfoCardBodyItem,
    AttendeesCardBodyItem,
    LastUpdateInfoCardBodyItem
  }
})
export default class PatternCardBodyForPrivate extends Vue {
  @Prop() pattern: AvailabilityModelForPrivate | null

  get organaizerAccount() {
    return CalendarsModule.accountInfo(this.pattern.organizer.accountId)
  }

  get organaizerCalendar() {
    return this.organaizerAccount.calendars.find((c) => c.id === this.pattern.organizer.calendarId)
  }

  get attendees() {
    return this.pattern.attendees.map((attendee) => {
      const calendar = CalendarsModule.getCalendar({ accountId: attendee.accountId, calendarId: attendee.calendarId })
      return {
        email: attendee.calendarId,
        fullName: calendar.title || attendee.calendarId
      }
    })
  }

  get hasUpdateInfo() {
    return !!this.pattern.updatedAt
  }

  get lastUpdateAt() {
    return this.pattern.updatedAt.toISOString()
  }
}
