











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DailyCalendarTimeGrid extends Vue {
  @Prop() index: number
  @Prop() gridHeight: string

  touchFlag = false
  touchStart() {
    this.touchFlag = true
  }
  touchhold(e) {
    const rect = e.target.getBoundingClientRect()
    const clientY = e.touches[0].clientY
    const offsetY = clientY - window.pageYOffset - rect.top
    const newEvent = {
      offsetY,
      clientY
    }
    this.$emit('mousedown', newEvent, this.index)
  }
  preventContext(e) {
    e.preventDefault()
  }
  mousedown(e) {
    if (this.touchFlag) {
      this.touchFlag = false
      return
    }
    if (e.button && e.button !== 0) {
      // left button click
      return
    }
    this.$emit('mousedown', e, this.index)
  }
}
