












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ListType } from '@/types'
import { ScheduleModel } from '@/models/data'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { EventBus, EVENTS } from '@/lib/eventBus'
import AppModule from '@/store/modules/app'
import Card from '@/components/card/molecules/Card.vue'
import ConfirmedScheduleCardBodyForPrivate from '@/components/card/ConfirmedScheduleCardBodyForPrivate.vue'
import ScheduleListEmptyMessage from './ScheduleListEmptyMessage.vue'

@Component({
  components: {
    Card,
    ConfirmedScheduleCardBodyForPrivate,
    ScheduleListEmptyMessage
  }
})
export default class ConfirmedScheduleList extends Vue {
  @Prop() confirmedSchedules: ScheduleModel[]

  isDisplayedPastSchedules = false
  today: Date = new Date()

  get existsSchedules(): boolean {
    return this.confirmedSchedules.length !== 0
  }

  get existsPastSchedules(): boolean {
    return Object.keys(this.confirmedPastSchedules).length > 0
  }

  get showPastScheduleButton(): boolean {
    return this.existsPastSchedules
  }

  get confirmedPastSchedules() {
    // filter future schedules
    const confirmedPastSchedules = this.confirmedSchedules
      .filter((s) => s.confirmedInfo && new Date(s.confirmedInfo.end) < this.today)
      .sort(this.scheduleDateComparator)
    return this.aggregateSchedulesByDate(confirmedPastSchedules)
  }

  get confirmedFutureSchedules() {
    // filter past schedules
    const confirmedFutureSchedules = this.confirmedSchedules
      .filter((s) => s.confirmedInfo && new Date(s.confirmedInfo.end) >= this.today)
      .sort(this.scheduleDateComparator)
    return this.aggregateSchedulesByDate(confirmedFutureSchedules)
  }
  get isSchedule() {
    return (schedule: ScheduleModel) => {
      if (schedule.type === ListType.SCHEDULE) {
        return true
      }
      return false
    }
  }
  get cardIcon() {
    return (schedule: ScheduleModel) => {
      if (this.isSchedule(schedule)) {
        return 'calendar'
      }
      return 'chart-box'
    }
  }

  async showItem(item: ScheduleModel) {
    AppModule.closeQuickView()
    EventBus.emit(EVENTS.SHOW_EVENT, {
      eventId: item.confirmedInfo?.eventId,
      accountId: item.accountId,
      calendarId: item.calendarId,
      moveDate: true
    })
  }

  togglePastSchedulesDisplayState() {
    this.isDisplayedPastSchedules = !this.isDisplayedPastSchedules
  }

  formatDateForDateHeader(date: Date, today: Date): string {
    return `${this.isSameDay(date, today) ? `${this.$t('scheduleTabs.listItem.today')} ` : ''}${spirDateFormat(
      date,
      spirDateFormatTypes.yyyymmddweekday
    )}`
  }

  aggregateSchedulesByDate(schedules: ScheduleModel[]) {
    const result: { [key: string]: { schedule: ScheduleModel }[] } = {}
    for (const schedule of schedules) {
      if (!schedule.confirmedInfo) {
        continue
      }
      const start = new Date(schedule.confirmedInfo.start)
      const formattedDate = this.formatDateForDateHeader(start, this.today)
      if (!(formattedDate in result)) {
        result[formattedDate] = []
      }
      result[formattedDate].push({ schedule })
    }
    return result
  }

  isSameDay(lhs: Date, rhs: Date) {
    return lhs.getFullYear() === rhs.getFullYear() && lhs.getMonth() === rhs.getMonth() && lhs.getDay() === rhs.getDay()
  }

  scheduleDateComparator(lhs: ScheduleModel, rhs: ScheduleModel) {
    return new Date(lhs.confirmedInfo.start).getTime() - new Date(rhs.confirmedInfo.start).getTime()
  }
}
