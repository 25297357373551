































import FormDescriptionTextareaItem from '@/components/ui/domain/item/FormDescriptionTextareaItem.vue'
import FormLocationInputForm from '@/components/ui/domain/item/FormLocationInputForm/index.vue'
import FormOnlineMeetingDropdownItem from '@/components/ui/domain/item/FormOnlineMeetingDropdownItem/index.vue'
import FormVisibilitySelectBoxItem from '@/components/ui/domain/item/FormVisibilitySelectBoxItem.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OthersInfoSection',
  components: {
    SidePanelFormSectionTitle,
    ListItemSpacer,
    FormDescriptionTextareaItem,
    FormOnlineMeetingDropdownItem,
    FormLocationInputForm,
    FormVisibilitySelectBoxItem
  },
  props: {
    othersInfo: {
      type: Object,
      required: true
    }
  }
})
