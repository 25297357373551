





































import CalendarEventListener from '@/components/functional/CalendarEventListener.vue'
import { CalendarAlertState } from '@/components/layout/types'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import {
  AvailabilitySharingListState,
  OverviewState
} from '@/components/sidePanels/confirm/availabilitySharing/AvailabilitySharingConfirmOperationBox.vue'
import AvailabilitySharingConfirmSidePanelBox from '@/components/sidePanels/confirm/availabilitySharing/AvailabilitySharingConfirmSidePanelBox.vue'
import AvailabilitySharingConfirmSidePanelFooter, {
  FooterButtonsState
} from '@/components/sidePanels/confirm/availabilitySharing/AvailabilitySharingConfirmSidePanelFooter.vue'
import { useAnalytics } from '@/composables/useAnalytics'
import { useBuefy } from '@/composables/useBuefy'
import { useConfirmation } from '@/composables/useConfirmation'
import { useDeviceInfo } from '@/composables/useDeviceInfo'
import { useScheduleEventListPanel } from '@/composables/useScheduleEventListPanel'
import i18n from '@/i18n'
import { SignalType } from '@/lib/analytics/LogEntry'
import { inConfirmationPage } from '@/lib/utils'
import ConfirmAvailabilityModule from '@/store/modules/confirmAvailability'
import EventToConfirmModule from '@/store/modules/eventToConfirm'
import { FullCalendarEvent, FullCalendarMouseEvent } from '@/types'
import Common from '@/views/calendar/Common.vue'
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from '@vue/composition-api'
import { addMinutes } from 'date-fns'
import { useRoute } from 'vue2-helpers/vue-router'

export default defineComponent({
  components: {
    Common,
    ConfirmPageHeader,
    CalendarEventListener,
    AvailabilitySharingConfirmSidePanelBox,
    AvailabilitySharingConfirmSidePanelFooter
  },
  props: {
    onPageMount: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const analytics = useAnalytics()
    const { isNarrow } = useDeviceInfo()
    const route = useRoute()
    const buefy = useBuefy()
    const {
      firstDate,
      isSignIn,
      isScheduleEventListOpen,
      currentPageId,
      noConnectedAccount,
      initCommonProcess,
      setCalendarDate,
      handleMouseLeave,
      handleEventHover,
      updateConfirmEvent,
      updateConfirmEventByMouseEvent
    } = useConfirmation()
    const accessedWithConfirmStart = ref(false)

    const confirmDateFromQueryParam = computed((): null | Date => {
      return inConfirmationPage.getConfirmDateFromQueryParam(route)
    })

    const isShowCalendarFooter = computed(() => {
      if (!isNarrow.value) return false
      else {
        // モバイル端末で、
        // カレンダー表示 x ログイン中 以外はFooterを表示
        return !(isScheduleEventListOpen.value && isSignIn.value)
      }
    })
    const canConfirm = computed(() => {
      return !noConnectedAccount.value && !!EventToConfirmModule.eventToConfirm
    })
    function handleConfirmClick(params?: { cancelBtn?: string }) {
      emit('confirmAvailability', params)
    }
    const mobileFooterButtonState = computed((): FooterButtonsState => {
      // モバイル端末(Footerno表示条件の限定は上のisShowCalendarFooterで決定)
      // リスト表示 x ログイン無し => サインインボタンのみ
      if (!isSignIn.value && isScheduleEventListOpen.value) return { type: 'only_signin_button' }
      else if (isSignIn.value && !isScheduleEventListOpen.value) {
        // カレンダー表示 x ログインあり => 確認ボタンのみ
        return {
          type: 'only_confirm_button',
          confirmationDisabled: !canConfirm.value,
          onConfirmClick: handleConfirmClick
        }
      } else {
        // カレンダー表示 x ログイン無し => サインボタン&確認ボタン
        return { type: 'all', confirmationDisabled: !canConfirm.value, onConfirmClick: handleConfirmClick }
      }
    })
    const availability = computed(() => ConfirmAvailabilityModule.getModel)
    const overview = computed(() => ConfirmAvailabilityModule.getOverview)
    const candidates = computed(() => ConfirmAvailabilityModule.candidatesByConditionAsCalendarFormat)
    const eventToConfirm = computed(() => EventToConfirmModule.eventToConfirm)
    const candidatesByMerged = computed(() => ConfirmAvailabilityModule.candidatesByMerged)
    const isLoading = computed(() => ConfirmAvailabilityModule.getIsLoading)
    const isConfirming = computed(() => ConfirmAvailabilityModule.isConfirming)
    const overviewState = computed((): OverviewState => {
      return ConfirmAvailabilityModule.getIsOverviewLoading
        ? { type: 'loading' }
        : {
            type: 'reading',
            ...overview.value
          }
    })
    const availabilitySharingListState = computed((): AvailabilitySharingListState => {
      if (ConfirmAvailabilityModule.getIsLoading) {
        return { type: 'loading' }
      } else {
        if (candidates.value.length === 0) {
          return { type: 'empty' }
        } else {
          return {
            type: 'selecting',
            candidates: candidates.value
          }
        }
      }
    })
    const noCandidateAlertState = computed((): CalendarAlertState => {
      if (availabilitySharingListState.value.type === 'empty') {
        return {
          type: 'messageOpen',
          title: i18n.t('description.availabilitySharing.noCandidates.message1').toString(),
          message: i18n.t('description.availabilitySharing.noCandidates.message2').toString()
        }
      } else {
        return { type: 'closed' }
      }
    })
    const additionalEvents = computed(() => {
      if (!eventToConfirm.value) {
        return [...candidatesByMerged.value]
      }
      return [...candidatesByMerged.value, eventToConfirm.value]
    })
    async function confirmIfThereIsQueryParam() {
      const startDate = confirmDateFromQueryParam.value
      if (!startDate || noConnectedAccount.value) {
        return
      }
      accessedWithConfirmStart.value = true
    }
    function initConfirm() {
      const startDate =
        confirmDateFromQueryParam.value || inConfirmationPage.getClosestStartingCandidate(candidates.value)?.start
      initCommonProcess({ startDate })
      // get query parameter
      confirmIfThereIsQueryParam()
    }

    async function onScheduleConfirmButtonClick(clickedEvent: FullCalendarEvent, params?: { cancelBtn?: string }) {
      updateConfirmEvent(clickedEvent.start, clickedEvent.end, availability.value.id)
      handleConfirmClick(params)
    }
    async function handleEventMouseEnter(payload: FullCalendarMouseEvent) {
      handleEventHover(availability.value.duration, payload)
    }
    function getCandidateByStartAndEnd(start: Date, end: Date) {
      return inConfirmationPage.getCandidateByStartAndEnd(candidates.value, start, end)
    }
    function showConfirmModal() {
      const startDate = confirmDateFromQueryParam.value
      const endDate: Date = addMinutes(startDate, availability.value.duration)
      const candidate = getCandidateByStartAndEnd(startDate, endDate)
      if (!candidate) {
        const toastOption = inConfirmationPage.getInvalidCandidateToastOption()
        buefy.toast.open(toastOption)
        return
      }
      onScheduleConfirmButtonClick(candidate, {
        cancelBtn: i18n.t('scheduleSharingText.confirmButton').toString()
      })
    }
    function handleClickedCandidate({
      event,
      jsEvent,
      el
    }: {
      event: FullCalendarEvent
      jsEvent: MouseEvent
      el: HTMLElement
    }) {
      analytics.send(SignalType.CLICK_CANDIDATE_ON_CALENDAR, { id: currentPageId })
      updateConfirmEventByMouseEvent(availability.value.duration, event, jsEvent, el)
    }
    function handleUpdateEvent({ event, revert }: { event: FullCalendarEvent; revert: Function }) {
      if (!eventToConfirm.value) {
        return
      }
      const [candidate] = candidatesByMerged.value.filter((s) => {
        return event.start >= new Date(s.start) && event.end <= new Date(s.end)
      })
      if (!candidate) {
        return revert()
      }
      updateConfirmEvent(
        event.start,
        event.end,
        candidate.id,
        event.extendedProps.accountId,
        event.extendedProps.calendarId
      )
    }
    function handleEventHoverOnPanel(clickedEvent: FullCalendarEvent) {
      updateConfirmEvent(clickedEvent.start, clickedEvent.end, availability.value.id)
    }
    function handleEventMouseLeave(payload: FullCalendarMouseEvent) {
      handleMouseLeave(payload)
    }

    watch(availability, (newModel) => {
      if (newModel) {
        setCalendarDate(candidates.value[0]?.start)
        if (accessedWithConfirmStart.value) {
          showConfirmModal()
        }
      }
    })
    onMounted(async () => {
      await props.onPageMount()
      initConfirm()
    })
    onBeforeUnmount(() => {
      ConfirmAvailabilityModule.reset()
      EventToConfirmModule.resetEventToConfirm()
    })
    useScheduleEventListPanel()
    return {
      firstDate,
      isSignIn,
      overview,
      candidates,
      isShowCalendarFooter,
      isLoading,
      isConfirming,
      overviewState,
      availabilitySharingListState,
      additionalEvents,
      canConfirm,
      mobileFooterButtonState,
      noCandidateAlertState,
      handleConfirmClick,
      onScheduleConfirmButtonClick,
      handleEventMouseEnter,
      handleClickedCandidate,
      handleUpdateEvent,
      handleEventHoverOnPanel,
      handleEventMouseLeave
    }
  }
})
