













import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import FormSelectBox from '@/components/newForm/atoms/SelectBox.vue'
import { AVAILABLE_DURATIONS } from '@/lib/utils/durations'
import { adjustDurationUnit } from '@/lib/utils'

@Component({
  components: {
    FormItem,
    FormSelectBox
  }
})
export default class FormDuration extends Vue {
  @Prop({ default: false }) required: boolean
  @Prop({ default: true }) isHalf: boolean
  @Prop({ default: false }) disabled: boolean

  items = []
  created() {
    this.items = AVAILABLE_DURATIONS.map((n) => {
      const displayDuration = adjustDurationUnit(n)
      if (displayDuration.type === 'hour') {
        return {
          title: this.$t('forms.event.durationHour', { hour: displayDuration.duration }).toString(),
          value: n
        }
      } else {
        return {
          title: this.$t('forms.event.duration', { min: displayDuration.duration }).toString(),
          value: n
        }
      }
    })
  }
}
