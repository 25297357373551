








































import { Component, PropSync, Vue } from 'vue-property-decorator'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import FormCollapseButton from '@/components/newForm/atoms/CollapseButton.vue'
import InputBuffer from '@/components/newForm/molecules/InputBuffer.vue'
import { AvailabilityModel } from '@/models/data'
import SelectTimezone from '@/components/newForm/molecules/SelectTimezone.vue'
import SelectStartAndEndDateAndMaxCountPerDay from '@/components/patterns/form/molecules/ConditionDate.vue'
import PatternFormConditionWeekDay from '../../form/molecules/ConditionWeekDay.vue'
import ExceptionList from '../../form/molecules/ExceptionList.vue'
import SelectHolidays from '@/components/newForm/molecules/SelectHolidays.vue'
import { checkOverlap } from '@/lib/utils'
import { nanoid } from 'nanoid'
import Duration from '@/components/newForm/molecules/Duration.vue'
import { Country } from '@spirinc/contracts'
import { MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION } from '@/types'

@Component({
  components: {
    Duration,
    FromGroup,
    FormCollapseButton,
    InputBuffer,
    SelectTimezone,
    SelectStartAndEndDateAndMaxCountPerDay,
    PatternFormConditionWeekDay,
    ExceptionList,
    SelectHolidays
  }
})
export default class PatternSidePanelFormCondition extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: AvailabilityModel

  get isTeamType() {
    return this.editingObjectSynced.type === 'team'
  }
  get maxNumOfCountries(): number {
    return MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION
  }
  updateCondition() {
    this.$emit('updateCondition')
  }
  updateObject() {
    this.$emit('updateObject')
  }
  checkExceptionOverlap(start: Date, end: Date, id?: string): boolean {
    if (!checkOverlap(this.editingObjectSynced.exceptionsAsCalendarFormat, start, end, id)) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.error.duplicated').toString()
      })
      return false
    }
    return true
  }
  saveNewException(editedException) {
    if (this.checkExceptionOverlap(editedException.start, editedException.end, editedException.id)) {
      if (!editedException.id) {
        this.editingObjectSynced.exceptions.push({
          id: nanoid(),
          start: editedException.start.toISOString(),
          end: editedException.end.toISOString(),
          allDay: false,
          type: editedException.type
        })
      } else {
        // update
        const findIndex = this.editingObjectSynced.exceptions.findIndex((e) => e.id === editedException.id)
        if (findIndex >= 0) {
          this.editingObjectSynced.exceptions[findIndex] = {
            ...this.editingObjectSynced.exceptions[findIndex],
            type: editedException.type,
            start: editedException.start.toISOString(),
            end: editedException.end.toISOString()
          }
          this.editingObjectSynced.exceptions = [...this.editingObjectSynced.exceptions]
        }
      }
    }
    this.updateCondition()
  }
  handleClickedException(event) {
    const findIndex = this.editingObjectSynced.exceptions.findIndex((e) => e.id === event.id)
    if (findIndex >= 0) {
      this.editingObjectSynced.exceptions.splice(findIndex, 1)
      this.updateCondition()
    }
  }
  handleCountrySelect(country: Country) {
    this.editingObjectSynced.countries.push({ code: country.code })
    this.updateCondition()
  }
  handleCountryDelete(deletingCountry: Country) {
    const { countries, holidays } = this.editingObjectSynced.getCountriesAndHolidaysRemoved(deletingCountry.code)
    this.editingObjectSynced.countries = countries
    this.editingObjectSynced.holidays = holidays
    this.updateCondition()
  }
}
