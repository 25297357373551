
































import { Component, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import List from '@/components/atoms/List.vue'
import ListItem from '@/components/teamSettings/sidePanels/atoms/RouterListItem.vue'
import Divider from '@/components/atoms/Divider.vue'
import ListPanelLayout from '@/components/layout/ListPanel.vue'
import TeamHeader from '../headers/TeamHeader.vue'
import { RouteNames } from '@/router/index'
import TeamRecordModule from '@/store/modules/teamRecord'

type MenuGroup = {
  label: string
  items: {
    label: string
    routeName: RouteNames
    disabled?: boolean
  }[]
  onlyAdmin?: boolean
}

@Component({
  components: {
    ListPanelLayout,
    Avatar,
    List,
    ListItem,
    Divider,
    TeamHeader
  }
})
export default class TeamMenuSidePanel extends Vue {
  get teamId() {
    return this.$route.params.id
  }
  get amIAdmin() {
    return TeamRecordModule.myInfoByTeamId(this.teamId)?.authority === 'administrator'
  }
  get menuGroups() {
    const groups: MenuGroup[] = [
      {
        label: this.$t('teamMenu.team.title').toString(),
        items: [
          {
            label: this.$t('teamMenu.team.member').toString(),
            routeName: 'TeamSettingMember'
          }
        ]
      },
      {
        label: this.$t('teamMenu.personal.title').toString(),
        items: [
          {
            label: this.$t('teamMenu.personal.myCalendar').toString(),
            routeName: 'TeamSettingMyCalendar'
          }
        ]
      },
      {
        label: this.$t('teamMenu.admin.title').toString(),
        items: [
          {
            label: this.$t('teamMenu.admin.teamSetting').toString(),
            routeName: 'TeamInfoSetting'
          },
          {
            label: this.$t('teamMenu.admin.paymentInfo').toString(),
            routeName: 'TeamSettingDummy',
            disabled: true
          }
        ],
        onlyAdmin: true
      }
    ]
    return groups
  }
}
