import TimezoneModule from '@/store/modules/timezones'
import ProfileModule from '@/store/modules/profile'
import i18n from '@/i18n'
import { DateTime, DurationUnit } from 'luxon'
import { isAfter, isEqual } from 'date-fns'
import { localTimezone } from '@/lib/timezone'

export const DEFAULT_TIMEZONE = 'Asia/Tokyo'

const DEFAULT_START_OF_WEEK = 7

const getDateTimeByUserTimeZone = (date: Date, timezone?: string): DateTime => {
  const userTimezone = timezone || TimezoneModule.userTimezoneKey
  return DateTime.fromJSDate(date, { zone: userTimezone })
}
const getDateTimeStartOfByUserTimeZone = (date: Date, durationUnit: DurationUnit): DateTime => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.startOf(durationUnit)
}

/**
 *
 * @param date 受け取った時間をUserのTimezonを考慮した、Localの時間に変える.
 * usecase: 2021/01/01 10:00(local timezone)を使って2021/01/01 10:00(user timezone)になるための、Dateを取得
 * ex) local: Asia/Tokyo, User Timezone: America/New_York, input: 2021/02/02 00:00
 * return: 2021/02/02 14:00 なぜなら、日本とニューヨークの時差が14時間のため。
 */
export const parserDateToUserTimezone = (date: Date): Date => {
  const userTimezone = TimezoneModule.userTimezoneKey
  if (userTimezone === localTimezone()) {
    return date
  }
  return DateTime.fromJSDate(date).setZone(userTimezone, { keepLocalTime: true }).toJSDate()
}

// hh:mm を分で返す
export const getMinByUserTimezone = (date: Date) => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.hour * 60 + dateTime.minute
}
export const endOfByUserTimezone = (date: Date, durationUnit: DurationUnit) => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.endOf(durationUnit).toJSDate()
}
// Day
export const startOfDay = (date: Date, timezone?: string): Date => {
  const dateTime = getDateTimeByUserTimeZone(date, timezone)
  return dateTime.startOf('day').toJSDate()
}
export const endOfDay = (date: Date, timezone?: string): Date => {
  const dateTime = getDateTimeByUserTimeZone(date, timezone)
  return dateTime.endOf('day').toJSDate()
}
export const addDays = (date: Date, count: number): Date => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.plus({ days: count }).toJSDate()
}
// Week
export const addWeeks = (date: Date, count: number): Date => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.plus({ weeks: count }).toJSDate()
}

/**
 *
 * @param date
 * @param startWeekDay: 週の始まりを指定できる。デフォルトは日曜日
 */
export const startOfWeek = (date: Date, option?: { startWeekDay?: number; timeZone?: string }): Date => {
  const startWeekDay = option?.startWeekDay || DEFAULT_START_OF_WEEK
  const dateTime = getDateTimeByUserTimeZone(date, option?.timeZone)
  if (dateTime.weekday === startWeekDay) {
    return dateTime.startOf('day').toJSDate()
  }
  let offsetWithLuxonStartWeekDayAndPayload = 0
  switch (option?.startWeekDay) {
    case 7:
      offsetWithLuxonStartWeekDayAndPayload = -1
      break
    case 6:
      offsetWithLuxonStartWeekDayAndPayload = -2
      break
    case 2:
      offsetWithLuxonStartWeekDayAndPayload = 1
      break
  }
  return dateTime.startOf('week').plus({ days: offsetWithLuxonStartWeekDayAndPayload }).toJSDate()
}
export const startOfWeekByUserStartWeekday = (date: Date): Date => {
  return startOfWeek(date, { startWeekDay: ProfileModule.startWeekDayNum || DEFAULT_START_OF_WEEK })
}
export const endOfWeek = (date: Date, option?: { startWeekDay?: number; timeZone?: string }): Date => {
  const nextWeekStart = startOfWeek(addWeeks(date, 1), option)
  return DateTime.fromJSDate(nextWeekStart).minus({ milliseconds: 1 }).toJSDate()
}

export const isThisWeek = (date: Date, option?: { startWeekDay?: number; timeZone?: string }): boolean => {
  const startOfWeekDay = startOfWeek(date, option)
  const startOfWeekDayByNow = startOfWeek(new Date(), option)
  return isEqual(startOfWeekDay, startOfWeekDayByNow)
}
// Month
export const addMonths = (date: Date, count: number): Date => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.plus({ months: count }).toJSDate()
}
export const startOfMonth = (date: Date): Date => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.startOf('months').toJSDate()
}
export const endOfMonth = (date: Date): Date => {
  const dateTime = getDateTimeByUserTimeZone(date)
  return dateTime.endOf('months').toJSDate()
}
export const isSameMonth = (baseDate: Date, targetDate: Date) => {
  const baseDateTime = getDateTimeByUserTimeZone(baseDate)
  const targetDateTime = getDateTimeByUserTimeZone(targetDate)
  return baseDateTime.hasSame(targetDateTime, 'months')
}
// Year
export const isThisYear = (baseDate: Date, targetDate: Date = new Date()) => {
  const baseDateTime = getDateTimeByUserTimeZone(baseDate)
  const targetDateTime = getDateTimeByUserTimeZone(targetDate)
  return baseDateTime.hasSame(targetDateTime, 'years')
}

export const diffDaysByUserTimezone = (baseDate: Date, targetDate: Date) => {
  const target = getDateTimeStartOfByUserTimeZone(targetDate, 'day')
  const base = getDateTimeStartOfByUserTimeZone(baseDate, 'day')
  const isBaseAfterTarget = isAfter(baseDate, targetDate)
  const diff = Math.abs(base.diff(target, 'day').days)
  return isBaseAfterTarget ? diff : diff * -1
}

export const isSameDayByUserTimeZone = (firstDate: Date, secondDate: Date) => {
  const userTimezone = TimezoneModule.userTimezoneKey
  const firstDateTime = DateTime.fromJSDate(firstDate, { zone: userTimezone })
  const secondDateTime = DateTime.fromJSDate(secondDate, { zone: userTimezone })
  return firstDateTime.hasSame(secondDateTime, 'day')
}

export const roundToNextNearestMinutes = (date: Date, options: { nearestTo: number }, timeZone?: string) => {
  const userTimezone = timeZone || TimezoneModule.userTimezoneKey
  const dateTime = DateTime.fromJSDate(date).setZone(userTimezone)
  let minute = dateTime.minute
  const secondsAfter = dateTime.second > 0 || dateTime.millisecond > 0
  if (secondsAfter) {
    minute += 1
  }
  const mod = minute % options.nearestTo
  const remainder = options.nearestTo - (mod === 0 ? options.nearestTo : mod)
  return dateTime
    .plus({ minutes: remainder + (secondsAfter ? 1 : 0) })
    .set({ second: 0, millisecond: 0 })
    .toJSDate()
}
export const isToday = (date: Date): boolean => {
  return isSameDayByUserTimeZone(new Date(), date)
}

export const exchangeDateByTimezone = (currentTimezone: string, newTimezone: string, date: Date) => {
  const currentTimezoneDateTime = getDateTimeByUserTimeZone(date, currentTimezone)
  return DateTime.fromObject({
    year: currentTimezoneDateTime.year,
    month: currentTimezoneDateTime.month,
    day: currentTimezoneDateTime.day,
    zone: newTimezone
  }).toJSDate()
}
export const parseHourAndMinToDate = (hourAndMin: string, baseDate?: Date) => {
  baseDate = baseDate || startOfDay(new Date())
  const currentTimezoneDateTime = getDateTimeByUserTimeZone(baseDate)
  const userTimezone = TimezoneModule.userTimezoneKey
  const hour = Number(hourAndMin.split(':')[0])
  const minute = Number(hourAndMin.split(':')[1])
  return DateTime.fromObject({
    year: currentTimezoneDateTime.year,
    month: currentTimezoneDateTime.month,
    day: currentTimezoneDateTime.day,
    hour,
    minute,
    zone: userTimezone
  }).toJSDate()
}
