import { DateSlot, DatetimeSlot } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import dateSpan from '@/filters/dateSpan'
import { getAllDayListItemTitle, spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { Exception } from '@/models/data'
import { groupBy, sortBy, toPairs } from 'lodash'

export const FromExceptionModel = {
  convertToDatetimeSlot(e: Exception): DatetimeSlot {
    const start = new Date(e.start)
    const end = new Date(e.end)
    const event = { start, end }
    const title = e.allDay ? getAllDayListItemTitle({ event }) : dateSpan(event)
    return {
      id: e.id,
      title,
      start,
      end,
      isValidCandidate: true
    }
  },
  convertToDateSlots(exceptions: Exception[]): DateSlot[] {
    return toPairs(
      groupBy(
        sortBy(exceptions, (exception: Exception) => new Date(exception.start)),
        (exception) => spirDateFormat(new Date(exception.start), spirDateFormatTypes.yyyymmddweekday)
      )
    ).map(
      ([date, exceptions], index): DateSlot => ({
        id: index,
        title: date,
        datetimeSlots: exceptions.map(this.convertToDatetimeSlot)
      })
    )
  }
}
