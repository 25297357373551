






import AuthPageLayout from '@/components/layout/auth/AuthPageLayout.vue'
import SignInWithPasswordFormBox from '@/components/auth/SignInWithPasswordFormBox.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    AuthPageLayout,
    SignInWithPasswordFormBox
  }
})
