




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    height: {
      type: [String, Number],
      default: null
    },
    width: {
      type: [String, Number],
      default: null
    }
  }
})
