













import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { defineComponent, PropType, computed } from '@vue/composition-api'
import ConditionIncludedCalendarsAndMembersAutocomplete from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/index.vue'
import {
  ListSetForUI,
  AvailableAutocompleteListType,
  SelectedCalendarType,
  AutocompleteCalendar
} from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { ICalendar } from '@/types'

export default defineComponent({
  name: 'FormMeetingRoomSelectItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    ConditionIncludedCalendarsAndMembersAutocomplete
  },
  props: {
    selectedCalendarTypes: {
      type: Array as PropType<SelectedCalendarType[]>,
      default: () => []
    },
    resourceCalendarList: {
      type: Array as PropType<ICalendar[]>,
      default: () => []
    },
    onCalendarAdd: {
      type: Function as PropType<(accountId: string, calendarId: string) => void>,
      required: true
    },
    onCalendarDelete: {
      type: Function as PropType<(accountId: string, calendarId: string) => void>,
      required: true
    }
  },
  setup(props) {
    const listSetForUI = computed((): ListSetForUI => {
      const targetCalendars: AutocompleteCalendar[] = props.resourceCalendarList
        .filter(
          (rc) => !props.selectedCalendarTypes.some((c) => c.accountId === rc.accountId && c.calendarId === rc.id)
        )
        .map((item) => ({
          type: 'calendar',
          listItem: item
        }))
      const selectedCalendars: AutocompleteCalendar[] = props.selectedCalendarTypes.map((item) => ({
        type: 'calendar',
        listItem: props.resourceCalendarList.find((rc) => item.accountId === rc.accountId && item.calendarId === rc.id)
      }))
      return {
        targetCalendars,
        selectedCalendars
      }
    })
    const handleCalendarAdd = (addedItem: AvailableAutocompleteListType) => {
      if (addedItem.type === 'calendar') {
        props.onCalendarAdd(addedItem.listItem.accountId, addedItem.listItem.id)
      }
    }
    const handleCalendarDelete = (deletedItem: AvailableAutocompleteListType) => {
      if (deletedItem.type === 'calendar') {
        props.onCalendarDelete(deletedItem.listItem.accountId, deletedItem.listItem.id)
      }
    }
    return {
      listSetForUI,
      handleCalendarAdd,
      handleCalendarDelete
    }
  }
})
