










import { Component, Prop, Vue } from 'vue-property-decorator'
import Section from '../atoms/Section.vue'

@Component({
  components: {
    Section
  }
})
export default class UserBadgeSetting extends Vue {
  @Prop() expanded: boolean
  settingMenus = []
  created() {
    this.settingMenus = [
      {
        to: 'Settings',
        title: this.$t('buttons.settings')
      },
      {
        to: 'ProfileView',
        title: this.$t('buttons.profile')
      },
      {
        to: 'SignOut',
        title: this.$t('buttons.signOut')
      }
    ]
  }
}
