

























import { Component, Vue } from 'vue-property-decorator'
import TeamInvitedSignUp from '@/components/molecules/auth/TeamInvitedSignUp.vue'
import TeamInvitedSignIn from '@/components/molecules/auth/TeamInvitedSignIn.vue'
import TeamInvitedSlideLayout from '@/components/teamSettings/slides/TeamInvitedSlideLayout.vue'

@Component({
  components: {
    TeamInvitedSignUp,
    TeamInvitedSignIn,
    TeamInvitedSlideLayout
  }
})
export default class TeamInvitedSignUpSlide extends Vue {
  get isSignUpMode() {
    const mode = this.$route.query.mode
    if (typeof mode === 'string' && mode === 'signIn') {
      return false
    }

    return true
  }

  goToSignUp() {
    this.$emit('goToSignUp')
  }

  goToSignIn() {
    this.$emit('goToSignIn')
  }
  successInAuth() {
    this.$emit('next')
  }
}
