















import MemberAvatar from '@/components/molecules/MemberAvatar.vue'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { CardItem } from '@/lib/utils'
import { CardItemTip } from '@/types'
import { Creator } from '@spirinc/contracts'
import { parseISO } from 'date-fns'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardBodyItem from './CardBodyItem.vue'

@Component({
  components: {
    CardBodyItem,
    MemberAvatar
  }
})
export default class LastUpdateInfoCardBodyItem extends Vue {
  @Prop() datetime: string
  @Prop() lastUpdateUser?: Creator

  get lastUpdateDateTime() {
    if (!this.datetime) return ''
    const parsed = parseISO(this.datetime)
    return spirDateFormat(parsed, spirDateFormatTypes.mdHourMin)
  }

  get hasLastUser() {
    return this.photoUrl && this.fullName
  }
  get photoUrl() {
    return this.lastUpdateUser?.photoUrl
  }
  get fullName() {
    return this.lastUpdateUser?.fullName || ''
  }
  get label(): string {
    return 'labels.lastUpdatedAt'
  }
  get tipInfo(): CardItemTip {
    return CardItem.convertToCardItemTip(true, this.$t(this.label).toString())
  }
}
