import axiosInstance from '../axios'
import {
  TeamTeamIdAvailabilitySharingsPostRequest,
  TeamsTeamIdAvailabilitySharingsPeekCandidatesPostRequest,
  TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostRequest,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse,
  TeamTeamIdAvailabilitySharingsPostResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse
} from '@spirinc/contracts'
import store from '@/store'

export const create = async (
  teamId: string,
  availability: TeamTeamIdAvailabilitySharingsPostRequest
): Promise<TeamTeamIdAvailabilitySharingsPostResponse> => {
  return (await axiosInstance.post(`teams/${teamId}/availability-sharings`, availability)).data
}
export const fetchCandidatesByParam = async (
  teamId: string,
  availability: TeamsTeamIdAvailabilitySharingsPeekCandidatesPostRequest
) => {
  return (await axiosInstance.post(`teams/${teamId}/availability-sharings/peek-candidates`, availability)).data
}
export const getOverview = async (
  teamId: string,
  id: string
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse> => {
  return (await axiosInstance.get(`public/teams/${teamId}/availability-sharings/${id}/overview`)).data
}
export const getCandidatesById = async (
  teamId: string,
  id: string
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse> => {
  return (await axiosInstance.get(`public/teams/${teamId}/availability-sharings/${id}/candidates`)).data
}
export const confirm = async (
  teamId: string,
  id: string,
  payload: TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmPostRequest
) => {
  if (store.getters['User/isSignIn']) {
    return (await axiosInstance.post(`teams/${teamId}/availability-sharings/${id}/confirm`, payload)).data
  }
  return (await axiosInstance.post(`public/teams/${teamId}/availability-sharings/${id}/confirm`, payload)).data
}
export const getList = async (teamId: string, query?: string) => {
  if (query) {
    return (await axiosInstance.get(`teams/${teamId}/availability-sharings?${query}`)).data
  }
  return (await axiosInstance.get(`teams/${teamId}/availability-sharings`)).data
}
export const deletePattern = (teamId, id) => {
  return axiosInstance.delete(`teams/${teamId}/availability-sharings/${id}`)
}
export const updatePattern = async (teamId, id, payload) => {
  return (await axiosInstance.patch(`teams/${teamId}/availability-sharings/${id}`, payload)).data
}

export const getAvailability = async (teamId, id) => {
  return (await axiosInstance.get(`teams/${teamId}/availability-sharings/${id}`)).data
}
export const updateOrder = async (teamId: string, payload: { order: string[] }) => {
  return (await axiosInstance.put(`teams/${teamId}/availability-sharings/order`, payload)).data
}
export const getConfirmedInfo = async (
  teamId: string,
  availabilityId: string,
  confirmedEventId: string
): Promise<PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse> => {
  return (
    await axiosInstance.get(
      `public/teams/${teamId}/availability-sharings/${availabilityId}/confirmed/${confirmedEventId}`
    )
  ).data
}
