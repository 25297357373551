







import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { TipInfo } from '@/components/ui/form/tipInfo'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirSelectBox, { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'

type IconSetting = {
  type: 'icon' | 'svg'
  src: string
}

type TitleSetting = {
  title: string
  required: boolean
  tip?: TipInfo
}

export default defineComponent({
  name: 'FormSelectBoxItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    SpirSelectBox
  },
  props: {
    icon: {
      type: Object as PropType<IconSetting>,
      required: true
    },
    title: {
      type: Object as PropType<TitleSetting>,
      required: true
    },
    value: {
      type: [String, Number] as PropType<string | number>
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)

    const model = computed({
      get: () => value.value,
      set: (newValue) => emit('input', newValue)
    })

    return {
      model
    }
  }
})
