




import AddCalendar from '@/components/page/AddCalendarPage.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    AddCalendar
  }
})
