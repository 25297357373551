import {
  getAlldayEndDate,
  getAllDayStartDate,
  getDefaultSelectedCalendar,
  updateEndDateConsiderAllDay
} from '@/lib/utils'
import { addDays, startOfDay } from '@/lib/utils/timezone'
import store from '@/store'
import CalendarModule from '@/store/modules/calendars'
import {
  FreeBusyStatus,
  FullCalendarEvent,
  GoogleEvent,
  ICalendar,
  ITypeCalendarListForUI,
  LOCAL_STORAGE_KEY_EVENT_DEFAULT_VALUES,
  OnlineMeetingType,
  VISIBILITY
} from '@/types'
import { addHours, isAfter, isEqual, startOfHour } from 'date-fns'
import { cloneDeep } from 'lodash'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getDefaultOnlineMeetintType } from '../lib/utils'
import CalendarsModule from './calendars'
import EventModule from './event'
import EventsModule from './events'
import TempEventModule from './tempEvent'

export const UpdateResult = {
  onlineMtgUpdated: 'onlineMtgUpdated'
} as const

export type UpdateResult = keyof typeof UpdateResult

const DefaultEvent = (writableCalendars: ITypeCalendarListForUI[], tempEvent?: FullCalendarEvent): GoogleEvent => {
  let defaultIds: { accountId: string; calendarId: string } = {
    accountId: null,
    calendarId: null
  }
  // LocalStorageに入ってる前回選択した値
  const selectedEventValues: {
    calendarId: string
    accountId: string
    onlineMeeting?: { type: OnlineMeetingType }
  } = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY_EVENT_DEFAULT_VALUES))
  // payload に入ってるのが優先
  if (tempEvent && tempEvent.extendedProps?.calendarId) {
    defaultIds = {
      accountId: tempEvent.extendedProps.accountId,
      calendarId: tempEvent.extendedProps.calendarId
    }
  } else {
    const defaultCalendar = getDefaultSelectedCalendar(writableCalendars, selectedEventValues)
    defaultIds = {
      accountId: defaultCalendar.accountId,
      calendarId: defaultCalendar.calendarId
    }
  }
  const selectedCalendar: ICalendar = CalendarModule.getCalendar({
    accountId: defaultIds.accountId,
    calendarId: defaultIds.calendarId
  })
  const start = tempEvent?.start ? tempEvent.start.toISOString() : startOfHour(addHours(new Date(), 1)).toISOString()
  const end = tempEvent?.end ? tempEvent.end.toISOString() : addHours(new Date(start), 1).toISOString()
  return {
    ...defaultIds,
    allDay: tempEvent?.allDay || false,
    start,
    end,
    id: null,
    summary: null,
    location: '',
    description: '',
    attendees: [],
    resources: [],
    attendeeFlag: null,
    writable: true,
    onlineMeeting: getDefaultOnlineMeetintType(selectedCalendar, selectedEventValues?.onlineMeeting),
    visibility: VISIBILITY.DEFAULT,
    freeBusyStatus: FreeBusyStatus.busy
  }
}
if (store.state['EditEvent']) {
  store.unregisterModule('EditEvent')
}
@Module({
  dynamic: true,
  name: 'EditEvent',
  namespaced: true,
  store
})
class EditEvent extends VuexModule {
  _editingEvent: GoogleEvent = null
  _isDirty = false
  _isLoading = false

  get isExist() {
    return !!this.editingEvent
  }
  get isDirty() {
    return this._isDirty
  }
  get editingEvent(): GoogleEvent {
    return this._editingEvent
  }
  get isLoading() {
    return this._isLoading
  }
  @Mutation
  SET_EDITING_EVENT(event: GoogleEvent) {
    this._editingEvent = event
  }

  @Mutation
  SET_DIRTY(flag: boolean) {
    this._isDirty = flag
  }
  @Mutation
  SET_LOADING(isLoading: boolean) {
    this._isLoading = isLoading
  }
  @Action
  update(payload: GoogleEvent): UpdateResult | null {
    let result: UpdateResult | null = null
    const end = updateEndDateConsiderAllDay(this.editingEvent.allDay, payload.allDay, this.editingEvent.end)
    const newEvent = {
      ...this.editingEvent,
      ...payload,
      end
    }
    if (this.editingEvent.accountId !== newEvent.accountId || this.editingEvent.calendarId !== newEvent.calendarId) {
      const onlineMeeting = getDefaultOnlineMeetintType(
        CalendarsModule.getCalendar({ accountId: newEvent.accountId, calendarId: newEvent.calendarId }),
        newEvent.onlineMeeting
      )
      if (newEvent.onlineMeeting?.type !== onlineMeeting.type) {
        result = UpdateResult.onlineMtgUpdated
        newEvent.onlineMeeting = onlineMeeting
      }
    }
    this.SET_EDITING_EVENT(newEvent)
    this.SET_DIRTY(true)
    return result
  }

  @Action
  startCreatingNewEvent() {
    const newEvent = DefaultEvent(CalendarsModule.writableCalendars, TempEventModule.getTempEvent)

    TempEventModule.RESET()
    this.SET_DIRTY(false)
    this.SET_EDITING_EVENT(newEvent)
    CalendarsModule.visibleCalendarIfNotVisible({
      accountId: newEvent.accountId,
      calendarId: newEvent.calendarId
    })
  }

  @Action
  async setEventAsEditingEvent({ eventId, accountId, calendarId }) {
    let event = EventsModule.getEventById(eventId, accountId)
    if (!event) {
      const result = await EventModule.getEvent({ eventId, accountId, calendarId })
      event = result.event
    }
    const newEvent = cloneDeep(event)
    this.SET_EDITING_EVENT(newEvent)
    this.SET_DIRTY(false)
  }

  @Action
  async saveEditingEventObject() {
    const editingEvent = cloneDeep(this._editingEvent)
    editingEvent.start = getAllDayStartDate(new Date(this.editingEvent.start), this.editingEvent.allDay).toISOString()
    editingEvent.end = getAlldayEndDate(new Date(this.editingEvent.end), this.editingEvent.allDay).toISOString()
    this.SET_LOADING(true)
    try {
      if (editingEvent.id) {
        await EventModule.updateEvent(editingEvent)
      } else {
        await EventModule.createEvent(editingEvent)
      }
      await CalendarsModule.visibleCalendarIfNotVisible({
        accountId: editingEvent.accountId,
        calendarId: editingEvent.calendarId
      })
      this.initEditingEvent()
    } finally {
      this.SET_LOADING(false)
    }
  }

  @Action
  updateDate(payload: { start: Date; end: Date; allDay?: boolean }) {
    const newEvent = cloneDeep(this._editingEvent)
    let newStart = payload.start
    let newEnd = payload.end
    if (payload.allDay) {
      if (isEqual(newStart, newEnd) || isAfter(newStart, newEnd)) {
        // startが変わったかEndが変わったか判断し、変わっていない日付の方を修正する
        if (isEqual(newStart, new Date(this.editingEvent.start))) {
          newStart = addDays(startOfDay(newEnd), -1)
        } else {
          newEnd = addDays(startOfDay(newStart), 1)
        }
      }
    }
    newEvent.start = newStart.toISOString()
    newEvent.end = newEnd.toISOString()
    newEvent.allDay = payload.allDay === undefined ? newEvent.allDay : payload.allDay
    this.SET_EDITING_EVENT(newEvent)
    this.SET_DIRTY(true)
  }
  @Action
  initEditingEvent() {
    this.SET_EDITING_EVENT(null)
    this.SET_DIRTY(false)
  }
}

export default getModule(EditEvent)
