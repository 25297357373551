const SPIR_PREFIX = 'SPIR__'
export const SignalType = {
  ACTIVE: 'ACTIVE',
  SEE_PROFILE_OVERVIEW: 'SEE_PROFILE_OVERVIEW', // 画面でプロフィールを閲覧（Overview)
  SEE_PROFILE_CONFIRM_MODAL: 'SEE_PROFILE_CONFIRM_MODAL', // でプロフィールを閲覧（ConfirmModal)
  CLICK_SIGNUP_CONFIRM: 'CLICK_SIGNUP_CONFIRM', // 確定画面で登録を開始
  CLICK_SIGNUP_CONFIRM_MODAL: 'CLICK_SIGNUP_CONFIRM_MODAL', // 確定画面Modalで登録を開始
  SEE_PROFILE_ON_EVENT_MODAL: 'SEE_PROFILE_ON_EVENT_MODAL', // EventModalでプロフィールを表示
  SEE_PROFILE_ON_CANDIDATE_MODAL: 'SEE_PROFILE_ON_CANDIDATE_MODAL', // 候補Modalでプロフィールを表示
  CLICK_SIGNUP_TOP: 'CLICK_SIGNUP_TOP', // Top画面で登録を開始
  CLICK_SIGNUP_TEAM_INVITED: 'CLICK_SIGNUP_TEAM_INVITED', // Team招待画面で登録を開始
  CLICK_SIGNIN_TEAM_INVITED: 'CLICK_SIGNIN_TEAM_INVITED', // Team招待画面でログインを開始
  SYNC_ACCOUNT: 'SYNC_ACCOUNT', // 最初のGoogleアカウント連携を開始
  SYNC_ACCOUNT_SUCCESS: 'SYNC_ACCOUNT_SUCCESS', // 最初のGoogleアカウント連携を完了
  DELETE_ACCOUNT: 'DELETE_ACCOUNT', // Googleアカウント削除
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS', // Googleアカウント削除を完了
  CLICK_LOGIN_TOP: 'CLICK_LOGIN_TOP', // Top画面でログイン
  LOGOUT: 'LOGOUT', // ログアウト
  CONFIRM_SCHEDULING: 'CONFIRM_SCHEDULING', // 日程調整を確定
  CONFIRM_PUBLICURL: 'CONFIRM_PUBLICURL', // 公開URLを確定
  CONFIRM_GROUPPOLL: 'CONFIRM_GROUPPOLL', // 日程投票に投票
  VOTE_GROUPPOLL: 'VOTE_GROUPPOLL', // 日程投票に投票
  CLICK_CANDIDATE_ON_CALENDAR: 'CLICK_CANDIDATE_ON_CALENDAR', // カレンダから候補を選択
  HOVER_CANDIDATE_ON_CALENDAR: 'HOVER_CANDIDATE_ON_CALENDAR', // 候補をHoverする
  CLICK_CANDIDATE_ON_PANEL: 'CLICK_CANDIDATE_ON_PANEL', // パネルから候補を選択
  HOVER_CANDIDATE_ON_PANEL: 'HOVER_CANDIDATE_ON_PANEL', // パネルでHover
  REQUEST_ALTERNATIVE_SCHEDULING: 'REQUEST_ALTERNATIVE_SCHEDULING', // 日程調整確定画面で代替日の依頼
  SUGGEST_ALTERNATIVE_SCHEDULING: 'SUGGEST_ALTERNATIVE_SCHEDULING', // 代替日提案
  EDIT_MYPROFILE: 'EDIT_MYPROFILE', // プロフィールを編集
  CONNECT_ZOOM: 'CONNECT_ZOOM', // Zoomを連携
  UNCONNECT_ZOOM: 'UNCONNECT_ZOOM', // Zoom連携を解除
  OPEN_EVENTDETAIL: 'OPEN_EVENTDETAIL', // 予定をクリックして予定詳細を確認
  EDIT_EVENT: 'EDIT_EVENT', // 予定詳細で編集ボタンをクリック
  DELETE_EVENT: 'DELETE_EVENT', // 予定を削除
  DRAGCALENDAR: 'DRAGCALENDAR', // カレンダーの空いている部分をクリックした
  CLICKMENU: 'CLICKMENU', // 左上の作成をクリックした
  GO_FROM_MENU: 'GO_FROM_MENU', // 画面遷移
  CREATE_EVENT: 'CREATE_EVENT', // 予定作成
  CREATE_SCHEDULE: 'CREATE_SCHEDULE', // 日程調整作成
  EDIT_SCHEDULE: 'EDIT_SCHEDULE', // 日程調整編集
  CREATE_POLL: 'CREATE_POLL', // 日程投票作成
  EDIT_POLL: 'EDIT_POLL', // 日程投票作成
  CREATE_PUBLICURL: 'CREATE_PUBLICURL', // 公開URL作成
  EDIT_PUBLICURL: 'EDIT_PUBLICURL', // 公開URL編集
  DELETE_PUBLICURL: 'DELETE_PUBLICURL', // 公開URL削除
  CLICK_AUTOCANDIDATE: 'CLICK_AUTOCANDIDATE', // 自動抽出クリック
  OPEN_CANDIDATE: 'OPEN_CANDIDATE', // 候補のModal表示
  CLICK_EDIT_ON_CANDIDATE_MODAL: 'CLICK_EDIT_ON_CANDIDATE_MODAL', // 候補のModalからEdit
  CLICK_URL_SHARE_ON_CANDIDATE_MODAL: 'CLICK_URL_SHARE_ON_CANDIDATE_MODAL', // 候補のModalからURLShare
  CLICK_DELETE_ON_CANDIDATE_MODAL: 'CLICK_DELETE_ON_CANDIDATE_MODAL', // 候補のModalからDelete
  CLICK_CONFIRM_ON_CANDIDATE_MODAL: 'CLICK_CONFIRM_ON_CANDIDATE_MODAL', // 候補のModalからConfirm
  GO_FROM_LIST: 'GO_FROM_LIST', // リストから遷移
  CLICK_DELETE_ON_LIST: 'CLICK_DELETE_ON_LIST', // リストから削除
  CLICK_URL_SHARE_ON_EDIT_PAGE: 'CLICK_URL_SHARE_ON_EDIT_PAGE', // 編集画面でURLShare
  CLICK_CONFIRM_ON_EDIT_PAGE: 'CLICK_CONFIRM_ON_EDIT_PAGE', // 編集画面で確定
  CLICK_EDIT_ON_EDIT_PAGE: 'CLICK_EDIT_ON_EDIT_PAGE', // 編集画面で編集
  REMOVE_CANDIDATE_ON_EDIT_PAGE: 'REMOVE_CANDIDATE_ON_EDIT_PAGE', // 編集画面で候補削除
  CLICK_DELETE_ON_EDIT_PAGE: 'CLICK_DELETE_ON_EDIT_PAGE', // 編集画面で削除
  SHOW_OVERVIEW: 'SHOW_OVERVIEW', // Overviewを表示
  SHOW_CALENDAR: 'SHOW_CALENDAR', // Overviewからカレンダーを表示
  SHOW_PAGE_AFTER_CONFIRM: 'SHOW_PAGE_AFTER_CONFIRM', // 確定後のAccess
  CLICK_HTML_SHARE_ON_CANDIDATE_MODAL: 'CLICK_HTML_SHARE_ON_CANDIDATE_MODAL', // 候補のModalからHTMLShare
  SEE_PUBLIC_HTML_URL_SHARE_MODAL: 'SEE_PUBLIC_HTML_URL_SHARE_MODAL', // 公開URLのShare用Modal
  CLICK_PUBLIC_URL_LINK: 'CLICK_PUBLIC_URL_LINK', // 公開URLのリンクをコピー
  SHOW_AVAILABILITY_SHARING_CANDIDATES: 'SHOW_AVAILABILITY_SHARING_CANDIDATES', // 公開URLの候補日を表示
  TOGGLE_PUBLICURL: 'TOGGLE_PUBLICURL', // 公開URLの公開設定変更
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS', // サインアップ成功
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS', // サインイン成功
  CLICK_NAV_LOGO: 'CLICK_NAV_LOGO', // グロナビのロゴクリック
  CLICK_NAV_PLUS: 'CLICK_NAV_PLUS', // グロナビの生成ボタン
  CLICK_NAV_LIST: 'CLICK_NAV_LIST',
  SHOW_AUTH_MODAL_FROM_KARTE: 'SHOW_AUTH_MODAL_FROM_KARTE', // カルテからSignUp用のModalを表示
  SHOW_PUBLIC_URL_LIST: 'SHOW_PUBLIC_URL_LIST', // 公開URLのリスト表示
  SHOW_TEAM_SETTING: 'SHOW_TEAM_SETTING', // チーム設定画面表示
  PAGE_ACCESS: 'PAGE_ACCESS', // すべての画面のアクセスを送る
  CLICK_ADD_CALENDAR: 'CLICK_ADD_CALENDAR', // カレンダー追加ボタン押下
  CLICK_CONNECT_GOOGLE_ACCOUNT: 'CLICK_CONNECT_GOOGLE_ACCOUNT', // Google用の登録画面でボタンクリック
  CLICK_ADD_INITIAL_CALENDAR: 'CLICK_ADD_INITIAL_CALENDAR', // 新規登録のカレンダー追加画面でカレンダー追加ボタン押下
  SYNC_ACCOUNT_FAILURE: 'SYNC_ACCOUNT_FAILURE', // カレンダーの追加で失敗
  SIGNUP_FAILURE: 'SIGNUP_FAILURE', // 新規登録で失敗
  CLICK_SIGNIN: 'CLICK_SIGNIN', //
  CLICK_PEEK_CANDIDATE_OPEN: 'CLICK_PEEK_CANDIDATE_OPEN', // 候補を自動抽出をクリック
  CLICK_PEEK_CANDIDATE_SAVE: 'CLICK_PEEK_CANDIDATE_SAVE', // 候補を自動抽出で保存をクリック
  CLICK_PEEK_CANDIDATE_CANCEL: 'CLICK_PEEK_CANDIDATE_CANCEL', // 候補を自動抽出でキャンセルをクリック
  CLICK_ARRANGEMENT_SAVE: 'CLICK_ARRANGEMENT_SAVE', // 候補を提案で保存をクリック
  CLICK_ARRANGEMENT_PREVIEW: 'CLICK_ARRANGEMENT_PREVIEW', // 候補を提案で相手への見え方をクリック
  CLICK_CANDIDATE_ADD: 'CLICK_CANDIDATE_ADD', // 候補を追加をクリック
  CLICK_CANDIDATE_EDIT: 'CLICK_CANDIDATE_EDIT', // 候補を編集をクリック
  CLICK_CANDIDATE_DELETE: 'CLICK_CANDIDATE_DELETE', // 候補を削除をクリック
  CLICK_CANDIDATE_ALL_DELETE: 'CLICK_CANDIDATE_ALL_DELETE' // 候補をすべて削除をクリック
} as const

// add prefix
Object.keys(SignalType).forEach((typeKey) => (SignalType[typeKey] = `${SPIR_PREFIX}${SignalType[typeKey]}`))
export type SignalType = keyof typeof SignalType

// routerで使うためページ遷移系は別に定義する
export const PageAccessSignalType = {
  ACCESS_ARRANGEMENT_CREATE: 'ACCESS_ARRANGEMENT_CREATE', // 候補提案作成ページにアクセス
  ACCESS_SCHEDULE_EDIT: 'ACCESS_SCHEDULE_EDIT', // 日程調整編集ページにアクセス
  ACCESS_SCHEDULE_CONFIRM: 'ACCESS_SCHEDULE_CONFIRM', // 日程調整確定画面にアクセス
  ACCESS_GROUPPOLL_EDIT: 'ACCESS_GROUPPOLL_EDIT', // 日程投票編集画面にアクセス
  ACCESS_GROUPPOLL_VOTE: 'ACCESS_GROUPPOLL_VOTE', // 日程投票投票画面にアクセス
  ACCESS_PUBLICURL_CREATE: 'ACCESS_PUBLICURL_CREATE', // 公開URL作成画面にアクセス
  ACCESS_PUBLICURL_EDIT: 'ACCESS_PUBLICURL_EDIT', // 公開URL編集画面にアクセス
  ACCESS_PUBLICURL_CONFIRM: 'ACCESS_PUBLICURL_CONFIRM', // 公開URL確定画面にアクセス
  ACCESS_EVENT_CREATE: 'ACCESS_EVENT_CREATE', // 予定作成画面にアクセス
  ACCESS_EVENT_EDIT: 'ACCESS_EVENT_EDIT', // 予定編集画面にアクセス
  ACCESS_MYPROFILE: 'ACCESS_MYPROFILE', // プロフィールページにアクセス
  ACCESS_SIGNIN: 'ACCESS_SIGNIN',
  ACCESS_SIGNUP: 'ACCESS_SIGNUP'
}
// add prefix
Object.keys(PageAccessSignalType).forEach(
  (typeKey) => (PageAccessSignalType[typeKey] = `${SPIR_PREFIX}${PageAccessSignalType[typeKey]}`)
)
export type PageAccessSignalType = keyof typeof PageAccessSignalType
