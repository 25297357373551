















import { defineComponent, computed } from '@vue/composition-api'
import LanguageDropdown from '@/components/ui/domain/dropdown/LanguageDropdown/index.vue'
import { useLanguageSetting } from '@/composables/useLanguageSetting'
import UserModule from '@/store/modules/user'

export default defineComponent({
  components: {
    LanguageDropdown
  },
  setup() {
    const languageSetting = useLanguageSetting()
    return {
      currentLanguage: languageSetting.currentLanguage,
      isShowLanguageSelector: computed(() => !UserModule.isSignIn),
      handleChangeLanguage: languageSetting.handleChangeLanguage
    }
  }
})
