












import { TwoChoicesRadioState } from '@/components/ui/spir/twoChoicesRadioState'
import { defineComponent, PropType } from '@vue/composition-api'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  components: {
    InlineSvg
  },
  props: {
    state: {
      type: String as PropType<TwoChoicesRadioState>,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
})
