






import UserBadge from '@/components/molecules/UserBadge.vue'
import { computed, defineComponent } from '@vue/composition-api'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  components: {
    UserBadge
  },
  name: 'GuestAsideHeader',
  setup() {
    const i18n = useTranslation()
    const userName = computed(() => i18n.t('calendarList.guest.userName'))
    return {
      userName
    }
  }
})
