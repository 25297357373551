
























import Draggable from 'vuedraggable'
import { PrioritizedOnlineMeetings } from '@spirinc/contracts'
import OnlineMeetingItem from './_OnlineMeetingItem.vue'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

const MeetingOrder = ['zoom', 'googleMeet', 'microsoftTeams'] as const

export default defineComponent({
  name: 'OnlineMeetingPrioritySelector',
  components: {
    Draggable,
    OnlineMeetingItem
  },
  props: {
    onlineMeetings: {
      type: Array as PropType<PrioritizedOnlineMeetings>,
      required: true
    },
    onChange: {
      type: Function as PropType<(value: PrioritizedOnlineMeetings) => void>,
      required: true
    }
  },
  setup(props) {
    const { onlineMeetings } = toRefs(props)
    const enabledList = computed(() => onlineMeetings.value.map((om) => om.type))
    const disabledList = computed(() =>
      MeetingOrder.filter((meeting) => !onlineMeetings.value.some((om) => om.type === meeting))
    )

    const model = computed({
      get: () => onlineMeetings.value,
      set: (newValue) => props.onChange(newValue)
    })

    function toEnabled(type) {
      onlineMeetings.value.push({ type })
      props.onChange(onlineMeetings.value)
    }

    function toDisabled(type) {
      const findIndex = onlineMeetings.value.findIndex((om) => om.type === type)
      if (findIndex >= 0) {
        onlineMeetings.value.splice(findIndex, 1)
        props.onChange(onlineMeetings.value)
      }
    }

    return {
      enabledList,
      disabledList,
      model,
      toEnabled,
      toDisabled
    }
  }
})
