





















import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { defineComponent, PropType, computed } from '@vue/composition-api'
import ConditionIncludedCalendarsAndMembersAutocomplete from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/index.vue'
import {
  SelectedCalendar,
  SelectedMember,
  ListSetForUI,
  AvailableAutocompleteListType,
  MemberList
} from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { frontSupportLanguages, ICalendar } from '@/types'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  name: 'FormTargetCalendarsAndMembersForAutoCandidates',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    ConditionIncludedCalendarsAndMembersAutocomplete
  },
  props: {
    selectedItems: {
      type: Object as PropType<{
        calendars: SelectedCalendar[]
        members: SelectedMember[]
      }>
    },
    availableItemList: {
      type: Object as PropType<{
        calendarList: ICalendar[]
        memberList: MemberList[]
      }>
    },
    onCalendarAdd: {
      type: Function as PropType<(addedItem: AvailableAutocompleteListType) => void>,
      required: true
    },
    onCalendarDelete: {
      type: Function as PropType<(deletedItem: AvailableAutocompleteListType) => void>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const listSetForUI = computed((): ListSetForUI => {
      const selectedCalendars: AvailableAutocompleteListType[] = []
      const targetCalendars: AvailableAutocompleteListType[] = []
      if (props.availableItemList.calendarList) {
        props.availableItemList.calendarList.forEach((calendar) => {
          const foundCalendar = props.selectedItems.calendars.find(
            (sc) => sc.accountId === calendar.accountId && sc.calendarId === calendar.id
          )
          if (foundCalendar) {
            selectedCalendars.push({
              type: 'calendar',
              listItem: calendar
            })
          } else {
            targetCalendars.push({
              type: 'calendar',
              listItem: calendar
            })
          }
        })
      }
      if (props.availableItemList.memberList) {
        props.availableItemList.memberList.forEach((member) => {
          const foundMember = props.selectedItems.members.find(
            (sm) => sm.teamId === member.teamId && sm.memberId === member.member.id
          )
          if (foundMember) {
            selectedCalendars.push({
              type: 'member',
              teamId: member.teamId,
              listItem: member.member
            })
          } else {
            targetCalendars.push({
              type: 'member',
              teamId: member.teamId,
              listItem: member.member
            })
          }
        })
      }
      return {
        selectedCalendars: selectedCalendars,
        targetCalendars: targetCalendars
      }
    })
    const tipWidth = i18n.locale === frontSupportLanguages.ja ? 280 : 90
    return {
      tipWidth,
      listSetForUI
    }
  }
})
