





























































import { ScheduleModel } from '@/models/data/schedule'
import { FullCalendarEvent, OnlineMeetingType, SpirUser } from '@/types'
import ConfirmItemSpacer from '@/components/sidePanels/confirm/atoms/ConfirmItemSpacer.vue'
import ConfirmDescription from '@/components/sidePanels/confirm/ConfirmDescription.vue'
import ConfirmCandidateBlockTitleBox from '@/components/sidePanels/confirm/atoms/ConfirmCandidateBlockTitleBox.vue'
import ConfirmMap from '@/components/sidePanels/confirm/ConfirmMap.vue'
import ConfirmToolLink from '@/components/sidePanels/confirm/ConfirmToolLink.vue'
import HostMembersInfo from '@/components/sidePanels/confirm/schedule/HostMembersInfo.vue'
import ConfirmTimeZoneSelect from '@/components/sidePanels/confirm/atoms/ConfirmTimeZoneSelect.vue'
import ConfirmCandidateListBox from '@/components/sidePanels/confirm/ConfirmCandidateListBox.vue'
import Divider from '@/components/atoms/Divider.vue'
import candidateDates from '@/filters/candidateDates'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import { extractAndConstructCandidates } from '@/lib/utils'
import { defineComponent, PropType, computed, ref, toRefs } from '@vue/composition-api'
import DateRangeInput from '@/components/molecules/DateRangeInput.vue'
import DatetimeSlotEditor from '@/components/ui/form/FormDatetimeSlotsBox/DatetimeSlotEditor.vue'
import SuggestAlternateCandidateListBox from '../SuggestAlternativeDate/SuggetAlternateCandidateDateListBox.vue'
import { DatetimeSlotSavePayload } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { addHours, addMinutes, startOfHour } from 'date-fns'
import { nanoid } from 'nanoid'
import { useTranslation } from '@/composables/useTranslation'
import TitleAndLanguage from '@/components/sidePanels/confirm/TitleAndLanguage.vue'

export default defineComponent({
  components: {
    ConfirmItemSpacer,
    HostMembersInfo,
    ConfirmDescription,
    ConfirmMap,
    ConfirmToolLink,
    Divider,
    ConfirmCandidateBlockTitleBox,
    ConfirmTimeZoneSelect,
    ConfirmCandidateListBox,
    DateRangeInput,
    DatetimeSlotEditor,
    SuggestAlternateCandidateListBox,
    TitleAndLanguage
  },
  props: {
    events: {
      type: Array as PropType<Array<FullCalendarEvent>>,
      required: true
    },
    schedule: {
      type: Object as PropType<ScheduleModel>,
      required: true
    },
    authorInfo: {
      type: Object as PropType<SpirUser>
    },
    onEventUpdate: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>
    },
    onEventDelete: {
      type: Function as PropType<(eventId: string) => void>
    },
    isNarrow: {
      type: Boolean
    }
  },
  setup(props) {
    const showDateRangeInput = ref(false)
    const start = startOfHour(addHours(new Date(), 1))
    const end = props.schedule.duration ? addMinutes(start, props.schedule.duration) : addHours(new Date(start), 1)
    const newEventDefault = ref({ start, end, id: undefined })
    const isOpenAddButton = ref(false)
    const i18n = useTranslation()
    const { schedule, events } = toRefs(props)
    const attendees = computed(() => props.schedule.attendees || [])
    const hasOnlineMeeting = computed(() => props.schedule.onlineMeeting.type !== OnlineMeetingType.none)
    const dateRange = computed(() => candidateDates(schedule.value))
    const eventsForList = computed(() => {
      const notSuggestedCandidates = events.value.filter((ev) => ev.title === i18n.t('labels.rejected').toString())
      const suggestedCandidate = events.value.filter((ev) => ev.title !== i18n.t('labels.rejected').toString())
      const spliteNotSuggestedCandidate = extractAndConstructCandidates(notSuggestedCandidates, schedule.value)
      const joinedEvent = spliteNotSuggestedCandidate.concat(suggestedCandidate)
      return joinedEvent
    })

    function handleCalendarOpen() {
      return AppScreenControlModule.toggleScheduleEventsList(false)
    }
    function handleClickedActionButton() {
      newEventDefault.value.id = nanoid()
      isOpenAddButton.value = true
      showDateRangeInput.value = true
    }

    function OnClickDateRangeInputCancelButton() {
      showDateRangeInput.value = false
      isOpenAddButton.value = false
    }

    function OnClickDateRangeInputSaveButton(payload) {
      showDateRangeInput.value = false
      isOpenAddButton.value = false
      props.onEventUpdate(payload)
    }

    return {
      attendees,
      location,
      hasOnlineMeeting,
      dateRange,
      eventsForList,
      showDateRangeInput,
      newEventDefault,
      isOpenAddButton,
      handleCalendarOpen,
      handleClickedActionButton,
      OnClickDateRangeInputCancelButton,
      OnClickDateRangeInputSaveButton
    }
  }
})
