











import { defineComponent, PropType } from '@vue/composition-api'
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  name: 'OnlineMeetingDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem
  },
  props: {
    value: {
      type: String as PropType<'enabled' | 'disabled'>,
      required: true
    }
  },
  setup(props, { emit }) {
    const i18n = useTranslation()

    function label(value: 'enabled' | 'disabled') {
      return i18n.t(`availabilitySharing.form.webMeeting.${value}`).toString()
    }

    function handleChange(newValue: string) {
      emit('change', newValue)
    }

    return {
      label,
      handleChange
    }
  }
})
