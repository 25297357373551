
































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import SelectedCountryItem from '@/components/newForm/atoms/SelectedCountryItem.vue'
import { FrontSupportCountryCode, frontSupportCountryCodes } from '@/types/frontSupportCountry'
import { Countries, Country } from '@spirinc/contracts'
import { TranslateResult } from 'vue-i18n'
import { holidayExclusion } from '@/lib/utils'
import { MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION } from '@/types'

type CountryOption = {
  country: Country
  selected: boolean
  label: TranslateResult
}

@Component({
  components: {
    FormItem,
    SelectedCountryItem
  }
})
export default class FormSelectHolidays extends Vue {
  @Prop() countries: Countries
  @Prop({ default: MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION }) maxNumOfCountries: number
  @Prop() onCountrySelect: (country: Country) => void
  @Prop() onCountryDelete: (country: Country) => void

  get options(): CountryOption[] {
    return frontSupportCountryCodes.map((code: FrontSupportCountryCode) => ({
      country: { code },
      selected: this.countries.some((country: Country) => code === country.code),
      label: this.getCountryLabel(code)
    }))
  }
  get disabled() {
    return this.countries.length >= this.maxNumOfCountries
  }

  get selectText() {
    const i18nInfo = this.disabled
      ? {
          label: 'availabilitySharing.form.holidayException.addButtonDisabled',
          option: { count: this.maxNumOfCountries }
        }
      : { label: 'availabilitySharing.form.holidayException.addButton', option: {} }
    return this.$t(i18nInfo.label, i18nInfo.option)
  }

  getCountryLabel(code: FrontSupportCountryCode) {
    return holidayExclusion.getI18nCountryName(code)
  }

  handleCountrySelect(option: CountryOption) {
    this.onCountrySelect(option.country)
  }

  handleSelectedCountryDelete(country: Country) {
    this.onCountryDelete(country)
  }
}
