
















import { Component, Prop, Vue } from 'vue-property-decorator'
import TeamInvitedSlideLayout from '@/components/teamSettings/slides/TeamInvitedSlideLayout.vue'

@Component({
  components: {
    TeamInvitedSlideLayout
  }
})
export default class TeamInvitedTokenExpiredSlide extends Vue {
  @Prop() title: string
  @Prop() description: string
}
