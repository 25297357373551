



















import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeAxis from './timeAxis/Index.vue'
import Columns from './columns/Index.vue'
import { ResourceColumn, ResourceEvent } from '@/types'

@Component({
  components: {
    TimeAxis,
    Columns
  }
})
export default class ResourceViewBody extends Vue {
  @Prop() eventDate: Date
  @Prop() columns: ResourceColumn[]
  @Prop() gridHeight: number
  @Prop() selectable: boolean
  @Prop() events: ResourceEvent[]
  @Prop() fullWidthEvents: ResourceEvent[]
  @Prop({ default: true }) isDisplayDay: boolean
  @Prop({ default: true }) isDisplayWeekDay: boolean
}
