












import { defineComponent, PropType, computed, toRefs } from '@vue/composition-api'
import { LanguageSelectItem } from '@/components/ui/spir/types'

export default defineComponent({
  name: 'LanguageItem',
  props: {
    languageItem: {
      type: Object as PropType<LanguageSelectItem>,
      required: true
    },
    isShorten: {
      type: Boolean,
      default: false
    },
    isShowIcon: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { languageItem, isShorten } = toRefs(props)
    return {
      languageLabel: computed(() => (isShorten.value ? languageItem.value.shortenLabel : languageItem.value.label))
    }
  }
})
