























import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarListItem from '@/components/calendarList/molecules/CalenderListItem.vue'
import Avatar from 'vue-avatar'

@Component({
  components: {
    CalendarListItem,
    Avatar
  }
})
export default class CalendarListMemberCheckBox extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: '#1e366a' }) backgroundColor: string
  @Prop() visible: boolean
  @Prop() fullName: string
  @Prop() photoUrl: string

  get hasMenuSlot() {
    return !!this.$slots.menu
  }
}
