














import SettingsSection from '@/components/organisms/settings/Section.vue'
import SpirButton from '@/components/ui/Button.vue'
import SpirCheckbox from '@/components/ui/spir/SpirCheckbox.vue'
import TipMarkBase from '@/components/ui/tipMark/TipMarkBase.vue'
import { useSettings } from '@/composables/useSettings'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { updateBasicProfile } from '@/lib/api/profile'
import ProfileModule from '@/store/modules/profile'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  components: {
    SettingsSection,
    SpirCheckbox,
    TipMarkBase,
    SpirButton
  },
  setup() {
    const i18n = useTranslation()
    const { updateProfile } = useSettings()
    const { openPrimaryTopToast } = useToast()
    const initialPeekCandidatesEnabled = (() => {
      const enabled = ProfileModule.arrangementSettings?.peekCandidatesEnabled
      return enabled === undefined ? true : enabled
    })()
    const peekCandidatesEnabled = ref<boolean>(initialPeekCandidatesEnabled)
    const isDirty = ref(false)
    const isSaving = ref(false)
    const handlePeekCandidatesEnabledUpdate = async (newValue: boolean) => {
      isDirty.value = true
      peekCandidatesEnabled.value = newValue
    }
    const handleSave = async () => {
      const currentModel = ProfileModule.myProfile
      currentModel.arrangementSettings = {
        peekCandidatesEnabled: peekCandidatesEnabled.value
      }
      isSaving.value = true
      try {
        // 現在putAPIにも関わらず、一部のFieldがundefinedでは更新できない
        await updateProfile(() => updateBasicProfile(currentModel))
        peekCandidatesEnabled.value = ProfileModule.arrangementSettings?.peekCandidatesEnabled
        isDirty.value = false
        openPrimaryTopToast({ message: i18n.t('message.commonSuccess').toString() })
      } finally {
        isSaving.value = false
      }
    }
    return {
      peekCandidatesEnabled,
      isSaving,
      isDirty,
      handlePeekCandidatesEnabledUpdate,
      handleSave
    }
  }
})
