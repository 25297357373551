




























import { Component, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import UserModule from '@/store/modules/user'
import TeamInvitedModule from '@/store/modules/teamInvited'
import TeamInvitedLayout from '@/components/teamSettings/TeamInvitedLayout.vue'
import TeamInvitedStartSlide from '@/components/teamSettings/slides/TeamInvitedStartSlide.vue'
import TeamInvitedSignUpSlide from '@/components/teamSettings/slides/TeamInvitedSignUpSlide.vue'
import TeamInvitedSettingIntroSlide from '@/components/teamSettings/slides/TeamInvitedSettingIntroSlide.vue'
import TeamInvitedSettingSlide from '@/components/teamSettings/slides/TeamInvitedSettingSlide.vue'
import TeamInvitedCompleteSlide from '@/components/teamSettings/slides/TeamInvitedCompleteSlide.vue'
import Divider from '@/components/atoms/Divider.vue'
import { APIError } from '@/lib/sdk/error'
import { ERROR_CODE } from '@spirinc/message-catalog'
import queryParams from '@/lib/queryParams'

@Component({
  components: {
    Avatar,
    TeamInvitedLayout,
    TeamInvitedStartSlide,
    TeamInvitedSignUpSlide,
    TeamInvitedSettingIntroSlide,
    TeamInvitedSettingSlide,
    TeamInvitedCompleteSlide,
    Divider
  }
})
export default class TeamMemberInvited extends Vue {
  currentStep = 0

  mounted() {
    const stepStr = this.$route.query.step as string
    const stepNumber = Number(stepStr)
    if (!isNaN(stepNumber)) {
      if (!this.isSignIn) {
        this.currentStep = 0
        return
      }
      if (stepNumber === 1) {
        this.next()
      }
    }
  }

  get isSignIn() {
    return UserModule.isSignIn
  }

  get teamId() {
    return this.$route.params.id
  }

  get invitationId() {
    return this.$route.params.invitationId
  }

  get token() {
    return this.$route.query.token
  }

  get isLoading() {
    return TeamInvitedModule.isLoading
  }

  next() {
    if (this.currentStep < 3 && this.isSignIn) {
      this.currentStep = 3
    } else {
      this.currentStep = this.currentStep + 1
    }
    this.$router.push({ query: { ...this.$route.query, step: this.currentStep.toString() } })
  }

  goToSignUp() {
    this.$router.push({ query: { ...this.$route.query, mode: 'signUp' } })
  }

  goToSignIn() {
    this.$router.push({ query: { ...this.$route.query, mode: 'signIn' } })
  }

  goToApp() {
    this.$router.push('/')
  }

  async acceptInvitation(selectedCalendars) {
    const teamId = this.$route.params.id
    const invitationId = this.$route.params.invitationId
    const token = this.$route.query['token'] as string
    try {
      await TeamInvitedModule.acceptInvitation({
        teamId,
        invitationId,
        token,
        calendars: selectedCalendars
      })
      this.next()
    } catch (e) {
      let messageKey = 'etc'
      if (e instanceof APIError && e.code === ERROR_CODE.USER_ALREADY_JOIN_TEAM) {
        messageKey = 'joined-already'
      }
      this.$router.push({
        name: 'TeamMemberTokenExpired',
        query: { [queryParams.INVITATION_ERROR_CODE]: messageKey }
      })
    }
  }
}
