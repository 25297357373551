






















import ConfirmItemSpacer from '@/components/sidePanels/confirm/atoms/ConfirmItemSpacer.vue'
import BarSkelton from '@/components/ui/BarSkelton.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmItemSpacer,
    BarSkelton
  }
})
