import {
  FormAuthorDropdownItemSetup,
  FormOnlineMeetingDropdownItemSetup
} from '@/components/ui/domain/item/formItemComposables'
import { OnlineMeetingTool } from '@/components/ui/domain/item/FormOnlineMeetingDropdownItem/onlineMeeting'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { OnlineMeetingType } from '@/types'
import { computed } from '@vue/composition-api'

export const useAuthorAndOnlineMeetingsConnect = (source: {
  getDefaultOnlineMeetingTool: (
    info: { accountId: string; calendarId: string },
    previousOnlineMeeting: OnlineMeetingType
  ) => OnlineMeetingTool
  getAvailableOnlineMeetings: (info: { accountId: string; calendarId: string }) => OnlineMeetingTool[]
  authorForm: FormAuthorDropdownItemSetup
  onlineMeetingForm: FormOnlineMeetingDropdownItemSetup
}) => {
  const i18n = useTranslation()
  const { openInfoBottomToast } = useToast()

  const availableOnlineMeetings = computed(() =>
    source.getAvailableOnlineMeetings({
      accountId: source.authorForm.value.accountId,
      calendarId: source.authorForm.value.calendarId
    })
  )
  // 主催者変更時にaccountIdが変更になると利用できるonlineMeetingsも変わるので連動してupdateする
  function updateOnlineMeeting() {
    const onlineMeetingTool = source.getDefaultOnlineMeetingTool(
      {
        accountId: source.authorForm.value.accountId,
        calendarId: source.authorForm.value.calendarId
      },
      source.onlineMeetingForm.onlineMeetingType
    )
    if (source.onlineMeetingForm.onlineMeetingType !== onlineMeetingTool.serviceType) {
      source.onlineMeetingForm.handleUpdate(onlineMeetingTool.serviceType)
      openInfoBottomToast({ message: i18n.t('message.changeOnlineMtgUnavailable').toString(), duration: 5000 })
    }
  }
  return {
    availableOnlineMeetings,
    handleOrganizerUpdate: (newValue: { accountId: string; calendarId: string }) => {
      // source.authorForm.handleUpdateを呼び出してaccountId, calendarIdを更新してからupdateOnlineMeetingを呼び出す。
      source.authorForm.handleUpdate({ accountId: newValue.accountId, calendarId: newValue.calendarId })
      updateOnlineMeeting()
    }
  }
}