









































import DisplayAttendees from '@/components/newForm/molecules/DisplayAttendees.vue'
import TargetCalendarsDropDown from '@/components/ui/domain/dropdown/TargetCalendarsDropdown/index.vue'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { useModal } from '@/composables/useModal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { AvailabilityModelForPrivate } from '@/models/data'
import { AvailabilityCalendarType } from '@spirinc/contracts/dist/apis/models/AvailabilityCalendarType'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import SelectedTargetAttendees from './_SelectedTargetAttendees/index.vue'

export default defineComponent({
  name: 'FormTargetCalendarsSelectItem',
  components: {
    FormItemTitle,
    FormItemSpacer,
    FormItem,
    DisplayAttendees,
    SelectedTargetAttendees,
    TargetCalendarsDropDown
  },
  props: {
    editingObject: {
      type: Object as PropType<AvailabilityModelForPrivate>
    },
    onUpdate: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const { openDangerTopToast } = useToast()
    const { openTargetCalendarToggleModal } = useModal()
    const i18n = useTranslation()
    const { editingObject, onUpdate } = toRefs(props)
    const calendars = computed(() => editingObject.value.calendars)
    const attendees = computed(() => editingObject.value.attendees)
    const organizerAsArray = computed(() => [editingObject.value.organizer])
    const theOthers = computed(() => {
      return editingObject.value.calendars.filter(
        (c) =>
          !(
            c.accountId === editingObject.value.organizer.accountId &&
            c.calendarId === editingObject.value.organizer.calendarId
          ) && !editingObject.value.attendees.some((a) => a.accountId === c.accountId && a.calendarId === c.calendarId)
      )
    })

    function removeFromCalendar(attendee: AvailabilityCalendarType) {
      const findIndex = editingObject.value.calendars.findIndex(
        (c) => c.accountId === attendee.accountId && c.calendarId === attendee.calendarId
      )
      if (findIndex >= 0) {
        editingObject.value.calendars.splice(findIndex, 1)
        onUpdate.value()
      }
    }

    function selectModalProps(attendee: AvailabilityCalendarType): { header: string; body: string } {
      const props =
        editingObject.value.organizer.accountId === attendee.accountId &&
        editingObject.value.organizer.calendarId === attendee.calendarId
          ? {
              header: i18n.t('availabilitySharing.extractCalendarConfirm.organizer.header').toString(),
              body: i18n.t('availabilitySharing.extractCalendarConfirm.organizer.body').toString()
            }
          : {
              header: i18n.t('availabilitySharing.extractCalendarConfirm.attendee.header').toString(),
              body: i18n.t('availabilitySharing.extractCalendarConfirm.attendee.body').toString()
            }
      return props
    }
    return {
      calendars,
      attendees,
      organizerAsArray,
      theOthers,
      handleToggleCalendar: (attendee: AvailabilityCalendarType) => {
        // if current check status is false, no problem.
        if (
          !editingObject.value.calendars.some(
            (c) => c.accountId === attendee.accountId && c.calendarId === attendee.calendarId
          )
        ) {
          editingObject.value.calendars.push(attendee)
          onUpdate.value()
          return
        }
        // there must be one calendar at least.
        if (editingObject.value.calendars.length === 1) {
          openDangerTopToast({
            message: i18n.t('message.error.atLeastOneCalendar').toString()
          })
          return
        }
        // checked calendar is the others calendar, no problem
        if (theOthers.value.some((c) => c.accountId === attendee.accountId && c.calendarId === attendee.calendarId)) {
          return removeFromCalendar(attendee)
        }
        const props = selectModalProps(attendee)
        openTargetCalendarToggleModal({
          props,
          confirm: () => {
            removeFromCalendar(attendee)
          }
        })
      }
    }
  }
})
