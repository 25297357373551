
















import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import SpirHelperText from '@/components/ui/spir/SpirHelperText.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'

export default defineComponent({
  name: 'SpirTextarea',
  components: {
    SpirHelperText
  },
  props: {
    value: {
      type: String
    },
    rows: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    }
  },
  setup(props, context) {
    const { errorState, value } = toRefs(props)
    const hasError = computed(() => errorState.value.type === 'hasError')
    const text = computed({
      get: () => value.value,
      set: (newText: string) => {
        context.emit('input', newText)
      }
    })
    return {
      hasError,
      text
    }
  }
})
