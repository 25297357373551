










import { defineComponent, PropType } from '@vue/composition-api'
import SpirTwoChoicesRadioButton from '@/components/ui/spir/SpirTwoChoicesRadioButton.vue'

export default defineComponent({
  components: {
    SpirTwoChoicesRadioButton
  },
  props: {
    value: {
      type: String as PropType<'and' | 'or'>
    },
    onClick: {
      type: Function as PropType<(newValue: 'and' | 'or') => void>,
      required: true
    }
  }
})
