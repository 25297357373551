
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { PersonalCalendar, Calendar, MemberCalendarRequest } from '@spirinc/contracts'
import MainCalendarSelect from '@/components/teamSettings/selects/MainCalendarSelect.vue'
import Section from './atoms/Section.vue'

@Component({
  components: {
    MainCalendarSelect,
    Section
  }
})
export default class TeamMainCalendarSelectView extends Vue {
  @Prop() primaryCalendar: MemberCalendarRequest
  @Prop({ default: [] }) selectableCalendarGroups: PersonalCalendar[]
  @Prop() isLoading: boolean
  s
  onChangeMainCalendar(calendar: Calendar) {
    this.$emit('selectPrimaryCalendar', calendar)
  }
}
