
























import { CardItem } from '@/lib/utils'
import { Attendee, CardItemTip } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AttendeeLine from '../atoms/AttendeeLine.vue'
import CardBodyItem from '../atoms/CardBodyItem.vue'

const MAX_COUNT = 3

@Component({
  components: {
    CardBodyItem,
    AttendeeLine
  }
})
export default class AttendeesCardBodyItem extends Vue {
  @Prop() attendees: Attendee[]
  @Prop() i18nLabel: string
  @Prop() i18nAttendee: string
  @Prop() i18nAttendingType?: string
  @Prop({ default: 'full-name-and-email' }) type: 'icon' | 'title-only' | 'full-name-and-email'

  get currentAttendees() {
    if (!this.attendees) {
      return []
    }
    return this.attendees
  }

  get itemLabel() {
    return this.$t(this.i18nLabel)
  }

  get attendeeCountMessage() {
    let message = this.$t(this.i18nAttendee, { count: this.currentAttendees.length })
    if (this.i18nAttendingType) {
      message += ` (${this.$t(this.i18nAttendingType)})`
    }
    return message
  }

  get filteredAttendees() {
    return this.currentAttendees.slice(0, MAX_COUNT)
  }
  get otherAttendees() {
    return this.currentAttendees.slice(MAX_COUNT, 5000)
  }

  get isOverMaxCount(): boolean {
    return this.currentAttendees.length > MAX_COUNT
  }
  get overCount(): number {
    return this.currentAttendees.length - MAX_COUNT
  }
  get tipInfo(): CardItemTip {
    return CardItem.convertToCardItemTip(true, this.$t(this.i18nLabel).toString())
  }
}
