

















import EditEventModule from '@/store/modules/editEvent'
import EventModule from '@/store/modules/event'
import ProfileModule from '@/store/modules/profile'
import { GoogleEvent } from '@/types'
import { cloneDeep, debounce } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormCommon from './FormCommon.vue'

@Component({
  components: {
    FormCommon
  }
})
export default class EventFormForPrivate extends Vue {
  @Prop() targetObject: GoogleEvent
  @Prop() timeZone: string | undefined
  @Prop({ default: false }) edit: boolean

  updateModelDebounce = null
  editingObject: GoogleEvent = null

  @Watch('targetObject')
  setEditingObject(newValue) {
    if (newValue) {
      this.editingObject = cloneDeep(this.targetObject)
    }
  }

  created() {
    this.updateModelDebounce = debounce(this.updateEditingObject, 300)
    if (this.targetObject) {
      this.editingObject = cloneDeep(this.targetObject)
    }
  }

  get isLoading() {
    return EditEventModule.isLoading || EventModule.isLoading
  }

  get myInfo() {
    return ProfileModule.myProfile
  }

  handleDateUpdate(data: { start: Date; end: Date }) {
    EditEventModule.updateDate({ ...data, allDay: this.targetObject.allDay })
  }

  updateEditingObject() {
    this.$emit('updateEditingObject', this.editingObject)
  }
}
