




import { defineComponent, onMounted, ref } from '@vue/composition-api'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { useAuthSignUp } from '@/composables/auth/useAuthSignUpWithRedirect'
import { useRouter } from 'vue2-helpers/vue-router'
import { authErrorTypes } from '@/types/authError'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'
import queryParams from '@/lib/queryParams'
import { SessionStorage } from '@/store/lib/sessionStorage'
import { useAuthErrorToast } from '@/composables/auth/useAuthErrorToast'
import { useAddGoogleCalenarWithRedirect } from '@/composables/auth/useAddGoogleCalenarWithRedirect'

export default defineComponent({
  components: {
    LoadingSpinner
  },
  setup() {
    const router = useRouter()
    const savedAuthAction = ref(null)
    const { showAuthErrorToast } = useAuthErrorToast()
    const { sendSignal } = useSendSignal()
    const { signUpAndAddCalendarToGoogle } = useAuthSignUp()
    const { registerGoogleCalendar } = useAddGoogleCalenarWithRedirect()
    const routerPush = (payloads) => {
      // fromが、sign in , sign up, add calendarなど、Auth前の画面だった場合、payloadのページに遷移。それ以外は、Redirect前の画面に遷移
      const redirectPath = savedAuthAction.value.from === 'BeforeAuth' ? payloads : savedAuthAction.value.redirectFrom
      return router.push(redirectPath).catch(GuardExceptionHandlers.noopAgainstRedirection)
    }
    const processSignUp = async () => {
      const response = await signUpAndAddCalendarToGoogle(location.href)
      switch (response.result) {
        case 'cancel':
          return routerPush({ name: 'SignUp' })
        case 'error':
          showAuthErrorToast(response.errorCode)
          await sendSignal(SignalType.SIGNUP_FAILURE, {
            type: 'google',
            from: savedAuthAction.value.from,
            errorCode: response.errorCode
          })
          return routerPush({ name: 'SignUp' })
        case 'errorInRegisterCalendar':
          await sendSignal(SignalType.SIGNUP_FAILURE, {
            type: 'google',
            from: savedAuthAction.value.from,
            errorCode: response.errorCode
          })
          return routerPush({ name: 'AddCalendar', query: { [queryParams.ERROR_CODE]: response.errorCode } })
        case 'success':
          await sendSignal(SignalType.SIGNUP_SUCCESS, { type: 'google', from: savedAuthAction.value.from || 'TopPage' })
          await sendSignal(SignalType.SYNC_ACCOUNT_SUCCESS, {
            type: 'google',
            from: savedAuthAction.value.from || 'TopPage'
          })
          return routerPush({ name: 'Main' })
        case 'signin':
          sendSignal(SignalType.SIGNIN_SUCCESS, { type: 'google', from: savedAuthAction.value.from || 'TopPage' })
          return routerPush({ name: 'Main' })
      }
    }
    const processAddCalendar = async () => {
      const response = await registerGoogleCalendar(location.href)
      switch (response.result) {
        case 'cancel':
          return routerPush({ name: 'AddCalendar' })
        case 'error':
          showAuthErrorToast(response.errorCode)
          sendSignal(SignalType.SYNC_ACCOUNT_FAILURE, { type: 'google', errorCode: response.errorCode })
          return routerPush({ name: 'AddCalendar' })
        case 'success':
          sendSignal(SignalType.SYNC_ACCOUNT_SUCCESS, { type: 'google' })
          return routerPush({ name: 'Main' })
      }
    }
    onMounted(async () => {
      const authAction = SessionStorage.getAuthAction()
      savedAuthAction.value = authAction
      SessionStorage.setAuthAction(null)
      if (authAction?.action === 'SignUp') {
        return processSignUp()
      } else if (authAction?.action === 'AddCalendar') {
        return processAddCalendar()
      }
      showAuthErrorToast(authErrorTypes.unknown)
      return routerPush({ name: 'SignUp' })
    })
    return {}
  }
})
