













import { Component, Vue } from 'vue-property-decorator'
import ConfirmWithEmailAndName from './molecules/ConfirmWithEmailAndName.vue'
import UserModule from '@/store/modules/user'

type RequestData = {
  email: string
  name: string
}

@Component({
  components: {
    ConfirmWithEmailAndName
  }
})
export default class SuggestAlternativeCandidates extends Vue {
  requestData: RequestData = {
    email: '',
    name: ''
  }

  get isSignIn() {
    return UserModule.isSignIn
  }
  get subTitle() {
    if (this.isSignIn) {
      return this.$t('alternativeCandidates.modal.suggestDetail.signIn').toString()
    }
    return this.$t('alternativeCandidates.modal.suggestDetail.notSignIn').toString()
  }

  register() {
    this.$emit('register', this.requestData)
    this.$emit('close')
  }
}
