



























import { Component, PropSync, Vue } from 'vue-property-decorator'
import { AvailabilityModelForTeam } from '@/models/data'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import AndOrSelector from '@/components/patterns/form/atoms/AndOrSelector.vue'
import MemberSelector from '@/components/patterns/form/molecules/MemberSelector.vue'
import OrganizerMemberSelector from '@/components/patterns/form/molecules/OrganizerMemberSelector.vue'
import TargetNotificationEmails from '@/components/molecules/TargetNotificationEmails.vue'

@Component({
  components: {
    FromGroup,
    AndOrSelector,
    MemberSelector,
    OrganizerMemberSelector,
    TargetNotificationEmails
  }
})
export default class PatternSidePanelMemberPickers extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: AvailabilityModelForTeam

  get allActiveTeamMembers() {
    return this.editingObjectSynced.allActiveTeamMembers
  }
  get allMembers() {
    return this.allActiveTeamMembers.filter((m) => m.id !== this.editingObjectSynced.organizerMemberId)
  }
  updatedSelector(newValue) {
    this.editingObjectSynced.organizerMemberId = ''
    this.editingObjectSynced.memberIds = []
    this.editingObjectSynced.attendingType = newValue
    this.updateCondition()
  }
  addMember(newMember) {
    this.editingObjectSynced.memberIds.push(newMember)
    this.updateCondition()
  }
  deleteMember(deletedMemberId) {
    const findIndex = this.editingObjectSynced.memberIds.findIndex((id) => id === deletedMemberId)
    this.editingObjectSynced.memberIds.splice(findIndex, 1)
    this.updateCondition()
  }
  selectedOrganizer(organizerId: string) {
    // remove current organizer from member ids
    if (this.editingObjectSynced.organizerMemberId) {
      const findIndex = this.editingObjectSynced.memberIds.indexOf(this.editingObjectSynced.organizerMemberId)
      if (findIndex >= 0) {
        this.editingObjectSynced.memberIds.splice(findIndex, 1)
      }
    }
    this.editingObjectSynced.organizerMemberId = organizerId
    this.editingObjectSynced.memberIds.push(organizerId)
    this.updateCondition()
  }
  updateObject() {
    this.$emit('updateObject')
  }
  updateCondition() {
    this.$emit('updateCondition')
  }
}
