


















import HomeIconButton from '@/components/atoms/HomeIconButton.vue'
import CreateButtons from '@/components/CreateMenu/CreateMenu.vue'
import FloatingMenuLayout from '@/components/layout/floatingMenu.vue'
import { useFloatingMenuActions } from '@/composables/calendar/useFloatingMenuActions'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import { defineComponent, getCurrentInstance, onMounted } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import Common from './Common.vue'

export default defineComponent({
  name: 'CalendarMain',
  components: {
    Common,
    CreateButtons,
    FloatingMenuLayout,
    HomeIconButton
  },
  props: {
    showSidePanel: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const route = useRoute()
    const currentInstance = getCurrentInstance()
    const i18n = useTranslation()
    const { openDangerBottomToast } = useToast()
    const { floatingMenuRef, additionalEvents, addCandidate, resetTempEvent } = useFloatingMenuActions()

    function showToast() {
      const errorMessageKey = route.query.error
      if (!errorMessageKey) {
        return
      }
      let errorKey = 'message.errorCommon'
      if (currentInstance.proxy.$te(`message.error.${errorMessageKey}`)) {
        errorKey = `message.error.${errorMessageKey}`
      }
      openDangerBottomToast({
        message: i18n.t(errorKey).toString()
      })
    }

    onMounted(() => {
      resetTempEvent()
      AppScreenControlModule.toggleScheduleEventsList(false)
      showToast()
    })
    return {
      floatingMenuRef,
      additionalEvents,
      handleCandidateAdd: addCandidate,
      handleCandidateReset: resetTempEvent
    }
  }
})
