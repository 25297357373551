




















import { defineComponent } from '@vue/composition-api'
import AppealBox from '@/components/auth/AppealBox.vue'
import SignInButton from '@/components/molecules/SignInButton.vue'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'

export default defineComponent({
  components: {
    AppealBox,
    SignInButton
  },
  setup() {
    const { sendSignal } = useSendSignal()
    const sendSignUpSignal = () => {
      return sendSignal(SignalType.CLICK_SIGNUP_CONFIRM)
    }
    return {
      sendSignUpSignal
    }
  }
})
