














import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import UnconfirmedScheduleEmptyList from '@/components/schedule/list/scheduleList/UnconfirmedScheduleEmptyList.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import UnconfirmedListBox from '@/components/adjustmentList/UnconfirmedListBox.vue'
import { UnconfirmedListTabContentType } from '@/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  components: {
    LoadingSpinner,
    UnconfirmedScheduleEmptyList,
    ListItemSpacer,
    UnconfirmedListBox
  },
  props: {
    content: {
      type: Object as PropType<UnconfirmedListTabContentType>
    }
  },
  setup(props) {
    const { content } = toRefs(props)
    const cards = computed(() => content.value.cards)
    const isEmpty = computed(() => cards.value.length === 0)
    return {
      isEmpty,
      cards
    }
  }
})
