
































import { Component, Prop, PropSync, Watch, Vue } from 'vue-property-decorator'
import CalendarsModule from '@/store/modules/calendars'
import ProfileModule from '@/store/modules/profile'
import { ITypeCalendarListForUI } from '@/types'
import CalendarSelector2 from '@/components/forms/CalendarSelector2.vue'
import OtherCalendarItem from '@/components/forms/OtherCalendarItem.vue'
import { SelectedCalendar } from '../data/defaultAccountAndCalendarId'

@Component({
  components: {
    CalendarSelector2,
    OtherCalendarItem
  }
})
export default class EmailOrCalendarSelect extends Vue {
  @Prop() formattedDate: string
  @Prop({ default: true }) showForm: boolean
  @Prop({ default: null }) subTitle: string
  @Prop({ default: () => [] }) ignoreEmails?: string[]
  @PropSync('email', { type: String }) emailSynced!: string
  @PropSync('name', { type: String }) nameSynced!: string
  @PropSync('selectedCalendar', { type: Object }) selectedCalendarSynced!: SelectedCalendar

  isOtherMailDropdownActive = false
  useOtherMail = false
  otherMail = ''
  selectedCalendarIndex = 0

  mounted() {
    this.setEmailFromCalendar()
    this.setNameFromProfile()
  }
  @Watch('useOtherMail')
  updatedOtherMail(newValue) {
    if (newValue) {
      this.emailSynced = this.otherMail
      this.nameSynced = this.otherMail
    } else {
      this.setEmailFromCalendar()
      this.setNameFromProfile()
    }
  }
  get getSubTitle() {
    return !this.showForm ? '' : this.subTitle || this.$t('message.confirm.pleaseInputYourInfo')
  }
  get writableButNotGroupCalendars(): ITypeCalendarListForUI[] {
    return this.writableCalendars.filter((c) => c.type === 'normal')
  }
  get writableCalendars() {
    return CalendarsModule.writableCalendars
  }
  get fullName() {
    return ProfileModule.userInfo.fullName ?? ''
  }
  get getIsOtherMailDropdownActive() {
    return this.isOtherMailDropdownActive
  }
  get getUseOtherMail() {
    return this.useOtherMail
  }
  confirmOtherCalendar(email: string) {
    this.useOtherMail = true
    this.otherMail = email
    this.updatedEmailCondition(email)
    this.updatedNameCondition(email)
  }
  resetOtherMail() {
    this.useOtherMail = false
    this.otherMail = ''
  }
  otherMailActiveChange(status: boolean) {
    this.isOtherMailDropdownActive = status
  }
  setEmailFromCalendar() {
    if (this.selectedCalendarSynced) {
      const calendar = this.writableButNotGroupCalendars.find(
        (c) =>
          c.accountId === this.selectedCalendarSynced.accountId &&
          c.calendarId === this.selectedCalendarSynced.calendarId
      )
      this.emailSynced = calendar.invitationAddress
    }
  }
  setNameFromProfile() {
    this.nameSynced = this.fullName
  }
  updatedEmailCondition(email: string) {
    this.emailSynced = email
  }
  updatedNameCondition(name: string) {
    this.nameSynced = name
  }
}
