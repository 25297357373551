import { Mergeable } from './mergeable'
import { Attendee } from '@/types'
import { FullCalendarEvent } from '@/types'
import {
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse,
  PrioritizedOnlineMeetings
} from '@spirinc/contracts'

export type AttendeeForAvailability = {
  fullName: string
  photoURL?: string
  userId?: string
  accountId: string
  calendarId: string
  email: string
  organizer?: boolean
}

export const decodeAttendee = (attendee: AttendeeForAvailability): Attendee => {
  return {
    id: attendee.userId,
    fullName: attendee.fullName,
    photoURL: attendee.photoURL,
    email: attendee.email,
    organizer: attendee.organizer
  }
}

export type ConfirmAvailabilityModelData =
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse & {
    id: string
    teamId?: string
  }
export class ConfirmAvailabilityModel extends Mergeable {
  id?: string
  teamId?: string
  title: string
  duration: number
  location?: string
  description?: string
  start: string
  end: string
  candidates: FullCalendarEvent[]
  organizer?: Attendee
  attendees: Attendee[]
  candidateDescription?: string
  timeZone: string
  prioritizedOnlineMeetings: PrioritizedOnlineMeetings

  constructor(data: ConfirmAvailabilityModelData) {
    super(data.candidates)
    this.teamId = data.teamId
    this.id = data.id
    this.title = data.title || ''
    this.duration = data.duration || 30
    this.location = data.location
    this.description = data.description
    this.start = data.start
    this.end = data.end
    this.candidateDescription = data.candidateDescription
    this.prioritizedOnlineMeetings = data.prioritizedOnlineMeetings
    if (data.organizer) {
      this.organizer = decodeAttendee(data.organizer)
    }
    if (data.attendees && data.attendees.length > 0) {
      this.attendees = data.attendees.map((a) => decodeAttendee(a))
    } else {
      this.attendees = []
    }
    this.timeZone = data.timeZone
  }

  get isTeamAvailability() {
    return !!this.teamId
  }
}
