

















import { Component, Vue } from 'vue-property-decorator'
import TeamInvitedSlideLayout from '@/components/teamSettings/slides/TeamInvitedSlideLayout.vue'

@Component({
  components: {
    TeamInvitedSlideLayout
  }
})
export default class TeamInvitedCompleteSlide extends Vue {
  next() {
    this.$emit('next')
  }
}
