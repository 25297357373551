










































import { computed, defineComponent, PropType } from '@vue/composition-api'
import CollapseWithChevronHeader from '@/components/atoms/CollapseWithChevronHeader.vue'
import CalendarAvatarAndName from '@/components/calendarList/atoms/CalendarAvatarAndName.vue'
import MemberCheckBox from '@/components/calendarList/molecules/MemberCheckBox.vue'
import MemberCheckBoxAndMenu from '@/components/calendarList/molecules/MemberCheckBoxAndMenu.vue'
import { TeamMemberCalendar } from '@/models/data/team'
import CalendarModule from '@/store/modules/calendars'
import PersonalCalendarListItem from '@/components/calendarList/molecules/PersonalCalendarListItem.vue'
import { isMobile } from '@/lib/utils'
import { useDeferredFeature } from '@/composables/useDeferredFeature'

export default defineComponent({
  name: 'TeamCalendarBlock',
  components: {
    CollapseWithChevronHeader,
    CalendarAvatarAndName,
    MemberCheckBox,
    MemberCheckBoxAndMenu,
    PersonalCalendarListItem
  },
  props: {
    teamCalendar: {
      type: Object as PropType<TeamMemberCalendar>
    },
    onMemberCalendarColorChange: {
      type: Function as PropType<(payload: { teamId: string; memberId: string; color: string }) => void>
    }
  },
  setup(props, { emit }) {
    const myCalendars = computed(() => {
      if (!props.teamCalendar.self.calendars) {
        return []
      }
      return CalendarModule.flattenCalendarsArray.filter((iCalendar) => {
        return props.teamCalendar.self.calendars.find(
          (c) => c.calendar.accountId === iCalendar.accountId && c.calendar.id === iCalendar.id
        )
      })
    })
    const myCalendarCheckStatus = computed(() => myCalendars.value.every((c) => c.visible))

    function handleToggleCalendar(accountId: string, calendarId: string, visible: boolean) {
      CalendarModule.toggleCalendar({
        accountId,
        calendarId,
        visible
      })
    }

    function toggleMyCalendars() {
      const toggled = !myCalendarCheckStatus.value
      myCalendars.value.forEach((mc) => handleToggleCalendar(mc.accountId, mc.calendarId, toggled))
    }

    function handleToggleMember(memberId: string) {
      emit('toggleCalendar', {
        teamId: props.teamCalendar.id,
        memberId
      })
    }

    function handleMemberCalendarColorChange(payload: { memberId: string; color: string }) {
      props.onMemberCalendarColorChange({ ...payload, teamId: props.teamCalendar.id })
    }

    function handleCalendarBackgroundColorChange(payload) {
      CalendarModule.setCalendarBackgroundColor(payload)
    }

    const { defer } = useDeferredFeature(myCalendars.value.length + props.teamCalendar.memberCalendars.length)

    return {
      isMobile: isMobile(),
      myCalendars,
      myCalendarCheckStatus,
      handleToggleCalendar,
      toggleMyCalendars,
      handleToggleMember,
      handleMemberCalendarColorChange,
      handleCalendarBackgroundColorChange,
      defer
    }
  }
})
