









































import SpirButton from '@/components/ui/Button.vue'
import ArrangementPeekCandidateList from '@/components/ui/domain/collapse/ArrangementPeekCandidateList/ArrangementPeekCandidateList.vue'
import CommonHeaderParts from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/_CommonHeaderParts.vue'
import { PeekCandidatesPanelHandler } from '@/components/ui/domain/sidePanel/arrangement/useArrangementPeekCandidatesSidePanel'
import {
  DateSlot,
  DatetimeSlotDeletePayload,
  DatetimeSlotSavePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import TipMarkBase from '@/components/ui/tipMark/TipMarkBase.vue'
import { useAnalytics } from '@/composables/useAnalytics'
import { SignalType } from '@/lib/analytics'
import { ListType } from '@/types'
import { defineComponent, inject, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'EditablePeekCandidatesSection',
  components: {
    ListItemSpacer,
    SpirButton,
    ArrangementPeekCandidateList,
    CommonHeaderParts,
    TipMarkBase
  },
  props: {
    isAutoCandidatesNoticeVisible: {
      type: Boolean,
      default: true
    },
    dateSlots: {
      type: Array as PropType<DateSlot[]>,
      default: () => []
    },
    durationToSplit: {
      type: Number,
      required: true
    },
    onAllDelete: {
      type: Function as PropType<() => void>,
      required: true
    },
    onSave: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>,
      required: true
    },
    onDatetimeDelete: {
      type: Function as PropType<(payload: DatetimeSlotDeletePayload) => void>,
      required: true
    },
    onDatetimeUpdate: {
      type: Function as PropType<(payload: DatetimeSlotUpdatePayload) => void>,
      required: true
    },
    onCalendarOpen: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const analytics = useAnalytics()
    const { handleOpen } = inject(PeekCandidatesPanelHandler)

    function handlePeekCandidatesPanelOpen() {
      handleOpen()
      analytics.send(SignalType.CLICK_PEEK_CANDIDATE_OPEN)
    }
    function handleAllDelete() {
      props.onAllDelete()
      analytics.send(SignalType.CLICK_CANDIDATE_ALL_DELETE)
    }
    function handleSave(payload: DatetimeSlotSavePayload) {
      props.onSave(payload)
      analytics.send(SignalType.CLICK_CANDIDATE_ADD)
    }
    function handleDatetimeDelete(payload: DatetimeSlotDeletePayload) {
      props.onDatetimeDelete(payload)
      analytics.send(SignalType.CLICK_CANDIDATE_DELETE)
    }
    function handleDatetimeUpdate(payload: DatetimeSlotUpdatePayload) {
      props.onDatetimeUpdate(payload)
      analytics.send(SignalType.CLICK_CANDIDATE_EDIT)
    }
    return {
      scheduleType: ListType.SCHEDULE,
      handlePeekCandidatesPanelOpen,
      handleAllDelete,
      handleSave,
      handleDatetimeDelete,
      handleDatetimeUpdate
    }
  }
})
