













import { computed, defineComponent, PropType } from '@vue/composition-api'
import { TipPosition } from './tipMark'

function toBuefyPosition(pos: TipPosition): 'is-right' | 'is-bottom' | 'is-top' {
  switch (pos) {
    case 'bottom':
      return 'is-bottom'
    case 'top':
      return 'is-top'
    default:
      return 'is-right'
  }
}
export default defineComponent({
  props: {
    pos: {
      type: String as PropType<TipPosition>,
      default: 'right'
    },
    width: {
      type: Number,
      default: 260
    }
  },
  setup(props) {
    const position = computed(() => toBuefyPosition(props.pos))
    return {
      position
    }
  }
})
