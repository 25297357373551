
















import Vue from 'vue'
import Component from 'vue-class-component'
import SettingsSection from '@/components/organisms/settings/Section.vue'
import { Subscription } from '@/types'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    SettingsSection
  }
})
export default class SubscriptionSection extends Vue {
  @Prop({ default: null }) subscription: Subscription | null
  toSubscription() {
    this.$router.push({ name: 'Subscription' })
  }
}
