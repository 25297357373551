















import { cloneDeep } from 'lodash'
import { computed, defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import FormMessageFormSelectBox from '@/components/ui/domain/item/confirmationForm/FormMessageFormSelectBox.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'

export default defineComponent({
  name: 'ConfirmationFormMessageSection',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    FormMessageFormSelectBox
  },
  setup() {
    const { editingObject, updateEditingObject } = useAvailabilitySharingForm()

    const confirmationFormMessageProperty = computed(() => editingObject.value.confirmationForm.properties['message'])
    const confirmationFormMessageRequired = computed(() =>
      editingObject.value.confirmationForm.required.some((key) => key === 'message')
    )
    function handleConfirmationFormMessagePropertyActive(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.confirmationForm.properties['message'].isActive = newValue
      updateEditingObject(clone)
    }
    function handleConfirmationFormMessageRequired(newValue) {
      const clone = cloneDeep(editingObject.value)
      const findIndex = clone.confirmationForm.required.indexOf('message')
      if (newValue) {
        if (findIndex < 0) {
          clone.confirmationForm.required.push('message')
        }
      } else {
        if (findIndex >= 0) {
          clone.confirmationForm.required.splice(findIndex, 1)
        }
      }
      updateEditingObject(clone)
    }

    return {
      confirmationFormMessageProperty,
      confirmationFormMessageRequired,
      handleConfirmationFormMessagePropertyActive,
      handleConfirmationFormMessageRequired
    }
  }
})
