







import MemberAvatar from '@/components/molecules/MemberAvatar.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import OrganizerInfoCardBodyItemFrame from './_OrganizerInfoCardBodyItemFrame.vue'

@Component({
  components: {
    OrganizerInfoCardBodyItemFrame,
    MemberAvatar
  }
})
export default class OrganizerInfoCardBodyItem extends Vue {
  @Prop() organizer: { fullName?: string; userId?: string; photoURL?: string }
}
