














import ConfirmedListPastButton from '@/components/adjustmentList/ConfirmedAllListBox.vue'
import ConfirmedListBox from '@/components/adjustmentList/ConfirmedListBox.vue'
import ConfirmedScheduleEmptyList from '@/components/adjustmentList/ConfirmedScheduleEmptyList.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import CalendarFilterButton from '@/components/ui/CalendarFilterButton.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import i18n from '@/i18n'
import { ConfirmedPrivateDateGroup, ConfirmedTeamDateGroup } from '@/types'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  components: {
    LoadingSpinner,
    CalendarFilterButton,
    ConfirmedScheduleEmptyList,
    ConfirmedListBox,
    ListItemSpacer,
    ConfirmedListPastButton
  },
  props: {
    past: {
      type: Array as PropType<Array<ConfirmedPrivateDateGroup> | Array<ConfirmedTeamDateGroup>>,
      required: true
    },
    future: {
      type: Array as PropType<Array<ConfirmedPrivateDateGroup> | Array<ConfirmedTeamDateGroup>>,
      required: true
    }
  },
  setup() {
    const isPastAdjustmentsDisplayed = ref(false)
    const pastButtonTitle = computed(() => {
      return isPastAdjustmentsDisplayed.value
        ? i18n.t('scheduleTabs.lists.hidePastSchedules')
        : i18n.t('scheduleTabs.lists.showPastSchedules')
    })
    function handlePastButtonClick() {
      isPastAdjustmentsDisplayed.value = !isPastAdjustmentsDisplayed.value
    }
    return {
      pastButtonTitle,
      isPastAdjustmentsDisplayed,
      handlePastButtonClick
    }
  }
})
