





















import DateTimeForm from '@/components/organisms/DateTimeForm.vue'
import SpirButton from '@/components/ui/Button.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { computed, defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { addMinutes, differenceInMinutes, isAfter, isBefore, isEqual, subMinutes } from 'date-fns'
import { DatetimeSlotSavePayload } from './datetimeSlot'

export default defineComponent({
  name: 'DatetimeSlotEditor',
  components: {
    DateTimeForm,
    FormItemSpacer,
    SpirButton
  },
  props: {
    eventToEdit: {
      type: Object as PropType<{ id: string; start: Date; end: Date }>
    },
    splitEventsByDuration: {
      type: Number, // 空き時間URLは30のみ
      required: true
    },
    fixDuration: {
      type: Boolean,
      default: false // poll のみtrue
    },
    disabledPast: {
      type: Boolean, // 空き時間URLのみfalse
      default: true
    },
    minInterval: {
      type: Number // 空き時間URLのみ
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true
    },
    onSave: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>,
      required: true
    }
  },
  setup(props) {
    const { eventToEdit, splitEventsByDuration, fixDuration } = toRefs(props)
    const startDate = ref<Date | null>(null)
    const endDate = ref<Date | null>(null)

    if (eventToEdit.value) {
      startDate.value = eventToEdit.value.start
      endDate.value = eventToEdit.value.end
    }

    function setStartDate() {
      const newStartdate = subMinutes(endDate.value, splitEventsByDuration.value)
      if ((fixDuration.value && !isEqual(startDate.value, newStartdate)) || isAfter(startDate.value, newStartdate)) {
        startDate.value = newStartdate
      }
    }
    watch(endDate, () => {
      if (!endDate.value) {
        return
      }
      setStartDate()
    })

    function setEndDate() {
      const newEndDate = addMinutes(startDate.value, splitEventsByDuration.value)
      if ((fixDuration.value && !isEqual(endDate.value, newEndDate)) || isBefore(endDate.value, newEndDate)) {
        endDate.value = newEndDate
      }
    }
    watch(startDate, () => {
      if (!startDate.value) return
      if (startDate.value === eventToEdit.value?.start && endDate.value === eventToEdit.value?.end) return
      setEndDate()
    })
    watch(splitEventsByDuration, () => {
      if (!startDate.value) {
        return
      }
      const minutesEndDateAndStarDate = differenceInMinutes(endDate.value, startDate.value)
      if (minutesEndDateAndStarDate < splitEventsByDuration.value) {
        setEndDate()
      }
    })
    function reset() {
      startDate.value = endDate.value = null
    }

    function handleCancel() {
      reset()
      props.onCancel()
    }
    const minEndDate = computed(() => {
      if (!startDate.value) {
        return null
      }
      return addMinutes(startDate.value, splitEventsByDuration.value)
    })
    const isValid = computed(() => {
      return startDate.value && endDate.value
    })
    function handleSave() {
      const payload: DatetimeSlotSavePayload = {
        id: eventToEdit.value?.id,
        allDay: false,
        end: endDate.value,
        start: startDate.value
      }
      props.onSave(payload)
      reset()
    }

    return {
      startDate,
      endDate,
      minEndDate,
      isValid,
      handleCancel,
      handleSave
    }
  }
})
