
















import { defineComponent, ref, PropType } from '@vue/composition-api'
import AvatarOnTabHeader from '@/components/molecules/TeamSelectorTab/AvatarOnTabHeader.vue'
import UnconfirmedListPrivateTabContent from '@/components/adjustmentList/UnconfirmedListPrivateTabContent.vue'
import UnconfirmedListTeamTabContent from '@/components/adjustmentList/UnconfirmedListTeamTabContent.vue'
import { UnconfirmedTabsState } from '@/types'

export default defineComponent({
  components: {
    AvatarOnTabHeader,
    UnconfirmedListPrivateTabContent,
    UnconfirmedListTeamTabContent
  },
  props: {
    currentTab: {
      type: String as PropType<'private' | string>,
      required: true
    },
    tabsState: {
      type: Object as PropType<UnconfirmedTabsState>,
      required: true
    },
    onTabChange: {
      type: Function as PropType<(tab: string) => void>,
      required: true
    },
    tabsClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const currentTabRef = ref(props.currentTab)
    return {
      currentTabRef
    }
  }
})
