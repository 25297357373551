








import { Component, Vue } from 'vue-property-decorator'
import MainView from '../Main.vue'
@Component({
  components: {
    MainView
  }
})
export default class ListRoot extends Vue {}
