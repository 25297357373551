













import { Component, Vue } from 'vue-property-decorator'
import TeamCalendarBlock from './TeamCalendarBlock.vue'
import CalendarPanel from '@/components/calendarList/molecules/CalendarPanel.vue'
import TeamCalendarModule from '@/store/modules/teamCalendar'

@Component({
  components: {
    CalendarPanel,
    TeamCalendarBlock
  }
})
export default class TeamCalendarList extends Vue {
  get hasTeamCalendar() {
    return this.teamCalendars?.length > 0
  }
  get teamCalendars() {
    return TeamCalendarModule.teamCalendars
  }
  onToggleCalendar(payload) {
    TeamCalendarModule.toggleMemberCalendar(payload)
  }
  handleMemberCalendarColorChange(payload: { teamId: string; memberId: string; color: string }) {
    TeamCalendarModule.changeMemberCalendarColor(payload)
  }
}
