











import { Component, Prop, Vue } from 'vue-property-decorator'
import CancelButton from '@/components/atoms/CancelButton.vue'

export type CancelOptions = {
  icon?: string
  title?: string
}

@Component({
  components: {
    CancelButton
  }
})
export default class SidePanelHeaderButton extends Vue {
  @Prop({ default: 'close' }) cancelIcon: string
}
