









import FormCollapseButton from '@/components/newForm/atoms/CollapseButton.vue'
import FormTargetCalendarsSelectItem from '@/components/ui/domain/item/FormTargetCalendarsSelectItem/index.vue'
import { AvailabilityModelForPrivate } from '@/models/data'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FormCollapseButton,
    FormTargetCalendarsSelectItem
  }
})
export default class PatternSidePanelTargetCalendars extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: AvailabilityModelForPrivate

  updateCondition() {
    this.$emit('updateCondition')
  }
}
