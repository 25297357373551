




import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import EditEventModule from '@/store/modules/editEvent'
import ProfileModule from '@/store/modules/profile'
import UserModule from '@/store/modules/user'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import CalendarMixin from '../CalendarMixin'
import EventLayout from './Layout.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    EventLayout
  }
})
export default class EventEdit extends mixins(CalendarMixin) {
  async created() {
    await UserModule.fetchIntegrations(true)
  }
  async mounted() {
    // there must be editing event when user accessed.
    if (!EditEventModule.isExist) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
      this.$router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
    }
    this.toggleScheduleEventsList(true)
  }
  async beforeRouteLeave(route, redirect, next) {
    if (EditEventModule.isDirty) {
      if (await this.confirmDiscardItem()) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
  get myProfile() {
    return ProfileModule.myProfile
  }
  get timeZone(): string | undefined {
    return this.myProfile?.primaryTimezoneKey
  }
}
