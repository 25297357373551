






















































import FormCollapseButton from '@/components/newForm/atoms/CollapseButton.vue'
import FormGroup from '@/components/newForm/atoms/FormGroup.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import AttendeeSelectorByEmailWithTeamMember from '@/components/newForm/molecules/AttendeeSelectorByEmailWithTeamMember.vue'
import Duration from '@/components/newForm/molecules/Duration.vue'
import HeaderTitleInput from '@/components/newForm/molecules/HeaderTitle.vue'
import InputField from '@/components/newForm/molecules/InputField.vue'
import OnlineMeeting from '@/components/newForm/molecules/OnlineMeeting.vue'
import PrimaryCalendarSelector from '@/components/newForm/molecules/WritableCalendarWithAccountInfo.vue'
import EventFormDateInputWrapper from '@/components/schedule/forms/EventFormDateInputWrapper.vue'
import FormTextareaWithHtml from '@/components/ui/domain/item/FormTextareaWithHtml.vue'
import { FromGoogleEvent } from '@/lib/utils'
import CalendarsModule from '@/store/modules/calendars'
import TeamRecordModule from '@/store/modules/teamRecord'
import { FullCalendarEvent, GoogleEvent } from '@/types'
import { debounce, pick } from 'lodash'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import OthersSection from './OthersSection.vue'

const EventFormAttrs = [
  'id',
  'summary',
  'accountId',
  'calendarId',
  'location',
  'description',
  'visibility',
  'attendees',
  'resources',
  'allDay',
  'onlineMtg',
  'onlineMtgUrl',
  'onlineMeeting',
  'attendeeFlag'
]

@Component({
  components: {
    FormGroup,
    FormItem,
    FormCollapseButton,
    HeaderTitleInput,
    InputField,
    Duration,
    PrimaryCalendarSelector,
    AttendeeSelectorByEmailWithTeamMember,
    OnlineMeeting,
    EventFormDateInputWrapper,
    OthersSection,
    FormTextareaWithHtml
  }
})
export default class EventSidePanelBasicInfoSection extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: GoogleEvent
  @Prop() timeZone: string | undefined
  @Prop({ required: true }) onDateUpdate: (data: { start: Date; end: Date }) => void

  event: Pick<
    GoogleEvent,
    | 'id'
    | 'summary'
    | 'location'
    | 'description'
    | 'visibility'
    | 'accountId'
    | 'calendarId'
    | 'attendees'
    | 'allDay'
    | 'onlineMtg'
    | 'onlineMtgUrl'
    | 'onlineMeeting'
    | 'resources'
    | 'attendeeFlag'
  > = null
  updateEventDebounce = null

  created() {
    this.reset()
  }
  mounted() {
    this.updateEventDebounce = debounce(this.emitNewEvent, 300)
  }

  @Watch('editingObjectSynced', { deep: true })
  resetEditingSchedule(newSchedule, oldSchedule) {
    if (!oldSchedule || !newSchedule) {
      return
    }
    if (oldSchedule.id !== newSchedule.id || oldSchedule.onlineMeeting?.type !== newSchedule.onlineMeeting?.type) {
      this.reset()
    }
  }

  @Watch('event', { deep: true })
  async updatedEvent(newObj, oldObj) {
    if (!oldObj) {
      return
    }
    if (!newObj.id) {
      this.updateEventDebounce()
    }
    if (oldObj.id && oldObj.id === newObj.id) {
      this.updateEventDebounce()
    }
  }

  get editingEventByCalendarFormat(): FullCalendarEvent | null {
    return this.editingObjectSynced ? FromGoogleEvent.convertToFullCalendarEvent(this.editingObjectSynced) : null
  }

  get accountWithcalendars() {
    return CalendarsModule.getAccountWithcalendars
  }

  get allCalendarsButNotResource() {
    return CalendarsModule.allCalendarsButNotResource
  }

  get availableAttendeeList() {
    return this.allCalendarsButNotResource.filter(
      (c) =>
        c.invitationAddress &&
        !(c.accountId === this.editingObjectSynced.accountId && c.calendarId === this.editingObjectSynced.calendarId)
    )
  }

  get availableOnlineMeetings() {
    const currentCalendar = CalendarsModule.getCalendar({
      accountId: this.editingObjectSynced.accountId,
      calendarId: this.editingObjectSynced.calendarId
    })
    return currentCalendar.availableOnlineMeetings || []
  }
  get allTeamMembers() {
    return TeamRecordModule.allTeamMembers
  }
  updateObject() {
    this.$emit('input')
  }

  emitNewEvent() {
    this.$emit('updateEditingObject', this.editingObjectSynced)
  }

  update() {
    this.$emit('update')
  }

  selectedNewPrimaryCalendar({ accountId, calendarId }: { accountId: string; calendarId: string }) {
    this.editingObjectSynced.accountId = accountId
    this.editingObjectSynced.calendarId = calendarId
    this.updateObject()
  }

  reset() {
    this.event = pick(this.editingObjectSynced, EventFormAttrs)
  }
  handleUpdateDescription() {
    this.updateObject()
  }

  handleDateUpdate(data: { start: Date; end: Date }): void {
    this.onDateUpdate(data)
  }
}
