

























import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: { ValidationProvider }
})
export default class WithValidationInput extends Vue {
  @Prop() vid: string
  @Prop() rules: Record<string, any> | string
  @Prop() value: string
  @Prop() helpText: string
  @Prop({ default: false }) hasError: boolean
  @Prop({ default: false }) disabled: boolean

  innerValue = ''

  @Watch('innerValue')
  updatedInnerValue(newValue) {
    if (newValue === this.value) {
      return
    }
    this.$emit('input', newValue)
  }

  @Watch('value')
  updatedValue(newValue) {
    this.innerValue = newValue
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  inputType(dirty, isValid) {
    if (!dirty) {
      return
    }
    return this.hasError || !isValid ? 'is-danger' : ''
  }

  errorMessage(dirty, isValid, errors) {
    if (isValid || !dirty) {
      return this.helpText
    }
    return errors[0]
  }
}
