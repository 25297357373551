














import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirSelectBox, { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'
import { ConfirmationFormProperty } from '@/models/data'
import { useTranslation } from '@/composables/useTranslation'

/**
 * このコンポーネントはConfirmationFormの「主催者へのメッセージ」に特化したものです。
 * 汎用的なConfirmationFormを作成するときは別コンポーネントに作り直します。
 */
export default defineComponent({
  name: 'FormMessageFormSelectBox',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    SpirSelectBox
  },
  props: {
    property: {
      type: Object as PropType<ConfirmationFormProperty>,
      required: true
    },
    isRequired: {
      type: Boolean,
      required: true
    },
    onInputIsActive: {
      type: Function as PropType<(value: boolean) => void>,
      required: true
    },
    onInputIsRequired: {
      type: Function as PropType<(value: boolean) => void>,
      required: true
    }
  },
  setup(props) {
    const { property, isRequired, onInputIsActive, onInputIsRequired } = toRefs(props)
    const i18n = useTranslation()

    const iconUrl = computed(() =>
      property.value.icon ? require(`@/assets/icons/${property.value.icon}.svg`) : undefined
    )
    const tooltip = computed(() =>
      property.value.tooltip
        ? {
            type: 'visible',
            position: 'top',
            messages: [property.value.tooltip],
            width: 250
          }
        : undefined
    )

    const activeValue = computed({
      get: () => (property.value.isActive ? 'active' : 'inactive'),
      set: (newValue) => onInputIsActive.value(newValue === 'active')
    })
    const requiredValue = computed({
      get: () => (isRequired.value ? 'required' : 'optional'),
      set: (newValue) => onInputIsRequired.value(newValue === 'required')
    })

    const activeOptions: SelectItem[] = [
      {
        key: 'active',
        label: i18n.t('availabilitySharing.form.confirmationForm.active.on').toString()
      },
      {
        key: 'inactive',
        label: i18n.t('availabilitySharing.form.confirmationForm.active.off').toString()
      }
    ]
    const requiredOptions: SelectItem[] = [
      {
        key: 'required',
        label: i18n.t('availabilitySharing.form.confirmationForm.required.on').toString()
      },
      {
        key: 'optional',
        label: i18n.t('availabilitySharing.form.confirmationForm.required.off').toString()
      }
    ]

    return {
      iconUrl,
      tooltip,
      activeValue,
      requiredValue,
      activeOptions,
      requiredOptions
    }
  }
})
