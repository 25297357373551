









import FormCollapseButton from '@/components/ui/form/FormCollapseButton.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    FormCollapseButton
  }
})
