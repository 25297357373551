import { render, staticRenderFns } from "./_ActionButtons.vue?vue&type=template&id=bfb3ccb4&scoped=true&"
import script from "./_ActionButtons.vue?vue&type=script&lang=ts&"
export * from "./_ActionButtons.vue?vue&type=script&lang=ts&"
import style0 from "./_ActionButtons.vue?vue&type=style&index=0&id=bfb3ccb4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfb3ccb4",
  null
  
)

export default component.exports