var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('availabilitySharing.form.title.label')},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('FormTextInputItem',{attrs:{"suggestions":_vm.suggestions,"icon":{ type: 'icon', src: 'format-title' },"title":{
      required: true,
      title: _vm.$t('availabilitySharing.form.title.label'),
      tip: {
        type: 'visible',
        position: 'top',
        width: 250,
        messages: [_vm.$t('availabilitySharing.form.title.help')]
      }
    },"disabled":_vm.disabled,"placeholder":_vm.$t('availabilitySharing.form.title.placeholder'),"errorState":_vm.makeErrorMessage(v)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }