

























import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISchedule, ScheduleStatus, ScheduleStatusUpdateActionName } from '@/types'
import RequestAlternativeCandidatesModal from '@/components/modal/confirm/RequestAlternativeCandidates.vue'
import RequestAlternativeComplete from '@/components/modal/RequestAlternativeComplete.vue'
import ScheduleListModule from '@/store/modules/scheduleList'
import UserModule from '@/store/modules/user'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { SignalType } from '@/lib/analytics/LogEntry'
import Button from '@/components/ui/Button.vue'
import { GuestConfirmer, SpirUserConfirmer } from '@/models/data'

@Component({
  components: {
    Button
  }
})
export default class RequestOtherOptionButton extends Vue {
  @Prop() editingSchedule: ISchedule
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) isPreview: boolean
  // NOTE: beufy dropdown position
  @Prop({ default: 'is-top-left' }) position: string
  @Prop({ default: false }) isOrganizer: boolean

  get dropdownItems() {
    return [
      {
        title: this.$t('alternativeCandidates.dropdown.requestTitle'),
        detail: this.$t('alternativeCandidates.dropdown.requestDetail'),
        action: this.showRequestAlternativeCandidatesModal,
        visible: this.isPreview || this.canRequest
      },
      {
        title: this.$t('alternativeCandidates.dropdown.suggestTitle'),
        detail: this.$t('alternativeCandidates.dropdown.suggestDetail'),
        action: this.toSuggestAlternativeDatePage,
        visible: this.isPreview || this.canSuggest
      }
    ].filter((item) => item.visible)
  }
  get showThisButton() {
    return this.dropdownItems.length !== 0
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get requestButtonDisabled() {
    if (this.isPreview) {
      return false
    }

    if (!this.editingSchedule) {
      return true
    }
    return !(this.canRequest || this.canSuggest)
  }
  get title() {
    if (this.canRequest || this.isPreview) {
      return this.$t('buttons.requestOrSuggestAlternativeCandidates')
    }
    return this.$t('buttons.suggestAlternativeCandidates')
  }
  get canRequest(): boolean {
    if (this.isOrganizer) {
      return false
    }
    return this.editingSchedule.actions?.some(
      (action) => action.name.toString() === ScheduleStatusUpdateActionName.confirmerRequest
    )
  }
  get canSuggest(): boolean {
    if (this.isOrganizer) {
      return this.editingSchedule.actions?.some(
        (action) => action.name.toString() === ScheduleStatusUpdateActionName.organizerSuggest
      )
    }
    return this.editingSchedule.actions?.some(
      (action) =>
        action.name.toString() === ScheduleStatusUpdateActionName.organizerSuggest ||
        action.name.toString() === ScheduleStatusUpdateActionName.confirmerSuggest
    )
  }

  showModal(BodyComponent: any, events, props: any) {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: BodyComponent,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props,
      events: {
        ...events
      }
    })
    return modal
  }
  showRequestAlternativeCandidatesModal() {
    if (this.isPreview) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.preview.notAllowedRequestAlternativeCandidates').toString()
      })
      return
    }

    this.showModal(
      RequestAlternativeCandidatesModal,
      {
        register: async ({ email, name }: { email: string; name?: string }) => {
          try {
            const confirmer = UserModule.isSignIn
              ? new SpirUserConfirmer({ email, user: ProfileModule.myProfile })
              : new GuestConfirmer({
                  email,
                  name,
                  timeZone: TimezoneModule.userTimezoneInfo.key,
                  language: ProfileModule.getLanguage
                })
            await ScheduleListModule.requestAlternativeCandidates({
              scheduleId: this.editingSchedule.id,
              confirmer
            })
            await this.$analytics.send(SignalType.REQUEST_ALTERNATIVE_SCHEDULING)
            const nextStatus: ScheduleStatus = 'requestedByConfirmer'
            this.showModal(RequestAlternativeComplete, {}, { nextStatus })
          } catch (e) {
            if (e instanceof Error) {
              this.$buefy.toast.open({
                type: 'is-danger',
                position: 'is-bottom',
                message: `Failed to fetch schedule: ${e.message}`
              })
            }
          }
        }
      },
      {
        isSignIn: this.isSignIn
      }
    )
  }
  toSuggestAlternativeDatePage() {
    if (this.isPreview) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.preview.notAllowedRequestAlternativeCandidates').toString()
      })
      return
    }
    this.$router.push({ name: 'SuggestAlternativeDate', params: { id: this.editingSchedule.id } })
  }
}
