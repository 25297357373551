




















import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    FormItem,
    ValidationProvider
  }
})
export default class FormInput extends Vue {
  @Prop() required: boolean
  @Prop({ default: 'alphabetical' }) icon: string
  @Prop() label: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: null }) rules: string
  @Prop({ default: null }) helpText

  inputType(dirty, isValid) {
    if (!dirty) {
      return
    }
    return !isValid ? 'is-danger' : ''
  }
  errorMessage(dirty, isValid, errors) {
    if (isValid || !dirty) {
      return this.helpText
    }
    return errors[0]
  }
  updatedValue(e) {
    this.$emit('input', e)
  }
}
