import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import './profile'
import './dailyView'
import './settings'
import './listPanel'
import './subscription'
import ProfileModule from './profile'
import ListPanelModule from './listPanel'
import TeamScheduleListModule from './teamScheduleList'
import CalendarsModule from './calendars'
import EventsModule from './events'
import EventModule from './event'
import CalendarControlModule from './calendarControl'
import { removePersistence } from '@/store'
import UserModule from '@/store/modules/user'
import TeamCalendarModule from './teamCalendar'
import TeamRecordModule from './teamRecord'

const MODULE_NAME = 'App'
const BREAK_POINT_FOR_RESPONSIVE = 768
const BREAK_POINT_FOR_LARGE_TABLET = 1024

export enum SidePanelTab {
  CALENDAR_LIST = 'caneldarList',
  SCHEDULE_LIST = 'scheduleList'
}
export interface AppState {
  isLoading: boolean
  isQuickViewVisible: boolean
  windowWidth: number
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class App extends VuexModule {
  private loading = false
  private pageLoading = false
  private quickViewVisible = false
  private windowWidth = window.innerWidth

  // hard private fieldを使ったら TypeError: attempted to get private field on non-instance になった。
  get isQuickViewVisible() {
    return this.quickViewVisible
  }
  get isLoading() {
    return this.loading
  }
  get isPageLoading() {
    return this.pageLoading
  }
  get isNarrow(): boolean {
    if (this.windowWidth === 0) {
      return false
    }
    return this.windowWidth < BREAK_POINT_FOR_RESPONSIVE
  }
  get isLargeTabletWidth() {
    return this.windowWidth < BREAK_POINT_FOR_LARGE_TABLET
  }
  @Action
  async initApp() {
    await Promise.all([ProfileModule.initProfileModule(), CalendarsModule.initCalendars()])
    this.context.dispatch('DailyView/fetchCalendarsForDailyView', null, { root: true })
    this.context.dispatch('Settings/fetchSettings', null, { root: true })
    this.context.dispatch('User/fetchIntegrations', null, { root: true })
    this.context.dispatch('Subscription/refreshSubscription', null, { root: true })
    ListPanelModule.fetchList()
    CalendarControlModule.fetchEvents()
  }
  @Action
  async pollingEventsAndSchedules() {
    await CalendarControlModule.fetchEvents({
      hideLoader: true,
      sequantial: true
    })
    await TeamScheduleListModule.fetchAllList()
    await ListPanelModule.fetchList({ sequantial: true })
  }
  @Action
  resetAllModules() {
    window.sessionStorage.clear()
    return Promise.all([
      UserModule.RESET_STATE(),
      CalendarsModule.RESET_STATE(),
      EventsModule.RESET_STATE(),
      EventModule.RESET_STATE(),
      ProfileModule.RESET_STATE(),
      ListPanelModule.RESET_STATE(),
      TeamCalendarModule.RESET_STATE(),
      TeamRecordModule.RESET_STATE(),
      removePersistence()
    ])
  }
  @Action
  closeQuickView() {
    this.ON_CLOSE_QUICK_VIEW()
  }

  @Action
  setWindowWidth() {
    this.SET_WINDOW_WIDTH(window.innerWidth)
  }

  @Mutation
  SET_LOADING_STATUS(loadingStatus: boolean) {
    this.loading = loadingStatus
  }
  @Mutation
  ON_CLOSE_QUICK_VIEW() {
    this.quickViewVisible = false
  }
  @Mutation
  ON_SHOW_QUICK_VIEW() {
    this.quickViewVisible = true
  }
  @Mutation
  SET_WINDOW_WIDTH(width) {
    this.windowWidth = width
  }
  @Mutation
  SET_PAGE_LOADING(isPageLoading: boolean) {
    this.pageLoading = isPageLoading
  }
}

export default getModule(App)
