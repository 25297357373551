








































import { defineComponent, ref, Ref, computed, onMounted } from '@vue/composition-api'
import { Calendar, MemberCalendarRequest } from '@spirinc/contracts'
import CalendarModule from '@/store/modules/calendars'
import TeamInvitedModule from '@/store/modules/teamInvited'
import TeamInvitedSlideLayout from '@/components/teamSettings/slides/TeamInvitedSlideLayout.vue'
import TeamMainCalendarSelectView from '@/components/teamSettings/calendarSetting/TeamMainCalendarSelectView.vue'
import TeamCalendarShareSettingView from '@/components/teamSettings/calendarSetting/TeamCalendarShareSettingView.vue'
import Divider from '@/components/atoms/Divider.vue'
import CalendarAddButton from '@/components/calendarList/molecules/CalendarAddButton.vue'

export type TeamMemberCalendarSetting = Omit<MemberCalendarRequest, 'primary'>
type AccountAndCalendarId = { accountId: string; calendarId: string }

export default defineComponent({
  components: {
    TeamInvitedSlideLayout,
    TeamMainCalendarSelectView,
    TeamCalendarShareSettingView,
    CalendarAddButton,
    Divider
  },
  setup(_, { emit }) {
    const selectedCalendars: Ref<TeamMemberCalendarSetting[]> = ref([])
    const primaryAccountAndCalendarId: Ref<AccountAndCalendarId> = ref(null)

    const selectCalendarByKey = (accountAndCalendarId: AccountAndCalendarId) => {
      return selectedCalendars.value.find((sc) => {
        return sc.accountId === accountAndCalendarId.accountId && sc.calendarId === accountAndCalendarId.calendarId
      })
    }

    const isLoading = computed(() => TeamInvitedModule.isLoading)
    const primaryCalendar = computed(() => selectCalendarByKey(primaryAccountAndCalendarId.value))
    const calendarGroups = computed(() => CalendarModule.getAccountWithCalendarsButNotResource)
    const writableCalendarsGroups = computed(() => CalendarModule.getAccountWithPrimaryCalendar)

    const handleSelectPrimaryCalendar = (calendar: Calendar) => {
      // primary以外を取得
      const newSelectedCalendars = (selectedCalendars.value = selectedCalendars.value.filter(
        (sc) =>
          !(
            sc.accountId === primaryAccountAndCalendarId.value.accountId &&
            sc.calendarId === primaryAccountAndCalendarId.value.calendarId
          )
      ))
      primaryAccountAndCalendarId.value = { accountId: calendar.accountId, calendarId: calendar.id }
      selectedCalendars.value = [
        ...newSelectedCalendars,
        { accountId: calendar.accountId, calendarId: calendar.id, visibility: 'readAll' }
      ]
    }
    const handleMyCalendarUpdate = (updatedCalendar: {
      accountId: string
      calendarId: string
      visibility: false | 'readAll' | 'freeBusyOnly'
    }) => {
      const findIndex = selectedCalendars.value.findIndex(
        (sc) => sc.accountId === updatedCalendar.accountId && sc.calendarId === updatedCalendar.calendarId
      )
      if (updatedCalendar.visibility === false) {
        if (findIndex >= 0) {
          selectedCalendars.value.splice(findIndex, 1)
        }
        return
      }
      if (findIndex >= 0) {
        selectedCalendars.value[findIndex].visibility = updatedCalendar.visibility
        return
      }
      selectedCalendars.value.push({
        accountId: updatedCalendar.accountId,
        calendarId: updatedCalendar.calendarId,
        visibility: updatedCalendar.visibility
      })
    }
    const handleAcceptInvitation = () => {
      emit(
        'acceptInvitation',
        selectedCalendars.value.map((sc) => {
          return {
            ...sc,
            primary:
              sc.accountId === primaryAccountAndCalendarId.value.accountId &&
              sc.calendarId === primaryAccountAndCalendarId.value.calendarId
          }
        })
      )
    }
    onMounted(() => {
      // defaultに1個目のAccountをセットする
      if (writableCalendarsGroups.value.length) {
        const primaryCalendar = writableCalendarsGroups.value[0].calendars.find((c) => c.primary)
        if (primaryCalendar) {
          handleSelectPrimaryCalendar(primaryCalendar)
        }
      }
    })
    return {
      primaryAccountAndCalendarId,
      handleAcceptInvitation,
      handleSelectPrimaryCalendar,
      handleMyCalendarUpdate,
      primaryCalendar,
      calendarGroups,
      writableCalendarsGroups,
      selectedCalendars,
      isLoading,
      buttonDisabled: !primaryCalendar || isLoading
    }
  }
})
