const APP_UPDATE_AVAILABLE_EVENT = 'SPIR__APP_UPDATE_AVAILABLE'

export function setupAppUpdateAvailableHandler(guardAppUpdate: (updateApp: () => void) => void) {
  let registration: ServiceWorkerRegistration = null

  function updateApp() {
    registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
  }

  function handleAppUpdateAvailable(event: Event) {
    if (event instanceof CustomEvent && event.detail instanceof ServiceWorkerRegistration) {
      registration = event.detail
      guardAppUpdate(updateApp)
    }
  }

  function handleControllerChange() {
    window.location.reload()
  }

  if ('serviceWorker' in navigator) {
    document.addEventListener(APP_UPDATE_AVAILABLE_EVENT, handleAppUpdateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', handleControllerChange)
  }
}

export function noticeAppUpdateAvailable(registration: ServiceWorkerRegistration) {
  document.dispatchEvent(new CustomEvent(APP_UPDATE_AVAILABLE_EVENT, { detail: registration }))
}
