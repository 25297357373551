import { DayOfTheWeekRules } from '@spirinc/contracts'
import { ResourceEvent, workdayKeys } from '@/types'
import { addDays, parseHourAndMinToDate } from '@/lib/utils/timezone'
import { nanoid } from 'nanoid'

const MIDNIGHT_FORMAT = '24:00'

export const parseFromTime = (s: string, baseDate?: Date): Date => {
  const defaultDate = baseDate || new Date()
  if (s === MIDNIGHT_FORMAT) {
    return addDays(defaultDate, 1)
  }
  return parseHourAndMinToDate(s, defaultDate)
}
export const PickDayOfWeekDefaultEvent = (start: Date, end: Date, columnKey: string): ResourceEvent => {
  return {
    columnKey: columnKey,
    id: nanoid(),
    title: '',
    start,
    end,
    extendedProps: {
      source: 'candidate'
    },
    backgroundColor: 'white',
    border: '2px dashed #0085C7',
    textColor: '#0085C7',
    editable: true
  }
}

export const startSelects = [
  'now',
  'one_hour_later',
  'two_hours_later',
  'three_hours_later',
  'four_hours_later',
  'five_hours_later',
  'tomorrow',
  'the_day_after_tomorrow',
  'three_days_later',
  'four_days_later',
  'five_days_later',
  'next_week',
  'one_week_later',
  'the_week_after_next',
  'two_weeks_later',
  'three_weeks_later',
  'four_weeks_later',
  'next_month'
] as const
export type StartSelects = typeof startSelects[number]

export const endSelects = [
  'one_day',
  'two_days',
  'three_days',
  'four_days',
  'five_days',
  'one_week',
  'two_weeks',
  'three_weeks',
  'four_weeks',
  'two_months',
  'three_months'
] as const
export type EndSelects = typeof endSelects[number]

export const defaultWeekRule = (): DayOfTheWeekRules => {
  return workdayKeys.reduce((a, c) => {
    if (c === 'saturday' || c === 'sunday') {
      return a
    }
    a[c] = {
      rules: [
        {
          start: '09:00',
          end: '17:00',
          type: 'include'
        }
      ]
    }
    return a
  }, {})
}
