import { parseISO } from 'date-fns'
import { isSameDayByUserTimeZone } from '@/lib/utils/timezone'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export default function (payload?: { start?: string; end?: string }) {
  if (!payload || !payload.start || !payload.end) {
    return ''
  }
  const firstDate = parseISO(payload.start)
  const lastDate = parseISO(payload.end)
  if (isSameDayByUserTimeZone(firstDate, lastDate)) {
    return `${spirDateFormat(firstDate, spirDateFormatTypes.mdWeekday)}`
  }
  return `${spirDateFormat(firstDate, spirDateFormatTypes.mdWeekday)} - ${spirDateFormat(
    lastDate,
    spirDateFormatTypes.mdWeekday
  )}`
}
