// import * as firebaseTypes from 'firebase'
import router from '@/router'
import { AbTestingTogglableFeatures } from '@/types/feature'
import '@firebase/analytics'
import * as Sentry from '@sentry/vue'
import firebase from 'firebase/app'
import { isEnabled } from 'vue-feature-flipping'
import { PageAccessSignalType, SignalType } from './LogEntry'

export interface SignalRequestSender {
  send(
    signalType: SignalType | PageAccessSignalType,
    customPayload?: { [key: string]: string | number | boolean }
  ): Promise<void>
  setUserId(userId?: string): void
}

class FirebaseSignalRequestSender implements SignalRequestSender {
  private readonly analytics: firebase.analytics.Analytics
  constructor(analytics: firebase.analytics.Analytics) {
    this.analytics = analytics
  }
  send(
    signalType: SignalType | PageAccessSignalType,
    customPayload?: { [key: string]: string | number }
  ): Promise<void> {
    const logEvent: (string, customPayload?: { [key: string]: string | number }) => void = this.analytics
      .logEvent as any
    logEvent(signalType, customPayload)

    return Promise.resolve()
  }
  setUserId(userId?: string) {
    this.analytics.setUserId(userId)
  }
}

class KarteEventSender implements SignalRequestSender {
  send(
    signalType: SignalType | PageAccessSignalType,
    customPayload?: { [key: string]: string | number }
  ): Promise<void> {
    try {
      window.tracker.track(signalType, customPayload)
    } catch (e) {
      if ('tracker' in window && e instanceof Error) {
        window.tracker.track('_error', { message: e.message })
      }
    }
    return Promise.resolve()
  }
  setUserId(userId?: string): void {
    try {
      window.tracker.user({
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: userId
      })
    } catch (e) {
      Sentry.captureException(e)
      if ('tracker' in window && e instanceof Error) {
        window.tracker.track('_error', { message: e.message })
      }
    }
  }
}

class ConsoleLogger implements SignalRequestSender {
  send(
    signalType: SignalType | PageAccessSignalType,
    customPayload?: { [key: string]: string | number }
  ): Promise<void> {
    console.log(signalType, customPayload) // eslint-disable-line no-console
    return Promise.resolve()
  }
  setUserId(userId?: string): void {
    console.log('set user id', userId) // eslint-disable-line no-console
  }
}

export class AnalyticsRequestSender implements SignalRequestSender {
  private senders: SignalRequestSender[]
  constructor() {
    this.senders = []
    if (firebase.analytics.isSupported()) {
      this.senders.push(new FirebaseSignalRequestSender(firebase.analytics()))
    }
    this.senders.push(new KarteEventSender())
    if (process.env.VUE_APP_MODE === 'local') {
      this.senders.push(new ConsoleLogger())
    }
  }
  getABTestingSettings(): { [key: string]: boolean } {
    return Object.fromEntries(
      Object.entries(AbTestingTogglableFeatures).map(([key, value]) => [key, isEnabled(value.featureName)])
    )
  }
  send(
    signalType: SignalType | PageAccessSignalType,
    customPayload?: { [key: string]: string | number }
  ): Promise<void> {
    // logEvent accepts any event string and treat it as a custom event,
    // but type definition doesn't refer it.
    const defaultPayload = { ...this.getABTestingSettings(), screenName: router.currentRoute.name }
    const customPayloadWithCurrentScreenName = customPayload
      ? {
          ...customPayload,
          ...defaultPayload
        }
      : defaultPayload
    try {
      Promise.all(this.senders.map((sender) => sender.send(signalType, customPayloadWithCurrentScreenName)))
    } catch (e) {
      Sentry.captureException(e)
    }
    return Promise.resolve()
  }
  setUserId(userId?: string) {
    try {
      Promise.all(this.senders.map((sender) => sender.setUserId(userId)))
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}
