import { updateBasicProfile as apiUpdateBasicProfile } from '../api/profile'
import { IProfileBasic } from '../../types'
import { APIError } from './error'

export const updateProfile = async (payload: IProfileBasic) => {
  try {
    return await apiUpdateBasicProfile(payload)
  } catch (e: any) {
    if (e.response) {
      throw new APIError(e.response.status, e.response.data, e.response)
    }
    throw e
  }
}
