




import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ConfirmTitle extends Vue {
  @Prop({ required: true }) title: string
}
