














import { defineComponent, PropType } from '@vue/composition-api'

export type CheckState = 'no-checked' | 'checked' | 'empty'
export type TimesStringState = { type: 'no-text' } | { type: 'text'; text: string } | { type: 'empty' }

export default defineComponent({
  name: 'WeekdayConditionItem',
  props: {
    label: {
      type: String,
      required: true
    },
    checkState: {
      type: String as PropType<CheckState>,
      default: 'empty'
    },
    timesStringState: {
      type: Object as PropType<TimesStringState>,
      default: { type: 'empty' }
    }
  }
})
