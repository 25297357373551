






import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from './molecules/Item.vue'

@Component({
  components: {
    DisplayItem
  }
})
export default class DisplayMessageToOrganizer extends Vue {
  @Prop() item: string
}
