






import { Component, Vue } from 'vue-property-decorator'
import SidePanelLayout from '@/components/sidePanels/edit/Layout.vue'
import Skeleton from '@/components/sidePanels/molecules/Skeleton.vue'

@Component({
  components: {
    SidePanelLayout,
    Skeleton
  }
})
export default class SidePanelEditSkeleton extends Vue {}
