import RemoteConfigKeys from './remoteConfig'

export class TogglableFeature {
  readonly featureName: string
  readonly remoteConfigKeyName: RemoteConfigKeys

  constructor(featureName: string, remoteConfigKeyName: RemoteConfigKeys) {
    this.featureName = featureName
    this.remoteConfigKeyName = remoteConfigKeyName
  }
}

const TogglableFeatures = {
  notification: new TogglableFeature('notification', RemoteConfigKeys.notificationEnabled),
  subscription: new TogglableFeature('subscription', RemoteConfigKeys.subscriptionEnabled),
  accountLink: new TogglableFeature('accountLink', RemoteConfigKeys.accountLinkEnabled),
  emailChange: new TogglableFeature('emailChange', RemoteConfigKeys.emailChangeEnabled),
  team: new TogglableFeature('team', RemoteConfigKeys.teamEnabled),
  teamPhotoUpdate: new TogglableFeature('teamPhotoUpdate', RemoteConfigKeys.teamPhotoUpdateEnabled)
} as const

type TogglableFeatures = typeof TogglableFeatures[keyof typeof TogglableFeatures]

const FEATURE_TOGGLE_PREFIX_FOR_AB_TESTING = 'abTesting'
const AbTestingTogglableFeatures = {
  abTestingShowNewConfirmPage: new TogglableFeature(
    `${FEATURE_TOGGLE_PREFIX_FOR_AB_TESTING}ShowNewConfirmPage`,
    RemoteConfigKeys.abTestingShowNewConfirmPageEnabled
  )
} as const

type AbTestingTogglableFeatures = typeof AbTestingTogglableFeatures[keyof typeof AbTestingTogglableFeatures]

export { TogglableFeatures, AbTestingTogglableFeatures }
