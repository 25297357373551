




























import SpirTwoChoicesRadioButton from '@/components/ui/spir/SpirTwoChoicesRadioButton.vue'
import { ListType } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'
import ArrangementTypeRadioContent from './_ArrangementTypeRadioContent.vue'

export default defineComponent({
  components: {
    SpirTwoChoicesRadioButton,
    ArrangementTypeRadioContent
  },
  props: {
    val: {
      type: String as PropType<ListType>
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function as PropType<(val: ListType) => void>,
      required: true
    }
  }
})
