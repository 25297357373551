























import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import CopyBoxBodyFrame from '@/components/copyToShare/copyBoxParts/CopyBoxBodyFrame.vue'
import CopyBoxBodyHeader from '@/components/copyToShare/copyBoxParts/CopyBoxBodyHeader.vue'
import CopyBoxHeader from '@/components/copyToShare/copyBoxParts/CopyBoxHeader.vue'
import CopyBoxLanguageAndTimeZone from '@/components/copyToShare/copyBoxParts/CopyBoxLanguageAndTimeZone.vue'
import CopyBoxLinkText from '@/components/copyToShare/copyBoxParts/CopyBoxLinkText.vue'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { copyOrShareHTMLAndText } from '@/lib/utils/functions'
import { FrontSupportLanguage } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxForHtml',
  components: {
    LoadingSpinner,
    CopyBoxBodyFrame,
    CopyBoxHeader,
    CopyBoxBodyHeader,
    CopyBoxLinkText,
    CopyBoxLanguageAndTimeZone
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      required: true
    },
    boxHeaderTitle: {
      type: String,
      required: true
    },
    htmlText: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    },
    language: {
      type: String as PropType<FrontSupportLanguage>,
      required: true
    },
    onLanguageUpdate: {
      type: Function as PropType<(lang: FrontSupportLanguage) => Promise<void>>,
      required: true
    },
    onAnalyticsSend: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    },
    onTimeZoneChange: {
      type: Function as PropType<(code: string) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { openSuccessTopToast } = useToast()
    const htmlMailText = computed((): string => {
      return props.isLoading ? '' : props.htmlText
    })

    async function copyHTMLText() {
      await props.onAnalyticsSend()
      if (copyOrShareHTMLAndText(props.htmlText, props.text) === 'clipboard') {
        openSuccessTopToast({
          message: i18n.t('message.copiedToClipBoard').toString()
        })
      }
    }

    function handleTimeZoneChange(code: string) {
      props.onTimeZoneChange(code)
    }
    return {
      htmlMailText,
      copyHTMLText,
      handleTimeZoneChange
    }
  }
})
