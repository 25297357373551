import axiosInstance from '../axios'

export const createSubscription = async (paymentMethodId: string, priceId: string) => {
  const payload = { paymentMethodId: paymentMethodId, priceId: priceId }
  return (await axiosInstance.post('subscription', payload)).data
}

export const fetchSubscription = async () => {
  return (await axiosInstance.get('subscription')).data
}

export const refreshSubscription = async () => {
  return (await axiosInstance.get('subscription/refresh')).data
}
