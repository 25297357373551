








































import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import padStart from 'lodash/padStart'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import AutoCandidatesTimeRangeForm from '@/components/calendar/AutoCandidatesTimeRangeForm.vue'

@Component({
  components: {
    CommonLayoutModal,
    AutoCandidatesTimeRangeForm
  }
})
export default class AutoCandidatesTimeRangeSettingsModal extends Vue {
  @Prop()
  orgTimeRanges

  timeRanges = []
  addFormOpened = false
  editFormOpened = false

  mounted() {
    this.timeRanges = this.orgTimeRanges
  }

  timeRangeFormat(timeRange) {
    const startTimeHour = padStart(timeRange.startTime.hour, 2, '0')
    const startTimeMin = padStart(timeRange.startTime.min, 2, '0')
    const endTimeHour = padStart(timeRange.endTime.hour, 2, '0')
    const endTimeMin = padStart(timeRange.endTime.min, 2, '0')
    return `${startTimeHour}:${startTimeMin} - ${endTimeHour}:${endTimeMin}`
  }

  select(i, timeRange) {
    const timeRanges = this.timeRanges.map((tr) => ({ ...tr, selected: false }))
    timeRanges[i] = { ...timeRange, selected: true }

    this.$emit('updateValue', timeRanges)
    this.$emit('close')
  }

  remove(i, timeRange) {
    const timeRanges = this.timeRanges
    timeRanges.splice(i, 1)
    if (timeRange.selected && timeRanges[0]) {
      timeRanges[0].selected = true
    }
    this.$emit('updateValue', timeRanges)
    this.timeRanges = timeRanges
  }

  add(timeRange) {
    const timeRanges = this.timeRanges.map((tr) => ({ ...tr, selected: false }))
    timeRanges.push({ ...timeRange, selected: true })

    this.$emit('updateValue', timeRanges)
    this.timeRanges = timeRanges
    this.addFormOpened = false
  }

  update(i, timeRange) {
    const timeRanges = this.timeRanges
    timeRanges[i] = { ...timeRange }

    this.$emit('updateValue', timeRanges)
    this.editFormOpened = false
  }
}
