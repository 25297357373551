






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CalendarColorSwatch extends Vue {
  @Prop() color: string
  @Prop({ default: false }) isCurrent: boolean
  @Prop() onSelect: (color: string) => void

  handleClick(e) {
    e.stopPropagation()
    if (!this.isCurrent) {
      this.onSelect(this.color)
    }
  }
}
