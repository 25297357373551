












import InlineSvg from 'vue-inline-svg'
import CreatemenuTipMark from '@/components/ui/CreateMenuTipMark.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    InlineSvg,
    CreatemenuTipMark
  },
  props: {
    svg: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    tip: {
      type: Object as PropType<{ line1: string; line2: string }>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
