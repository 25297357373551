







import { Component, PropSync, Vue } from 'vue-property-decorator'
import { GoogleEvent } from '@/types'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import InputField from '@/components/newForm/molecules/InputField.vue'
import LocationInput from '@/components/newForm/molecules/LocationInput.vue'
import Visibility from '@/components/newForm/molecules/Visibility.vue'

@Component({
  components: {
    FromGroup,
    InputField,
    LocationInput,
    Visibility
  }
})
export default class EventSidePanelOthersSection extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: GoogleEvent

  updateObject() {
    this.$emit('input')
  }
}
