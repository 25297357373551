
















import TitleDropdown from '@/components/ui/domain/dropdown/TitleDropdown/index.vue'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'
import { useVeeValidationProviderSlot } from '@/composables/useVeeValidationProviderSlot'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'FormTitleInputItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    ValidationProvider,
    TitleDropdown
  },
  props: {
    fieldName: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    suggestions: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { value, onInput, suggestions } = toRefs(props)
    const model = computed({
      get: () => value.value,
      set: (newDesc: string) => {
        onInput.value(newDesc)
      }
    })
    const titleSuggestions = computed(() => suggestions.value.slice().reverse())
    const { makeErrorMessage } = useVeeValidationProviderSlot()
    return {
      model,
      titleSuggestions,
      makeErrorMessage
    }
  }
})
