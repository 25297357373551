







import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModelTeam } from '@/models/data/schedule'
import CreatorInfoCardBodyItem from './atoms/OrganizerInfoCardBodyItem.vue'
import CardBodyFrame from './atoms/CardBodyFrame.vue'
import ConfirmedScheduleTimeInfoCardBodyItem from './atoms/ConfirmedScheduleTimeInfoCardBodyItem.vue'

@Component({
  components: {
    CreatorInfoCardBodyItem,
    CardBodyFrame,
    ConfirmedScheduleTimeInfoCardBodyItem
  }
})
export default class ConfirmedScheduleCardBodyForTeam extends Vue {
  @Prop() item: ScheduleModelTeam | null

  get organizer() {
    return this.item?.organizer
  }
  get timeRange() {
    return { start: this.item.confirmedInfo?.start, end: this.item.confirmedInfo?.end }
  }
}
