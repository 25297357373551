





































import { Price, Product, SubscriptionProduct } from '@/types'
import { Component, Vue } from 'vue-property-decorator'
import SubscriptionModule from '@/store/modules/subscription'
import { isEnabled } from 'vue-feature-flipping'
import { TogglableFeatures } from '@/types/feature'
import { Stripe, loadStripe, StripeCardElement } from '@stripe/stripe-js'

@Component
export default class Subscription extends Vue {
  private selectedProduct: Product | null = null
  private selectedPrice: Price | null = null
  private stripe: Stripe | null = null
  private element: StripeCardElement | null = null

  async created() {
    if (!isEnabled(TogglableFeatures.subscription.featureName)) {
      this.$router.push({ name: 'Main' })
    }
    await SubscriptionModule.fetchProducts()
  }

  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_PUBLIC_KEY)
    const elements = this.stripe.elements()
    this.element = elements.create('card')
    this.element.mount('#subscription__credit-card-form')

    this.element.on('change', (event) => {
      this.showError(event.error.message)
    })
  }

  async submit() {
    if (this.selectedProduct == null || this.selectedPrice == null) {
      this.showError(this.$t('subscription.error.productOrPriceNotSelected').toString())
      return
    }
    try {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.element
      })
      if (error) {
        this.showError(error.message)
        return
      }
      await SubscriptionModule.createSubscription({ paymentMethodId: paymentMethod.id, priceId: this.selectedPrice.id })
      this.$buefy.toast.open({
        type: 'is-primary',
        position: 'is-bottom',
        message: this.$t('subscription.subscriptionSuccess', {
          planName: `${this.selectedProduct.name} ${this.selectedPrice.name}`
        }).toString()
      })
    } catch (error) {
      this.showError(this.$t('message.errorCommon').toString())
    }
  }

  private get subscriptionProducts(): SubscriptionProduct[] {
    return SubscriptionModule.products
  }

  private selectPrice(product: Product, price: Price) {
    this.selectedProduct = product
    this.selectedPrice = price
  }

  private formatPriceAmount(price: Price): string {
    const formatter = Intl.NumberFormat('ja-JP')
    const formattedPrice = `${formatter.format(price.unitAmount)}${this.$t('subscription.price.yen')}`

    const formattedUnit = ((): string => {
      switch (price.recurring.interval) {
        case 'year':
          return this.$t('subscription.price.perYear').toString()
        case 'month':
          if (price.recurring.intervalCount === 1) {
            return this.$t('subscription.price.perMonth', { month: price.recurring.intervalCount }).toString()
          } else {
            return this.$t('subscription.price.perMonths', { month: price.recurring.intervalCount }).toString()
          }
        case 'week':
          if (price.recurring.intervalCount === 1) {
            return this.$t('subscription.price.perWeek', { week: price.recurring.intervalCount }).toString()
          } else {
            return this.$t('subscription.price.perWeeks', { week: price.recurring.intervalCount }).toString()
          }
        case 'day':
          if (price.recurring.intervalCount === 1) {
            return this.$t('subscription.price.perDay', { day: price.recurring.intervalCount }).toString()
          } else {
            return this.$t('subscription.price.perDays', { day: price.recurring.intervalCount }).toString()
          }
      }
    })()
    return `${formattedPrice}${formattedUnit}`
  }

  private showError(message: string) {
    this.$buefy.toast.open({
      type: 'is-danger',
      position: 'is-bottom',
      message: message
    })
  }
}
