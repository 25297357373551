import i18n from '@/i18n'
import { APIError } from '@/lib/sdk/error'
import { ERROR_CODE } from '@spirinc/message-catalog'

/**
 * APIからのエラーコードから、i18nのエラーメッセージを探しリターンする。i18nで考慮しないエラーの場合はデフォルトエラーメッセージを表示する
 */
export default (
  error: Error | unknown,
  option?: { handleKeys: { errorCode: ERROR_CODE; i18nKey: string; parameter?: { [key: string]: any } }[] }
): string => {
  if (error instanceof APIError) {
    if (option?.handleKeys.length > 0) {
      const findFromHandleKeys = option?.handleKeys.find((hk) => hk.errorCode === error.code)
      if (findFromHandleKeys && i18n.te(findFromHandleKeys.i18nKey)) {
        return i18n.t(findFromHandleKeys.i18nKey, findFromHandleKeys.parameter).toString()
      }
    }
    if (error.status >= 500) {
      return i18n.t('message.error.serverError').toString()
    }
  }
  return i18n.t('message.errorCommon').toString()
}
