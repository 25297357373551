















import { Component, Prop, Vue } from 'vue-property-decorator'
import Layout from '../atoms/AsideMenuItemLayout.vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { NotificationMessage } from '@/models/data/notifications'
import UserModule from '@/store/modules/user'

@Component({
  components: {
    Layout
  }
})
export default class NotificationButton extends Vue {
  @Prop() expanded: boolean

  db: firebase.firestore.Firestore = firebase.firestore()
  showNotification = false
  notifications: NotificationMessage[] = []
  numberOfNewNotifications = 0
  lastConfirmedTimestampMs: number = null
  unsubscribe = null

  async mounted() {
    await this.updateNotifications()
  }
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
  async updateNotifications() {
    const user = UserModule.currentUser
    if (user) {
      this.lastConfirmedTimestampMs = await this.getLastConfirmedTimestampMs()
      await this.watchNewNotificationMessages(this.lastConfirmedTimestampMs)
    }
  }
  async toggleNotification() {
    this.showNotification = !this.showNotification
    if (this.showNotification) {
      await this.updateLastConfirmedTimestampMs()
    }
  }

  async getLastConfirmedTimestampMs(): Promise<number | null> {
    const user = UserModule.currentUser
    const notificationMessagesDoc = await this.db
      .collection('users')
      .doc(user.uid)
      .collection('notifications')
      .orderBy('lastConfirmedTimestampMs', 'desc')
      .where('lastConfirmedTimestampMs', '!=', null)
      .limit(1)
      .get()
    if (notificationMessagesDoc.empty) {
      return null
    }
    const notificationMessages = []
    notificationMessagesDoc.forEach((doc) => {
      notificationMessages.push(NotificationMessage.createFromDoc(doc.data()))
    })
    if (notificationMessages.length === 0) {
      return null
    }
    const latestNotificationMessage = notificationMessages[0]
    return latestNotificationMessage.lastConfirmedTimestampMs
  }

  async watchNewNotificationMessages(lastConfirmedTimestampMs: number | null) {
    const user = UserModule.currentUser
    let query = this.db.collection('users').doc(user.uid).collection('notifications').orderBy('timestampMs', 'desc')
    if (lastConfirmedTimestampMs !== null) {
      query = query.where('timestampMs', '>', lastConfirmedTimestampMs)
    }
    this.unsubscribe = query.onSnapshot((querySnapshot) => {
      this.numberOfNewNotifications = querySnapshot.size
    })
  }

  async updateLastConfirmedTimestampMs() {
    const user = UserModule.currentUser
    const latestNotificationMessageDoc = await this.db
      .collection('users')
      .doc(user.uid)
      .collection('notifications')
      .orderBy('timestampMs', 'desc')
      .limit(1)
      .get()
    if (latestNotificationMessageDoc.empty) {
      return
    }
    const notificationMessages = []
    latestNotificationMessageDoc.forEach((doc) => {
      notificationMessages.push(NotificationMessage.createFromDoc(doc.data()))
    })
    const latestNotificationMessage = notificationMessages[0]
    await this.db
      .collection('users')
      .doc(user.uid)
      .collection('notifications')
      .doc(latestNotificationMessage.id)
      .update({
        lastConfirmedTimestampMs: Date.now()
      })
  }
  hasNewNotifications(): boolean {
    return this.numberOfNewNotifications > 0
  }
}
