




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { AvailabilityModelForTeam } from '@/models/data'
import WeekdayInfoCardBodyItem from '@/components/card/atoms/WeekdayInfoCardBodyItem.vue'
import OrganizerInfoCardBodyItem from '@/components/card/atoms/OrganizerInfoCardBodyItem.vue'
import UndecidedOrganizerInfoCardBodyItem from '@/components/card/atoms/UndecidedOrganizerInfoCardBodyItem.vue'
import AttendeesCardBodyItem from '@/components/card/molecules/AttendeesCardBodyItem.vue'
import LastUpdateInfoCardBodyItem from '@/components/card/atoms/LastUpdateInfoCardBodyItem.vue'

@Component({
  components: {
    WeekdayInfoCardBodyItem,
    OrganizerInfoCardBodyItem,
    UndecidedOrganizerInfoCardBodyItem,
    AttendeesCardBodyItem,
    LastUpdateInfoCardBodyItem
  }
})
export default class PatternCardBodyForTeam extends Vue {
  @Prop() pattern: AvailabilityModelForTeam | null

  get organizer() {
    return this.pattern.organizerMember
  }

  get allAttendingMembers() {
    return this.pattern.allAttendingMembers
  }

  get hasUpdateInfo() {
    return !!this.pattern.updatedAt && !!this.pattern.lastUpdateUser
  }

  get lastUpdateAt() {
    return this.pattern.updatedAt.toISOString()
  }

  get lastUpdateUser() {
    return this.pattern.lastUpdateUser
  }
}
