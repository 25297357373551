














import { Prop, Component, Vue } from 'vue-property-decorator'
import { IProfileBasic } from '@/types'
import ProfileHeader from '@/components/profile/ProfileHeader.vue'
import ProfileBasicViewer from '@/components/profile/ProfileBasicViewer.vue'

@Component({
  components: {
    ProfileHeader,
    ProfileBasicViewer
  }
})
export default class ProfileContent extends Vue {
  @Prop() profile: IProfileBasic
}
