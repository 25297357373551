import AppScreenControlModule from '@/store/modules/appScreenControl'
export const useAppScreenInfo = () => {
  const toggleScheduleEventsList = (isOpen: boolean) => {
    AppScreenControlModule.toggleScheduleEventsList(isOpen)
  }
  return {
    toggleScheduleEventsList,
    openCalendar: () => toggleScheduleEventsList(false),
    isScheduleEventListOpen: AppScreenControlModule.isScheduleEventListOpen
  }
}
