import { fetchProducts } from '@/lib/api/subscriptionProducts'
import { createSubscription, fetchSubscription, refreshSubscription } from '@/lib/api/subscription'
import { SubscriptionProduct, Subscription as SubscriptionModel } from '@/types/subscription'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'

@Module({
  dynamic: true,
  name: 'Subscription',
  namespaced: true,
  store
})
class Subscription extends VuexModule {
  private _products: SubscriptionProduct[] = []
  private _subscription: SubscriptionModel | null = null

  get products(): SubscriptionProduct[] {
    return this._products
  }

  get subscription(): SubscriptionModel | null {
    return this._subscription
  }

  @Action
  async fetchProducts(): Promise<void> {
    this.SET_PRODUCTS(await fetchProducts())
  }

  @Action
  async createSubscription({ paymentMethodId, priceId }): Promise<void> {
    this.SET_SUBSCRIPTION(await createSubscription(paymentMethodId, priceId))
  }

  @Action
  async fetchSubscription(): Promise<void> {
    const subscription = await fetchSubscription()
    if (Object.keys(subscription).length === 0) {
      this.SET_SUBSCRIPTION(null)
    } else {
      this.SET_SUBSCRIPTION(subscription)
    }
  }

  @Action
  async refreshSubscription(): Promise<void> {
    const subscription = await refreshSubscription()
    if (Object.keys(subscription).length === 0) {
      this.SET_SUBSCRIPTION(null)
    } else {
      this.SET_SUBSCRIPTION(subscription)
    }
  }

  @Mutation
  SET_PRODUCTS(products: SubscriptionProduct[]) {
    this._products = products
  }

  @Mutation
  SET_SUBSCRIPTION(subscription: SubscriptionModel) {
    this._subscription = subscription
  }
}

export default getModule(Subscription)
