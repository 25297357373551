












import CalendarDropdown from '@/components/ui/domain/dropdown/CalendarDropdown/index.vue'
import { CalendarCollection } from '@/components/ui/domain/dropdown/CalendarDropdown/types'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FormOrganizerDropdownItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    CalendarDropdown
  },
  props: {
    calendarCollections: {
      type: Array as PropType<CalendarCollection[]>,
      required: true
    },
    value: {
      type: Object as PropType<{ accountId: string; calendarId: string }>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function as PropType<(value: { accountId: string; calendarId: string }) => void>,
      required: true
    }
  }
})
