









import { Component, Prop, Vue } from 'vue-property-decorator'
import { MemberResponse as MemberInfo } from '@spirinc/contracts'
import CalendarMemberAvatar from '@/components/patterns/form/molecules/CalendarMemberAvatar.vue'

@Component({
  components: {
    CalendarMemberAvatar
  }
})
export default class FormMemberList extends Vue {
  @Prop() selectedMembers: Array<string>
  @Prop() allMembers: MemberInfo[]

  get membersInfo() {
    return this.allMembers.filter((m) => this.selectedMembers.some((id) => id === m.id))
  }
  deleteMember(memberId) {
    this.$emit('deleteMember', memberId)
  }
}
