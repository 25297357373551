import { render, staticRenderFns } from "./CollapseText.vue?vue&type=template&id=69e6afde&scoped=true&"
import script from "./CollapseText.vue?vue&type=script&lang=ts&"
export * from "./CollapseText.vue?vue&type=script&lang=ts&"
import style0 from "./CollapseText.vue?vue&type=style&index=0&id=69e6afde&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e6afde",
  null
  
)

export default component.exports