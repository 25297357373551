import { render, staticRenderFns } from "./AddCalendarDescriptionItem.vue?vue&type=template&id=6f34e371&scoped=true&"
import script from "./AddCalendarDescriptionItem.vue?vue&type=script&lang=ts&"
export * from "./AddCalendarDescriptionItem.vue?vue&type=script&lang=ts&"
import style0 from "./AddCalendarDescriptionItem.vue?vue&type=style&index=0&id=6f34e371&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f34e371",
  null
  
)

export default component.exports