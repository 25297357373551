
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HelpModal extends Vue {
  @Prop() rightButtonTitle?: string
  @Prop({ default: false }) showFooter?: boolean
  @Prop({ default: true }) showCloseButton?: boolean
  @Prop() leftButtonTitle?: string
  @Prop({ default: false }) rightButtonDisabled?: boolean
  @Prop({ default: false }) enableDelete?: boolean
  @Prop({ default: true }) showHeader: boolean
}
