








import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { UserInfoModel } from '@/models/data/userInfo'
import { JoiningTeam } from '@spirinc/contracts'
import queryParams from '@/lib/queryParams'
import TeamSelectorTab from './Index.vue'

@Component({
  components: {
    TeamSelectorTab
  }
})
export default class TeamSelectorTabWithQueryParamsSync extends Vue {
  @Prop() myProfile: UserInfoModel
  @Prop() teams: JoiningTeam[]
  currentTab = ''
  created() {
    this.currentTab = (this.$route.query[queryParams.TEAM_ID] as string) || 'private'
  }

  @Watch('$route.query')
  updatedQuery(newValue, oldValue) {
    if (newValue[queryParams.TEAM_ID] !== oldValue[queryParams.TEAM_ID]) {
      this.currentTab = (this.$route.query[queryParams.TEAM_ID] as string) || 'private'
    }
  }
  updateTab() {
    if (this.currentTab === 'private') {
      this.$router.push({ query: {} })
    } else {
      this.$router.push({ query: { [queryParams.TEAM_ID]: this.currentTab } })
    }
    this.$emit('updatedTab', this.currentTab)
  }
}
