


































import Button from '@/components/ui/Button.vue'
import { FilteringCalendarInfo } from '@/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import vClickOutside from 'v-click-outside'

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Button
  },
  props: {
    calendars: {
      type: Array as PropType<Array<FilteringCalendarInfo>>,
      default: () => []
    }
  },
  setup() {
    const isListVisible = ref(false)
    return {
      isListVisible,
      handleHeaderClick: () => {
        isListVisible.value = !isListVisible.value
      },
      closeVisibleList: (event) => {
        if (isListVisible.value) {
          isListVisible.value = false
          event.stopPropagation()
        }
      }
    }
  }
})
