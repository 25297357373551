


















import { Component, Prop, Vue } from 'vue-property-decorator'
// fixme: 一旦Interfaceは公開URLのAttendeeにする。他のところで使われるようになって変える必要があったらその時また修正する
import { AvailabilityCalendarType } from '@spirinc/contracts'
import { ICalendar } from '@/types'

type SelectedCalendar = Partial<AvailabilityCalendarType & { email: string }>

@Component
export default class FormSelectedCalendarList extends Vue {
  @Prop() selectedList: SelectedCalendar[]
  @Prop() userCalendars: ICalendar[]
  @Prop() icon: string

  calendarName(selectedItem: SelectedCalendar) {
    const item = this.userCalendars.find((c) => {
      return (
        selectedItem.email === c.invitationAddress ||
        (c.accountId === selectedItem.accountId && c.id === selectedItem.calendarId)
      )
    })
    return item?.title || item?.invitationAddress || selectedItem.email
  }
  deleteItem(selectedItem: SelectedCalendar) {
    this.$emit('deleteCalendar', selectedItem)
  }
}
