

































import { cloneDeep } from 'lodash'
import { computed, defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import AttendingTypeRadioButton from '@/components/ui/domain/radioButton/AttendingTypeRadioButton.vue'
import FormOrganizerDropdownItem from '@/components/ui/domain/item/FormOrganizerDropdownItem.vue'
import FormAttendingEmails from '@/components/ui/domain/item/FormAttendingEmails.vue'
import FormCandidateTargetCalendarsSettingsCollapseButtonItem from '@/components/ui/domain/item/FormCandidateTargetCalendarsSettingsCollapseButtonItem.vue'
import FormTargetCalendarsSelectItem from '@/components/ui/domain/item/FormTargetCalendarsSelectItem/index.vue'
import FormNotificationSettingsCollapseButtonItem from '@/components/ui/domain/item/FormNotificationSettingsCollapseButtonItem.vue'
import FormConfirmationEmailTargetsItem from '@/components/ui/domain/item/FormConfirmationEmailTargetsItem/index.vue'
import CalendarsModule from '@/store/modules/calendars'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'

export default defineComponent({
  name: 'OrganaizerAndAttendeeSectionForPersonal',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    AttendingTypeRadioButton,
    FormOrganizerDropdownItem,
    FormAttendingEmails,
    FormCandidateTargetCalendarsSettingsCollapseButtonItem,
    FormTargetCalendarsSelectItem,
    FormNotificationSettingsCollapseButtonItem,
    FormConfirmationEmailTargetsItem
  },
  setup() {
    const { editingObjectForPrivate, updateCondition } = useAvailabilitySharingForm()

    const calendarCollections = computed(() => {
      return CalendarsModule.getAccountWithcalendars.map((pc) => ({
        accountId: pc.accountId,
        userName: pc.userName,
        userPhoto: pc.userPhoto,
        calendars: pc.calendars.filter((c) => c.primary)
      }))
    })
    const organizerCalendar = computed(() => ({
      accountId: editingObjectForPrivate.value.organizer.accountId,
      calendarId: editingObjectForPrivate.value.organizer.calendarId
    }))
    const handleOrganizerCalendarChange = (newValue) => {
      const clone = cloneDeep(editingObjectForPrivate.value)

      // 旧organaizerをcalendarsから削除
      const calendarIndex = clone.calendars.findIndex(
        (s) => s.accountId === clone.organizer.accountId && s.calendarId === clone.organizer.calendarId
      )
      if (calendarIndex >= 0) {
        clone.calendars.splice(calendarIndex, 1)
      }

      const selectedCalendar = CalendarsModule.allCalendarsButNotResource.find(
        (c) => c.accountId === newValue.accountId && c.calendarId === newValue.calendarId
      )

      // 新organaizerがattendeeにある場合は削除
      const attendeeIndex = clone.attendees.findIndex(
        (s) => s.accountId === selectedCalendar.accountId && s.calendarId === selectedCalendar.id
      )
      if (attendeeIndex >= 0) {
        clone.attendees.splice(attendeeIndex, 1)
      }

      // 新organaizerがcalendarsにない場合はは追加
      if (
        !clone.calendars.some((s) => s.accountId === selectedCalendar.accountId && s.calendarId === selectedCalendar.id)
      ) {
        clone.calendars.push({
          accountId: selectedCalendar.accountId,
          calendarId: selectedCalendar.id
        })
      }

      clone.organizer = {
        accountId: selectedCalendar.accountId,
        calendarId: selectedCalendar.id
      }
      updateCondition(clone)
    }

    const availableAttendeeList = computed(() => {
      return CalendarsModule.allCalendarsButNotResource.filter(
        (c) =>
          c.invitationAddress &&
          !(
            c.accountId === editingObjectForPrivate.value.organizer.accountId &&
            c.calendarId === editingObjectForPrivate.value.organizer.calendarId
          )
      )
    })
    const selectedCalendarTypes = computed(() => {
      return editingObjectForPrivate.value.attendees.map((a) => ({
        type: 'email',
        email: a.calendarId
      }))
    })
    function handleAttendeeEmailAdd(selectedEmail) {
      const targetCalendar = availableAttendeeList.value.find((a) => a.invitationAddress === selectedEmail)
      if (!targetCalendar) return
      const clone = cloneDeep(editingObjectForPrivate.value)
      clone.attendees.push({
        accountId: targetCalendar.accountId,
        calendarId: targetCalendar.calendarId
      })
      if (
        !editingObjectForPrivate.value.calendars.some(
          (s) => s.accountId === targetCalendar.accountId && s.calendarId === targetCalendar.calendarId
        )
      ) {
        clone.calendars.push({
          accountId: targetCalendar.accountId,
          calendarId: targetCalendar.calendarId
        })
      }
      updateCondition(clone)
    }
    function handleAttendeeEmailDeleted(deletedEmail) {
      const targetCalendar = availableAttendeeList.value.find((a) => a.invitationAddress === deletedEmail)
      if (!targetCalendar) return
      const clone = cloneDeep(editingObjectForPrivate.value)
      const attendeeIndex = clone.attendees.findIndex(
        (s) => s.accountId === targetCalendar.accountId && s.calendarId === targetCalendar.calendarId
      )
      if (attendeeIndex >= 0) {
        clone.attendees.splice(attendeeIndex, 1)
      }
      const calendarIndex = clone.calendars.findIndex(
        (s) => s.accountId === targetCalendar.accountId && s.calendarId === targetCalendar.calendarId
      )
      if (calendarIndex >= 0) {
        clone.calendars.splice(calendarIndex, 1)
      }
      updateCondition(clone)
    }

    const candidateTargetCalendarsEditingObject = editingObjectForPrivate
    function handleCandidateTargetCalendarsUpdate() {
      updateCondition(candidateTargetCalendarsEditingObject.value)
    }

    return {
      calendarCollections,
      organizerCalendar,
      handleOrganizerCalendarChange,
      availableAttendeeList,
      selectedCalendarTypes,
      handleAttendeeEmailAdd,
      handleAttendeeEmailDeleted,
      candidateTargetCalendarsEditingObject,
      handleCandidateTargetCalendarsUpdate
    }
  }
})
