




import { Watch } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import EventLayout from './Layout.vue'
import EditEventModule from '@/store/modules/editEvent'
import CreateMixin from '@/views/calendar/CreateMixin'
import { mixins } from 'vue-class-component'
import ProfileModule from '@/store/modules/profile'
import CalendarMixin from '../CalendarMixin'
import UserModule from '@/store/modules/user'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    EventLayout
  }
})
export default class EventCreate extends mixins(CreateMixin, CalendarMixin) {
  @Watch('hasPrimaryCalendar')
  changedCalendarLoading(hasCalendar) {
    if (!this.editingEvent && hasCalendar) {
      this.initEditingModule()
    }
  }
  async created() {
    await UserModule.fetchIntegrations(true)
  }
  mounted() {
    return this.initEditingModule()
  }
  async beforeRouteLeave(route, redirect, next) {
    if (EditEventModule.isDirty) {
      if (await this.confirmDiscardItem()) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
  async initEditingModule() {
    if (!this.hasPrimaryCalendar) {
      return Promise.resolve()
    }
    await EditEventModule.startCreatingNewEvent()
    this.$buefy.toast.open({
      type: 'is-info',
      position: 'is-top',
      message: this.$t('message.startCreating', { type: this.$t('labels.event') }).toString()
    })
  }
  get myProfile() {
    return ProfileModule.myProfile
  }
  get timeZone(): string | undefined {
    return this.myProfile?.primaryTimezoneKey
  }
  get editingEvent() {
    return EditEventModule.editingEvent
  }
}
