














import AppModule from '@/store/modules/app'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Layout from './atoms/AsideMenuItemLayout.vue'
import AsideMenuBottom from './MenuBottom.vue'
import AsideMenuTeam from './MenuTeam.vue'
import AsideMenuTop from './MenuTop.vue'
import AsideMenuItemLogo from './molecules/AsideMenuItemLogo.vue'
import OpenCloseButton from './molecules/OpenCloseButton.vue'

const LOCAL_STORAGE_KEY = 'spir-nav-menu'

@Component({
  components: {
    AsideMenuTop,
    AsideMenuItemLogo,
    AsideMenuBottom,
    Layout,
    OpenCloseButton,
    AsideMenuTeam
  }
})
export default class NavMenu extends Vue {
  open = true
  @Watch('open')
  openStatusUpdated() {
    this.$ls.set(LOCAL_STORAGE_KEY, this.open)
  }
  mounted() {
    const openStatus = this.$ls.get(LOCAL_STORAGE_KEY)
    if (typeof openStatus === 'boolean') {
      this.open = openStatus
    }
  }
  closeQuickView() {
    AppModule.closeQuickView()
  }

  closeMenu() {
    this.open = false
  }
}
