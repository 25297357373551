



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICalendar } from '@/types'

@Component
export default class CalendarSettingItemCalendar extends Vue {
  @Prop() calendar: ICalendar
  @Prop() visible: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) showEmail?: boolean

  onToggleCalendar() {
    if (this.disabled) {
      return
    }
    this.$emit('onToggleCalendar')
  }
}
