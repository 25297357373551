


























import { Component, Prop, Vue } from 'vue-property-decorator'
import AttendeePullDownForm from './AttendeePullDownForm.vue'

@Component({
  components: {
    AttendeePullDownForm
  }
})
export default class AttendeeInvitationButton extends Vue {
  @Prop({ default: true }) updateHeightWhenDropdownIsActive
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false

  get getIsDropdownActive() {
    return this.isDropdownActive
  }
  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }

  toggle() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropdownRef = this.$refs.dropdown as any
    dropdownRef.toggle()
  }
  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm({ name, email }: { name: string; email: string }) {
    this.$emit('confirm', { name, email })
  }
}
