import { pick } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class SessionStorage<T = any> {
  #sessionStorageKey: string
  #keys: Array<string>
  constructor(sessionStorageKey: string, keys: Array<string>) {
    this.#sessionStorageKey = sessionStorageKey
    this.#keys = keys
  }
  saveToSessionStorage(targetObject: T) {
    const targetValues = pick(targetObject, this.#keys)
    window.sessionStorage.setItem(this.#sessionStorageKey, JSON.stringify(targetValues))
  }
  loadFromSessionStorage(): T {
    return JSON.parse(window.sessionStorage.getItem(this.#sessionStorageKey)) || {}
  }
}
