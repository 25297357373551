



































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from './CommonLayout.vue'
import { GoogleEvent } from '@/types'
import UserModule from '@/store/modules/user'
import LocationRead from '@/components/molecules/LocationRead.vue'
import AttendeeItem from '@/components/molecules/AttendeeItem.vue'
import { OnlineMeetingType } from '@/types/schedule'
import HtmlDisplay from '@/components/molecules/HtmlDisplay.vue'
import MeetingRoomInfo from '@/components/molecules/MeetingRoomInfo.vue'
import OnlineMeetingSelector from '@/components/molecules/OnlineMeetingSelector.vue'
import AttendeeWithProfileButton from '@/components/molecules/AttendeeWithProfileButton.vue'
import CalendarsModule from '@/store/modules/calendars'
import { SignalType } from '@/lib/analytics/LogEntry'
import EventModule from '@/store/modules/event'
import AttendeeStatus from '@/components/molecules/AttendeeStatus.vue'
import eventDate from '@/filters/eventDate'
import EventForModalModule from '@/store/modules/eventForModal'
import MemberAvatar from '@/components/molecules/MemberAvatar.vue'

@Component({
  components: {
    CommonLayoutModal,
    LocationRead,
    AttendeeItem,
    AttendeeWithProfileButton,
    HtmlDisplay,
    MeetingRoomInfo,
    OnlineMeetingSelector,
    AttendeeStatus,
    MemberAvatar
  }
})
export default class EventFormModal extends Vue {
  @Prop() currentEvent: GoogleEvent
  @Prop({ default: false }) hideControls: boolean

  editingEvent: GoogleEvent = null
  lockOptions = []

  get hasTeamInfo() {
    return !!this.currentEvent.teamInfo
  }
  get attendees() {
    return EventForModalModule.getAttendees
  }
  get writableCalendars() {
    return CalendarsModule.writableCalendars
  }
  get accountWithcalendars() {
    return CalendarsModule.getAccountWithcalendars
  }
  get currentUser() {
    return UserModule.currentUser
  }
  get readOnly() {
    return true
  }
  get canDelete() {
    return this.editingEvent.writable && this.editingEvent.id
  }
  get organizerName() {
    return this.currentEvent.organizer?.displayName || this.currentEvent.organizer?.email || this.calendarName
  }
  get calendarName() {
    if (this.currentEvent && this.currentEvent.accountId && this.currentEvent.calendarId) {
      return this.getCalendarName(this.currentEvent.accountId, this.currentEvent.calendarId)
    }
    return null
  }
  get isDeleted() {
    const deleted = this.editingEvent.deleted ?? false
    return deleted
  }
  get showControls() {
    return !this.hideControls && this.editingEvent.writable && !this.isDeleted
  }
  get onlineMeetingType() {
    return this.editingEvent.onlineMeeting ? this.editingEvent.onlineMeeting.type : OnlineMeetingType.none
  }
  get dateRangeDisplay() {
    return eventDate(this.editingEvent.start, this.editingEvent.end, this.editingEvent.allDay)
  }
  getCalendarName(accountId, calendarId) {
    return CalendarsModule.getCalendarName({ accountId, calendarId })
  }
  showInputField(fieldName) {
    return !this.readOnly || !!this.currentEvent[fieldName]
  }
  created() {
    this.editingEvent = {
      ...this.currentEvent
    }
    this.lockOptions = ['default', 'private', 'public'].map((s) => ({
      title: this.$t(`forms.event.visibility.${s}`).toString(),
      value: s
    }))
  }
  deleteEvent() {
    this.$emit('deleteEvent')
    this.$emit('close')
  }
  async updateAttendeeStatus() {
    await EventModule.updateAttendee(this.editingEvent)
  }
  get hasAttendee(): boolean {
    return this.attendees.length > 0
  }
  get hasRoom(): boolean {
    return this.editingEvent.resources && this.editingEvent.resources.length > 0
  }
  get currentRoom() {
    return this.editingEvent.resources
  }
  async handleClickProfile() {
    await this.$analytics.send(SignalType.SEE_PROFILE_ON_EVENT_MODAL)
  }
}
