

















































import { Component, Vue } from 'vue-property-decorator'
import TeamSettingLayout from '@/components/teamSettings/TeamSettingsLayout.vue'
import InputField from '@/components/newForm/molecules/InputField.vue'
import TeamRecordModule, { TeamInfo } from '@/store/modules/teamRecord'
import { TeamDetailModel } from '@/models/data/team'
import ProfileModule from '@/store/modules/profile'
import { TogglableFeatures } from '@/types/feature'
import { isEnabled } from 'vue-feature-flipping'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    TeamSettingLayout,
    InputField,
    ValidationObserver
  }
})
export default class TeamDetails extends Vue {
  teamName = this.teamModel.name
  updatingTeamInfo = false

  get teamId() {
    return this.$route.params.id
  }
  get currentTeamInfo(): TeamInfo {
    return TeamRecordModule.teamByTeamId(this.teamId)
  }
  get teamModel(): TeamDetailModel {
    return this.currentTeamInfo.team
  }

  get isLoading() {
    return this.updatingTeamInfo
  }

  get isUpdateTeamPhotoEnabled() {
    return isEnabled(TogglableFeatures.teamPhotoUpdate.featureName)
  }

  async updateTeamName() {
    try {
      this.updatingTeamInfo = true
      await TeamRecordModule.updateTeamInfo({
        teamId: this.teamId,
        name: this.teamName
      })
      await ProfileModule.fetchMyProfile()
      this.$buefy.toast.open({
        message: this.$t('message.success.saved').toString(),
        type: 'is-success'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('message.errorCommon').toString(),
        type: 'is-danger'
      })
    } finally {
      this.updatingTeamInfo = false
    }
  }
}
