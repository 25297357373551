























import { Component, Prop, Vue } from 'vue-property-decorator'
import Divider from '@/components/atoms/Divider.vue'

@Component({
  components: {
    Divider
  }
})
export default class CommonLayoutModal extends Vue {
  @Prop() rightButtonTitle?: string
  @Prop({ default: true }) showFooter: boolean
  @Prop({ default: true }) showCloseButton?: boolean
  @Prop() leftButtonTitle?: string
  @Prop({ default: false }) rightButtonDisabled?: boolean
  @Prop({ default: false }) enableDelete?: boolean
  @Prop({ default: true }) showHeader: boolean
  @Prop({ default: false }) showHeaderBorder?: boolean
}
