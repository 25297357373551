


































import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirDatePicker from '@/components/ui/spir/SpirDatePicker.vue'
import { endOfDay, getMinByUserTimezone, startOfDay } from '@/lib/utils/timezone'
import { addMinutes, isBefore } from 'date-fns'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

@Component({
  components: {
    SpirDatePicker,
    FormItemSpacer
  }
})
export default class DateTimeInput extends Vue {
  @Prop() title: string
  @Prop({ default: false }) hideTimeSelector: boolean
  @Prop({ default: null }) validStartDate: Date // for set datepicker disabled
  @Prop({ default: 15 }) minInterval: number
  @Prop({ default: false }) inclusive: boolean
  @PropSync('inputDate', { type: Date }) inputDateSynced!: Date

  timesArray: Array<{ date: number; valid: boolean }> = []
  currentTimeByNumber = 0

  created() {
    this.updateTimeArray()
  }
  @Watch('inputDateSynced')
  changedDate() {
    this.updateTimeArray()
  }
  @Watch('validStartDate')
  updatedValidDate() {
    this.updateTimeArray()
  }
  updateTimeArray() {
    const targetDates: Set<number> = new Set()
    let currentTime = startOfDay(this.inputDateSynced)
    const endTime = endOfDay(this.inputDateSynced)
    const validTimeNumber = this.validStartDate ? this.validStartDate.getTime() : 0
    do {
      targetDates.add(currentTime.getTime())
      currentTime = addMinutes(currentTime, this.minInterval)
    } while (isBefore(currentTime, endTime))
    this.timesArray = Array.from(targetDates)
      .sort()
      .map((time) => {
        return {
          date: time,
          valid: time >= validTimeNumber
        }
      })
    this.$nextTick(() => {
      this.currentTimeByNumber = this.inputDateSynced.getTime()
    })
  }
  dateToTime(dateTime: number) {
    return spirDateFormat(new Date(dateTime), spirDateFormatTypes.hourMin)
  }
  updatedTime(event) {
    this.inputDateSynced = new Date(Number(event.target.value))
  }
  handleDateUpdated(selectedDate) {
    const newDate = new Date(selectedDate)
    // timeが表示していない場合は、日付のままセット、表示している場合は時間を維持する
    if (this.hideTimeSelector) {
      this.inputDateSynced = newDate
      return
    }
    const dateMin = getMinByUserTimezone(this.inputDateSynced)
    this.inputDateSynced = addMinutes(newDate, dateMin)
  }
  get disabledDates() {
    if (!this.validStartDate) {
      return undefined
    }
    return this.validStartDate
  }
}
