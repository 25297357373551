
















import { defineComponent, ref, toRefs, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'FormCollapseBorderedCard',
  props: {
    title: {
      type: String,
      required: true
    },
    collapseId: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isOpen } = toRefs(props)
    const open = ref(isOpen.value)
    watch(isOpen, (newVal: boolean) => {
      open.value = newVal
    })
    return {
      open
    }
  }
})
