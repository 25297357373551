


















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModelTeam } from '@/models/data/schedule'
import CommonLayoutModal from './CommonLayout.vue'
import LocationRead from '../molecules/LocationRead.vue'
import HtmlDisplay from '@/components/molecules/HtmlDisplay.vue'
import candidateDates from '@/filters/candidateDates'
import AttendeeWithProfileButton from '@/components/molecules/AttendeeWithProfileButton.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import ShareButton from '@/components/molecules/ShareButton.vue'

@Component({
  components: {
    CommonLayoutModal,
    LocationRead,
    AttendeeWithProfileButton,
    HtmlDisplay,
    ShareButton
  }
})
export default class CandidateDetailModal extends Vue {
  @Prop() currentSchedule: ScheduleModelTeam
  @Prop() candidateId: string

  get isValidCandidate() {
    const currentCandidate = this.currentSchedule.candidates.find((c) => c.id === this.candidateId)
    if (!currentCandidate) {
      return false
    }
    return currentCandidate.status === 'suggestedByOrganizer' || currentCandidate.status === 'suggestedByConfirmer'
  }
  get isSyncing() {
    return !this.currentSchedule.allEventsSynced
  }
  get showOrganizer() {
    return this.currentSchedule.organizerInfo
  }
  get showAttendees() {
    return this.currentSchedule.attendees.map((a) => {
      return {
        ...a,
        id: a.userId
      }
    })
  }
  get showCandidates() {
    return candidateDates(this.currentSchedule)
  }
  showInputField(fieldName) {
    return !!this.currentSchedule[fieldName]
  }
  handleEditSchedule() {
    this.$emit('editSchedule')
  }
  async handleClickProfile() {
    await this.$analytics.send(SignalType.SEE_PROFILE_CONFIRM_MODAL)
  }
}
