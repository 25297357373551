






































import BottomNavMenu from '@/components/BottomNavMenu/BottomNavMenu.vue'
import FormStateChangeAlert from '@/components/ui/domain/alert/arrangement/FormStateChangeAlert.vue'
import ArrangementPreviewModal from '@/components/ui/domain/modal/arrangement/ArrangementPreviewModal.vue'
import { TitleSuggestionSetups } from '@/components/ui/domain/sections/arrangement/composables/types'
import {
  ArrangementFormRequest,
  CreateArrangementSidePanelInfo,
  PersonalTeamInfoGetters
} from '@/components/ui/domain/sidePanel/arrangement/types'
import {
  PeekCandidatesControl,
  usePeekCandidatesPanelHandler
} from '@/components/ui/domain/sidePanel/arrangement/useArrangementPeekCandidatesSidePanel'
import { useNewArrangementsFormData } from '@/components/ui/domain/sidePanel/arrangement/useNewArrangementsFormData'
import BottomNavMenuFrame from '@/components/ui/frame/BottomNavMenuFrame.vue'
import { CommonCalendarHandlers } from '@/components/ui/spir/types'
import { useFloatingMenuActions } from '@/composables/calendar/useFloatingMenuActions'
import { noop } from '@/lib/utils'
import { genNewCandidateFromTempEvent } from '@/lib/utils/candidate'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'
import { CreateEvent, FullCalendarEvent } from '@/types'
import Common from '@/views/calendar/Common.vue'
import { computed, defineComponent, onMounted, PropType, provide, ref, watch } from '@vue/composition-api'
import BottomForm from './_BottomForm.vue'
import MenuSelectSection from './_MenuSelectSection.vue'
import ModeSwitchButton from './_ModeSwitchButton.vue'
import { useLayoutChangeSetup } from './_useLayoutChangeSetup'

export default defineComponent({
  name: 'ArrangementsOnCalendarPage',
  components: {
    Common,
    BottomNavMenu,
    BottomNavMenuFrame,
    BottomForm,
    MenuSelectSection,
    ArrangementPreviewModal,
    FormStateChangeAlert,
    ModeSwitchButton
  },
  props: {
    initialFormRequest: {
      type: Object as PropType<ArrangementFormRequest>,
      required: true
    },
    titleSuggestionSetups: {
      type: Object as PropType<TitleSuggestionSetups>,
      required: true
    },
    onLastFormRequestSave: {
      type: Function as PropType<(formRequest: ArrangementFormRequest) => void>,
      required: true
    }
  },
  setup(props) {
    const commonRef = ref<CommonCalendarHandlers | null>(null)
    const {
      currentPageState,
      calendarTheme,
      calendarBodyMode,
      isSelectingMenu,
      isModeSwitchActive,
      isModeSwitchVisible,
      formStateChangeAlertType,
      heightState,
      resetLayout,
      startEditing,
      toggleLayerMode,
      toggleOverviewAndDetailForms,
      toOverviewForm,
      showCancelAlertIfNeeded,
      addTempCandidate
    } = useLayoutChangeSetup()
    const { floatingMenuRef, additionalEvents, addCandidate, resetTempEvent } = useFloatingMenuActions()
    const personalTeamInfoGetters = {
      getMyProfile: () => ProfileModule.myProfile,
      getMyTeams: () => TeamModule.myTeams
    }
    provide(PersonalTeamInfoGetters, personalTeamInfoGetters)
    async function handleBottomFormClose(shouldStayHere: () => Promise<boolean>) {
      const staying = await shouldStayHere()
      if (!staying) {
        resetLayout()
      }
    }
    const { changeFormRequest, getFormDataFunctions, clearAllData } = useNewArrangementsFormData({
      formRequest: props.initialFormRequest,
      suggestionSetups: props.titleSuggestionSetups,
      setLastFormRequest: props.onLastFormRequestSave,
      getMyTeams: personalTeamInfoGetters.getMyTeams,
      canShowAutoCandidateInfo: false,
      saveAutoPeekCandidatesInfoNextTimeCheck: noop,
      candidatesInitializingWay: { type: 'from_blank' },
      cancelEditing: handleBottomFormClose,
      openCalendar: () => toOverviewForm()
    })
    provide(CreateArrangementSidePanelInfo, { changeFormRequest, getFormDataFunctions })
    const formDataFunctions = computed(() => getFormDataFunctions())
    const formData = computed(() => formDataFunctions.value.getAllData())
    const formRequest = computed(() => formDataFunctions.value.getFormRequest())
    provide(PeekCandidatesControl, { formData })
    const { isPeekCandidateSectionOpened, candidatesControls } = usePeekCandidatesPanelHandler({ formData })
    async function handleMenuClick() {
      await clearAllData()
      formData.value.clearDirty()
      if (floatingMenuRef.value) {
        const event = additionalEvents.value[0]
        if (event) {
          const { start, end } = genNewCandidateFromTempEvent({
            event,
            duration: formData.value.basicInfo.duration.value
          })
          await formData.value.candidatesInfo.addNewCandidate({ start, end }, null)
          resetTempEvent()
        }
        floatingMenuRef.value.hideMenuWithNoEmit()
      }
      startEditing()
    }
    function handleTempCandidateAdd(data: CreateEvent) {
      addCandidate(data)
      addTempCandidate()
    }
    const candidates = computed(() =>
      isSelectingMenu.value ? additionalEvents.value : candidatesControls.value.candidates
    )
    watch(currentPageState, () => {
      if (commonRef.value) {
        setTimeout(() => {
          commonRef.value.updateCalendarHeight()
        }, 300)
      }
    })
    onMounted(() => {
      resetTempEvent()
    })
    return {
      calendarTheme,
      calendarBodyMode,
      isModeSwitchActive,
      isModeSwitchVisible,
      floatingMenuRef,
      commonRef,
      formData,
      formRequest,
      candidates,
      isSelectingMenu,
      isPeekCandidateSectionOpened,
      formStateChangeAlertType,
      heightState,
      handleMenuClick,
      handleCandidateReset: resetTempEvent,
      handleCandidateClicked: (id: string) => {
        candidatesControls.value.handleDelete(id)
        showCancelAlertIfNeeded()
      },
      handleEventAdd: (data: CreateEvent) => {
        if (isSelectingMenu.value) {
          handleTempCandidateAdd(data)
        } else {
          candidatesControls.value.handleUpdate(data)
          showCancelAlertIfNeeded()
        }
      },
      handleEventUpdate: ({ event, revert }: { event: FullCalendarEvent; revert: Function }) => {
        candidatesControls.value.handleUpdate(event, revert)
      },
      handleBottomFormClose: async () => {
        await handleBottomFormClose(formData.value.shouldStayHere)
      },
      handleCalendarBodyModeChange: () => toggleLayerMode(),
      handleFormHeightToggle: () => toggleOverviewAndDetailForms()
    }
  }
})
