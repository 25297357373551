




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CancelButton extends Vue {
  @Prop({ default: 'close' }) icon
  @Prop() title!: string
}
