




import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmText from './ConfirmText.vue'
import { formatEventTimes } from '@/lib/utils/date'

@Component({
  components: {
    ConfirmText
  }
})
export default class ConfirmParagraph extends Vue {
  @Prop({ required: true }) event: { start: Date; end: Date }
  @Prop() timeZone: string

  showFormatEventTimes(event, timeZone?: string) {
    return formatEventTimes(event, timeZone)
  }

  get timeRangeTitle(): string {
    return formatEventTimes(this.event, this.timeZone)
  }
}
