












import { Component, PropSync, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import FormSelectBox from '@/components/newForm/atoms/SelectBox.vue'

@Component({
  components: {
    FormItem,
    FormSelectBox
  }
})
export default class FormVisibility extends Vue {
  @PropSync('visibility', { type: String }) visibilitySynced: string
  items = []

  created() {
    this.items = ['default', 'private', 'public'].map((n) => {
      return {
        key: n,
        title: this.$t(`forms.event.visibility.${n}`).toString(),
        value: n
      }
    })
  }
}
