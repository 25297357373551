













import FormTextareaItem from '@/components/ui/form/item/FormTextareaItem.vue'
import { useVeeValidationProviderSlot } from '@/composables/useVeeValidationProviderSlot'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'FormDescriptionTextareaItem',
  components: {
    FormTextareaItem,
    ValidationProvider
  },
  props: {
    value: {
      type: String
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { value, onInput } = toRefs(props)
    const { makeErrorMessage } = useVeeValidationProviderSlot()
    const model = computed({
      get: () => value.value,
      set: (newDesc: string) => onInput.value(newDesc)
    })
    return {
      model,
      makeErrorMessage
    }
  }
})
