import {
  FormDescriptionTextareaItemSetup,
  FormOnlineMeetingDropdownItemSetup,
  FormLocationInputFormSetup,
  FormVisibilitySelectBoxItemSetup,
  useFormDescriptionTextareaItem,
  useFormLocationInputForm,
  useFormOnlineMeetingDropdownItem,
  useFormVisibilitySelectBoxItem
} from '@/components/ui/domain/item/formItemComposables'
import { OnlineMeetingType, VISIBILITY } from '@/types'
import { getDefaultOnlineMeetintType } from '@/store/lib/utils'
import CalendarsModule from '@/store/modules/calendars'
import UserModule from '@/store/modules/user'
import { OnlineMeetingTool } from '@/components/ui/domain/item/FormOnlineMeetingDropdownItem/onlineMeeting'

export type PersonalOthersInfoSectionInitialData = {
  description: string
  onlineMeetingType: OnlineMeetingType
  location: string
  visibility: VISIBILITY
}
export type PersonalOthersInfoSectionSetup = {
  descriptionForm: FormDescriptionTextareaItemSetup
  onlineMeetingForm: FormOnlineMeetingDropdownItemSetup
  locationForm: FormLocationInputFormSetup
  visibilityForm: FormVisibilitySelectBoxItemSetup
  getDefaultOnlineMeetingTool: (
    info: { accountId: string; calendarId: string },
    previousOnlineMeeting: OnlineMeetingType
  ) => OnlineMeetingTool
  getAvailableOnlineMeetings: (info: { accountId: string; calendarId: string }) => OnlineMeetingTool[]
}
export const usePersonalOthersInfoSectionSetup = (
  initial: PersonalOthersInfoSectionInitialData
): PersonalOthersInfoSectionSetup => {
  const descriptionForm = useFormDescriptionTextareaItem(initial.description)
  const onlineMeetingForm = useFormOnlineMeetingDropdownItem({ type: initial.onlineMeetingType })
  const locationForm = useFormLocationInputForm({ location: initial.location || '' })
  const visibilityForm = useFormVisibilitySelectBoxItem({ visibility: initial.visibility })

  function getDefaultOnlineMeetingTool(
    info: { accountId: string; calendarId: string },
    previousOnlineMeetingType: OnlineMeetingType
  ) {
    const calendar = CalendarsModule.getCalendar({
      accountId: info.accountId,
      calendarId: info.calendarId
    })
    const onlineMeeting = getDefaultOnlineMeetintType(calendar, { type: previousOnlineMeetingType })
    return {
      serviceType: onlineMeeting.type
    }
  }

  function getAvailableOnlineMeetings(info: { accountId: string; calendarId: string }) {
    const currentCalendar = CalendarsModule.getCalendar({
      accountId: info.accountId,
      calendarId: info.calendarId
    })
    const meetings = currentCalendar?.availableOnlineMeetings || []
    const availableMeetings = meetings.reduce(
      (a: OnlineMeetingTool[], c) => {
        a.push({
          serviceType: c
        })
        return a
      },
      [
        {
          serviceType: OnlineMeetingType.none
        }
      ]
    )
    return availableMeetings.concat({
      serviceType: OnlineMeetingType.zoom,
      isConnected: UserModule.isConnectedZoom,
      accountName: UserModule.integrations.zoomInfo?.email
    })
  }

  return {
    descriptionForm,
    onlineMeetingForm,
    locationForm,
    visibilityForm,
    getDefaultOnlineMeetingTool,
    getAvailableOnlineMeetings
  }
}
