import { Attendee } from '@/types'
import { decodeAttendee } from './availabilityForConfirm'
import { ConfirmationForm } from './availability'
import {
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse,
  PublicAvailabilitySharingsOverviewGetResponse,
  OnlineMeeting,
  PrioritizedOnlineMeetings
} from '@spirinc/contracts'

export class AvailabilityOverviewModel {
  title: string
  duration: number
  onlineMeeting: OnlineMeeting
  location?: string
  description?: string
  start: string
  end: string
  organizer?: Attendee
  attendees: Attendee[]
  candidateDescription?: string
  timeZone: string
  confirmationForm?: ConfirmationForm
  constructor(data: PublicAvailabilitySharingsOverviewGetResponse) {
    this.title = data.title
    this.duration = data.duration
    this.onlineMeeting = data.onlineMeeting
    this.location = data.location
    this.description = data.description
    this.start = data.start
    this.end = data.end
    if (data.organizer) {
      this.organizer = decodeAttendee(data.organizer)
    }
    if (data.attendees && data.attendees.length > 0) {
      this.attendees = data.attendees.map((a) => decodeAttendee(a))
    } else {
      this.attendees = []
    }
    this.timeZone = data.timeZone
    this.candidateDescription = data.candidateDescription
    this.confirmationForm = data.confirmationForm
  }
}

export class AvailabilityOverviewModelForTeam {
  title: string
  duration: number
  location?: string
  description?: string
  start: string
  end: string
  organizer?: Attendee
  attendees: Attendee[]
  candidateDescription?: string
  timeZone: string
  prioritizedOnlineMeetings: PrioritizedOnlineMeetings
  confirmationForm?: ConfirmationForm

  constructor(data: PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse) {
    this.title = data.title
    this.duration = data.duration
    this.location = data.location
    this.description = data.description
    this.start = data.start
    this.end = data.end
    if (data.organizer) {
      this.organizer = decodeAttendee(data.organizer)
    }
    if (data.attendees && data.attendees.length > 0) {
      this.attendees = data.attendees.map((a) => decodeAttendee(a))
    } else {
      this.attendees = []
    }
    this.timeZone = data.timeZone
    this.candidateDescription = data.candidateDescription
    this.confirmationForm = data.confirmationForm
    this.prioritizedOnlineMeetings = data.prioritizedOnlineMeetings
  }
}
