








import { Component, Vue } from 'vue-property-decorator'
import TeamInvitedLayout from '@/components/teamSettings/TeamInvitedLayout.vue'
import TeamInvitedTokenExpiredSlide from '@/components/teamSettings/slides/TeamInvitedTokenExpiredSlide.vue'
import queryParams from '@/lib/queryParams'

@Component({
  components: {
    TeamInvitedLayout,
    TeamInvitedTokenExpiredSlide
  }
})
export default class TeamMemberInvitedTokenExpired extends Vue {
  get message(): { title: string; description: string } {
    const errorCode = this.$route.query[queryParams.INVITATION_ERROR_CODE] as string
    if (this.$te(`teamInvited.errors.${errorCode}.title`)) {
      return {
        title: this.$t(`teamInvited.errors.${errorCode}.title`).toString(),
        description: this.$t(`teamInvited.errors.${errorCode}.description`).toString()
      }
    }
    return {
      title: this.$t('teamInvited.errors.token-expired.title').toString(),
      description: this.$t('teamInvited.errors.token-expired.description').toString()
    }
  }
}
