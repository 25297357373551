








import { defineComponent } from '@vue/composition-api'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import ChangeAuthRoute from '@/components/atoms/ChangeAuthRoute.vue'
import SignInButtons from '@/components/auth/SignInButtons.vue'

import i18n from '@/i18n'

export default defineComponent({
  props: {
    signInWithGoogle: {
      type: Function,
      required: true
    },
    signInWithMicrosoft: {
      type: Function,
      required: true
    }
  },
  components: {
    AuthFormLayout,
    SignInButtons,
    AuthFormSpacer,
    ChangeAuthRoute
  },
  setup() {
    const title = i18n.t('forms.auth.signIn.title')
    return {
      title
    }
  }
})
