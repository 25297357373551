


































import Divider from '@/components/atoms/Divider.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SpirCheckbox from '@/components/ui/spir/SpirCheckbox.vue'
import SpirModalFrame from '@/components/ui/spir/SpirModal/SpirModalFrame.vue'
import { defineComponent, computed, PropType } from '@vue/composition-api'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  name: 'AutoPeekCandidatesInfoModal',
  components: {
    SpirModalFrame,
    ClosingPageheadTitleBox,
    ListItemSpacer,
    Divider,
    SpirCheckbox
  },
  props: {
    dontShowNextTimeChecked: {
      type: Boolean,
      required: true
    },
    onDontShowNextTimeCheckToggle: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(_, { emit }) {
    const currentLanguage = computed(() => ProfileModule.getLanguage)
    return {
      currentLanguage,
      mp4Link: computed(() =>
        require(`@/assets/byLanguage/${currentLanguage.value}/videos/autoPeekCandidates/introduce.mp4`)
      ),
      webmLink: computed(() =>
        require(`@/assets/byLanguage/${currentLanguage.value}/videos/autoPeekCandidates/introduce.webm`)
      ),
      handleClose: () => {
        emit('close')
      }
    }
  }
})
