









import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from './molecules/Item.vue'
import oneDateRange from '@/filters/oneDateRange'
import TimezoneLabel from '@/components/molecules/TimezoneLabel.vue'
import TimezoneModule from '@/store/modules/timezones'

@Component({
  components: {
    DisplayItem,
    TimezoneLabel
  }
})
export default class DisplayDateWithTimezone extends Vue {
  @Prop() start: Date
  @Prop() end: Date

  get formattedDate() {
    return oneDateRange({ startDate: this.start, endDate: this.end })
  }
  get userTimezoneInfo() {
    return TimezoneModule.userTimezoneInfo
  }
}
