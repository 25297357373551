























import FormTextareaItem from '@/components/ui/form/item/FormTextareaItem.vue'
import { useVeeValidationProviderSlot } from '@/composables/useVeeValidationProviderSlot'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'FormCandidateDescriptionTextareaItem',
  components: {
    ValidationProvider,
    FormTextareaItem
  },
  props: {
    value: {
      type: String
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { makeErrorMessage } = useVeeValidationProviderSlot()
    const model = computed({
      get: () => props.value,
      set: (newDesc: string) => props.onInput(newDesc)
    })
    return {
      model,
      makeErrorMessage
    }
  }
})
