import { BNoticeComponent } from 'buefy/types/components'
import { useBuefy } from './useBuefy'

type ReturnTypes = {
  openInfoBottomToast: (data: { message: string; duration?: number }) => void
  openPrimaryTopToast: (data: { message: string }) => void
  openWarningTopToast: (data: { message: string }) => void
  openDangerBottomToast: ({ message, duration }: { message: string; duration?: number }) => void
  openDangerTopToast: (data: { message: string }) => void
  openInfoTopToast: (data: { message: string }) => void
  openSuccessTopToast: (data: { message: string }) => void
}

export const useToast = (): ReturnTypes => {
  const buefy = useBuefy()

  function openInfoBottomToast(data: { message: string; duration?: number }): BNoticeComponent {
    return buefy.toast.open({
      type: 'is-info',
      position: 'is-bottom',
      message: data.message,
      duration: data.duration
    })
  }

  function openPrimaryTopToast(data: { message: string }): void {
    buefy.toast.open({
      type: 'is-primary',
      position: 'is-top',
      message: data.message
    })
  }

  function openWarningTopToast(data: { message: string }): void {
    buefy.toast.open({
      type: 'is-warning',
      position: 'is-top',
      message: data.message
    })
  }

  function openDangerBottomToast({ message, duration = 2000 }: { message: string; duration?: number }): void {
    buefy.toast.open({
      type: 'is-danger',
      position: 'is-bottom',
      message: message,
      duration: duration
    })
  }

  function openDangerTopToast(data: { message: string }): void {
    buefy.toast.open({
      type: 'is-danger',
      position: 'is-top',
      message: data.message
    })
  }

  function openInfoTopToast(data: { message: string }): void {
    buefy.toast.open({
      type: 'is-info',
      position: 'is-top',
      message: data.message
    })
  }

  function openSuccessTopToast(data: { message: string }): void {
    buefy.toast.open({
      type: 'is-success',
      position: 'is-top',
      message: data.message
    })
  }

  return {
    openInfoBottomToast,
    openPrimaryTopToast,
    openWarningTopToast,
    openDangerBottomToast,
    openDangerTopToast,
    openInfoTopToast,
    openSuccessTopToast
  }
}
