










import CopyBoxLinkText from './CopyBoxLinkText.vue'
import Button from '@/components/ui/Button.vue'
import TipMarkBase from '@/components/ui/tipMark/TipMarkBase.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxHeader',
  components: {
    CopyBoxLinkText,
    TipMarkBase,
    Button
  },
  props: {
    title: {
      type: String,
      required: true
    },
    buttonTitle: {
      type: String,
      required: true
    },
    tip: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    return {
      handleClick: props.onClick
    }
  }
})
