






















import IconButton from '@/components/atoms/IconButton.vue'
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ValidationObserver } from 'vee-validate'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import AuthenticationMixin from './AuthenticationMixin'
import firebase from 'firebase/app'

@Component({
  components: {
    ValidationObserver,
    WithValidationInput,
    IconButton
  }
})
export default class SendPasswordResetEmail extends mixins(AuthenticationMixin) {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }

  email = ''

  async resetPassword() {
    firebase
      .auth()
      .sendPasswordResetEmail(this.email)
      .then(() => {
        this.email = ''
        this.$refs.observer.reset()
        this.$buefy.toast.open({
          message: 'メールを送信しました。',
          type: 'is-success'
        })
      })
      .catch((error) => {
        this.$buefy.toast.open({
          message: error,
          type: 'is-danger'
        })
      })
  }
}
