






import { Component, Prop, Vue } from 'vue-property-decorator'
import Input from '../atoms/Input.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'

@Component({
  components: {
    Input,
    FormItem
  }
})
export default class FormInputfield extends Vue {
  @Prop() required: boolean
  @Prop() icon: string
  @Prop() label: string
  @Prop({ default: null }) help: string
  @Prop() rules: string
}
