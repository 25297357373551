import { channelTalkService } from './channelTalk'
import { karteService } from './karte'
import { UserInfoModel } from '@/models/data/userInfo'

class SendUserInfoToExternalServices {
  signedInUser(userInfoModel: UserInfoModel) {
    channelTalkService.sendUserInfo(userInfoModel.userProperties())
    karteService.sendUserInfo(userInfoModel.userProperties())
  }
  updateTeamInfoToUser(payload: { teams: string[]; roles: string[] }) {
    channelTalkService.updateTeamInfo(payload.teams, payload.roles)
  }
  unSignedInUser() {
    channelTalkService.sendUserInfo()
    karteService.sendUserInfo()
  }
  signedOut() {
    channelTalkService.sendUserInfo()
    karteService.sendUserInfo()
  }
}
export const sendUserInfoToExternalServices = new SendUserInfoToExternalServices()
