import { computed, ComputedRef, ref } from '@vue/composition-api'
import { FullCalendarEvent } from '@/types'

export const usePreviewStateInitializer = () => {
  return {
    initialize: <T extends { candidates: FullCalendarEvent[] }>(pageData: ComputedRef<T>) => {
      const isPreviewOpen = ref(false)
      const isPreviewDisabled = computed(() => !(pageData.value?.candidates?.length > 0))
      return {
        isPreviewOpen,
        isPreviewDisabled,
        preview: () => {
          isPreviewOpen.value = true
        }
      }
    }
  }
}

export const usePreviewState = <T extends { candidates: FullCalendarEvent[] }>(pageData: ComputedRef<T>) => {
  const { initialize } = usePreviewStateInitializer()
  return initialize(pageData)
}
