import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { computed, ComputedRef, onMounted, Ref, ref } from '@vue/composition-api'

export const useEditPageInitializing = ({
  setupEditPage
}: {
  setupEditPage: () => Promise<void>
}): {
  initializing: Ref<boolean>
  isSidePanelVisible: ComputedRef<boolean>
  openSidePanel: () => void
} => {
  const { toggleScheduleEventsList, isScheduleEventListOpen } = useCalendarMixin()
  function openSidePanel() {
    toggleScheduleEventsList(true)
  }
  const initializing = ref(false)
  const isSidePanelVisible = computed(() => isScheduleEventListOpen.value)

  async function initialize() {
    try {
      initializing.value = true
      openSidePanel()
      await setupEditPage()
    } finally {
      initializing.value = false
    }
  }

  onMounted(() => {
    initialize()
  })

  return {
    initializing,
    isSidePanelVisible,
    openSidePanel
  }
}
