










import { Component, Prop, Vue } from 'vue-property-decorator'
import { Attendee, ProfileOnOverview, SpirUser } from '@/types'
import { FromAttendee, FromSpirUser } from '@/lib/utils'
import AuthorAndAttendeesInfo from '../atoms/AuthorAndAttendeesInfo.vue'
import { nanoid } from 'nanoid'

@Component({
  components: {
    AuthorAndAttendeesInfo
  }
})
export default class HostMembersInfo extends Vue {
  @Prop() author: SpirUser
  @Prop({ default: [] }) attendees: Attendee[]

  get authorProfile(): ProfileOnOverview {
    const author = this.author || { id: nanoid(), name: '', photoURL: '' }
    const profile = FromSpirUser.convertToProfileOnOverview(author, { isAuthor: true, isLoading: !this.author })
    return profile
  }

  get headerTitle(): string {
    return this.$t('schedule.list.attendeeCount.messageWithAuthor', {
      authorCount: 1,
      attendeesCount: this.attendees.length
    }).toString()
  }

  get attedeeProfiles(): ProfileOnOverview[] {
    return this.attendees.map((attendee) =>
      FromAttendee.convertToProfileOnOverview(attendee, { isLoading: false, isAuthor: false })
    )
  }
}
