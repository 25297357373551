import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const {
      confirmDiscardItem,
      isScheduleEventListOpen,
      toggleScheduleEventsList,
      confirmDeleteItem,
      showModal,
      deleteEventItem
    } = useCalendarMixin()

    return {
      confirmDiscardItem,
      isScheduleEventListOpen,
      toggleScheduleEventsList,
      confirmDeleteItem,
      showModal,
      deleteEventItem
    }
  }
})
