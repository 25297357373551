import i18n from '@/i18n'
import { SignalType } from '@/lib/analytics'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { copyClipboard } from '@/lib/utils'
import { PollModel, ScheduleModel, ScheduleModelTeam } from '@/models/data'
import { AllRouteNames } from '@/router'
import AppModule from '@/store/modules/app'
import ListPanelModule from '@/store/modules/listPanel'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import { CardTitleActionItem, ListType, UnconfirmedCardType } from '@/types'
import {
  UnconfirmedCardInfo,
  UnconfirmedPollCardStatus,
  UnconfirmedScheduleCardStatus,
  UnconfirmedTeamCardInfo,
  UnconfirmedTeamScheduleCardType
} from '@/types/unconfirmedList'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { useAnalytics } from './useAnalytics'
import { useBuefy } from './useBuefy'
import { useModal } from './useModal'
import { useToast } from './useToast'

function convertToUnconfirmedCardInfo(model: ScheduleModel | PollModel): UnconfirmedCardInfo {
  return {
    id: model.id,
    title: model.title,
    duration: model.duration,
    canDisplayDate: model.canDisplayDate,
    timeRange: { start: model.start, end: model.end },
    authorName: model.calendarInfo?.calendarName,
    backgroundColor: model.calendarInfo?.backgroundColor,
    attendees: model.attendees?.length === 0 ? [] : model.attendees,
    updatedAt: model.updatedAt
  }
}

function convertToUnconfirmedTeamCardInfo(model: ScheduleModelTeam): UnconfirmedTeamCardInfo {
  return {
    id: model.id,
    title: model.title,
    duration: model.duration,
    canDisplayDate: model.canDisplayDate,
    timeRange: { start: model.start, end: model.end },
    organizerInfo: model?.organizerInfo,
    attendees:
      model.allAttendingMembers?.map((memberResponse) => ({
        id: memberResponse.id,
        fullName: memberResponse.fullName,
        photoURL: memberResponse.photoUrl
      })) || [],
    updatedAt: model.updatedAt,
    lastUpdateUser: model.lastUpdateUser
  }
}

type ReturnTypes = {
  getPrivateCardTypes: (models: (PollModel | ScheduleModel)[]) => UnconfirmedCardType[]
  getTeamCardTypes: (models: ScheduleModelTeam[]) => UnconfirmedTeamScheduleCardType[]
  getTeamLoading: (model: { isLoading: boolean }) => boolean
}

export const useUnconfirmedConverters = (): ReturnTypes => {
  const analytics = useAnalytics()
  const route = useRoute()
  const router = useRouter()
  const buefy = useBuefy()
  const { openScheduleConfirmationModal, openUnconfirmedDeleteModal, openScheduleShareModal } = useModal()
  const { openPrimaryTopToast } = useToast()

  function showConfirmationModal(schedule: ScheduleModel) {
    const openPage = () => {
      const isInTargetConfirmPage = route.name === 'ConfirmSchedule' && route.params.id === schedule.id
      if (!isInTargetConfirmPage) {
        const openPage = {
          name: AllRouteNames.ConfirmSchedule,
          params: { id: schedule.id }
        }
        router.push(openPage)
      }
    }
    openScheduleConfirmationModal({ handleOpen: openPage })
  }

  function goToConfirmPage(schedule: ScheduleModel) {
    AppModule.closeQuickView()
    showConfirmationModal(schedule)
  }
  async function showItem(model: ScheduleModel | PollModel) {
    await analytics.send(SignalType.GO_FROM_LIST, { to: model.type })
    AppModule.closeQuickView()
    EventBus.emit(EVENTS.EDIT_SCHEDULE, { scheduleId: model.id, type: model.type })
  }

  // Private Schedule card status
  function convertToScheduleCardStatus(schedule: ScheduleModel): UnconfirmedScheduleCardStatus {
    switch (schedule.status) {
      case 'expired':
        return { type: 'expired' }
      case 'suggestedByConfirmer':
        return {
          type: 'suggested',
          handle: () => goToConfirmPage(schedule)
        }
      case 'requestedByConfirmer':
        return {
          type: 'requested',
          handle: () => showItem(schedule)
        }
      default:
        return {
          type: 'open',
          handle: () => {
            router.push({
              name: AllRouteNames.ConfirmSchedule,
              params: { id: schedule.id }
            })
          }
        }
    }
  }

  // Private Poll card status
  function convertToPollCardStatus(poll: PollModel): UnconfirmedPollCardStatus {
    switch (poll.status) {
      case 'expired':
        return { type: 'expired' }
      default:
        return {
          type: 'open',
          handle: () => showItem(poll)
        }
    }
  }

  function goToTeamConfirmPage(schedule: ScheduleModelTeam) {
    router.push({ name: AllRouteNames.TeamScheduleConfirm, params: { id: schedule.teamId, scheduleId: schedule.id } })
  }
  function goToTeamEditPage(schedule: ScheduleModelTeam) {
    router.push({ name: AllRouteNames.TeamScheduleEdit, params: { id: schedule.teamId, scheduleId: schedule.id } })
  }

  // Team Schedule card status
  function convertToTeamScheduleCardStatus(schedule: ScheduleModelTeam): UnconfirmedScheduleCardStatus {
    switch (schedule.status) {
      case 'expired':
        return { type: 'expired' }
      case 'suggestedByConfirmer':
        return {
          type: 'suggested',
          handle: () => goToTeamConfirmPage(schedule)
        }
      case 'requestedByConfirmer':
        return {
          type: 'requested',
          handle: () => goToTeamEditPage(schedule)
        }
      default:
        return {
          type: 'open',
          handle: () => goToTeamConfirmPage(schedule)
        }
    }
  }

  async function handleUnconfirmedModelDelete(type: ListType, executeDeletion: () => Promise<void>) {
    await analytics.send(SignalType.CLICK_DELETE_ON_LIST, { type: type })
    const customTitle = i18n
      .t('message.confirmDeleteWholeTitle', {
        type: i18n.t(`labels.${type}`)
      })
      .toString()
    openUnconfirmedDeleteModal({
      title: customTitle,
      handleDelete: async () => {
        try {
          await executeDeletion()
          const msg = i18n
            .t('message.success.deleted', {
              type: i18n.t(`labels.${type}`).toString()
            })
            .toString()
          openPrimaryTopToast({ message: msg })
        } catch (e) {
          openPrimaryTopToast({ message: i18n.t('message.errorCommon').toString() })
        }
      }
    })
  }

  async function handleDeleteSchedule(model: ScheduleModel | PollModel) {
    await handleUnconfirmedModelDelete(model.type, async () => {
      await ListPanelModule.deleteSchedule(model.id)
    })
  }

  async function handleDeleteTeamSchedule(schedule: ScheduleModelTeam) {
    await handleUnconfirmedModelDelete(ListType.SCHEDULE, async () => {
      await TeamScheduleListModule.deleteSchedule({ teamId: schedule.teamId, scheduleId: schedule.id })
    })
  }

  async function openShareModal(schedule: ScheduleModel | ScheduleModelTeam) {
    await analytics.send(SignalType.CLICK_HTML_SHARE_ON_CANDIDATE_MODAL, { type: schedule.type })
    openScheduleShareModal(schedule)
  }

  async function copyUrl(poll: PollModel) {
    await analytics.send(SignalType.CLICK_URL_SHARE_ON_CANDIDATE_MODAL, { type: poll.type })
    copyClipboard(poll.urlForConfirmer)
    buefy.toast.open({
      message: i18n.t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }

  // Cardのタイトル右横のGearボタン用アクション作成関数
  function getEditAction(canEdit: boolean, handler: () => void): CardTitleActionItem | null {
    if (!canEdit) return null
    return {
      title: i18n.t('buttons.editing').toString(),
      handle: handler
    }
  }
  function getShareAction(canShare: boolean, handler: () => void): CardTitleActionItem | null {
    if (!canShare) return null
    return {
      title: i18n.t('buttons.share').toString(),
      handle: handler
    }
  }
  function getDeleteAction(handler: () => void): CardTitleActionItem {
    return {
      title: i18n.t('buttons.delete').toString(),
      handle: handler
    }
  }

  function convertToActionItems(
    schedule: ScheduleModel,
    cardStatus: UnconfirmedScheduleCardStatus
  ): CardTitleActionItem[] {
    const canEdit = cardStatus.type === 'open'
    const editAction = getEditAction(canEdit, () => showItem(schedule))
    const canShare = cardStatus.type !== 'expired'
    const shareAction = getShareAction(canShare, () => openShareModal(schedule))
    const deleteAction = getDeleteAction(() => handleDeleteSchedule(schedule))

    return [editAction, shareAction, deleteAction].filter((item) => !!item)
  }
  function convertToPollActionItems(poll: PollModel, cardStatus: UnconfirmedPollCardStatus): CardTitleActionItem[] {
    const canEdit = cardStatus.type === 'open'
    const editAction = getEditAction(canEdit, () => showItem(poll))
    const canShare = cardStatus.type !== 'expired'
    const shareAction = getShareAction(canShare, () => copyUrl(poll))
    const deleteAction = getDeleteAction(() => handleDeleteSchedule(poll))

    return [editAction, shareAction, deleteAction].filter((item) => !!item)
  }
  function convertToTeamActionItems(
    scheduleTeam: ScheduleModelTeam,
    cardStatus: UnconfirmedScheduleCardStatus
  ): CardTitleActionItem[] {
    const canEdit = cardStatus.type === 'open'
    const editAction = getEditAction(canEdit, () => goToTeamEditPage(scheduleTeam))
    const canShare = cardStatus.type !== 'expired'
    const shareAction = getShareAction(canShare, () => openShareModal(scheduleTeam))
    const deleteAction = getDeleteAction(() => handleDeleteTeamSchedule(scheduleTeam))

    return [editAction, shareAction, deleteAction].filter((item) => !!item)
  }
  return {
    getPrivateCardTypes: (models: (PollModel | ScheduleModel)[]): UnconfirmedCardType[] => {
      const cards: UnconfirmedCardType[] = models.map((model: PollModel | ScheduleModel) => {
        if (model.type === 'schedule') {
          const schedule = model as ScheduleModel
          const scheduleCardInfo: UnconfirmedCardInfo = convertToUnconfirmedCardInfo(schedule)
          const cardStatus: UnconfirmedScheduleCardStatus = convertToScheduleCardStatus(schedule)
          const actionItems = convertToActionItems(schedule, cardStatus)
          return {
            type: 'schedule',
            key: schedule.id,
            firstCandidateStartDate: schedule.firstCandidateStartDate,
            info: scheduleCardInfo,
            status: cardStatus,
            actionItems
          }
        } else {
          const poll = model as PollModel
          const pollCardInfo: UnconfirmedCardInfo = convertToUnconfirmedCardInfo(poll)
          const cardStatus: UnconfirmedPollCardStatus = convertToPollCardStatus(poll)
          const actionItems = convertToPollActionItems(poll, cardStatus)
          return {
            type: 'poll',
            key: model.id,
            firstCandidateStartDate: null,
            info: pollCardInfo,
            status: cardStatus,
            actionItems
          }
        }
      })
      return cards
    },
    getTeamCardTypes: (models: ScheduleModelTeam[]): UnconfirmedTeamScheduleCardType[] => {
      const cards: UnconfirmedTeamScheduleCardType[] = models.map((scheduleTeam: ScheduleModelTeam) => {
        const info = convertToUnconfirmedTeamCardInfo(scheduleTeam)
        const status = convertToTeamScheduleCardStatus(scheduleTeam)
        const actionItems = convertToTeamActionItems(scheduleTeam, status)

        return {
          type: 'teamSchedule',
          key: scheduleTeam.id,
          firstCandidateStartDate: scheduleTeam.firstCandidateStartDate,
          info,
          status,
          actionItems
        }
      })
      return cards
    },
    getTeamLoading: (model: { isLoading: boolean }): boolean => {
      return model?.isLoading
    }
  }
}
