












import AuthPageLayout from '@/components/layout/auth/AuthPageLayout.vue'
import AddSpecificCalendarFormBox from '@/components/auth/addCalendar/AddSpecificCalendarFormBox.vue'
import { defineComponent, reactive, onMounted } from '@vue/composition-api'
import { useAddCalendar } from '@/composables/auth/useAddMSCalenarWithRedirect'
import { useAddGoogleCalenarWithRedirect } from '@/composables/auth/useAddGoogleCalenarWithRedirect'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import UserModule from '@/store/modules/user'
import queryParams from '@/lib/queryParams'

interface State {
  errorCode: string
}

export default defineComponent({
  components: {
    AuthPageLayout,
    // FIXME: タイトルの参照先がja_old2.jsonのaddCalendar.titleになっている
    AddSpecificCalendarFormBox,
    LoadingSpinner
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive<State>({
      errorCode: null
    })
    const { getMicrosoftAuthCode } = useAddCalendar()
    const { getGoogleAuthCode } = useAddGoogleCalenarWithRedirect()
    const executeGoogleProcess = () => {
      return getGoogleAuthCode()
    }
    const executeMicrosoftProcess = () => {
      return getMicrosoftAuthCode()
    }
    const addCalendar = () => {
      if (UserModule.authProvider === 'google') {
        return executeGoogleProcess()
      } else {
        return executeMicrosoftProcess()
      }
    }
    const signOut = () => {
      router.push({ name: 'SignOut' })
    }
    onMounted(() => {
      state.errorCode = route.query[queryParams.ERROR_CODE] as string
    })
    return {
      state,
      signOut,
      authProvider: UserModule.authProvider,
      addCalendar
    }
  }
})
