






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormIconButton extends Vue {
  @Prop() icon!: string
  @Prop({ default: 'is-success' }) type: string
}
