












import { defineComponent, PropType } from '@vue/composition-api'
import AuthButtonsFrame from '@/components/auth/AuthButtonsFrame.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import IconButton from '@/components/atoms/IconButton.vue'

export default defineComponent({
  props: {
    addMicrosoftCalendar: {
      type: Function,
      required: true
    },
    addGoogleCalendar: {
      type: Function,
      required: true
    },
    onlyConnectTo: {
      type: String as PropType<'google' | 'microsoft'>
    }
  },
  components: {
    AuthButtonsFrame,
    AuthFormSpacer,
    IconButton
  }
})
