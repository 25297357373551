/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue'
import App from './App.vue'
import AppForLoading from './AppForLoading.vue'
import router from '@/router'
import store from '@/store'
import UserModule from '@/store/modules/user'
import './registerServiceWorker'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import GAuth from './plugins/gapi'
import config from './lib/config'
import firebaseInit from './lib/firebase'
import i18n from './i18n'
import Vue2TouchEvents from 'vue2-touch-events'
import '@mdi/font/css/materialdesignicons.css'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import './styles/main.scss'
import './filters'
import FeatureFlipping from 'vue-feature-flipping'
import VShortKey from 'vue-shortkey'
import IdleVue from 'idle-vue'
import { AnalyticsRequestSender } from './lib/analytics/signal'
import 'firebase/analytics'
import './plugins/karte'
import './plugins/channelTalk'
import './plugins/vee-validator'
import VueCompositionAPI from '@vue/composition-api'
import Storage from 'vue-ls'
import { SignalType } from './lib/analytics'
import VueGtm from '@gtm-support/vue2-gtm'
import '@/plugins/v-calendar'
import VueMeta from 'vue-meta'

async function main() {
  Vue.use(VueMeta)

  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  })
  Vue.use(Storage)
  const eventsHub = new Vue()
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1000 * 60 * 30, // 30min
    store
  })

  await firebaseInit.init()
  Vue.use(VueCompositionAPI)
  Vue.prototype.$analytics = new AnalyticsRequestSender()
  Vue.prototype.$signal = SignalType

  Vue.config.productionTip = false
  Vue.use(VShortKey, { prevent: ['input', 'textarea', '*[contenteditable="true"]'] })
  if (process.env.VUE_APP_MODE !== 'production') {
    Vue.config.devtools = true
  }
  Vue.use(Vue2TouchEvents, {
    disableClick: true,
    touchHoldTolerance: 500
  })
  Vue.use(Buefy, {
    defaultStatusIcon: false
  })
  Vue.use(GAuth, {
    scope: config.google.scope,
    prompt: config.google.prompt,
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
  })
  Vue.component('vue-draggable-resizable', VueDraggableResizable)

  Vue.use(FeatureFlipping)
  if (process.env.VUE_APP_MODE !== 'local') {
    Sentry.init({
      Vue: Vue,
      dsn: 'https://b83796c8d5e64b0cbaa1abc2d275fef4@o329011.ingest.sentry.io/1844005',
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.VUE_APP_MODE,
      tracesSampleRate: process.env.VUE_APP_MODE === 'production' ? 0.1 : 1.0,
      tracingOptions: {
        trackComponents: true
      }
    })
  }

  new Vue({
    render: (h) => h(AppForLoading)
  }).$mount('#app')
  UserModule.checkRedirectFromSignIn().then(() => {
    UserModule.firebaseInit().then(() => {
      new Vue({
        i18n,
        router,
        store,
        render: (h) => h(App)
      }).$mount('#app')
    })
  })
}

main()
