
















import CopyBoxForUrlAndHtml from '@/components/copyToShare/CopyBoxForUrlAndHtml.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { Candidate, ListType } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CopyBoxForUrlAndHtml
  }
})
export default class CopyUrlAndHtml extends Vue {
  @Prop() activeCandidates: Candidate[]
  @Prop() title: string
  @Prop() duration: number
  @Prop() scheduleType: ListType | 'availabilitySharing'
  @Prop() canHtmlCopy: boolean
  @Prop() urlForConfirm: string

  get urlCopyBoxInfo() {
    return {
      title: this.$t('shareTextModal.copyURLSubTitle', { type: this.$t(`labels.${this.scheduleType}`) }),
      headerTitle: this.$t('shareTextModal.copyURLText', { type: this.$t(`shareTextModal.arrangementType.label`) }),
      analytics: {
        signal: SignalType.CLICK_URL_SHARE_ON_CANDIDATE_MODAL,
        payload: { type: this.scheduleType }
      }
    }
  }
  get htmlCopyBoxInfo() {
    return {
      analytics: {
        signal: SignalType.CLICK_HTML_SHARE_ON_CANDIDATE_MODAL,
        payload: { type: this.scheduleType }
      }
    }
  }

  get userTimezone() {
    return TimezoneModule.userTimezoneKey
  }

  get userLanguage() {
    return ProfileModule.getLanguage
  }

  handleTimeZoneChange(code: string) {
    this.$emit('changedTimeZone', code)
  }
}
