import { FullCalendarEvent } from '@/types'
import EditScheduleModule from '@/store/modules/editSchedule'
import SuggestAlternativeCandidateModule from '@/store/modules/suggestAlternativeCandidate'
import { isBefore, differenceInMinutes, addMinutes } from 'date-fns'
import queryParams from '@/lib/queryParams'
import { computed, ComputedRef, Ref } from '@vue/composition-api'
import { useTranslation } from '@/composables/useTranslation'
import { useModal } from '@/composables/useModal'
import { useRouter } from 'vue2-helpers/vue-router'
import { useToast } from '@/composables/useToast'
import { ScheduleStatus } from '@/types'
import RequestAlternativeComplete from '@/components/modal/RequestAlternativeComplete.vue'
import SuggestAlternativeCandidatesModal from '@/components/modal/confirm/SuggestAlternativeCandidates.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { useAnalytics } from '@/composables/useAnalytics'
import UserModule from '@/store/modules/user'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { SpirUserConfirmer, GuestConfirmer } from '@/models/data'

export const useSuggestDateActionFunctions = (data: {
  isLoading: Ref<boolean>
  isOrganizer: Ref<boolean>
  scheduleId: ComputedRef<string>
}) => {
  const i18n = useTranslation()
  const analytics = useAnalytics()
  const router = useRouter()
  const { openDangerBottomToast } = useToast()
  const { openSpecifiedBodyModal } = useModal()
  const isLoading = data.isLoading
  const isOrganizer = data.isOrganizer
  const scheduleId = data.scheduleId
  const editingSchedule = computed(() => EditScheduleModule.editingSchedule)
  const isSignIn = computed(() => UserModule.isSignIn)

  function handleAddSuggestingCandidate(payload, revert = null) {
    if (isBefore(payload.start, new Date())) {
      openDangerBottomToast({ message: i18n.t('message.error.mustBeBeforeNow').toString() })
      if (revert) {
        revert()
      }
      return
    }
    if (differenceInMinutes(payload.end, payload.start) < editingSchedule.value.duration) {
      payload.end = addMinutes(payload.start, editingSchedule.value.duration)
    }
    SuggestAlternativeCandidateModule.addCandidate(payload)
  }

  function onEventDelete(eventId: string) {
    SuggestAlternativeCandidateModule.deleteCandidate(eventId)
  }

  function handleUpdateEvent({ event, revert }: { event: FullCalendarEvent; revert: Function }) {
    handleAddSuggestingCandidate(event, revert)
  }

  function showModal(BodyComponent: any, events, props: any) {
    return openSpecifiedBodyModal({ BodyComponent, events, props })
  }

  function toConfirmPage() {
    router.push({
      name: 'ConfirmSchedule',
      params: { id: editingSchedule.value.id },
      query: { [queryParams.QUERY_SHOW_CALENDAR]: 'true' }
    })
  }

  function showCompleteModal() {
    const nextStatus = ScheduleStatus.suggestedByConfirmer
    showModal(
      RequestAlternativeComplete,
      {
        close: () => {
          toConfirmPage()
        }
      },
      { nextStatus }
    )
  }

  function onConfirmButtonClick() {
    showModal(
      SuggestAlternativeCandidatesModal,
      {
        register: async ({ email, name }: { email: string; name?: string }) => {
          try {
            isLoading.value = true
            const confirmer = UserModule.isSignIn
              ? new SpirUserConfirmer({ email, user: ProfileModule.myProfile })
              : new GuestConfirmer({
                email,
                name,
                timeZone: TimezoneModule.userTimezoneInfo.key,
                language: ProfileModule.getLanguage
              })
            await SuggestAlternativeCandidateModule.suggestAlternativeCandidates({
              scheduleId: scheduleId.value,
              isOrganizer: isOrganizer.value,
              confirmer
            })
            await analytics.send(SignalType.SUGGEST_ALTERNATIVE_SCHEDULING)
            showCompleteModal()
          } catch (e) {
            openDangerBottomToast({ message: i18n.t('message.errorCommon').toString() })
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        isSignIn: isSignIn.value
      }
    )
  }
  return {
    handleUpdateEvent,
    handleAddSuggestingCandidate,
    onEventDelete,
    onConfirmButtonClick,
    toConfirmPage
  }
}
