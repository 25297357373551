































import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from './molecules/Item.vue'
import copy from 'clipboard-copy'
import { IOnlineMeeting } from '@/types/schedule'

@Component({
  components: {
    DisplayItem
  }
})
export default class DisplayOnlineMeeting extends Vue {
  @Prop() item: IOnlineMeeting

  copyLink() {
    copy(this.item.info.url)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }
  get imageUrl() {
    return require(`@/assets/icons/${this.item.type}.png`)
  }
  get onlineMeetingLabel() {
    return this.$t('onlineMeetings.joinTo', { type: this.$t(`onlineMeetings.${this.item.type}`) })
  }
  get showOnlineMeetingInfo() {
    return this.item?.type !== 'none' && this.item.info
  }
}
