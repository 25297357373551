import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import {
  DefaultCalenderViewSessionStorage,
  DefaultDateSessionStorage,
  CreatedAtSessionStorage,
  ScrollPositionSessionStorage
} from '@/models/sessionStorage/calenderState'
import { isBefore, parseISO, formatISO } from 'date-fns'
import EventsModule from './events'
import TimezomeModule from '@/store/modules/timezones'
import ProfileModule from '@/store/modules/profile'
import {
  exchangeDateByTimezone,
  startOfDay,
  startOfWeek,
  endOfDay,
  addDays,
  isSameMonth,
  isThisWeek
} from '@/lib/utils/timezone'
import { isMobile } from '@/lib/utils'
import { WeekDay } from '@/models/data/userInfo'
import { getHourByUserTimezone } from '@/lib/utils/dateFormat'

const MODULE_NAME = 'CalendarControl'

export const GRID_HEIGHT = 52
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class CalendarControl extends VuexModule {
  _eventsDate: {
    start: Date
    end: Date
  } | null = {
    start: new Date(),
    end: new Date()
  }
  currentCalendarView: 1 | 3 | 7 = 7
  startWeekNum = WeekDay.sunday
  defaultCalenderViewSessionStorageSaver = new DefaultCalenderViewSessionStorage()
  defaultDateSessionStorageSaver = new DefaultDateSessionStorage()
  createdAtSessionStorageSaver = new CreatedAtSessionStorage()
  scrollPositionSessionStorage = new ScrollPositionSessionStorage()

  lastScrollPosition = 0

  get getEventsDate() {
    return this._eventsDate
  }
  get getCurrentView() {
    return this.currentCalendarView
  }
  get nextPeriodDate() {
    return addDays(this._eventsDate.start, this.currentCalendarView)
  }
  get isNextPeriodSameMonth() {
    const nextPeriod = this.nextPeriodDate
    return isSameMonth(this._eventsDate.start, nextPeriod)
  }

  get getLastScrollPosition() {
    return this.lastScrollPosition
  }

  get startWeek() {
    return ProfileModule.startWeekDayNum || this.startWeekNum
  }

  @Action({ commit: 'SET_CURRENT_VIEW' })
  setDefaultCalendarView() {
    const defaultCalenderView = this.defaultCalenderViewSessionStorageSaver.loadFromSessionStorage().defaultCalenderView
    if (defaultCalenderView) {
      return defaultCalenderView
    }
    if (isMobile()) {
      return 3
    }
    return 7
  }

  @Action
  setDefaultDate() {
    const defaultDate = this.defaultDateSessionStorageSaver.loadFromSessionStorage().defaultDate
      ? parseISO(this.defaultDateSessionStorageSaver.loadFromSessionStorage().defaultDate)
      : new Date()
    const createdAt = this.createdAtSessionStorageSaver.loadFromSessionStorage().createdAt
      ? parseISO(this.createdAtSessionStorageSaver.loadFromSessionStorage().createdAt)
      : null
    const startWeekDay = this.startWeek
    const today = Date.now()
    if (createdAt && isBefore(today, addDays(createdAt, 1))) {
      let newDate = defaultDate ?? new Date()
      if (this.getCurrentView === 7) {
        newDate = startOfWeek(newDate, { timeZone: TimezomeModule.timezoneKeysForDisplay[0].key, startWeekDay })
      }
      this.SET_EVENTS_DATE({ start: newDate, saveToSession: !TimezomeModule.hasInterrupt })
      const scrollPosition = this.scrollPositionSessionStorage.loadFromSessionStorage().scrollPosition || 0
      this.SET_LAST_SCROLL_POSITION(scrollPosition)
    } else {
      let newDate = new Date()
      this.setScrollToFirstHour(getHourByUserTimezone(newDate))
      const currentView = this.getCurrentView
      if (Number(currentView) === 7) {
        newDate = startOfWeek(newDate, { timeZone: TimezomeModule.timezoneKeysForDisplay[0].key, startWeekDay })
      }
      this.SET_EVENTS_DATE({ start: newDate, saveToSession: !TimezomeModule.hasInterrupt })
    }
  }
  @Action
  moveCurrentDate(type) {
    const currentView = this.getCurrentView
    const currentEventDate = this.getEventsDate
    let newStart = currentEventDate.start
    switch (type) {
      case 'today':
        newStart = new Date()
        break
      case 'prev':
        newStart = addDays(newStart, -Number(currentView))
        break
      case 'next':
        newStart = addDays(newStart, Number(currentView))
        break
    }
    this.setCurrentDate(newStart)
  }
  @Action
  UpdateEventStartByChangingTimezone(payload: { currentTimezone: string; newTimezone: string; isInit: boolean }) {
    const newStartDate = exchangeDateByTimezone(payload.currentTimezone, payload.newTimezone, this.getEventsDate.start)
    this.SET_EVENTS_DATE({ start: newStartDate, saveToSession: payload.isInit })
  }
  @Action
  setCurrentView(newView) {
    const currentView = this.getCurrentView
    let newStartDate = this.getEventsDate.start || new Date()
    // when current view is week, and changed one day
    if (Number(currentView) === 7 && Number(newView) === 1) {
      // and today is in current view, set to today.
      if (isThisWeek(newStartDate)) {
        newStartDate = new Date()
      }
    }
    this.SET_CURRENT_VIEW(newView)
    this.setCurrentDate(newStartDate)
  }
  @Action
  setCurrentDate(startDate?: Date) {
    let newDate = startDate || new Date()
    const currentView = this.getCurrentView
    const startWeekDay = this.startWeek
    if (Number(currentView) === 7) {
      newDate = startOfWeek(newDate, { timeZone: TimezomeModule.timezoneKeysForDisplay[0].key, startWeekDay })
    }
    this.SET_EVENTS_DATE({ start: newDate, saveToSession: !TimezomeModule.hasInterrupt })
  }
  @Action
  fetchEvents(option?: { hideLoader?: boolean; sequantial?: boolean }) {
    return Promise.all([EventsModule.fetchEvents({ option }), EventsModule.fetchAllVisibleTeamCalendars({ option })])
  }
  @Action
  setScrollToFirstHour(firstHour: number) {
    this.SET_LAST_SCROLL_POSITION(firstHour * GRID_HEIGHT)
  }
  @Mutation
  SET_CURRENT_VIEW(newView) {
    this.currentCalendarView = newView

    this.defaultCalenderViewSessionStorageSaver.saveToSessionStorage({ defaultCalenderView: newView })
  }
  @Mutation
  SET_EVENTS_DATE(payload: { start: Date; saveToSession: boolean }) {
    const { start, saveToSession } = payload
    this._eventsDate = {
      start: startOfDay(start, TimezomeModule.timezoneKeysForDisplay[0].key),
      end: endOfDay(addDays(start, Number(this.currentCalendarView) - 1), TimezomeModule.timezoneKeysForDisplay[0].key)
    }
    if (saveToSession) {
      this.defaultDateSessionStorageSaver.saveToSessionStorage({
        defaultDate: formatISO(startOfDay(start, TimezomeModule.timezoneKeysForDisplay[0].key))
      })
    }
    this.createdAtSessionStorageSaver.saveToSessionStorage({ createdAt: formatISO(new Date()) })
  }
  @Mutation
  SET_LAST_SCROLL_POSITION(scrollPosition: number) {
    this.lastScrollPosition = scrollPosition
    this.scrollPositionSessionStorage.saveToSessionStorage({
      scrollPosition
    })
  }
}

export default getModule(CalendarControl)
