






















































import FormBufferTimeSelectBoxItem from '@/components/ui/domain/item/FormBufferTimeSelectBoxItem.vue'
import FormDurationSelectBoxItem from '@/components/ui/domain/item/FormDurationSelectBoxItem.vue'
import FormExceptionDatetimeListItem from '@/components/ui/domain/item/FormExceptionDatetimeListItem/FormExceptionDatetimeListItem.vue'
import FormHolidaysSelectItem from '@/components/ui/domain/item/FormHolidaysSelectItem/index.vue'
import FormMaxNumPerDaySelectBoxItem from '@/components/ui/domain/item/FormMaxNumPerDaySelectBoxItem.vue'
import FormOffsetFromStartSelectBoxItem from '@/components/ui/domain/item/FormOffsetFromStartSelectBoxItem.vue'
import FormStartDateSelectBoxItem from '@/components/ui/domain/item/FormStartDateSelectBoxItem.vue'
import FormTimezoneAndExceptionsSettingsCollapseButtonItem from '@/components/ui/domain/item/FormTimezoneAndExceptionsSettingsCollapseButtonItem.vue'
import FormTimezoneSelectItem from '@/components/ui/domain/item/FormTimezoneSelectItem.vue'
import FormWeekdayConditionItem from '@/components/ui/domain/item/FormWeekdayConditionItem/FormWeekdayConditionItem.vue'
import {
  DateSlot,
  DatetimeSlotDeletePayload,
  DatetimeSlotSavePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useTranslation } from '@/composables/useTranslation'
import { adjustDurationUnit, FromExceptionModel } from '@/lib/utils'
import { startSelects } from '@/lib/utils/peekCandidates'
import { durations, timeBuffers } from '@/models/data/availability'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { computed, defineComponent } from '@vue/composition-api'
import { cloneDeep, range } from 'lodash'

export default defineComponent({
  name: 'CandidateSection',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    FormWeekdayConditionItem,
    FormDurationSelectBoxItem,
    FormStartDateSelectBoxItem,
    FormOffsetFromStartSelectBoxItem,
    FormBufferTimeSelectBoxItem,
    FormMaxNumPerDaySelectBoxItem,
    FormTimezoneAndExceptionsSettingsCollapseButtonItem,
    FormTimezoneSelectItem,
    FormHolidaysSelectItem,
    FormExceptionDatetimeListItem
  },
  setup() {
    const i18n = useTranslation()
    const {
      editingObjectType,
      editingObject,
      editingObjectForPrivate,
      updateCondition,
      addException,
      updateException,
      removeException,
      addHolidayException,
      removeHolidayException
    } = useAvailabilitySharingForm()

    const isPersonalType = computed(() => editingObjectType.value === 'personal')

    const dayOfTheWeekRules = computed(() => editingObject.value.dayOfTheWeekRules)
    const displayWeekdays = computed(() => editingObject.value.showDisplayWeekday)
    function handleWeekdayConditionChange(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.dayOfTheWeekRules = newValue
      updateCondition(clone)
    }

    const durationItems = durations.map((d) => {
      const displayDuration = adjustDurationUnit(d)
      if (displayDuration.type === 'hour') {
        return {
          key: d,
          label: i18n.t('forms.event.durationHour', { hour: displayDuration.duration }).toString()
        }
      } else {
        return {
          key: d,
          label: i18n.t('forms.event.duration', { min: displayDuration.duration }).toString()
        }
      }
    })
    const duration = computed(() => editingObject.value.duration)
    function handleDurationInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.duration = newValue
      updateCondition(clone)
    }

    const startDateItems = startSelects.map((s) => ({
      key: s,
      label: i18n.t(`peekCandidates.dateOptions.${s}`).toString()
    }))
    const startDate = computed(() => editingObject.value.start)
    function handleStartDateInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.start = newValue
      updateCondition(clone)
    }

    const offsetFromStart = computed(() => editingObject.value.end)
    function handleOffsetFromStartInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.end = newValue
      updateCondition(clone)
    }

    const timeBufferItems = timeBuffers.map((b) => ({
      key: b,
      label: i18n.t('forms.event.duration', { min: b }).toString()
    }))
    const timeBuffer = computed(() => editingObject.value.timeBuffer)
    function handleTimeBufferInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.timeBuffer = newValue
      updateCondition(clone)
    }

    const maxNumPerDayItemsdisabledDueToAttendee = computed(() => editingObjectForPrivate.value.calendars.length > 1)
    const maxNumPerDayItems = range(1, 51).map((n) => ({
      key: n,
      label: String(n)
    }))
    const maxNumPerDay = computed(() => editingObjectForPrivate.value.maxNumPerDay)
    function handleMaxNumPerDayInput(newValue) {
      const clone = cloneDeep(editingObjectForPrivate.value)
      clone.maxNumPerDay = newValue
      updateCondition(clone)
    }

    const timezone = computed(() => editingObject.value.timeZone)
    const handleTimezoneChange = (newValue) => {
      const clone = cloneDeep(editingObject.value)
      clone.timeZone = newValue
      updateCondition(clone)
    }

    const holidayCountryCodes = computed(() => editingObject.value.countries.map((c) => c.code))
    async function handleCountryCodeAdd(addedCode: FrontSupportCountryCode) {
      await addHolidayException(addedCode)
    }
    async function handleCountryCodeRemove(removedCode: FrontSupportCountryCode) {
      await removeHolidayException(removedCode)
    }

    const dateSlots = computed((): DateSlot[] => {
      return FromExceptionModel.convertToDateSlots(editingObject.value.exceptions)
    })
    function handleAllDelete() {
      const clone = cloneDeep(editingObject.value)
      clone.exceptions = []
      updateCondition(clone)
    }
    function handleSave(payload: DatetimeSlotSavePayload) {
      addException(payload)
    }
    function handleDatetimeDelete(payload: DatetimeSlotDeletePayload) {
      removeException(payload)
    }
    function handleDatetimeUpdate(payload: DatetimeSlotUpdatePayload) {
      updateException(payload)
    }

    return {
      isPersonalType,
      dayOfTheWeekRules,
      displayWeekdays,
      handleWeekdayConditionChange,
      durationItems,
      duration,
      handleDurationInput,
      startDateItems,
      startDate,
      handleStartDateInput,
      offsetFromStart,
      handleOffsetFromStartInput,
      timeBufferItems,
      timeBuffer,
      handleTimeBufferInput,
      maxNumPerDayItemsdisabledDueToAttendee,
      maxNumPerDayItems,
      maxNumPerDay,
      handleMaxNumPerDayInput,
      timezone,
      handleTimezoneChange,
      holidayCountryCodes,
      handleCountryCodeAdd,
      handleCountryCodeRemove,
      dateSlots,
      handleAllDelete,
      handleSave,
      handleDatetimeDelete,
      handleDatetimeUpdate
    }
  }
})
