

































import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ArrangementFormSidePanelFooterButtons from '@/components/ui/domain/footer/ArrangementFormSidePanelFooterButtons.vue'
import {
  CreateArrangementSidePanelInfo,
  PersonalTeamInfoGetters
} from '@/components/ui/domain/sidePanel/arrangement/types'
import SidePanelFooterFrame from '@/components/ui/page/SidePanelFooterFrame.vue'
import SidePanelFrame from '@/components/ui/page/SidePanelFrame.vue'
import SidePanelHeaderFrame from '@/components/ui/page/SidePanelHeaderFrame.vue'
import { defineComponent, inject } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import NewArrangementFormBody from './NewArrangementFormBody.vue'
import { useNewArrangementFormPanel } from './useNewArrangementFormPanel'

export default defineComponent({
  name: 'Sections',
  components: {
    ArrangementFormSidePanelFooterButtons,
    SidePanelFrame,
    SidePanelHeaderFrame,
    SidePanelFooterFrame,
    ClosingPageheadTitleBox,
    NewArrangementFormBody,
    ValidationObserver
  },
  setup() {
    const { changeFormRequest, getFormDataFunctions } = inject(CreateArrangementSidePanelInfo)
    const { getMyProfile, getMyTeams } = inject(PersonalTeamInfoGetters)
    const {
      currentArrangementType,
      formRequest,
      formData,
      arrangementTypeSectionState,
      selectedPersonalTeamValue,
      personalInfo,
      teamInfos,
      handlePersonalTeamIdChange,
      handleArrangementTypeChange,
      handleClose,
      handleSave,
      handlePreviewOpen
    } = useNewArrangementFormPanel({ changeFormRequest, getFormDataFunctions, getMyProfile, getMyTeams })
    return {
      currentArrangementType,
      formRequest,
      formData,
      arrangementTypeSectionState,
      selectedPersonalTeamValue,
      personalInfo,
      teamInfos,
      handlePersonalTeamIdChange,
      handleArrangementTypeChange,
      handleClose,
      handleSave,
      handlePreviewOpen
    }
  }
})
