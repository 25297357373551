/* eslint-disable @typescript-eslint/camelcase */

export default {
  google: {
    scope:
      'profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
    prompt: 'consent'
  },
  microsoft: {
    client_id: 'bc1572df-1be4-4a27-86db-2f9b3a54b476',
    response_type: 'code',
    response_mode: 'query',
    prompt: 'select_account',
    scope: ['offline_access', 'user.read', 'calendars.readWrite'].join(' '),
    redirect_uri: `${window.location.origin}/callback-from-ms`
  },
  phrase: {
    projectId: '843fb1a4e515a03414bb4b4cc0c61d44'
  }
}
