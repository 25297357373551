


























import { AvailableAutocompleteListType } from './types'
import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import DisplayItem from './_DisplayItem/index.vue'
import { nanoid } from 'nanoid'

export default defineComponent({
  name: 'CalendarAutocomplete',
  components: {
    DisplayItem
  },
  props: {
    placeholder: { type: String, required: true },
    targetCalendars: { type: Array as PropType<AvailableAutocompleteListType[]>, default: () => [] },
    onCalendarAdd: {
      type: Function as PropType<(selectedCalendar: AvailableAutocompleteListType) => void>,
      required: true
    },
    allowInputEmail: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputedValue = ref('')
    const targetListWithKey = computed(() => {
      return props.targetCalendars.map((tc) => ({ key: nanoid(), ...tc }))
    })
    const filteredList = computed(() => {
      if (!inputedValue.value) {
        return targetListWithKey.value
      }
      const filterdList = targetListWithKey.value.filter((t) => {
        let searchTargetAttrs = []
        if (t.type === 'calendar') {
          searchTargetAttrs = [t.listItem.accountId, t.listItem.id, t.listItem.invitationAddress, t.listItem.title]
        }
        if (t.type === 'member') {
          searchTargetAttrs = [t.listItem.fullName, t.listItem.email]
        }
        return searchTargetAttrs.join(' ').toLowerCase().indexOf(inputedValue.value.toLowerCase()) >= 0
      })
      if (filterdList.length > 0) {
        return filterdList
      }
      if (!props.allowInputEmail) {
        return filterdList
      }
      const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
      }
      if (validateEmail(inputedValue.value)) {
        return [
          {
            type: 'email',
            email: inputedValue.value
          }
        ]
      }
      return []
    })
    return {
      inputedValue,
      filteredList,
      handleSelect: (selectedItem: AvailableAutocompleteListType) => {
        if (!selectedItem) {
          return
        }
        props.onCalendarAdd(selectedItem)
        inputedValue.value = ''
      },
      handleBlur: () => {
        setTimeout(() => {
          inputedValue.value = ''
        }, 200)
      }
    }
  }
})
