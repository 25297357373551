
























import PersonalTeamSelectDropdown from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/index.vue'
import {
  PersonalInfo,
  PersonalTeamValue,
  TeamInfo
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import ArrangementTypeRadioButton from '@/components/ui/domain/radioButton/ArrangementTypeRadioButton/index.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { ListType } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ArrangementTypeSectionState } from './types'

export default defineComponent({
  components: {
    ListItemSpacer,
    PersonalTeamSelectDropdown,
    SidePanelFormSectionTitle,
    ArrangementTypeRadioButton
  },
  props: {
    personal: {
      type: Object as PropType<PersonalInfo>,
      required: true
    },
    teams: {
      type: Array as PropType<TeamInfo[]>,
      required: true
    },
    personalTeamValue: {
      type: String as PropType<PersonalTeamValue>,
      required: true
    },
    arrangementType: {
      type: String as PropType<ListType>,
      required: true
    },
    state: {
      type: String as PropType<ArrangementTypeSectionState>,
      default: 'allOk'
    },
    onPersonalTeamValueChange: {
      type: Function as PropType<(newValue: PersonalTeamValue) => void>,
      required: true
    },
    onArrangementTypeChange: {
      type: Function as PropType<(listType: ListType) => void>,
      required: true
    }
  },
  setup(props) {
    const isPersonalTeamSelectDropdownDisabled = computed((): boolean => {
      return props.state === 'personalTeamDropdownDisabled' || props.state === 'allDisabled'
    })
    const isArrangementTypeRadioButtonDisabled = computed((): boolean => {
      return props.state === 'allDisabled'
    })
    return {
      isPersonalTeamSelectDropdownDisabled,
      isArrangementTypeRadioButtonDisabled
    }
  }
})
