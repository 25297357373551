






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmParagraph extends Vue {
  @Prop({ required: true }) text: string
}
