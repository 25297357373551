




















import { defineComponent, toRefs, computed } from '@vue/composition-api'
import AfterConfirm from '../Index.vue'
import { useAfterConfirmSetup } from '@/composables/useAfterConfirmSetup'
import { useConfirmedAvailabilitySharingCancel } from '@/composables/useConfirmedAvailabilitySharingCancel'
import { getConfirmedInfo } from '@/lib/api/availabilityTeam'
import { AfterConfirmModelForAvailability } from '@/models/data/afterConfirm'
import MessageToOrganizer from '@/components/newDisplay/MessageToOrganizer.vue'
import Description from '@/components/newDisplay/Descrpition.vue'
import DisplayTitle from '@/components/newDisplay/Title.vue'
import DateWithTimezoneAndEditButtons from '@/components/newDisplay/DateWithTimezoneAndEditButtons.vue'
import OnlineMeeting from '@/components/newDisplay/OnlineMeeting.vue'
import Attendees from '@/components/newDisplay/Attendees.vue'
import Location from '@/components/newDisplay/Location.vue'
import { cancelConfirmedPublicURL } from '@/lib/api/team'

export default defineComponent({
  components: {
    AfterConfirm,
    DisplayTitle,
    DateWithTimezoneAndEditButtons,
    OnlineMeeting,
    Attendees,
    Location,
    MessageToOrganizer,
    Description
  },
  props: {
    id: {
      type: String,
      required: true
    },
    confirmedId: {
      type: String,
      required: true
    },
    teamId: {
      type: String
    }
  },
  setup(props) {
    const { id, confirmedId, teamId } = toRefs(props)
    const fetchConfirmedInfo = async () => {
      const response = await getConfirmedInfo(teamId.value, id.value, confirmedId.value)
      const afterConfirmModel = new AfterConfirmModelForAvailability({
        teamId: teamId.value,
        payload: response
      })
      return afterConfirmModel
    }
    const properties = computed(() => {
      return {
        type: 'availability-sharing',
        id: id.value,
        confirmedId: confirmedId.value,
        teamId: teamId.value
      }
    })
    const { afterConfirmModel, isLoading } = useAfterConfirmSetup({
      fetchConfirmedInfo,
      properties
    })
    const cancelConfirmedScheduleApi = async (cancelMessage: string) => {
      await cancelConfirmedPublicURL(teamId.value, id.value, confirmedId.value, cancelMessage)
    }
    const { isCanceling, cancelConfirmedSchedule, isCanceled } = useConfirmedAvailabilitySharingCancel({
      cancelConfirmedScheduleApi
    })
    const handleCancel = async () => {
      await cancelConfirmedSchedule()
    }
    return {
      isLoading: isLoading,
      isCanceling,
      isCanceled,
      handleCancel,
      afterConfirmModel
    }
  }
})
