










































import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { ScheduleModel } from '@/models/data/schedule'
import { Attendee, FullCalendarEvent, SpirUser } from '@/types'
import ConfirmTitle from '@/components/sidePanels/confirm/ConfirmTitle.vue'
import ConfirmItemSpacer from '@/components/sidePanels/confirm/atoms/ConfirmItemSpacer.vue'
import ConfirmDescription from '@/components/sidePanels/confirm/ConfirmDescription.vue'
import ConfirmCandidateBlockTitleBox from '@/components/sidePanels/confirm/atoms/ConfirmCandidateBlockTitleBox.vue'
import ConfirmMap from '@/components/sidePanels/confirm/ConfirmMap.vue'
import ConfirmToolLink from '@/components/sidePanels/confirm/ConfirmToolLink.vue'
import HostMembersInfo from '@/components/sidePanels/confirm/schedule/HostMembersInfo.vue'
import ConfirmTimeZoneSelect from '@/components/sidePanels/confirm/atoms/ConfirmTimeZoneSelect.vue'
import ConfirmCandidateListBox from '@/components/sidePanels/confirm/ConfirmCandidateListBox.vue'
import Divider from '@/components/atoms/Divider.vue'
import candidateDates from '@/filters/candidateDates'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import AppModule from '@/store/modules/app'
import { extractAndConstructCandidates } from '@/lib/utils'
import TitleAndLanguage from '@/components/sidePanels/confirm/TitleAndLanguage.vue'

@Component({
  components: {
    ConfirmItemSpacer,
    ConfirmTitle,
    HostMembersInfo,
    ConfirmDescription,
    ConfirmMap,
    ConfirmToolLink,
    Divider,
    ConfirmCandidateBlockTitleBox,
    ConfirmTimeZoneSelect,
    ConfirmCandidateListBox,
    TitleAndLanguage
  }
})
export default class ScheduleConfirmOperationBox extends Vue {
  @Prop() candidates: FullCalendarEvent[]
  @Prop() schedule: ScheduleModel
  @Prop() authorInfo: SpirUser
  @Prop({ default: false }) isPreview: boolean
  @Prop({ default: false }) isSyncing: boolean
  @Prop() onCandidateSelect: (payload: FullCalendarEvent) => Promise<void>
  @Prop() onCandidateHover: (payload: FullCalendarEvent) => Promise<void>

  get title(): string {
    return this.schedule.title
  }
  get author(): SpirUser {
    return this.authorInfo
  }
  get attendees(): Attendee[] {
    return this.schedule.attendees || []
  }
  get description(): string {
    return this.schedule.description
  }
  get location(): string {
    return this.schedule.location
  }
  get hasOnlineMeeting(): boolean {
    return this.schedule.onlineMeeting.type !== 'none'
  }
  get dateRange(): string {
    return candidateDates(this.schedule)
  }
  get duration(): number {
    return this.schedule.duration
  }
  get isNarrow(): boolean {
    return AppModule.isNarrow
  }
  get eventsForList() {
    return extractAndConstructCandidates(this.candidates, this.schedule)
  }
  handleCalendarOpen() {
    AppScreenControlModule.toggleScheduleEventsList(false)
  }
  async handleCandidateSelect(candidate: FullCalendarEvent) {
    await this.onCandidateSelect(candidate)
  }
  async handleCandidateHover(candidate: FullCalendarEvent) {
    await this.onCandidateHover(candidate)
  }
}
