import { guardPoll, PollForGuard } from '@/lib/utils/pollRoutingGuard'
import { RouteNames } from '@/router'
import EditPollModule from '@/store/modules/editPoll'
import { NavigationGuardNext, Route } from 'vue-router'

export default async (to: Route, _, next: NavigationGuardNext) => {
  const toRoute: RouteNames = to.name as RouteNames
  const pollId = to.params.id
  async function getEditingPoll(): Promise<PollForGuard> {
    await EditPollModule.setPollAsEditingPoll({ pollId })
    const poll = EditPollModule.editingPoll
    return { id: poll.id, isInvalid: poll.isInvalid, status: poll.status, isMine: EditPollModule.isMine }
  }
  const redirectInfo = await guardPoll({ to: toRoute, getEditingPoll })
  return redirectInfo ? next({ name: redirectInfo.name, query: redirectInfo.query }) : next()
}
