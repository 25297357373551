import { extend, configure } from 'vee-validate'
import { required, email, min, max, excluded } from 'vee-validate/dist/rules'
import i18n from '@/i18n'

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`message.error.validation.${values._rule_}`, { ...values, attr: field })
  }
})

extend('email', email)
extend('required', required)
extend('min', min)
extend('max', max)
extend('emailExcluded', excluded)
