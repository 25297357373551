














































import { Component, Prop, Vue } from 'vue-property-decorator'
import CardBodyItem from '@/components/card/atoms/CardBodyItem.vue'

@Component({
  components: {
    CardBodyItem
  }
})
export default class Card extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop() icon?: string
  @Prop() title: string
  @Prop({ default: false }) isDraggable: boolean
  @Prop({ default: false }) isActive: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: 'is-white' }) type: 'is-white' | 'is-danger'

  get headerIcon() {
    return this.isDraggable ? 'drag' : this.icon
  }
  get hasFooter() {
    return !!this.$slots.actions
  }
}
