

































import { FullCalendarEvent, Candidate, CandidateStatus, ScheduleStatusUpdateActionName, isActiveStatus } from '@/types'
import EditScheduleModule from '@/store/modules/editSchedule'
import Common from '@/views/calendar/Common.vue'
import SuggestAlternativeCandidateModule from '@/store/modules/suggestAlternativeCandidate'
import { parseISO } from 'date-fns'
import { ScheduleSource } from '@/types'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import CalendarControlModule from '@/store/modules/calendarControl'
import { isMobile } from '@/lib/utils'
import SidePanel from '@/components/page/ScheduleConfirmPage/SuggestDate/_SidePanel/index.vue'
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { useTranslation } from '@/composables/useTranslation'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { useToast } from '@/composables/useToast'
import { useScheduleMixin } from '@/composables/useScheduleMixin'
import UserModule from '@/store/modules/user'
import { useSuggestDateActionFunctions } from '@/components/page/ScheduleConfirmPage/_useSuggestDateActionFunctions'
import { AllRouteNames } from '@/router/index'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'

export default defineComponent({
  components: {
    Common,
    ConfirmPageHeader,
    SidePanel
  },
  setup() {
    const isLoading = ref(true)
    const firstDate = ref(new Date())
    const isSignIn = computed(() => UserModule.isSignIn)
    const i18n = useTranslation()
    const { openDangerBottomToast, openInfoTopToast } = useToast()
    const router = useRouter()
    const route = useRoute()
    const { isNarrow, getEditingEventByCalendarFormat, isSyncing, scheduleId, showToastIfScheduleIsSyncing } =
      useScheduleMixin()
    const isOrganizer = computed(() => EditScheduleModule.isMine)
    const { handleUpdateEvent, handleAddSuggestingCandidate, onEventDelete, onConfirmButtonClick, toConfirmPage } =
      useSuggestDateActionFunctions({ isLoading, isOrganizer, scheduleId })
    const alternativeCandidates = computed(() => SuggestAlternativeCandidateModule.alternativeCandidates)
    const additionalEvents = computed(() => {
      const alternativeCandidateEvents = alternativeCandidates.value
        .sort((c1: Candidate, c2: Candidate) => {
          return c1.start > c2.start ? 1 : -1
        })
        .map((c: Candidate, index: number) => {
          const event = {
            id: c.id,
            start: parseISO(c.start),
            end: parseISO(c.end),
            title: `${i18n.t('labels.candidate').toString()} - ${index + 1}`,
            extendedProps: { source: 'candidate' as ScheduleSource }
          }
          return event
        })
      const currentCandidateEvents = getEditingEventByCalendarFormat.value.map((event: FullCalendarEvent) => {
        return {
          ...event,
          title: i18n.t('labels.rejected').toString(),
          editable: false,
          extendedProps: { ...event.extendedProps, source: 'rejected' as ScheduleSource }
        }
      })
      return alternativeCandidateEvents.concat(currentCandidateEvents)
    })

    const editingSchedule = computed(() => EditScheduleModule.editingSchedule)
    const canSuggest = computed(() => {
      return editingSchedule.value.actions?.some(
        (action) =>
          action.name.toString() === ScheduleStatusUpdateActionName.organizerSuggest ||
          action.name.toString() === ScheduleStatusUpdateActionName.confirmerSuggest
      )
    })

    function initSuggestPage() {
      if (!canSuggest) {
        openDangerBottomToast({ message: i18n.t('message.errorCommon').toString() })
        toConfirmPage()
        return
      }
      SuggestAlternativeCandidateModule.reset()
      // すでに登録された代替日を編集するため、代替日をSuggestAlternativeCandidateModuleに移す
      // 自分が作った候補日のみ編集対象とする
      editingSchedule.value.candidates
        .filter((c: Candidate) => {
          if (isOrganizer.value) {
            return c.status === CandidateStatus.suggestedByOrganizer
          } else {
            return c.status === CandidateStatus.suggestedByConfirmer
          }
        })
        .forEach((c: Candidate) => {
          EditScheduleModule.removeCandidate(c.id)
          SuggestAlternativeCandidateModule.addCandidate({
            id: c.id,
            start: parseISO(c.start),
            end: parseISO(c.end)
          })
        })

      openInfoTopToast({ message: i18n.t('alternativeCandidates.toastMessage').toString() })

      // set current date
      const startDate = editingSchedule.value.candidates.filter((c) => isActiveStatus(c.status))[0]?.start
      if (startDate) {
        firstDate.value = parseISO(startDate)
        CalendarControlModule.setCurrentDate(firstDate.value)
      }
    }

    onMounted(async () => {
      const scheduleId = route.params.id
      try {
        isLoading.value = true
        await EditScheduleModule.setScheduleAsEditingSchedule({ scheduleId: scheduleId })
        if (EditScheduleModule._editingSchedule.status === 'deleted') {
          throw 'deleted'
        }
        showToastIfScheduleIsSyncing()
        initSuggestPage()
      } catch (e) {
        let errorMessage = i18n.t('message.errorCommon').toString()
        switch (e) {
          case 'deleted':
            errorMessage = i18n.t('message.error.deleted').toString()
            break
          case 'noSchedule':
            errorMessage = i18n.t('message.error.noSchedule').toString()
            break
        }
        openDangerBottomToast({ message: errorMessage })
        router.push({ name: AllRouteNames.Main }).catch(GuardExceptionHandlers.noopAgainstRedirection)
      } finally {
        isLoading.value = false
      }
    })

    return {
      editingSchedule,
      firstDate,
      isLoading,
      additionalEvents,
      isSyncing,
      isNarrow,
      isMobile: isMobile(),
      isSignIn,
      handleUpdateEvent,
      handleAddSuggestingCandidate,
      onEventDelete,
      onConfirmButtonClick
    }
  }
})
