






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    desc: {
      type: String,
      required: true
    },
    linkName: {
      type: String,
      required: true
    }
  }
})
