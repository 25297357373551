





































import PanelHeaderButton from '@/components/atoms/PanelHeaderButton.vue'
import FormForPrivate from '@/components/event/sidePanels/FormForPrivate.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { FromGoogleEvent } from '@/lib/utils'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import AppModule from '@/store/modules/app'
import CalendarsModule from '@/store/modules/calendars'
import EditEventModule, { UpdateResult } from '@/store/modules/editEvent'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import CalendarMixin from '../CalendarMixin'
import Common from '../Common.vue'

@Component({
  components: {
    Common,
    FormForPrivate,
    PanelHeaderButton
  }
})
export default class EventLayout extends mixins(CalendarMixin) {
  @Prop() timeZone: string | undefined

  get writableCalendars() {
    return CalendarsModule.writableCalendars
  }
  get isCreatePage() {
    return this.$route.name === 'CreateEvent'
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  get targetObject() {
    return EditEventModule.editingEvent
  }
  get getEditingEventByCalendarFormat() {
    return this.targetObject ? [FromGoogleEvent.convertToFullCalendarEvent(this.targetObject)] : []
  }
  get isNarrowAndCalendarView() {
    return this.isNarrow && !this.isScheduleEventListOpen
  }
  get confirmButtonTitle() {
    return this.isNarrowAndCalendarView ? this.$t('buttons.select') : this.$t('buttons.save')
  }
  // life cycle
  beforeDestroy() {
    this.toggleScheduleEventsList()
    EditEventModule.initEditingEvent()
  }
  cancel() {
    this.$router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
  }
  async handleUpdateEditingObject(newEvent) {
    const result = await EditEventModule.update(newEvent)
    if (result === UpdateResult.onlineMtgUpdated) {
      this.$buefy.toast.open({
        type: 'is-info',
        position: 'is-bottom',
        message: this.$t('message.changeOnlineMtgUnavailable').toString()
      })
    }
  }
  async handleConfirmEvent() {
    if (this.isNarrowAndCalendarView) {
      this.toggleScheduleEventsList(true)
      return
    }
    try {
      await EditEventModule.saveEditingEventObject()
      await this.$analytics.send(SignalType.CREATE_EVENT, { from: this.$route.query.from as string })
      this.$buefy.toast.open({
        type: 'is-primary',
        position: 'is-top',
        message: `${this.$t('message.success.savedEvent')}`
      })
      this.$router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
    } catch (e) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-top',
        message: `${this.$t('message.errorCommon')}`
      })
    }
  }
  addEvent(payload) {
    EditEventModule.updateDate(payload)
  }
  handleUpdateEvent({ event }) {
    this.addEvent(event)
  }
  async handleDelete() {
    if (
      await this.deleteEventItem({
        accountId: EditEventModule.editingEvent.accountId,
        calendarId: EditEventModule.editingEvent.calendarId,
        eventId: EditEventModule.editingEvent.id
      })
    ) {
      EditEventModule.initEditingEvent()
      this.$router.push({ name: 'Main' })
    }
  }
}
