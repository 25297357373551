




































































import SpirButton from '@/components/ui/Button.vue'
import {
  PersonalInfo,
  PersonalTeamValue,
  TeamInfo
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import TitleDropdown from '@/components/ui/domain/dropdown/TitleDropdown/index.vue'
import ArrangementFormSidePanelFooterButtons from '@/components/ui/domain/footer/ArrangementFormSidePanelFooterButtons.vue'
import { ArrangementTypeSectionState } from '@/components/ui/domain/section/arrangement/types'
import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import ArrangementPeekCandidatesConditionSidePanel from '@/components/ui/domain/sidePanel/arrangement/ArrangementPeekCandidatesConditionSidePanel.vue'
import NewArrangementFormBody from '@/components/ui/domain/sidePanel/arrangement/NewArrangementPanel/NewArrangementFormBody.vue'
import SidePanelsWrapper from '@/components/ui/domain/sidePanel/arrangement/SidePanelsWrapper.vue'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFooterFrame from '@/components/ui/page/SidePanelFooterFrame.vue'
import SidePanelFrame from '@/components/ui/page/SidePanelFrame.vue'
import SidePanelHeaderFrame from '@/components/ui/page/SidePanelHeaderFrame.vue'
import { ListType } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import FormHeader from './_FormHeader.vue'
import OverviewBox from './_OverviewBox.vue'
import { BottomNavHeightState, bottomNavHeightStates } from './_type'

export default defineComponent({
  name: 'NewArrangementsFormPanel',
  components: {
    ArrangementFormSidePanelFooterButtons,
    SidePanelsWrapper,
    ArrangementPeekCandidatesConditionSidePanel,
    FormHeader,
    SidePanelFrame,
    SidePanelHeaderFrame,
    SidePanelFooterFrame,
    NewArrangementFormBody,
    ValidationObserver,
    ListItemSpacer,
    SpirButton,
    TitleDropdown,
    OverviewBox
  },
  props: {
    isPeekCandidateSectionOpened: {
      type: Boolean,
      default: false
    },
    heightState: {
      type: String as PropType<BottomNavHeightState>,
      required: true
    },
    formRequest: {
      type: Object as PropType<ArrangementFormRequest>,
      required: true
    },
    formData: {
      type: Object as PropType<ArrangementTypeFormData>,
      required: true
    },
    personal: {
      type: Object as PropType<PersonalInfo>,
      required: true
    },
    teams: {
      type: Array as PropType<TeamInfo[]>,
      required: true
    },
    personalTeamValue: {
      type: String as PropType<PersonalTeamValue>,
      required: true
    },
    arrangementType: {
      type: String as PropType<ListType>,
      required: true
    },
    state: {
      type: String as PropType<ArrangementTypeSectionState>,
      default: 'allOk'
    },
    onPersonalTeamValueChange: {
      type: Function as PropType<(newValue: PersonalTeamValue) => void>,
      required: true
    },
    onArrangementTypeChange: {
      type: Function as PropType<(listType: ListType) => void>,
      required: true
    },
    onPreviewOpen: {
      type: Function as PropType<() => void>,
      required: true
    },
    onDetailToggle: {
      type: Function as PropType<() => void>,
      required: true
    },
    onSave: {
      type: Function as PropType<() => void>,
      required: true
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const isPanelShrinked = computed(() => props.heightState !== bottomNavHeightStates.EXPANDED)
    const isCrushed = computed(() => props.heightState === bottomNavHeightStates.CRUSHED)
    return {
      isPanelShrinked,
      isCrushed
    }
  }
})
