









import { Component, Prop, Vue } from 'vue-property-decorator'
import CollapseWithChevronHeader from '@/components/atoms/CollapseWithChevronHeader.vue'

@Component({
  components: {
    CollapseWithChevronHeader
  }
})
export default class CalendarListCalendarPanel extends Vue {
  @Prop() title: string
}
