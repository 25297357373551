









import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { FullCalendarEvent, ISchedule } from '@/types'
import BasePreviewScheduleModal from './schedulePreview/BasePreviewScheduleModal.vue'

@Component({
  components: {
    BasePreviewScheduleModal
  }
})
export default class PreviewPrivateScheduleModal extends Vue {
  @PropSync('open', { type: Boolean }) openSynced!: boolean
  @Prop() schedule: ISchedule
  @Prop() candidates: FullCalendarEvent[]
}
