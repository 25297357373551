










import { addMinutes } from 'date-fns'
import { Component, Prop, Vue } from 'vue-property-decorator'
import EventFormDateInput from './EventFormDateInput.vue'
import EventFormDateInputAllDay from './EventFormDateInputAllDay.vue'

@Component({
  components: {
    EventFormDateInput,
    EventFormDateInputAllDay
  }
})
export default class EventFormDateInputWrapper extends Vue {
  @Prop() startDate: Date
  @Prop() endDate: Date
  @Prop() allDay: boolean
  @Prop() timeZone: string | undefined
  @Prop({ required: true }) onDateUpdate: (data: { start: Date; end: Date }) => void

  debounceUpdate: Function

  get dateInputComponent() {
    if (this.allDay) {
      return EventFormDateInputAllDay
    }
    return EventFormDateInput
  }
  onUpdateDate({ start, end }: { start: Date; end: Date }) {
    const payload = {
      start,
      end
    }
    this.updateDate(payload)
  }
  updateDate(payload: { start: Date; end: Date }) {
    this.onDateUpdate(payload)
  }
  get minEndDate() {
    if (!this.startDate) {
      return null
    }
    return addMinutes(this.startDate, 15)
  }
}
