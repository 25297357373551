


























import { Component, Prop, Vue } from 'vue-property-decorator'
import ProfileContentModal from '@/components/modal/ProfileContent.vue'
import UserBadge from '@/components/molecules/UserBadge.vue'
import AttendeeIcon from '@/components/atoms/AttendeeIcon.vue'
import { SpirAttendeeStatus, ProfileOnOverview } from '@/types'

@Component({
  components: {
    UserBadge,
    AttendeeIcon
  }
})
export default class AttendeeWithProfileButton extends Vue {
  @Prop() attendeeInfo: ProfileOnOverview
  @Prop({ default: true }) isStatusIconON: boolean

  get fullName() {
    return this.attendeeInfo.fullName
  }
  get photoURL() {
    return this.attendeeInfo.photoURL
  }
  get isLoading() {
    return this.attendeeInfo.isLoading
  }
  get email() {
    return this.attendeeInfo.email
  }

  get userId() {
    return this.attendeeInfo.userId || null
  }

  get attendanceStatus() {
    return this.attendeeInfo.attendanceStatus || SpirAttendeeStatus.ACCEPTED
  }
  get isAuthor() {
    return this.attendeeInfo.isAuthor || false
  }
  openCreatorProfile() {
    this.$emit('clickProfile')
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ProfileContentModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        id: this.userId
      },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
}
