















import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import CalendarModule from '@/store/modules/calendars'
import { AvailabilityCalendarType } from '@spirinc/contracts'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import TargetSettingItem from './_TargetSettingItem.vue'

export default defineComponent({
  name: 'SelectedTargetAttendees',
  components: {
    FormItemSpacer,
    TargetSettingItem
  },
  props: {
    label: {
      type: String,
      required: true
    },
    attendees: {
      type: Array as PropType<AvailabilityCalendarType[]>,
      required: true
    },
    baseList: {
      type: Array as PropType<AvailabilityCalendarType[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { baseList } = toRefs(props)
    return {
      getCalendar: (attendee: AvailabilityCalendarType) => {
        return CalendarModule.getCalendar(attendee)
      },
      onToggleCalendar: (attendee: AvailabilityCalendarType) => {
        emit('onToggleCalendar', attendee)
      },
      isCheckedCalendar: (attendee: AvailabilityCalendarType) => {
        return baseList.value.some((c) => c.accountId === attendee.accountId && c.calendarId === attendee.calendarId)
      }
    }
  }
})
