import Vue from 'vue'
import Component from 'vue-class-component'
import firebase from 'firebase/app'
import { AuthFrom, SessionStorage } from '@/store/lib/sessionStorage'
import { RouteNames } from '@/router/index'

@Component
export default class AuthenticationMixin extends Vue {
  async signInWithProvider(provider) {
    try {
      await firebase.auth().signInWithRedirect(provider)
    } catch (e: any) {
      if (e.code && e.code === 'auth/account-exists-with-different-credential') {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
          message: this.$t('message.error.accountExistsInAnotherCredential').toString()
        })
      } else {
        throw new Error(e)
      }
    }
  }
  async signInOrSignUpWithGoogle() {
    SessionStorage.setAuthAction({
      action: 'SignInOrSignUp',
      from: this.getAuthFrom(),
      id: this.$route.params.id,
      type: 'Google'
    })
    return this.authWithGoogle()
  }
  async signUpWithGoogle() {
    SessionStorage.setAuthAction({
      action: 'SignUp',
      from: this.getAuthFrom(),
      id: this.$route.params.id,
      type: 'Google'
    })
    return this.authWithGoogle()
  }
  async loginWithGoogle() {
    SessionStorage.setAuthAction({
      action: 'SignIn',
      from: this.getAuthFrom(),
      id: this.$route.params.id,
      type: 'Google'
    })
    return this.authWithGoogle()
  }
  private authWithGoogle() {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    googleProvider.setCustomParameters({
      prompt: 'select_account'
    })
    return this.signInWithProvider(googleProvider)
  }
  async signInWithMicrosoft() {
    const msProvider = new firebase.auth.OAuthProvider('microsoft.com')
    msProvider.setCustomParameters({
      prompt: 'select_account'
    })
    return this.signInWithProvider(msProvider)
  }
  async signInOrSignUpWithMicrosoft() {
    SessionStorage.setAuthAction({
      action: 'SignInOrSignUp',
      from: this.getAuthFrom(),
      id: this.$route.params.id,
      type: 'Microsoft'
    })
    return this.authWithMicrosoft()
  }
  async signUpWithMicrosoft() {
    SessionStorage.setAuthAction({
      action: 'SignUp',
      from: this.getAuthFrom(),
      id: this.$route.params.id,
      type: 'Microsoft'
    })
    return this.authWithMicrosoft()
  }
  async loginWithMicrosoft() {
    SessionStorage.setAuthAction({
      action: 'SignIn',
      from: this.getAuthFrom(),
      id: this.$route.params.id,
      type: 'Microsoft'
    })
    return this.authWithMicrosoft()
  }
  private authWithMicrosoft() {
    const msProvider = new firebase.auth.OAuthProvider('microsoft.com')
    msProvider.setCustomParameters({
      prompt: 'select_account'
    })
    return this.signInWithProvider(msProvider)
  }

  private getAuthFrom(): AuthFrom {
    const routeName = this.$route.name as RouteNames | undefined
    if (routeName === 'ConfirmSchedule' || routeName === 'SuggestAlternativeDate') {
      return 'Schedule'
    }
    if (routeName === 'VotePoll') {
      return 'GroupPoll'
    }
    if (routeName === 'AvailabilityConfirm') {
      return 'PublicUrl'
    }
    if (routeName === 'TeamMemberInvited') {
      return 'TeamMemberInvited'
    }
    return 'TopPage'
  }
}
