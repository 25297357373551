import { FrontSupportLanguage } from '@/types'
export class GuestConfirmer {
  readonly type: 'guest' = 'guest'
  readonly email: string
  readonly name: string
  readonly timeZone: string
  readonly language: FrontSupportLanguage

  constructor({ email, name, timeZone, language }: { email: string, name: string, timeZone: string, language: FrontSupportLanguage }) {
    this.email = email
    this.name = name
    this.timeZone = timeZone
    this.language = language
  }

  getLanguage() {
    return this.language
  }

  getEmail() {
    return this.email
  }

  getName() {
    return this.name
  }
}
