

























import { Component, Vue, Prop } from 'vue-property-decorator'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'

@Component({
  components: {
    CommonLayoutModal
  }
})
export default class OnlineMeetingToolInfoModal extends Vue {
  @Prop() accountName: string
}
