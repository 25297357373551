import firebase from 'firebase/app'
import { createUser } from '@/lib/api/user'
import TimezonesModule from '@/store/modules/timezones'
import FeatureTogglerModule from '@/store/modules/featureToggler'
import AppModule from '@/store/modules/app'
import { APIError } from '@/lib/sdk/error'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { authErrorTypes, AuthErrorTypes } from '@/types/authError'
import { useAuthAuthcode } from './useAuthAuthcode'
import { useAddGoogleCalenarWithRedirect } from './useAddGoogleCalenarWithRedirect'
import { useAuthSessionStorage } from './useAuthSessionStorage'
import { captureException } from '@sentry/vue'
import ProfileModule from '@/store/modules/profile'

export type SignUpSuccess = {
  result: 'success' | 'signin' | 'cancel'
}
export type SignUpErrors = {
  result: 'error'
  errorCode: AuthErrorTypes
}
export type ErrorInRegisterCalendar = {
  result: 'errorInRegisterCalendar'
  errorCode: AuthErrorTypes
}
export type SignUpResult = SignUpSuccess | SignUpErrors | ErrorInRegisterCalendar

export const useAuthSignUp = () => {
  const { setSession } = useAuthSessionStorage()
  const { getGoogleAuthCodeByRedirect, registerAuthcode } = useAuthAuthcode()
  const { parseGoogleAuthResult } = useAddGoogleCalenarWithRedirect()
  const initSpir = async () => {
    FeatureTogglerModule.initialize()
    await AppModule.initApp()
  }

  const authWithMicrosoft = async () => {
    const msProvider = new firebase.auth.OAuthProvider('microsoft.com')
    msProvider.setCustomParameters({
      prompt: 'select_account'
    })
    return firebase.auth().signInWithRedirect(msProvider)
  }
  const signUpWithGoogle = () => {
    setSession({ action: 'SignUp', type: 'Google' })
    return getGoogleAuthCodeByRedirect()
  }
  const signUpWithMicrosoft = () => {
    setSession({ action: 'SignUp', type: 'Microsoft' })
    return authWithMicrosoft()
  }
  const signUpToFirebase = async (
    signUpFunction: () => Promise<firebase.auth.UserCredential>
  ): Promise<SignUpResult> => {
    let firebaseSignUpResponse = null
    try {
      // firebase にユーザーを登録する
      firebaseSignUpResponse = await signUpFunction()
    } catch (e: any) {
      if (e?.code === 'auth/account-exists-with-different-credential') {
        return {
          result: 'error',
          errorCode: authErrorTypes.accountExistsInAnotherCredential
        }
      }
      captureException(e)
      return {
        result: 'error',
        errorCode: authErrorTypes.unknown
      }
    }
    if (!firebaseSignUpResponse.additionalUserInfo?.isNewUser) {
      return {
        result: 'signin'
      }
    }
    try {
      const response = await createUser({
        timeZone: TimezonesModule.localTimezoneInfo.key,
        language: ProfileModule.getLanguage
      })
      if (!response.data?.id) {
        throw new Error('error in creating user to spir')
      }
      return {
        result: 'success'
      }
    } catch (e: any) {
      //FB からユーザーを消す
      await firebase.auth().signOut()
      await firebaseSignUpResponse.user.delete()
      if (e instanceof APIError && e.code === ERROR_CODE.ACCOUNT_DUPLICATED) {
        return {
          result: 'error',
          errorCode: authErrorTypes.accountDuplicated
        }
      }
      captureException(e)
      return {
        result: 'error',
        errorCode: authErrorTypes.unknown
      }
    }
  }
  // callbackParam: redirect=/#code=4/0AX4XfWgjcT5iJHKDEGV--cL52GFXl_Rte6Z4K4DX…P99VlBrNE5MzvqI-5Ct-pCA&authuser=0&prompt=consent
  const signUpAndAddCalendarToGoogle = async (fullPath: string): Promise<SignUpResult> => {
    const parseFullPath = parseGoogleAuthResult(fullPath)
    if (parseFullPath.result !== 'ok') {
      return parseFullPath
    }

    const credential = firebase.auth.GoogleAuthProvider.credential(parseFullPath.parseResult.id_token)
    try {
      const signUpResponse = await signUpToFirebase(() => firebase.auth().signInWithCredential(credential))
      if (signUpResponse.result === 'cancel' || signUpResponse.result === 'error') {
        return signUpResponse
      }
    } catch (e) {
      return {
        result: 'error',
        errorCode: 'unknown'
      }
    }
    const registerResponse = await registerAuthcode(parseFullPath.parseResult.code)
    if (registerResponse.result === 'error') {
      await initSpir()
      return {
        result: 'errorInRegisterCalendar',
        errorCode: registerResponse.errorCode
      }
    }
    await initSpir()
    return {
      result: 'success'
    }
  }
  return {
    signUpWithGoogle,
    signUpWithMicrosoft,
    signUpAndAddCalendarToGoogle
  }
}
