












import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItemLayout from './FormItemLayout.vue'
@Component({
  components: {
    FormItemLayout
  }
})
export default class FormCollapseButton extends Vue {
  @Prop() title: string
  @Prop() collapseId!: string

  open = false
}
