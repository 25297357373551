



















import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import DisplayItem from './molecules/Item.vue'
import oneDateRange from '@/filters/oneDateRange'
import TimezoneLabel from '@/components/molecules/TimezoneLabel.vue'
import TimezoneModule from '@/store/modules/timezones'
import SpirButton from '@/components/ui/Button.vue'

export default defineComponent({
  components: {
    DisplayItem,
    TimezoneLabel,
    SpirButton
  },
  props: {
    start: {
      type: Date,
      required: true
    },
    end: {
      type: Date,
      required: true
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true
    },
    onReadjustment: {
      // デザイン確認のため。実装はまだ未定。
      type: Function as PropType<() => void>
    }
  },
  setup(props) {
    const { start, end } = toRefs(props)
    const formattedDate = computed(() => {
      return oneDateRange({ startDate: start.value, endDate: end.value, hideYear: true })
    })
    const userTimezoneInfo = computed(() => {
      return TimezoneModule.userTimezoneInfo
    })
    const canReadjustment = computed(() => {
      return !!props.onReadjustment
    })
    const cancel = () => {
      props.onCancel()
    }
    return {
      formattedDate,
      userTimezoneInfo,
      canReadjustment,
      cancel
    }
  }
})
