














import { Component, Vue } from 'vue-property-decorator'
import IconButton from '@/components/atoms/IconButton.vue'
import SendPasswordResetEmail from '@/components/molecules/auth/SendPasswordResetEmail.vue'
import ChangeAuthRoute from '@/components/atoms/ChangeAuthRoute.vue'
import DescriptionForInAppBrowser from '@/components/atoms/DescriptionForInAppBrowser.vue'
import AuthLayout from './Layout.vue'
import { isNotSupported as notSupported } from '@/lib/utils'

@Component({
  components: {
    AuthLayout,
    SendPasswordResetEmail,
    ChangeAuthRoute,
    IconButton,
    DescriptionForInAppBrowser
  }
})
export default class SendPasswordResetEmailView extends Vue {
  get isNotSupported() {
    return notSupported()
  }
}
