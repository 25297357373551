









import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { FullCalendarEvent, SpirUser } from '@/types'
import BasePreviewScheduleModal from './schedulePreview/BasePreviewScheduleModal.vue'
import { FromOrganizerInfo } from '@/lib/utils'
import { ScheduleModel } from '@/models/data'

@Component({
  components: {
    BasePreviewScheduleModal
  }
})
export default class PreviewTeamScheduleModal extends Vue {
  @PropSync('open', { type: Boolean }) openSynced!: boolean
  @Prop() schedule: ScheduleModel
  @Prop() candidates: FullCalendarEvent[]

  get organizer(): SpirUser {
    return FromOrganizerInfo.convertToSpirUser(this.schedule.organizerInfo)
  }
}
