













import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { defineComponent, PropType, computed } from '@vue/composition-api'
import ConditionIncludedCalendarsAndMembersAutocomplete from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/index.vue'
import {
  SelectedMemberType,
  ListSetForUI,
  AvailableAutocompleteListType,
  AutocompleteMember,
  MemberList
} from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'

export default defineComponent({
  name: 'FormAttendingCandidateMemberItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    ConditionIncludedCalendarsAndMembersAutocomplete
  },
  props: {
    selectedMemberTypes: {
      type: Array as PropType<SelectedMemberType[]>,
      required: true
    },
    availableMemberList: {
      type: Array as PropType<MemberList[]>,
      required: true
    },
    onMemberAdd: {
      type: Function as PropType<(selectedMember: AutocompleteMember) => void>,
      required: true
    },
    onMemberDelete: {
      type: Function as PropType<(deletedMember: AutocompleteMember) => void>,
      required: true
    }
  },
  setup(props) {
    const listSetForUI = computed((): ListSetForUI => {
      const selectedMemberItems: AutocompleteMember[] = props.availableMemberList
        .filter((ml) => props.selectedMemberTypes.find((sm) => sm.memberId === ml.member.id))
        .map((item) => ({
          type: 'member',
          teamId: item.teamId,
          listItem: item.member
        }))
      const targetMemberItems: AutocompleteMember[] = props.availableMemberList
        .filter((ml) => !props.selectedMemberTypes.find((sm) => sm.memberId === ml.member.id))
        .map((item) => ({
          type: 'member',
          teamId: item.teamId,
          listItem: item.member
        }))
      return {
        selectedCalendars: selectedMemberItems,
        targetCalendars: targetMemberItems
      }
    })
    function handleCalendarAdd(addedItem: AvailableAutocompleteListType) {
      if (addedItem.type === 'member') {
        props.onMemberAdd(addedItem)
      }
    }
    function handleCalendarDelete(deletedItem: AvailableAutocompleteListType) {
      if (deletedItem.type === 'member') {
        props.onMemberDelete(deletedItem)
      }
    }
    return {
      listSetForUI,
      handleCalendarAdd,
      handleCalendarDelete
    }
  }
})
