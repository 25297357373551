import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { FullCalendarEvent } from '@/types'
import store from '@/store'

@Module({
  dynamic: true,
  name: 'EventToConfirm',
  namespaced: true,
  store
})
class EventToConfirm extends VuexModule {
  _event: FullCalendarEvent | null = null
  _isEditingAlternativeCandidatesSuggestions = false
  _editingCandidateId: string | null = null

  get eventToConfirm() {
    return this._event
  }
  get isEditingAlternativeCandidatesSuggestions() {
    return this._isEditingAlternativeCandidatesSuggestions
  }
  get editingCandidateId() {
    return this._editingCandidateId
  }

  @Mutation
  UPDATE_EVENT(event: FullCalendarEvent | null) {
    this._event = event
  }
  @Mutation
  START_EDITING_ALTERNATIVE_CANDIDATES_SUGGESTIONS() {
    this._isEditingAlternativeCandidatesSuggestions = true
  }
  @Mutation
  CANCEL_EDITING_ALTERNATIVE_CANDIDATES_SUGGESTIONS() {
    this._isEditingAlternativeCandidatesSuggestions = false
  }
  @Mutation
  SET_EDITING_CANDIDATE(candidateId: string | null) {
    this._editingCandidateId = candidateId
  }

  @Action
  updateEventToConfirm(eventToConfirm: FullCalendarEvent | null) {
    this.UPDATE_EVENT(eventToConfirm)
  }
  @Action
  resetEventToConfirm() {
    this.UPDATE_EVENT(null)
  }
  @Action
  startEditingAlternativeCandidatesSuggestions() {
    this.START_EDITING_ALTERNATIVE_CANDIDATES_SUGGESTIONS()
  }
  @Action
  cancelEditingAlternativeCandidatesSuggestions() {
    this.CANCEL_EDITING_ALTERNATIVE_CANDIDATES_SUGGESTIONS()
  }
  @Action
  setEditingCandidate(candidateId: string | null) {
    this.SET_EDITING_CANDIDATE(candidateId)
  }
}

export default getModule(EventToConfirm)
