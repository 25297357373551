



















import Divider from '@/components/atoms/Divider.vue'
import {
  PersonalInfo,
  PersonalTeamValue,
  TeamInfo
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import ArrangementTypeSection from '@/components/ui/domain/section/arrangement/ArrangementTypeSection.vue'
import { ArrangementTypeSectionState } from '@/components/ui/domain/section/arrangement/types'
import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import PersonalPollSections from '@/components/ui/domain/sections/arrangement/PersonalPollSections.vue'
import PersonalScheduleSections from '@/components/ui/domain/sections/arrangement/PersonalScheduleSections.vue'
import TeamScheduleSections from '@/components/ui/domain/sections/arrangement/TeamScheduleSections.vue'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import SidePanelBodyFrame from '@/components/ui/page/SidePanelBodyFrame.vue'
import { ListType } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'NewArrangementFormBody',
  components: {
    SidePanelBodyFrame,
    ArrangementTypeSection,
    Divider,
    PersonalScheduleSections,
    TeamScheduleSections,
    PersonalPollSections
  },
  props: {
    personal: {
      type: Object as PropType<PersonalInfo>,
      required: true
    },
    teams: {
      type: Array as PropType<TeamInfo[]>,
      required: true
    },
    personalTeamValue: {
      type: String as PropType<PersonalTeamValue>,
      required: true
    },
    arrangementType: {
      type: String as PropType<ListType>,
      required: true
    },
    state: {
      type: String as PropType<ArrangementTypeSectionState>,
      default: 'allOk'
    },
    onPersonalTeamValueChange: {
      type: Function as PropType<(newValue: PersonalTeamValue) => void>,
      required: true
    },
    onArrangementTypeChange: {
      type: Function as PropType<(listType: ListType) => void>,
      required: true
    },
    formRequest: {
      type: Object as PropType<ArrangementFormRequest>,
      required: true
    },
    formData: {
      type: Object as PropType<ArrangementTypeFormData>,
      required: true
    }
  }
})
