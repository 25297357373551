






import { Component, Vue } from 'vue-property-decorator'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import Skeleton from '@/components/molecules/Skeleton.vue'
@Component({
  components: {
    FromGroup,
    Skeleton
  }
})
export default class ComponentName extends Vue {}
