// for new module
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store from '@/store'
import UserModule from './user'
import { ICalendar } from '../../types'
import { getDailyViewCalendars } from '../../lib/api/calendar'

@Module({
  dynamic: true,
  name: 'DailyView',
  namespaced: true,
  store
})
class DailyView extends VuexModule {
  _calendarsForDailyView: ICalendar[] = []
  _isDailyViewLoading = false

  get calendarsForDailyView() {
    return this._calendarsForDailyView
  }
  get isDailyViewLoading() {
    return this._isDailyViewLoading
  }

  @Mutation
  ON_FETCH_DAILY_CALENDARS(calendars) {
    this._calendarsForDailyView = calendars
  }
  @Mutation
  ON_SET_LOADING(status) {
    this._isDailyViewLoading = status
  }

  @Action
  async fetchCalendarsForDailyView() {
    if (UserModule.isSignIn) {
      this.ON_SET_LOADING(true)
      try {
        const calendars = await getDailyViewCalendars()
        this.ON_FETCH_DAILY_CALENDARS(calendars)
      } finally {
        this.ON_SET_LOADING(false)
      }
    }
  }
}

export default getModule(DailyView)
