






import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from './molecules/Item.vue'
import HtmlDisplay from '@/components/molecules/HtmlDisplay.vue'

@Component({
  components: {
    DisplayItem,
    HtmlDisplay
  }
})
export default class DisplayDescription extends Vue {
  @Prop() item: string
}
