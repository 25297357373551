

















import BottomNavMenu from '@/components/BottomNavMenu/BottomNavMenu.vue'
import BaseLayout from '@/components/layout/Base.vue'
import AddCalendar from '@/components/modal/AddCalendar.vue'
import NavMenu from '@/components/NavMenu/NavMenu.vue'
import BottomNavMenuFrame from '@/components/ui/frame/BottomNavMenuFrame.vue'
import { channelTalkService } from '@/lib/externalServices/channelTalk'
import { INTERVAL_SEC, isMobile } from '@/lib/utils'
import { AllRouteNames, RouteNames } from '@/router'
import AppModule from '@/store/modules/app'
import CalendarsModule from '@/store/modules/calendars'
import ProfileModule from '@/store/modules/profile'
import UserModule from '@/store/modules/user'
import Vue100vh from 'vue-div-100vh'
import { Component, Vue, Watch } from 'vue-property-decorator'

const SHOW_BOTTOM_MENU_ON_MOBILE: RouteNames[] = [
  AllRouteNames.Main,
  AllRouteNames.TeamSettingMyCalendar,
  AllRouteNames.TeamSettingMember,
  AllRouteNames.ScheduleList,
  AllRouteNames.AvailabilityList,
  AllRouteNames.AfterConfirm,
  AllRouteNames.ConfirmedList,
  AllRouteNames.UnconfirmedList
]

@Component({
  components: {
    Vue100vh,
    BaseLayout,
    BottomNavMenu,
    NavMenu,
    BottomNavMenuFrame
  }
})
export default class CalendarHome extends Vue {
  intervalIdForPolling = null
  @Watch('needToAddCalendar')
  moveToAddCalendarPage() {
    this.goToAddCalendarIfThereNoAccount()
  }
  created() {
    if (!isMobile() && ProfileModule.getLanguage === 'ja') {
      channelTalkService.showChannelButton()
    }
  }
  mounted() {
    this.startPolling()
    this.goToAddCalendarIfThereNoAccount()
  }
  beforeDestroy() {
    if (!isMobile()) {
      channelTalkService.hideChannelButton()
    }
    this.stopPolling()
  }
  get isQuickViewVisible() {
    return AppModule.isQuickViewVisible
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get showMobileGlobalNav() {
    const routerName: RouteNames = this.$route.name as RouteNames
    return SHOW_BOTTOM_MENU_ON_MOBILE.indexOf(routerName) >= 0
  }
  get isIdle(): boolean {
    return this.$store.state.idleVue.isIdle
  }
  closeQuickView() {
    AppModule.ON_CLOSE_QUICK_VIEW()
  }
  async polling() {
    if (!this.isIdle) {
      //polling時にエラーになったらとまるので回避
      try {
        await AppModule.pollingEventsAndSchedules()
      } catch (e) {
        throw new Error('error in polling')
      }
    }
    this.intervalIdForPolling = setTimeout(async () => this.polling(), INTERVAL_SEC)
  }
  async startPolling() {
    if (this.intervalIdForPolling) {
      return
    }
    this.polling()
  }
  stopPolling() {
    if (this.intervalIdForPolling) {
      clearTimeout(this.intervalIdForPolling)
      this.intervalIdForPolling = null
    }
  }
  goToAddCalendarIfThereNoAccount() {
    if (this.needToAddCalendar) {
      const confirmPages = [
        'ConfirmSchedule',
        'AvailabilityConfirm',
        'TeamAvailabilitySharingConfirm',
        'TeamScheduleConfirm',
        'VotePoll'
      ]
      const currentRouteName = this.$route.name as RouteNames
      if (confirmPages.indexOf(currentRouteName) >= 0) {
        const modal = this.$buefy.modal.open({
          parent: this,
          component: AddCalendar,
          hasModalCard: true,
          canCancel: false,
          props: {
            isShowCloseButton: false,
            isShowSignOut: true,
            closeModal: () => {
              modal.close()
            }
          }
        })
      } else {
        this.$router.push({ name: AllRouteNames.AddCalendar })
      }
    }
  }
  get needToAddCalendar() {
    const exceptList = ['TeamMemberInvited', 'SignUp', 'SignIn', 'SignOut', 'CallbackFromMS', 'AddCalendar']
    const currentRouteName = this.$route.name as RouteNames
    if (exceptList.indexOf(currentRouteName) >= 0) {
      return false
    }
    return !!ProfileModule.myProfile && !CalendarsModule.getIsCalendarLoading && !CalendarsModule.hasAccount
  }
}
