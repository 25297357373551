


















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ICalendar } from '@/types'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import EmailSelector, { EmailSelectorItem } from '@/components/newForm/atoms/EmailSelector.vue'
import SelectedCalendarList from '@/components/newForm/atoms/SelectedCalendarList.vue'
import { MemberResponse } from '@spirinc/contracts'
@Component({
  components: {
    FormItem,
    EmailSelector,
    SelectedCalendarList
  }
})
export default class FormAttendeeSelectorByEmailWithTeamMember extends Vue {
  @PropSync('selectedAttendee', { type: Array }) selectedAttendeeSynced: { email: string }[]
  @Prop() availableAttendeeList: ICalendar[]
  @Prop() teamMembers: MemberResponse[]

  get filteredAvailableAttendeeList(): EmailSelectorItem[] {
    return [
      ...this.availableAttendeeList.map((c) => {
        return {
          email: c.invitationAddress,
          title: c.title
        }
      }),
      ...this.teamMembers.map((tm) => {
        return {
          email: tm.email,
          title: tm.fullName,
          photoURL: tm.photoUrl
        }
      })
    ]
      .filter((c) => !this.selectedAttendeeSynced.some((a) => a.email === c.email))
      .filter((c, i, arr) => arr.findIndex((c_) => c_.email === c.email) === i)
  }
  get addedEmails() {
    return this.selectedAttendeeSynced.map((se) => se.email)
  }
  handleAddedEmail(addedCalendar: { email: string }) {
    this.selectedAttendeeSynced.push(addedCalendar)
    this.$emit('input')
    this.$emit('added', addedCalendar)
  }
  handleDeleteCalendar(deletedCalendar: { email: string }) {
    const findIndex = this.selectedAttendeeSynced.findIndex((s) => s.email === deletedCalendar.email)
    if (findIndex >= 0) {
      this.selectedAttendeeSynced.splice(findIndex, 1)
      this.$emit('input')
      this.$emit('deleted', deletedCalendar)
    }
  }
}
