











import { Component, Prop, Vue } from 'vue-property-decorator'
import AppModule from '@/store/modules/app'
import { ScheduleModel, ScheduleModelTeam } from '@/models/data'
import UnconfirmedScheduleEmptyList from './UnconfirmedScheduleEmptyList.vue'

@Component({
  components: {
    UnconfirmedScheduleEmptyList
  }
})
export default class UnconfirmedScheduleList extends Vue {
  @Prop() unconfirmedSchedules: ScheduleModel[] | ScheduleModelTeam[]

  get isNarrow() {
    return AppModule.isNarrow
  }
  get isEmpty(): boolean {
    return this.unconfirmedSchedules.length === 0
  }
  get sortedUnconfirmedSchedules() {
    return this.unconfirmedSchedules.sort(
      (a, b) => a.firstCandidateStartDate?.getTime() - b.firstCandidateStartDate?.getTime()
    )
  }
}
