







import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'

@Component({
  components: {
    Avatar
  }
})
export default class UserIcon extends Vue {
  @Prop() userPhoto?: string
  @Prop({ default: '' }) userName: string
  @Prop({ default: 35 }) iconWidth: number

  get photo() {
    return this.userPhoto || require('@/assets/images/account.png')
  }
}
