import ProfileModule from '@/store/modules/profile'

export const useSettings = () => {
  const updateProfile = async (updateFunction: () => Promise<any>) => {
    await updateFunction()
    await ProfileModule.fetchMyProfile()
  }

  return {
    updateProfile
  }
}
