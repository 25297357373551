import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'
import VueI18nPhraseInContextEditor from 'vue-i18n-phrase-in-context-editor'
import config from '@/lib/config'

export const LOCAL_STORAGE_KEY_FOR_TRANSLATE = 'spir-translate-mode'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages
})
if (process.env.VUE_APP_MODE === 'development' || process.env.VUE_APP_MODE === 'local') {
  new VueI18nPhraseInContextEditor(i18n, {
    phraseEnabled: window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_TRANSLATE) === 'true',
    projectId: config.phrase.projectId,
    autoLowercase: false
  })
}
export default i18n
