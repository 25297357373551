




































import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import padStart from 'lodash/padStart'
import { isBefore } from 'date-fns'

@Component
export default class AutoCandidatesTimeRangeForm extends Vue {
  @Prop() timeRange
  localTimeRange = {
    title: '',
    startTime: {
      hour: 9,
      min: 0
    },
    endTime: {
      hour: 11,
      min: 0
    }
  }

  mounted() {
    if (this.timeRange) {
      this.localTimeRange = JSON.parse(JSON.stringify(this.timeRange))
      this.localTimeRange.startTime.hour = Number(this.localTimeRange.startTime.hour)
      this.localTimeRange.startTime.min = Number(this.localTimeRange.startTime.min)
      this.localTimeRange.endTime.hour = Number(this.localTimeRange.endTime.hour)
      this.localTimeRange.endTime.min = Number(this.localTimeRange.endTime.min)
    }
  }

  get isValid() {
    const startTimeHour = Number(this.localTimeRange.startTime.hour)
    const startTimeMin = Number(this.localTimeRange.startTime.min)
    const endTimeHour = Number(this.localTimeRange.endTime.hour)
    const endTimeMin = Number(this.localTimeRange.endTime.min)
    const startTime = new Date(0, 0, 0, startTimeHour, startTimeMin)
    const endTime = new Date(0, 0, 0, endTimeHour, endTimeMin)

    return this.localTimeRange.title.length > 0 && isBefore(startTime, endTime)
  }

  format(number) {
    return padStart(number, 2, '0')
  }
}
