export default {
  QUERY_PARAM_CONFIRMED_DATE: 'confirmed-date',
  QUERY_PARAM_CONFIRMED_ID: 'confirmed-id',
  QUERY_PARAM_SCHEDULE_ID: 'schedule-id',
  CANDIDATE_ID: 'candidate-id',
  QUERY_SHOW_CALENDAR: 'show-calendar',
  QUERY_NOT_FOUND_AVAILABILITY: 'availability',
  QUERY_NOT_FOUND_SCHEDULE: 'schedule',
  QUERY_PARAM_CONFIRM_START: 'confirm-start',
  QUERY_PARAM_TIMEZONE: 'timezone',
  TEAM_ID: 'team-id',
  INVITATION_ERROR_CODE: 'joined-already',
  ERROR_CODE: 'error-code'
}
