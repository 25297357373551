
























import SpirButton from '@/components/ui/Button.vue'
import { ICalendar } from '@/types'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'TargetSettingItem',
  components: {
    SpirButton
  },
  props: {
    calendar: {
      type: Object as PropType<ICalendar>,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isEmailVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { disabled } = toRefs(props)
    return {
      handleCalendarToggle() {
        if (disabled.value) {
          return
        }
        emit('onToggleCalendar')
      }
    }
  }
})
