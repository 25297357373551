













































import PreviewTeamScheduleModal from '@/components/modal/preview/PreviewTeamScheduleModal.vue'
import Skeleton from '@/components/sidePanels/edit/Skeleton.vue'
import { useMobileCalendarFooterSetup } from '@/components/ui/domain/section/arrangement/composables/useMobileCalendarFooterSetup'
import MobileCalendarCandidatesControls from '@/components/ui/domain/section/arrangement/MobileCalendarCandidatesControls.vue'
import MobileCalendarFooter from '@/components/ui/domain/section/arrangement/MobileCalendarFooter.vue'
import {
  PeekCandidatesForm,
  TeamScheduleFormData,
  TitleSuggestionSetup
} from '@/components/ui/domain/sections/arrangement/composables/types'
import { useTeamScheduleFormData } from '@/components/ui/domain/sections/arrangement/composables/useTeamScheduleFormsData'
import {
  PeekCandidatesControl,
  usePeekCandidatesPanelHandler
} from '@/components/ui/domain/sidePanel/arrangement/useArrangementPeekCandidatesSidePanel'
import { useEditPageInitializing } from '@/components/ui/domain/sidePanel/arrangement/useEditPageInitializing'
import { useHolidayCountryRemover } from '@/composables/useHolidayCountryRemover'
import { ScheduleDuration } from '@/models/data'
import CalendarControlModule from '@/store/modules/calendarControl'
import EditScheduleTeamModule from '@/store/modules/editScheduleTeam'
import UserModule from '@/store/modules/user'
import { CreateEvent, FullCalendarEvent } from '@/types'
import Common from '@/views/calendar/Common.vue'
import { computed, defineComponent, PropType, provide, ref, watch } from '@vue/composition-api'
import { parseISO } from 'date-fns'
import SidePanel from './_SidePanel.vue'
import { TeamScheduleEditSidePanelInfo } from './_teamScheduleEditPage'

export default defineComponent({
  components: {
    Common,
    SidePanel,
    Skeleton,
    PreviewTeamScheduleModal,
    MobileCalendarFooter,
    MobileCalendarCandidatesControls
  },
  props: {
    getTeamScheduleSuggestionSetup: {
      type: Function as PropType<(teamId: string) => TitleSuggestionSetup>,
      required: true
    }
  },
  setup(props) {
    const firstDate = ref(new Date())
    const editingTeamSchedule = computed(() => EditScheduleTeamModule.editingSchedule)
    const existCandidates = computed((): boolean => {
      return editingTeamSchedule.value?.activeCandidates.length > 0
    })
    const { initializeTeam } = useTeamScheduleFormData()
    const { getHolidayCountryRemover } = useHolidayCountryRemover()
    const { suggestions, save } = props.getTeamScheduleSuggestionSetup(editingTeamSchedule.value.teamId)
    const team = ref(
      initializeTeam({
        teamModel: editingTeamSchedule.value,
        titleSuggestions: suggestions,
        saveTitleSuggestion: save,
        isEditMode: true
      })
    )
    provide(TeamScheduleEditSidePanelInfo, {
      getCurrentTeamId: () => team.value.getCurrentTeamId(),
      getTeamScheduleFormData: () => team.value.getTeamScheduleFormData()
    })
    const formData = computed((): TeamScheduleFormData => team.value.getTeamScheduleFormData())

    const { isPeekCandidateSectionOpened, candidatesControls, openPanel } = usePeekCandidatesPanelHandler({ formData })
    const candidates = computed(() => candidatesControls.value.candidates)
    const handleUpdate = computed(() => candidatesControls.value.handleUpdate)
    const handleDelete = computed(() => candidatesControls.value.handleDelete)
    provide(PeekCandidatesControl, { formData })
    const showLoaderOnCalendar = computed(() => formData.value.peekCandidatesForm.isCandidateLoading)

    const { canPreview, handlePreview, handleFormCancel, handleCalendarClose } = useMobileCalendarFooterSetup({
      formData
    })
    function setCurrentDateToFirstCandidate() {
      if (!existCandidates.value) {
        return
      }
      const firstStartTime = editingTeamSchedule.value?.activeCandidates[0].start
      firstDate.value = parseISO(firstStartTime)
      CalendarControlModule.setCurrentDate(firstDate.value)
    }
    const { initializing, isSidePanelVisible, openSidePanel } = useEditPageInitializing({
      setupEditPage: async () => {
        await UserModule.fetchIntegrations(true)
        setCurrentDateToFirstCandidate()
      }
    })
    function handleHolidayExceptionRemoveWithConfirmationModal(event: FullCalendarEvent) {
      const peekCandidatesForm: PeekCandidatesForm = formData.value.peekCandidatesForm
      const remover = getHolidayCountryRemover(peekCandidatesForm.countriesForm.handleCountryCodeRemove)
      remover(event)
    }

    watch(editingTeamSchedule, (newTeamScheduleModel) => {
      if (newTeamScheduleModel) {
        team.value = initializeTeam({
          teamModel: newTeamScheduleModel,
          titleSuggestions: suggestions,
          saveTitleSuggestion: save,
          isEditMode: true
        })
      }
    })
    return {
      showLoaderOnCalendar,
      initializing,
      isSidePanelVisible,
      isPeekCandidateSectionOpened,
      candidates,
      formData,
      canPreview,
      handlePreview,
      handleFormCancel,
      handleCalendarClose,
      handlePeekCandidatesPanelOpen: () => {
        openSidePanel()
        openPanel()
      },
      handleDurationUpdate: (newDuration: ScheduleDuration) => {
        formData.value.basicInfo.duration.handleChange(newDuration)
      },
      handleEventUpdate: ({ event, revert }: { event: FullCalendarEvent; revert: Function }) => {
        handleUpdate.value(event, revert)
      },
      handleCandidateClicked: (id: string) => {
        handleDelete.value(id)
      },
      handleEventAdd: (data: CreateEvent) => {
        handleUpdate.value(data)
      },
      handleHolidayExceptionRemoveWithConfirmationModal
    }
  }
})
