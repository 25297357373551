




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModel } from '@/models/data/schedule'
import CardBodyFrame from './atoms/CardBodyFrame.vue'
import { ScheduleStatus } from '@/types/schedule'
import LastUpdateInfoCardBodyItem from './atoms/LastUpdateInfoCardBodyItem.vue'
import OnWaitingCard from '@/components/card/molecules/OnWaitingCard.vue'
import OwnerInfoCardBodyItem from './atoms/OwnerInfoCardBodyItem.vue'
import TimeInfoCardBodyItem from './atoms/TimeInfoCardBodyItem.vue'
import AttendeesCardBodyItem from './molecules/AttendeesCardBodyItem.vue'

@Component({
  components: {
    OnWaitingCard,
    OwnerInfoCardBodyItem,
    LastUpdateInfoCardBodyItem,
    TimeInfoCardBodyItem,
    AttendeesCardBodyItem,
    CardBodyFrame
  }
})
export default class ScheduleCardBodyForPrivate extends Vue {
  @Prop() schedule: ScheduleModel | null

  get isExpiredItem() {
    return this.schedule.status === ScheduleStatus.expired
  }
  get duration() {
    return this.schedule.duration
  }

  get canDisplayDate() {
    return this.schedule.canDisplayDate
  }

  get timeRange() {
    return { start: this.schedule.start, end: this.schedule.end }
  }

  get attendees() {
    if (!this.schedule.attendees) {
      return []
    }
    return this.schedule.attendees
  }

  get calendarName() {
    return this.schedule.calendarInfo?.calendarName
  }

  get backgroundColor() {
    return this.schedule.calendarInfo?.backgroundColor
  }
  get lastUpdateDateTime() {
    return this.schedule.updatedAt
  }
}
