











import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { DisplayWeekDay } from '@/models/data'
import { DayOfTheWeekRules } from '@spirinc/contracts'
import { defineComponent, PropType } from '@vue/composition-api'
import WeekdayConditionBox from './_WeekdayConditionBox.vue'

export default defineComponent({
  name: 'FormWeekdayConditionItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    WeekdayConditionBox
  },
  props: {
    dayOfTheWeekRules: {
      type: Object as PropType<DayOfTheWeekRules>,
      required: true
    },
    displayWeekdays: {
      type: Array as PropType<DisplayWeekDay[]>,
      default: () => []
    },
    onWeekdayConditionChange: {
      type: Function as PropType<(dayOfTheWeekRules: DayOfTheWeekRules) => void>,
      required: true
    }
  }
})
