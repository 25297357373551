








import { defineComponent, PropType } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import LocationInput from './_LocationInput.vue'

export default defineComponent({
  name: 'FormLocationInputForm',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    LocationInput
  },
  props: {
    location: {
      type: String,
      required: true
    },
    onInput: {
      type: Function as PropType<(newLocation: string) => void>,
      required: true
    }
  }
})
