import { FullCalendarEvent, isActiveStatus } from '@/types'
import queryParams from '@/lib/queryParams'
import { Route } from 'vue-router'
import { isAfter, isBefore, isEqual, parseISO } from 'date-fns'
import { BNoticeConfig } from 'buefy/types/components'
import i18n from '@/i18n'

export const inConfirmationPage = {
  getConfirmDateFromQueryParam(route: Route): null | Date {
    const startTimeForConfirm = route.query[queryParams.QUERY_PARAM_CONFIRM_START] as string
    if (!startTimeForConfirm) {
      return null
    }
    const startDate: Date = parseISO(startTimeForConfirm)
    if (startDate.toString() === 'Invalid Date') {
      return null
    }
    return startDate
  },
  getClosestStartingCandidate(candidates: FullCalendarEvent[], currentTime?: Date): FullCalendarEvent | undefined {
    const now = currentTime || new Date()
    return candidates.filter((c) => isActiveStatus(c.extendedProps?.status) && isAfter(c.start, now))[0]
  },
  getInvalidCandidateToastOption(option?: { duration: number }): BNoticeConfig {
    return {
      type: 'is-primary',
      position: 'is-top',
      message: i18n.t('message.selectedCandidateIsInvalidPleaseSelectAgain').toString(),
      duration: option?.duration || 5000
    }
  },
  getCandidateByStartAndEnd<T extends { start: string | Date; end: string | Date }>(
    candidates: T[],
    start: Date,
    end: Date
  ): T | undefined {
    return candidates.find((s) => {
      const startAsDate = new Date(s.start)
      const endAsDate = new Date(s.end)
      return (
        (isEqual(start, startAsDate) || isAfter(start, startAsDate)) &&
        (isEqual(end, endAsDate) || isBefore(end, endAsDate))
      )
    })
  }
}
