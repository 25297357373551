













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CalendarColorSwatchLayout extends Vue {
  @Prop({ default: false }) isNarrow: boolean
  @Prop({ default: 1 }) rowNum: number

  swatchSize = 24
  mounted() {
    this.swatchSize = this.isNarrow ? 30 : 24
  }
}
