import { useToast } from '@/composables/useToast'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { CandidateError } from '@/models/data/error'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import EditScheduleModule from '@/store/modules/editSchedule'
import PollAndSchedule from '@/store/modules/pollAndSchedule'
import { computed, getCurrentInstance, onBeforeUnmount, ref } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { useCalendarMixin } from './useCalendarMixin'
import { useDeviceInfo } from './useDeviceInfo'
import { useTranslation } from './useTranslation'

const MAX_DURATION = 1000 * 120 // Pollingを2分にする。
const DURATION_FOR_SYNC_MESSAGE = 3000

export const useScheduleMixin = () => {
  const timerForCheckSync = ref(null)
  const toastComponent = ref(null)
  const { isNarrow } = useDeviceInfo()
  const route = useRoute()
  const { toggleScheduleEventsList } = useCalendarMixin()
  const { openInfoBottomToast, openDangerBottomToast } = useToast()
  const i18n = useTranslation()
  const currentInstance = getCurrentInstance()

  const isScheduleEventListOpen = computed(() => {
    return AppScreenControlModule.isScheduleEventListOpen
  })
  const showControlWidget = computed(() => {
    return isNarrow.value && !isScheduleEventListOpen.value
  })
  const isValid = computed(() => {
    return PollAndSchedule.isValid
  })

  const getEditingEventByCalendarFormat = computed(() => {
    return PollAndSchedule.getEditingEventByCalendarFormat
  })

  const isSyncing = computed((): boolean => {
    return PollAndSchedule.isSyncing
  })

  const scheduleId = computed(() => {
    return route.params.id
  })

  function toggleViewOrConfirm() {
    if (!isNarrow.value || isScheduleEventListOpen.value) {
      EventBus.emit(EVENTS.VALIDATE_SCHEDULE)
      return 'confirm'
    }
    toggleScheduleEventsList(true)
    return 'toggle'
  }
  // todo: 日程調整のみ対応されているが、日程投票に対応させる必要がある。
  function showToastIfScheduleIsSyncing() {
    const getSchedule = async () => {
      await PollAndSchedule.setEditingObject({ id: scheduleId.value, hideLoading: true })
      if (!isSyncing.value) {
        clearInterval(timerForCheckSync.value)
        timerForCheckSync.value = null
        if (toastComponent.value) {
          toastComponent.value.close()
        }
      }
    }
    if (isSyncing.value) {
      toastComponent.value = openInfoBottomToast({
        message: i18n.t('message.pleaseWaitForSync').toString(),
        duration: MAX_DURATION
      })
      timerForCheckSync.value = setInterval(getSchedule, DURATION_FOR_SYNC_MESSAGE)
    }
  }
  async function addNewCandidate(payload, revert = null) {
    try {
      await EditScheduleModule.updateOrAddEventStartAndEnd(payload)
    } catch (e) {
      let errorMessageKey = 'message.errorCommon'
      if (e instanceof CandidateError) {
        if (currentInstance.proxy.$te(e.errorMessageKey)) {
          errorMessageKey = e.errorMessageKey
        }
      }
      openDangerBottomToast({
        message: i18n.t(errorMessageKey).toString()
      })
      revert ? revert() : ''
    }
    return
  }
  onBeforeUnmount(() => {
    if (timerForCheckSync.value) {
      clearInterval(timerForCheckSync.value)
    }
    if (toastComponent.value) {
      toastComponent.value.close()
    }
  })
  return {
    isNarrow,
    isScheduleEventListOpen,
    showControlWidget,
    isValid,
    getEditingEventByCalendarFormat,
    isSyncing,
    scheduleId,
    toggleScheduleEventsList,
    toggleViewOrConfirm,
    showToastIfScheduleIsSyncing,
    addNewCandidate
  }
}
