import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { addDays, startOfDay, endOfDay, startOfWeek, isThisWeek } from '@/lib/utils/timezone'
import CalendarControlModule from './calendarControl'
import TimezomeModule from './timezones'
import { WeekDay } from '@/models/data/userInfo'
import ProfileModule from '@/store/modules/profile'

const MODULE_NAME = 'Preview'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Preview extends VuexModule {
  _eventsDate: {
    start: Date
    end: Date
  } = {
    start: new Date(),
    end: new Date()
  }

  currentCalendarView: 1 | 3 | 7 = 7

  get eventsDate() {
    return this._eventsDate
  }
  get getCurrentView() {
    return this.currentCalendarView
  }

  @Action
  initPreview(startDate: Date) {
    this.SET_CURRENT_VIEW(CalendarControlModule.getCurrentView)
    const currentView = this.getCurrentView
    if (Number(currentView) === 7) {
      startDate = startOfWeek(startDate, {
        timeZone: TimezomeModule.timezoneKeysForDisplay[0].key,
        startWeekDay: ProfileModule.startWeekDayNum || WeekDay.sunday
      })
    }
    this.SET_EVENTS_DATE({ start: startDate })
  }
  @Action
  setCurrentView(newView: string) {
    const currentView = this.getCurrentView
    let newStartDate = this.eventsDate.start || new Date()
    if (Number(currentView) === 7 && Number(newView) === 1) {
      if (isThisWeek(newStartDate)) {
        newStartDate = new Date()
      }
    }
    this.SET_CURRENT_VIEW(newView)
    this.setCurrentDate(newStartDate)
  }
  @Action
  moveCurrentDate(type) {
    const currentView = this.getCurrentView
    const currentEventDate = this.eventsDate
    let newStart = currentEventDate.start
    switch (type) {
      case 'today':
        newStart = new Date()
        break
      case 'prev':
        newStart = addDays(newStart, -Number(currentView))
        break
      case 'next':
        newStart = addDays(newStart, Number(currentView))
        break
    }
    this.setCurrentDate(newStart)
  }

  @Action
  setCurrentDate(startDate?: Date) {
    let newDate = startDate || new Date()
    const currentView = this.getCurrentView
    const startWeekDay = CalendarControlModule.startWeek
    if (Number(currentView) === 7) {
      newDate = startOfWeek(newDate, { timeZone: TimezomeModule.timezoneKeysForDisplay[0].key, startWeekDay })
    }
    this.SET_EVENTS_DATE({ start: newDate })
  }

  @Mutation
  SET_EVENTS_DATE({ start }: { start: Date }) {
    this._eventsDate = {
      start: startOfDay(start),
      end: endOfDay(addDays(start, Number(this.currentCalendarView) - 1))
    }
  }
  @Mutation
  SET_CURRENT_VIEW(newView) {
    this.currentCalendarView = newView
  }
}

export default getModule(Preview)
