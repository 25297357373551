




















import CardTitle from '@/components/ui/CardTitle.vue'
import OwnerInfoCardBodyItem from '@/components/card/atoms/OwnerInfoCardBodyItem.vue'
import LastUpdateInfoCardBodyItem from '@/components/card/atoms/LastUpdateInfoCardBodyItem.vue'
import TimeInfoCardBodyItem from '@/components/card/atoms/TimeInfoCardBodyItem.vue'
import AttendeesCardBodyItem from '@/components/card/molecules/AttendeesCardBodyItem.vue'
import UnconfirmedListCard from '@/components/adjustmentList/UnconfirmedListCard.vue'
import UnconfirmedStatusOperationBox from '@/components/ui/UnconfirmedStatusOperationBox.vue'
import { CardTitleActionItem, UnconfirmedCardInfo, UnconfirmedScheduleCardStatus } from '@/types'

import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    CardTitle,
    UnconfirmedListCard,
    OwnerInfoCardBodyItem,
    LastUpdateInfoCardBodyItem,
    TimeInfoCardBodyItem,
    AttendeesCardBodyItem,
    UnconfirmedStatusOperationBox
  },
  props: {
    info: {
      type: Object as PropType<UnconfirmedCardInfo>,
      required: true
    },
    status: {
      type: Object as PropType<UnconfirmedScheduleCardStatus>,
      required: true
    },
    actionItems: {
      type: Array as PropType<Array<CardTitleActionItem>>,
      required: true
    },
    isTipVisible: {
      type: Boolean,
      default: false
    }
  }
})
