



































import PreviewPollScheduleModal from '@/components/modal/preview/PreviewPollScheduleModal.vue'
import Skeleton from '@/components/sidePanels/edit/Skeleton.vue'
import { useMobileCalendarFooterSetup } from '@/components/ui/domain/section/arrangement/composables/useMobileCalendarFooterSetup'
import MobileCalendarFooter from '@/components/ui/domain/section/arrangement/MobileCalendarFooter.vue'
import { usePersonalPollFormData } from '@/components/ui/domain/sections/arrangement/composables/usePersonalPollFormData'
import { useEditPageInitializing } from '@/components/ui/domain/sidePanel/arrangement/useEditPageInitializing'
import { usePollMixin } from '@/composables/usePollMixin'
import { useScheduleMixin } from '@/composables/useScheduleMixin'
import { noop } from '@/lib/utils'
import EditPollModule from '@/store/modules/editPoll'
import UserModule from '@/store/modules/user'
import Common from '@/views/calendar/Common.vue'
import { computed, toRefs, defineComponent, provide, watch } from '@vue/composition-api'
import { PersonalPollEditSidePanelInfo } from './_personalPollEditPage'
import SidePanel from './_SidePanel.vue'

export default defineComponent({
  components: {
    Common,
    MobileCalendarFooter,
    Skeleton,
    SidePanel,
    PreviewPollScheduleModal
  },
  props: {
    confirmId: {
      type: String
    }
  },
  setup(props) {
    const { confirmId } = toRefs(props)
    const { showToastIfScheduleIsSyncing } = useScheduleMixin()
    const { setEditingPoll } = usePollMixin()

    const editingPoll = computed(() => {
      return EditPollModule.editingPoll
    })

    const { openPollConfirmationModal, updatePersonalPollForms, getPersonalPollFormData } = usePersonalPollFormData({
      pollModel: editingPoll.value,
      titleSuggestions: [],
      saveTitleSuggestion: () => noop(),
      isEditMode: true
    })
    provide(PersonalPollEditSidePanelInfo, { getPersonalPollFormData })
    const formData = computed(() => getPersonalPollFormData())
    const candidatesInfo = computed(() => formData.value.candidatesInfo)
    const { canPreview, handlePreview, handleFormCancel, handleCalendarClose } = useMobileCalendarFooterSetup({
      formData
    })
    const { initializing, isSidePanelVisible } = useEditPageInitializing({
      setupEditPage: async () => {
        await UserModule.fetchIntegrations(true)
        setEditingPoll()
        showToastIfScheduleIsSyncing()
      }
    })
    function confirmIfThereIsCandidateIdInQuery() {
      if (confirmId.value) {
        openPollConfirmationModal(confirmId.value)
      }
    }
    watch(editingPoll, (newPollModel, oldPollModel) => {
      if (newPollModel && newPollModel.id !== oldPollModel.id) {
        updatePersonalPollForms({
          title: newPollModel.title,
          duration: newPollModel.duration,
          author: {
            accountId: newPollModel.accountId,
            calendarId: newPollModel.calendarId
          },
          description: newPollModel.description,
          onlineMeeting: { type: newPollModel.onlineMeeting.type },
          location: newPollModel.location || '',
          visibility: newPollModel.visibility
        })
      }
    })
    watch(confirmId, () => {
      confirmIfThereIsCandidateIdInQuery()
    })
    confirmIfThereIsCandidateIdInQuery()
    return {
      initializing,
      isSidePanelVisible,
      formData,
      canPreview,
      handlePreview,
      handleFormCancel,
      handleCalendarClose,
      handleEventUpdate: ({ revert }: { revert: Function }) => {
        revert()
        candidatesInfo.value.addNewCandidate()
      },
      handleCandidateClicked: (id: string) => {
        candidatesInfo.value.removeCandidate(id)
      },
      handleEventAdd: () => {
        candidatesInfo.value.addNewCandidate()
      }
    }
  }
})
