














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FormGroup extends Vue {
  @Prop({ default: null }) title: string
  @Prop({ default: null }) help: string
}
