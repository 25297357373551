
















import TimezoneEdit from '@/components/organisms/TimezoneEdit.vue'
import SpirButton from '@/components/ui/Button.vue'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { APIError } from '@/lib/sdk/error'
import { TimeZones } from '@spirinc/contracts'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'TimeZoneSettingForm',
  components: {
    ValidationObserver,
    TimezoneEdit,
    SpirButton
  },
  props: {
    timeZones: {
      type: Object as PropType<TimeZones>,
      required: true
    },
    onTimeZonesUpdate: {
      type: Function as PropType<(timeZones: TimeZones) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { openPrimaryTopToast, openDangerTopToast } = useToast()
    const currentTimeZones = ref<TimeZones>(props.timeZones)
    const isDirty = ref<boolean>(false)
    const isLoading = ref<boolean>(false)
    function handleTimeZoneEdit() {
      isDirty.value = true
    }
    async function handleTimeZonesUpdate() {
      try {
        isLoading.value = true
        await props.onTimeZonesUpdate(currentTimeZones.value)
        openPrimaryTopToast({ message: i18n.t('message.success.saved').toString() })
        isDirty.value = false
      } catch (e) {
        if (e instanceof APIError) {
          if (e.code === ERROR_CODE.ILLEGAL_ARGUMENT) {
            openDangerTopToast({
              message: i18n.t('message.error.notSupported', { value: currentTimeZones.value.primary.key }).toString()
            })
          } else if (e.status >= 500) {
            openDangerTopToast({
              message: i18n.t('message.error.serverError').toString()
            })
          }
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      currentTimeZones,
      isDirty,
      isLoading,
      handleTimeZoneEdit,
      handleTimeZonesUpdate
    }
  }
})
