








import { Component, Prop, Vue } from 'vue-property-decorator'
import Card from '@/components/card/molecules/Card.vue'

@Component({
  components: {
    Card
  }
})
export default class TeamConfirmedScheduleDateItems extends Vue {
  @Prop() title: string
  @Prop() isSchedule: boolean

  get cardIcon() {
    if (this.isSchedule) {
      return 'calendar'
    }
    return 'chart-check'
  }

  handleClick(e) {
    e.stopPropagation()
    this.$emit('click')
  }
}
