






















import { defineComponent, PropType, computed } from '@vue/composition-api'
import CalendarAutocomplete from './_CalendarAutocomplete.vue'
import DisplayItem from './_DisplayItem/index.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirButton from '@/components/ui/Button.vue'
import { ListSetForUI, AvailableAutocompleteListType } from './types'

export default defineComponent({
  name: 'ConditionIncludedCalendarsAutocomplete',
  components: {
    CalendarAutocomplete,
    FormItemSpacer,
    DisplayItem,
    SpirButton
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    listSetForUI: {
      type: Object as PropType<ListSetForUI>,
      default: () => ({ targetCalendars: [], selectedCalendars: [] })
    },
    onCalendarAdd: {
      type: Function as PropType<(selectedCalendar: AvailableAutocompleteListType) => void>,
      required: true
    },
    onCalendarDelete: {
      type: Function as PropType<(calendar: AvailableAutocompleteListType) => void>,
      required: true
    },
    allowInputEmail: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const selectedItems = computed(() => {
      return props.listSetForUI.selectedCalendars.map((sc) => {
        const setKey = (item: AvailableAutocompleteListType) => {
          switch (item.type) {
            case 'email':
              return item.email
            case 'calendar':
              return item.listItem.accountId + item.listItem.id
            case 'member':
              return item.teamId + item.listItem.id
          }
        }
        return {
          ...sc,
          key: setKey(sc)
        }
      })
    })
    return {
      selectedItems
    }
  }
})
