


















import { defineComponent, computed } from '@vue/composition-api'
import { SignalType } from '@/lib/analytics/LogEntry'
import { useSendSignal } from '@/composables/useSendSignal'
import SignInButton from '@/components/molecules/SignInButton.vue'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  name: 'CalendarSyncInfo',
  components: {
    SignInButton
  },
  props: {
    columnLayout: {
      type: Boolean,
      default: false
    },
    isShowSignInButton: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { sendSignal } = useSendSignal()

    const signInButtonTitle = computed(() => {
      if (props.isPreview) {
        return i18n.t('buttons.showAuthModalForPreview').toString()
      }
      return i18n.t('buttons.showAuthModalFromCalendarListPanel').toString()
    })
    return {
      sendSignal: () => {
        if (props.isPreview) {
          return
        }
        return sendSignal(SignalType.CLICK_SIGNUP_CONFIRM)
      },
      signInButtonTitle
    }
  }
})
