import { FullCalendarEvent } from '@/types'
import { nanoid } from 'nanoid'
import { differenceInMinutes, isBefore } from 'date-fns'
import i18n from '@/i18n'

export type CandidateByCondition = {
  end: string
  start: string
}

export abstract class Mergeable {
  candidates: FullCalendarEvent[]
  candidateSpan = 30

  constructor(candidates: CandidateByCondition[]) {
    this.candidates = candidates.map((c) => {
      return this.createNewFullCalendarEvent(new Date(c.start), new Date(c.end))
    })
    this.candidates.sort((a, b): number => {
      return isBefore(a.start, b.start) ? -1 : 1
    })
    if (this.candidates.length > 0 && differenceInMinutes(this.candidates[0].end, this.candidates[0].start) < 30) {
      this.candidateSpan = 15
    }
  }
  createNewFullCalendarEvent(start: Date, end: Date): FullCalendarEvent {
    return {
      id: nanoid(),
      start,
      end,
      title: `${i18n.t('labels.candidate').toString()}`,
      editable: false,
      extendedProps: {
        source: 'templateCandidate'
      }
    }
  }
  /**
   * アルゴリズム
   * 1. 次の要素のEndDateを確認する
   *   1. 有効なら、（マージ対象なら）次に進む
   *   2. 無効なら、Mergeする。
   * 2. MergeしたArrayをReturnする
   */
  get mergedCandidates(): FullCalendarEvent[] {
    const returnValue: FullCalendarEvent[] = []
    let start: Date
    this.candidates.forEach((candidate, index) => {
      if (!start) {
        start = candidate.start
      }
      if (!this.candidates[index + 1]) {
        returnValue.push(this.createNewFullCalendarEvent(start, candidate.end))
        return
      }
      const nextEnd = this.candidates[index + 1].end
      const diffMin = differenceInMinutes(nextEnd, candidate.end)
      if (diffMin <= this.candidateSpan) {
        return
      }
      // push
      returnValue.push(this.createNewFullCalendarEvent(start, candidate.end))
      start = null
    })

    return returnValue
  }
}
