




import { formatDateForDateHeader } from '@/composables/useConfirmedConverter'
import i18n from '@/i18n'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const today = new Date()
    const todayFormatted = formatDateForDateHeader(today)
    const prefix = todayFormatted === props.title ? `${i18n.t('scheduleTabs.listItem.today')} ` : ''
    const editedTitle = `${prefix}${props.title}`

    return {
      editedTitle
    }
  }
})
