




















import { Component, Prop, Vue } from 'vue-property-decorator'
import PreparedCalendarColors from './PreparedCalendarColors.vue'
import CustomColorPaletteButton from '../atoms/CustomColorPaletteButton.vue'
import CalendarColorSwatch from '../atoms/CalendarColorSwatch.vue'
import CalendarColorSwatchLayout from '../atoms/CalendarColorSwatchLayout.vue'

const colors = [
  '#9F2857',
  '#C63461',
  '#C3281C',
  '#D88177',
  '#E35C33',
  '#DF732C',
  '#E39635',
  '#EDBF4B',
  '#DFC45A',
  '#C2C851',
  '#88B153',
  '#5DB27E',
  '#397D49',
  '#419488',
  '#429BDF',
  '#5086EC',
  '#4153AF',
  '#7A87C6',
  '#AE9FD7',
  '#966DAB',
  '#8333A4',
  '#74574A',
  '#616161',
  '#A59B8F'
]

@Component({
  components: {
    PreparedCalendarColors,
    CustomColorPaletteButton,
    CalendarColorSwatch,
    CalendarColorSwatchLayout
  }
})
export default class CalendarColorPalette extends Vue {
  @Prop({ default: false }) isNarrow: boolean
  @Prop() currentColor: string
  @Prop() onColorSelect: (color: string) => void
  @Prop() onCustomColorColorPaletteOpen: () => void

  preparedColors = []
  isCustomColorUsed = false
  mounted() {
    const colorSet = colors.reduce(
      (acc, color) => {
        const isCurrentColor = color === this.currentColor
        const preparedColor = { value: color, isCurrent: isCurrentColor }
        acc.preparedColors.push(preparedColor)
        acc.hasPreparedColorUsed ||= isCurrentColor
        return acc
      },
      {
        preparedColors: [],
        hasPreparedColorUsed: false
      }
    )
    this.preparedColors = colorSet.preparedColors
    this.isCustomColorUsed = !colorSet.hasPreparedColorUsed
  }

  handleColorSelect(color: string) {
    this.onColorSelect(color)
  }

  handleCustomColorPaletteOpen() {
    this.onCustomColorColorPaletteOpen()
  }

  handleShield(e) {
    e.stopPropagation()
  }
}
