
























import { Component, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import CalendarModule from '@/store/modules/calendars'
import TeamRecordModule from '@/store/modules/teamRecord'
import ControlLabel from '@/components/atoms/ControlLabel.vue'
import Divider from '@/components/atoms/Divider.vue'
import TeamSettingLayout from '@/components/teamSettings/TeamSettingsLayout.vue'
import TeamHeader from '@/components/teamSettings/headers/TeamHeader.vue'
import TeamMainCalendarSelectView from '@/components/teamSettings/calendarSetting/TeamMainCalendarSelectView.vue'
import TeamCalendarShareSettingView from '@/components/teamSettings/calendarSetting/TeamCalendarShareSettingView.vue'
import MainCalendarSelect from '@/components/teamSettings/selects/MainCalendarSelect.vue'
import CalendarShareVisibilitySelect from '@/components/teamSettings/selects/CalendarShareVisibilitySelect.vue'
import { MemberCalendarRequest, Calendar } from '@spirinc/contracts'
import CalendarAddButton from '@/components/calendarList/molecules/CalendarAddButton.vue'
import teamScheduleList from '@/store/modules/teamScheduleList'
import { ScheduleStatus } from '@/types'
import ConfirmModal from '@/components/modal/Confirm.vue'

@Component({
  components: {
    Avatar,
    ControlLabel,
    Divider,
    TeamSettingLayout,
    TeamHeader,
    TeamMainCalendarSelectView,
    TeamCalendarShareSettingView,
    MainCalendarSelect,
    CalendarShareVisibilitySelect,
    CalendarAddButton
  }
})
export default class TeamMyCalendar extends Vue {
  isLoading = false
  beforeDestroy() {
    CalendarModule.fetchCalendars()
  }
  get teamId() {
    return this.$route.params.id
  }
  get myInfo() {
    return TeamRecordModule.myInfoByTeamId(this.teamId)
  }
  get myCalendars(): MemberCalendarRequest[] {
    return (
      this.myInfo?.calendars.map((mc) => {
        return {
          accountId: mc.calendar.accountId,
          calendarId: mc.calendar.id,
          primary: mc.primary,
          visibility: mc.visibility
        }
      }) || []
    )
  }
  get primaryCalendar() {
    return this.myCalendars.find((calendar) => calendar.primary)
  }
  get primaryAccountAndCalendarId() {
    return { accountId: this.primaryCalendar.accountId, calendarId: this.primaryCalendar.calendarId }
  }
  get calendarGroups() {
    return CalendarModule.getAccountWithCalendarsButNotResource
  }
  get writableCalendarsGroups() {
    return CalendarModule.getAccountWithPrimaryCalendar
  }
  async selectPrimaryCalendar(calendar: Calendar) {
    this.isLoading = true

    const status: ScheduleStatus[] = [
      'suggestedByOrganizer',
      'requestedByConfirmer',
      'requestedByOrganizer',
      'suggestedByConfirmer'
    ]
    const organizerTeamSchedule = await teamScheduleList
      .fetchTeamSchedule({ teamId: this.teamId, status, organizerMemberId: this.myInfo.id })
      .then((res) => {
        return res.schedules
      })

    if (organizerTeamSchedule.length > 0) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        canCancel: true,
        props: {
          header: this.$t('calendarList.teamCalendars.confirmChange').toString(),
          cancelBtn: this.$t('buttons.cancel').toString(),
          confirmBtn: this.$t('buttons.change').toString(),
          body: this.$t('calendarList.teamCalendars.changeCalendarConfirmBody', {
            scheduleCount: organizerTeamSchedule.length
          }).toString()
        },
        events: {
          confirm: async () => {
            // delete schedule before changing the primary calendar
            await Promise.all(
              organizerTeamSchedule.map((schedule) => {
                teamScheduleList.deleteSchedule({ teamId: this.teamId, scheduleId: schedule.id })
              })
            )
            await TeamRecordModule.updateCalendar({
              teamId: this.teamId,
              accountId: calendar.accountId,
              calendarId: calendar.id,
              payload: { primary: true }
            })
            this.isLoading = false
          },
          cancel: () => {
            this.isLoading = false
          }
        }
      })
    } else {
      await TeamRecordModule.updateCalendar({
        teamId: this.teamId,
        accountId: calendar.accountId,
        calendarId: calendar.id,
        payload: { primary: true }
      })
      this.isLoading = false
    }
  }
  async handleMyCalendarUpdate(calendar: {
    accountId: string
    calendarId: string
    visibility: false | 'readAll' | 'freeBusyOnly'
  }) {
    this.isLoading = true
    if (calendar.visibility) {
      await TeamRecordModule.updateCalendar({
        teamId: this.teamId,
        accountId: calendar.accountId,
        calendarId: calendar.calendarId,
        payload: { visibility: calendar.visibility as 'readAll' | 'freeBusyOnly' }
      })
    } else {
      await TeamRecordModule.deleteCalendar({
        teamId: this.teamId,
        accountId: calendar.accountId,
        calendarId: calendar.calendarId
      })
    }
    this.isLoading = false
  }
}
