



















import Divider from '@/components/atoms/Divider.vue'
import PersonalScheduleBasicInfoSection from '@/components/ui/domain/section/arrangement/BasicInfoSection/PersonalScheduleBasicInfoSection.vue'
import OthersInfoSection from '@/components/ui/domain/section/arrangement/OthersInfoSection.vue'
import EditablePeekCandidatesSection from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/EditablePeekCandidatesSection.vue'
import { defineComponent, computed } from '@vue/composition-api'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  components: {
    PersonalScheduleBasicInfoSection,
    EditablePeekCandidatesSection,
    Divider,
    OthersInfoSection
  },
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      isAutoCandidatesNoticeVisible: computed(() => ProfileModule.getLanguage === 'ja')
    }
  }
})
