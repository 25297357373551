






























import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from '@/components/newDisplay/molecules/Item.vue'
import { ProfileOnOverview } from '@/types'
import AttendeeWithProfileButton from '@/components/molecules/AttendeeWithProfileButton.vue'
import CollapseIconButton from './CollapseIconButton.vue'
import ConfirmText from './ConfirmText.vue'

@Component({
  components: {
    DisplayItem,
    AttendeeWithProfileButton,
    CollapseIconButton,
    ConfirmText
  }
})
export default class AuthorAndAttendeesInfo extends Vue {
  @Prop({ required: true }) sectionIconName: string
  @Prop({ required: true }) statisticsInfo: string
  @Prop() author: ProfileOnOverview
  @Prop({ default: [] }) attendees: ProfileOnOverview[]
  @Prop({ default: false }) isStatusIconON: boolean

  isOpen = false

  get hasAttendees(): boolean {
    return this.attendees.length > 0
  }

  get headerTitle(): string {
    return this.statisticsInfo
  }

  get allAttendees(): ProfileOnOverview[] {
    return this.attendees
  }

  handleToggle() {
    this.isOpen = !this.isOpen
  }
}
