import { ISchedulePollCommon, ICandidateBase, Attendee } from './schedule'

export interface CandidateVote extends ICandidateBase {
  votes?: UserVote[]
}
export const pollStatus = ['open', 'confirmed', 'deleted', 'expired'] as const
export type PollStatus = typeof pollStatus[number]

export interface IPoll extends ISchedulePollCommon {
  candidates: Array<CandidateVote>
  status: PollStatus
}

const pollAnswer = ['yes', 'no'] as const
export type PollAnswer = typeof pollAnswer[number]

export type UserVote = {
  attendee: Attendee
  answer: PollAnswer
}
