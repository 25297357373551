





















import PersonalCalendarBlock from './PersonalCalendarBlock.vue'
import CalendarPanel from '@/components/calendarList/molecules/CalendarPanel.vue'
import CalendarsModule from '@/store/modules/calendars'
import CalendarAddButton from '@/components/calendarList/molecules/CalendarAddButton.vue'
import UserModule from '@/store/modules/user'
import { PersonalCalendar } from '@spirinc/contracts'
import { SignalType } from '@/lib/analytics/LogEntry'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { isNotSupported as notSupported } from '@/lib/utils'
import { computed, defineComponent } from '@vue/composition-api'
import { useAnalytics } from '@/composables/useAnalytics'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  components: {
    CalendarPanel,
    CalendarAddButton,
    PersonalCalendarBlock
  },
  setup() {
    const analytics = useAnalytics()
    const { openPrimaryTopToast, openDangerBottomToast } = useToast()
    const i18n = useTranslation()
    const accountWithcalendars = computed(() => CalendarsModule.getAccountWithcalendars)
    const isNotSupported = computed(() => notSupported())
    function onToggleCalendar(payload) {
      return CalendarsModule.toggleCalendar(payload)
    }
    function onCalendarBackgroundColorChange(payload) {
      return CalendarsModule.setCalendarBackgroundColor(payload)
    }
    async function onDeleteAccount(account: PersonalCalendar) {
      try {
        await analytics.send(SignalType.DELETE_ACCOUNT)
        await UserModule.deleteAccount(account.accountId)
        await analytics.send(SignalType.DELETE_ACCOUNT_SUCCESS)
        openPrimaryTopToast({ message: i18n.t('message.commonSuccess').toString() })
      } catch (e: any) {
        const errorCode = e.code
        if (errorCode === ERROR_CODE.PRIMARY_CALENDAR_CAN_NOT_BE_DELETED) {
          openDangerBottomToast({
            message: i18n.t('message.deleteAccountFailByPrimaryCalendar').toString(),
            duration: 5000
          })
        } else {
          openDangerBottomToast({
            message: i18n.t('message.errorCommon').toString(),
            duration: 5000
          })
        }
      }
    }
    return {
      accountWithcalendars,
      isNotSupported,
      onToggleCalendar,
      onCalendarBackgroundColorChange,
      onDeleteAccount
    }
  }
})
