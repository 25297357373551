














import { defineComponent, computed } from '@vue/composition-api'
import AddCalendarDescriptionItem from './AddCalendarDescriptionItem.vue'
import { useTranslation } from '@/composables/useTranslation'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  props: {
    authProvider: {
      type: String
    }
  },
  components: {
    AddCalendarDescriptionItem
  },
  setup() {
    const i18n = useTranslation()
    const currentLanguage = computed(() => ProfileModule.getLanguage)
    const items = [
      {
        icon: 'help-circle',
        caption: i18n.t('forms.auth.addCalendar.explanation.aboutPermission.caption'),
        description: i18n.t('forms.auth.addCalendar.explanation.aboutPermission.description')
      },
      {
        icon: 'security',
        caption: i18n.t('forms.auth.addCalendar.explanation.aboutDataHandling.caption'),
        description: i18n.t('forms.auth.addCalendar.explanation.aboutDataHandling.description')
      }
    ]
    return {
      items,
      googleImage: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/images/signup_google.png`)),
      msImage: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/images/signup_microsoft.png`))
    }
  }
})
