import TeamModule from '@/store/modules/team'
import TeamRecordModule from '@/store/modules/teamRecord'
import UserModule from '@/store/modules/user'
import { Location } from 'vue-router'

// true: join, false: not joined
export const amIJoined = async (teamId: string): Promise<boolean> => {
  if (!UserModule.isSignIn) {
    return Promise.resolve(false)
  }
  if (TeamModule.getMyTeamById(teamId)) {
    if (!TeamRecordModule.teamByTeamId(teamId)?.myInfo) {
      await TeamRecordModule.fetchTeam({ teamId })
    }
    return Promise.resolve(true)
  }
  // fetch して問題なければ joinしているとする。
  try {
    await TeamRecordModule.fetchTeam({ teamId })
    return Promise.resolve(true)
  } catch (e) {
    Promise.resolve(false)
  }
}

export default async (to: Location, _, next) => {
  const teamId = to.params.id
  // confirmの場合はGuardしない
  if (to.name === 'TeamScheduleConfirm') {
    return next()
  }
  if (await amIJoined(teamId)) {
    next()
  } else {
    const redirectTo = (to.query.redirect || '/') as string
    next(redirectTo)
  }
}
