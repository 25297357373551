import ConfirmAvailability from '@/components/modal/confirm/ConfirmAvailability.vue'
import i18n from '@/i18n'
import { SignalType } from '@/lib/analytics/LogEntry'
import queryParams from '@/lib/queryParams'
import getErrorMessage from '@/lib/utils/getErrorMessage'
import {
  AvailabilityOverviewModelForTeam,
  AvailabilityOverviewModel,
  ConfirmationForm,
  ConfirmAvailabilityModelData,
  Confirmer
} from '@/models/data'
import { AfterConfirmModelForAvailability } from '@/models/data/afterConfirm'
import AfterConfirmModule from '@/store/modules/afterConfirm'
import ConfirmAvailabilityModule from '@/store/modules/confirmAvailability'
import EventToConfirmModule from '@/store/modules/eventToConfirm'
import UserModule from '@/store/modules/user'
import { AfterConfirmQueryParams, AvailabilityConfirm } from '@/types'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { computed, getCurrentInstance } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'
import { useAnalytics } from './useAnalytics'
import { useBuefy } from './useBuefy'

export type PrivateSignalParams = { id: string; type: 'private'; authorUserId: string }
export type TeamSignalParams = { id: string; type: 'team'; teamId: string }
type SignalParams = PrivateSignalParams | TeamSignalParams

export type PrivateAfterConfirmQueryParamsParts = {
  id: string
  't-or-p': 'private'
}
export type TeamAfterConfirmQueryParamsParts = {
  id: string
  't-or-p': 'team'
  't-id': string
}
type AfterConfirmQueryParamsParts = PrivateAfterConfirmQueryParamsParts | TeamAfterConfirmQueryParamsParts

type SetupProp = {
  getConfirmAvailabilityModalOtherParams?: () => { confirmationForm: ConfirmationForm }
  getAvailabilityModel: () => Promise<ConfirmAvailabilityModelData>
  getAvailabilityOverviewModel: () => Promise<AvailabilityOverviewModelForTeam | AvailabilityOverviewModel>
  getConfirmPublicURLSignalParams: () => SignalParams
  getAfterConfirmQueryParams: () => AfterConfirmQueryParamsParts
  persistAvailability: ({
    availabilityConfirm: AvailabilityConfirm,
    eventToConfirm: FullCalendarEvent,
    confirmer: Confirmer
  }) => Promise<{ afterConfirmModel: AfterConfirmModelForAvailability; email: string }>
}

type ConfirmAvailabilitySetupFunctions = {
  setEditMode: () => Promise<void>
  handleConfirmAvailability: (params?: { cancelBtn?: string }) => void
}

export const useConfirmAvailabilitySetup = ({
  getConfirmAvailabilityModalOtherParams,
  getAvailabilityModel,
  getAvailabilityOverviewModel,
  getConfirmPublicURLSignalParams,
  getAfterConfirmQueryParams,
  persistAvailability
}: SetupProp): ConfirmAvailabilitySetupFunctions => {
  const currentInstance = getCurrentInstance()
  const router = useRouter()
  const buefy = useBuefy()
  const analytics = useAnalytics()

  const eventToConfirm = computed(() => {
    return EventToConfirmModule.eventToConfirm
  })
  const isSignIn = computed(() => {
    return UserModule.isSignIn
  })

  function cancel() {
    router.push({ name: 'Main' })
  }

  async function setEditMode() {
    try {
      ConfirmAvailabilityModule.fetchConfirmAvailabilityModel(getAvailabilityModel)
      await ConfirmAvailabilityModule.fetchConfirmAvailabilityOverviewModel(getAvailabilityOverviewModel)
    } catch (e: any) {
      if (e.response?.status === 404) {
        router.push({ name: 'NotFound', query: { page: queryParams.QUERY_NOT_FOUND_AVAILABILITY } })
        return
      }
      buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: i18n.t('message.errorCommon').toString()
      })
      cancel()
    }
  }

  function getConfirmPersister(
    availabilityConfirm: AvailabilityConfirm,
    confirmer: Confirmer
  ): () => Promise<{ afterConfirmModel: AfterConfirmModelForAvailability; email: string }> {
    return async () => {
      return await persistAvailability({
        availabilityConfirm,
        eventToConfirm: eventToConfirm.value,
        confirmer
      })
    }
  }

  async function confirmAvailability(availabilityConfirm: AvailabilityConfirm, confirmer: Confirmer) {
    try {
      const persistAvailabilityConfirm = getConfirmPersister(availabilityConfirm, confirmer)
      await ConfirmAvailabilityModule.confirmOne(persistAvailabilityConfirm)
      const afterConfirm = AfterConfirmModule.getAfterConfirm

      analytics.send(SignalType.CONFIRM_PUBLICURL, getConfirmPublicURLSignalParams())
      const otherParams = getAfterConfirmQueryParams()
      const queryParam: AfterConfirmQueryParams = {
        type: 'availability',
        'e-id': afterConfirm.confirmedId,
        ...otherParams
      }
      return router.push({ name: 'AfterConfirm', query: queryParam })
    } catch (e) {
      const errorMessage = getErrorMessage(e, {
        handleKeys: [
          {
            errorCode: ERROR_CODE.AVAILABILITY_UPDATED,
            i18nKey: 'availabilitySharing.messages.error.AVAILABILITY_UPDATED'
          }
        ]
      })
      buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: errorMessage
      })
    }
  }
  function handleConfirmAvailability(params?: { cancelBtn?: string }) {
    const otherParams = getConfirmAvailabilityModalOtherParams ? getConfirmAvailabilityModalOtherParams() : {}
    const modal = buefy.modal.open({
      parent: currentInstance.proxy,
      component: ConfirmAvailability,
      hasModalCard: true,
      events: {
        confirm: (availabilityConfirm: AvailabilityConfirm, confirmer: Confirmer) => {
          confirmAvailability(availabilityConfirm, confirmer)
          modal.close()
        }
      },
      props: {
        candidateStartDate: eventToConfirm.value.start,
        candidateEndDate: eventToConfirm.value.end,
        ...otherParams,
        ...params
      }
    })
  }
  return {
    setEditMode,
    handleConfirmAvailability
  }
}
