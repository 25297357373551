


















import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { defineComponent, PropType } from '@vue/composition-api'
import { DateSlot, DatetimeSlotDeletePayload, DatetimeSlotSavePayload, DatetimeSlotUpdatePayload } from './datetimeSlot'
import DatetimeSlotListItem from './_DatetimeSlotListItem.vue'
import DateSlotListItemTitle from './DateSlotListItemTitle.vue'

export default defineComponent({
  name: 'DateSlotListItem',
  components: {
    FormItemSpacer,
    DatetimeSlotListItem,
    DateSlotListItemTitle
  },
  props: {
    dateSlot: {
      type: Object as PropType<DateSlot>,
      required: true
    },
    onDatetimeDelete: {
      type: Function as PropType<(payload: DatetimeSlotDeletePayload) => void>,
      required: true
    },
    onDatetimeUpdate: {
      type: Function as PropType<(payload: DatetimeSlotUpdatePayload) => void>,
      required: true
    }
  },
  setup(props) {
    return {
      handleDatetimeDelete: (id: string) => {
        const deletePaylod = { id, dateSlotId: props.dateSlot.id }
        props.onDatetimeDelete(deletePaylod)
      },
      handleDatetimeUpdate: (payload: DatetimeSlotSavePayload) => {
        const updatePayload = { dateSlotId: props.dateSlot.id, id: payload.id, ...payload }
        props.onDatetimeUpdate(updatePayload)
      }
    }
  }
})
