




import { Component, Vue } from 'vue-property-decorator'
import EditScheduleModule from '@/store/modules/editSchedule'
import { RouteNames } from '@/router'
import { Route, NavigationGuardNext } from 'vue-router'
import queryParams from '@/lib/queryParams'
import { AfterConfirmQueryParams } from '@/types/schedule'

const guard = async (to: Route, _, next: NavigationGuardNext) => {
  const scheduleId = to.params.id
  let nextName: RouteNames = 'Main'
  const toRoute: RouteNames = to.name as RouteNames
  try {
    await EditScheduleModule.setScheduleAsEditingSchedule({ scheduleId: scheduleId })
    if (EditScheduleModule.editingSchedule.isInvalid) {
      return next({ name: nextName, query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
    }
    if (EditScheduleModule.editingSchedule.status === 'deleted') {
      return next({ name: nextName, query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
    }
    if (toRoute === 'ConfirmSchedule') {
      if (EditScheduleModule.editingSchedule.status === 'expired') {
        return next({ name: nextName, query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
      }
      if (EditScheduleModule.editingSchedule.status === 'confirmed') {
        const queryParam: AfterConfirmQueryParams = {
          type: 'schedule',
          't-or-p': 'private',
          id: EditScheduleModule.editingSchedule.id
        }
        return next({ name: 'AfterConfirm', query: queryParam })
      }
    }
    next()
  } catch (e: any) {
    if (e.response?.status === 404) {
      nextName = 'NotFound'
      return next({ name: nextName, query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
    }
    // fixme: QueryParameterを送る側、受け取る側でルールがわかるように仕組みがあった方がわかりやすい。
    return next({ name: nextName, query: { error: 'common' } })
  }
}

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])

@Component
export default class ScheduleIdRoot extends Vue {
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.name === from.name && to.params?.id === from.params?.id) {
      return next()
    }
    guard(to, from, next)
  }
  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    guard(to, from, next)
  }
}
