














































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import debounce from 'lodash/debounce'
import { Editor, EditorContent } from 'tiptap'
import { Link } from 'tiptap-extensions'
import { searchAddress } from '@/lib/api/util'
import { text2link } from '@/lib/utils'

type LocationReturn = { title: string; description: string }

@Component({
  components: {
    FormItem,
    EditorContent
  }
})
export default class FormLocationInput extends Vue {
  locations: Array<LocationReturn> = []
  searchKey: string = null
  isFetching = false
  selectedLocation = null

  @PropSync('currentLocation', { type: String }) currentLocationSync!: string
  @Prop({ default: true }) showIcon: boolean
  @Prop({ default: false }) disabled: boolean

  getLocations: Function

  created() {
    this.getLocations = debounce(this.searchLocation, 300)
    this.selectedLocation = this.currentLocationSync
  }
  handleInput(newValue) {
    this.currentLocationSync = newValue
    this.$emit('input')
  }
  handleEnter(e) {
    this.locations = []
    e.target.blur()
  }
  async searchLocation(location) {
    this.currentLocationSync = location
    if (!location || !location.length) {
      this.locations = []
      this.selectedLocation = ''
      return
    }
    try {
      this.isFetching = true
      const response = await searchAddress(location, this.searchKey)
      if (response.status === 'ok') {
        this.searchKey = response.search_key
        this.locations = response.predictions
      }
    } finally {
      this.isFetching = false
    }
  }
  getLocationFromOption(option: LocationReturn) {
    if (!option || !option.title || !option.description) {
      return ''
    }
    return `${option.title}, ${option.description}`
  }
  selected(option: LocationReturn) {
    this.selectedLocation = this.getLocationFromOption(option)
    this.currentLocationSync = this.getLocationFromOption(option)
  }
  openGoogleMaps() {
    if (!this.currentLocationSync) {
      return
    }
    window.open(`https://www.google.com/maps?q=${this.selectedLocation}`, '_blank')
  }

  get showMapBtn() {
    return this.formattedSelectedLocationEditor.getHTML().indexOf('<a') === -1
  }

  get formattedSelectedLocationEditor() {
    return new Editor({
      content: text2link(this.selectedLocation),
      extensions: [new Link()],
      editable: false
    })
  }
}
