import { useAnalytics } from '@/composables/useAnalytics'
import { useDeviceInfo } from '@/composables/useDeviceInfo'
import { SignalType } from '@/lib/analytics/LogEntry'
import queryParams from '@/lib/queryParams'
import { getStartAndEndDateFromClickedPosition } from '@/lib/utils'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import CalendarControlModule from '@/store/modules/calendarControl'
import CalendarsModule from '@/store/modules/calendars'
import EventToConfirmModule from '@/store/modules/eventToConfirm'
import PollAndSchedule from '@/store/modules/pollAndSchedule'
import UserModule from '@/store/modules/user'
import { FullCalendarEvent, FullCalendarMouseEvent, SESSION_STORAGE_TOGGLE_SCHEDULE_EVENTS_LIST } from '@/types'
import { SLOT_DURATION } from '@/types/constants'
import { computed, nextTick, Ref, ref, ComputedRef } from '@vue/composition-api'
import { parseISO } from 'date-fns'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

type ConfirmationFunctions = {
  firstDate: Ref<Date>
  showOverview: Ref<boolean>
  isSignIn: ComputedRef<boolean>
  isScheduleEventListOpen: ComputedRef<boolean>
  getEditingEventByCalendarFormat: ComputedRef<FullCalendarEvent[]>
  currentPageId: string
  noConnectedAccount: ComputedRef<boolean>
  handleProceedToNext: () => void
  initCommonProcess: (payload: { startDate?: Date }) => void
  setCalendarDate: (startDate?: Date) => void
  handleMouseLeave: (payload: FullCalendarMouseEvent) => void
  handleEventHover: (duration: number, payload: FullCalendarMouseEvent) => Promise<void>
  updateConfirmEvent: (start: Date, end: Date, candidateId: string, accountId?: string, calendarId?: string) => void
  updateConfirmEventByMouseEvent: (
    duration: number,
    event: FullCalendarEvent,
    jsEvent: MouseEvent,
    el: HTMLElement
  ) => void
}

export const useConfirmation = (): ConfirmationFunctions => {
  const { isNarrow } = useDeviceInfo()
  const route = useRoute()
  const router = useRouter()
  const analytics = useAnalytics()

  const firstDate = ref(new Date())
  const showOverview = ref(true)

  const hoverEvent = ref<null | (Pick<FullCalendarMouseEvent, 'event' | 'el'> & { duration: number })>(null)

  const isScheduleEventListOpen = computed(() => AppScreenControlModule.isScheduleEventListOpen)
  const getEditingEventByCalendarFormat = computed(() => PollAndSchedule.getEditingEventByCalendarFormat)
  const isSignIn = computed(() => UserModule.isSignIn)
  const currentPageId = route.params.id

  // ログインしたけど、カレンダーをもっていない
  const noConnectedAccount = computed((): boolean => {
    return isSignIn.value && !CalendarsModule.hasAccount
  })
  function handleProceedToNext() {
    showOverview.value = false
    analytics.send(SignalType.SHOW_CALENDAR, { id: currentPageId })
    router.push({ query: { [queryParams.QUERY_SHOW_CALENDAR]: 'true' } }).catch()
  }
  function setCalendarDate(startDate?: Date) {
    // set current Date
    const candidateDateInQuery = route.query[queryParams.QUERY_PARAM_CONFIRMED_DATE]
    let calendarDate
    if (candidateDateInQuery) {
      try {
        calendarDate = parseISO(candidateDateInQuery as string)
      } catch {}
    }
    // if there is no query param, set calendar date to first day of candidates
    if (!calendarDate && startDate) {
      calendarDate = startDate
      firstDate.value = calendarDate
    }
    CalendarControlModule.setCurrentDate(calendarDate)
  }

  function showOverviewPageOrCalendar() {
    const showCalendar: boolean = String(route.query[queryParams.QUERY_SHOW_CALENDAR]) === 'true'
    if (showCalendar) {
      showOverview.value = false
      return
    }
    if (PollAndSchedule.isMine) {
      showOverview.value = false
    }
  }
  function showCalendarViewOrFormView() {
    if (isNarrow.value) {
      let showForm = true
      if (isSignIn.value) {
        showForm = sessionStorage.getItem(SESSION_STORAGE_TOGGLE_SCHEDULE_EVENTS_LIST) === 'true'
      }
      nextTick(() => {
        AppScreenControlModule.toggleScheduleEventsList(showForm)
      })
    }
  }

  function initCommonProcess(payload: { startDate?: Date }) {
    setCalendarDate(payload.startDate)
    // if this page is mine, close landing page
    showOverviewPageOrCalendar()
    // for mobile view, show previous view when reload
    showCalendarViewOrFormView()
    if (showOverview.value) {
      analytics.send(SignalType.SHOW_OVERVIEW, { id: currentPageId })
      return
    }
    analytics.send(SignalType.SHOW_CALENDAR, { id: currentPageId })
  }

  function updateConfirmEvent(start: Date, end: Date, candidateId: string, accountId?: string, calendarId?: string) {
    const duration = differenceInMinutes(end, start)
    const newEvent: FullCalendarEvent = {
      id: `confirmEvent`,
      title: '',
      start,
      end,
      durationEditable: false,
      extendedProps: {
        source: duration >= SLOT_DURATION ? 'confirmer' : 'confirmerUnderThirty',
        candidateId,
        accountId,
        calendarId
      }
    }
    return EventToConfirmModule.updateEventToConfirm(newEvent)
  }
  function updateConfirmEventByMouseEvent(
    duration: number,
    event: FullCalendarEvent,
    jsEvent: MouseEvent,
    el: HTMLElement
  ) {
    const {
      id,
      start,
      end,
      extendedProps: { accountId, calendarId }
    } = event
    const clickedTime = getStartAndEndDateFromClickedPosition(
      duration,
      start,
      end,
      duration < SLOT_DURATION ? duration : SLOT_DURATION,
      jsEvent,
      el
    )
    updateConfirmEvent(clickedTime.start, clickedTime.end, id, accountId, calendarId)
  }
  function handleEventMouseMove(event: MouseEvent) {
    updateConfirmEventByMouseEvent(hoverEvent.value.duration, hoverEvent.value.event, event, hoverEvent.value.el)
  }
  function handleMouseLeave(payload: FullCalendarMouseEvent) {
    hoverEvent.value = null
    payload.el.removeEventListener('mousemove', handleEventMouseMove)
  }
  async function handleEventHover(duration: number, payload: FullCalendarMouseEvent) {
    const { source } = payload.event.extendedProps
    // only pending is target
    if (source !== 'pending' && source !== 'templateCandidate') {
      return
    }
    await analytics.send(SignalType.HOVER_CANDIDATE_ON_CALENDAR, { id: currentPageId })
    hoverEvent.value = {
      duration,
      el: payload.el,
      event: payload.event
    }
    updateConfirmEventByMouseEvent(duration, payload.event, payload.jsEvent, payload.el)
    payload.el.addEventListener('mousemove', handleEventMouseMove)
  }
  return {
    firstDate,
    isSignIn,
    isScheduleEventListOpen,
    showOverview,
    getEditingEventByCalendarFormat,
    currentPageId,
    noConnectedAccount,
    handleProceedToNext,
    initCommonProcess,
    setCalendarDate,
    handleMouseLeave,
    handleEventHover,
    updateConfirmEvent,
    updateConfirmEventByMouseEvent
  }
}
