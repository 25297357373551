







import { Component, Vue } from 'vue-property-decorator'
import ProfileLayout from '@/components/layout/Profile.vue'
import ProfileMobileNav from '@/components/profile/ProfileMobileNav.vue'
import ProfileContent from '@/components/profile/ProfileContent.vue'
import ProfileModule from '@/store/modules/profile'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'

@Component({
  components: {
    ProfileLayout,
    ProfileContent,
    ProfileMobileNav
  }
})
export default class ProfileView extends Vue {
  profile = null

  get myProfile() {
    return ProfileModule.myProfile
  }
  get isLoading() {
    return ProfileModule.isLoading
  }
  async getProfile(id) {
    return ProfileModule.getProfile(id)
  }
  async mounted() {
    if (this.$route.params.id) {
      this.profile = await this.getProfile(this.$route.params.id)
    }
  }
  get showProfile() {
    if (this.$route.params.id) {
      return this.profile
    } else {
      return this.myProfile
    }
  }
  handleBack() {
    this.$router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
  }
}
