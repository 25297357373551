






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const KB = 1000

@Component
export default class ImageUploader extends Vue {
  @Prop({ default: 100 })
  sizeLimitKB: number

  file: File = null

  @Watch('file')
  onFileChanged(file: File) {
    if (file) {
      if (file.size > this.sizeLimitKB * KB) {
        this.$buefy.dialog.alert(`File size exceeded the maximum size of ${this.sizeLimitKB / 1000}MB`)
      } else {
        this.$emit('addFile', file)
      }
    }
  }
}
