


























import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import HolidatDropdown from '@/components/ui/domain/item/FormHolidaysSelectItem/_HolidayDropdown.vue'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import SelectedHolidayCountryItem from '@/components/ui/domain/item/FormHolidaysSelectItem/_SelectedHolidayCountryItem.vue'
import { MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION } from '@/types'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  name: 'FormHolidaysSelectItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    HolidatDropdown,
    SelectedHolidayCountryItem
  },
  props: {
    maxNumOfCountries: {
      type: Number,
      default: MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION
    },
    selectedCountryCodes: {
      type: Array as PropType<FrontSupportCountryCode[]>,
      required: true
    },
    onCountryCodeAdd: {
      type: Function as PropType<(value: string) => void>,
      required: true
    },
    onCountryCodeRemove: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { selectedCountryCodes, maxNumOfCountries } = toRefs(props)
    const i18n = useTranslation()

    const disabled = computed(() => selectedCountryCodes.value.length >= maxNumOfCountries.value)

    const triggerLabel = computed(() => {
      const i18nInfo = disabled.value
        ? {
            label: 'availabilitySharing.form.holidayException.addButtonDisabled',
            option: { count: maxNumOfCountries.value }
          }
        : { label: 'availabilitySharing.form.holidayException.addButton', option: {} }
      return i18n.t(i18nInfo.label, i18nInfo.option)
    })

    return {
      disabled,
      triggerLabel
    }
  }
})
