






















import TimezoneLabel from '@/components/molecules/TimezoneLabel.vue'
import SpirDropdownButton from '@/components/ui/spir/SpirDropdown/SpirDropdownButton.vue'
import TimeZoneModule, { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TimezoneCommon from './Common.vue'

@Component({
  components: {
    TimezoneCommon,
    TimezoneLabel,
    SpirDropdownButton
  }
})
export default class InlineTimezoneSelector extends Vue {
  @Prop({ default: '' }) timeZone: string
  @Prop({ default: false }) hideIcon
  isDropdownActive = false

  get updateHeight() {
    return this.isDropdownActive
  }
  get selectedTimeZone(): TimezoneDetailWithTimeLabel {
    return TimeZoneModule.timezoneByKey({ key: this.timeZone })
  }
  handleChangeTimeZone(key: string) {
    this.$emit('onChangeTimeZone', key)
  }
  handleActiveChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
  }
}
