import type { FullCalendarEvent } from '@/types'
import type { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { useModal } from './useModal'

export const useHolidayCountryRemover = () => {
  const { openRemoveHolidayExceptionConfirmationModal } = useModal()
  return {
    getHolidayCountryRemover: (removeHolidayException: (code: FrontSupportCountryCode) => Promise<void> | void) => {
      return (event: FullCalendarEvent) => {
        const countryCode = event.extendedProps?.countryCode || 'JP'
        openRemoveHolidayExceptionConfirmationModal({
          countryCode,
          confirm: async () => {
            await removeHolidayException(countryCode)
          }
        })
      }
    }
  }
}
