
















import { computed, defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import PersonalTeamSelectDropdown from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/index.vue'
import {
  personalId,
  PersonalTeamValue
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'

export default defineComponent({
  name: 'PersonalTeamTypeSection',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    PersonalTeamSelectDropdown
  },
  setup() {
    const { formMode, personalTeamValue, changeFormType } = useAvailabilitySharingForm()

    const personal = computed(() => ({
      id: personalId,
      name: ProfileModule.myProfile.fullName,
      photoURL: ProfileModule.myProfile.photoURL
    }))
    const teams = computed(() => TeamModule.myTeams)
    const disabled = computed(() => teams.value.length === 0 || formMode.value === 'edit')

    function onPersonalTeamValueChange(newValue: PersonalTeamValue) {
      const formType =
        newValue === personalId ? ({ type: 'personal' } as const) : ({ type: 'team', teamId: newValue } as const)
      changeFormType(formType)
    }

    return {
      personalTeamValue,
      personal,
      teams,
      disabled,
      onPersonalTeamValueChange
    }
  }
})
