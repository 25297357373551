
















import { defineComponent, inject, computed, ref, onMounted, watch } from '@vue/composition-api'
import SidePanelBox from '@/components/ui/page/SidePanelBox.vue'
import {
  PeekCandidatesPanelHandler,
  PeekCandidatesControl
} from '@/components/ui/domain/sidePanel/arrangement/useArrangementPeekCandidatesSidePanel'
import FormFooterButtons from '@/components/ui/form/FormFooterButtons.vue'
import PageheadTitleBox from '@/components/ui/PageheadTitleBox.vue'
import PeekCandidatesFormSections from '@/components/ui/domain/sections/arrangement/PeekCandidatesFormSections.vue'
import { useTranslation } from '@/composables/useTranslation'
import { usePeekCandidatesCondition } from '@/composables/peekCandidates/usePeekCandidatesCondition'
import deepEqual from 'deep-equal'
import { useAnalytics } from '@/composables/useAnalytics'
import { SignalType } from '@/lib/analytics'

export default defineComponent({
  name: 'ArrangementPeekCandidatesConditionSidePanel',
  components: {
    SidePanelBox,
    PageheadTitleBox,
    FormFooterButtons,
    PeekCandidatesFormSections
  },
  setup() {
    const i18n = useTranslation()
    const analytics = useAnalytics()
    const { handleClose } = inject(PeekCandidatesPanelHandler)
    const { isConditionLoading, getCondition, saveCondition } = usePeekCandidatesCondition()
    const isConditionSaveOnConfirm = ref(true)
    const { formData } = inject(PeekCandidatesControl)
    const allData = computed(() => formData.value)
    const peekCandidatesForm = computed(() => allData.value.peekCandidatesForm)
    const allConditions = computed(() => peekCandidatesForm.value.allConditions)
    const isCandidateLoading = computed(() => peekCandidatesForm.value.isCandidateLoading)

    watch(allConditions, async (newValue, oldValue) => {
      if (!deepEqual(newValue, oldValue)) {
        await allData.value.peekCandidatesForm.fetchCandidates(newValue)
      }
    })
    const handleConditionSave = async () => {
      if (isConditionSaveOnConfirm.value) {
        await saveCondition(allConditions.value)
      }
      allData.value.peekCandidatesForm.applyAutoPeekCandidates()
      allData.value.basicInfo.duration.handleChange(allConditions.value.duration)
      handleClose()
      analytics.send(SignalType.CLICK_PEEK_CANDIDATE_SAVE, { isConditionSave: isConditionSaveOnConfirm.value })
    }
    function handleConditionCancel() {
      handleClose()
      analytics.send(SignalType.CLICK_PEEK_CANDIDATE_CANCEL)
    }
    const handleIsConditionSaveOnConfirmToggle = () => {
      isConditionSaveOnConfirm.value = !isConditionSaveOnConfirm.value
    }
    onMounted(async () => {
      const peekCandidatesCondition = await getCondition()
      peekCandidatesForm.value.updateForms(peekCandidatesCondition)
      peekCandidatesForm.value.durationForm.handleInput(allData.value.basicInfo.duration.value)
    })
    return {
      isConditionLoading,
      isConditionSaveOnConfirm,
      handleIsConditionSaveOnConfirmToggle,
      footerButtons: computed(() => [
        {
          type: 'basic',
          title: i18n.t('buttons.cancel'),
          onClick: handleConditionCancel
        },
        {
          type: 'primary',
          title: i18n.t('forms.sections.peekCandidatesForm.runPeekButton'),
          disabled: isCandidateLoading.value === true,
          onClick: handleConditionSave
        }
      ]),
      peekCandidatesForm
    }
  }
})
