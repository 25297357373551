

























import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import LanguageItem from './_LanguageItem.vue'
import { useLanguageList } from '@/composables/useLanguageList'

export default defineComponent({
  name: 'LanguageDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem,
    LanguageItem
  },
  props: {
    value: {
      type: [String, Number] as PropType<string | number>
    },
    position: {
      type: String as PropType<'bottom' | 'top' | 'bottom-left'>,
      default: 'bottom'
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium'
    },
    isShortenSelectedValue: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)
    const { languageOptions } = useLanguageList()
    const selectedLanguage = computed(() => languageOptions.find((lo) => lo.key === value.value))
    function handleChange(newValue: string) {
      emit('input', newValue)
    }
    return {
      languageOptions,
      selectedLanguage,
      handleChange
    }
  }
})
