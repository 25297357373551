






import FormCollapseButton from '@/components/ui/form/FormCollapseButton.vue'
import i18n from '@/i18n'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FormNotificationSettingsCollapseButtonItem',
  components: {
    FormCollapseButton
  },
  setup() {
    const title = i18n.t('forms.common.collapseButtonTitle.notificationSettings.title').toString()
    return {
      title
    }
  }
})
