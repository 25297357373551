

























import FormCandidateDescriptionTextareaItem from '@/components/ui/domain/item/FormCandidateDescriptionTextareaItem.vue'
import FormCandidateTitleInputItem from '@/components/ui/domain/item/FormCandidateTitleInputItem.vue'
import FormPrivateTitleInputItem from '@/components/ui/domain/item/FormPrivateTitleInputItem.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'BasicInfoSection',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    FormPrivateTitleInputItem,
    FormCandidateTitleInputItem,
    FormCandidateDescriptionTextareaItem
  },
  props: {
    suggestions: {
      type: Object as PropType<{ private: string[]; candidate: string[] }>,
      default: () => ({ private: [], candidate: [] })
    }
  },
  setup() {
    const { editingObject, updateEditingObject } = useAvailabilitySharingForm()

    const privateTitle = computed(() => editingObject.value.title)
    function handlePrivateTitleInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.title = newValue
      updateEditingObject(clone)
    }

    const candidateTitle = computed(() => editingObject.value.candidateTitle)
    function handleCandidateTitleInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.candidateTitle = newValue
      updateEditingObject(clone)
    }

    const candidateDescription = computed(() => editingObject.value.candidateDescription)
    function handleCandidateDescriptionInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.candidateDescription = newValue
      updateEditingObject(clone)
    }

    return {
      privateTitle,
      handlePrivateTitleInput,
      candidateTitle,
      handleCandidateTitleInput,
      candidateDescription,
      handleCandidateDescriptionInput
    }
  }
})
