






















import { defaultScheduleMenus, MenuInfo } from '@/lib/menus'
import TempEventModule from '@/store/modules/tempEvent'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { isBefore } from 'date-fns'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import Section from '../atoms/Section.vue'
import IconButton from '../molecules/IconButton.vue'

export default defineComponent({
  name: 'Schedules',
  components: {
    Section,
    IconButton
  },
  props: {
    isCalendar: {
      type: Boolean,
      default: false
    },
    scheduleMenus: {
      type: Array as PropType<MenuInfo[]>,
      default: () => defaultScheduleMenus
    }
  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const isEventBefore = computed<boolean>(() => {
      if (!TempEventModule.getTempEvent) {
        return false
      }
      return isBefore(TempEventModule.getTempEvent.start, new Date())
    })
    function linkByMenu(menu: MenuInfo) {
      const query = { from: 'modal' }
      return { name: menu.targetLinkName, query }
    }
    function hideItem(menu: MenuInfo) {
      return (props.isCalendar && menu.hideOnCalendar) || false
    }
    function isDisabled(nextLinkName) {
      if (!props.isCalendar) {
        if (nextLinkName === route.name) {
          return true
        }
        return false
      }
      return isEventBefore.value || TempEventModule.getTempEvent?.allDay
    }
    function handleClick(menu: MenuInfo) {
      if (menu.onMenuClick) {
        menu.onMenuClick(menu.key)
      } else {
        emit('clickRoute', menu.targetLinkName)
        const routing = linkByMenu(menu)
        router.push(routing)
      }
    }

    return {
      linkByMenu,
      hideItem,
      isDisabled,
      handleClick
    }
  }
})
