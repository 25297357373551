















import { defineComponent } from '@vue/composition-api'
import ConfirmSkeltonCandidateBlockDate from './atoms/ConfirmSkeltonCandidateBlockDate.vue'
import ConfirmSkeltonCandidateSelectRow from './ConfirmSkeltonCandidateSelectRow.vue'
import ConfirmCandidateDateUL from './atoms/ConfirmCandidateDateUL.vue'
import ConfirmCandidateDateLI from './atoms/ConfirmCandidateDateLI.vue'
import ConfirmCandidateDateRowUL from './atoms/ConfirmCandidateDateRowUL.vue'
import ConfirmCandidateDateRowLI from './atoms/ConfirmCandidateDateRowLI.vue'

export default defineComponent({
  components: {
    ConfirmSkeltonCandidateBlockDate,
    ConfirmCandidateDateUL,
    ConfirmCandidateDateLI,
    ConfirmCandidateDateRowUL,
    ConfirmCandidateDateRowLI,
    ConfirmSkeltonCandidateSelectRow
  },
  setup() {
    return {
      data: [
        { no: 1, children: [1, 2] },
        { no: 2, children: [1] },
        { no: 3, children: [1, 2, 3] }
      ]
    }
  }
})
