import { reactive, computed } from '@vue/composition-api'
import {
  SelectedCalendar,
  SelectedMember,
  AvailableAutocompleteListType,
  MemberList
} from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { ICalendar } from '@/types'
import CalendarsModule from '@/store/modules/calendars'
import TeamCalendarModule from '@/store/modules/teamCalendar'
import { PeekCandidatesPostRequest } from '@spirinc/contracts'

export type FormCalendarsAutoCompleteItemSetup = {
  selectedItems: {
    calendars: SelectedCalendar[]
    members: SelectedMember[]
  }
  items: {
    calendarList: ICalendar[]
    memberList: MemberList[]
  }
  asParameter: PeekCandidatesPostRequest['calendars']
  handleAddItem: (addedItem: AvailableAutocompleteListType) => void
  handleDeleteItem: (deletededItem: AvailableAutocompleteListType) => void
}
export const useFormCalendarsAutoCompleteItem = (): FormCalendarsAutoCompleteItemSetup => {
  const targetCalendarList = computed(() => CalendarsModule.allCalendarsButNotResource)
  const targetMemberList = computed(() => TeamCalendarModule.allTeamMembersWithEmailArray)
  const selectedItems = computed(() => {
    return {
      calendars: targetCalendarList.value
        .filter((c) => c.visible)
        .map((c): SelectedCalendar => ({ accountId: c.accountId, calendarId: c.id })),
      members: targetMemberList.value
        .filter((am) => am.member.visible)
        .map((am): SelectedMember => ({ teamId: am.teamId, memberId: am.member.id }))
    }
  })
  const toggleCalendar = (item: AvailableAutocompleteListType) => {
    if (item.type === 'calendar') {
      return CalendarsModule.toggleCalendar({
        accountId: item.listItem.accountId,
        calendarId: item.listItem.id
      })
    }
    if (item.type === 'member') {
      return TeamCalendarModule.toggleMemberCalendar({ teamId: item.teamId, memberId: item.listItem.id })
    }
  }
  const asParameter = computed(() => {
    return {
      type: 'and' as PeekCandidatesPostRequest['calendars']['type'],
      personalCalendars: selectedItems.value.calendars,
      teamMembers: selectedItems.value.members.reduce((a, c) => {
        const foundTeam = a.find((tm) => tm.teamId === c.teamId)
        if (foundTeam) {
          foundTeam.memberIds.push(c.memberId)
        } else {
          a.push({ teamId: c.teamId, memberIds: [c.memberId] })
        }
        return a
      }, [])
    }
  })
  return reactive({
    selectedItems,
    items: {
      calendarList: targetCalendarList.value,
      memberList: targetMemberList.value
    },
    asParameter,
    handleAddItem: (addedItem: AvailableAutocompleteListType) => toggleCalendar(addedItem),
    handleDeleteItem: (deletedItem: AvailableAutocompleteListType) => toggleCalendar(deletedItem)
  })
}
