






import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import SpirButton from '@/components/ui/Button.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import { defineComponent, PropType } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'EmailDeleteActionBox',
  components: {
    ValidationObserver,
    ValidationProvider,
    WithValidationInput,
    FormItemSpacer,
    SpirTextInput,
    SpirButton
  },
  props: {
    type: {
      type: String as PropType<'button' | 'empty'>,
      default: 'button'
    },
    onDelete: {
      type: Function as PropType<() => void>
    }
  }
})
