














import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import OnlineMeetingDropdown from './_OnlineMeetingDropdown.vue'
import OnlineMeetingPrioritySelector from './_OnlineMeetingPrioritySelector/index.vue'
import { PrioritizedOnlineMeetings } from '@spirinc/contracts'

export default defineComponent({
  name: 'FormOnlineMeetingPriorityDropdownItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    OnlineMeetingDropdown,
    OnlineMeetingPrioritySelector
  },
  props: {
    onlineMeetings: {
      type: Array as PropType<PrioritizedOnlineMeetings>,
      required: true
    },
    onChange: {
      type: Function as PropType<(newOnlineMeetings: PrioritizedOnlineMeetings) => void>,
      required: true
    }
  },
  setup(props) {
    const { onlineMeetings } = toRefs(props)

    const isEnabled = computed(() => onlineMeetings.value.length > 0)
    const dropdownValue = computed(() => (isEnabled.value ? 'enabled' : 'disabled'))

    function handleDropdownChange(newValue) {
      if (newValue === 'enabled') {
        props.onChange([{ type: 'zoom' }, { type: 'googleMeet' }, { type: 'microsoftTeams' }])
      } else {
        props.onChange([])
      }
    }

    return {
      isEnabled,
      dropdownValue,
      handleDropdownChange
    }
  }
})
