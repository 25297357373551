import {
  TitleSuggestionSetups,
  TitleSuggestionSetup
} from '@/components/ui/domain/sections/arrangement/composables/types'
import {
  AvailabilitySharingTitleSuggestionsLocalStorage,
  TitleSuggestionsLocalStorage
} from '@/models/localStorage/TitleSuggestions'
import { AvailabilitySharingTitleSuggestionSetups } from '@/types/availabilitySharingForm'

export const GenTitleSuggestionSetup = {
  genSuggestionSetupsForCreate() {
    const titleSuggestionsLocalStorage = new TitleSuggestionsLocalStorage()
    const suggestionSetups: TitleSuggestionSetups = {
      personalScheduleSuggestionSetup: {
        suggestions: titleSuggestionsLocalStorage.privateScheduleTitleSuggestions,
        save: (title: string) => titleSuggestionsLocalStorage.saveToPrivateScheduleTitleSuggestion(title)
      },
      personalPollSuggestionSetup: {
        suggestions: titleSuggestionsLocalStorage.privatePollTitleSuggestions,
        save: (title: string) => titleSuggestionsLocalStorage.saveToPrivatePollTitleSuggestion(title)
      },
      getTeamScheduleSuggestionSetup: (teamId: string): TitleSuggestionSetup => {
        return {
          suggestions: titleSuggestionsLocalStorage.getTeamScheduleTitleSuggestions(teamId),
          save: (title: string) => titleSuggestionsLocalStorage.saveToTeamScheduleTitleSuggestion({ id: teamId, title })
        }
      }
    }
    return suggestionSetups
  }
}

export const GenAvailabilitySharingTitleSuggestionSetup = {
  genSuggestionSetups(): AvailabilitySharingTitleSuggestionSetups {
    const storage = new AvailabilitySharingTitleSuggestionsLocalStorage()
    const suggestionSetups = {
      personalSetup: {
        suggestions: {
          private: storage.personalPrivateTitleSuggestions.slice().reverse(),
          candidate: storage.personalCandidateTitleSuggestions.slice().reverse()
        },
        save: ({ privateTitle, candidateTitle }: { privateTitle: string; candidateTitle: string }) => {
          storage.saveToPersonalPrivateTitle(privateTitle)
          storage.saveToPersonalCandidateTitle(candidateTitle)
        }
      },
      getTeamSetup: (teamId: string) => {
        return {
          suggestions: {
            private: storage.getTeamPrivateTitleSuggestions(teamId).slice().reverse(),
            candidate: storage.getTeamCandidateTitleSuggestions(teamId).slice().reverse()
          },
          save: ({ privateTitle, candidateTitle }: { privateTitle: string; candidateTitle: string }) => {
            storage.saveToTeamPrivateTitle({ id: teamId, title: privateTitle })
            storage.saveToTeamCandidateTitle({ id: teamId, title: candidateTitle })
          }
        }
      }
    }
    return suggestionSetups
  }
}
