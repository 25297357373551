






















import { Vue, Prop, Component } from 'vue-property-decorator'
import UserModule from '@/store/modules/user'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import CalendarsModule from '@/store/modules/calendars'
import { defaultAccountAndCalendarId, SelectedCalendar, saveSelectedCalendar } from './data/defaultAccountAndCalendarId'
import oneDateRange from '@/filters/oneDateRange'
import AttendeeInput from './molecules/AttendeeInput.vue'
import ConfirmWithEmailAndName from './molecules/ConfirmWithEmailAndName.vue'
import { AvailabilityConfirm } from '@/types'
import { ConfirmationForm } from '@/models/data/availability'
import ConfirmationForms from './organisms/ConfirmationForms.vue'
import { GuestConfirmer, SpirUserConfirmer } from '@/models/data'

@Component({
  components: {
    AttendeeInput,
    ConfirmWithEmailAndName,
    ConfirmationForms
  }
})
export default class ConfirmAvailabilityModal extends Vue {
  @Prop() candidateStartDate: Date
  @Prop() candidateEndDate: Date
  @Prop({ default: false }) isOrganizer: boolean
  @Prop() confirmationForm: ConfirmationForm
  @Prop() cancelBtn: string

  availabilityConfirm: AvailabilityConfirm = {
    attendees: []
  }
  confirmerInfo: { email: string; name?: string } = {
    email: '',
    name: ''
  }
  selectedCalendar: SelectedCalendar = null
  created() {
    if (this.isSignIn) {
      this.selectedCalendar = defaultAccountAndCalendarId()
      if (this.selectedCalendar) {
        const selectedCalendar = CalendarsModule.getCalendar({
          accountId: this.selectedCalendar.accountId,
          calendarId: this.selectedCalendar.calendarId
        })
        if (selectedCalendar) {
          this.confirmerInfo.email = selectedCalendar.invitationAddress
        }
      }
    }
    // confirmation formが設定されてる場合は初期化
    if (this.confirmationForm) {
      this.availabilityConfirm['formData'] = {}
      Object.keys(this.confirmationForm.properties).map((key) => {
        if (this.confirmationForm.properties[key].isActive) {
          this.availabilityConfirm['formData'][key] = undefined
        }
      })
    }
  }
  get hasConfirmationForm() {
    return this.availabilityConfirm.formData && Object.keys(this.availabilityConfirm.formData).length > 0
  }
  get formattedDate() {
    return oneDateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  handleConfirm() {
    if (this.selectedCalendar) {
      saveSelectedCalendar(this.selectedCalendar)
      CalendarsModule.visibleCalendarIfNotVisible(this.selectedCalendar)
    }
    const confirmer = UserModule.isSignIn
      ? new SpirUserConfirmer({ email: this.confirmerInfo.email, user: ProfileModule.myProfile })
      : new GuestConfirmer({
          email: this.confirmerInfo.email,
          name: this.confirmerInfo.name,
          timeZone: TimezoneModule.userTimezoneInfo.key,
          language: ProfileModule.getLanguage
        })

    this.$emit('confirm', this.availabilityConfirm, confirmer)
  }
  handleCancel() {
    this.$emit('close')
  }

  deleteAttendee(index: number) {
    this.availabilityConfirm.attendees = [
      ...this.availabilityConfirm.attendees.slice(0, index),
      ...this.availabilityConfirm.attendees.slice(index + 1, this.availabilityConfirm.attendees.length)
    ]
  }
  addAttendee(attendee: { email: string; name: string }) {
    this.availabilityConfirm.attendees = [...this.availabilityConfirm.attendees, attendee]
  }

  get emails() {
    const emails = this.availabilityConfirm.attendees.map((attendee) => attendee.email)
    if (this.confirmerInfo.email) {
      emails.push(this.confirmerInfo.email)
    }
    return emails
  }
}
