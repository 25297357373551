













import { defineComponent, PropType } from '@vue/composition-api'
import { CalendarAlertState } from '@/components/layout/types'

export default defineComponent({
  name: 'CalendarAlert',
  props: {
    state: {
      type: Object as PropType<CalendarAlertState>,
      required: true
    }
  }
})
