



import TeamScheduleEditPage from '@/components/page/TeamScheduleEditPage/index.vue'
import { TitleSuggestionSetups } from '@/components/ui/domain/sections/arrangement/composables/types'
import { useArrangementChangeGuard } from '@/composables/useArrangementChangeGuard'
import { GenTitleSuggestionSetup } from '@/lib/utils'
import { defineComponent, ref } from '@vue/composition-api'
import { NavigationGuard } from 'vue-router'

export default defineComponent({
  components: {
    TeamScheduleEditPage
  },
  setup() {
    const page = ref(null)
    const { guard } = useArrangementChangeGuard()
    function guardBeforeRouteLeave(...args: Parameters<NavigationGuard>) {
      guard(page.value.formData.shouldStayHere, args)
    }
    const suggestionSetups: TitleSuggestionSetups = GenTitleSuggestionSetup.genSuggestionSetupsForCreate()
    return {
      page,
      getTeamScheduleSuggestionSetup: suggestionSetups.getTeamScheduleSuggestionSetup,
      guardBeforeRouteLeave
    }
  },
  beforeRouteLeave(to, from, next) {
    this.guardBeforeRouteLeave(to, from, next)
  }
})
