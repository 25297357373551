






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    onClick: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    return {
      handleClick: async () => {
        await props.onClick()
      }
    }
  }
})
