export const getNextPath = ({
  isSignIn,
  isRedirected = null,
  redirect = '/'
}: {
  isSignIn: boolean
  isRedirected: 'ok' | 'error' | 'noUser' | 'invalidInvitationCode' | 'userExists' | null
  redirect?: string
}): { redirect: string | undefined } => {
  if (isSignIn || isRedirected === 'ok') {
    return { redirect }
  } else {
    return { redirect: undefined }
  }
}
