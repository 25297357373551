



















import { defineComponent } from '@vue/composition-api'
import PageheadTitle from '@/components/ui/page/pagehead/PageheadTitle.vue'

export default defineComponent({
  components: {
    PageheadTitle
  },
  props: {
    title: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  }
})
