

















import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import VueColor from 'vue-color'

@Component({
  components: {
    CommonLayoutModal,
    Chrome: VueColor.Chrome
  }
})
export default class CustomColorPickBox extends Vue {
  @Prop() currentColorHex: string
  @Prop() onColorSelect: (color: string) => void

  hex = ''

  get colors() {
    return { hex: this.hex || this.currentColorHex }
  }

  handlePropagation(e) {
    e.stopPropagation()
  }

  updateColor(newColors) {
    this.hex = newColors.hex
  }

  handleColorSelect() {
    this.onColorSelect(this.colors.hex)
    this.$emit('close')
  }
}
