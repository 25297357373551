






import { Component, Vue } from 'vue-property-decorator'
import OrganizerInfoCardBodyItemFrame from './_OrganizerInfoCardBodyItemFrame.vue'

@Component({
  components: {
    OrganizerInfoCardBodyItemFrame
  }
})
export default class UndecidedOrganizerInfoCardBodyItem extends Vue {}
