




















import { defineComponent, toRefs, computed } from '@vue/composition-api'
import AuthFormTitle from '@/components/auth/AuthFormTitle.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import DescriptionForInAppBrowser from '@/components/atoms/DescriptionForInAppBrowser.vue'
import TermsAndPolicy from '@/components/atoms/TermsAndPolicy.vue'
import { isNotSupported as notSupported } from '@/lib/utils'
import i18n from '@/i18n'

export default defineComponent({
  components: {
    AuthFormTitle,
    AuthFormSpacer,
    DescriptionForInAppBrowser,
    TermsAndPolicy
  },
  props: {
    title: {
      type: String
    }
  },
  setup(props) {
    const { title } = toRefs(props)
    const isNotSupported = computed(() => notSupported())
    const finalTitle = computed(() => {
      if (!props.title) {
        return null
      }
      return isNotSupported.value ? i18n.t('forms.auth.noSupport.title') : title.value
    })
    return {
      isNotSupported,
      finalTitle
    }
  }
})
