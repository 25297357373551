

























import FormDurationSelectBoxItem from '@/components/ui/domain/item/FormDurationSelectBoxItem.vue'
import FormTitleInputItem from '@/components/ui/domain/item/FormTitleInputItem.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseBasicInfoSection',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    FormTitleInputItem,
    FormDurationSelectBoxItem
  },
  props: {
    title: {
      type: Object,
      required: true
    },
    duration: {
      type: Object,
      required: true
    }
  }
})
