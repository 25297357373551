import { ref, readonly, getCurrentInstance } from '@vue/composition-api'
import ConfirmWithMessage from '@/components/modal/ConfirmWithMessage.vue'
import { useBuefy } from './useBuefy'

type SetupProp = {
  cancelConfirmedScheduleApi: (message: string) => Promise<any>
}
export const useConfirmedAvailabilitySharingCancel = ({ cancelConfirmedScheduleApi }: SetupProp) => {
  const currentInstance = getCurrentInstance()
  const isCanceling = ref(false)
  const isCanceled = ref(false)
  const buefy = useBuefy()
  const cancelConfirmedSchedule = async () => {
    const modal = buefy.modal.open({
      parent: currentInstance.proxy,
      component: ConfirmWithMessage,
      props: {
        onConfirm: async (message: string) => {
          isCanceling.value = true
          try {
            await cancelConfirmedScheduleApi(message)
            isCanceled.value = true
          } catch (e) {
            buefy.toast.open({
              type: 'is-danger',
              position: 'is-bottom',
              message: currentInstance.proxy.$i18n.t('message.errorCommon').toString()
            })
          } finally {
            modal.close()
            isCanceling.value = false
          }
        }
      }
    })
  }
  return {
    isCanceling: readonly(isCanceling),
    isCanceled: readonly(isCanceled),
    cancelConfirmedSchedule
  }
}
