
















import { defineComponent, ref, PropType } from '@vue/composition-api'
import AvatarOnTabHeader from '@/components/molecules/TeamSelectorTab/AvatarOnTabHeader.vue'
import ConfirmedListPrivateTabContent from '@/components/adjustmentList/ConfirmedListPrivateTabContent.vue'
import ConfirmedListTeamTabContent from '@/components/adjustmentList/ConfirmedListTeamTabContent.vue'
import { ConfirmedTabsState } from '@/types'

export default defineComponent({
  components: {
    AvatarOnTabHeader,
    ConfirmedListPrivateTabContent,
    ConfirmedListTeamTabContent
  },
  props: {
    currentTab: {
      type: String as PropType<'private' | string>,
      required: true
    },
    tabsState: {
      type: Object as PropType<ConfirmedTabsState>,
      required: true
    },
    onTabChange: {
      type: Function as PropType<(tab: string) => void>,
      required: true
    },
    tabsClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const currentTabRef = ref(props.currentTab)
    return {
      currentTabRef
    }
  }
})
