









import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import TimezoneLabel from '@/components/molecules/TimezoneLabel.vue'
import { useModal } from '@/composables/useModal'
import TimezoneModule from '@/store/modules/timezones'

export default defineComponent({
  name: 'FormTimezoneSelectItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    TimezoneLabel
  },
  props: {
    isUpdateStore: {
      type: Boolean,
      default: true
    },
    timezone: {
      type: String,
      required: true
    },
    onTimezoneChange: {
      type: Function as PropType<(timezone: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { timezone, onTimezoneChange, isUpdateStore } = toRefs(props)
    const { openTimeZoneKeySelectorModalModal } = useModal()

    const timezoneInfo = computed(() => TimezoneModule.timezoneByKey({ key: timezone.value }))

    function handleClick() {
      openTimeZoneKeySelectorModalModal({
        props: { isUpdateStore: isUpdateStore.value, timezoneKey: timezone.value },
        confirm: onTimezoneChange.value
      })
    }

    return {
      timezoneInfo,
      handleClick
    }
  }
})
