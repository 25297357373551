






import { defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { BottomNavHeightState, bottomNavHeightStates } from './_type'

export default defineComponent({
  name: 'BottomFormCollapse',
  props: {
    heightState: {
      type: String as PropType<BottomNavHeightState>,
      required: true
    }
  },
  setup(props) {
    const { heightState } = toRefs(props)
    function getAnimationClass(from, to) {
      if (
        (from === bottomNavHeightStates.INITIAL && to === bottomNavHeightStates.EXPANDED) ||
        (from === bottomNavHeightStates.SHRINKED && to === bottomNavHeightStates.EXPANDED)
      ) {
        return 'expanded'
      }
      if (
        (from === bottomNavHeightStates.INITIAL && to === bottomNavHeightStates.CRUSHED) ||
        (from === bottomNavHeightStates.SHRINKED && to === bottomNavHeightStates.CRUSHED)
      ) {
        return 'crushed'
      }
      if (from === bottomNavHeightStates.CRUSHED && to === bottomNavHeightStates.SHRINKED) {
        return 'reshrinked'
      }
      if (from === bottomNavHeightStates.EXPANDED && to === bottomNavHeightStates.SHRINKED) {
        return 'shrinked'
      }
    }
    const animationClass = ref('')
    watch(heightState, (newVal, oldVal) => {
      animationClass.value = getAnimationClass(oldVal, newVal)
    })

    return {
      animationClass
    }
  }
})
