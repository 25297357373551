



























import { Component, Prop, Vue } from 'vue-property-decorator'
import UserModule from '@/store/modules/user'
import { SignalType } from '@/lib/analytics/LogEntry'
import Skeleton from '@/components/molecules/Skeleton.vue'
import AppelSignUp from '@/components/afterConfirm/AppealSignUp.vue'

@Component({
  components: {
    Skeleton,
    AppelSignUp
  }
})
export default class AfterConfirm extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: false }) isCanceled: boolean

  get isSignIn() {
    return UserModule.isSignIn
  }
  async sendSignal() {
    await this.$analytics.send(SignalType.CLICK_SIGNUP_CONFIRM)
  }

  get titleAndDescription() {
    if (this.isCanceled) {
      return {
        title: this.$t('afterConfirm.header.canceled.title'),
        description: this.$t('afterConfirm.header.canceled.description'),
        descriptionOnMobile: this.$t('afterConfirm.header.canceled.description-mobile')
      }
    }
    return {
      title: this.$t('afterConfirm.header.normal.title'),
      description: this.$t('afterConfirm.header.normal.description'),
      descriptionOnMobile: this.$t('afterConfirm.header.normal.description-mobile')
    }
  }
}
