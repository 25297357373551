import { LocalStorage } from '@/models/localStorage/localStorage'

export const doNotShowNextTimeKeys = {
  AUTO_PEEK_CANDIDATES_INFO: 'AUTO_PEEK_CANDIDATES_INFO' // 自動抽出条件の説明モーダルを表示用条件
} as const
type DO_NOT_SHOW_NEXT_TIME_CHECK = keyof typeof doNotShowNextTimeKeys

export class DoNotShowNextTimeCheck extends LocalStorage {
  constructor(key: DO_NOT_SHOW_NEXT_TIME_CHECK) {
    super(key, ['doNotShowNextTime'])
  }

  loadFromLocalStorage(): { doNotShowNextTime: boolean } {
    return super.loadFromLocalStorage()
  }
  get canShow(): boolean {
    const current = this.loadFromLocalStorage().doNotShowNextTime
    if (current === undefined) return true
    else return !current
  }
  save(doNotShowNextTime: boolean) {
    this.saveToLocalStorage({ doNotShowNextTime })
  }
}
