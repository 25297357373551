




















import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import TeamRecordModule, { TeamInfo } from '@/store/modules/teamRecord'
import { TeamDetailModel } from '@/models/data/team'

@Component({
  components: {
    Avatar
  }
})
export default class TeamHeader extends Vue {
  @Prop({ default: 'm' }) size: 's' | 'm'

  get avatarSize() {
    if (this.size === 's') {
      return 16
    }
    return 32
  }

  get teamId() {
    return this.$route.params.id
  }
  get currentTeamInfo(): TeamInfo {
    return TeamRecordModule.teamByTeamId(this.teamId)
  }
  get teamModel(): TeamDetailModel {
    return this.currentTeamInfo.team
  }
}
