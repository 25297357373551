











import { Component, Prop, Vue } from 'vue-property-decorator'
import Layout from '../atoms/AsideMenuItemLayout.vue'

@Component({
  components: {
    Layout
  }
})
export default class AsideMenuItemWithIcon extends Vue {
  @Prop() expanded: boolean
  @Prop() icon: string
  @Prop() label: string
}
