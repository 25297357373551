










































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import UserBadge from '@/components/molecules/UserBadge.vue'
import { ICalendar } from '@/types'
import DropdownWithList from './DropdownWithList.vue'
import { PersonalCalendar } from '@spirinc/contracts'

@Component({
  components: {
    FormItem,
    UserBadge,
    DropdownWithList
  }
})
export default class FormWritableCalendarWithAccountInfo extends Vue {
  @Prop() accountId: string
  @Prop() calendarId: string
  @Prop() accountWithcalendars: PersonalCalendar[]
  @Prop({ default: false }) required: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: true }) onlyPrimary: boolean

  get currentCalendar() {
    return this.accountWithcalendars
      .find((a) => a.accountId === this.accountId)
      ?.calendars.find((c) => c.id === this.calendarId)
  }
  targetCalendars(calendars: ICalendar[]) {
    if (this.onlyPrimary) {
      return calendars.filter((c) => c.primary)
    }
    return calendars.filter((c) => c.writable)
  }

  isActive(accountId: string, calendarId: string) {
    return this.accountId === accountId && this.calendarId === calendarId
  }
  selectCalendar(accountId: string, calendarId: string) {
    this.$emit('selected', {
      accountId,
      calendarId
    })
  }
}
