import TempLocalStorage from '@/models/localStorage/Temp'
import { FrontSupportLanguage, frontSupportLanguages } from '@/types'
import { getUserLocales } from 'get-user-locale'

const DEFAULT_LANGUAGE = frontSupportLanguages.en

const tempLocalStorage = new TempLocalStorage()

export const getGuestLanguage = (availableUserLocales: string[]) => {
  return (
    availableUserLocales
      .map((lc) => lc.replace(/-.*/, '')) //ja-JP, en-USなどの後ろを消す
      .find((lang) => {
        return Object.values(frontSupportLanguages).find((fsl) => fsl === lang)
      }) || DEFAULT_LANGUAGE
  )
}

export const getUserLanguage = (getUserLanguageInBackend: () => FrontSupportLanguage | undefined) => {
  // getuserLanguageInBackendはログイン済みであればmyProfileからlanguageを取得
  const backendUserLanguage = getUserLanguageInBackend()
  if (backendUserLanguage) {
    return backendUserLanguage
  }
  // ↓非ログインユーザー用
  // 1. localstorageに入ってる言語
  const findFromLocalStorage = tempLocalStorage.loadFromLocalStorage()?.language
  if (findFromLocalStorage && frontSupportLanguages[findFromLocalStorage]) {
    return findFromLocalStorage
  }
  return getGuestLanguage(getUserLocales())
}
