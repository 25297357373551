

















import { Component, Prop, Vue } from 'vue-property-decorator'
import SidePanelHeaderButton, { CancelOptions } from '@/components/sidePanels/edit/SidePanelHeaderButton.vue'
import FooterPanel from '../../layout/FooterPanel.vue'
import AppModule from '@/store/modules/app'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'

@Component({
  components: {
    FooterPanel,
    SidePanelHeaderButton,
    LoadingSpinner
  }
})
export default class SidePanelEditLayout extends Vue {
  @Prop({ default: false }) showFooter: boolean
  @Prop({ default: 'cancel' }) cancelMode: 'cancel' | 'back'
  @Prop({ default: null }) title: string
  @Prop({ default: false }) isLoading: boolean

  get isNarrow() {
    return AppModule.isNarrow
  }
  get cancelOptions(): CancelOptions {
    if (this.cancelMode === 'cancel') {
      return {
        icon: 'close',
        title: 'buttons.cancel'
      }
    } else {
      return {
        icon: 'close',
        title: 'buttons.back'
      }
    }
  }
  get cancelIcon() {
    return this.cancelMode === 'cancel' ? 'close' : 'arrow-left'
  }
  get cancelTitle() {
    const titleKey = this.cancelMode === 'cancel' ? 'buttons.cancel' : 'buttons.back'
    return this.$t(titleKey)
  }
  cancel() {
    this.$emit('cancel')
  }
}
