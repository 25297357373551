








































import Divider from '@/components/atoms/Divider.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ArrangementFormSidePanelFooterButtons from '@/components/ui/domain/footer/ArrangementFormSidePanelFooterButtons.vue'
import ArrangementTypeSection from '@/components/ui/domain/section/arrangement/ArrangementTypeSection.vue'
import TeamScheduleSections from '@/components/ui/domain/sections/arrangement/TeamScheduleSections.vue'
import ArrangementPeekCandidatesConditionSidePanel from '@/components/ui/domain/sidePanel/arrangement/ArrangementPeekCandidatesConditionSidePanel.vue'
import SidePanelsWrapper from '@/components/ui/domain/sidePanel/arrangement/SidePanelsWrapper.vue'
import SidePanelBodyFrame from '@/components/ui/page/SidePanelBodyFrame.vue'
import SidePanelFooterFrame from '@/components/ui/page/SidePanelFooterFrame.vue'
import SidePanelFrame from '@/components/ui/page/SidePanelFrame.vue'
import SidePanelHeaderFrame from '@/components/ui/page/SidePanelHeaderFrame.vue'
import { useAnalytics } from '@/composables/useAnalytics'
import { usePersonalTeamSelectDropdownSetup } from '@/composables/usePersonalTeamSelectDropdownSetup'
import { SignalType } from '@/lib/analytics'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'
import { ListType } from '@/types'
import { computed, defineComponent, inject, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { useRoute } from 'vue2-helpers/vue-router'
import { TeamScheduleEditSidePanelInfo } from './_teamScheduleEditPage'

export default defineComponent({
  props: {
    isPeekCandidateSectionOpened: {
      type: Boolean
    }
  },
  components: {
    ArrangementPeekCandidatesConditionSidePanel,
    SidePanelsWrapper,
    ValidationObserver,
    SidePanelFrame,
    SidePanelHeaderFrame,
    SidePanelBodyFrame,
    SidePanelFooterFrame,
    ClosingPageheadTitleBox,
    ArrangementFormSidePanelFooterButtons,
    Divider,
    TeamScheduleSections,
    ArrangementTypeSection
  },
  setup() {
    const analytics = useAnalytics()
    const route = useRoute()
    const { selectedPersonalTeamValue, personalInfo, teamInfos, handlePersonalTeamChange } =
      usePersonalTeamSelectDropdownSetup({
        initialPersonalTeamValue: route.params.id,
        getMyProfile: () => ProfileModule.myProfile,
        getMyTeams: () => TeamModule.myTeams
      })
    const currentArrangementType = ref<ListType>(ListType.SCHEDULE)
    const { getCurrentTeamId, getTeamScheduleFormData } = inject(TeamScheduleEditSidePanelInfo)
    const formData = computed(() => getTeamScheduleFormData())
    const teamId = computed(() => getCurrentTeamId())
    watch(teamId, (newTeamId) => {
      handlePersonalTeamChange(newTeamId)
    })

    return {
      currentArrangementType,
      formData,
      selectedPersonalTeamValue,
      personalInfo,
      teamInfos,
      handlePersonalTeamChange: () => ({}),
      handleArrangementTypeChange: () => ({}),
      handleClose: () => formData.value.pageDataControl.cancel(),
      handleSave: () => {
        formData.value.pageDataControl.confirm()
        analytics.send(SignalType.CLICK_ARRANGEMENT_SAVE, { arrangementType: currentArrangementType.value })
      },
      handlePreviewOpen: () => {
        formData.value.pageDataControl.preview()
        analytics.send(SignalType.CLICK_ARRANGEMENT_PREVIEW, { arrangementType: currentArrangementType.value })
      }
    }
  }
})
