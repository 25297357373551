import axiosInstance from '../axios'
import {
  MemberCalendarRequest,
  TeamsTeamIdInvitationsPostRequest,
  CalendarsTeamsTeamIdMembersMemberIdPatchRequest
} from '@spirinc/contracts'

type UpdateTeamMemberInvitationPayload = {}
export const getTeam = async (teamId: string) => {
  return (await axiosInstance.get(`teams/${teamId}`)).data
}

export const checkValidToken = async (teamId: string, invitationId: string, token: string) => {
  return (await axiosInstance.get(`public/teams/${teamId}/invitations/${invitationId}`, { params: { token } })).data
}

export const getPublicTeam = async (teamId: string) => {
  return (await axiosInstance.get(`public/teams/${teamId}`)).data
}

export const invitationTeamMember = async (teamId: string, payload: TeamsTeamIdInvitationsPostRequest) => {
  return (await axiosInstance.post(`teams/${teamId}/invitations`, payload)).data
}
export const cancelInvitation = async (teamId: string, invitationId: string) => {
  return (await axiosInstance.delete(`teams/${teamId}/invitations/${invitationId}`)).data
}
export const resendInvitation = async (teamId: string, invitationId: string) => {
  return (await axiosInstance.put(`teams/${teamId}/invitations/${invitationId}/resend`)).data
}

export const acceptInvitation = async (
  teamId: string,
  invitationId: string,
  token: string,
  calendars: MemberCalendarRequest[]
) => {
  return (
    await axiosInstance.put(`teams/${teamId}/invitations/${invitationId}`, {
      token,
      calendars
    })
  ).data
}
export const getTeamMemberSelf = async (teamId: string) => {
  return (await axiosInstance.get(`teams/${teamId}/members/self`)).data
}

export const updateTeamMemberSelf = async (teamId: string, calendars: MemberCalendarRequest[]) => {
  return (await axiosInstance.put(`teams/${teamId}/members/self`, { calendars })).data
}
export const updateMyCalendar = async (
  teamId: string,
  accountId: string,
  calendarId: string,
  payload: { primary?: boolean; visibility?: 'readAll' | 'freeBusyOnly' }
) => {
  return (
    await axiosInstance.put(`/teams/${teamId}/members/self/accounts/${accountId}/calendars/${calendarId}`, payload)
  ).data
}
export const deleteMyCalendar = async (teamId: string, accountId: string, calendarId: string) => {
  return (await axiosInstance.delete(`/teams/${teamId}/members/self/accounts/${accountId}/calendars/${calendarId}`))
    .data
}
export const patchMemberCalendar = async (
  teamId: string,
  memberId: string,
  payload: CalendarsTeamsTeamIdMembersMemberIdPatchRequest
) => {
  return (await axiosInstance.patch(`calendars/teams/${teamId}/members/${memberId}`, payload)).data
}

export const getMemberEvent = async (teamId: string, memberId: string, timeMin: string, timeMax: string) => {
  return (await axiosInstance.get(`teams/${teamId}/members/${memberId}/events`, { params: { timeMin, timeMax } })).data
}

export const updateTeamMemberInvitation = async (
  teamId: string,
  memberId: string,
  payload: UpdateTeamMemberInvitationPayload
) => {
  return (await axiosInstance.put(`teams/${teamId}/members/${memberId}/invitation`, payload)).data
}

export const updateTeamInfo = async (teamId: string, payload: { name: string; photoUrl: string }) => {
  return (await axiosInstance.patch(`teams/${teamId}`, payload)).data
}

export const updateTeamMemberAuthority = async (teamId: string, memberId: string, authority: string) => {
  return (await axiosInstance.patch(`teams/${teamId}/members/${memberId}`, { authority })).data
}

export const fetchInvitationUrl = async (teamId: string, invitationId: string) => {
  return (await axiosInstance.get(`teams/${teamId}/invitations/${invitationId}/url`)).data
}

export const deleteTeamMember = async (teamId: string, memberId: string) => {
  return await axiosInstance.delete(`teams/${teamId}/members/${memberId}`)
}

export const cancelConfirmedPublicURL = async (
  teamId: string,
  availabilitySharingId: string,
  availabilitySharingConfirmId: string,
  message: string
) => {
  return (
    await axiosInstance.put(
      `public/teams/${teamId}/availability-sharings/${availabilitySharingId}/confirmed/${availabilitySharingConfirmId}`,
      {
        action: 'cancel',
        message
      }
    )
  ).data
}
