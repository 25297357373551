



















import { Component, Vue, Prop } from 'vue-property-decorator'
import OtherCalendarRegistButton from './OtherCalendarRegistButton.vue'

@Component({
  components: {
    OtherCalendarRegistButton
  }
})
export default class OtherCalendarItem extends Vue {
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false
  useOtherMail = false
  email = ''

  get getUseOtherMail() {
    return this.useOtherMail
  }

  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm(email: string) {
    this.email = email
    this.useOtherMail = true
    this.activeChanged(false)
    this.$emit('confirm', email)
  }
  handleReset() {
    this.email = ''
    this.useOtherMail = false
    this.activeChanged(false)
    this.$emit('reset')
  }
}
