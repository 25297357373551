














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ControlLabel extends Vue {
  @Prop({ default: false }) active: boolean
  @Prop({ default: '' }) label: string
  @Prop({ default: 'left' }) labelPlacement: 'top' | 'bottom' | 'left' | 'right'
}
