

































import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator'
import CalendarColorPalette from './CalendarColorPalette.vue'
import CustomColorPickBox from '../atoms/CustomColorPickBox.vue'

@Component({
  components: {
    CalendarColorPalette,
    CustomColorPickBox
  }
})
export default class CalendarItemMenuButton extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop() currentColorHex: string
  @Prop({ default: false }) willShowMenuBottom: boolean
  @Prop() onActiveChange: (isActive: boolean) => void
  @Prop() onColorSelect: (color: string) => void

  @InjectReactive() readonly isNarrow!: boolean

  $refs: {
    dropdown: any // eslint-disable-line
  }

  isCustomColorPickBoxActive = false
  isColorPaletteDropdownActive = false

  get isMenuActive() {
    return this.isColorPaletteDropdownActive || this.isCustomColorPickBoxActive
  }

  closeDropdown() {
    const dropdown = this.$refs.dropdown
    dropdown?.toggle()
  }

  handleTrigger(isActive: boolean) {
    this.isColorPaletteDropdownActive = isActive
    this.onActiveChange(this.isMenuActive)
  }
  handleColorSelect(color: string) {
    this.onColorSelect(color)
    this.closeDropdown()
  }
  handleCustomColorSelect(color: string) {
    this.onColorSelect(color)
  }
  handleCustomColorPaletteOpen() {
    this.isCustomColorPickBoxActive = true
    this.closeDropdown()
  }
  handleModalClose() {
    this.isCustomColorPickBoxActive = false
    this.onActiveChange(this.isMenuActive)
  }

  get modalPosition() {
    // See https://github.com/buefy/buefy/issues/3037
    return this.willShowMenuBottom ? 'is-bottom-right' : 'is-top-right'
  }
}
