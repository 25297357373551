import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { AllRouteNames } from '@/router'
import { computed, ComputedRef } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'

type SetupProps = {
  formData: ComputedRef<ArrangementTypeFormData>
}
export const useMobileCalendarFooterSetup = ({ formData }: SetupProps) => {
  const router = useRouter()
  const { toggleScheduleEventsList } = useCalendarMixin()
  const canPreview = computed(() => formData.value.candidatesInfo.hasAtLeastOneCandidate)

  return {
    canPreview,
    handlePreview: () => {
      formData.value.pageDataControl.preview()
    },
    handleFormCancel: () => {
      router.push({ name: AllRouteNames.UnconfirmedList })
    },
    handleCalendarClose: () => toggleScheduleEventsList(true)
  }
}
