import { CandidateForVote, VoteStatusProps } from '@/store/modules/vote'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export type DatetimeSlot = {
  id: string // anything that can be identified to delete this slot.
  title: string // '11:00 - 12:00`
  start: Date
  end: Date
  isValidCandidate: boolean
  confirmation?: { gotoConfirmation: () => void }
}
export type VotingDatetimeSlot = DatetimeSlot & {
  poll: { candidateForVote: CandidateForVote | VoteStatusProps; isMaxCount: boolean }
}

type DateSlotBase = {
  id: string // Anything that can be used as key for a loop
  title: string // '2021/1/6(月)'
}
export type DateSlot = DateSlotBase & {
  datetimeSlots: DatetimeSlot[]
}
export type VotingDateSlot = DateSlotBase & {
  datetimeSlots: VotingDatetimeSlot[]
}

export type DatetimeSlotSavePayload = {
  id?: string
  allDay: boolean // fixme: 更新時にコンポーネントが自動的につけてくるが、本当はいらないはず
  end: Date
  start: Date
}

export type DatetimeSlotUpdatePayload = {
  dateSlotId: string
  id: string
  allDay: boolean // fixme: 更新時にコンポーネントが自動的につけてくるが、本当はいらないはず
  end: Date
  start: Date
}
export type DatetimeSlotDeletePayload = {
  dateSlotId: string
  id: string
}

export const DatetimeSlotEditorSetting = 'SlotEditorSetting'

export function dateSlotPeriodTitle(dateSlots: DateSlot[] | VotingDateSlot[], emptyTitle: string): string {
  if (dateSlots.length === 0) return emptyTitle
  else {
    const start = dateSlots[0].datetimeSlots[0].start
    const lastDateSlot = dateSlots[dateSlots.length - 1]
    const end = lastDateSlot.datetimeSlots[lastDateSlot.datetimeSlots.length - 1].end
    return `${spirDateFormat(start, spirDateFormatTypes.mdWeekday)} - ${spirDateFormat(
      end,
      spirDateFormatTypes.mdWeekday
    )}`
    // 'M/d(ccc)'
  }
}

export function toNoConfirmationDatetimeSlot<T extends { id: string; start: Date; end: Date }>(
  data: T
): Omit<DatetimeSlot, 'isValidCandidate'> {
  return {
    id: data.id,
    title: `${spirDateFormat(data.start, spirDateFormatTypes.hourMin)} - ${spirDateFormat(
      data.end,
      spirDateFormatTypes.hourMin
    )}`,
    start: data.start,
    end: data.end
  }
  //'HH:mm'
}
