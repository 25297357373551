














import { Component, Prop, Vue } from 'vue-property-decorator'
import MemberAvatar from '@/components/molecules/MemberAvatar.vue'
import { MemberResponse as MemberInfo } from '@spirinc/contracts'
import UserModule from '@/store/modules/user'
import TeamCalendarModule from '@/store/modules/teamCalendar'

@Component({
  components: {
    MemberAvatar
  }
})
export default class CalendarMemberAvatar extends Vue {
  @Prop() member: MemberInfo

  get backgroundColor() {
    return this.calendarInfoByMemberId?.backgroundColor || 'gray'
  }
  get photoUrl() {
    return this.member.photoUrl
  }
  get fullName() {
    return this.isMine ? this.$t('myInfo.mine') : this.member.fullName
  }
  get teamId() {
    return this.$route.params.id
  }
  get isMine() {
    return this.member.userId === UserModule.myId
  }
  get calendarInfoByMemberId() {
    return TeamCalendarModule.memberCalendarByTeamAndMemberId(this.teamId, this.member.id)
  }
}
