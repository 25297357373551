import { render, staticRenderFns } from "./AboutAutoCandidateExtraction.vue?vue&type=template&id=70b386ec&scoped=true&"
import script from "./AboutAutoCandidateExtraction.vue?vue&type=script&lang=ts&"
export * from "./AboutAutoCandidateExtraction.vue?vue&type=script&lang=ts&"
import style0 from "./AboutAutoCandidateExtraction.vue?vue&type=style&index=0&id=70b386ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b386ec",
  null
  
)

export default component.exports