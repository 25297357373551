










import { Vue, Component } from 'vue-property-decorator'
import SettingsSection from '@/components/organisms/settings/Section.vue'
import ZoomIntegrationRow from '@/components/organisms/settings/ZoomIntegrationRow.vue'
import UserModule from '@/store/modules/user'

@Component({
  components: {
    SettingsSection,
    ZoomIntegrationRow
  }
})
export default class IntegrationsSection extends Vue {
  async created() {
    UserModule.fetchIntegrations(true)
  }

  get zoomInfo() {
    return {
      accountName: UserModule.integrations.zoomInfo?.email,
      isConnected: UserModule.isConnectedZoom,
      passwordSetting: UserModule.integrations.zoomInfo?.settings.passwordSetting
    }
  }
}
