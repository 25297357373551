






import { defineComponent } from '@vue/composition-api'
import PageheadFrame from './page/pagehead/PageheadFrame.vue'
import PageheadTitle from './page/pagehead/PageheadTitle.vue'

export default defineComponent({
  components: {
    PageheadFrame,
    PageheadTitle
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
})
