





























import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import UserModule from '@/store/modules/user'
import SignInButton from '@/components/molecules/SignInButton.vue'
import { defineComponent, computed } from '@vue/composition-api'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'

export default defineComponent({
  components: {
    CommonLayoutModal,
    SignInButton
  },
  props: {
    title: {
      type: String
    }
  },
  setup(_, { emit }) {
    const { sendSignal } = useSendSignal()
    const sendSignUpSignal = () => {
      return sendSignal(SignalType.CLICK_SIGNUP_CONFIRM)
    }
    const handleBackToHome = () => {
      emit('backToHome')
    }
    return {
      isSignIn: computed(() => UserModule.isSignIn),
      sendSignUpSignal,
      handleBackToHome
    }
  }
})
