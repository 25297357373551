



import { computed, defineComponent, PropType } from '@vue/composition-api'
import FormFooterButtons from '@/components/ui/form/FormFooterButtons.vue'
import { useTranslation } from '@/composables/useTranslation'
import AppModule from '@/store/modules/app'

export default defineComponent({
  name: 'FooterButtons',
  components: {
    FormFooterButtons
  },
  props: {
    isValid: {
      type: Boolean,
      required: true
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()

    const isNarrow = computed(() => AppModule.isNarrow)

    const footerButtons = computed(() => {
      const confirmButton = {
        type: 'primary',
        disabled: !props.isValid,
        title: i18n.t('buttons.save'),
        onClick: () => props.onConfirm()
      }

      const cancelButton = {
        type: 'basic',
        title: i18n.t('buttons.cancel'),
        onClick: () => props.onCancel()
      }

      if (isNarrow.value) {
        return [cancelButton, confirmButton]
      } else {
        return [confirmButton]
      }
    })

    return {
      footerButtons
    }
  }
})
