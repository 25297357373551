export const arrangementsPageStates = {
  SELECTING_MENU: 'SELECTING_MENU', // ページ初期値・カレンダー上での候補選択やPlusButtonからのメニュー選択の待機状態
  STARTING_FORM: 'STARTING_FORM', // 候補を簡易フォームで初期表示
  EDITING_FORM_WITH_OVERVIEW: 'EDITING_FORM_WITH_OVERVIEW', // 候補を簡易フォームで編集状態
  EDITING_FORM_WITH_DETAIL: 'EDITING_FORM_WITH_DETAIL', // 候補を詳細フォームで編集状態
  CHECKING_EVENTS: 'CHECKING_EVENTS' // 候補を提案フォームで編集状態
} as const
export type ArrangementsPageState = keyof typeof arrangementsPageStates

export const bottomNavHeightStates = {
  INITIAL: 'initial',
  EXPANDED: 'expanded',
  SHRINKED: 'shrinked',
  CRUSHED: 'crushed'
} as const
export type BottomNavHeightState = Lowercase<keyof typeof bottomNavHeightStates>
