// for new module
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { GoogleEvent } from '../../types/schedule'
import { update, deleteEvent, create, getEventById } from '../../lib/api/event'
import { LOCAL_STORAGE_KEY_EVENT_DEFAULT_VALUES } from '@/types'
import { pick } from 'lodash'
import store from '@/store'
import EventsModule from './events'

export const ATTRS_SAVE_TO_LOCAL_STORAGE_IN_EVENT = ['calendarId', 'accountId', 'onlineMeeting']

const saveSomeValuesToLocalStorage = (payload: GoogleEvent) => {
  const targetValues = pick(payload, ATTRS_SAVE_TO_LOCAL_STORAGE_IN_EVENT)
  window.localStorage.setItem(LOCAL_STORAGE_KEY_EVENT_DEFAULT_VALUES, JSON.stringify(targetValues))
}

@Module({
  dynamic: true,
  name: 'Event',
  namespaced: true,
  store
})
class Event extends VuexModule {
  _isLoading = false

  get isLoading() {
    return this._isLoading
  }

  @Mutation
  RESET_STATE() {
    this._isLoading = false
  }
  @Mutation
  SET_LODING(status: boolean) {
    this._isLoading = status
  }

  @Action
  // todo: have to refresh event
  async updateEvent(payload: GoogleEvent) {
    this.SET_LODING(true)
    try {
      // if calendar id has changed, remove event and create event
      const currentEvent = EventsModule.getEventById(payload.id, payload.accountId)
      if (currentEvent && currentEvent.calendarId !== payload.calendarId) {
        const payloadForCreate = {
          ...payload
        }
        delete payloadForCreate.id
        await Promise.all([this.deleteEvent(currentEvent), this.createEvent(payloadForCreate)])
      } else {
        await update(payload)
      }
      saveSomeValuesToLocalStorage(payload)
      await EventsModule.fetchEvents({
        targetCalendars: [{ accountId: payload.accountId, calendarId: payload.calendarId }]
      })
    } catch (e: any) {
      throw new Error(e)
    } finally {
      this.SET_LODING(false)
    }
  }

  @Action
  async updateAttendee(payload: GoogleEvent) {
    try {
      await update(pick(payload, ['id', 'accountId', 'calendarId', 'attendees']))
      await EventsModule.fetchEvents({
        targetCalendars: [{ accountId: payload.accountId, calendarId: payload.calendarId }]
      })
    } catch (e: any) {
      throw new Error(e)
    }
  }

  @Action
  async createEvent(payload: GoogleEvent) {
    this.SET_LODING(true)
    try {
      saveSomeValuesToLocalStorage(payload)
      await create(payload)
      await EventsModule.fetchEvents({
        targetCalendars: [{ accountId: payload.accountId, calendarId: payload.calendarId }]
      })
    } catch (e: any) {
      throw new Error(e)
    } finally {
      this.SET_LODING(false)
    }
  }
  @Action
  async deleteEvent(payload) {
    this.SET_LODING(true)
    try {
      await deleteEvent(payload)
      await EventsModule.fetchEvents({
        targetCalendars: [{ accountId: payload.accountId, calendarId: payload.calendarId }]
      })
    } catch (e: any) {
      throw new Error(e)
    } finally {
      this.SET_LODING(false)
    }
  }
  @Action
  async getEvent(payload): Promise<{ event: GoogleEvent }> {
    this.SET_LODING(true)
    try {
      return await getEventById(payload)
    } catch (e) {
      throw new Error('no event')
    } finally {
      this.SET_LODING(false)
    }
  }
}

export default getModule(Event)
