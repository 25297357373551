






import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonToWrapIcon from '@/components/atoms/ButtonToWrapIcon.vue'

@Component({
  components: {
    ButtonToWrapIcon
  }
})
export default class CollapseIconButton extends Vue {
  @Prop({ required: true }) isOpen: boolean
  @Prop({ required: true }) onClick: () => void

  handleClick() {
    this.onClick()
  }
}
