








import { computed, defineComponent, PropType } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirSelectBox, { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'

export default defineComponent({
  name: 'FormMaxNumPerDaySelectBoxItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    SpirSelectBox
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    disabledDueToAttendee: {
      type: Boolean,
      default: false
    },
    onInput: {
      type: Function as PropType<(value: number) => void>,
      required: true
    }
  },
  setup(props) {
    const model = computed({
      get: () => props.value,
      set: (newValue) => props.onInput(newValue)
    })

    return {
      model
    }
  }
})
