



























import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import { SpirAttendeeStatus } from '@/types'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

@Component({
  components: {
    Avatar,
    Popper
  }
})
export default class AttendeeIcon extends Vue {
  @Prop() photoURL
  @Prop() fullName
  @Prop({ default: SpirAttendeeStatus.ACCEPTED }) attendanceStatus
  @Prop({ default: false }) hideAttendanceIcon
  @Prop({ default: 'is-top' }) toolTipPosition
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: 24 }) iconSize: number

  get hideTooltip() {
    return !this.fullName || this.fullName === ''
  }

  get getStyleClass() {
    return this.attendanceStatus
  }

  get attendanceIcon() {
    switch (this.attendanceStatus) {
      case SpirAttendeeStatus.DECLIEND:
        return 'block-helper'
      case SpirAttendeeStatus.ACCEPTED:
        return 'check'
      case SpirAttendeeStatus.TENTATIVE:
        return 'help'
    }
    return 'help'
  }
}
