
























import { Component, Prop, Vue } from 'vue-property-decorator'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

@Component({
  components: {
    Popper
  }
})
export default class AsideMenuItemWithImage extends Vue {
  @Prop({ default: false }) expanded: boolean
  @Prop({ default: false }) hideToolTip

  get showToolTip() {
    return !this.expanded && !this.hideToolTip
  }
}
