





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { SLOT_DURATION } from '@/types/constants'
import { startOfDay } from '@/lib/utils/timezone'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

@Component
export default class NewEvent extends Vue {
  @Prop() parentHeight: number
  @Prop() parentWidth: number
  @Prop() oneSlotPixel: number
  @Prop({ default: false }) draggable: boolean
  @Prop() event: any

  render = true // in order to revert

  @Watch('event')
  resetComponent() {
    this.refresh()
  }

  get height() {
    const diff = differenceInMinutes(this.event.end, this.event.start)
    return Math.round((diff / SLOT_DURATION) * this.oneSlotPixel)
  }
  get formatTime() {
    return `${spirDateFormat(this.event.start, spirDateFormatTypes.hourMin)} - ${spirDateFormat(
      this.event.end,
      spirDateFormatTypes.hourMin
    )}`
  }
  get startPixcel() {
    const diffMinFromStartDay = differenceInMinutes(this.event.start, startOfDay(this.event.start))
    return Math.round((diffMinFromStartDay / SLOT_DURATION) * this.oneSlotPixel)
  }
  refresh() {
    this.render = false
    this.$nextTick(() => {
      this.render = true
    })
  }
  onDragstop(left, top) {
    const topOffset = top - this.startPixcel
    if (topOffset === 0) {
      return
    }
    // when draged to above of top
    if (top < 0) {
      return
    }
    const unitCount = Math.round(topOffset / this.oneSlotPixel)
    const startDate = addMinutes(this.event.start, unitCount * SLOT_DURATION)
    const endDate = addMinutes(this.event.end, unitCount * SLOT_DURATION)
    EventBus.emit(EVENTS.UPDATE_EVENT, {
      event: {
        ...this.event,
        start: startDate,
        end: endDate
      },
      revert: () => {
        // this.setStartPixel()
        this.render = false
        this.refresh()
      }
    })
  }
}
