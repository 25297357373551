










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PatternFormWeekdayWithCheck extends Vue {
  @Prop() label!: string
  @Prop() check!: boolean
  @Prop() timesString: string
}
