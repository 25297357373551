







import { defineComponent } from '@vue/composition-api'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import ChangeAuthRoute from '@/components/atoms/ChangeAuthRoute.vue'
import SignInWithPasswordForm from '@/components/auth/SignInWithPasswordForm.vue'

import i18n from '@/i18n'

export default defineComponent({
  components: {
    AuthFormLayout,
    SignInWithPasswordForm,
    AuthFormSpacer,
    ChangeAuthRoute
  },
  setup() {
    const title = i18n.t('forms.auth.signIn.title')
    return {
      title
    }
  }
})
