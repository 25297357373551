import { useModal } from '@/composables/useModal'
import { useSendSignal } from '@/composables/useSendSignal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import { PollModel } from '@/models/data'
import EditPollModule from '@/store/modules/editPoll'
import { computed, reactive, ref } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { PageDataControl, UsePageDataControlCommon } from './types'
import { useDirtyChecker } from './_useDirtyChecker'
import { usePreviewState } from './_usePreviewState'

export const usePersonalPollPageDataControl = ({
  pageData,
  updateModel,
  saveTitleSuggestion,
  isEditMode = false,
  goToNext,
  cancelEditing
}: UsePageDataControlCommon & {
  updateModel: (partialModel: Partial<PollModel>) => void
}): PageDataControl => {
  const route = useRoute()
  const i18n = useTranslation()
  const { openDangerBottomToast, openPrimaryTopToast } = useToast()
  const { openConfirmModalInPoll, openSyncedDetailModal } = useModal()
  const { sendSignal } = useSendSignal()
  const isSyncing = ref(false)

  function updatePollModel() {
    updateModel(pageData.value)
  }

  async function update() {
    const result = new Promise((resolve) => {
      openConfirmModalInPoll({
        title: i18n.t('confirms.update.header').toString(),
        body: i18n.t('confirms.update.body').toString(),
        confirm: async () => {
          try {
            updatePollModel()
            isSyncing.value = true
            const targetId = EditPollModule.editingPoll.id
            await EditPollModule.updatePoll()
            saveTitleSuggestion(pageData.value.title)
            await sendSignal(SignalType.EDIT_POLL, { id: targetId })
            isSyncing.value = false
            openPrimaryTopToast({ message: `${i18n.t('message.success.savedSchedule')}` })
          } catch (e) {
            openDangerBottomToast({ message: `${i18n.t('message.errorCommon')}` })
          } finally {
            resolve(true)
          }
        }
      })
    })
    await result
  }
  async function create() {
    isSyncing.value = true
    updatePollModel()
    try {
      const pollId = await EditPollModule.createPoll()
      await sendSignal(SignalType.CREATE_POLL, { from: route.query.from as string, id: pollId })
      saveTitleSuggestion(pageData.value.title)
      openSyncedDetailModal({ model: EditPollModule.editingPoll })
      isSyncing.value = false
    } catch (e) {
      openDangerBottomToast({ message: `${i18n.t('message.errorCommon')}` })
    }
  }
  const { isPreviewOpen, isPreviewDisabled, preview } = usePreviewState(pageData)
  const previewData = computed(() => {
    updatePollModel()
    const model = new PollModel({ ...EditPollModule.editingPoll })
    model.title = model.title || i18n.t('labels.notInputted').toString()
    return { model, candidates: EditPollModule.getEditingEventByCalendarFormat }
  })
  const { clearDirty, shouldStayHere, selectPageDataControl } = useDirtyChecker({
    current: pageData,
    isEditMode,
    update,
    create,
    goToNext,
    cancelEditing
  })
  const { confirm, cancel } = selectPageDataControl()
  return {
    clearDirty,
    shouldStayHere,
    pageDataState: reactive({ isSyncing, isPreviewOpen, isPreviewDisabled, previewData }),
    handlers: { confirm, cancel, preview }
  }
}
