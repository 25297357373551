







import { computed, defineComponent, onMounted } from '@vue/composition-api'
import { useBuefy } from '@/composables/useBuefy'
import { isMobile } from '@/lib/utils'
import { AllRouteNames } from '@/router'
import { useRoute } from 'vue2-helpers/vue-router'
import { setupAppUpdateAvailableHandler } from '@/lib/setupServiceWorkerUpdate'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  name: 'AppUpdateAvailableSnackbar',
  setup() {
    const i18n = useTranslation()
    const buefy = useBuefy()
    const route = useRoute()

    setupAppUpdateAvailableHandler((updateApp) => {
      buefy.snackbar.open({
        container: '.app-updated-available-snackbar-container',
        message: i18n.t('app.updateAvailableMessage').toString(),
        position: 'is-bottom',
        indefinite: true,
        actionText: i18n.t('app.update').toString(),
        onAction: () => updateApp()
      })
    })

    const isPageWithActionButton = computed(() => {
      const pagesWithActionButton = [AllRouteNames.ArrangementsOnCalendar]
      return pagesWithActionButton.some((routeName) => routeName === route.name)
    })

    return {
      isMobile: isMobile(),
      isPageWithActionButton
    }
  }
})
