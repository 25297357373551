import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import {
  getTeam,
  getTeamMemberSelf,
  invitationTeamMember,
  cancelInvitation,
  resendInvitation,
  updateMyCalendar,
  deleteMyCalendar,
  updateTeamInfo,
  updateTeamMemberAuthority,
  fetchInvitationUrl,
  deleteTeamMember
} from '@/lib/api/team'
import { invitationTeamMember as sdkInvitationMember } from '@/lib/sdk/team'
import { TeamDetailModel, TeamMemberSelfModel } from '@/models/data/team'
import store from '@/store'
import UserModule from '@/store/modules/user'
import teamCalendarModule from '@/store/modules/teamCalendar'
import { sendUserInfoToExternalServices } from '@/lib/externalServices/sendUserInfoToExternalServices'
import { MemberResponse } from '@spirinc/contracts'

const MODULE_NAME = 'TeamRecord'

export type TeamInfo = {
  team: TeamDetailModel | null
  myInfo: TeamMemberSelfModel | null
  isLoading: boolean
}

type TeamRecordProps = {
  [teamId: string]: TeamInfo
}

const teamInfo = (teamRecords: TeamRecordProps): { teams: string[]; roles: string[] } => {
  const teams = Object.keys(teamRecords)
  const roles = Object.keys(teamRecords).map((teamId) => teamRecords[teamId].myInfo?.authority)
  return {
    teams,
    roles
  }
}

export interface IModuleTeamRecord {
  _teamRecord: TeamRecord
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TeamRecord extends VuexModule {
  _teamRecord: TeamRecordProps = {}

  get teamByTeamId() {
    return (teamId: string): TeamInfo => {
      return this._teamRecord[teamId]
    }
  }
  get myInfoByTeamId() {
    return (teamId: string): TeamMemberSelfModel => {
      return this._teamRecord[teamId]?.myInfo
    }
  }
  get memberInfo() {
    return (teamId: string, memberId: string): MemberResponse => {
      const team = this.teamByTeamId(teamId)
      if (!team) {
        return null
      }
      return team.team?.members.find((m) => m.id === memberId)
    }
  }
  get isLoading() {
    return (teamId: string) => {
      const hit = this._teamRecord[teamId]
      if (!hit) {
        return false
      }
      return hit.isLoading
    }
  }
  get allTeamMembers() {
    return Object.values(this._teamRecord)
      .map((tr) => {
        return (tr.team?.members ?? []).map((tm) => tm)
      })
      .flat()
  }
  @Mutation
  RESET_STATE() {
    this._teamRecord = {}
  }
  @Mutation
  SET_LOADING({ teamId, isLoading }: { teamId: string; isLoading: boolean }) {
    this._teamRecord = {
      ...this._teamRecord,
      [teamId]: {
        ...this._teamRecord[teamId],
        isLoading
      }
    }
  }

  @Mutation
  SET_TEAM({ teamId, team }: { teamId: string; team: TeamDetailModel }) {
    this._teamRecord = {
      ...this._teamRecord,
      [teamId]: {
        ...this._teamRecord[teamId],
        team: team
      }
    }
  }

  @Mutation
  SET_TEAM_MY_INFO({ teamId, myInfo }: { teamId: string; myInfo: TeamMemberSelfModel }) {
    this._teamRecord = {
      ...this._teamRecord,
      [teamId]: {
        ...this._teamRecord[teamId],
        myInfo: myInfo
      }
    }
  }

  @Action
  async fetchTeam({ teamId, showLoader = true }: { teamId: string; showLoader?: boolean }) {
    if (!UserModule.isSignIn) {
      return
    }
    this.SET_LOADING({ teamId, isLoading: !!showLoader })
    try {
      const response = await Promise.all([getTeam(teamId), getTeamMemberSelf(teamId)])
      const teamModel = new TeamDetailModel(response[0])
      const myInfo = new TeamMemberSelfModel(response[1])
      this.SET_TEAM({ teamId, team: teamModel })
      this.SET_TEAM_MY_INFO({ teamId, myInfo })
      sendUserInfoToExternalServices.updateTeamInfoToUser(teamInfo(this._teamRecord))
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING({ teamId, isLoading: false })
    }
  }

  @Action
  async invitationTeamMember({ teamId, emails }: { teamId: string; emails: string[] }) {
    this.SET_LOADING({ teamId, isLoading: true })
    try {
      await Promise.all(emails.map((email) => invitationTeamMember(teamId, { email })))
      await this.fetchTeam({ teamId, showLoader: false })
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING({ teamId, isLoading: false })
    }
  }
  @Action
  invitationMember({ teamId, email }: { teamId: string; email: string }) {
    return sdkInvitationMember(teamId, { email })
  }
  @Action
  async cancelInvitation({ teamId, invitationId }: { teamId: string; invitationId: string }) {
    await cancelInvitation(teamId, invitationId)
    this.fetchTeam({ teamId, showLoader: false })
  }
  @Action
  async resendInvitation({ teamId, invitationId }: { teamId: string; invitationId: string }) {
    await resendInvitation(teamId, invitationId)
    this.fetchTeam({ teamId, showLoader: false })
  }
  @Action
  async updateCalendar({
    teamId,
    accountId,
    calendarId,
    payload
  }: {
    teamId: string
    accountId: string
    calendarId: string
    payload: { primary?: boolean; visibility?: 'readAll' | 'freeBusyOnly' }
  }) {
    const myInfo = await updateMyCalendar(teamId, accountId, calendarId, payload)
    this.SET_TEAM_MY_INFO({ teamId, myInfo })
  }
  @Action
  async deleteCalendar({ teamId, accountId, calendarId }: { teamId: string; accountId: string; calendarId: string }) {
    const myInfo = await deleteMyCalendar(teamId, accountId, calendarId)
    this.SET_TEAM_MY_INFO({ teamId, myInfo })
  }
  @Action
  async updateTeamInfo({ teamId, name, photoUrl }: { teamId: string; name?: string; photoUrl?: string }) {
    await updateTeamInfo(teamId, { name, photoUrl })
    await this.fetchTeam({ teamId })
  }

  @Action
  async updateTeamMemberAuthority({
    teamId,
    memberId,
    authority
  }: {
    teamId: string
    memberId: string
    authority: string
  }) {
    await updateTeamMemberAuthority(teamId, memberId, authority)
  }

  @Action
  async fetchInvitationUrl({ teamId, invitationId }: { teamId: string; invitationId: string }) {
    return fetchInvitationUrl(teamId, invitationId)
  }
  @Action
  async deleteTeamMember({ teamId, memberId }: { teamId: string; memberId: string }) {
    await deleteTeamMember(teamId, memberId)

    const team = this._teamRecord[teamId].team
    team.members = this._teamRecord[teamId].team.members.filter((member) => member.id !== memberId)
    this.SET_TEAM({ teamId, team })

    const teamCalendars = teamCalendarModule._teamCalendars
    const index = teamCalendarModule._teamCalendars.findIndex((calendar) => calendar.id === teamId)
    const updatedCalendar = teamCalendars[index].memberCalendars.filter((calendar) => calendar.id !== memberId)
    teamCalendars[index].memberCalendars = updatedCalendar
    teamCalendarModule.SET_TEAM_CALENDARS(teamCalendars)
  }
}

export default getModule(TeamRecord)
