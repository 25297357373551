import {
  ArrangementTypeFormData,
  PersonalPollFormData,
  PersonalScheduleFormData,
  TeamScheduleFormData
} from '@/components/ui/domain/sections/arrangement/composables/types'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { activeLayerNames, CalendarBodyMode } from '@/types'

export function getArrangementTypeKey(formRequest: ArrangementFormRequest): string {
  return formRequest.type === 'personalPoll' ? 'poll' : 'schedule'
}

function isTeamFormData(formData: ArrangementTypeFormData): formData is TeamScheduleFormData {
  return !!formData.basicInfo['organizer']
}
function isPersonalFormData(
  formData: ArrangementTypeFormData
): formData is PersonalScheduleFormData | PersonalPollFormData {
  return !!formData.basicInfo['author']
}

export function selectHost(formData: ArrangementTypeFormData): {
  backgroundColor?: string
  url?: string
  name?: string
} {
  if (isTeamFormData(formData)) {
    const id = formData.basicInfo.organizer.value
    const member = formData.basicInfo.organizer.calendarCollections.find((member) => member.id === id)
    return { backgroundColor: member?.backgroundColor, url: member?.photoUrl, name: member?.fullName }
  } else if (isPersonalFormData(formData)) {
    const current = formData.basicInfo.author.value
    const account = formData.basicInfo.author.calendarCollections.find(
      (account) => account.accountId === current.accountId
    )
    const author = account?.calendars?.find((calendar) => calendar.id === current.calendarId)
    return { backgroundColor: author?.backgroundColor, name: author?.title }
  } else {
    return {}
  }
}

export const CalendarBodyModeSwitch = {
  isModeSwitchButtonActive(mode: CalendarBodyMode): boolean {
    return mode.type === 'layer' && mode.activeLayerName === activeLayerNames.registered
  },
  initialLayerMode(): CalendarBodyMode {
    return { type: 'layer', activeLayerName: activeLayerNames.none }
  },
  activateTemp(): CalendarBodyMode {
    return { type: 'layer', activeLayerName: activeLayerNames.temp }
  },
  activateCandidate(): CalendarBodyMode {
    return { type: 'layer', activeLayerName: activeLayerNames.candidate }
  },
  toggle(mode: CalendarBodyMode): CalendarBodyMode {
    if (mode.type === 'normal') return mode
    const nextLayerName =
      mode.activeLayerName === 'none' || mode.activeLayerName === 'registered'
        ? activeLayerNames.candidate
        : activeLayerNames.registered
    return { type: 'layer', activeLayerName: nextLayerName }
  }
}
