import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import AutoCandidatesModule from './autoCandidate'
import { FullCalendarEvent, OnlineMeetingType } from '@/types'
import { ScheduleDuration, ScheduleModelTeam } from '@/models/data/schedule'
import * as scheduleAPI from '../../lib/api/scheduleTeam'
import TimezoneModule from './timezones'
import EventsModule from './events'
import AfterConfirmModule from '@/store/modules/afterConfirm'
import clone from 'clone'
import TeamScheduleListModule from './teamScheduleList'
import UserModule from './user'
import TeamScheduleLocalStorage from '@/models/localStorage/TeamSchedule'
import { Confirmer } from '@/models/data'
import ProfileModule from '@/store/modules/profile'

export const TeamScheduleUpdateResult = {
  removedOverDurationCandidate: 'removedOverDurationCandidate',
  changeOnlineMtgUnavailable: 'changeOnlineMtgUnavailable'
} as const
export type TeamScheduleUpdateResult = typeof TeamScheduleUpdateResult[keyof typeof TeamScheduleUpdateResult]

export const MAX_CANDIDATE_COUNT = 50

export const localStorageKey = (teamId) => {
  return `TEAM_SCHEDULE_${teamId}`
}
@Module({
  dynamic: true,
  name: 'EditScheduleForTeam',
  namespaced: true,
  store
})
class EditScheduleForTeam extends VuexModule {
  _editingSchedule: ScheduleModelTeam = null
  _isDirty = false
  _isLoading = false

  get editingSchedule(): ScheduleModelTeam {
    return this._editingSchedule
  }
  get isDirty() {
    return this._isDirty
  }
  get isLoading(): boolean {
    return this._isLoading
  }
  get getEditingEventByCalendarFormat(): FullCalendarEvent[] {
    return this.editingSchedule?.getEditingEventByCalendarFormat || []
  }
  get areAllEventsSynced(): boolean {
    return !!this.editingSchedule?.areAllEventsSynced
  }

  @Action
  startCreatingNewSchedule(teamId) {
    const newSchedule = new ScheduleModelTeam(teamId)
    // 保存された値で復元
    const localStorageSaver = new TeamScheduleLocalStorage(localStorageKey(newSchedule.teamId))
    const savedValues = localStorageSaver.loadFromLocalStorage()
    if (savedValues) {
      newSchedule.setValues(savedValues)
    }
    this.SET_DIRTY(false)
    return this.SET_EDITING_SCHEDULE(newSchedule)
  }
  @Action
  async fetchSchedule({
    teamId,
    scheduleId,
    hideLoading
  }: {
    teamId: string
    scheduleId: string
    hideLoading?: boolean
  }) {
    if (!hideLoading) {
      this.SET_LOADING(true)
    }
    try {
      const response = await scheduleAPI.getSchedule(teamId, scheduleId)
      return new ScheduleModelTeam(teamId, response)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async setScheduleAsEditingSchedule(payload: {
    teamId: string
    scheduleId: string
    hideLoading?: boolean
    findFromStore?: boolean
  }) {
    const { teamId, scheduleId, findFromStore } = payload
    let schedule: ScheduleModelTeam = null
    if (findFromStore) {
      schedule = TeamScheduleListModule.scheduleModelByTeamIdScheduleId(teamId, scheduleId)
    }
    // scheduleがLocalStorageから生成された場合、ClassObjectではないので再度Fetchする。
    if (!schedule || !(schedule instanceof ScheduleModelTeam)) {
      schedule = await this.fetchSchedule(payload)
    }
    if (schedule.status === 'confirmed') {
      await AfterConfirmModule.setModelFromScheduleTeam(schedule)
    }
    this.SET_DIRTY(false)
    this.SET_EDITING_SCHEDULE(schedule)
  }
  @Action
  async setScheduleForConfirm(payload: {
    teamId: string
    scheduleId: string
    hideLoading?: boolean
    findFromStore?: boolean
  }) {
    const { teamId, scheduleId, hideLoading, findFromStore } = payload
    if (!hideLoading) {
      this.SET_LOADING(true)
    }
    try {
      let schedule: ScheduleModelTeam = null
      if (findFromStore) {
        schedule = TeamScheduleListModule.confirmedScheduleByTeamIdAndScheduleId(teamId, scheduleId)
      }
      // scheduleがLocalStorageから生成された場合、ClassObjectではないので再度Fetchする。
      if (!schedule || !(schedule instanceof ScheduleModelTeam)) {
        schedule = new ScheduleModelTeam(teamId, await scheduleAPI.getPublicSchedule(teamId, scheduleId))
      }
      if (schedule.status === 'confirmed') {
        await AfterConfirmModule.setModelFromScheduleTeam(schedule)
      }
      this.SET_DIRTY(false)
      this.SET_EDITING_SCHEDULE(schedule)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async afterUpdate(payload: { teamId: string; model: ScheduleModelTeam }) {
    const { teamId, model } = payload
    const localStorageSaver = new TeamScheduleLocalStorage(localStorageKey(teamId))
    localStorageSaver.saveToLocalStorage(model)
    this.SET_EDITING_SCHEDULE(model)
    TeamScheduleListModule.UPDATE_SCHEDULE_MODEL({ teamId, model })
    this.SET_DIRTY(false)
  }
  @Action
  async createSchedule(teamId) {
    this.SET_LOADING(true)
    try {
      const response = await scheduleAPI.create(teamId, this.editingSchedule)
      const newModel = new ScheduleModelTeam(teamId, response)
      this.afterUpdate({ teamId, model: newModel })
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  addCandidate(payload: { id?: string; start: Date; end: Date }) {
    const newSchedule: ScheduleModelTeam = clone(this.editingSchedule)
    newSchedule.addCandidate(payload.start, payload.end, payload.id)
    AutoCandidatesModule.SET_AUTO_CANDIDATE_FLAG(false)
    this.SET_EDITING_SCHEDULE(newSchedule)
    this.SET_DIRTY(true)
  }
  @Action
  removeCandidate(id) {
    const newSchedule: ScheduleModelTeam = clone(this.editingSchedule)
    newSchedule.removeCandidateById(id)
    this.SET_EDITING_SCHEDULE(newSchedule)
  }
  @Action
  setAutoGeneratedCandidateToSchedule(): 'overMaxCandidate' | null {
    const newSchedule: ScheduleModelTeam = clone(this.editingSchedule)
    const generatedCandidates = AutoCandidatesModule.autoGenratedCandidates
    const returnValue = newSchedule.replaceAllVaildCandidates(generatedCandidates)
    AutoCandidatesModule.SET_AUTO_CANDIDATE_FLAG(true)
    this.SET_EDITING_SCHEDULE(newSchedule)
    this.SET_DIRTY(true)
    return returnValue
  }
  @Action
  updateDuration(newDuration: ScheduleDuration) {
    const newSchedule: ScheduleModelTeam = clone(this.editingSchedule)
    newSchedule.duration = newDuration
    this.SET_EDITING_SCHEDULE(newSchedule)
    this.SET_DIRTY(true) // todo: 毎回呼ぶのがだるい
  }
  @Action
  updateModel(newSchedule: ScheduleModelTeam): TeamScheduleUpdateResult[] {
    const response: TeamScheduleUpdateResult[] = []
    if (!newSchedule.removeCandidateIfitsUnderNewDuration()) {
      response.push(TeamScheduleUpdateResult.removedOverDurationCandidate)
    }
    // 主催者が変わったらOnlineMeetingを考慮する必要がある。
    if (
      this.editingSchedule.onlineMeeting.type !== OnlineMeetingType.none &&
      this.editingSchedule.organizerMemberId !== newSchedule.organizerMemberId
    ) {
      const isExistCurrentOnlineMeetingInNewOrganizer = newSchedule.availableOnlineMeetings.indexOf(
        this.editingSchedule.onlineMeeting.type
      )
      // 新しい主催者が既存のオンラインミーティングをもっていない場合は変える
      if (isExistCurrentOnlineMeetingInNewOrganizer < 0) {
        newSchedule.onlineMeeting = { type: newSchedule.availableOnlineMeetings[0] || 'none' }
        response.push(TeamScheduleUpdateResult.changeOnlineMtgUnavailable)
      }
    }
    this.SET_EDITING_SCHEDULE(newSchedule)
    this.SET_DIRTY(true)
    return response
  }
  @Action
  async updateSchedule() {
    this.SET_LOADING(true)
    try {
      const teamId = this.editingSchedule.teamId
      const response = await scheduleAPI.updateSchedule(this.editingSchedule)
      const newModel = new ScheduleModelTeam(teamId, response)
      this.afterUpdate({ teamId, model: newModel })
    } finally {
      this.SET_LOADING(false)
    }
  }

  @Action
  replaceAllCandidates(newCandidates: { start: string; end: string; id: string }[]) {
    const newSchedule: ScheduleModelTeam = clone(this.editingSchedule)
    newSchedule.candidates = newCandidates
    this.SET_EDITING_SCHEDULE(newSchedule)
  }

  @Action
  async confirm(payload: {
    candidateId: string
    start: Date
    attendees: { name: string; email: string }[]
    confirmer: Confirmer
  }) {
    this.SET_LOADING(true)
    try {
      const response = await scheduleAPI.confirm(this.editingSchedule.teamId, this.editingSchedule.id, {
        candidateId: payload.candidateId,
        start: payload.start.toISOString(),
        duration: this.editingSchedule.duration,
        attendees: payload.attendees,
        confirmer: payload.confirmer
      })
      const newModel = new ScheduleModelTeam(this.editingSchedule.teamId, response)
      if (newModel.amIOrganizer) {
        this.afterUpdate({ teamId: newModel.teamId, model: newModel })
      }
      await AfterConfirmModule.setModelFromScheduleTeam(newModel)
      EventsModule.fetchEvents()
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  initEditingSchedule() {
    this.SET_EDITING_SCHEDULE(null)
    this.SET_DIRTY(false)
    AutoCandidatesModule.SET_AUTO_CANDIDATE_FLAG(false)
  }
  @Mutation
  SET_DIRTY(flag: boolean) {
    this._isDirty = flag
  }
  @Mutation
  SET_EDITING_SCHEDULE(schedule: ScheduleModelTeam) {
    this._editingSchedule = schedule
  }
  @Mutation
  SET_LOADING(isLoading) {
    this._isLoading = isLoading
  }
}

export default getModule(EditScheduleForTeam)
