


























import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import HeaderTitleInput from '@/components/newForm/molecules/HeaderTitle.vue'
import InputField from '@/components/newForm/molecules/InputField.vue'
import FormCandidateDescriptionTextareaItem from '@/components/ui/domain/item/FormCandidateDescriptionTextareaItem.vue'
import { AvailabilityModel } from '@/models/data'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FromGroup,
    HeaderTitleInput,
    InputField,
    FormCandidateDescriptionTextareaItem
  }
})
export default class PatternSidePanelRequired extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: AvailabilityModel

  updateObject() {
    this.$emit('input')
  }
  handleChange(newDesc: string) {
    this.editingObjectSynced.candidateDescription = newDesc
    this.updateObject()
  }
}
