











import NavigationBar from '@/components/auth/NavigationBar.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    NavigationBar
  }
})
