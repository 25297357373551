import {
  useFormAuthorDropdownItem,
  useFormDurationSelectBoxItem,
  useFormTitleInputItem
} from '@/components/ui/domain/item/formItemComposables'
import {
  UsePeekCandidatesSection,
  usePeekCandidatesSectionSetupInitializer
} from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/usePeekCandidatesSectionSetup'
import {
  UseVotingPeekCandidatesSection,
  useVotingPeekCandidatesSectionSetupInitializer
} from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/useVotingPeekCandidatesSectionSetup'
import { usePersonalOthersInfoSectionSetup } from '@/components/ui/domain/section/arrangement/personalOthersInfoSectionComposable'
import {
  DatetimeSlotDeletePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { useModal } from '@/composables/useModal'
import { usePollMixin } from '@/composables/usePollMixin'
import { useSendSignal } from '@/composables/useSendSignal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import { filterByPrimaryCalendar } from '@/lib/utils'
import { ScheduleDuration } from '@/models/data'
import { PollModel } from '@/models/data/poll'
import { AllRouteNames } from '@/router'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import CalendarsModule from '@/store/modules/calendars'
import EditPollModule, { MAX_CANDIDATE_COUNT } from '@/store/modules/editPoll'
import VoteModule, { CandidateForVote } from '@/store/modules/vote'
import { AfterConfirmQueryParams, FullCalendarEvent, ListType } from '@/types/schedule'
import { computed, reactive } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'
import { usePeekCandidatesFormInit } from '../peekCandidatesSectionComposables/usePeekCandidatesFormInit'
import {
  OverwritingData,
  PersonalPollEditablePeekCandidatesSection,
  PersonalPollFormData,
  UpdatePersonalPollForms,
  UpdatePersonalScheduleForms
} from './types'
import { useCandidatesMaxOverWatcher } from './_useCandidatesMaxOverWatcher'
import { useNextAction } from './_useNextAction'
import { useOptionalCalendarOpen } from './_useOptionalCalendarOpen'
import { usePersonalPollPageDataControl } from './_usePersonalPollPageDataControl'
import { useAuthorAndOnlineMeetingsConnect } from './_useSetupBetweenSections'

type UsePersonalPollFormData = {
  pollModel: PollModel
  titleSuggestions: string[]
  saveTitleSuggestion: (title: string) => void
  isEditMode?: boolean
  goToNext?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  cancelEditing?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  openCalendar?: () => void
}
export const usePersonalPollFormData = ({
  pollModel,
  titleSuggestions,
  saveTitleSuggestion,
  isEditMode = false,
  goToNext,
  cancelEditing,
  openCalendar
}: UsePersonalPollFormData): {
  openPollConfirmationModal: (candidateId: string) => void
  updatePersonalPollForms: (data: Partial<UpdatePersonalScheduleForms>) => void
  updatePersonalPollFormData: (overwrite?: OverwritingData) => void
  getPersonalPollFormData
} => {
  const { sendSignal } = useSendSignal()
  const { openPollConfirmModal } = useModal()
  const { openDangerBottomToast } = useToast()
  const i18n = useTranslation()
  const { pollId } = usePollMixin()
  const router = useRouter()
  const votingPeekCandidatesSectionSetupInitializer = useVotingPeekCandidatesSectionSetupInitializer()
  const peekCandidatesSectionSetupInitializer = usePeekCandidatesSectionSetupInitializer()
  const titleForm = useFormTitleInputItem(pollModel.title)
  const durationForm = useFormDurationSelectBoxItem(pollModel.duration)
  const authorForm = useFormAuthorDropdownItem({ accountId: pollModel.accountId, calendarId: pollModel.calendarId })
  const { handleCalendarOpen } = useOptionalCalendarOpen({ openCalendar })
  const {
    descriptionForm,
    onlineMeetingForm,
    locationForm,
    visibilityForm,
    getDefaultOnlineMeetingTool,
    getAvailableOnlineMeetings
  } = usePersonalOthersInfoSectionSetup({
    description: pollModel.description,
    onlineMeetingType: pollModel.onlineMeeting.type,
    location: pollModel.location || '',
    visibility: pollModel.visibility
  })
  const { availableOnlineMeetings, handleOrganizerUpdate } = useAuthorAndOnlineMeetingsConnect({
    authorForm,
    onlineMeetingForm,
    getDefaultOnlineMeetingTool,
    getAvailableOnlineMeetings
  })
  function goToUnconfirmedList() {
    router.push({ name: AllRouteNames.UnconfirmedList }).catch(GuardExceptionHandlers.noopAgainstAbortion)
  }
  const { getNextAction } = useNextAction({ goToNextPage: goToUnconfirmedList })
  const toNext = getNextAction(goToNext)
  const toCancelEditing = getNextAction(cancelEditing)
  const { peekCandidates, peekCandidatesConditionForm, copyTemporaryCandidatesToCandidates } =
    usePeekCandidatesFormInit({ listType: ListType.POLL })

  function isVotingPeekCandidateSection(
    setup: UseVotingPeekCandidatesSection | UsePeekCandidatesSection
  ): setup is UseVotingPeekCandidatesSection {
    return !setup['clearCandidates']
  }
  function getEditablePeekCandidatesSection(
    setup: UsePeekCandidatesSection
  ): PersonalPollEditablePeekCandidatesSection {
    return reactive({
      dateSlots: setup.dateSlots,
      durationToSplit: durationForm.duration,
      onAllDelete: setup.clearCandidates,
      onSave: setup.addNewCandidate,
      onDatetimeDelete: (data: DatetimeSlotDeletePayload) => {
        setup.removeCandidate(data.id)
      },
      onDatetimeUpdate: (data: DatetimeSlotUpdatePayload) => {
        setup.addNewCandidate(data, null)
      },
      onCalendarOpen: handleCalendarOpen
    })
  }
  const currentPoll = computed((): Partial<PollModel> => {
    const personalPoll = {
      title: titleForm.title,
      duration: durationForm.duration,
      accountId: authorForm.value.accountId,
      calendarId: authorForm.value.calendarId,
      location: locationForm.location,
      description: descriptionForm.description,
      visibility: visibilityForm.visibility,
      onlineMeeting: { type: onlineMeetingForm.onlineMeetingType },
      candidates: EditPollModule.editingPoll?.candidates
    }
    return personalPoll
  })

  function updatePollModel(partialModel: Partial<PollModel>) {
    EditPollModule.update(partialModel)
  }
  const { clearDirty, shouldStayHere, pageDataState, handlers } = usePersonalPollPageDataControl({
    pageData: currentPoll,
    updateModel: updatePollModel,
    saveTitleSuggestion,
    isEditMode,
    goToNext: toNext,
    cancelEditing: toCancelEditing
  })
  function confirmPoll(candidateId: string) {
    const votes = VoteModule.getCurrentVotes
    const item: CandidateForVote = votes.find((c) => c.id === candidateId)
    if (!item) {
      return
    }
    async function confirm() {
      try {
        await EditPollModule.confirmPoll(item.id)
        await sendSignal(SignalType.CONFIRM_GROUPPOLL, { id: pollId.value })
        const queryParam: AfterConfirmQueryParams = {
          type: 'group-poll',
          't-or-p': 'private',
          id: pollId.value
        }
        clearDirty()
        return router.push({ name: 'AfterConfirm', query: queryParam })
      } catch (e) {
        openDangerBottomToast({ message: i18n.t('message.errorCommon').toString() })
      }
    }
    openPollConfirmModal({ candidateForVote: item, confirm })
  }
  function generateGotoConfirmation(event: { id: string }) {
    return () => {
      sendSignal(SignalType.CLICK_CONFIRM_ON_EDIT_PAGE)
      confirmPoll(event.id)
    }
  }
  function replaceAllCandidates(newCandidates: { start: string; end: string; id: string }[]) {
    EditPollModule.replaceAllCandidates(newCandidates)
  }
  function selectPeekCandidatesSectionSetup(): UseVotingPeekCandidatesSection | UsePeekCandidatesSection {
    if (isEditMode) {
      const setup: UseVotingPeekCandidatesSection = votingPeekCandidatesSectionSetupInitializer.initialize({
        getFullCalendarEvents: () =>
          EditPollModule.getEditingEventByCalendarFormat.map((c) => ({ ...c, editable: false })),
        deleteCandidate: EditPollModule.removeCandidate,
        generateGotoConfirmation
      })
      return setup
    } else {
      const setup: UsePeekCandidatesSection = peekCandidatesSectionSetupInitializer.initialize({
        saveCandidate: async (payload: { start: Date; end: Date; id?: string }) => {
          await EditPollModule.updateOrAddEventStartAndEnd(payload)
        },
        deleteCandidate: EditPollModule.removeCandidate,
        getFullCalendarEvents: () => EditPollModule.getEditingEventByCalendarFormat,
        generateGotoConfirmation: () => () => {
          /* noop */
        },
        clearAllCandidates: EditPollModule.clearCandidates
      })
      return setup
    }
  }
  const peekCandidatesSectionSetup = selectPeekCandidatesSectionSetup()
  function updateForms(data: Partial<UpdatePersonalPollForms>) {
    if (data.title) titleForm.handleUpdate(data.title)
    if (data.duration) durationForm.handleUpdate(data.duration)
    if (data.author) authorForm.handleUpdate(data.author)
    if (data.description !== undefined) descriptionForm.handleUpdate(data.description)
    if (data.onlineMeeting) onlineMeetingForm.handleUpdate(data.onlineMeeting.type)
    if (data.location !== undefined) locationForm.handleUpdate(data.location)
    if (data.visibility) visibilityForm.handleUpdate(data.visibility)
  }
  // Auto peek candidates watcher
  useCandidatesMaxOverWatcher({
    maxCount: MAX_CANDIDATE_COUNT,
    getOverFlag: () => peekCandidates.tempCandidates.length > MAX_CANDIDATE_COUNT,
    clearOverFlag: () => {
      peekCandidates.tempCandidates = peekCandidates.tempCandidates.slice(0, MAX_CANDIDATE_COUNT)
    }
  })
  // Hand candidates watcher
  useCandidatesMaxOverWatcher({
    maxCount: MAX_CANDIDATE_COUNT,
    getOverFlag: () => EditPollModule.isOverMaxCandidate,
    clearOverFlag: () => EditPollModule.SET_OVER_MAX_CANDIDATE_COUNT(false)
  })
  return {
    openPollConfirmationModal: confirmPoll,
    updatePersonalPollForms: updateForms,
    updatePersonalPollFormData(overwrite?: OverwritingData) {
      updateForms({
        title: overwrite?.title ?? titleForm.title,
        duration: overwrite?.duration ?? durationForm.duration,
        description: overwrite?.description ?? descriptionForm.description,
        location: overwrite?.location ?? locationForm.location,
        visibility: overwrite?.visibility ?? visibilityForm.visibility
      })
      const updatingCandidates = (overwrite?.candidates ?? []).slice(0, MAX_CANDIDATE_COUNT)
      copyTemporaryCandidatesToCandidates(updatingCandidates, replaceAllCandidates)
      updatePollModel({ ...currentPoll.value })
    },
    getPersonalPollFormData(): PersonalPollFormData {
      return reactive({
        clearDirty,
        shouldStayHere,
        pageDataState,
        pageDataControl: handlers,
        temporaryCandidatesInfo: {
          candidates: peekCandidates.tempCandidates
        },
        candidatesInfo: {
          hasAtLeastOneCandidate: peekCandidatesSectionSetup.hasAtLeastOneCandidate,
          candidates: peekCandidatesSectionSetup.candidates,
          addNewCandidate: peekCandidatesSectionSetup.addNewCandidate,
          removeCandidate: peekCandidatesSectionSetup.removeCandidate
        },
        basicInfo: {
          title: {
            value: titleForm.title,
            suggestions: titleSuggestions,
            handleChange: titleForm.handleUpdate,
            disabled: isEditMode
          },
          duration: {
            value: durationForm.duration,
            disabled: isEditMode,
            items: durationForm.items,
            handleChange: (newDuration: ScheduleDuration) => {
              durationForm.handleUpdate(newDuration)
              updatePollModel({ duration: newDuration }) // Todo: 現在はeditPollの管理するdurationも更新するが依存をなくしてeditScheduleと共通化しやすくしたい
            }
          },
          author: {
            value: authorForm.value,
            disabled: isEditMode,
            calendarCollections: filterByPrimaryCalendar(CalendarsModule.getAccountWithcalendars),
            handleChange: handleOrganizerUpdate
          }
        },
        peekCandidatesSection: {
          isEditMode,
          setup: isVotingPeekCandidateSection(peekCandidatesSectionSetup)
            ? peekCandidatesSectionSetup
            : getEditablePeekCandidatesSection(peekCandidatesSectionSetup)
        },
        othersInfo: {
          description: {
            value: descriptionForm.description,
            handleChange: descriptionForm.handleUpdate
          },
          onlineMeeting: {
            value: onlineMeetingForm.onlineMeetingType,
            tools: availableOnlineMeetings,
            handleChange: onlineMeetingForm.handleUpdate
          },
          location: {
            value: locationForm.location,
            handleChange: locationForm.handleUpdate
          },
          visibility: {
            value: visibilityForm.visibility,
            items: visibilityForm.items,
            handleChange: visibilityForm.handleUpdate
          }
        },
        peekCandidatesForm: {
          isCandidateLoading: peekCandidates.isCandidateLoading,
          updateForms: peekCandidatesConditionForm.updateForms,
          allConditions: peekCandidatesConditionForm.allConditions,
          calendarsAutoCompleteForm: peekCandidatesConditionForm.calendarsAutoCompleteForm,
          weekdayForm: peekCandidatesConditionForm.weekdayForm,
          durationForm: peekCandidatesConditionForm.durationForm,
          startForm: peekCandidatesConditionForm.startForm,
          endForm: peekCandidatesConditionForm.endForm,
          timeBufferForm: peekCandidatesConditionForm.timeBufferForm,
          timezoneForm: peekCandidatesConditionForm.timezoneForm,
          countriesForm: peekCandidatesConditionForm.countriesForm,
          mixTemporariesAndOthers: (): FullCalendarEvent[] => {
            return peekCandidates.tempCandidates.concat(peekCandidates.holidayEvents)
          },
          fetchCandidates: peekCandidates.fetchCandidates,
          applyAutoPeekCandidates: () =>
            copyTemporaryCandidatesToCandidates(peekCandidates.tempCandidates, replaceAllCandidates)
        }
      })
    }
  }
}
