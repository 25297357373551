




















import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeZoneModule, { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import TimezoneCommon from './Common.vue'
import TimezoneLabel from '../../molecules/TimezoneLabel.vue'

@Component({
  components: {
    TimezoneCommon,
    TimezoneLabel
  }
})
export default class FullTimezoneSelector extends Vue {
  @Prop({ default: '' }) timeZone: string
  @Prop({ default: false }) updateHeightWhenDropdownIsActive
  @Prop() title
  isDropdownActive = false

  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }
  get selectedTimeZone(): TimezoneDetailWithTimeLabel {
    return TimeZoneModule.timezoneByKey({ key: this.timeZone })
  }

  handleChangeTimeZone(key: string) {
    this.$emit('onChangeTimeZone', key)
  }
  handleActiveChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
  }
}
