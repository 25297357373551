








import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { CardItem } from '@/lib/utils'
import { CardItemTip } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardBodyItem from './CardBodyItem.vue'

@Component({
  components: {
    CardBodyItem
  }
})
export default class ConfirmedScheduleTimeInfoCardBodyItem extends Vue {
  @Prop() timeRange?: { start?: string; end?: string }

  get startEndTime() {
    if (!this.timeRange?.start || !this.timeRange?.end) {
      return ''
    }
    return `${this.formatTime(new Date(this.timeRange.start))} - ${this.formatTime(new Date(this.timeRange.end))}`
  }
  get tipInfo(): CardItemTip {
    return CardItem.convertToCardItemTip(true, this.$t('labels.timePeriod').toString())
  }
  formatTime(date: Date): string {
    return spirDateFormat(date, spirDateFormatTypes.hourMin)
  }
}
