







import PersonalScheduleEditPage from '@/components/page/PersonalScheduleEditPage/index.vue'
import { TitleSuggestionSetups } from '@/components/ui/domain/sections/arrangement/composables/types'
import { useArrangementChangeGuard } from '@/composables/useArrangementChangeGuard'
import { GenTitleSuggestionSetup } from '@/lib/utils'
import { defineComponent, ref } from '@vue/composition-api'
import { NavigationGuard } from 'vue-router'

export default defineComponent({
  components: {
    PersonalScheduleEditPage
  },
  setup() {
    const page = ref(null)
    const { guard } = useArrangementChangeGuard()
    const suggestionSetups: TitleSuggestionSetups = GenTitleSuggestionSetup.genSuggestionSetupsForCreate()
    const { suggestions, save } = suggestionSetups.personalScheduleSuggestionSetup
    function guardBeforeRouteLeave(...args: Parameters<NavigationGuard>) {
      guard(page.value.formData.shouldStayHere, args)
    }
    return {
      page,
      titleSuggestions: suggestions,
      saveTitleSuggestion: save,
      guardBeforeRouteLeave
    }
  },
  beforeRouteLeave(to, from, next) {
    this.guardBeforeRouteLeave(to, from, next)
  }
})
