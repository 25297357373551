












import { Component, Prop, Vue } from 'vue-property-decorator'
import Item from './Item.vue'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { addHours } from 'date-fns'
import { isToday } from '@/lib/utils/timezone'
import { spirDateFormatCustom, spirDateFormatCustomTypes } from '@/lib/utils/dateFormat'

@Component({
  components: {
    Item
  }
})
export default class TimeAxis extends Vue {
  @Prop() gridHeight: string
  @Prop() eventDate: Date
  @Prop() isDisplayWeekDay: boolean

  // Memo: I couldn't make scrollable element without below code..
  mounted() {
    EventBus.on(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  beforeDestroy() {
    EventBus.off(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  get amIToday() {
    return isToday(this.eventDate)
  }
  get weekDay() {
    return spirDateFormatCustom(this.eventDate, spirDateFormatCustomTypes.ccc)
  }
  get dayNumber() {
    return spirDateFormatCustom(this.eventDate, spirDateFormatCustomTypes.d)
  }
  syncScroll(srcElement) {
    const headerLables = this.$refs.axisLabels as HTMLElement
    if (headerLables) {
      headerLables.scrollTo({
        top: srcElement.scrollTop
      })
    }
  }
  dateToShow(index: number) {
    return addHours(this.eventDate, index)
  }
}
