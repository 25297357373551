import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { computed, watch } from '@vue/composition-api'

export const useCandidatesMaxOverWatcherInitializer = () => {
  const { openDangerTopToast } = useToast()
  const i18n = useTranslation()

  return {
    initialize: (data: { maxCount: number; getOverFlag: () => boolean; clearOverFlag: () => void }) => {
      const isOverMax = computed((): boolean => data.getOverFlag())

      watch(isOverMax, (newValue, oldValue) => {
        if (!newValue || newValue === oldValue) {
          return
        }
        openDangerTopToast({ message: i18n.t('message.overMaxCandidate', { count: data.maxCount }).toString() })
        data.clearOverFlag()
      })
    }
  }
}
export const useCandidatesMaxOverWatcher = (data: {
  maxCount: number
  getOverFlag: () => boolean
  clearOverFlag: () => void
}) => {
  const { initialize } = useCandidatesMaxOverWatcherInitializer()
  initialize(data)
}
