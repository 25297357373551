




import { Component, Prop, Vue } from 'vue-property-decorator'
import { IProfileBasic } from '@/types'

@Component
export default class ProfileBasicViewer extends Vue {
  @Prop({ default: {} })
  profile: IProfileBasic
}
