













import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const currentArrangementType = ref('schedule')
    return {
      currentArrangementType,
      handleArrangementTypeChange: (val: 'schedule' | 'poll') => {
        currentArrangementType.value = val
      },
      handleClose: () => ({})
    }
  }
})
