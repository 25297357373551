














import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmModalLayout from '@/components/layout/ConfirmedModal.vue'
import { ListType } from '@/store/modules/listPanel'

@Component({
  components: {
    ConfirmModalLayout
  }
})
export default class ComponentName extends Vue {
  @Prop() timeZone: string

  get getTimeZone() {
    return this.timeZone
  }
  get myType() {
    return ListType.POLL
  }
}
