










import { defineComponent } from '@vue/composition-api'
import Section from '../atoms/Section.vue'

export default defineComponent({
  components: {
    Section
  }
})
