


















import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { SelectItem as OriginalSelectItem } from './types'

export type SelectItem = OriginalSelectItem

export default defineComponent({
  name: 'SpirSelectBox',
  props: {
    value: {
      type: [String, Number] as PropType<string | number>
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    expanded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    leftMaterialIcon: {
      type: String
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium'
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)

    const model = computed({
      get: () => value.value,
      set: (newValue) => emit('input', newValue)
    })
    const hasLeftMaterialIcon = computed((): boolean => !!props.leftMaterialIcon)

    return {
      model,
      hasLeftMaterialIcon
    }
  }
})
