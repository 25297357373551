




















import { defineComponent, PropType } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import FormTargetCalendarsAndMembersForAutoCandidates from '@/components/ui/domain/item/FormTargetCalendarsAndMembersForAutoCandidates.vue'
import FormDurationSelectBoxItem from '@/components/ui/domain/item/FormDurationSelectBoxItem.vue'
import { FormDurationItemSetup } from '@/components/ui/domain/sections/arrangement/peekCandidatesSectionComposables/_usePeekCandidatesConditionForm'
import { FormCalendarsAutoCompleteItemSetup } from '@/components/ui/domain/sections/arrangement/peekCandidatesSectionComposables/_useFormCalendarsAutoCompleteItem'
export default defineComponent({
  name: 'PeekCandidatesFormSection',
  props: {
    calendarsInfo: {
      type: Object as PropType<FormCalendarsAutoCompleteItemSetup>,
      required: true
    },
    durationInfo: {
      type: Object as PropType<FormDurationItemSetup>,
      required: true
    }
  },
  components: {
    ListItemSpacer,
    FormDurationSelectBoxItem,
    FormTargetCalendarsAndMembersForAutoCandidates
  }
})
