




















import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import DropdownWithList from '@/components/newForm/molecules/DropdownWithList.vue'
import { MemberResponse as MemberInfo } from '@spirinc/contracts'
import CalendarMemberAvatar from '@/components/patterns/form/molecules/CalendarMemberAvatar.vue'

@Component({
  components: {
    FormItem,
    DropdownWithList,
    CalendarMemberAvatar
  }
})
export default class FormOrganizerMemberSelector extends Vue {
  @Prop() allMembers: MemberInfo[]
  @Prop() selectedMemberId: string
  @Prop({ default: false }) disabled: boolean

  get selectedMember() {
    return this.allMembers.find((m) => m.id === this.selectedMemberId)
  }
  selectMember(memberId: string) {
    this.$emit('selectedOrganizer', memberId)
  }
}
