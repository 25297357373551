











import { Component, PropSync, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import FormCollapseButton from '@/components/newForm/atoms/CollapseButton.vue'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import TargetNotificationEmailsInput from '@/components/newForm/molecules/TargetNotificationEmailsInput.vue'

@Component({
  components: {
    ValidationObserver,
    WithValidationInput,
    FromGroup,
    FormCollapseButton,
    FormItem,
    TargetNotificationEmailsInput
  }
})
export default class TargetNotificationEmails extends Vue {
  @PropSync('notifications', { type: Array }) notificationsSynced!: { email: string }[]
}
