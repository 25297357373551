
















import SpirButton from '@/components/ui/Button.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { useModal } from '@/composables/useModal'
import { useTranslation } from '@/composables/useTranslation'
import { spirDateFormatCustom, spirDateFormatCustomTypes } from '@/lib/utils/dateFormat'
import { DisplayWeekDay } from '@/models/data'
import { ResourceEvent } from '@/types'
import { DayOfTheWeekRules } from '@spirinc/contracts'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { flatten } from 'lodash'
import WeekdayConditionItem, { CheckState, TimesStringState } from './_WeekdayConditionItem.vue'

export default defineComponent({
  name: 'WeekdayConditionBox',
  components: {
    FormItemSpacer,
    WeekdayConditionItem,
    SpirButton
  },
  props: {
    dayOfTheWeekRules: {
      type: Object as PropType<DayOfTheWeekRules>,
      required: true
    },
    displayWeekdays: {
      type: Array as PropType<DisplayWeekDay[]>,
      default: () => []
    },
    onWeekdayConditionChange: {
      type: Function as PropType<(dayOfTheWeekRules: DayOfTheWeekRules) => void>,
      required: true
    }
  },
  setup(props) {
    const { dayOfTheWeekRules, displayWeekdays, onWeekdayConditionChange } = toRefs(props)
    const i18n = useTranslation()
    const { openWeekdayConditionPickModal } = useModal()

    function getCheckState(weekday: DisplayWeekDay, checkRequired: boolean): CheckState {
      if (weekday.check) return 'checked'
      else if (checkRequired) return 'no-checked'
      else return 'empty'
    }

    function timesString(resourceEvents: ResourceEvent[]) {
      if (!resourceEvents || resourceEvents.length === 0) {
        return ''
      }
      let timeString = `${spirDateFormatCustom(
        resourceEvents[0].start,
        spirDateFormatCustomTypes.H
      )}-${spirDateFormatCustom(resourceEvents[0].end, spirDateFormatCustomTypes.H)}`
      if (resourceEvents.length > 1) {
        timeString += i18n.t('labels.multiple')
      }
      return timeString
    }

    function getTimesStringState(weekday: DisplayWeekDay, timesStringRequired: boolean): TimesStringState {
      if (weekday.timesEvents.length > 0) return { type: 'text', text: timesString(weekday.timesEvents) }
      if (timesStringRequired) return { type: 'no-text' }
      else return { type: 'empty' }
    }

    const weekdays = computed(() => {
      const { hasCheck, hasTimesString } = displayWeekdays.value.reduce(
        (acc, weekday) => {
          acc.hasCheck ||= weekday.check
          acc.hasTimesString ||= weekday.timesEvents.length > 0
          return acc
        },
        { hasCheck: false, hasTimesString: false }
      )

      return displayWeekdays.value.map((weekday) => {
        const checkState = getCheckState(weekday, hasCheck)
        const timesStringState = getTimesStringState(weekday, hasTimesString)
        return {
          key: weekday.key,
          label: weekday.key,
          checkState,
          timesStringState
        }
      })
    })

    return {
      weekdays,
      handleChangeButtonClick: () => {
        const calendarEvents: ResourceEvent[] = flatten(displayWeekdays.value.map((w) => w.timesEvents))
        openWeekdayConditionPickModal({
          props: {
            dayOfTheWeekRules: dayOfTheWeekRules.value,
            calendarEvents
          },
          confirm: (dayOfTheWeekRules: DayOfTheWeekRules) => {
            onWeekdayConditionChange.value(dayOfTheWeekRules)
          }
        })
      }
    }
  }
})
