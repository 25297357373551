







import { useTimeZoneIntervalLabel } from '@/composables/useTimeZoneIntervalLabel'
import { TimezoneDetail } from '@/lib/timezone'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'TimezoneLabel',
  props: {
    timezoneInfo: {
      type: Object as PropType<TimezoneDetail>,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    timezoneLabel: {
      type: String
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'small'
    }
  },
  setup(props) {
    const { timezoneInfo } = toRefs(props)
    const timeZoneKey = computed(() => timezoneInfo.value.key)
    const { timeZoneIntervalLabel } = useTimeZoneIntervalLabel({ timeZoneKey })
    const selectedTimeZoneLabel = computed(() => {
      return `${props.timezoneInfo.fullLabel}(${timeZoneIntervalLabel.value})`
    })
    return {
      selectedTimeZoneLabel
    }
  }
})
