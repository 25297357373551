





























import { Component, Prop, Vue } from 'vue-property-decorator'
import UserBadge from '@/components/molecules/UserBadge.vue'
import Avatar from 'vue-avatar'
import CalendarItemLayout from './CalendarItemLayout.vue'

@Component({
  components: {
    CalendarItemLayout,
    Avatar,
    UserBadge
  }
})
export default class CalendarAvatarAndName extends Vue {
  @Prop({ default: '' }) photoUrl: string
  @Prop({ default: '' }) title: string
  @Prop({ default: false }) showMenu: boolean
  @Prop({ default: false }) disabled: boolean
}
