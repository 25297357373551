













import { defineComponent, toRefs, computed } from '@vue/composition-api'
import { useAfterConfirmSetup } from '@/composables/useAfterConfirmSetup'
import AfterConfirm from '../Index.vue'
import { AfterConfirmModel } from '@/models/data/afterConfirm'
import DisplayTitle from '@/components/newDisplay/Title.vue'
import DateWithTimezone from '@/components/newDisplay/DateWithTimezone.vue'
import OnlineMeeting from '@/components/newDisplay/OnlineMeeting.vue'
import Attendees from '@/components/newDisplay/Attendees.vue'
import Description from '@/components/newDisplay/Descrpition.vue'
import Location from '@/components/newDisplay/Location.vue'
import { getPollById } from '@/lib/api/poll'
import { PollModel } from '@/models/data/poll'

export default defineComponent({
  components: {
    AfterConfirm,
    DisplayTitle,
    DateWithTimezone,
    OnlineMeeting,
    Attendees,
    Description,
    Location
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { id } = toRefs(props)
    const fetchConfirmedInfo = async () => {
      const response = await getPollById(id.value)
      const pollModel = new PollModel(response)
      const afterConfirmModel = AfterConfirmModel.createByPollModel(pollModel)
      return afterConfirmModel
    }
    const properties = computed(() => {
      return {
        type: 'group-poll',
        id: id.value
      }
    })
    const { afterConfirmModel, isLoading } = useAfterConfirmSetup({
      fetchConfirmedInfo,
      properties
    })
    return {
      isLoading: isLoading,
      afterConfirmModel
    }
  }
})
