

















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import { DisplayWeekDay } from '@/models/data'
import WeekdayWithCheck from '@/components/patterns/form/atoms/WeekdayWithCheck.vue'
import DayRangeSelectOnWeeklyView from '@/components/patterns/modals/DayRangeSelectOnWeeklyView.vue'
import { flatten } from 'lodash'
import { ResourceEvent } from '@/types'
import { getHourByUserTimezone } from '@/lib/utils/dateFormat'
import FormIconButton from '@/components/newForm/atoms/IconButton.vue'
import { DayOfTheWeekRules } from '@spirinc/contracts'

@Component({
  components: {
    FormItem,
    WeekdayWithCheck,
    FormIconButton
  }
})
export default class PatternFormConditionWeekDay extends Vue {
  @PropSync('dayOfTheWeekRules', { type: Object }) dayOfTheWeekRulesSynced: DayOfTheWeekRules
  @Prop() displayWeekday: DisplayWeekDay[]

  showPickWeekModel() {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: DayRangeSelectOnWeeklyView,
      hasModalCard: true,
      props: {
        dayOfTheWeekRules: this.dayOfTheWeekRulesSynced,
        calendarEvents: flatten(this.displayWeekday.map((w) => w.timesEvents))
      },
      events: {
        confirm: (dayOfTheWeekRules: DayOfTheWeekRules) => {
          this.dayOfTheWeekRulesSynced = dayOfTheWeekRules
          this.$emit('input')
          modal.close()
        }
      }
    })
  }
  timesString(resourceEvents: ResourceEvent[]) {
    if (!resourceEvents || resourceEvents.length === 0) {
      return ''
    }
    let timeString = `${getHourByUserTimezone(resourceEvents[0].start)}-${getHourByUserTimezone(resourceEvents[0].end)}`
    if (resourceEvents.length > 1) {
      timeString += this.$t('labels.multiple')
    }
    return timeString
  }
}
