import {
  PollStatus,
  VISIBILITY,
  IOnlineMeeting,
  Attendee,
  SpirUser,
  CandidateVote,
  ListType,
  ICalendarListForUI,
  FullCalendarEvent,
  CandidateStatus
} from '@/types'
import CalendarsModule, { encodeCalendarKeyByAccountIdAndCalendarId } from '@/store/modules/calendars'
import { sortBy } from 'lodash'
import { ListClass } from './schedule'
import { getCandidateSource, isEditable } from '@/lib/utils/candidate'
import { separateCandidateByDuration } from '@/store/lib/utils'
import { parseISO, isBefore, differenceInMinutes, addMinutes } from 'date-fns'
import i18n from '@/i18n'
import { isSameEvent } from '@/lib/utils/event'
import { CandidateError } from './error'
import { nanoid } from 'nanoid'
import { ScheduleDuration } from '.'

export type PollParameter = {
  id?: string
  title: string
  duration: ScheduleDuration
  accountId: string
  calendarId: string
  location?: string
  description?: string
  visibility: VISIBILITY
  onlineMtg?: boolean
  onlineMeeting: IOnlineMeeting
  start?: string
  end?: string
  processed?: boolean
  attendees?: Attendee[]
  author?: SpirUser
  candidates: Array<CandidateVote> //todo: make candidate model next time
  createdAt?: string
  updatedAt?: string
  status?: PollStatus
}

export class PollModel implements ListClass {
  id?: string
  title: string
  duration: ScheduleDuration
  accountId: string
  calendarId: string
  location?: string
  description?: string
  visibility: VISIBILITY
  onlineMtg?: boolean
  onlineMeeting: IOnlineMeeting
  processed: boolean
  createdAt?: string
  updatedAt?: string
  attendees?: Attendee[]
  author?: SpirUser
  candidates: Array<CandidateVote> //todo: make candidate model next time
  status: PollStatus
  type: ListType
  calendarKey: string

  name = 'polls'
  constructor(data: PollParameter) {
    this.id = data.id
    this.title = data.title
    this.duration = data.duration
    this.description = data.description
    this.accountId = data.accountId
    this.calendarId = data.calendarId
    this.location = data.location
    this.visibility = data.visibility
    this.onlineMtg = data.onlineMtg
    this.onlineMeeting = data.onlineMeeting
    this.processed = data.processed || false
    this.attendees = data.attendees
    this.author = data.author
    this.candidates = []
    if (data.candidates && data.candidates.length > 0) {
      this.candidates = sortBy(data.candidates, 'start')
    }
    this.status = data.status || 'open'
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.type = ListType.POLL
    const calendarKey = encodeCalendarKeyByAccountIdAndCalendarId(data.accountId, data.calendarId)
    this.calendarKey = calendarKey
  }
  get isInvalid(): boolean {
    return this.status === 'deleted'
  }
  get validCandidates() {
    return this.candidates.filter((c) => {
      return (
        c.status !== CandidateStatus.deleted &&
        c.status !== CandidateStatus.rejected &&
        c.status !== CandidateStatus.expiredPoll &&
        c.status !== CandidateStatus.expired
      )
    })
  }
  get canDisplayDate() {
    return this.status === 'open'
  }
  get confirmedInfo() {
    const confirmedCandidate = this.candidates.find((c) => c.status === 'confirmed')
    return confirmedCandidate
  }
  get calendarInfo() {
    const allCalendars: ICalendarListForUI = CalendarsModule.flattenCalendars
    if (!allCalendars[this.calendarKey]) {
      return null
    }
    return {
      backgroundColor: allCalendars[this.calendarKey].backgroundColor,
      foregroundColor: allCalendars[this.calendarKey].foregroundColor,
      calendarName: allCalendars[this.calendarKey].title
    }
  }
  get isConfirmed(): boolean {
    if (!this.candidates || this.candidates.length === 0) {
      return false
    }
    return this.status === 'confirmed'
  }
  get getCandidatesToDisplayOnCalendar() {
    return this.candidates.filter((c) => c.status !== 'deleted')
  }
  get start() {
    if (!this.getCandidatesToDisplayOnCalendar?.length) {
      return undefined
    }
    return this.getCandidatesToDisplayOnCalendar[0].start
  }
  get end() {
    if (!this.getCandidatesToDisplayOnCalendar?.length) {
      return undefined
    }
    return this.getCandidatesToDisplayOnCalendar[this.getCandidatesToDisplayOnCalendar.length - 1].end
  }
  get getEditingEventByCalendarFormat(): FullCalendarEvent[] {
    const accountId = this.accountId
    const calendarId = this.calendarId
    return this.getCandidatesToDisplayOnCalendar.map((c: CandidateVote) => {
      const source = getCandidateSource(c.status, parseISO(c.start))
      return {
        title: this.status === 'confirmed' ? this.title : `${i18n.t('labels.candidate').toString()}`,
        id: c.id,
        start: parseISO(c.start),
        end: parseISO(c.end),
        extendedProps: {
          source,
          accountId,
          calendarId,
          newCandidate: !c.status
        },
        editable: isEditable(c.status),
        clickable: !(source === 'expiredPoll')
      }
    })
  }
  get urlForConfirmer(): string {
    return `${window.location.origin}/${this.name}/${this.id}/vote`
  }
  addCandidate(start: Date, end: Date) {
    if (isBefore(start, new Date())) {
      throw new CandidateError('mustBeBeforeNow')
    }
    let newCandidates: Array<{ start: Date; end: Date }> = []
    if (differenceInMinutes(end, start) < this.duration) {
      end = addMinutes(start, this.duration)
      newCandidates.push({
        start,
        end
      })
    } else {
      newCandidates = separateCandidateByDuration({ start, end }, this.duration)
    }

    if (
      this.candidates.some((c) => {
        return newCandidates.some((nc) => {
          return isSameEvent({ start: new Date(c.start), end: new Date(c.end) }, { start: nc.start, end: nc.end })
        })
      })
    ) {
      throw new CandidateError('intersection')
    }
    this.candidates = [
      ...this.candidates,
      ...newCandidates.map((c) => ({
        start: c.start.toISOString(),
        end: c.end.toISOString(),
        id: nanoid()
      }))
    ]
    this.candidates = sortBy(this.candidates, 'start')
  }
}
