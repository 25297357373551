









import { Component, Prop, Vue } from 'vue-property-decorator'
import ShareButtom from '@/components/profile/ShareButton.vue'
import { IProfileBasic } from '../../types'

@Component({
  components: {
    ShareButtom
  }
})
export default class ProfileMobileNav extends Vue {
  @Prop({ default: false })
  isMobileOnly: boolean

  @Prop()
  profile: IProfileBasic

  @Prop({ default: true })
  shareable: boolean
}
