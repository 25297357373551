import { FromUserInfoModel } from './../../lib/utils'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { ISchedule, Candidate, ISchedulePollCommon, FullCalendarEvent, ScheduleStatusUpdateAction } from '@/types'
import { cloneDeep, pick } from 'lodash'
import store from '@/store'
import AutoCandidatesModule from './autoCandidate'
import ScheduleListModule from './scheduleList'
import { getNewCandidates, getInactiveCandidates, getDefaultOnlineMeetintType } from '../lib/utils'
import ScheduleLocalStorage from '@/models/localStorage/Schedule'
import CalendarsModule from '@/store/modules/calendars'
import { ScheduleModel } from '@/models/data/schedule'
import * as scheduleAPI from '../../lib/api/schedule'
import CandidatesModule from './candidates'
import EventsModule from './events'
import AfterConfirmModule from '@/store/modules/afterConfirm'
import ProfileModule from '@/store/modules/profile'
import { Confirmer } from '@/models/data'
import { Duration } from '@/models/data/availability'
import { AfterConfirmModelFactoryForSchedule } from '@/models/data/AfterConfirmModelFactory/schedule'

const updateResult = ['candidateRemoved', 'onlineMtgUpdated'] as const
export type UpdateResult = typeof updateResult[number] | null
export const MAX_CANDIDATE_COUNT = 50

if (store.state['EditSchedule']) {
  store.unregisterModule('EditSchedule')
}

@Module({
  dynamic: true,
  name: 'EditSchedule',
  namespaced: true,
  store
})
class EditSchedule extends VuexModule {
  _editingSchedule: ScheduleModel = null
  _isDirty = false
  _submitted = false
  _overMaxCandidate = false
  localStorageSaver = new ScheduleLocalStorage()
  isLoading = false

  get getIsLoading() {
    return this.isLoading
  }
  get canConfirm() {
    return (
      this._editingSchedule &&
      this._editingSchedule.actions?.some((a: ScheduleStatusUpdateAction) => a.name === 'confirm')
    )
  }
  get isMine() {
    return (
      this._editingSchedule && this._editingSchedule.author?.id === this.context.rootGetters['User/currentUser']?.uid
    )
  }
  get isDirty() {
    return this._isDirty
  }
  // editingScheduleと同じ。vuex-module-decoratorではInterfaceによる共通化ができなかったので同じGetterをPollにも入れる
  get editingForm(): ScheduleModel {
    return this._editingSchedule
  }
  get editingSchedule(): ScheduleModel {
    return this._editingSchedule
  }
  get isValid() {
    return this._editingSchedule && !!this._editingSchedule.title
  }
  get isExist() {
    return !!this._editingSchedule
  }
  get isSyncing() {
    return !(this.isExist && this._editingSchedule.processed)
  }
  get getExistCandidates() {
    if (!this.isExist) {
      return []
    }
    return this._editingSchedule.candidates.filter((c) => c.status !== 'deleted')
  }
  get getValidCandidates() {
    if (!this.isExist) {
      return []
    }
    return this.getExistCandidates.filter((c) => c.status !== 'expired' && c.status !== 'rejected')
  }

  get getEditingEventByCalendarFormat(): FullCalendarEvent[] {
    if (!this.isExist) {
      return []
    }
    return this.editingSchedule.getEditingEventByCalendarFormat
  }
  get getPreviewEventByCalendarFormat(): FullCalendarEvent[] {
    if (!this.isExist) {
      return []
    }
    return this.editingSchedule.getPreviewEventByCalendarFormat()
  }
  get isOverMaxCandidate() {
    return this._overMaxCandidate
  }
  @Mutation
  SET_EDITING_SCHEDULE(schedule: ScheduleModel) {
    this._editingSchedule = schedule
    if (schedule) {
      let confirmedInfo = null
      if (schedule.status === 'confirmed') {
        const confirmedCandidate = schedule.candidates.find((c) => c.status === 'confirmed')
        if (confirmedCandidate) {
          confirmedInfo = pick(confirmedCandidate, ['start', 'end', 'eventId'])
        }
      }
      this._editingSchedule.confirmedInfo = confirmedInfo
    }
  }

  @Mutation
  SET_DIRTY(flag: boolean) {
    this._isDirty = flag
  }
  @Mutation
  SET_OVER_MAX_CANDIDATE_COUNT(isOver: boolean) {
    this._overMaxCandidate = isOver
  }
  @Mutation
  SET_LOADING(isLoading: boolean) {
    this.isLoading = isLoading
  }
  @Action
  startCreatingNewSchedule() {
    const newSchedule = new ScheduleModel()
    const userInfo = ProfileModule.userInfo
    const authorInfo = FromUserInfoModel.convertToSpirUser(userInfo)
    newSchedule.author = authorInfo
    this.SET_DIRTY(false)
    CalendarsModule.visibleCalendarIfNotVisible({
      accountId: newSchedule.accountId,
      calendarId: newSchedule.calendarId
    })
    return this.SET_EDITING_SCHEDULE(newSchedule)
  }
  @Action
  async fetchSchedule({ scheduleId, hideLoading }: { scheduleId: string; hideLoading?: boolean }) {
    if (!hideLoading) {
      this.SET_LOADING(true)
    }
    try {
      const res = await scheduleAPI.getScheduleById(scheduleId)
      return new ScheduleModel(res)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async setScheduleAsEditingSchedule(payload: { scheduleId: string; hideLoading?: boolean }) {
    const schedule: ScheduleModel = await this.fetchSchedule(payload)
    if (schedule.status === 'confirmed') {
      await AfterConfirmModule.setModelFromSchedule(schedule)
    }
    this.SET_DIRTY(false)
    this.SET_EDITING_SCHEDULE(schedule)
  }
  @Action
  async addCandidate(payload: { start: Date; end: Date; id?: string }) {
    const currentSchedule = new ScheduleModel({ ...this._editingSchedule })
    currentSchedule.addCandidate(payload.start, payload.end, payload.id)
    this.updateScheduleWithAdjustCandidate(currentSchedule)
    AutoCandidatesModule.SET_AUTO_CANDIDATE_FLAG(false)
    this.SET_DIRTY(true)
  }
  @Action
  updateOrAddEventStartAndEnd(payload) {
    const newPayload = pick(payload, 'id', 'end', 'start')
    return this.addCandidate(newPayload)
  }
  // schedule をCreate,update した後の共通処理
  @Action
  async afterUpdate(schedule: ScheduleModel) {
    ScheduleListModule.fetchScheduleList({ hideLoader: true })
    ScheduleListModule.ON_ADD_UPDATE_SCHEDULE(schedule)
    CandidatesModule.updateScheduleCandidates(schedule)
    this.SET_DIRTY(false)
  }
  @Action
  async createSchedule() {
    this.SET_LOADING(true)
    try {
      this.localStorageSaver.saveToLocalStorage(this._editingSchedule)
      const response = await scheduleAPI.create(this._editingSchedule)
      const createdSchedule = new ScheduleModel(response)
      this.afterUpdate(createdSchedule)
      this.SET_EDITING_SCHEDULE(createdSchedule)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async confirmSchedule({
    scheduleId,
    start,
    candidateId,
    duration,
    attendees,
    confirmer
  }: {
    scheduleId: string
    start: string
    candidateId: string
    duration: Duration
    attendees: { name: string; email: string }[]
    confirmer: Confirmer
  }) {
    this.SET_LOADING(true)
    try {
      const data = {
        start,
        candidateId,
        duration,
        attendees,
        ...confirmer
      }
      const response = await scheduleAPI.confirm(data, scheduleId)
      const newModel = new ScheduleModel(response)
      if (this.isMine) {
        this.afterUpdate(newModel)
      }
      const confirmedInfo = await scheduleAPI.getConfirmedEvent(scheduleId)
      const afterModel = AfterConfirmModelFactoryForSchedule.create(confirmedInfo.schedule, confirmedInfo.event)
      AfterConfirmModule.SET_MODEL(afterModel)
      this.update(response)
      EventsModule.fetchEvents()
      return response
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async deleteCandidate({ scheduleId, candidateId }) {
    const currentSchedule: ScheduleModel = await ScheduleListModule.getScheduleByScheduleId(scheduleId)
    if (!currentSchedule) {
      return
    }
    this.SET_LOADING(true)
    try {
      await scheduleAPI.deleteCandidate(scheduleId, candidateId)
      const candidateIndex = currentSchedule.candidates.findIndex((c) => c.id === candidateId)
      if (candidateIndex >= 0) {
        currentSchedule.candidates.splice(candidateIndex, 1)
      }
      this.afterUpdate(currentSchedule)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async updateSchedule() {
    const newSchedule: ScheduleModel = cloneDeep(this._editingSchedule)
    const currentSchedule: ScheduleModel = await ScheduleListModule.getScheduleByScheduleId(newSchedule.id)
    if (!currentSchedule) {
      return
    }
    this.SET_LOADING(true)
    try {
      if (this._editingSchedule.status === 'requestedByConfirmer') {
        const newCandidates = getNewCandidates(newSchedule.candidates)
        await scheduleAPI.executeScheduleStatusUpdateAction(newSchedule.id, 'organizerResponse', newCandidates, null)
        newSchedule.candidates = newCandidates
        this.afterUpdate(newSchedule)
      } else {
        this.localStorageSaver.saveToLocalStorage(newSchedule)
        newSchedule.candidates = newSchedule.candidates.filter((c) => c.status !== 'deleted' && c.status !== 'expired')
        const response = await scheduleAPI.updateSchedule(newSchedule)
        const updatedSchedule = new ScheduleModel(response)
        this.afterUpdate(updatedSchedule)
      }
      return newSchedule.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  setAutoGeneratedCandidateToSchedule() {
    const newSchedule = new ScheduleModel({ ...this._editingSchedule })
    const generatedCandidates = AutoCandidatesModule.autoGenratedCandidates
    const remainingCandidates = getInactiveCandidates(newSchedule.candidates)
    newSchedule.candidates = generatedCandidates.concat(remainingCandidates)
    AutoCandidatesModule.SET_AUTO_CANDIDATE_FLAG(true)
    this.updateScheduleWithAdjustCandidate(newSchedule)
    this.SET_DIRTY(true)
  }
  @Action
  update(payload: Partial<ISchedule>) {
    const newSchedule = new ScheduleModel({ ...this._editingSchedule, ...payload })
    let response: UpdateResult = null
    if (!newSchedule.removeCandidateIfitsUnderNewDuration()) {
      response = 'candidateRemoved'
    } else if (
      this._editingSchedule.accountId !== newSchedule.accountId ||
      this._editingSchedule.calendarId !== newSchedule.calendarId
    ) {
      const onlineMeeting = getDefaultOnlineMeetintType(
        CalendarsModule.getCalendar({ accountId: newSchedule.accountId, calendarId: newSchedule.calendarId }),
        newSchedule.onlineMeeting
      )
      if (newSchedule.onlineMeeting?.type !== onlineMeeting.type) {
        response = 'onlineMtgUpdated'
        newSchedule.onlineMeeting = onlineMeeting
      }
    }
    this.SET_EDITING_SCHEDULE(newSchedule)
    this.SET_DIRTY(true)
    return response
  }
  @Action
  clearCandidates() {
    const newSchedule = new ScheduleModel({ ...this._editingSchedule })
    newSchedule.candidates = []
    this.SET_EDITING_SCHEDULE(newSchedule)
  }
  @Action
  replaceAllCandidates(newCandidates: Candidate[]) {
    const newSchedule = new ScheduleModel({ ...this._editingSchedule })
    newSchedule.candidates = newCandidates
    this.SET_EDITING_SCHEDULE(newSchedule)
  }
  @Action
  async removeCandidate(candidateId) {
    const newSchedule = new ScheduleModel({ ...this._editingSchedule })
    if (newSchedule.removeCandidate(candidateId)) {
      this.updateScheduleWithAdjustCandidate(newSchedule)
      this.SET_DIRTY(true)
    }
  }

  @Action
  initEditingSchedule() {
    this.SET_EDITING_SCHEDULE(null)
    this.SET_DIRTY(false)
    this.SET_OVER_MAX_CANDIDATE_COUNT(false)
    AutoCandidatesModule.SET_AUTO_CANDIDATE_FLAG(false)
  }

  @Action
  copyFromEditingObject(otherSchedule: ISchedulePollCommon) {
    const newSchedule = cloneDeep(otherSchedule)
    this.SET_EDITING_SCHEDULE(new ScheduleModel(newSchedule))
    this.SET_DIRTY(true)
  }
  // fixme: これはModelに移したいが、次にしましょう。
  @Action
  updateScheduleWithAdjustCandidate(schedule: ScheduleModel) {
    const newSchedule = new ScheduleModel({ ...schedule })
    if (newSchedule.candidates.length > MAX_CANDIDATE_COUNT) {
      this.SET_OVER_MAX_CANDIDATE_COUNT(true)
      newSchedule.candidates = newSchedule.candidates.slice(0, MAX_CANDIDATE_COUNT)
    } else {
      this.SET_OVER_MAX_CANDIDATE_COUNT(false)
    }
    this.SET_EDITING_SCHEDULE(new ScheduleModel(newSchedule))
  }
}

export default getModule(EditSchedule)
