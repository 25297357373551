





































import { CardTitleActionItem } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

type IconType = 'schedule' | 'poll' | 'none'
export default defineComponent({
  props: {
    icon: {
      type: String as PropType<IconType>,
      default: 'none'
    },
    title: {
      type: String,
      required: true
    },
    actionItems: {
      type: Array as PropType<Array<CardTitleActionItem>>,
      default: () => []
    }
  }
})
