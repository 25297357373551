





















import { Component, Prop, Vue } from 'vue-property-decorator'
import AttendeeIcon from '@/components/atoms/AttendeeIcon.vue'
import { UserVote, Attendee, PollAnswer, SpirAttendeeStatus } from '@/types'

type AttendeeIconsType = Pick<UserVote, 'attendee'> & Partial<Pick<UserVote, 'answer'>>

@Component({
  components: {
    AttendeeIcon
  }
})
export default class PollAttendeeIcons extends Vue {
  @Prop() attendeesWithStatus: AttendeeIconsType[]
  @Prop() attendees: Attendee[]

  get attendeeList() {
    if (this.attendees) {
      return this.attendees.map((a) => ({ attendee: a }))
    }
    return this.attendeesWithStatus
  }
  getAttendanceStatus(answer?: PollAnswer): SpirAttendeeStatus {
    if (!this.attendeesWithStatus) {
      return SpirAttendeeStatus.ACCEPTED
    }
    return answer === 'yes' ? SpirAttendeeStatus.ACCEPTED : SpirAttendeeStatus.DECLIEND
  }
}
