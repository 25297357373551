export class BrowserVisibilityDetector {
  private _player: { play: () => Promise<void>; pause: () => Promise<void> }
  private _handler: () => void

  constructor(player: { play: () => Promise<void>; pause: () => Promise<void> }) {
    this._player = player
    this._handler = () => {
      if (document.visibilityState === 'visible') {
        this._player.play()
      } else {
        this._player.pause()
      }
    }
  }
  on() {
    document.addEventListener('visibilitychange', this._handler)
    window.addEventListener('beforeunload', () => {
      this._player.pause()
    })
  }
  off() {
    document.removeEventListener('visibilitychange', this._handler)
    window.removeEventListener('beforeunload', this._player.pause)
  }
}
