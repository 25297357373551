







import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmText from './ConfirmText.vue'
import TimezoneButtonWithFullLabel from '@/components/organisms/timezoneButton/TimezoneButtonWithFullLabel.vue'
import { adjustDurationUnit } from '@/lib/utils'

@Component({
  components: { ConfirmText, TimezoneButtonWithFullLabel }
})
export default class ConfirmTimeZoneSelect extends Vue {
  @Prop({ required: true }) dateRange: string
  @Prop({ required: true }) duration: number

  get text(): string {
    const displayDuration = adjustDurationUnit(this.duration)
    const durationText =
      displayDuration.type === 'hour'
        ? this.$t('poll.list.durationHour.message', { hour: displayDuration.duration }).toString()
        : this.$t('poll.list.duration.message', { minute: displayDuration.duration }).toString()
    return `${this.dateRange} ${durationText}`
  }
}
