















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EmailItem',
  props: {
    email: { type: String, required: true }
  }
})
