import { AvailabilityOverviewModel, AvailabilityOverviewModelForTeam, ConfirmAvailabilityModel } from '@/models/data'
import { AfterConfirmModelForAvailability } from '@/models/data/afterConfirm'
import store from '@/store'
import { FullCalendarEvent } from '@/types'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ConfirmAvailabilityModelData } from './../../models/data/availabilityForConfirm'
import AfterConfirmModule from './afterConfirm'
import CalendarsModule from './calendars'
import EventsModule from './events'
import EventToConfirmModule from './eventToConfirm'

const MODULE_NAME = 'ConfirmAvailability'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class ConfirmAvailability extends VuexModule {
  _confirmAvailability: ConfirmAvailabilityModel = null
  _overview: AvailabilityOverviewModel = null
  _isLoading = false
  _isOverviewLoading = false
  _isConfirming = false
  _canceled = false

  get isConfirming() {
    return this._isConfirming
  }
  get getOverview() {
    return this._overview
  }
  get getModel() {
    return this._confirmAvailability
  }
  get getIsLoading() {
    return this._isLoading
  }
  get getIsOverviewLoading() {
    return this._isOverviewLoading
  }
  get candidatesByConditionAsCalendarFormat(): FullCalendarEvent[] {
    if (!this._confirmAvailability) {
      return []
    }
    return this._confirmAvailability.candidates
  }
  get candidatesByMerged(): FullCalendarEvent[] {
    if (!this._confirmAvailability) {
      return []
    }
    return this._confirmAvailability.mergedCandidates
  }
  @Action
  async confirmOne(
    persistAvailabilityConfirm: () => Promise<{ afterConfirmModel: AfterConfirmModelForAvailability; email: string }>
  ) {
    this.SET_IS_CONFIRMING(true)
    try {
      const { afterConfirmModel, email } = await persistAvailabilityConfirm()
      AfterConfirmModule.setAfterConfirmModelForAvailability(afterConfirmModel)
      this.afterConfirm(email)
    } finally {
      this.SET_IS_CONFIRMING(false)
    }
  }
  /**
   * 確定時に入力したEmailでEventsをFetchする。複数ありえるが、すべて再度Fetchする
   * @param email
   */
  @Action
  async afterConfirm(email: string) {
    const calendarsByEmail = CalendarsModule.getCalendarsByEmail(email)
    EventsModule.fetchEvents({
      targetCalendars: calendarsByEmail.map((c) => ({ accountId: c.accountId, calendarId: c.calendarId })),
      option: {
        sequantial: true
      }
    })
    EventToConfirmModule.resetEventToConfirm()
  }
  @Action({ commit: 'SET_MODEL' })
  async fetchConfirmAvailabilityModel(getModelData: () => Promise<ConfirmAvailabilityModelData>) {
    this.SET_LOADING(true)
    try {
      const response = await getModelData()
      return response
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action({ commit: 'SET_OVERVIEW' })
  async fetchConfirmAvailabilityOverviewModel(
    getModelData: () => Promise<AvailabilityOverviewModel | AvailabilityOverviewModelForTeam>
  ) {
    this.SET_OVERVIEW_LOADING(true)
    try {
      const overviewModel = await getModelData()
      return overviewModel
    } finally {
      this.SET_OVERVIEW_LOADING(false)
    }
  }
  @Action
  reset() {
    this.SET_MODEL(null)
  }
  @Mutation
  SET_MODEL(availaility: ConfirmAvailabilityModelData) {
    if (availaility) {
      this._confirmAvailability = new ConfirmAvailabilityModel(availaility)
    } else {
      this._confirmAvailability = null
    }
  }
  @Mutation
  SET_LOADING(isLoading) {
    this._isLoading = isLoading
  }
  @Mutation
  SET_IS_CONFIRMING(isConfirming: boolean) {
    this._isConfirming = isConfirming
  }
  @Mutation
  SET_OVERVIEW_LOADING(overviewLoading: boolean) {
    this._isOverviewLoading = overviewLoading
  }
  @Mutation
  SET_OVERVIEW(overview?: AvailabilityOverviewModel) {
    this._overview = overview
  }
}

export default getModule(ConfirmAvailability)
