

















import { defineComponent } from '@vue/composition-api'
import Vue100vh from 'vue-div-100vh'
import BaseLayout from '@/components/layout/Base.vue'
import NavigationBar from '@/components/auth/NavigationBar.vue'

export default defineComponent({
  name: 'TeamInvitedLayout',
  components: {
    Vue100vh,
    BaseLayout,
    NavigationBar
  }
})
