










import { defineComponent, PropType } from '@vue/composition-api'
import FormSelectBoxItem from '@/components/ui/form/item/FormSelectBoxItem.vue'
import { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'
import { Duration } from '@/models/data'

export default defineComponent({
  name: 'FormDurationSelectBoxItem',
  components: {
    FormSelectBoxItem
  },
  props: {
    value: {
      type: Number as PropType<Duration>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  }
})
