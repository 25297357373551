




import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as Sentry from '@sentry/vue'

// const transaction = Sentry.startTransaction({ name: "show-loader" });
// const span = transaction.startChild({ op: "functionX" });
@Component
export default class LoadingSpinner extends Vue {
  @Prop() active: boolean
  @Prop() fullPage: boolean
  @Prop() loaderId: string
  @Prop({ default: false }) disableTracing: boolean

  @Watch('active')
  setTransaction(newValue: boolean) {
    if (!this.disableTracing) {
      return
    }
    // false -> true
    if (newValue) {
      if (this.transaction || this.span) {
        return
      }
      this.transaction = Sentry.startTransaction({ name: 'loader-transaction' })
      this.span = this.transaction.startChild({ op: 'show loader' })
    } else {
      if (!this.transaction || !this.span) {
        return
      }
      this.span.finish()
      this.transaction.finish()
    }
  }
  transaction = null
  span = null
}
