
























import Divider from '@/components/atoms/Divider.vue'
import PersonalPollBasicInfoSection from '@/components/ui/domain/section/arrangement/BasicInfoSection/PersonalPollBasicInfoSection.vue'
import OthersInfoSection from '@/components/ui/domain/section/arrangement/OthersInfoSection.vue'
import EditablePeekCandidatesSection from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/EditablePeekCandidatesSection.vue'
import VotingPeekCandidatesSection from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/VotingPeekCandidatesSection.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ListItemSpacer,
    PersonalPollBasicInfoSection,
    Divider,
    OthersInfoSection,
    VotingPeekCandidatesSection,
    EditablePeekCandidatesSection
  },
  props: {
    formData: {
      type: Object,
      required: true
    }
  }
})
