





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { validate } from 'vee-validate'
import Avatar from 'vue-avatar'

export type EmailSelectorItem = {
  photoURL?: string
  email: string
  title?: string
}

@Component({
  components: {
    Avatar
  }
})
export default class FormEmailSelector extends Vue {
  @Prop() placeholder: string
  @Prop({ default: () => [] }) targetEmails: EmailSelectorItem[]
  @Prop({ default: () => [] }) extractList: string[]

  inputedValue = ''
  isValidEmail = false
  listForAutoComplete: EmailSelectorItem[] = []

  $refs: {
    autocomplete: any
  }

  async updateList() {
    if (!this.inputedValue) {
      this.listForAutoComplete = this.targetEmails
      return
    }
    if (this.extractList.indexOf(this.inputedValue) >= 0) {
      this.listForAutoComplete = []
      return
    }
    const filterdList = this.targetEmails.filter((t) => {
      const searchTargetAttrs = [t.email, t.title]
      return searchTargetAttrs.join(' ').toLowerCase().indexOf(this.inputedValue.toLowerCase()) >= 0
    })
    if (filterdList.length > 0) {
      this.listForAutoComplete = filterdList
      return
    }
    const validResult = await validate(this.inputedValue, 'required|email')
    if (validResult.valid) {
      this.listForAutoComplete = [
        {
          title: this.inputedValue,
          email: this.inputedValue
        }
      ]
    } else {
      this.listForAutoComplete = []
    }
  }
  selected(selectedItem: EmailSelectorItem) {
    if (!selectedItem) {
      return
    }
    this.$emit('addedEmail', {
      email: selectedItem.email
    })
    // hack: 選択後、リストの一番目のDataが選択されるBugを回避するため。
    if (this.$refs.autocomplete) {
      this.$refs.autocomplete.isActive = false
    }
    this.inputedValue = ''
  }
  async onBlur() {
    setTimeout(() => {
      this.inputedValue = ''
    }, 200)
  }
  onFocus() {
    this.listForAutoComplete = this.targetEmails
  }
}
