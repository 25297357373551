










import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import SpirButton from '@/components/ui/Button.vue'
import { defineComponent, PropType } from '@vue/composition-api'
import AddCalendarDescription from '@/components/auth/addCalendar/AddCalendarDescription.vue'
import { SignalType } from '@/lib/analytics'

export default defineComponent({
  props: {
    onGoogleSignUp: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  components: {
    AuthFormSpacer,
    AddCalendarDescription,
    SpirButton
  },
  setup() {
    return {
      confirmButtonSignalKey: SignalType.CLICK_CONNECT_GOOGLE_ACCOUNT
    }
  }
})
