import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import * as availabilityAPI from '@/lib/api/availability'
import { getList, deletePattern, updatePattern, fetchCandidatesByParam, updateOrder } from '@/lib/api/availabilityTeam'
import {
  AvailabilityModel,
  AvailabilityModelForPrivate,
  AvailabilityModelForTeam,
  PatternCandidateModel
} from '@/models/data'
import { FullCalendarEvent } from '@/types'
import { orderBy } from 'lodash'

type Types = 'team' | 'private'

const MODULE_NAME = 'AvailabilitySharingList'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class AvailabilitySharingList extends VuexModule {
  _privateList: { isLoading: boolean; list: AvailabilityModelForPrivate[] } = {
    isLoading: false,
    list: []
  }
  _teamList: { [key: string]: { isLoading: boolean; list: AvailabilityModelForTeam[] } } = {}

  _fetchedPatternCandidate: { model?: PatternCandidateModel; id: string; isLoading: boolean; teamId?: string } | null =
    null

  get getList() {
    return (teamId?: string) => {
      return !teamId ? this._privateList : this._teamList[teamId]
    }
  }

  get privateList() {
    return this._privateList
  }

  get teamLists() {
    return this._teamList
  }

  get isfetchedCandidateLoading(): boolean {
    return this._fetchedPatternCandidate?.isLoading
  }

  get fetchedCandidates(): FullCalendarEvent[] {
    return this._fetchedPatternCandidate?.model?.mergedCandidates || []
  }
  get fetchedPatternCandidate() {
    return this._fetchedPatternCandidate
  }
  @Action
  async fetchPatterns(teamId?: string, query?: string) {
    const type: Types = teamId ? 'team' : 'private'
    this.SET_LOADING({ loading: true, teamId })
    try {
      if (type === 'private') {
        const patterns = (await availabilityAPI.getList()).availabilitySharings
        const patternModels = patterns.map((p) => new AvailabilityModelForPrivate(p))
        this.SET_PRIVATE_LIST(patternModels)
      } else {
        const patterns = (await getList(teamId, query)).availabilitySharings
        const patternModels = patterns.map((p) => new AvailabilityModelForTeam(teamId, p))
        this.SET_TEAM_LIST({ teamId, list: patternModels })
      }
    } finally {
      this.SET_LOADING({ loading: false, teamId })
    }
  }
  @Action
  async deletePattern(pattern: AvailabilityModel) {
    const patternId = pattern.id
    let teamId
    if (pattern.type === 'team') {
      teamId = (pattern as AvailabilityModelForTeam).teamId
    }
    if (pattern.type === 'private') {
      await availabilityAPI.deletePattern(patternId)
      this.UPDATE_PRIVATE_LIST({ id: patternId })
    } else {
      await deletePattern(teamId, patternId)
      this.UPDATE_TEAM_LIST({ id: patternId, teamId })
    }
  }
  @Action
  async togglePublish(pattern: AvailabilityModel) {
    const patternId = pattern.id
    let teamId
    if (pattern.type === 'team') {
      teamId = (pattern as AvailabilityModelForTeam).teamId
    }
    if (pattern.type === 'private') {
      const pattern = this._privateList.list.find((p) => p.id === patternId)
      pattern.isLoading = true
      try {
        this.UPDATE_PRIVATE_LIST({ id: patternId, newModel: pattern })
        const response = await availabilityAPI.updatePattern(patternId, { isPublished: !pattern.isPublished })
        const newModel = new AvailabilityModelForPrivate(response)
        this.UPDATE_PRIVATE_LIST({ id: patternId, newModel })
      } catch (e) {
        pattern.isLoading = false
        this.UPDATE_PRIVATE_LIST({ id: patternId, newModel: pattern })
        throw e
      }
    } else {
      const pattern = this._teamList[teamId].list.find((p) => p.id === patternId)
      pattern.isLoading = true
      try {
        this.UPDATE_TEAM_LIST({ id: patternId, teamId, newModel: pattern })
        const response = await updatePattern(teamId, patternId, { isPublished: !pattern.isPublished })
        const newModel = new AvailabilityModelForTeam(teamId, response)
        this.UPDATE_TEAM_LIST({ id: patternId, teamId, newModel })
      } catch (e) {
        pattern.isLoading = false
        this.UPDATE_TEAM_LIST({ id: patternId, teamId, newModel: pattern })
        throw e
      }
    }
  }
  @Action
  async order({ sharingIds, teamId }: { sharingIds: string[]; teamId?: string }) {
    const type: Types = teamId ? 'team' : 'private'
    this.SET_LOADING({ loading: true, teamId })
    try {
      if (type === 'private') {
        await availabilityAPI.order({ order: sharingIds })
        await this.fetchPatterns()
      } else {
        await updateOrder(teamId, { order: sharingIds })
        await this.fetchPatterns(teamId)
      }
    } finally {
      this.SET_LOADING({ loading: false, teamId })
    }
  }
  @Action
  async fetchCandidatesByModel(pattern: AvailabilityModel) {
    const teamId = pattern.type === 'team' ? (pattern as AvailabilityModelForTeam).teamId : undefined
    try {
      this.SET_CANDIDATE_MODEL({ id: pattern.id, teamId, isLoading: true })
      const response =
        pattern.type === 'private'
          ? await availabilityAPI.fetchCandidatesByParam(pattern.parameterForFetchCandidates)
          : await fetchCandidatesByParam(teamId, pattern.parameterForFetchCandidates)
      const candidateModel = new PatternCandidateModel({
        ...response,
        candidates: response.timespans,
        id: pattern.id
      })
      this.SET_CANDIDATE_MODEL({ id: pattern.id, teamId, isLoading: false, model: candidateModel })
    } catch (e) {
      this.SET_CANDIDATE_MODEL({ id: pattern.id, teamId, isLoading: false })
    }
  }
  @Action
  reset() {
    this.RESET_LIST()
    this.SET_CANDIDATE_MODEL(null)
  }
  @Action
  async fetchMemberAvailabilityLength({ teamId, query }: { teamId: string; query: string }): Promise<number> {
    const patterns = (await getList(teamId, query)).availabilitySharings
    const patternModels = patterns.map((p) => new AvailabilityModelForTeam(teamId, p))
    return patternModels.length
  }
  @Mutation
  SET_PRIVATE_LIST(list: AvailabilityModelForPrivate[]) {
    this._privateList = {
      ...this._privateList,
      list: orderBy(list, ['index', 'createdAt'], ['asc', 'asc'])
    }
  }
  @Mutation
  UPDATE_PRIVATE_LIST({ id, newModel }: { id: string; newModel?: AvailabilityModelForPrivate }) {
    const findIndex = this._privateList.list.findIndex((l) => l.id === id)
    if (findIndex < 0) {
      return
    }
    if (newModel) {
      this._privateList.list[findIndex] = newModel
    } else {
      this._privateList.list.splice(findIndex, 1)
    }
    this._privateList.list = [...this._privateList.list]
  }
  @Mutation
  UPDATE_TEAM_LIST({ teamId, id, newModel }: { teamId: string; id: string; newModel?: AvailabilityModelForTeam }) {
    const findIndex = this._teamList[teamId]?.list.findIndex((l) => l.id === id)
    if (findIndex < 0) {
      return
    }
    if (!this._teamList[teamId]) {
      return
    }
    if (newModel) {
      this._teamList[teamId].list[findIndex] = newModel
    } else {
      this._teamList[teamId].list.splice(findIndex, 1)
    }
    this._teamList[teamId].list = [...this._teamList[teamId].list]
  }
  @Mutation
  RESET_LIST() {
    this._privateList = {
      isLoading: false,
      list: []
    }
    this._teamList = {}
  }
  @Mutation
  SET_TEAM_LIST({ teamId, list }: { teamId: string; list: AvailabilityModelForTeam[] }) {
    this._teamList[teamId] = {
      ...this._teamList[teamId],
      list: orderBy(list, ['index', 'createdAt'], ['asc', 'asc'])
    }
  }
  @Mutation
  SET_LOADING({ loading, teamId }: { loading: boolean; teamId?: string }) {
    if (teamId) {
      const previousState = this._teamList[teamId] ?? { isLoading: false, list: [] }
      this._teamList = {
        ...this._teamList,
        [teamId]: {
          ...previousState,
          isLoading: loading
        }
      }
    } else {
      this._privateList = {
        ...this._privateList,
        isLoading: loading
      }
    }
  }
  @Mutation
  SET_CANDIDATE_MODEL(payload?: { model?: PatternCandidateModel; id: string; teamId?: string; isLoading: boolean }) {
    this._fetchedPatternCandidate = null
    if (payload) {
      this._fetchedPatternCandidate = payload
    }
  }
}

export default getModule(AvailabilitySharingList)
