














import { noop } from '@/lib/utils'
import vClickOutside from 'v-click-outside'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  directives: {
    clickOutside: vClickOutside.directive
  }
})
export default class FloatingMenuLayout extends Vue {
  show = false
  customStyle = {}
  showMenu(jsEvent: MouseEvent) {
    this.show = true
    this.$nextTick(() => {
      this.setMenuPosition(jsEvent)
    })
  }
  setMenuPosition(jsEvent: MouseEvent | TouchEvent) {
    const thisComponent = this.$refs.myComponent as HTMLElement
    if (!thisComponent) {
      this.hideMenu()
      return
    }
    this.customStyle = {}
    try {
      if (jsEvent instanceof TouchEvent) {
        // hack. SafariでExceptionが起こる
        this.customStyle = {
          position: 'fixed',
          bottom: '-5px',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }
        return
      }
    } catch (e) {
      noop()
    }
    jsEvent = jsEvent as MouseEvent
    const childSize = {
      width: thisComponent.clientWidth,
      height: thisComponent.clientHeight
    }
    const clientX = jsEvent.clientX
    const clientY = jsEvent.clientY
    // Windowの右から、下からのサイズを取得
    const rightWidth = document.documentElement.clientWidth - clientX
    const bottomHeight = document.documentElement.clientHeight - clientY
    if (childSize.width > rightWidth) {
      // Elementの幅がWindowの右側の空間より大きい
      this.customStyle['right'] = `${rightWidth}px`
    } else {
      this.customStyle['left'] = `${clientX}px`
    }
    if (childSize.height > bottomHeight && childSize.height > clientY) {
      this.customStyle['top'] = '0px'
      return
    }
    if (childSize.height > bottomHeight) {
      // Elementの高さがWindowの下側の空間より大きい
      this.customStyle['bottom'] = `${bottomHeight}px`
    } else {
      this.customStyle['top'] = `${clientY}px`
    }
  }
  hideMenuWithNoEmit() {
    this.show = false
  }
  hideMenu() {
    this.hideMenuWithNoEmit()
    this.$emit('hideEvent')
  }
}
