










import { Component, Prop, Vue } from 'vue-property-decorator'
import AuthorAndAttendeesInfo from '../atoms/AuthorAndAttendeesInfo.vue'
import { ProfileOnOverview } from '@/types'

@Component({
  components: {
    AuthorAndAttendeesInfo
  }
})
export default class PollVotersInfo extends Vue {
  @Prop() author: ProfileOnOverview
  @Prop({ default: [] }) voters: ProfileOnOverview[]

  get headerTitle(): string {
    return this.$t('poll.list.attendeeCount.message', { count: this.voters.length }).toString()
  }

  get allVoters(): ProfileOnOverview[] {
    return this.voters
  }
}
