import { getCurrentInstance } from '@vue/composition-api'
import { WebStorage } from 'vue-ls'

export const useLocalStorage = (): WebStorage => {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(`Should be used in setup().`)
  }
  return instance.proxy.$ls as WebStorage
}
