import { FromHolidays } from '@/lib/utils/converters/fromHolidays'
import { IOnlineMeeting, WorkDayKey, FullCalendarEvent, MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION } from '@/types'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { isBefore } from 'date-fns'
import { differenceInHours, isEqual, addHours } from 'date-fns'
import { getDefaultSelectedCalendar } from '@/lib/utils'
import {
  startOfDay,
  addDays,
  addWeeks,
  startOfWeek,
  startOfWeekByUserStartWeekday,
  addMonths,
  startOfMonth
} from '@/lib/utils/timezone'
import { weekDays } from '@/lib/utils/calendars'
import CalendarsModule from '@/store/modules/calendars'
import { getDefaultOnlineMeetintType } from '@/store/lib/utils'
import TimezoneModule from '@/store/modules/timezones'
import {
  AvailabilitySharingsAvailabilitySharingIdGetResponse,
  AvailabilityCalendarType,
  ExceptionRules,
  DayOfTheWeekRules,
  MemberResponse,
  PrioritizedOnlineMeetings,
  TeamTeamIdAvailabilitySharingsPostRequest,
  TeamTeamIdAvailabilitySharingsPostResponse,
  TeamsTeamIdAvailabilitySharingsPeekCandidatesPostRequest,
  TeamAvailabilitySharingAttendingMembers,
  Countries,
  Country,
  AvailabilitySharingsPeekCandidatesPostRequest
} from '@spirinc/contracts'
import { ResourceEvent, ICalendar } from '@/types'
import AvailabilityStorage from '@/models/localStorage/Availability'
import CalendarModule from '@/store/modules/calendars'
import TeamRecordModule from '@/store/modules/teamRecord'
import { TeamDetailModel } from './team'
import { Location } from 'vue-router'
import CalendarControl from '@/store/modules/calendarControl'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { AllRouteNames } from '@/router'
import { parseFromTime, PickDayOfWeekDefaultEvent, StartSelects } from '@/lib/utils/peekCandidates'
import { endSelects, EndSelects, defaultWeekRule } from '@/lib/utils/peekCandidates'
import { Holiday } from '@/types'
import { IVueI18n } from 'vue-i18n'
import i18nGlobal from '@/i18n'

const UpdateResult = {
  onlineMtgUpdated: 'onlineMtgUpdated',
  meetingRoomsRefreshed: 'meetingRoomsRefreshed'
} as const
export type UpdateResult = keyof typeof UpdateResult

export type DisplayWeekDay = {
  key: WorkDayKey
  check: boolean
  timesEvents: ResourceEvent[]
}

export type Rule = {
  start: string
  end: string
  type: 'include' | 'exclude'
}
export type Exception = Rule & {
  id: string
  allDay?: boolean
}

const MAX_NUM_OF_DAY = 50

const decodeException = (exceptions: Rule[]): Exception[] => {
  return exceptions.map((e) => {
    const startDate = new Date(e.start)
    const endDate = new Date(e.end)
    const isAllDay = () => {
      const startOfStart = startOfDay(startDate)
      if (!isEqual(startOfStart, startDate)) {
        return false
      }
      const diffEndAndStart = differenceInHours(endDate, startDate)
      if (diffEndAndStart < 24) {
        return false
      }
      return diffEndAndStart % 24 === 0
    }
    return {
      ...e,
      id: nanoid(),
      allDay: isAllDay()
    }
  })
}

export const AttendeeType = {
  and: 'and',
  or: 'or'
} as const

export type AttendeeType = typeof AttendeeType[keyof typeof AttendeeType]

export type AvailabilityAttendee = {
  accountId: string
  calendarId: string
  title?: string
  email?: string
}

/**
 * Confirmation form
 */
export type ConfirmationFormProperty = {
  type: 'string'
  description?: string
  tooltip?: string
  widget: 'textarea' | 'input'
  icon?: string
  isActive?: boolean // 本来ならObjectから消せばよいが、現在の仕様が表示/非表示の切り替えが必要なので追加
}
export type ConfirmationForm = {
  type: 'object'
  description?: string
  properties: { [key: string]: ConfirmationFormProperty }
  required?: Array<string>
  'x-spir-properties-order'?: Array<string>
}

// DBのマイグレーションの前、生成時に追加
export const initConfirmationForm = (i18n: IVueI18n = i18nGlobal): ConfirmationForm => {
  return {
    type: 'object',
    description: i18n.t('availabilitySharing.confirmationForm.description').toString(),
    properties: {
      message: {
        type: 'string',
        description: i18n.t('availabilitySharing.confirmationForm.message.description').toString(),
        tooltip: i18n.t('availabilitySharing.confirmationForm.message.tooltip').toString(),
        widget: 'textarea',
        icon: 'message',
        isActive: true
      }
    },
    required: [],
    'x-spir-properties-order': ['message']
  }
}

export const durations = [15, 30, 45, 60, 90, 120, 180, 240, 300, 360, 420, 480] as const
export type Duration = typeof durations[number]

export const timeBuffers = [0, 15, 30, 45, 60, 90, 120] as const
export type TimeBuffer = typeof timeBuffers[number]

export abstract class AvailabilityModel {
  type: 'private' | 'team'
  id?: string
  title: string
  duration: Duration
  location?: string
  visibility: 'default' | 'public' | 'private' | 'confidential'
  description?: string
  candidateTitle: string
  candidateDescription?: string
  meetingRooms?: AvailabilityCalendarType[]
  timeBuffer: TimeBuffer
  isPublished: boolean
  start: StartSelects | string
  end: EndSelects | string
  dayOfTheWeekRules: DayOfTheWeekRules
  exceptions: Exception[]
  countries?: Countries
  holidays?: Holiday[]
  createdAt?: Date
  index?: number
  timeZone: string
  excludeScheduleCandidates: boolean
  excludeGroupPollCandidates: boolean
  excludeAllDays: boolean
  isLoading: boolean
  updatedAt?: Date
  confirmationForm?: ConfirmationForm
  constructor(data?: {
    id: string
    title: string
    description?: string
    candidateTitle: string
    candidateDescription?: string
    location?: string
    duration: Duration
    start:
      | string
      | 'now'
      | 'one_hour_later'
      | 'two_hours_later'
      | 'three_hours_later'
      | 'four_hours_later'
      | 'five_hours_later'
      | 'tomorrow'
      | 'the_day_after_tomorrow'
      | 'three_days_later'
      | 'four_days_later'
      | 'five_days_later'
      | 'next_week'
      | 'one_week_later'
      | 'the_week_after_next'
      | 'two_weeks_later'
      | 'three_weeks_later'
      | 'four_weeks_later'
      | 'next_month'
    end:
      | string
      | 'one_day'
      | 'two_days'
      | 'three_days'
      | 'four_days'
      | 'five_days'
      | 'one_week'
      | 'two_weeks'
      | 'three_weeks'
      | 'four_weeks'
      | 'two_months'
      | 'three_months'
    dayOfTheWeekRules: DayOfTheWeekRules
    exceptions: ExceptionRules
    timeBuffer: 0 | 15 | 30 | 45 | 60 | 90 | 120 | number // fixme: contractの反映が必要
    excludeScheduleCandidates: boolean
    excludeGroupPollCandidates: boolean
    excludeAllDays: boolean
    visibility: 'default' | 'public' | 'private' | 'confidential'
    isPublished: boolean
    countries?: Countries
    holidays?: Holiday[]
    createdAt: string
    index?: number
    timeZone: string
    updatedAt: string
    confirmationForm?: ConfirmationForm
  }) {
    if (!data) {
      //新規作成
      this.title = ''
      this.candidateTitle = ''
      this.description = ''
      this.candidateDescription = ''
      this.candidateTitle = ''
      this.meetingRooms = []
      this.timeBuffer = 0
      this.duration = 60
      this.isPublished = false
      this.start = 'tomorrow'
      this.end = 'two_weeks'
      this.dayOfTheWeekRules = defaultWeekRule()
      this.exceptions = []
      this.countries = []
      this.holidays = []
      this.timeZone = TimezoneModule.userTimezoneKey
      this.visibility = 'default'
      this.excludeGroupPollCandidates = false
      this.excludeScheduleCandidates = false
      this.excludeAllDays = false
      this.confirmationForm = initConfirmationForm()
    } else {
      this.id = data.id
      this.title = data.title
      this.duration = data.duration
      this.location = data.location
      this.description = data.description
      this.visibility = data.visibility
      this.candidateDescription = data.candidateDescription || ''
      this.candidateTitle = data.candidateTitle || ''
      this.timeBuffer = data.timeBuffer as 0 | 15 | 30 | 45 | 60 | 90 | 120
      this.isPublished = data.isPublished
      this.start = data.start
      if (endSelects.indexOf(data.end as EndSelects) >= 0) {
        this.end = data.end as EndSelects
      } else {
        this.end = data.end
      }
      this.dayOfTheWeekRules = data.dayOfTheWeekRules
      this.exceptions = data.exceptions ? decodeException(data.exceptions as Rule[]) : []
      this.countries = data.countries ? data.countries.slice(0, MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION) : []
      this.holidays = data.holidays ? data.holidays : []
      this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined
      this.index = data.index >= 0 ? data.index : Number.MAX_SAFE_INTEGER // if index is minus, set maximum number
      this.timeZone = data.timeZone
      this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : undefined
      this.excludeGroupPollCandidates = data.excludeGroupPollCandidates || false
      this.excludeScheduleCandidates = data.excludeScheduleCandidates || false
      this.excludeAllDays = data.excludeAllDays || false
      this.confirmationForm = data.confirmationForm
    }
    this.isLoading = false
  }
  get isDisabledMaxNum() {
    return false
  }
  get exceptionsAsCalendarFormat(): FullCalendarEvent[] {
    if (!this.exceptions) {
      return []
    }
    return this.exceptions.map((e) => {
      return {
        id: e.id,
        start: new Date(e.start),
        end: new Date(e.end),
        allDay: e.allDay,
        title: '',
        editable: false,
        extendedProps: {
          source: e.type === 'exclude' ? 'exceptionExclude' : 'exceptionInclude'
        }
      }
    })
  }
  get holidaysAsCalendarFormat(): FullCalendarEvent[] {
    return FromHolidays.convertToFullCalendarEvents(this.holidays || [])
  }
  get showDisplayWeekday(): DisplayWeekDay[] {
    const startDay = startOfWeekByUserStartWeekday(new Date())
    return weekDays(CalendarControl.startWeek).map((weekday, i) => {
      const currentDate = addDays(startOfDay(startDay), i)
      return {
        key: weekday,
        check: this.dayOfTheWeekRules[weekday]?.rules?.length > 0,
        timesEvents:
          this.dayOfTheWeekRules[weekday]?.rules?.map((rule) => {
            return PickDayOfWeekDefaultEvent(
              parseFromTime(rule.start, currentDate),
              parseFromTime(rule.end, currentDate),
              weekday
            )
          }) || []
      }
    })
  }

  // todo: backendのObjectが使えるようになったらそれに変えましょう
  get minEndDate() {
    let current = new Date()
    switch (this.start) {
      case 'one_hour_later':
        current = addHours(current, 1)
        break
      case 'two_hours_later':
        current = addHours(current, 2)
        break
      case 'three_hours_later':
        current = addHours(current, 3)
        break
      case 'four_hours_later':
        current = addHours(current, 4)
        break
      case 'five_hours_later':
        current = addHours(current, 5)
        break
      case 'tomorrow':
        current = startOfDay(addDays(current, 1))
        break
      case 'the_day_after_tomorrow':
        current = startOfDay(addDays(current, 2))
        break
      case 'three_days_later':
        current = startOfDay(addDays(current, 3))
        break
      case 'four_days_later':
        current = startOfDay(addDays(current, 4))
        break
      case 'five_days_later':
        current = startOfDay(addDays(current, 5))
        break
      case 'next_week':
        current = startOfWeek(addWeeks(current, 1), { startWeekDay: 1 })
        break
      case 'the_week_after_next':
        current = startOfWeek(addWeeks(current, 2), { startWeekDay: 1 })
        break
      case 'one_week_later':
        current = addWeeks(current, 1)
        break
      case 'two_weeks_later':
        current = addWeeks(current, 2)
        break
      case 'three_weeks_later':
        current = addWeeks(current, 3)
        break
      case 'four_weeks_later':
        current = addWeeks(current, 4)
        break
      case 'next_month':
        current = startOfMonth(addMonths(current, 1))
        break
    }
    return addDays(current, 1)
  }
  get endDateAsDateFormat(): Date | string {
    const endDate = new Date(this.end)
    return endDate
  }
  updateEndDate() {
    if (this.endDateAsDateFormat === 'Invalid Date') {
      return
    }
    const endDate = this.endDateAsDateFormat as Date
    if (isBefore(endDate, this.minEndDate)) {
      this.end = this.minEndDate.toISOString()
    }
  }
  getCountriesAndHolidaysRemoved(countryCode: FrontSupportCountryCode): { countries: Countries; holidays: Holiday[] } {
    const countries = this.countries.filter((country: Country) => countryCode !== country.code)
    const holidays = this.holidays.filter((holiday: Holiday) => countryCode != holiday.country.code)
    return { countries, holidays }
  }
  abstract get isValidToFetchCandidates()
  abstract get confirmURL(): string
  abstract get parameterForFetchCandidates()
  abstract get parameterForCreate()
  abstract get editPath(): Location
}

export class AvailabilityModelForPrivate extends AvailabilityModel {
  attendeeType: AttendeeType
  organizer: AvailabilityCalendarType
  attendees: AvailabilityCalendarType[]
  calendars: Array<AvailabilityAttendee>
  onlineMeeting: IOnlineMeeting
  meetingRooms: AvailabilityCalendarType[]
  maxNumPerDay: number
  constructor(data?: AvailabilitySharingsAvailabilitySharingIdGetResponse) {
    super(data)
    this.type = 'private'
    this.attendeeType = 'and'
    if (!data) {
      //新規作成
      const savedAvailability = new AvailabilityStorage()
      const savedAttrInLocalStorage = savedAvailability.getSavedAttrs()
      const defaultCalendar = getDefaultSelectedCalendar(
        CalendarsModule.writableCalendars,
        savedAttrInLocalStorage.organizer?.accountId && savedAttrInLocalStorage.organizer?.calendarId
          ? {
              accountId: savedAttrInLocalStorage.organizer.accountId,
              calendarId: savedAttrInLocalStorage.organizer.calendarId
            }
          : undefined
      )
      this.organizer = {
        accountId: defaultCalendar.accountId,
        calendarId: defaultCalendar.calendarId
      }
      const selectedCalendar = CalendarsModule.getCalendar({
        accountId: this.organizer.accountId,
        calendarId: this.organizer.calendarId
      })
      const onlineMeeting = getDefaultOnlineMeetintType(
        selectedCalendar,
        savedAttrInLocalStorage.onlineMeeting as IOnlineMeeting
      )
      this.onlineMeeting = onlineMeeting
      this.calendars = [
        {
          accountId: this.organizer.accountId,
          calendarId: this.organizer.calendarId
        }
      ]
      this.duration = savedAttrInLocalStorage.duration || 30
      this.attendees = []
      this.maxNumPerDay = 50
      this.meetingRooms = []
    } else {
      this.calendars = data.calendars.and
      this.attendees = data.attendees || []
      this.organizer = data.organizer
      this.onlineMeeting = (data.onlineMeeting as IOnlineMeeting) || { type: 'none' }
      this.maxNumPerDay = data.maxNumPerDay
      this.meetingRooms = data.meetingRooms || []
    }
  }
  get parameterForCreate() {
    const calendars = {
      and: [],
      or: []
    }
    if (this.attendeeType === 'and') {
      calendars.and = cloneDeep(this.calendars)
    }
    return {
      id: this.id,
      title: this.title,
      organizer: this.organizer,
      attendees: this.attendees,
      candidateTitle: this.candidateTitle,
      candidateDescription: this.candidateDescription,
      duration: this.duration,
      location: this.location,
      meetingRooms: this.meetingRooms,
      description: this.description,
      onlineMeeting: this.onlineMeeting,
      visibility: this.visibility,
      timeBuffer: this.timeBuffer,
      maxNumPerDay: this.isDisabledMaxNum ? MAX_NUM_OF_DAY : this.maxNumPerDay,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      calendars,
      timeZone: this.timeZone,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      countries: this.countries,
      confirmationForm: this.confirmationForm
    }
  }
  get parameterForFetchCandidates(): AvailabilitySharingsPeekCandidatesPostRequest {
    const calendars = {
      and: [],
      or: []
    }
    if (this.attendeeType === 'and') {
      calendars.and = cloneDeep(this.calendars)
    }
    return {
      duration: this.duration,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      attendees: this.attendees,
      meetingRooms: this.meetingRooms,
      timeBuffer: this.timeBuffer,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      timeZone: this.timeZone,
      maxNumPerDay: this.isDisabledMaxNum ? MAX_NUM_OF_DAY : this.maxNumPerDay,
      calendars,
      countries: this.countries
    }
  }
  get confirmURL(): string {
    return `${window.location.origin}/patterns/availability-sharing/${this.id}/confirm`
  }
  get isDisabledMaxNum() {
    return this.calendars.length > 1
  }
  get usableCalendarsByAccount(): ICalendar[] {
    const currentAccount = CalendarsModule.getAccountWithcalendars.find(
      (account) => account.accountId === this.organizer.accountId
    )
    return currentAccount ? currentAccount.calendars : []
  }
  updateAndValidation(payload: AvailabilityModelForPrivate): UpdateResult[] {
    const result: UpdateResult[] = []
    const calendar = CalendarModule.getCalendar({
      accountId: this.organizer.accountId,
      calendarId: this.organizer.calendarId
    })
    const onlineMeeting = getDefaultOnlineMeetintType(calendar, this.onlineMeeting)
    if (this.onlineMeeting.type !== onlineMeeting.type) {
      this.onlineMeeting = onlineMeeting
      result.push(UpdateResult.onlineMtgUpdated)
    }
    if (
      payload.meetingRooms?.length > 0 &&
      this.organizer.accountId !== payload.organizer.accountId &&
      this.organizer.calendarId !== payload.organizer.calendarId
    ) {
      this.meetingRooms = []
      result.push(UpdateResult.meetingRoomsRefreshed)
    }
    return result
  }
  get showCalendars(): { accountId: string; calendarId: string }[] {
    return [
      ...(this.calendars?.map((c) => {
        return {
          accountId: c.accountId,
          calendarId: c.calendarId
        }
      }) || []),
      ...(this.meetingRooms || [])
    ]
  }
  get isValidToFetchCandidates() {
    if (!this.dayOfTheWeekRules || Object.keys(this.dayOfTheWeekRules).length === 0) {
      return false
    }
    return true
  }
  get editPath(): Location {
    return {
      name: AllRouteNames.PersonalAvailabilitySharingEdit,
      params: {
        availabilitySharingId: this.id
      }
    }
  }
}
export class AvailabilityModelForTeam extends AvailabilityModel {
  teamId: string
  memberIds: Array<string>
  organizerMemberId: string
  attendingType: 'and' | 'or'
  orMethod?: 'randomized' | 'prioritized'
  onlineMeeting: PrioritizedOnlineMeetings
  lastUpdateUser?: {
    userId: string
    fullName: string
  }
  notifications?: { email: string }[]
  constructor(teamId: string, data?: TeamTeamIdAvailabilitySharingsPostResponse) {
    super(data)
    this.teamId = teamId
    this.type = 'team'
    this.organizerMemberId = ''
    if (!data) {
      this.attendingType = 'and'
      this.memberIds = []

      this.onlineMeeting = [{ type: 'zoom' }, { type: 'googleMeet' }, { type: 'microsoftTeams' }]
      this.notifications = []
    } else {
      if (data.attendingMembers.type === 'and') {
        this.attendingType = 'and'
        this.memberIds = data.attendingMembers.condition.memberIds
        this.organizerMemberId = data.attendingMembers.condition.organizerMemberId
      } else {
        this.attendingType = 'or'
        this.orMethod = data.attendingMembers.condition.method
        this.memberIds = data.attendingMembers.condition.memberIds
      }

      this.onlineMeeting = data.onlineMeeting
      this.lastUpdateUser = data.lastUpdateUser
      this.notifications = data.notifications
    }
  }
  get encodeAttendingMembers(): TeamAvailabilitySharingAttendingMembers {
    if (this.attendingType === 'and') {
      return {
        type: 'and',
        condition: {
          organizerMemberId: this.organizerMemberId,
          memberIds: this.memberIds
        }
      }
    }
    return {
      type: 'or',
      condition: {
        method: this.orMethod || 'randomized',
        memberIds: this.memberIds
      }
    }
  }
  get teamInfo(): TeamDetailModel {
    return TeamRecordModule.teamByTeamId(this.teamId)?.team
  }
  get allTeamMembers(): MemberResponse[] {
    return this.teamInfo?.members || []
  }
  get allActiveTeamMembers(): MemberResponse[] {
    return this.allTeamMembers.filter((m) => m.status === 'active')
  }
  get organizerMember(): MemberResponse {
    return this.allActiveTeamMembers.find((am) => am.id === this.organizerMemberId)
  }
  get allAttendingMembers(): MemberResponse[] {
    return this.memberIds
      .map((id) => {
        const memberInfo = this.allActiveTeamMembers.find((am) => am.id === id)
        return memberInfo
      })
      .filter((m) => !!m)
  }
  get parameterForFetchCandidates(): TeamsTeamIdAvailabilitySharingsPeekCandidatesPostRequest {
    return {
      duration: this.duration,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      countries: this.countries,
      attendingMembers: this.encodeAttendingMembers,
      timeBuffer: this.timeBuffer,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      timeZone: this.timeZone
    }
  }
  get isValidToFetchCandidates() {
    if (!this.dayOfTheWeekRules || Object.keys(this.dayOfTheWeekRules).length === 0) {
      return false
    }
    if (!this.memberIds || this.memberIds.length === 0) {
      return false
    }
    return true
  }
  get parameterForCreate(): TeamTeamIdAvailabilitySharingsPostRequest {
    return {
      title: this.title,
      description: this.description,
      candidateTitle: this.candidateTitle,
      candidateDescription: this.candidateDescription,
      location: this.location,
      onlineMeeting: this.onlineMeeting,
      duration: this.duration,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      countries: this.countries,
      attendingMembers: this.encodeAttendingMembers,
      meetingRooms: [],
      timeBuffer: this.timeBuffer,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      visibility: this.visibility,
      timeZone: this.timeZone,
      notifications: this.notifications,
      confirmationForm: this.confirmationForm
    }
  }
  get showMemberCalendars(): Array<string> {
    return [...this.memberIds].filter((m) => m)
  }
  get confirmURL(): string {
    return `${window.location.origin}/t/${this.teamId}/as/${this.id}/confirm`
  }
  get editPath(): Location {
    return {
      name: AllRouteNames.TeamAvailabilitySharingEdit,
      params: {
        id: this.teamId,
        availabilitySharingId: this.id
      }
    }
  }
}
