

















































import TagWithIconAndTitle from '@/components/atoms/TagWithIconAndTitle.vue'
import DateRangeInput from '@/components/molecules/DateRangeInput.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import EventGroup from '@/components/organisms/EventGroup.vue'
import { getAllDayListItemTitle } from '@/lib/utils/dateFormat'
import { roundToNextNearestMinutes } from '@/lib/utils/timezone'
import { Rule } from '@/models/data/availability'
import { FullCalendarEvent } from '@/types'
import { addMinutes, isBefore } from 'date-fns'
import { groupBy } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

type IExceptionListItem = {
  key: string
  label: string
  items: Function
  type: string
  icon: string
}

@Component({
  components: {
    FormItem,
    EventGroup,
    TagWithIconAndTitle,
    DateRangeInput
  }
})
export default class PatternFormExceptionList extends Vue {
  @Prop() exceptions: Rule[]
  @Prop() exceptionsAsCalendarFormat: FullCalendarEvent[]

  exceptionList: IExceptionListItem[] = []
  editingRange = null
  minInterval = 30

  created() {
    this.exceptionList = [
      {
        key: 'exclude',
        label: this.$t('availabilitySharing.form.exception.excludeList').toString(),
        type: 'is-danger',
        icon: 'cancel',
        items: () => this.excludeExceptions
      }
    ]
  }
  get groupedExceptions() {
    return groupBy(this.exceptionsAsCalendarFormat, 'extendedProps.source')
  }
  get excludeExceptions() {
    return this.groupedExceptions['exceptionExclude'] || []
  }
  get includeExceptions() {
    return this.groupedExceptions['exceptionInclude'] || []
  }
  get isNoException() {
    return this.exceptionsAsCalendarFormat.length === 0
  }
  displayAllday(event: FullCalendarEvent) {
    return getAllDayListItemTitle({ event })
  }
  setEditingMode() {
    this.setEditingRange()
  }
  setEditingRange(event?: FullCalendarEvent, key?: string) {
    if (event) {
      this.editingRange = {
        start: new Date(event.start),
        end: new Date(event.end),
        type: key,
        id: event.id
      }
    } else {
      const now = new Date()
      let start = roundToNextNearestMinutes(now, { nearestTo: this.minInterval })
      if (isBefore(start, now)) {
        start = addMinutes(start, this.minInterval)
      }
      const end = addMinutes(start, this.minInterval) // 30分をDefault
      this.editingRange = {
        start: start,
        end: end,
        type: 'exclude'
      }
    }
  }
  handleEdit(event: FullCalendarEvent, key: string) {
    this.setEditingRange(event, key)
  }
  handleDelete(event: FullCalendarEvent) {
    this.$emit('deleteException', event)
  }
  stopEditRange() {
    this.editingRange = null
  }
  saveEditingRange(editedValue) {
    this.$emit('saveNewException', {
      ...editedValue,
      type: this.editingRange.type
    })
    this.stopEditRange()
  }
}
