










import { defineComponent, computed } from '@vue/composition-api'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  setup() {
    const currentLanguage = computed(() => ProfileModule.getLanguage)
    return {
      currentLanguage,
      poster: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/images/spir_all_poster.png`)),
      mp4Link: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/videos/auth/spir_all.mp4`)),
      webmLink: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/videos/auth/spir_all.webm`))
    }
  }
})
