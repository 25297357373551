import RequestAlternativeComplete from '@/components/modal/RequestAlternativeComplete.vue'
import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { useModal } from '@/composables/useModal'
import { useSendSignal } from '@/composables/useSendSignal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import { ScheduleModel } from '@/models/data'
import EditScheduleModule, { UpdateResult } from '@/store/modules/editSchedule'
import { ISchedule, ScheduleStatus } from '@/types'
import { computed, reactive, ref } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { PageDataControl, UsePageDataControlCommon } from './types'
import { useDirtyChecker } from './_useDirtyChecker'
import { usePreviewState } from './_usePreviewState'

export const usePersonalSchedulePageDataControl = ({
  pageData,
  updateScheduleModel,
  saveTitleSuggestion,
  isEditMode = false,
  goToNext,
  cancelEditing
}: UsePageDataControlCommon & {
  updateScheduleModel: (partialModel: Partial<ISchedule>) => UpdateResult
}): PageDataControl => {
  const { showModal } = useCalendarMixin()
  const route = useRoute()
  const { sendSignal } = useSendSignal()
  const i18n = useTranslation()
  const { openDangerBottomToast, openPrimaryTopToast } = useToast()
  const { openSyncedDetailModal } = useModal()
  const isSyncing = ref(false)
  function updateModel(): UpdateResult {
    return updateScheduleModel(pageData.value)
  }
  async function updateSchedule() {
    try {
      await EditScheduleModule.updateSchedule()
      openPrimaryTopToast({ message: `${i18n.t('message.success.savedSchedule')}` })
    } catch (e) {
      openDangerBottomToast({ message: `${i18n.t('message.errorCommon')}` })
    }
  }
  async function update() {
    isSyncing.value = true
    updateModel()
    await updateSchedule()
    saveTitleSuggestion(pageData.value.title)
    isSyncing.value = false
    await sendSignal(SignalType.CLICK_EDIT_ON_EDIT_PAGE)
    await sendSignal(SignalType.EDIT_SCHEDULE, { id: EditScheduleModule.editingSchedule.id })
    if (EditScheduleModule.editingSchedule.status === 'requestedByConfirmer') {
      const nextStatus: ScheduleStatus = 'suggestedByOrganizer'
      showModal(RequestAlternativeComplete, {}, { nextStatus })
    }
  }
  async function create() {
    isSyncing.value = true
    updateModel()
    try {
      await EditScheduleModule.createSchedule()
      openPrimaryTopToast({ message: `${i18n.t('message.success.savedSchedule')}` })
      saveTitleSuggestion(pageData.value.title)
      await sendSignal(SignalType.CREATE_SCHEDULE, {
        from: route.query.from as string,
        id: EditScheduleModule.editingSchedule.id
      })
      isSyncing.value = false
      openSyncedDetailModal({ model: EditScheduleModule.editingSchedule })
    } catch (e) {
      openDangerBottomToast({ message: `${i18n.t('message.errorCommon')}` })
    }
  }
  const { isPreviewOpen, isPreviewDisabled, preview } = usePreviewState(pageData)
  const previewData = computed(() => {
    updateModel()
    const model = new ScheduleModel({ ...EditScheduleModule.editingSchedule })
    model.title = model.title || i18n.t('labels.notInputted').toString()
    return { model, candidates: EditScheduleModule.getPreviewEventByCalendarFormat }
  })
  const { clearDirty, shouldStayHere, selectPageDataControl } = useDirtyChecker({
    current: pageData,
    isEditMode,
    update,
    create,
    goToNext,
    cancelEditing
  })
  const { confirm, cancel } = selectPageDataControl()
  return {
    clearDirty,
    shouldStayHere,
    pageDataState: reactive({ isSyncing, isPreviewOpen, isPreviewDisabled, previewData }),
    handlers: { confirm, cancel, preview }
  }
}
