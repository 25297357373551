import axiosInstance from '../axios'
import { PeekCandidatesPostRequest, PeekCandidatesSettingsPostRequest } from '@spirinc/contracts'

export const getPeekCandidatesConditions = async () => {
  return (await axiosInstance.get(`peek-candidates/settings`)).data
}
export const saveNewPeekCandidatesConditions = async (condition: PeekCandidatesSettingsPostRequest) => {
  return (await axiosInstance.post(`peek-candidates/settings`, condition)).data
}
export const udpatePeekCandidatesConditions = async (id: string, condition: PeekCandidatesSettingsPostRequest) => {
  return (await axiosInstance.patch(`peek-candidates/settings/${id}`, condition)).data
}
export const getCandidates = async (condition: PeekCandidatesPostRequest) => {
  return (await axiosInstance.post(`peek-candidates`, condition)).data
}
