import { ref, readonly, onMounted, watch, computed, ComputedRef } from '@vue/composition-api'
import AfterConfirmModule from '@/store/modules/afterConfirm'
import { AfterConfirmModel } from '@/models/data/afterConfirm'
import getErrorMessage from '@/lib/utils/getErrorMessage'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { useRouter } from 'vue2-helpers/vue-router'
import { useToast } from '@/composables/useToast'

type SetupProp = {
  fetchConfirmedInfo: () => Promise<AfterConfirmModel>
  properties: ComputedRef<{
    id: string
    type: string
    confirmedId?: string
    teamId?: string
  }>
}
export const useAfterConfirmSetup = ({ fetchConfirmedInfo, properties }: SetupProp) => {
  const router = useRouter()
  const { openDangerBottomToast } = useToast()
  const isLoading = ref(false)

  const afterConfirmModel: ComputedRef<AfterConfirmModel | null> = computed(() => {
    return AfterConfirmModule.getAfterConfirm as AfterConfirmModel
  })
  const isValidModel = () => {
    if (!afterConfirmModel.value) {
      return false
    }
    if (afterConfirmModel.value.type !== properties.value.type) {
      return false
    }
    if (afterConfirmModel.value.id !== properties.value.id) {
      return false
    }
    if (afterConfirmModel.value.confirmedId !== properties.value.confirmedId) {
      return false
    }
    if (properties.value.teamId && afterConfirmModel.value.teamId !== properties.value.teamId) {
      return false
    }
    return true
  }
  const checkModelAndFetchSchedule = async () => {
    if (!isValidModel()) {
      try {
        isLoading.value = true
        if (!properties.value.id) {
          throw new Error('no id')
        }
        const afterConfirmModel = await fetchConfirmedInfo()
        AfterConfirmModule.SET_MODEL(afterConfirmModel)
      } catch (e) {
        const message = getErrorMessage(e, {
          handleKeys: [{ errorCode: ERROR_CODE.PAST_EVENT, i18nKey: 'message.error.pastEvent' }]
        })
        openDangerBottomToast({ message })
        return router.push({ name: 'NotFound' })
      } finally {
        isLoading.value = false
      }
      if (!afterConfirmModel.value) {
        return router.push({ name: 'NotFound' })
      }
    }
  }
  onMounted(() => {
    checkModelAndFetchSchedule()
  })
  watch(properties, () => {
    checkModelAndFetchSchedule()
  })
  return {
    isLoading: readonly(isLoading) || !!afterConfirmModel.value,
    afterConfirmModel
  }
}
