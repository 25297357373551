










import { Attendee } from '@/types'
import { FromAttendee, FromSpirUser } from '@/lib/utils'
import AuthorAndAttendeesInfo from '../atoms/AuthorAndAttendeesInfo.vue'
import { nanoid } from 'nanoid'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import i18n from '@/i18n'

export default defineComponent({
  components: {
    AuthorAndAttendeesInfo
  },
  props: {
    author: {
      type: Object as PropType<Attendee>,
      default: null
    },
    attendees: {
      type: Array as PropType<Attendee[]>,
      default: []
    }
  },
  setup(props) {
    const { author, attendees } = toRefs(props)

    const headerTitle = computed(() => {
      const option = {
        authorCount: 1,
        attendeesCount: attendees.value.length
      }
      return i18n.t('schedule.list.attendeeCount.messageWithAuthor', option).toString()
    })

    const authorProfile = computed(() => {
      const authorObj = author.value || { id: nanoid(), name: '', photoURL: '' }
      const profile = FromSpirUser.convertToProfileOnOverview(authorObj, {
        isAuthor: true,
        isLoading: !authorObj
      })
      return profile
    })

    const attedeeProfiles = computed(() => {
      return attendees.value.map((attendee) =>
        FromAttendee.convertToProfileOnOverview(attendee, { isLoading: false, isAuthor: false })
      )
    })

    return {
      authorProfile,
      attedeeProfiles,
      headerTitle
    }
  }
})
