






import { computed, defineComponent } from '@vue/composition-api'
import UserModule from '@/store/modules/user'
import { useModal } from '@/composables/useModal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import Button from '@/components/ui/Button.vue'

export default defineComponent({
  name: 'SignInButton',
  components: {
    Button
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { openAuthModal } = useModal()
    const { openInfoBottomToast } = useToast()
    const i18n = useTranslation()
    const handleShowAuthModal = () => {
      if (props.isPreview) {
        return openInfoBottomToast({ message: i18n.t('message.preview.canNotShowEvent').toString() })
      }
      return openAuthModal()
    }
    return {
      isSignIn: computed(() => UserModule.isSignIn),
      handleShowAuthModal
    }
  }
})
