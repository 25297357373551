





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import PullDownForm from './PullDownForm.vue'

@Component({
  components: {
    PullDownForm,
    ValidationObserver,
    WithValidationInput
  }
})
export default class OtherCalendarPullDownForm extends Vue {
  @Prop({ default: null }) header: string
  @Prop() body: string
  @Prop({ default: '' }) cancelBtn: string
  @Prop({ default: '' }) confirmBtn: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: true }) closeWhenButtonClicked: boolean
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  email = ''

  get cancelButtonTitle() {
    return this.cancelBtn || this.$t('buttons.cancel').toString()
  }
  get confirmButtonTitle() {
    return this.confirmBtn || this.$t('buttons.confirm').toString()
  }
  reset() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validateRef = this.$refs.validate as any
    validateRef.reset()
    this.email = ''
  }
  handleCancel() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('cancel')
    this.reset()
  }
  handleConfirm() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('confirm', this.email)
    this.reset()
  }
}
