










import { defineComponent } from '@vue/composition-api'
import Avatar from 'vue-avatar'

export default defineComponent({
  name: 'AvatarWithName',
  components: {
    Avatar
  },
  props: {
    avatarUrl: {
      type: String
    },
    name: {
      type: String,
      required: true
    }
  }
})
