




import { Component, Vue } from 'vue-property-decorator'
import UserModule from '@/store/modules/user'

@Component
export default class SignOut extends Vue {
  async created() {
    try {
      await UserModule.signOut()
    } finally {
      this.$router.push({ name: 'SignIn' })
    }
  }
}
