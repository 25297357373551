






import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import AuthFormLayout from '@/components/auth/AuthFormLayout.vue'
import GoogleSignUpBox from '@/components/auth/GoogleSignUpBox.vue'
import i18n from '@/i18n'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    signUpAndAddCalendarToGoogle: {
      type: Function,
      required: true
    }
  },
  components: {
    AuthFormLayout,
    LoadingSpinner,
    GoogleSignUpBox
  },
  setup() {
    const title = computed(() => {
      return i18n.t('forms.auth.addCalendar.title')
    })
    return {
      title
    }
  }
})
