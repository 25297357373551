























































import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import ConfirmMixin from '../ConfirmMixin'
import EventToConfirmModule from '@/store/modules/eventToConfirm'
import UserModule from '@/store/modules/user'
import { FullCalendarMouseEvent, FullCalendarEvent, ScheduleStatus, SpirUser } from '@/types'
import ConfirmSchedule from '@/components/modal/confirm/ConfirmSchedule.vue'
import { addMinutes } from 'date-fns'
import Tooltip from '@/components/molecules/Tooltip.vue'
import Common from '../Common.vue'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { FromOrganizerInfo, inConfirmationPage, isMobile } from '@/lib/utils'
import { ScheduleModelCommon } from '@/models/data/schedule'
import { AfterConfirmQueryParams } from '@/types'
import ScheduleConfirmSidePanelBox from '@/components/sidePanels/confirm/schedule/ScheduleConfirmSidePanelBox.vue'
import ConfirmMobileCalendarFooter from '@/components/schedule/ConfirmMobileCalendarFooter.vue'
import AppModule from '@/store/modules/app'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { GuestConfirmer, SpirUserConfirmer, Confirmer } from '@/models/data'

@Component({
  components: {
    Tooltip,
    Common,
    ConfirmPageHeader,
    ScheduleConfirmSidePanelBox,
    ConfirmMobileCalendarFooter
  }
})
export default class ScheduleConfirm extends mixins(ConfirmMixin) {
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: false }) isOrganizer: boolean
  @Prop() targetModel: ScheduleModelCommon
  @Prop() candidates: FullCalendarEvent[]
  @Prop() organizer: { id: string; fullName?: string; name?: string }
  @Prop() teamId: string
  @Prop() confirmSchedule: (option: {
    candidateId: string
    start: Date
    attendees: { name: string; email: string }[]
    confirmer: Confirmer
  }) => void
  @Prop({ default: false }) isSyncing: boolean

  TOAST_DURATION = 5000

  async mounted() {
    if (!isMobile()) {
      EventBus.on(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, this.handleEventMouseEnter)
      EventBus.on(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, this.handleEventMouseLeave)
    }
    try {
      this.initConfirmPage()
    } catch (e) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
      this.$router.push({ name: 'Main' })
    }
  }
  beforeDestroy() {
    EventBus.off(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, this.handleEventMouseEnter)
    EventBus.off(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, this.handleEventMouseLeave)
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get isEditingAlternativeCandidatesSuggestions() {
    return EventToConfirmModule.isEditingAlternativeCandidatesSuggestions
  }
  get eventToConfirm() {
    return EventToConfirmModule.eventToConfirm
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  get scheduleId() {
    return this.targetModel.id
  }

  get additionalEvents(): FullCalendarEvent[] {
    const returnEvents = []
    if (this.eventToConfirm) {
      returnEvents.push(this.eventToConfirm)
    }
    return returnEvents.concat(this.targetModel.getCandidatesForConfirm)
  }
  get showConfirmButton(): boolean {
    return isMobile() && !!this.eventToConfirm
  }
  get showCalendarFooter() {
    return this.showOtherOptionButton && (!this.isNarrow || !this.isScheduleEventListOpen)
  }
  // todo: editingScheduleが単純なObjectのため、状態を判断するロジックをかけないからいろんなところで似ているロジックを書くしか無い
  // editingScheduleをModel化してすべてModel内に持つべき
  get showOtherOptionButton() {
    if (this.isOrganizer) {
      return (
        this.targetModel.status === ScheduleStatus.suggestedByConfirmer ||
        this.targetModel.status === ScheduleStatus.requestedByConfirmer
      )
    } else {
      return (
        this.targetModel.status === ScheduleStatus.suggestedByOrganizer ||
        this.targetModel.status === ScheduleStatus.requestedByOrganizer
      )
    }
  }
  initConfirmPage() {
    const startDate =
      this.confirmDateFromQueryParam || inConfirmationPage.getClosestStartingCandidate(this.candidates)?.start
    this.initCommonProcess({ startDate })
    // get query parameter
    return this.confirmIfThereIsQueryParam()
  }
  get confirmDateFromQueryParam(): null | Date {
    return inConfirmationPage.getConfirmDateFromQueryParam(this.$route)
  }

  get authorInfo(): SpirUser {
    return FromOrganizerInfo.convertToSpirUser(this.organizer)
  }
  async confirmIfThereIsQueryParam() {
    const startDate = this.confirmDateFromQueryParam
    if (!startDate || this.noConnectedAccount) {
      return
    }
    const { duration } = this.targetModel
    const endDate: Date = addMinutes(startDate, duration)
    const candidate = this.getCandidateByStartAndEnd(startDate, endDate)
    if (!candidate) {
      const toastOption = inConfirmationPage.getInvalidCandidateToastOption({ duration: this.TOAST_DURATION })
      this.$buefy.toast.open(toastOption)
      return
    }
    await this.updateConfirmEvent(startDate, endDate, candidate.id)
    this.onScheduleConfirmButtonClick({ cancelBtn: this.$t('scheduleSharingText.confirmButton').toString() })
  }
  onScheduleConfirmButtonClick(params?: { cancelBtn?: string }) {
    this.showConfirmModal(ConfirmSchedule, params)
  }
  showConfirmModal(modalComponent, params?: { cancelBtn?: string }) {
    const parameter = {
      candidateStartDate: this.eventToConfirm.start,
      candidateEndDate: this.eventToConfirm.end,
      isOrganizer: this.isOrganizer,
      schedule: this.targetModel,
      ...params
    }
    const modal = this.$buefy.modal.open({
      parent: this,
      component: modalComponent,
      hasModalCard: true,
      onCancel: () => {
        EventToConfirmModule.resetEventToConfirm()
      },
      events: {
        close: async () => {
          await EventToConfirmModule.resetEventToConfirm()
          modal.close()
        },
        register: ({ email, name, attendees }) => {
          modal.close()
          this.confirm(email, name, attendees)
        }
      },
      props: parameter
    })
  }
  async confirm(email: string, name: string, attendees?: { name: string; email: string }[]) {
    const {
      start,
      extendedProps: { candidateId }
    } = this.eventToConfirm
    try {
      const confirmer = UserModule.isSignIn
        ? new SpirUserConfirmer({ email, user: ProfileModule.myProfile })
        : new GuestConfirmer({
            email,
            name,
            timeZone: TimezoneModule.userTimezoneInfo.key,
            language: ProfileModule.getLanguage
          })
      await this.confirmSchedule({
        candidateId,
        start,
        attendees,
        confirmer
      })
      await this.$analytics.send(SignalType.CONFIRM_SCHEDULING, {
        id: this.scheduleId,
        teamId: this.teamId
      })
      const queryParam: AfterConfirmQueryParams = {
        type: 'schedule',
        't-or-p': this.teamId ? 'team' : 'private',
        id: this.scheduleId,
        't-id': this.teamId
      }
      return this.$router.push({ name: 'AfterConfirm', query: queryParam })
    } catch (err) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
    }
  }
  async handleClickedCandidate({
    event,
    jsEvent,
    el
  }: {
    event: FullCalendarEvent
    jsEvent: MouseEvent
    el: HTMLElement
  }) {
    if (this.isSyncing) {
      return
    }
    await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_CALENDAR, { id: this.scheduleId, teamId: this.teamId })
    this.updateConfirmEventByMouseEvent(this.targetModel.duration, event, jsEvent, el)
  }
  getCandidateByStartAndEnd(start: Date, end: Date) {
    return inConfirmationPage.getCandidateByStartAndEnd(this.targetModel.candidates, start, end)
  }
  async handleUpdateEvent({ event, revert }: { event: FullCalendarEvent; revert: Function }) {
    if (!this.eventToConfirm) {
      return
    }
    const candidate = this.getCandidateByStartAndEnd(event.start, event.end)
    if (!candidate) {
      return revert()
    }
    await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_CALENDAR, { id: this.scheduleId, teamId: this.teamId })
    this.updateConfirmEvent(event.start, event.end, candidate.id)
  }
  handleDeleteConfirmer() {
    EventToConfirmModule.resetEventToConfirm()
  }
  async handleEventClick(payload: { start: Date; end: Date; candidateId: string }) {
    await this.updateConfirmEvent(payload.start, payload.end, payload.candidateId)
    this.onScheduleConfirmButtonClick()
  }
  async handleEventMouseEnter(payload: FullCalendarMouseEvent) {
    if (this.isSyncing) {
      return
    }
    this.handleEventHover(this.targetModel.duration, payload)
  }
  handleEventMouseLeave(payload: FullCalendarMouseEvent) {
    this.handleMouseLeave(payload)
  }
  handleEventHoverOnPanel(payload) {
    if (this.isSyncing) {
      return
    }
    this.updateConfirmEvent(payload.start, payload.end, payload.candidateId)
  }
}
