





import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'HelpMessage',
  props: {
    message: {
      type: String,
      required: true
    }
  }
})
