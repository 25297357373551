



















import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import SpirHelperText from '@/components/ui/spir/SpirHelperText.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'

export default defineComponent({
  name: 'SpirTextInput',
  components: {
    SpirHelperText
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    },
    onBlur: {
      type: Function as PropType<(e: InputEvent) => void>
    }
  },
  setup(props) {
    const { errorState } = toRefs(props)
    const input = ref<{ focus: () => void } | null>(null)
    const hasError = computed(() => errorState.value.type === 'hasError')
    function handleBlur(e: InputEvent) {
      if (props.onBlur) props.onBlur(e)
    }
    return {
      input,
      hasError,
      handleBlur,
      focus: () => {
        input.value.focus()
      }
    }
  }
})
