






































































import { Component, Vue } from 'vue-property-decorator'
import { Watch, Prop } from 'vue-property-decorator'
import BaseLayout from '@/components/layout/Base.vue'
import CalendarBody from '@/components/calendar/CalendarBody.vue'
import CalendarHeader from '@/components/calendar/CalendarHeader.vue'
import ScheduleConfirmBox from '@/components/schedule/ScheduleConfirmBox.vue'
import CalendarList from '@/components/calendarList/Index.vue'
import Vue100vh from 'vue-div-100vh'
import FooterPanel from './FooterPanel.vue'
import { FullCalendarEvent, CreateEvent, ISchedule } from '@/types'
import AppModule from '@/store/modules/app'
import CandidatesModule from '@/store/modules/candidates'
import EventsModule from '@/store/modules/events'
import PreviewModule from '@/store/modules/preview'
import ProfileModule from '@/store/modules/profile'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import CalendarControlModule, { GRID_HEIGHT } from '@/store/modules/calendarControl'
import TimezoneModule from '@/store/modules/timezones'
import { RouteNames } from '@/router/index'
import CalendarslModule from '@/store/modules/calendars'
import TeamCalendarModule from '@/store/modules/teamCalendar'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { debounce } from 'lodash'
import { isEqual } from 'date-fns'

@Component({
  components: {
    BaseLayout,
    CalendarBody,
    CalendarHeader,
    ScheduleConfirmBox,
    CalendarList,
    Vue100vh,
    FooterPanel,
    LoadingSpinner
  }
})
export default class CalendarLayout extends Vue {
  // todo: candidates is not same as Type.
  showSidePanelDelayed = false
  @Prop({ default: () => [] }) additionalEvents: FullCalendarEvent[]
  @Prop({ default: false }) showCalendarFooter: boolean
  @Prop({ default: false }) showSidePanel: boolean
  @Prop() schedule: ISchedule | null
  @Prop({ default: false }) showLoader: boolean
  @Prop() isSelectable: boolean
  @Prop({ default: '' }) theme: string
  @Prop({ default: false }) isPreview: boolean
  @Prop() firstScrollHourForPreview: number
  @Prop({ default: false }) isConfirm: boolean
  @Prop() timezoneButtonAsLabel

  calendarBodyHeight = 0
  lastCalendarScrollPositionForPreview = -1
  fetchEventsDebounce = null

  @Watch('showCalendarFooter')
  showCalendarFooterChanged() {
    this.updateHeight()
  }
  @Watch('showConfirmHeader')
  showConfirmHeaderChanged() {
    this.updateHeight()
  }
  @Watch('isScheduleEventListOpen')
  updatedListOpen(newValue) {
    if (!newValue) {
      this.updateHeight()
    }
  }
  // calendarの割り込みが変わるたびにEventsをFetchする。
  @Watch('visibleCalendars')
  fetchCalendars(newValue: string[], oldValue: string[]) {
    if (newValue && newValue.length > 0) {
      // 新しいVisibleCalendarsで、Oldにないキーがあれば、Fetchする。
      newValue.forEach((nKey) => {
        if (!oldValue || oldValue.length === 0 || oldValue.indexOf(nKey) < 0) {
          EventsModule.fetchEventsByCalendarKey(nKey)
        }
      })
    }
  }
  @Watch('visibleTeamCalendars')
  fetchTeamCalendars(newValue: string[], oldValue: string[]) {
    if (newValue && newValue.length > 0) {
      // 新しいVisibleCalendarsで、Oldにないキーがあれば、Fetchする。
      newValue.forEach((nKey) => {
        if (!oldValue || oldValue.length === 0 || oldValue.indexOf(nKey) < 0) {
          EventsModule.fetchEventsByTeamCalendarKey({ teamCalendarKey: nKey })
        }
      })
    }
  }
  @Watch('calendarEventDate')
  updatedCalendarEventDate(newValue: { start: Date; end: Date }, oldValue: { start: Date; end: Date }) {
    if (!newValue) {
      return
    }
    if (oldValue) {
      if (isEqual(newValue.start, oldValue.start) && isEqual(newValue.end, oldValue.end)) {
        return
      }
    }
    this.fetchEventsDebounce()
  }
  created() {
    this.fetchEventsDebounce = debounce(CalendarControlModule.fetchEvents, 300)
  }
  mounted() {
    window.addEventListener('resize', this.updateHeight)
    if (this.isPreview) {
      this.lastCalendarScrollPositionForPreview = this.firstScrollHourForPreview * GRID_HEIGHT
    }
    this.updateHeight()
    this.showSidePanelDelayed = true
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight)
  }
  get visibleCalendars() {
    return CalendarslModule.visibleCalendarsKeys
  }
  get visibleTeamCalendars() {
    return TeamCalendarModule.visibleTeamMemberKeys
  }
  get showCalendarList() {
    return AppScreenControlModule.showCalendarList
  }
  get lastCalendarScrollPosition() {
    if (this.isPreview) {
      return this.lastCalendarScrollPositionForPreview
    } else {
      return CalendarControlModule.getLastScrollPosition
    }
  }
  get delayedShowSidePanel() {
    return this.showSidePanelDelayed && this.showSidePanel
  }
  get getCurrentView() {
    return this.isPreview ? PreviewModule.getCurrentView : CalendarControlModule.getCurrentView
  }
  get calendarBodyKey() {
    // hack. timezone情報の更新。スマホサイズになったさいに再描画するため。重要！
    return (
      CalendarControlModule.getEventsDate.start.toISOString() +
      JSON.stringify(TimezoneModule.timezoneKeysForDisplay) +
      this.isNarrow.toString() +
      this.showCalendarList.toString()
    )
  }
  get myProfile() {
    return ProfileModule.myProfile
  }
  get calendarEventDate() {
    return CalendarControlModule.getEventsDate
  }
  get eventDate() {
    return this.isPreview ? PreviewModule.eventsDate : this.calendarEventDate
  }
  get events() {
    return [...EventsModule.privateEvents, ...EventsModule.teamEvents]
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  get allEvents(): FullCalendarEvent[] {
    if (this.isPreview) {
      return [...this.additionalEvents]
    }

    return [...this.dbSchedules, ...this.additionalEvents, ...this.events]
  }
  get allCandidates() {
    return CandidatesModule.candidates
  }
  get dbSchedules(): FullCalendarEvent[] {
    return this.allCandidates.filter((schedule) => {
      // don't show confirm page's schedule
      return !this.$route.params.id || this.$route.params.id !== schedule.id
    })
  }
  get isScheduleEventListOpen() {
    return AppScreenControlModule.isScheduleEventListOpen
  }
  get activeTab() {
    const thisRouteName: RouteNames = this.$route.name as RouteNames
    if (this.isNarrow) {
      if (thisRouteName === 'Main') {
        return '1'
      }
      if (thisRouteName === 'ScheduleList') {
        return '0'
      }
      if (thisRouteName === 'AvailabilityList') {
        return '0'
      }
      if (thisRouteName === 'UnconfirmedList') {
        return '0'
      }
      if (thisRouteName === 'ConfirmedList') {
        return '0'
      }
    }
    return this.isNarrow && this.showSidePanel && this.isScheduleEventListOpen ? '0' : '1'
  }
  get showConfirmHeader() {
    return this.isNarrow && !this.isScheduleEventListOpen
  }
  get showDailyView() {
    // previewとログインしていない場合は、Dailyviewを表示しない。
    return false
  }
  /*
  I've tried to set height automatically using flex-glow, but it doesn' work.
  */
  updateHeight() {
    this.$nextTick(() => {
      const pageHeight = document.documentElement.clientHeight
      const scheduleConfirmBanner = document.getElementById('confirm-header')
        ? document.getElementById('confirm-header').clientHeight
        : 0

      const getElementHeightByClassName = (className) => {
        const myElement = this.$el
        if (!myElement.getElementsByClassName(className) || !myElement.getElementsByClassName(className)[0]) {
          return 0
        }
        return myElement.getElementsByClassName(className)[0].clientHeight
      }
      const targetElementsClasses = ['page-header', 'calendar-body__tab__calendar__header', 'calendar-footer']
      const heightTotal = targetElementsClasses.reduce((a, c) => a + getElementHeightByClassName(c), 0)
      this.calendarBodyHeight = pageHeight - (scheduleConfirmBanner + heightTotal)
    })
  }

  handleEventClick(event: FullCalendarEvent) {
    this.$emit('handleEventClick', event)
  }

  handleAddEvent(payload: CreateEvent) {
    this.$emit('addEvent', payload)
  }
  handleDateClick(date) {
    this.$emit('dateClick', date)
  }
  handleUpdateCandidate(eventDropInfo) {
    this.$emit('updateCandidate', eventDropInfo)
  }
  handleSetCurrentDate(newDate) {
    if (this.isPreview) {
      return PreviewModule.setCurrentDate(newDate)
    }
    CalendarControlModule.setCurrentDate(newDate)
  }
  moveCurrentDate(method) {
    if (this.isPreview) {
      return PreviewModule.moveCurrentDate(method)
    }
    return CalendarControlModule.moveCurrentDate(method)
  }
  handleSetCurrentView(days) {
    if (this.isPreview) {
      return PreviewModule.setCurrentView(days)
    }
    CalendarControlModule.setCurrentView(days)
  }
  handleCalendarScroll(scrollPosition) {
    if (this.isPreview) {
      this.lastCalendarScrollPositionForPreview = scrollPosition
    } else {
      CalendarControlModule.SET_LAST_SCROLL_POSITION(scrollPosition)
    }
  }
}
