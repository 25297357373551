




import { Component, Vue } from 'vue-property-decorator'
import { NavigationGuardNext, Location } from 'vue-router'
import EditScheduleTeamModule from '@/store/modules/editScheduleTeam'
import { RouteNames, AllRouteNames } from '@/router'
import queryParams from '@/lib/queryParams'
import { AfterConfirmQueryParams } from '@/types/schedule'
import { syncScheduleCheck, resetTimer } from './SyncTimerModule'
import { ToastProgrammatic as Toast } from 'buefy'
import i18n from '@/i18n'

const guard = async (to: Location, _, next: NavigationGuardNext) => {
  const teamId = to.params.id
  const scheduleId = to.params.scheduleId
  const toRoute: RouteNames = to.name as RouteNames
  try {
    EditScheduleTeamModule.initEditingSchedule()
    // 必ず再度Fetchする
    if (toRoute === 'TeamScheduleConfirm') {
      await EditScheduleTeamModule.setScheduleForConfirm({ teamId, scheduleId, findFromStore: false })
    } else {
      await EditScheduleTeamModule.setScheduleAsEditingSchedule({ teamId, scheduleId, findFromStore: false })
    }
    if (EditScheduleTeamModule.editingSchedule.isInvalid) {
      return next({ name: 'NotFound', query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
    }
    if (EditScheduleTeamModule.editingSchedule.status === 'expired') {
      return next({ name: 'Main', query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
    }
    if (EditScheduleTeamModule.editingSchedule.status === 'confirmed') {
      if (toRoute === AllRouteNames.TeamScheduleEdit) {
        Toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: i18n.t('message.error.confiemd').toString()
        })
      }
      const queryParam: AfterConfirmQueryParams = {
        type: 'schedule',
        't-or-p': 'team',
        id: scheduleId,
        't-id': teamId
      }
      return next({ name: 'AfterConfirm', query: queryParam })
    }
    next()
  } catch (e: any) {
    if (e.response?.status === 404) {
      return next({ name: 'NotFound', query: { page: queryParams.QUERY_NOT_FOUND_SCHEDULE } })
    }
    // fixme: QueryParameterを送る側、受け取る側でルールがわかるように仕組みがあった方がわかりやすい。
    return next({ name: 'Main', query: { error: 'common' } })
  }
}

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])

@Component
export default class TeamScheduleRoot extends Vue {
  async beforeRouteUpdate(to: Location, from: Location, next: NavigationGuardNext) {
    if (to.params?.id === from.params?.id && to.params?.scheduleId === from.params?.scheduleId) {
      return next()
    }
    guard(to, from, next)
  }
  async beforeRouteEnter(to: Location, from: Location, next: NavigationGuardNext) {
    guard(to, from, next)
  }
  mounted() {
    syncScheduleCheck(
      this.$route.name as RouteNames,
      this.$route.params.id,
      this.$route.params.scheduleId,
      this.$t('message.pleaseWaitForSync').toString()
    )
  }
  beforeDestroy() {
    resetTimer()
    EditScheduleTeamModule.initEditingSchedule()
  }
}
