import { SelectedEmailType } from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { SelectItem } from '@/components/ui/spir/types'
import { useTranslation } from '@/composables/useTranslation'
import { OnlineMeetingType, VISIBILITY } from '@/types'
import { computed, reactive, ref } from '@vue/composition-api'
import { AVAILABLE_DURATIONS } from '@/lib/utils/durations'
import { ScheduleDuration } from '@/models/data'
import { adjustDurationUnit } from '@/lib/utils'
import { useToast } from '@/composables/useToast'
import { OnlineMeetingTool } from './FormOnlineMeetingDropdownItem/onlineMeeting'

// Title
export type FormTitleInputItemSetup = {
  title: string
  handleUpdate: (newTitle: string) => void
}
export const useFormTitleInputItemInitializer = (): { initialize: (init: string) => FormTitleInputItemSetup } => {
  return {
    initialize: (init: string): FormTitleInputItemSetup => {
      const title = ref(init)
      return reactive({
        title,
        handleUpdate: (newTitle: string) => {
          title.value = newTitle
        }
      })
    }
  }
}
export const useFormTitleInputItem = (initTitle = ''): FormTitleInputItemSetup => {
  const { initialize } = useFormTitleInputItemInitializer()
  return initialize(initTitle)
}

// Duration
export type FormDurationSelectBoxItemSetup = {
  duration: ScheduleDuration
  items: SelectItem[]
  handleUpdate: (newDuration: ScheduleDuration) => void
}
export const useFormDurationSelectBoxItemInitializer = (): {
  initialize: (init: ScheduleDuration) => FormDurationSelectBoxItemSetup
} => {
  const i18n = useTranslation()
  const items = AVAILABLE_DURATIONS.map((n) => {
    const displayDuration = adjustDurationUnit(n)
    if (displayDuration.type === 'hour') {
      return {
        label: i18n.t('forms.event.durationHour', { hour: displayDuration.duration }).toString(),
        key: n
      }
    } else {
      return {
        label: i18n.t('forms.event.duration', { min: displayDuration.duration }).toString(),
        key: n
      }
    }
  })
  return {
    initialize: (initDuration: ScheduleDuration = 30) => {
      const duration = ref(initDuration)
      return reactive({
        duration,
        items,
        handleUpdate: (newDuration: ScheduleDuration) => {
          duration.value = newDuration
        }
      })
    }
  }
}
export const useFormDurationSelectBoxItem = (initDuration: ScheduleDuration = 30): FormDurationSelectBoxItemSetup => {
  const { initialize } = useFormDurationSelectBoxItemInitializer()
  return initialize(initDuration)
}

// Author
export type FormAuthorDropdownItemSetup = {
  value: {
    accountId: string
    calendarId: string
  }
  handleUpdate: (newValue: { accountId: string; calendarId: string }) => void
}
export const useFormAuthorDropdownItem = (initial: {
  accountId: string
  calendarId: string
}): FormAuthorDropdownItemSetup => {
  const accountId = ref(initial.accountId)
  const calendarId = ref(initial.calendarId)

  return reactive({
    value: {
      accountId,
      calendarId
    },
    handleUpdate: (newValue: { accountId: string; calendarId: string }) => {
      accountId.value = newValue.accountId
      calendarId.value = newValue.calendarId
    }
  })
}

// Organizer
export type FormOrganizerDropdownItemSetup = {
  organizerMemberId: string
  handleUpdate: (newOrganizerMemberId: string) => void
}
export const useFormOrganizerDropdownItemInitializer = () => {
  return {
    initialize: (initial: { organizerMemberId: string }): FormOrganizerDropdownItemSetup => {
      const organizerMemberId = ref(initial.organizerMemberId)
      return reactive({
        organizerMemberId,
        handleUpdate: (newOrganizerMemberId: string) => (organizerMemberId.value = newOrganizerMemberId)
      })
    }
  }
}

// AttendingEmails
export type FormAttendingEmailsSetup = {
  selectedEmails: SelectedEmailType[]
  handleReplace: (attendeeEmails: SelectedEmailType[]) => void
  handleAdd: (newEmail: string) => void
  handleDelete: (deletedEmail: string) => void
}
export const useFormAttendingEmailsInitializer = () => {
  return {
    initialize: (initial: { attendeeEmails: SelectedEmailType[] }): FormAttendingEmailsSetup => {
      const selectedEmails = ref(initial.attendeeEmails)
      return reactive({
        selectedEmails,
        handleReplace: (attendeeEmails: SelectedEmailType[]) => {
          selectedEmails.value = attendeeEmails
        },
        handleAdd: (newEmail: string) => selectedEmails.value.push({ type: 'email', email: newEmail }),
        handleDelete: (deletedEmail: string) =>
          (selectedEmails.value = selectedEmails.value.filter((selected) => selected.email !== deletedEmail))
      })
    }
  }
}
export const useFormAttendingEmails = (initial: { attendeeEmails: SelectedEmailType[] }): FormAttendingEmailsSetup => {
  const { initialize } = useFormAttendingEmailsInitializer()
  return reactive(initialize(initial))
}

// Description
export type FormDescriptionTextareaItemSetup = {
  description: string
  handleUpdate: (newDesc: string) => void
}
export const useFormDescriptionTextareaItemInitializer = () => {
  return {
    initialize: (initDescription = ''): FormDescriptionTextareaItemSetup => {
      const description = ref(initDescription)
      return reactive({
        description,
        handleUpdate: (newDesc: string) => {
          description.value = newDesc
        }
      })
    }
  }
}
export const useFormDescriptionTextareaItem = (initDescription = ''): FormDescriptionTextareaItemSetup => {
  const { initialize } = useFormDescriptionTextareaItemInitializer()
  return initialize(initDescription)
}

// OnlineMeeting
export type FormOnlineMeetingDropdownItemSetup = {
  onlineMeetingType: OnlineMeetingType
  handleUpdate: (newType: OnlineMeetingType) => void
}
export const useFormOnlineMeetingDropdownItemInitializer = () => {
  return {
    initialize: (initial: { type: OnlineMeetingType }): FormOnlineMeetingDropdownItemSetup => {
      const onlineMeetingType = ref(initial.type)
      return reactive({
        onlineMeetingType,
        handleUpdate: (newType: OnlineMeetingType) => (onlineMeetingType.value = newType)
      })
    }
  }
}
export const useFormOnlineMeetingDropdownItem = (initial: {
  type: OnlineMeetingType
}): FormOnlineMeetingDropdownItemSetup => {
  const { initialize } = useFormOnlineMeetingDropdownItemInitializer()
  return initialize(initial)
}

export const useOrganizerAndOnlineMeetingsConnectInitializer = () => {
  const i18n = useTranslation()
  const { openInfoBottomToast } = useToast()
  const organizerAndOnlineMeetingsConnectInitializer = (source: {
    getDefaultOnlineMeetingTool: (previousOnlineMeeting: OnlineMeetingType) => OnlineMeetingTool
    getAvailableOnlineMeetings: () => OnlineMeetingTool[]
    organizerForm: FormOrganizerDropdownItemSetup
    onlineMeetingForm: FormOnlineMeetingDropdownItemSetup
  }) => {

    const availableOnlineMeetings = computed(() =>
      source.getAvailableOnlineMeetings()
    )
    // 主催者変更時に利用できるonlineMeetingsも変わるので連動してupdateする
    function updateOnlineMeeting() {
      const onlineMeetingTool = source.getDefaultOnlineMeetingTool(
        source.onlineMeetingForm.onlineMeetingType
      )
      if (source.onlineMeetingForm.onlineMeetingType !== onlineMeetingTool.serviceType) {
        source.onlineMeetingForm.handleUpdate(onlineMeetingTool.serviceType)
        openInfoBottomToast({ message: i18n.t('message.changeOnlineMtgUnavailable').toString(), duration: 5000 })
      }
    }
    return {
      availableOnlineMeetings,
      handleOrganizerUpdate: (newOrganizerMemberId: string) => {
        source.organizerForm.handleUpdate(newOrganizerMemberId)
        updateOnlineMeeting()
      }
    }
  }
  return organizerAndOnlineMeetingsConnectInitializer
}

// Location
export type FormLocationInputFormSetup = {
  location: string
  handleUpdate: (newLocation: string) => void
}
export const useFormLocationInputFormInitializer = () => {
  return {
    initialize: (initial: { location: string }): FormLocationInputFormSetup => {
      const location = ref(initial.location)

      return reactive({
        location,
        handleUpdate: (newLocation: string) => (location.value = newLocation)
      })
    }
  }
}
export const useFormLocationInputForm = (initial: { location: string }): FormLocationInputFormSetup => {
  const { initialize } = useFormLocationInputFormInitializer()
  return initialize(initial)
}

// Visibility
export type FormVisibilitySelectBoxItemSetup = {
  visibility: VISIBILITY
  items: SelectItem[]
  handleUpdate: (newVisibility: VISIBILITY) => void
}
export const useFormVisibilitySelectBoxItemInitializer = () => {
  const i18n = useTranslation()
  const items = ['default', 'private', 'public'].map((n) => {
    return {
      key: n,
      label: i18n.t(`forms.event.visibility.${n}`).toString()
    }
  })
  return {
    initialize: (initial: { visibility: VISIBILITY }): FormVisibilitySelectBoxItemSetup => {
      const visibility = ref(initial.visibility)
      return reactive({
        visibility,
        items,
        handleUpdate: (newVisibility: VISIBILITY) => (visibility.value = newVisibility)
      })
    }
  }
}
export const useFormVisibilitySelectBoxItem = (initial: {
  visibility: VISIBILITY
}): FormVisibilitySelectBoxItemSetup => {
  const { initialize } = useFormVisibilitySelectBoxItemInitializer()
  return initialize(initial)
}

// ConfirmationEmailTargets
export type FormConfirmationEmailTargetsItemSetup = {
  confirmationEmailTargets: string[]
  handleReplace: (emails: string[]) => void
  handleNewConfirmationEmailTargetAdd: (newEmail: string) => void
  handleConfirmationEmailTargetDelete: (email: string) => void
}
export const useFormConfirmationEmailTargetsItemInitializer = () => {
  return {
    initialize: (initial: { emails: string[] }): FormConfirmationEmailTargetsItemSetup => {
      const confirmationEmailTargets = ref(initial.emails)
      function handleReplace(emails: string[]) {
        confirmationEmailTargets.value = emails
      }
      function handleNewConfirmationEmailTargetAdd(newEmail: string) {
        confirmationEmailTargets.value.push(newEmail)
      }
      function handleConfirmationEmailTargetDelete(deletedEmail: string) {
        const idx = confirmationEmailTargets.value.indexOf(deletedEmail)
        if (idx > -1) {
          confirmationEmailTargets.value.splice(idx, 1)
        }
      }
      return reactive({
        confirmationEmailTargets,
        handleReplace,
        handleNewConfirmationEmailTargetAdd,
        handleConfirmationEmailTargetDelete
      })
    }
  }
}
