

































import { AvailabilityModel } from '@/models/data'
import SharingModule from '@/store/modules/availabilityPatternList'
import Card from '@/components/card/molecules/Card.vue'
import HelpMessage from './_HelpMessage.vue'
import PatternCardBodyForPrivate from './_PatternCardBodyForPrivate.vue'
import PatternCardBodyForTeam from './_PatternCardBodyForTeam.vue'
import SettingMenu from './_SettingMenu.vue'
import ActionButtons from './_ActionButtons.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    Card,
    HelpMessage,
    PatternCardBodyForPrivate,
    PatternCardBodyForTeam,
    SettingMenu,
    ActionButtons
  },
  props: {
    isLoading: {
      type: Boolean,
      defaul: false
    },
    isDragDisabled: {
      type: Boolean,
      defaul: false
    },
    isTipVisible: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: Object as PropType<AvailabilityModel>,
      required: true
    },
    onPublishToggle: {
      type: Function as PropType<(pattern: AvailabilityModel) => Promise<void>>,
      required: true
    },
    onEdit: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    },
    onDelete: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    },
    onCandidatesShow: {
      type: Function as PropType<(pattern: AvailabilityModel) => Promise<void>>,
      required: true
    },
    onLinkCopy: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    },
    onShareModalShow: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    }
  },
  setup(props) {
    const fetchedModel = computed(() => SharingModule.fetchedPatternCandidate)
    const isActive = computed(() => props.pattern.id === fetchedModel.value?.id)
    const isFetchingModel = computed(() => fetchedModel.value?.isLoading)
    const isCandidatesFetching = computed(() => isActive.value && isFetchingModel.value)

    return {
      isActive,
      isFetchingModel,
      isCandidatesFetching
    }
  }
})
