













import { defineComponent, PropType } from '@vue/composition-api'
import CalendarItem from './_CalendarItem.vue'
import EmailItem from './_EmailItem.vue'
import MemberItem from './_MemberItem.vue'
import { AvailableAutocompleteListType } from '../types'

export default defineComponent({
  name: 'AutocompleteDisplayItem',
  components: {
    CalendarItem,
    EmailItem,
    MemberItem
  },
  props: {
    item: {
      type: Object as PropType<AvailableAutocompleteListType>,
      required: true
    }
  }
})
