import { OnlineMeetingTool } from '@/components/ui/domain/item/FormOnlineMeetingDropdownItem/onlineMeeting'
import { UsePeekCandidatesSection } from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/usePeekCandidatesSectionSetup'
import { UseVotingPeekCandidatesSection } from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/useVotingPeekCandidatesSectionSetup'
import { DatetimeSlotUpdatePayload } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { SelectItem } from '@/components/ui/spir/types'
import { noop } from '@/lib/utils'
import { PollModel } from '@/models/data/poll'
import { ScheduleDuration, ScheduleModel, ScheduleModelTeam } from '@/models/data/schedule'
import { Candidate, FullCalendarEvent, ITypeCalendarListForUI, OnlineMeetingType, VISIBILITY } from '@/types'
import { EditablePickCandidatesCondition } from '@/types/peekCandidates'
import { MemberResponse } from '@spirinc/contracts'
import { ComputedRef } from '@vue/composition-api'
import { SelectedEmailType } from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { CalendarCollection } from '../../../dropdown/CalendarDropdown/types'
import {
  FormAttendingEmailsSetup,
  FormConfirmationEmailTargetsItemSetup,
  FormOrganizerDropdownItemSetup
} from '../../../item/formItemComposables'
import { FormCalendarsAutoCompleteItemSetup } from '../peekCandidatesSectionComposables/_useFormCalendarsAutoCompleteItem'
import { FormWeekdayConditionItemSetup } from '../peekCandidatesSectionComposables/_useFormWeekdayConditionItem'
import { FetchCandidatesConditionParameter } from '../peekCandidatesSectionComposables/_usePeekCandidates'
import {
  FormDurationItemSetup,
  FormEndItemSetup,
  FormStartItemSetup,
  FormTimeBufferItemSetup
} from '../peekCandidatesSectionComposables/_usePeekCandidatesConditionForm'
import { DateSlot } from './../../../../form/FormDatetimeSlotsBox/datetimeSlot'
import {
  AllPeekCandidatesConditionForms,
  FormCountryHolidaysItemSetup,
  FormTimezoneItemSetup
} from './../peekCandidatesSectionComposables/_usePeekCandidatesConditionForm'

export type TitleSuggestionSetup = { suggestions: string[]; save: (newTitle: string) => void }
export type TitleSuggestionSetups = {
  personalScheduleSuggestionSetup: TitleSuggestionSetup
  personalPollSuggestionSetup: TitleSuggestionSetup
  getTeamScheduleSuggestionSetup: (teamId: string) => TitleSuggestionSetup
}

export type UpdateFormsBase = {
  title: string
  duration: ScheduleDuration
  description: string
  onlineMeeting: { type: OnlineMeetingType }
  location: string
  visibility: VISIBILITY
}
export type UsePageDataControlCommon = {
  pageData: ComputedRef
  saveTitleSuggestion: (title: string) => void
  isEditMode?: boolean
  goToNext: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  cancelEditing: (shouldStayHere: () => Promise<boolean>) => Promise<void>
}
export type PageDataControl = {
  clearDirty: () => void
  shouldStayHere: () => Promise<boolean>
  pageDataState: {
    isSyncing: boolean
    isPreviewDisabled: boolean
    isPreviewOpen: boolean
    previewData: { model: ScheduleModel | ScheduleModelTeam | PollModel; candidates: FullCalendarEvent[] }
  }
  handlers: {
    confirm: () => void
    cancel: () => Promise<void>
    preview: () => void
  }
}
export type FormCandidatesInfo = {
  candidates: FullCalendarEvent[]
  addNewCandidate: <T extends { start: Date; end: Date; id?: string }>(payload: T, revert: Function | null) => void
  clearCandidates: () => void
  removeCandidate: (candidateId: string) => void
}

export type OverwritingData = {
  title?: string
  duration?: ScheduleDuration
  description?: string
  location?: string
  visibility?: VISIBILITY
  candidates?: FullCalendarEvent[]
}

export type CandidatesInfo = {
  hasAtLeastOneCandidate: boolean
  candidates: FullCalendarEvent[]
  addNewCandidate: UsePeekCandidatesSection['addNewCandidate'] | UseVotingPeekCandidatesSection['addNewCandidate']
  removeCandidate: UsePeekCandidatesSection['removeCandidate'] | UseVotingPeekCandidatesSection['removeCandidate']
}

export type PeekCandidatesForm = {
  isCandidateLoading: boolean
  updateForms: (newValues: EditablePickCandidatesCondition) => void
  allConditions: AllPeekCandidatesConditionForms['allConditions']
  calendarsAutoCompleteForm: FormCalendarsAutoCompleteItemSetup
  weekdayForm: FormWeekdayConditionItemSetup
  durationForm: FormDurationItemSetup
  startForm: FormStartItemSetup
  endForm: FormEndItemSetup
  timeBufferForm: FormTimeBufferItemSetup
  timezoneForm: FormTimezoneItemSetup
  countriesForm: FormCountryHolidaysItemSetup
  mixTemporariesAndOthers: () => FullCalendarEvent[]
  fetchCandidates: (peekCondition: FetchCandidatesConditionParameter) => Promise<FullCalendarEvent[]>
  applyAutoPeekCandidates: () => void
}
// Personal POLL
export type UpdatePersonalPollForms = UpdateFormsBase & {
  author: { accountId: string; calendarId: string }
}
export type PersonalPollBasicInfo = {
  title: { value: string; suggestions: string[]; disabled: boolean; handleChange: (newTitle: string) => void }
  duration: {
    value: ScheduleDuration
    disabled: boolean
    items: SelectItem[]
    handleChange: (newDuration: ScheduleDuration) => void
  }
  author: {
    value: {
      accountId: string
      calendarId: string
    }
    disabled: boolean
    calendarCollections: CalendarCollection[]
    handleChange: (newValue: { accountId: string; calendarId: string }) => void
  }
}
export type PersonalPollEditablePeekCandidatesSection = {
  dateSlots: DateSlot[]
  durationToSplit: number
  onAllDelete: UsePeekCandidatesSection['clearCandidates']
  onSave: UsePeekCandidatesSection['addNewCandidate']
  onDatetimeDelete: (data: DatetimeSlotUpdatePayload) => void
  onDatetimeUpdate: (data: DatetimeSlotUpdatePayload) => void
  onCalendarOpen: () => void
}
export type PersonalPollOthersInfo = {
  description: {
    value: string
    handleChange: (desc: string) => void
  }
  onlineMeeting: {
    value: OnlineMeetingType
    tools: OnlineMeetingTool[]
    handleChange: (newType: OnlineMeetingType) => void
  }
  location: {
    value: string
    handleChange: (newLoc: string) => void
  }
  visibility: {
    value: VISIBILITY
    items: SelectItem[]
    handleChange: (newVisibility: VISIBILITY) => void
  }
}

export type CommonFormData = {
  clearDirty: PageDataControl['clearDirty']
  shouldStayHere: PageDataControl['shouldStayHere']
  pageDataState: PageDataControl['pageDataState']
  pageDataControl: PageDataControl['handlers']
  temporaryCandidatesInfo: { candidates: FullCalendarEvent[] }
  candidatesInfo: CandidatesInfo
  othersInfo: PersonalPollOthersInfo
  peekCandidatesForm: PeekCandidatesForm
}

export type FormDataForInitializing = {
  isForInitializing: true
  clearDirty: CommonFormData['clearDirty']
  pageDataState: Pick<PageDataControl['pageDataState'], 'isSyncing'>
  basicInfo: { duration: { value: number; handleChange: (handleChange: number) => void } }
  candidatesInfo: Pick<CandidatesInfo, 'hasAtLeastOneCandidate' | 'candidates' | 'addNewCandidate'>
  peekCandidatesForm: Pick<PeekCandidatesForm, 'isCandidateLoading' | 'fetchCandidates' | 'applyAutoPeekCandidates'>
}
export function getEmptyCommonFormData(): FormDataForInitializing {
  return {
    isForInitializing: true,
    clearDirty: () => ({}),
    basicInfo: { duration: { value: 0, handleChange: noop } },
    pageDataState: { isSyncing: true },
    candidatesInfo: { hasAtLeastOneCandidate: false, candidates: [], addNewCandidate: noop },
    peekCandidatesForm: {
      isCandidateLoading: true,
      fetchCandidates: () => Promise.resolve([]),
      applyAutoPeekCandidates: noop
    }
  }
}
export type PersonalPollFormData = CommonFormData & {
  basicInfo: PersonalPollBasicInfo
  peekCandidatesSection: {
    isEditMode: boolean
    setup: UsePeekCandidatesSection | UseVotingPeekCandidatesSection | PersonalPollEditablePeekCandidatesSection
  }
}

// Personal Schedule
export type UpdatePersonalScheduleForms = UpdateFormsBase & {
  author: { accountId: string; calendarId: string }
  attendeeEmails: SelectedEmailType[]
}
export type PersonalScheduleBasicInfo = {
  title: Omit<PersonalPollBasicInfo['title'], 'disabled'>
  duration: Omit<PersonalPollBasicInfo['duration'], 'disabled'>
  author: PersonalPollBasicInfo['author']
  attendees: {
    values: FormAttendingEmailsSetup['selectedEmails']
    availableAttendeeList: ITypeCalendarListForUI[]
    handleReplace: FormAttendingEmailsSetup['handleReplace']
    handleCalendarAdd: FormAttendingEmailsSetup['handleAdd']
    handleCalendarDelete: FormAttendingEmailsSetup['handleDelete']
  }
}
export type PersonalScheduleFormData = CommonFormData & {
  basicInfo: PersonalScheduleBasicInfo
  peekCandidatesSection: {
    isEditMode: boolean
  } & PersonalPollEditablePeekCandidatesSection
}

// Team Schedule
export type UpdateTeamScheduleForms = UpdateFormsBase & {
  organizerMemberId: string
  attendeeEmails: SelectedEmailType[]
  confirmationEmailTargets: string[]
  candidates: Candidate[]
}
export type TeamScheduleBasicInfo = {
  title: PersonalScheduleBasicInfo['title']
  duration: PersonalScheduleBasicInfo['duration']
  organizer: {
    value: FormOrganizerDropdownItemSetup['organizerMemberId']
    disabled: boolean
    calendarCollections: (MemberResponse & { fullName: string; backgroundColor: string })[]
    handleChange: FormOrganizerDropdownItemSetup['handleUpdate']
  }
  attendees: {
    values: PersonalScheduleBasicInfo['attendees']['values']
    availableMemberList: { teamId: string; member: MemberResponse & { fullName: string; backgroundColor: string } }[]
    handleCalendarAdd: PersonalScheduleBasicInfo['attendees']['handleCalendarAdd']
    handleCalendarDelete: PersonalScheduleBasicInfo['attendees']['handleCalendarDelete']
  }
  confirmationEmailTargets: {
    values: FormConfirmationEmailTargetsItemSetup['confirmationEmailTargets']
    handleEmailAdd: FormConfirmationEmailTargetsItemSetup['handleNewConfirmationEmailTargetAdd']
    handleEmailDelete: FormConfirmationEmailTargetsItemSetup['handleConfirmationEmailTargetDelete']
  }
}
export type TeamScheduleFormData = CommonFormData & {
  basicInfo: TeamScheduleBasicInfo
  peekCandidatesSection: {
    isEditMode: boolean
  } & PersonalPollEditablePeekCandidatesSection
}

export type ArrangementTypeFormData = PersonalPollFormData | PersonalScheduleFormData | TeamScheduleFormData
