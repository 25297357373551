


















import ConfirmedListDateTitle from '@/components/adjustmentList/ConfirmedListDateTitle.vue'
import ConfirmedPrivateCardContent from '@/components/adjustmentList/ConfirmedPrivateCardContent.vue'
import ConfirmedTeamCardContent from '@/components/adjustmentList/ConfirmedTeamCardContent.vue'
import { ConfirmedPrivateDateGroup, ConfirmedTeamDateGroup } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmedListDateTitle,
    ConfirmedPrivateCardContent,
    ConfirmedTeamCardContent
  },
  props: {
    dateGroups: {
      type: Array as PropType<Array<ConfirmedPrivateDateGroup> | Array<ConfirmedTeamDateGroup>>,
      required: true
    }
  }
})
