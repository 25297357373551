













import { Component, Prop, Vue } from 'vue-property-decorator'
import Section from '../atoms/Section.vue'
import { JoiningTeam } from '@spirinc/contracts'
import TeamItem from '../atoms/TeamItem.vue'

@Component({
  components: {
    Section,
    TeamItem
  }
})
export default class UserBadgeTeamList extends Vue {
  @Prop() teams: JoiningTeam[]
}
