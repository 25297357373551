














import SpirButton, { ButtonType } from '@/components/ui/Button.vue'
import PagefooterFrame from '@/components/ui/page/pagefooter/PagefooterFrame.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export type FooterButton = {
  type: ButtonType
  disabled?: boolean
  title: string
  onClick: Function
}
export default defineComponent({
  name: 'FormFooterButtons',
  components: {
    PagefooterFrame,
    SpirButton
  },
  props: {
    buttons: {
      type: Array as PropType<Array<FooterButton>>
    }
  }
})
