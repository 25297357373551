














import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { IconSetting, TitleSetting } from '@/components/ui/form/item/formItemSetting'
import SpirTextarea from '@/components/ui/spir/SpirTextarea.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FormTextareaItem',
  components: {
    FormItemSpacer,
    FormItem,
    FormItemTitle,
    SpirTextarea
  },
  props: {
    icon: {
      type: Object as PropType<IconSetting>,
      required: true
    },
    title: {
      type: Object as PropType<TitleSetting>,
      required: true
    },
    value: {
      type: String
    },
    rows: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    }
  },
  setup(props, context) {
    const model = computed({
      get: () => props.value,
      set: (newDesc: string) => context.emit('input', newDesc)
    })
    return {
      model
    }
  }
})
