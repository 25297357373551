






import AllDayEvent from './AllDayEvent.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FullCalendarEvent } from '@/types'

@Component({
  components: {
    AllDayEvent
  }
})
export default class DailyCalendarAllDay extends Vue {
  @Prop() allDayEvents: FullCalendarEvent[]
}
