import { OrganizerInfo } from '../../../types/schedule'
import { SpirUser } from '@/types'

export const FromOrganizerInfo = {
  convertToSpirUser(info: OrganizerInfo, overrideOption?: Partial<SpirUser>): SpirUser {
    return {
      id: overrideOption?.id || info.userId,
      fullName: overrideOption?.fullName || info?.fullName,
      name: overrideOption?.name || info?.fullName,
      photoURL: overrideOption?.photoURL || info.photoURL
    }
  }
}
