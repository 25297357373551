











































import { Component, Prop, Vue } from 'vue-property-decorator'
import EditPollModule from '@/store/modules/editPoll'
import ConfirmDescription from '@/components/sidePanels/confirm/ConfirmDescription.vue'
import ConfirmMap from '@/components/sidePanels/confirm/ConfirmMap.vue'
import ConfirmToolLink from '@/components/sidePanels/confirm/ConfirmToolLink.vue'
import ConfirmCandidateBlockTitleBox from '@/components/sidePanels/confirm/atoms/ConfirmCandidateBlockTitleBox.vue'
import ConfirmItemSpacer from '@/components/sidePanels/confirm/atoms/ConfirmItemSpacer.vue'
import PollVotersInfo from '@/components/sidePanels/confirm/poll/PollVotersInfo.vue'
import Divider from '@/components/atoms/Divider.vue'
import PollVoteCandidateListBox from '@/components/sidePanels/confirm/poll/PollVoteCandidateListBox.vue'
import ConfirmTimeZoneSelect from '@/components/sidePanels/confirm/atoms/ConfirmTimeZoneSelect.vue'
import { Attendee, CandidateVote, PollAnswer, ProfileOnOverview } from '@/types'
import { PollModel } from '@/models/data'
import { FromAttendee } from '@/lib/utils'
import VoteModule, { CandidateForVote, IVote } from '@/store/modules/vote'
import ProfileModule from '@/store/modules/profile'
import { sortBy } from 'lodash'
import { parseISO, isBefore } from 'date-fns'
import { SignalType } from '@/lib/analytics'
import candidateDates from '@/filters/candidateDates'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import AppModule from '@/store/modules/app'
import TitleAndLanguage from '@/components/sidePanels/confirm/TitleAndLanguage.vue'

@Component({
  components: {
    ConfirmItemSpacer,
    PollVotersInfo,
    ConfirmDescription,
    ConfirmMap,
    ConfirmToolLink,
    ConfirmCandidateBlockTitleBox,
    Divider,
    PollVoteCandidateListBox,
    ConfirmTimeZoneSelect,
    TitleAndLanguage
  }
})
export default class PrivateVoteOperationBox extends Vue {
  @Prop({ default: false }) isPreview: boolean
  @Prop() previewVotes: IVote[]
  @Prop() onPreviewAnswerUpdate: (candidateId: string, newAnswer: PollAnswer) => void

  get poll(): PollModel {
    return EditPollModule.editingPoll
  }

  get author(): ProfileOnOverview {
    const author = this.poll.author
    return {
      fullName: author?.fullName || author?.name,
      photoURL: author?.photoURL,
      userId: author?.id,
      isAuthor: true,
      isLoading: !author
    }
  }

  get voters(): ProfileOnOverview[] {
    return EditPollModule.getAttendees
      .filter((attendee: Attendee) => attendee.id !== this.poll.author?.id)
      .map((attendee: Attendee) => {
        return FromAttendee.convertToProfileOnOverview(attendee, { isLoading: false, isAuthor: false })
      })
  }

  get description(): string {
    return this.poll.description
  }

  get location(): string {
    return this.poll.location
  }

  get hasOnlineMeeting(): boolean {
    return this.poll.onlineMtg
  }

  get votes(): IVote[] {
    return this.isPreview ? this.previewVotes : VoteModule.getVotes
  }

  get currentCandidates(): CandidateForVote[] {
    return this.isPreview ? this.previewCurrentCandidates : VoteModule.getCurrentVotes
  }

  get previewCurrentCandidates() {
    const candidates: CandidateForVote[] = sortBy(EditPollModule.getExistCandidates, ['start']).map(
      (c: CandidateVote) => {
        return {
          id: c.id,
          start: parseISO(c.start),
          end: parseISO(c.end),
          yesCount: 0,
          noCount: 0,
          votes: [],
          isDisabled: isBefore(parseISO(c.start), new Date())
        }
      }
    )
    return candidates
  }

  get myProfile() {
    return ProfileModule.myProfile
  }

  get timeZone(): string | undefined {
    return this.myProfile?.primaryTimezoneKey
  }

  get maxYesCount(): number {
    return VoteModule.getMaxYesCount
  }
  get isSyncing() {
    return EditPollModule.isSyncing
  }
  get isNarrow(): boolean {
    return AppModule.isNarrow
  }
  get displayCandidates(): string {
    return candidateDates(this.poll)
  }

  get duration(): number {
    return this.poll.duration
  }
  async handleAnswerUpdate(data: { candidateId: string; answer: PollAnswer }) {
    if (this.isPreview && this.onPreviewAnswerUpdate) {
      this.onPreviewAnswerUpdate(data.candidateId, data.answer)
    } else {
      await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_PANEL, { id: this.poll.id })
      VoteModule.updateAnswer({
        candidateId: data.candidateId,
        newAnswer: data.answer
      })
    }
  }

  handleCalendarOpen() {
    AppScreenControlModule.toggleScheduleEventsList(false)
  }
}
