













import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import { defineComponent, PropType } from '@vue/composition-api'
import EmailDeleteActionBox from './_EmailDeleteActionBox.vue'

export default defineComponent({
  name: 'RegisteredEmailList',
  components: {
    SpirTextInput,
    EmailDeleteActionBox
  },
  props: {
    emails: {
      type: Array as PropType<string[]>,
      default: []
    },
    onDelete: {
      type: Function as PropType<(email: string) => void>,
      required: true
    }
  }
})
