

















































import { Component, Watch, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import TeamRecordModule from '@/store/modules/teamRecord'
import Divider from '@/components/atoms/Divider.vue'
import TeamSettingLayout from '@/components/teamSettings/TeamSettingsLayout.vue'
import TeamHeader from '@/components/teamSettings/headers/TeamHeader.vue'
import TeamMemberItem from '@/components/teamSettings/items/TeamMemberItem.vue'
import InvitedMemberItem from '@/components/teamSettings/items/InvitedMemberItem.vue'
import InviteMemberModal from '@/components/teamSettings/modals/InviteMemberModal.vue'
import { APIError } from '@/lib/sdk/error'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { MemberResponse } from '@spirinc/contracts'
import UserModule from '@/store/modules/user'
import { copyClipboard } from '@/lib/utils'
import Message from '@/components/molecules/Message.vue'
import AvailabilityPatternListModule from '@/store/modules/availabilityPatternList'
import ConfirmModal from '@/components/modal/Confirm.vue'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import { ScheduleStatus } from '@/types'

@Component({
  components: {
    Avatar,
    Divider,
    TeamSettingLayout,
    TeamHeader,
    TeamMemberItem,
    InvitedMemberItem,
    InviteMemberModal,
    Message,
    ConfirmModal
  }
})
export default class TeamMember extends Vue {
  isInviting = false
  updatedAuthorityMemberId: string | null = null
  invitedError: { message: string; isActive: boolean }[] = []
  deleteTeamMemberId: string | null = null
  isFetchAvailabilites = false

  @Watch('teamId')
  resetError() {
    this.invitedError = []
  }
  get teamId() {
    return this.$route.params.id
  }
  get team() {
    return TeamRecordModule.teamByTeamId(this.teamId)
  }
  get teamMembers() {
    return this.team?.team?.members || []
  }
  get invitedMembers() {
    return this.team?.team?.invitations || []
  }
  get isLoading() {
    return (
      this.isInviting ||
      TeamRecordModule.isLoading(this.teamId) ||
      this.deleteTeamMemberId !== null ||
      this.isFetchAvailabilites
    )
  }
  get amIAdmin() {
    return TeamRecordModule.myInfoByTeamId(this.teamId)?.authority === 'administrator'
  }

  get currentUser() {
    return UserModule.currentUser
  }

  async cancelInvite(invitationId) {
    try {
      await TeamRecordModule.cancelInvitation({ teamId: this.teamId, invitationId: invitationId })
      this.$buefy.toast.open({
        message: this.$t('teamMember.message.successCancel').toString(),
        type: 'is-info'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('teamMember.message.errors.ETC').toString(),
        type: 'is-danger'
      })
    }
  }
  async resendInvite(invitationId) {
    try {
      await TeamRecordModule.resendInvitation({ teamId: this.teamId, invitationId: invitationId })
      this.$buefy.toast.open({
        message: this.$t('teamMember.message.successInvite').toString(),
        type: 'is-info'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('teamMember.message.errors.ETC').toString(),
        type: 'is-danger'
      })
    }
  }
  async sendInvitation(emails: string[]) {
    const sendInvitation = async (email): Promise<{ email: string; error?: Error }> => {
      try {
        await TeamRecordModule.invitationMember({ teamId: this.teamId, email })
        return Promise.resolve({ email })
      } catch (e: any) {
        return Promise.resolve({ email, error: e })
      }
    }
    try {
      this.isInviting = true
      this.invitedError = []
      const response = await Promise.all(emails.map((e) => sendInvitation(e)))
      await TeamRecordModule.fetchTeam({ teamId: this.teamId })
      this.invitedError = response
        .filter((r) => r.error)
        .map((r) => {
          let message = this.$t('teamMember.message.errors.ETC').toString()
          if (r.error instanceof APIError && r.error.code === ERROR_CODE.EMAIL_ALREADY_INVITED) {
            message = this.$t('teamMember.message.errors.EMAIL_ALREADY_INVITED', { email: r.email }).toString()
          }
          return {
            message: message,
            isActive: true
          }
        })
      this.$buefy.toast.open({
        message: this.$t('teamMember.message.successInvite').toString(),
        type: 'is-info',
        duration: 5000
      })
    } finally {
      this.isInviting = false
    }
  }
  async copyInviteUrl(invitationId: string) {
    try {
      const payload = {
        teamId: this.teamId,
        invitationId
      }
      const data = await TeamRecordModule.fetchInvitationUrl(payload)
      const invitationUrl = data.invitationUrl
      copyClipboard(invitationUrl)
      this.$buefy.toast.open({
        message: this.$t('message.copiedToClipBoard').toString(),
        type: 'is-success'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('teamMember.message.errors.INVITATION_ID_NOT_FOUND').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
  handleOpenInviteMemberModal() {
    const existEmails = this.teamMembers.map((member) => member.fullName)
    const modal = this.$buefy.modal.open({
      parent: this,
      component: InviteMemberModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        existEmails
      },
      events: {
        submit: async (emails: string[]) => {
          this.sendInvitation(emails)
          modal.close()
        }
      }
    })
  }
  async updateMemberAuthority(member: MemberResponse) {
    this.updatedAuthorityMemberId = member.id
    const data = {
      teamId: this.teamId,
      memberId: member.id,
      authority: member.authority
    }
    try {
      await TeamRecordModule.updateTeamMemberAuthority(data)
      this.$buefy.toast.open({
        message: this.$t('message.success.saved').toString(),
        type: 'is-success'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('messages.errorCommon').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.updatedAuthorityMemberId = null
    }
  }
  async deleteTeamMember(memberId: string) {
    this.deleteTeamMemberId = memberId
    try {
      const payload = {
        teamId: this.teamId,
        memberId
      }
      await TeamRecordModule.deleteTeamMember(payload)
      this.$buefy.toast.open({
        message: this.$t('message.success.deleted', { type: this.$t('teamMenu.team.member') }).toString(),
        type: 'is-success'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('message.errorCommon').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.deleteTeamMemberId = null
    }
  }

  showMemberDeleteConfirmModal(memberId: string, availabilityLength: number, teamScheduleLength: number) {
    const message =
      availabilityLength > 0 || teamScheduleLength > 0
        ? this.$t('teamMenu.member.delete-confirm-left-data', {
            availabilityCount: availabilityLength,
            scheduleCount: teamScheduleLength
          })
        : this.$t('teamMenu.member.delete-confirm')
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: this.$t('teamMenu.member.delete-label').toString(),
        body: message,
        confirmBtn: this.$t('buttons.delete').toString(),
        btnType: 'is-danger'
      },
      events: {
        close: () => {
          modal.close()
        },
        cancel: () => {
          modal.close()
        },
        confirm: async () => {
          modal.close()
          this.deleteTeamMember(memberId)
        }
      }
    })
  }

  async fetchAvailabilities(member: MemberResponse) {
    try {
      this.isFetchAvailabilites = true
      const query = `organizerMemberId=${member.id}&limit=10000`
      const status: ScheduleStatus[] = [
        'suggestedByOrganizer',
        'requestedByConfirmer',
        'requestedByOrganizer',
        'suggestedByConfirmer'
      ]
      let availabilityLength
      let teamScheduleLength
      await Promise.all([
        AvailabilityPatternListModule.fetchMemberAvailabilityLength({
          teamId: this.teamId,
          query
        }),
        TeamScheduleListModule.fetchTeamSchedule({
          teamId: this.teamId,
          status,
          organizerMemberId: member.id
        })
      ]).then((res) => {
        availabilityLength = res[0]
        teamScheduleLength = res[1].schedules.length
      })
      this.isFetchAvailabilites = false
      this.showMemberDeleteConfirmModal(member.id, availabilityLength, teamScheduleLength)
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('message.errorCommon').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
