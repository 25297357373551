import { SchedulesGetResponse, Event, EventAttendee } from '@spirinc/contracts'
import { SpirAttendeeStatus } from '@/types'
import { AfterConfirmModel, Attendee } from '@/models/data/afterConfirm'

export class AfterConfirmModelFactoryForSchedule {
  static create(schedule: SchedulesGetResponse, confirmedEvent: Event) {
    const attendees = this.syncEventAttendeeWithSchedule(confirmedEvent.attendees, schedule)
    return new AfterConfirmModel({
      type: 'schedule',
      teamOrPrivate: 'private',
      id: schedule.id,
      date: {
        start: new Date(confirmedEvent.start),
        end: new Date(confirmedEvent.end)
      },
      onlineMeeting: confirmedEvent.onlineMeeting,
      description: schedule.description,
      title: schedule.title,
      location: schedule.location,
      attendees: attendees
    })
  }

  static syncEventAttendeeWithSchedule(eventAttendees: EventAttendee[], schedule: SchedulesGetResponse) {
    const scheduleAuthor = eventAttendees.find((at) => at.organizer)
    const attendees: Attendee[] = [
      {
        fullName: schedule.author.name,
        email: scheduleAuthor.email,
        id: schedule.author.id,
        responseStatus: SpirAttendeeStatus.ACCEPTED,
        organizer: true
      }
    ]
    eventAttendees.forEach((at) => {
      if (at.organizer) {
        return
      }
      attendees.push({
        fullName: at.fullName,
        email: at.email,
        id: at.id,
        responseStatus: at.responseStatus as SpirAttendeeStatus,
        organizer: false
      })
    })
    return attendees
  }
}
