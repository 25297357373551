import { useAuthAuthcode } from './useAuthAuthcode'
import queryString from 'query-string'
import config from '@/lib/config'
import { LOCAL_STORAGE_KEY_FOR_CALLBACK_MS } from '@/types'
import { useRoute } from 'vue2-helpers/vue-router'
import CalendarsModule from '@/store/modules/calendars'
import { AuthErrorTypes } from '@/types/authError'

export type AddCalendarSuccess = {
  result: 'success' | 'cancel'
}
export type AddCalendarError = {
  result: 'error'
  errorCode: AuthErrorTypes
}
export type AddCalendarResult = Promise<AddCalendarSuccess | AddCalendarError>

export const useAddCalendar = () => {
  const route = useRoute()
  const { registerAuthcode } = useAuthAuthcode()
  const getMicrosoftAuthCode = () => {
    const parameter = queryString.stringify({
      ...config.microsoft,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: process.env.VUE_APP_MICROSOFT_CLIENT_ID
    })
    // when turn back from MS login page after redirecting, we will lose schedule id, so save id to local storage
    if (route.name !== 'AddCalendar') {
      window.localStorage.setItem(LOCAL_STORAGE_KEY_FOR_CALLBACK_MS, route.fullPath)
    }
    window.location.search = parameter
    window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' + parameter
  }

  const addMicrosoftCalendar = async (authCode: string): AddCalendarResult => {
    const registerResponse = await registerAuthcode(authCode, 'microsoft')
    if (registerResponse.result !== 'success') {
      return registerResponse
    }
    await CalendarsModule.fetchAfterAddCalendar()
    return {
      result: 'success'
    }
  }

  return {
    getMicrosoftAuthCode,
    addMicrosoftCalendar
  }
}
