









import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from './molecules/Item.vue'
import HtmlDisplay from '@/components/molecules/HtmlDisplay.vue'
import MapOpenButton from '@/components/atoms/MapOpenButton.vue'

@Component({
  components: {
    DisplayItem,
    HtmlDisplay,
    MapOpenButton
  }
})
export default class DisplayLocation extends Vue {
  @Prop() item: string

  openGoogleMaps() {
    if (!this.item) {
      return
    }
    window.open(`https://www.google.com/maps?q=${this.item}`, '_blank')
  }
}
