import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { AvailabilityModelForPrivate, AvailabilityModelForTeam, AvailabilityModel } from '@/models/data'
import { FullCalendarEvent } from '@/types'
import { FormMode, FormType } from '@/types/availabilitySharingForm'
import { personalId } from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import { cloneDeep } from 'lodash'
import EditAvailabilityModule from './editAvailability'
import EditAvailabilityTeamModule from './editAvailabilityTeam'

const MODULE_NAME = 'EditAvailabilitySharingForm'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class EditAvailabilitySharingForm extends VuexModule {
  private _mode: FormMode = 'create'
  private _type: FormType = 'personal'

  get formMode() {
    return this._mode
  }

  get type() {
    return this._type
  }

  get personalTeamValue() {
    // FIXME: チームが新規作成完了するとresetされるのでgetModelがnullになりteamIdがとれなくなることが発生するので根本解決が必要
    return this._type === 'personal' ? personalId : EditAvailabilityTeamModule.getModel?.teamId
  }

  get model(): AvailabilityModel {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.getModel
      case 'team':
        return EditAvailabilityTeamModule.getModel
    }
  }

  get modelForPrivate(): AvailabilityModelForPrivate {
    return EditAvailabilityModule.getModel
  }

  get modelForTeam(): AvailabilityModelForTeam {
    return EditAvailabilityTeamModule.getModel
  }

  get isLoading() {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.getIsLoading
      case 'team':
        return EditAvailabilityTeamModule.getIsLoading
    }
  }

  get isCandidateLoading() {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.getIsCandidateLoading
      case 'team':
        return EditAvailabilityTeamModule.getIsCandidateLoading
    }
  }

  get isDirty() {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.isDirty
      case 'team':
        return EditAvailabilityTeamModule.isDirty
    }
  }

  get candidatesByMerged(): FullCalendarEvent[] {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.candidatesByMerged
      case 'team':
        return EditAvailabilityTeamModule.candidatesByMerged
    }
  }

  @Action
  newAvailability({ type, teamId }: { type: FormType; teamId?: string }) {
    this.SET_MODE('create')
    this.SET_TYPE(type)
    switch (this._type) {
      case 'personal':
        EditAvailabilityModule.newAvailability()
        break
      case 'team':
        EditAvailabilityTeamModule.newAvailabilityForTeam(teamId)
        break
    }
  }

  @Action
  async fetchAvailability({
    type,
    availabilitySharingId,
    teamId
  }: {
    type: FormType
    availabilitySharingId: string
    teamId?: string
  }) {
    this.SET_MODE('edit')
    this.SET_TYPE(type)
    switch (this._type) {
      case 'personal':
        await EditAvailabilityModule.fetchAvailability(availabilitySharingId)
        break
      case 'team':
        await EditAvailabilityTeamModule.fetchAvailability({ teamId, id: availabilitySharingId })
        break
    }
  }

  @Action
  updateModel(newModel: AvailabilityModel) {
    switch (this._type) {
      case 'personal':
        if (newModel instanceof AvailabilityModelForPrivate) {
          EditAvailabilityModule.updateModel(newModel)
        }
        break
      case 'team':
        if (newModel instanceof AvailabilityModelForTeam) {
          EditAvailabilityTeamModule.updateModel(newModel)
        }
        break
    }
  }

  @Action
  async fetchCandidatesByParam() {
    switch (this._type) {
      case 'personal':
        await EditAvailabilityModule.fetchCandidatesByParam()
        break
      case 'team':
        await EditAvailabilityTeamModule.fetchCandidatesByParam()
        break
    }
  }

  @Action
  async changeFormType({ type, teamId }: { type: FormType; teamId?: string }) {
    if (this._mode !== 'create') {
      return
    }
    // typeを変更した時に内容を詰め替える
    const preTitle = this.model.title
    const preCandidateTitle = this.model.candidateTitle
    const preCandidateDescription = this.model.candidateDescription
    switch (type) {
      case 'personal': {
        await EditAvailabilityModule.newAvailability()
        this.SET_TYPE(type)
        EditAvailabilityTeamModule.reset()
        break
      }
      case 'team': {
        await EditAvailabilityTeamModule.newAvailabilityForTeam(teamId)
        this.SET_TYPE(type)
        EditAvailabilityModule.reset()
        break
      }
    }
    const clone = cloneDeep(this.model)
    clone.title = preTitle
    clone.candidateTitle = preCandidateTitle
    clone.candidateDescription = preCandidateDescription
    this.updateModel(clone)
  }

  @Action
  async create(): Promise<string> {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.create()
      case 'team':
        return EditAvailabilityTeamModule.create()
    }
  }

  @Action
  async update(): Promise<string> {
    switch (this._type) {
      case 'personal':
        return EditAvailabilityModule.update()
      case 'team':
        return EditAvailabilityTeamModule.update()
    }
  }

  @Action
  reset() {
    this.SET_MODE('create')
    this.SET_TYPE('personal')
    EditAvailabilityModule.reset()
    EditAvailabilityTeamModule.reset()
  }

  @Mutation
  SET_MODE(mode: FormMode) {
    this._mode = mode
  }

  @Mutation
  SET_TYPE(type: FormType) {
    this._type = type
  }
}

export default getModule(EditAvailabilitySharingForm)
