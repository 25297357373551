import i18n from '@/i18n'
import { isSameMonth, isThisYear } from '@/lib/utils/timezone'
import { spirDateFormatCustom, spirDateFormatCustomTypes } from '@/lib/utils/dateFormat'

/**
 * testしやすくするため、コンポーネントから分離
 * currentDateが今年ではない時 yyyy mm
 * 今年の場合 mm
 * 次のDateのmonthが変わる場合 mm-mm月
 * @param currentDate
 * @param nextDate
 * @returns
 */
export const fromtDateToCalendarHeader = (now: Date, currentDate: Date, nextDate: Date): string => {
  const getYearString = () => {
    if (isThisYear(now, currentDate)) {
      return ''
    }
    return i18n
      .t('calendar.year', { year: spirDateFormatCustom(currentDate, spirDateFormatCustomTypes.yyyy) })
      .toString()
  }
  const getMonthString = () => {
    const FORMAT_FOR_MONTH = spirDateFormatCustomTypes.MMM
    const nextDateMonth = spirDateFormatCustom(nextDate, FORMAT_FOR_MONTH)
    const nextDateMonthString = i18n.t('calendar.month', { month: nextDateMonth }).toString()
    if (isSameMonth(currentDate, nextDate)) {
      return nextDateMonthString
    }
    const currentDateMonth = spirDateFormatCustom(currentDate, FORMAT_FOR_MONTH)
    return `${currentDateMonth}-${nextDateMonthString}`
  }
  return getYearString() ? `${getYearString()} ${getMonthString()}` : getMonthString()
}
