


































import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { computed, defineComponent, PropType, provide, reactive, ref, toRefs } from '@vue/composition-api'
import {
  DateSlot,
  DatetimeSlot,
  DatetimeSlotDeletePayload,
  DatetimeSlotEditorSetting,
  DatetimeSlotSavePayload,
  DatetimeSlotUpdatePayload
} from './datetimeSlot'
import DatetimeSlotEditor from './DatetimeSlotEditor.vue'
import DateSlotListItem from './_DateSlotListItem.vue'
import OperationHeader from './_OperationHeader.vue'

export default defineComponent({
  name: 'FormDatetimeSlotsBox',
  components: {
    OperationHeader,
    DatetimeSlotEditor,
    FormItemSpacer,
    DateSlotListItem
  },
  props: {
    addButtonLabel: {
      type: String,
      required: true
    },
    dateSlots: {
      type: Array as PropType<DateSlot[]>,
      default: () => []
    },
    durationToSplit: {
      type: Number
    },
    isDurationFixed: {
      type: Boolean,
      default: false
    },
    isPastDisabled: {
      type: Boolean,
      default: true
    },
    minInterval: {
      type: Number
    },
    onAllDelete: {
      type: Function as PropType<() => void>,
      required: true
    },
    onSave: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>,
      required: true
    },
    onDatetimeDelete: {
      type: Function as PropType<(payload: DatetimeSlotDeletePayload) => void>,
      required: true
    },
    onDatetimeUpdate: {
      type: Function as PropType<(payload: DatetimeSlotUpdatePayload) => void>,
      required: true
    }
  },
  setup(props) {
    const { dateSlots, durationToSplit, isDurationFixed, isPastDisabled, minInterval } = toRefs(props)
    const slotEditorSettings = reactive({ durationToSplit, isDurationFixed, isPastDisabled, minInterval })
    provide(DatetimeSlotEditorSetting, slotEditorSettings)
    // 候補が0個または一つでもinvalidなDatetimeSlotがあればDelete All ButtonをDisabledにする
    const isAllDeleteDisabled = computed(() => {
      const empty = dateSlots.value.length === 0
      if (empty) return true
      const hasAnyInvalidateDatetimeSlot = dateSlots.value.reduce((acc, dateSlot) => {
        const invalidDatetimeSlot = dateSlot.datetimeSlots.find(
          (dateTimeSlot: DatetimeSlot) => !dateTimeSlot.isValidCandidate
        )
        return acc || !!invalidDatetimeSlot
      }, false)
      return hasAnyInvalidateDatetimeSlot
    })
    const isEditorOpened = ref(false)
    const addButtonDisabled = computed(() => isEditorOpened.value)
    function closeEditor() {
      isEditorOpened.value = false
    }
    return {
      isAllDeleteDisabled,
      addButtonDisabled,
      isEditorOpened,
      handleAddButtonClick: () => {
        isEditorOpened.value = true
      },
      handleDeleteButtonClick: () => {
        props.onAllDelete()
      },
      handleCancel: () => {
        closeEditor()
      },
      handleSave: (data: DatetimeSlotSavePayload) => {
        props.onSave(data)
        closeEditor()
      }
    }
  }
})
