












































import Vue from 'vue'
import { Component, PropSync, Prop } from 'vue-property-decorator'
import { OnlineMeetingType } from '@/types'
import UserModule from '@/store/modules/user'
import OnlineMeetingToolInfoModal from '@/components/modal/OnlineMeetingToolInfo.vue'
import ZoomSignInButton from '@/components/atoms/ZoomSigninButton.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'

interface IOnlineMeetingItemParams {
  serviceType: OnlineMeetingType
  isConnected?: boolean
  accountName?: string
}

@Component({
  components: {
    ZoomSignInButton,
    FormItem
  }
})
export default class OnlineMeetingSelector extends Vue {
  @PropSync('selectedService', { default: OnlineMeetingType.none }) selectedServiceSynced!: OnlineMeetingType
  @Prop() availableOnlineMeetings: OnlineMeetingType[]
  @Prop({ default: true }) canZoomIntegration: boolean

  get showZoomIntegration() {
    return this.canZoomIntegration
  }
  get onlineMeetingTools(): IOnlineMeetingItemParams[] {
    return this.availableOnlineMeetings.reduce(
      (a: IOnlineMeetingItemParams[], c) => {
        a.push({
          serviceType: c
        })
        return a
      },
      [
        {
          serviceType: OnlineMeetingType.none
        }
      ]
    )
  }
  get zoomInfo() {
    return {
      serviceType: OnlineMeetingType.zoom,
      isConnected: UserModule.isConnectedZoom,
      accountName: UserModule.integrations.zoomInfo?.email
    }
  }

  serviceName(serviceType: OnlineMeetingType) {
    return this.$t(`onlineMeetings.${serviceType.toString()}`).toString()
  }

  buttonState(isConnected: boolean) {
    if (isConnected) {
      return {
        type: '',
        title: this.$t('buttons.disconnect')
      }
    }

    return {
      type: 'is-primary',
      title: this.$t('buttons.connect')
    }
  }

  isSelected(item: IOnlineMeetingItemParams) {
    return item.serviceType === this.selectedServiceSynced
  }

  canSelect(item: IOnlineMeetingItemParams) {
    // NOTE: 連携情報の無いサービスは常に選択可能
    return item.isConnected == null ? true : item.isConnected
  }

  onClickItem(item: IOnlineMeetingItemParams) {
    if (!this.canSelect(item)) {
      return
    }
    this.selectedServiceSynced = item.serviceType
    this.$emit('input')
  }

  onClickSettingIcon(item: IOnlineMeetingItemParams) {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: OnlineMeetingToolInfoModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        accountName: item.accountName
      },
      events: {
        onClickDisconnectionButton: async () => {
          await UserModule.disconnectZoom()
          if (this.selectedServiceSynced === OnlineMeetingType.zoom) {
            this.selectedServiceSynced = OnlineMeetingType.none
          }
          modal.close()
        }
      }
    })
  }
}
