






















import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonToWrapIcon from '@/components/atoms/ButtonToWrapIcon.vue'

@Component({
  components: {
    ButtonToWrapIcon
  }
})
export default class MapOpenButton extends Vue {
  @Prop() location: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: 'text' }) mapOpenButtonType: 'text' | 'icon'

  get isMapOpenButtonTextType(): boolean {
    return this.mapOpenButtonType === 'text'
  }

  openGoogleMaps() {
    if (!this.location) {
      return
    }
    window.open(`https://www.google.com/maps?q=${this.location}`, '_blank')
  }
}
