






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeZoneModule from '@/store/modules/timezones'
import { localTimezone } from '@/lib/timezone'
import TimezoneItem from '@/components/organisms/timezoneSelector/TimezoneItem.vue'
import { timezoneGroups } from '@/lib/timezone'
import SpirDropdown from '@/components/ui/spir/SpirDropdown/SpirDropdown.vue'

@Component({
  components: {
    TimezoneItem,
    SpirDropdown
  }
})
export default class TimezoneSelectorCommon extends Vue {
  $refs: {
    searchInput: any
  }
  @Prop({ default: '' }) timeZone: string
  @Prop({ default: 'medium' }) size: 'small' | 'medium'

  searchText = ''
  searchedKeys = []

  get getLocalTimezoneKey() {
    return localTimezone()
  }
  get getLocalTimeZone() {
    return this.timezoneInfoByKey(localTimezone())
  }
  get groupByTimezone() {
    const allGroup = TimeZoneModule.timezonesByGroup
    if (this.searchedKeys.length === 0) {
      return allGroup
    }
    const filtered = Object.keys(allGroup).reduce((a, c) => {
      a[c] = allGroup[c].filter((tz) => this.searchedKeys.indexOf(tz.key) >= 0)
      return a
    }, {})
    return filtered
  }
  get groups(): string[] {
    return timezoneGroups
  }
  timezoneInfoByKey(key: string) {
    return TimeZoneModule.timezoneByKey({ key })
  }
  activeChanged(dropdownStatus: boolean) {
    this.$emit('activeChanged', dropdownStatus)
    if (dropdownStatus) {
      const timeZoneByKey = this.timezoneInfoByKey(this.timeZone)
      if (timeZoneByKey) {
        this.searchText = timeZoneByKey.label
      }
      this.$nextTick(() => {
        this.resetSearchInput()
      })
    } else {
      this.searchText = ''
      this.searchedKeys = []
    }
  }
  resetSearchInput() {
    this.$refs.searchInput.focus()
    if (this.$refs.searchInput.getElement()) {
      this.$refs.searchInput.getElement().select()
    }
  }
  handleSelectTimezone(tz: string) {
    this.$emit('onChangeTimeZone', tz)
  }
  clearText() {
    this.searchText = ''
    this.searchTimezone()
  }
  searchTimezone() {
    this.searchedKeys = TimeZoneModule.searchTimezoneByString(this.searchText)
  }
}
