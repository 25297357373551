
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { TeamInvitationResponse } from '@spirinc/contracts'
import MemberItem from './atoms/MemberItem.vue'

@Component({
  components: {
    MemberItem
  }
})
export default class TeamInvitedMemberItem extends Vue {
  @Prop() member: TeamInvitationResponse

  get email() {
    return this.member.email
  }
  get helperInfo(): { icon: string; message: string; type: 'has-text-danger' | 'has-text-info' } {
    switch (this.member.status) {
      case 'invitationExpired':
        return {
          icon: 'alert',
          message: this.$t('teamMember.memberStatus.invitationExpired').toString(),
          type: 'has-text-danger'
        }
      default:
        return {
          icon: 'send',
          message: this.$t('teamMember.memberStatus.invited').toString(),
          type: 'has-text-info'
        }
    }
  }

  cancelInvite() {
    this.$emit('cancelInvite')
  }
  resendInvite() {
    this.$emit('resendInvite')
  }
  copyInviteUrl() {
    this.$emit('copyInviteUrl')
  }
}
