export const authErrorTypes = {
  failInGoogleAuth: 'failInGoogleAuth',
  notEnoughScope: 'notEnoughScope',
  accountExistsInAnotherCredential: 'accountExistsInAnotherCredential',
  canceled: 'canceled',
  accountDuplicated: 'accountDuplicated',
  noUser: 'noUser',
  unknown: 'unknown'
} as const
export type AuthErrorTypes = typeof authErrorTypes[keyof typeof authErrorTypes]
export const DicAuthErrorAndi18nKey = (errorCode: AuthErrorTypes) => {
  return `message.error.auth.${errorCode}`
}
