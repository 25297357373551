








import ArrangementsOnCalendarPage from '@/components/page/ArrangementsOnCalendarPage/index.vue'
import { TitleSuggestionSetups } from '@/components/ui/domain/sections/arrangement/composables/types'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { GenTitleSuggestionSetup } from '@/lib/utils'
import { LastArrangementFormRequest } from '@/models/localStorage/LastArrangementFormRequest'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Arrangements',
  components: {
    ArrangementsOnCalendarPage
  },
  setup() {
    const lastArrangementFormRequest = new LastArrangementFormRequest()
    const initialFormRequest = lastArrangementFormRequest.loadFromLocalStorage()
    const suggestionSetups: TitleSuggestionSetups = GenTitleSuggestionSetup.genSuggestionSetupsForCreate()
    return {
      initialFormRequest,
      suggestionSetups,
      handleLastFormRequestSave: (newFormRequest: ArrangementFormRequest) =>
        lastArrangementFormRequest.saveToLocalStorage(newFormRequest)
    }
  }
})
