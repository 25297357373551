type DoNotShowNextTimeStorage = {
  canShow: boolean
  save: (doNotShow: boolean) => void
}
type Props = {
  isModalNeeded: boolean
  openModal: (data: { toggle: (doNotSow: boolean) => void; close: () => void }) => void
  storage: DoNotShowNextTimeStorage
}
export const useNextTimeCheckModal = ({ isModalNeeded, storage, openModal }: Props) => {
  const modalNeeded = storage.canShow
  return {
    openNextTimeCheckModalIfNeeded: async () => {
      if (isModalNeeded && modalNeeded) {
        await new Promise((resolve) => {
          openModal({
            toggle: (doNotSow: boolean) => {
              storage.save(doNotSow)
            },
            close: () => {
              resolve(true)
            }
          })
        })
      }
    }
  }
}
