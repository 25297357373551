import {
  personalId,
  PersonalTeamValue,
  TeamInfo
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import { ArrangementTypeSectionState } from '@/components/ui/domain/section/arrangement/types'
import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import { getPersonalTeamSelectorInfoFromFormRequest } from '@/components/ui/domain/sidePanel/arrangement/logics'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { useAnalytics } from '@/composables/useAnalytics'
import { useModal } from '@/composables/useModal'
import { usePersonalTeamSelectDropdownSetup } from '@/composables/usePersonalTeamSelectDropdownSetup'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import { noop } from '@/lib/utils'
import { ListType } from '@/types'
import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'

export type NewArrangementFormPanel = {
  currentArrangementType: Ref<ListType>
  formRequest: ComputedRef<ArrangementFormRequest>
  formData: ComputedRef<ArrangementTypeFormData>
  arrangementTypeSectionState: ComputedRef<ArrangementTypeSectionState>
  selectedPersonalTeamValue: Ref<PersonalTeamValue>
  personalInfo: ComputedRef<{
    id: string
    name: string
    photoURL: string
  }>
  teamInfos: ComputedRef<TeamInfo[]>
  handlePersonalTeamIdChange: (id: string) => void
  handleArrangementTypeChange: (val: ListType) => void
  handleClose: () => void
  handleSave: () => void
  handlePreviewOpen: () => void
}

export const useNewArrangementFormPanel = ({
  changeFormRequest,
  getFormDataFunctions,
  getMyProfile,
  getMyTeams
}): NewArrangementFormPanel => {
  const { openDangerTopToast } = useToast()
  const i18n = useTranslation()
  const analytics = useAnalytics()
  const { openArrangementTypeChangeConfirmModal } = useModal()
  const formDataFunctions = computed(() => getFormDataFunctions())
  const formRequest = computed(() => formDataFunctions.value.getFormRequest())
  const formData = computed(() => formDataFunctions.value.getAllData())

  const initial = getPersonalTeamSelectorInfoFromFormRequest(formRequest.value)
  const { selectedPersonalTeamValue, personalInfo, teamInfos, handlePersonalTeamChange } =
    usePersonalTeamSelectDropdownSetup({
      initialPersonalTeamValue: initial.personalTeamValue,
      getMyProfile,
      getMyTeams
    })
  const arrangementTypeSectionState = computed((): ArrangementTypeSectionState => {
    if (getMyTeams().length > 0) return 'allOk'
    else return 'personalTeamDropdownDisabled'
  })
  const initArrangementType = () => {
    switch (formRequest.value.type) {
      case 'personalSchedule':
        return ListType.SCHEDULE
      case 'personalPoll':
        return ListType.POLL
      default:
        return ListType.SCHEDULE
    }
  }
  const currentArrangementType = ref<ListType>(initArrangementType())
  function handleCurrentArrangementTypeChange(newType: ListType) {
    currentArrangementType.value = newType
  }
  function calcFormRequest(): ArrangementFormRequest {
    if (selectedPersonalTeamValue.value === 'personal') {
      return currentArrangementType.value === ListType.POLL ? { type: 'personalPoll' } : { type: 'personalSchedule' }
    } else {
      return { type: 'teamSchedule', id: selectedPersonalTeamValue.value }
    }
  }
  async function handleFormRequestChange() {
    const formRequest = calcFormRequest()
    try {
      await changeFormRequest(formRequest)
    } catch (e) {
      openDangerTopToast({ message: i18n.t('message.error.arrangementType.failedToFetchTeamRecord').toString() })
      handlePersonalTeamChange(personalId)
    }
  }

  async function changePersonalTeam(newVal: PersonalTeamValue) {
    if (newVal !== personalId && currentArrangementType.value === ListType.POLL) {
      handleCurrentArrangementTypeChange(ListType.SCHEDULE)
    }
    await handleFormRequestChange()
  }
  async function changeArrangementType(newArrangementType: ListType) {
    handleCurrentArrangementTypeChange(newArrangementType)
    if (newArrangementType === ListType.POLL && selectedPersonalTeamValue.value !== personalId) {
      handlePersonalTeamChange(personalId)
    }
    await handleFormRequestChange()
  }
  // 候補の自動抽出がOnの場合、候補を取得してセットする。
  return {
    currentArrangementType,
    formRequest,
    formData,
    arrangementTypeSectionState,
    selectedPersonalTeamValue,
    personalInfo,
    teamInfos,
    handlePersonalTeamIdChange: (id: string) => {
      const oldId = selectedPersonalTeamValue.value
      handlePersonalTeamChange(id)
      const overwriteBody =
        currentArrangementType.value === ListType.POLL && id !== personalId
          ? i18n.t('modals.arrangementTypeChangeConfirmation.changeToTeamSchedule').toString()
          : undefined
      openArrangementTypeChangeConfirmModal({
        body: overwriteBody,
        confirm: () => {
          changePersonalTeam(id)
        },
        cancel: () => {
          handlePersonalTeamChange(oldId)
        }
      })
    },
    handleArrangementTypeChange: (val: ListType) => {
      const overwriteBody =
        val === ListType.POLL && selectedPersonalTeamValue.value !== personalId
          ? i18n.t('modals.arrangementTypeChangeConfirmation.changeToPersonalPoll').toString()
          : undefined
      openArrangementTypeChangeConfirmModal({
        body: overwriteBody,
        confirm: () => {
          changeArrangementType(val)
        },
        cancel: noop
      })
    },
    handleClose: () => formData.value.pageDataControl.cancel(),
    handleSave: () => {
      formData.value.pageDataControl.confirm()
      analytics.send(SignalType.CLICK_ARRANGEMENT_SAVE, { arrangementType: currentArrangementType.value })
    },
    handlePreviewOpen: () => {
      formData.value.pageDataControl.preview()
      analytics.send(SignalType.CLICK_ARRANGEMENT_PREVIEW, { arrangementType: currentArrangementType.value })
    }
  }
}
