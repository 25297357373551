import { render, staticRenderFns } from "./_MemberItem.vue?vue&type=template&id=dd2fc7e8&scoped=true&"
import script from "./_MemberItem.vue?vue&type=script&lang=ts&"
export * from "./_MemberItem.vue?vue&type=script&lang=ts&"
import style0 from "./_MemberItem.vue?vue&type=style&index=0&id=dd2fc7e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd2fc7e8",
  null
  
)

export default component.exports