
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModelTeam } from '@/models/data/schedule'
import CardBodyFrame from './atoms/CardBodyFrame.vue'
import { Attendee, ScheduleStatus } from '@/types/schedule'
import LastUpdateInfoCardBodyItem from './atoms/LastUpdateInfoCardBodyItem.vue'
import OnWaitingCard from '@/components/card/molecules/OnWaitingCard.vue'
import OrganizerInfoCardBodyItem from './atoms/OrganizerInfoCardBodyItem.vue'
import TimeInfoCardBodyItem from './atoms/TimeInfoCardBodyItem.vue'
import AttendeesCardBodyItem from './molecules/AttendeesCardBodyItem.vue'
import { MemberResponse } from '@spirinc/contracts'

@Component({
  components: {
    OnWaitingCard,
    OrganizerInfoCardBodyItem,
    LastUpdateInfoCardBodyItem,
    TimeInfoCardBodyItem,
    AttendeesCardBodyItem,
    CardBodyFrame
  }
})
export default class ScheduleCardBodyForTeam extends Vue {
  @Prop() schedule: ScheduleModelTeam | null

  get lastUpdateUser() {
    return this.schedule?.lastUpdateUser
  }
  get organizer() {
    return this.schedule?.organizerInfo
  }
  get isExpiredItem() {
    return this.schedule.status === ScheduleStatus.expired
  }
  get duration() {
    return this.schedule.duration
  }

  get canDisplayDate() {
    return this.schedule.canDisplayDate
  }

  get timeRange() {
    return { start: this.schedule.start, end: this.schedule.end }
  }

  get attendees(): Attendee[] {
    if (!this.schedule?.allAttendingMembers) {
      return []
    }
    return this.schedule.allAttendingMembers.map(this.toAttendeeFromMemberResponse)
  }

  toAttendeeFromMemberResponse(memberResponse: MemberResponse): Attendee {
    return {
      id: memberResponse.id,
      fullName: memberResponse.fullName,
      photoURL: memberResponse.photoUrl
    }
  }

  get lastUpdateDateTime() {
    return this.schedule.updatedAt
  }
}
