










import FormCollapseButton from '@/components/newForm/atoms/CollapseButton.vue'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import LocationInput from '@/components/newForm/molecules/LocationInput.vue'
import OnlineMeeting from '@/components/newForm/molecules/OnlineMeeting.vue'
import Visibility from '@/components/newForm/molecules/Visibility.vue'
import FormDescriptionTextareaItem from '@/components/ui/domain/item/FormDescriptionTextareaItem.vue'
import { AvailabilityModelForPrivate } from '@/models/data'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FromGroup,
    OnlineMeeting,
    FormCollapseButton,
    LocationInput,
    Visibility,
    FormDescriptionTextareaItem
  }
})
export default class PatternSidePanelBasic extends Vue {
  @PropSync('editingObject', { type: Object }) editingObjectSynced!: AvailabilityModelForPrivate

  get calendarsByAccountId() {
    return this.editingObjectSynced.usableCalendarsByAccount
  }
  get availableOnlineMeetings() {
    const currentCalendar = this.calendarsByAccountId.find(
      (cal) => cal.id === this.editingObjectSynced.organizer.calendarId
    )
    return currentCalendar.availableOnlineMeetings || []
  }

  updateObject() {
    this.$emit('updateObject')
  }
  handleDescriptionChange(newDesc: string) {
    this.editingObjectSynced.description = newDesc
    this.updateObject()
  }
}
