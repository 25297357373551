import TeamInvitedModule from '@/store/modules/teamInvited'

export default async (to, _, next) => {
  const teamId = to.params.id as string
  const invitationId = to.params.invitationId as string
  const token = to.query['token'] as string
  try {
    await Promise.all([
      TeamInvitedModule.checkValidInvitationCode({ teamId, invitationId, token }),
      TeamInvitedModule.fetchTeam(teamId)
    ])
    next()
  } catch (e) {
    next({
      name: 'TeamMemberTokenExpired'
    })
  }
}
