















import FormCollapseBorderedCard from '@/components/ui/form/FormCollapseBorderedCard.vue'
import {
  DateSlot,
  dateSlotPeriodTitle,
  DatetimeSlotDeletePayload,
  DatetimeSlotSavePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import FormDatetimeSlotsBox from '@/components/ui/form/FormDatetimeSlotsBox/FormDatetimeSlotsBox.vue'
import { useTranslation } from '@/composables/useTranslation'
import { ListType } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ArrangementPeekCandidateList',
  components: {
    FormDatetimeSlotsBox,
    FormCollapseBorderedCard
  },
  props: {
    dateSlots: {
      type: Array as PropType<DateSlot[]>,
      default: () => []
    },
    durationToSplit: {
      type: Number,
      required: true
    },
    arrangementType: {
      type: String as PropType<ListType>,
      required: true
    },
    isSlotBoxOpen: {
      type: Boolean,
      default: false
    },
    onAllDelete: {
      type: Function as PropType<() => void>,
      required: true
    },
    onSave: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>,
      required: true
    },
    onDatetimeDelete: {
      type: Function as PropType<(payload: DatetimeSlotDeletePayload) => void>,
      required: true
    },
    onDatetimeUpdate: {
      type: Function as PropType<(payload: DatetimeSlotUpdatePayload) => void>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const title = computed(() => {
      return dateSlotPeriodTitle(props.dateSlots, i18n.t('forms.sections.peekCandidates.list.empty').toString())
    })
    const isDurationFixed = computed(() => props.arrangementType === ListType.POLL)
    return {
      title,
      isDurationFixed
    }
  }
})
