











import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdownButton',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium'
    },
    expanded: {
      type: Boolean,
      default: true
    }
  }
})
