






























import { Component, Watch, PropSync, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import { debounce } from 'lodash'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    WithValidationInput,
    FormItem,
    ValidationObserver
  }
})
/**
 * 最後がValidなEmailなら最後のItemまで、Validじゃなかったらその前のItemまでが保存対象。
 */
export default class TargetNotificationEmailsInput extends Vue {
  @PropSync('notifications', { type: Array }) notificationsSynced!: { email: string }[]
  @Watch('inputEmail')
  inputUpdated() {
    this.inputDebounce()
  }

  inputEmail = ''
  editingAddedRecord = false // Emailを入力してValidならNotificationに追加。追加されたRecordをV-forで表示しなくするためFlagを入れる
  inputDebounce = null
  created() {
    this.inputDebounce = debounce(this.handleInput, 100)
  }
  get showEmails() {
    if (this.addedEmails.length === 0) {
      return []
    }
    if (!this.editingAddedRecord) {
      return this.addedEmails
    }
    return this.addedEmails.slice(0, this.addedEmails.length - 1)
  }
  get addedEmails() {
    return this.notificationsSynced.map((n) => n.email)
  }
  reset() {
    this.inputEmail = ''
    this.editingAddedRecord = false
  }
  async handleInput() {
    if (!this.editingAddedRecord && !this.inputEmail) {
      return
    }
    const observer = this.$refs.observer as any
    const valid = !!(await observer.validate())
    // notificationに追加済みの場合
    if (this.editingAddedRecord) {
      if (valid) {
        this.notificationsSynced[this.notificationsSynced.length - 1].email = this.inputEmail
      } else {
        this.editingAddedRecord = false
        this.notificationsSynced.pop()
      }
    } else {
      if (!valid) {
        return
      }
      this.notificationsSynced.push({
        email: this.inputEmail
      })
      this.editingAddedRecord = true
    }
    this.$emit('input')
  }
  addEmail() {
    this.reset()
    this.$emit('input')
  }

  deleteEmail(index: number) {
    this.notificationsSynced.splice(index, 1)
    this.$emit('input')
  }
}
