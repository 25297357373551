import {
  DatetimeSlot,
  toNoConfirmationDatetimeSlot,
  VotingDateSlot,
  VotingDatetimeSlot
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { useModal } from '@/composables/useModal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import VoteModule, { CandidateForVote } from '@/store/modules/vote'
import { FullCalendarEvent } from '@/types'
import { computed, reactive } from '@vue/composition-api'
import { chain } from 'lodash'

type UseVotingPeekCandidatesSectionSetup = {
  getFullCalendarEvents: () => FullCalendarEvent[]
  deleteCandidate: (candidateId: string) => void
  generateGotoConfirmation: (event: { id: string }) => () => void
}
export type UseVotingPeekCandidatesSection = {
  candidates: FullCalendarEvent[]
  dateSlots: VotingDateSlot[]
  hasAtLeastOneCandidate: boolean
  addNewCandidate: () => void
  removeCandidate: (candidateId: string) => void
}
export const useVotingPeekCandidatesSectionSetupInitializer = () => {
  const { openDangerBottomToast } = useToast()
  const { openConfirmModalInPoll } = useModal()
  const i18n = useTranslation()
  return {
    initialize: ({
      getFullCalendarEvents,
      generateGotoConfirmation,
      deleteCandidate
    }: UseVotingPeekCandidatesSectionSetup): UseVotingPeekCandidatesSection => {
      const candidates = computed<FullCalendarEvent[]>(() => getFullCalendarEvents())
      const hasAtLeastOneCandidate = computed(() => candidates.value.length > 0)
      function addNewCandidate() {
        openDangerBottomToast({
          message: i18n.t('message.cannotAddCandidateInPollEdit').toString()
        })
      }
      function removeCandidate(candidateId: string) {
        openConfirmModalInPoll({
          title: i18n.t('confirms.deleteCandidate.header').toString(),
          body: i18n.t('confirms.deleteCandidate.body').toString(),
          confirm: () => {
            deleteCandidate(candidateId)
            VoteModule.initVotes() // 削除後のリスト更新を行うため
          }
        })
      }
      const candidatesForVote = computed<CandidateForVote[]>(() => VoteModule.getCurrentVotes)

      function getDateSlots(cs: CandidateForVote[]): VotingDateSlot[] {
        const maxCount = VoteModule.getMaxYesCount
        const dateSlots = chain(cs)
          .sortBy('start')
          .groupBy((event) => spirDateFormat(event.start, spirDateFormatTypes.yyyymmddweekday))
          .map((candidatesForVote: CandidateForVote[], key) => {
            const datetimeSlots: VotingDatetimeSlot[] = candidatesForVote.map((candidate: CandidateForVote) => {
              const event = { id: candidate.id, start: new Date(candidate.start), end: new Date(candidate.end) }
              const base: Omit<DatetimeSlot, 'isValidCandidate'> = toNoConfirmationDatetimeSlot(event)
              const slot: VotingDatetimeSlot = {
                ...base,
                isValidCandidate: true,
                confirmation: { gotoConfirmation: generateGotoConfirmation(event) },
                poll: { candidateForVote: candidate, isMaxCount: maxCount === candidate.yesCount && maxCount > 0 }
              }
              return slot
            })
            return { id: key, title: key, datetimeSlots }
          })
          .value()
        return dateSlots
      }
      const dateSlots = computed<VotingDateSlot[]>(() => getDateSlots(candidatesForVote.value))

      return reactive({
        candidates,
        dateSlots,
        hasAtLeastOneCandidate,
        addNewCandidate,
        removeCandidate
      })
    }
  }
}
