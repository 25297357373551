






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ListItemSpacer',
  props: {
    top: {
      type: [Boolean, String] as PropType<boolean | '16' | '8' | '4'>,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let topPx = 'pt16'
    let isTopSpacerRequired = false
    if (typeof props.top === 'string') {
      if (props.top === '16' || props.top === '8' || props.top === '4') {
        isTopSpacerRequired = true
        topPx = `pt${props.top}`
      }
    } else {
      isTopSpacerRequired = props.top
    }

    return {
      topPx,
      isTopSpacerRequired
    }
  }
})
