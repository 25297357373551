




























import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import { defineComponent, ref, PropType } from '@vue/composition-api'
import SpirButton from '@/components/ui/Button.vue'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  components: {
    ValidationObserver,
    CommonLayoutModal,
    SpirButton,
    WithValidationInput
  },
  props: {
    onConfirm: {
      type: Function as PropType<(message: string) => void>
    }
  },
  setup(props) {
    const message = ref('')
    const handleConfirm = () => {
      props.onConfirm(message.value)
    }
    return {
      message,
      handleConfirm
    }
  }
})
