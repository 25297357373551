




















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
import { WeekDay } from '@/models/data/userInfo'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

@Component({
  components: {
    Calendar,
    VDatePicker
  }
})
export default class DateRangePicker extends Vue {
  @Prop() minDate: Date
  @Prop() maxDate: Date
  @Prop() firstDayOfWeek: WeekDay

  @PropSync('range', { type: Object }) rangeSynced!: {
    start: Date
    end: Date
  }
  get startDate() {
    return spirDateFormat(this.rangeSynced.start, spirDateFormatTypes.yyyymmddweekday)
  }
  get endDate() {
    return spirDateFormat(this.rangeSynced.end, spirDateFormatTypes.yyyymmddweekday)
  }
  handleClick(event) {
    this.rangeSynced.start = event.start
    this.rangeSynced.end = event.end
    this.$emit('change')
  }
}
