




import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DateSlotListItemTitle',
  components: {
    FormItemSpacer
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
})
