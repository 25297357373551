



















import { defineComponent, PropType } from '@vue/composition-api'
import SpirDropdown from './SpirDropdown.vue'
import SpirDropdownButton from './SpirDropdownButton.vue'

export default defineComponent({
  name: 'SpirButtonDropdown',
  components: {
    SpirDropdown,
    SpirDropdownButton
  },
  props: {
    position: {
      type: String as PropType<'bottom' | 'top' | 'bottom-left'>,
      default: 'bottom'
    },
    value: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: true
    },
    mobileModal: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium'
    }
  },
  setup(props, { emit }) {
    function handleChange(newValue: string) {
      emit('change', newValue)
    }

    return {
      handleChange
    }
  }
})
