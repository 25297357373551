











import { Vue, Component } from 'vue-property-decorator'
import Vue100vh from 'vue-div-100vh'
import SettingsNavigation from '@/components/settings/SettingsNavigation.vue'
import IntegrationsSection from '@/components/settings/IntegrationsSection.vue'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'

@Component({
  components: {
    SettingsNavigation,
    Vue100vh,
    IntegrationsSection
  }
})
export default class SettingLayout extends Vue {
  handleOnBack() {
    this.$router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
  }
}
