





















import { computed, defineComponent, PropType } from '@vue/composition-api'
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import CalendarCollectionAvatarWithName from './_CalendarCollectionAvatarWithName.vue'
import CalendarAvatarWithName from './_CalendarAvatarWithName.vue'
import { CalendarCollection } from './types'
import { decodeCalendarKey, encodeCalendarKeyByAccountIdAndCalendarId } from '@/store/modules/calendars'

export default defineComponent({
  name: 'CalendarDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem,
    CalendarCollectionAvatarWithName,
    CalendarAvatarWithName
  },
  props: {
    calendarCollections: {
      type: Array as PropType<CalendarCollection[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object as PropType<{ accountId: string; calendarId: string }>,
      required: true
    }
  },
  setup(props, { emit }) {
    const selectedCalendar = computed(() =>
      props.calendarCollections
        .find((cc) => cc.accountId === props.value.accountId)
        .calendars.find((c) => c.id === props.value.calendarId)
    )

    function encodeKey(accountId: string, calendarId: string) {
      return encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId)
    }

    function handleChange(newValue: string) {
      const { accountId, calendarId } = decodeCalendarKey(newValue)
      emit('change', { accountId, calendarId })
    }

    return {
      selectedCalendar,
      encodeKey,
      handleChange
    }
  }
})
