






/**
 * まだ動作しない。CompositionAPIで使えるようにしたかったが、うまくInitできなかった。後回しして既存のコンポーネントを使う
 */
import { onMounted, onBeforeUnmount, ref, defineComponent, toRefs } from '@vue/composition-api'
import { Editor, EditorContent } from 'tiptap'
import {
  Placeholder,
  HardBreak,
  Heading,
  Bold,
  Code,
  Italic,
  Link,
  ListItem,
  TodoItem,
  TodoList,
  BulletList,
  OrderedList
} from 'tiptap-extensions'
import { text2link } from '@/lib/utils'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import FormItem from '@/components/newForm/atoms/FormItem.vue' // event formは旧デザインのため、旧FormItemを使う

const MAX_LENGTH = 60000
export default defineComponent({
  name: 'FormTextareaWithHtml',
  components: {
    FormItem,
    EditorContent
  },
  props: {
    value: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { openDangerBottomToast } = useToast()
    const i18n = useTranslation()
    const { value } = toRefs(props)
    const editor = ref(null)
    const handleUpdate = () => {
      const content = editor.value.getHTML()
      if (content.length > MAX_LENGTH) {
        openDangerBottomToast({
          message: i18n.t('message.error.exceedMacLength').toString()
        })
        editor.value.setContent(value.value)

        return
      }
      emit('input', content)
    }
    onMounted(() => {
      editor.value = new Editor({
        content: text2link(value.value),
        onUpdate: handleUpdate,
        extensions: [
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: i18n.t('forms.common.description.placeholder').toString(),
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          }),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new Link(),
          new Bold(),
          new Code(),
          new BulletList(),
          new Italic(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList()
        ],
        parseOptions: {
          preserveWhitespace: 'false'
        }
      })
    })
    onBeforeUnmount(() => {
      if (editor.value) {
        editor.value.destroy()
        editor.value = null
      }
    })
    return {
      editor
    }
  }
})
