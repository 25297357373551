












import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import MemberSelector from '@/components/newForm/atoms/MemberSelector.vue'
import { MemberResponse as MemberInfo } from '@spirinc/contracts'
import MemberList from '@/components/newForm/atoms/MemberList.vue'

@Component({
  components: {
    FormItem,
    MemberSelector,
    MemberList
  }
})
export default class PatternsMemberSelector extends Vue {
  @Prop() selectedMembers: Array<string>
  @Prop() allMembers: MemberInfo[]
  @Prop() type: 'or' | 'and'
  @Prop({ default: false }) disabled: boolean

  get label() {
    return this.type === 'and'
      ? this.$t('availabilitySharing.form.extractCalendar.attendeeLabel')
      : this.$t('availabilitySharing.form.extractCalendar.attendeeLabelForOr')
  }
  get notAddedMembers() {
    return this.allMembers.filter((m) => !this.selectedMembers.some((s) => s === m.id))
  }
  addMember(addedMember) {
    this.$emit('addMember', addedMember)
  }
  deleteMember(deletedMemberId) {
    this.$emit('deleteMember', deletedMemberId)
  }
}
