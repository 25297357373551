
















import UnconfirmedPollCard from '@/components/adjustmentList/UnconfirmedPollCard.vue'
import UnconfirmedScheduleCard from '@/components/adjustmentList/UnconfirmedScheduleCard.vue'
import UnconfirmedTeamScheduleCard from '@/components/adjustmentList/UnconfirmedTeamScheduleCard.vue'
import Button from '@/components/ui/Button.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import { UnconfirmedCardType, UnconfirmedTeamScheduleCardType } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    ListItemSpacer,
    Button,
    UnconfirmedScheduleCard,
    UnconfirmedPollCard,
    UnconfirmedTeamScheduleCard
  },
  props: {
    cards: {
      type: Array as PropType<UnconfirmedCardType[] | UnconfirmedTeamScheduleCardType[]>
    }
  },
  setup(props) {
    const sortedCards = computed(() => {
      return props.cards.sort(
        (
          a: UnconfirmedCardType | UnconfirmedTeamScheduleCardType,
          b: UnconfirmedCardType | UnconfirmedTeamScheduleCardType
        ) => {
          return a.firstCandidateStartDate?.getTime() - b.firstCandidateStartDate?.getTime()
        }
      )
    })

    return {
      sortedCards
    }
  }
})
