























































import ListPanelLayout from '@/components/layout/ListPanel.vue'
import TeamSelectorTabWithQueryParamsSync from '@/components/molecules/TeamSelectorTab/TeamSelectorTabWithQueryParamsSync.vue'
import AvailabilitySharingList from '@/components/page/AvailabilitySharingListPage/_AvailabilitySharingList.vue'
import HelpCard from '@/components/page/AvailabilitySharingListPage/_HelpCard.vue'
import Button from '@/components/ui/Button.vue'
import PageheadCloseButton from '@/components/ui/page/pagehead/PageheadCloseButton.vue'
import { useLocalStorage } from '@/composables/useLocalStorage'
import { isMobile } from '@/lib/utils'
import { AvailabilityModelForPrivate, AvailabilityModelForTeam } from '@/models/data'
import { AllRouteNames } from '@/router'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'
import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'
import queryParams from '@/lib/queryParams'

const LOCAL_STORAGE_KEY = 'spir-availability-sharing-list-help-need'
const AVAILABILITY_SHARING_USAGE_HELP_URL = 'https://jp.spirinc.com/accbeb16cee04ccf821b940be4273b4d'

export default defineComponent({
  components: {
    ListPanelLayout,
    AvailabilitySharingList,
    TeamSelectorTabWithQueryParamsSync,
    Button,
    HelpCard,
    PageheadCloseButton
  },
  props: {
    privateAvailabilitySharingList: {
      type: Object as PropType<{ isLoading: boolean; list: AvailabilityModelForPrivate[] }>,
      required: true
    },
    teamAvailabilitySharingLists: {
      type: Object as PropType<{ [key: string]: { isLoading: boolean; list: AvailabilityModelForTeam[] } }>,
      required: true
    }
  },
  setup(props) {
    const localStorage = useLocalStorage()
    const router = useRouter()

    const { privateAvailabilitySharingList, teamAvailabilitySharingLists } = toRefs(props)
    const isTabLoading = computed(() => TeamModule.isLoading)
    const isHelpNeed = ref<boolean>(localStorage.get(LOCAL_STORAGE_KEY, true))

    const myProfile = computed(() => ProfileModule.myProfile)
    const userLanguage = computed(() => ProfileModule.getLanguage)
    const teams = computed(() => TeamModule.myTeams)
    const hasTeam = computed(() => teams.value?.length > 0)

    function isLoadingPrivatePatterns() {
      return (
        isTabLoading.value ||
        (privateAvailabilitySharingList.value?.isLoading && privateAvailabilitySharingList.value?.list.length == 0)
      )
    }

    function isLoadingTeamPatterns(teamId: string) {
      return (
        isTabLoading.value ||
        (teamAvailabilitySharingLists.value[teamId]?.isLoading &&
          teamAvailabilitySharingLists.value[teamId]?.list.length == 0)
      )
    }

    function handleClose() {
      router.push({ name: AllRouteNames.Main })
    }

    function handleCreateButton(value: string) {
      if (!value || value === 'private') {
        router.push({ name: AllRouteNames.AvailabilitySharingCreate })
      } else {
        router.push({ name: AllRouteNames.AvailabilitySharingCreate, query: { [queryParams.TEAM_ID]: value } })
      }
    }

    function handleHelpClose() {
      isHelpNeed.value = false
      localStorage.set(LOCAL_STORAGE_KEY, isHelpNeed.value)
    }

    return {
      isMobile: isMobile(),
      helpURL: AVAILABILITY_SHARING_USAGE_HELP_URL,
      isTabLoading,
      isShowHelpPanel: computed(() => isHelpNeed && userLanguage.value === 'ja'),
      myProfile,
      teams,
      hasTeam,
      userLanguage,
      isLoadingPrivatePatterns,
      isLoadingTeamPatterns,
      handleClose,
      handleCreateButton,
      handleHelpClose
    }
  }
})
