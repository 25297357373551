const RemoteConfigKeys = {
  subscriptionEnabled: 'subscriptionEnabled',
  accountLinkEnabled: 'accountLinkEnabled',
  notificationEnabled: 'notificationEnabled',
  emailChangeEnabled: 'emailChangeEnabled',
  teamEnabled: 'teamEnabled',
  teamPhotoUpdateEnabled: 'teamPhotoUpdateEnabled',
  abTestingShowNewConfirmPageEnabled: 'abTestingShowNewConfirmPageEnabled'
}
type RemoteConfigKeys = typeof RemoteConfigKeys[keyof typeof RemoteConfigKeys]

export default RemoteConfigKeys
