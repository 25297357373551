
















import UnconfirmedListTabs from '@/components/adjustmentList/UnconfirmedListTabs.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import { useAdjustmentTabsSetup } from '@/composables/useAdjustmentTabsSetup'
import { useUnconfirmedConverters } from '@/composables/useUnconfirmedConverter'
import i18n from '@/i18n'
import ListPanelModule from '@/store/modules/listPanel'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import { UnconfirmedPrivateTabItemState, UnconfirmedTabsState, UnconfirmedTeamTabItemState } from '@/types'
import { computed, defineComponent, onMounted, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  components: {
    ClosingPageheadTitleBox,
    UnconfirmedListTabs
  },
  props: {
    personalTeamValue: {
      type: String as PropType<string>,
      default: 'private'
    }
  },
  setup(props) {
    const { personalTeamValue } = toRefs(props)
    const pageTitle = i18n.t('globalNavigation.unconfirmedList')
    const { getPrivateCardTypes, getTeamCardTypes, getTeamLoading } = useUnconfirmedConverters()
    const getPrivateList = () => ListPanelModule.listForListPanel
    const getTeamList = (teamId: string) => TeamScheduleListModule.scheduleListByTeamId(teamId)
    const {
      handleMounted,
      currentTab,
      getPrivateTabInfo,
      getTeamTabInfos,
      handleClose,
      handleTabChange,
      getTabsClass
    } = useAdjustmentTabsSetup<'unconfirmed'>({
      personalTeamValue,
      getPrivateList
    })

    const privateTabInfo = computed((): UnconfirmedPrivateTabItemState => getPrivateTabInfo(getPrivateCardTypes))
    const teamTabInfos = computed((): UnconfirmedTeamTabItemState[] =>
      getTeamTabInfos(getTeamList, getTeamCardTypes, getTeamLoading)
    )
    const tabs = computed((): UnconfirmedTabsState => {
      return { items: [privateTabInfo.value, ...teamTabInfos.value] }
    })

    const tabsClass = computed(() => getTabsClass(tabs.value))
    onMounted(handleMounted)

    return {
      pageTitle,
      currentTab,
      tabs,
      handleClose,
      handleTabChange,
      tabsClass
    }
  }
})
