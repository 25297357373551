






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'s' | 'm'>,
      default: 'm'
    },
    title: {
      type: String,
      required: true
    }
  }
})
