





































import { cloneDeep } from 'lodash'
import { computed, defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import FormOnlineMeetingDropdownItem from '@/components/ui/domain/item/FormOnlineMeetingDropdownItem/index.vue'
import FormOnlineMeetingPriorityDropdownItem from '@/components/ui/domain/item/FormOnlineMeetingPriorityDropdownItem/index.vue'
import FormLocationInputForm from '@/components/ui/domain/item/FormLocationInputForm/index.vue'
import FormMeetingRoomSelectItem from '@/components/ui/domain/item/FormMeetingRoomSelectItem.vue'
import FormDescriptionTextareaItem from '@/components/ui/domain/item/FormDescriptionTextareaItem.vue'
import FormVisibilitySelectBoxItem from '@/components/ui/domain/item/FormVisibilitySelectBoxItem.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useTranslation } from '@/composables/useTranslation'
import { OnlineMeetingType } from '@/types'
import { OnlineMeetingTool } from '../../item/FormOnlineMeetingDropdownItem/onlineMeeting'
import UserModule from '@/store/modules/user'

export default defineComponent({
  name: 'OtherInfoSection',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    FormOnlineMeetingDropdownItem,
    FormOnlineMeetingPriorityDropdownItem,
    FormLocationInputForm,
    FormMeetingRoomSelectItem,
    FormDescriptionTextareaItem,
    FormVisibilitySelectBoxItem
  },
  setup() {
    const i18n = useTranslation()
    const {
      editingObjectType,
      editingObject,
      editingObjectForPrivate,
      editingObjectForTeam,
      updateEditingObject,
      updateCondition
    } = useAvailabilitySharingForm()

    const isPersonalType = computed(() => editingObjectType.value === 'personal')
    const isTeamType = computed(() => editingObjectType.value === 'team')

    const onlineMeetingToolsForPersonal = computed(() => {
      const currentCalendar = editingObjectForPrivate.value.usableCalendarsByAccount.find(
        (cal) => cal.id === editingObjectForPrivate.value.organizer.calendarId
      )
      const availableOnlineMeetings = currentCalendar.availableOnlineMeetings || []
      return availableOnlineMeetings.reduce(
        (a: OnlineMeetingTool[], c) => {
          a.push({ serviceType: c })
          return a
        },
        [
          { serviceType: OnlineMeetingType.none },
          {
            serviceType: OnlineMeetingType.zoom,
            isConnected: UserModule.isConnectedZoom,
            accountName: UserModule.integrations.zoomInfo?.email
          }
        ]
      )
    })
    const onlineMeetingForPersonal = computed(() => editingObjectForPrivate.value.onlineMeeting.type)
    function handleOnlineMeetingChangeForPersonal(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.onlineMeeting.type = newValue
      updateEditingObject(clone)
    }

    const onlineMeetingsForTeam = computed(() => editingObjectForTeam.value.onlineMeeting)
    function handleOnlineMeetingsChangeForTeam(newValue) {
      const clone = cloneDeep(editingObjectForTeam.value)
      clone.onlineMeeting = newValue
      updateEditingObject(clone)
    }

    const location = computed(() => editingObject.value.location ?? '')
    function handlelocationInput(newValue) {
      if (newValue) {
        const clone = cloneDeep(editingObject.value)
        clone.location = newValue
        updateEditingObject(clone)
      }
    }

    const selectedResourceCalendarTypes = computed(() =>
      editingObjectForPrivate.value.meetingRooms.map((m) => ({
        type: 'calendar',
        accountId: m.accountId,
        calendarId: m.calendarId
      }))
    )
    const resourceCalendarList = computed(() =>
      editingObjectForPrivate.value.usableCalendarsByAccount.filter((c) => c.type === 'resource')
    )
    function handleResourceCalendarAdd(accountId, calendarId) {
      const clone = cloneDeep(editingObjectForPrivate.value)
      clone.meetingRooms.push({ accountId, calendarId })
      updateCondition(clone)
    }
    function handleResourceCalendarDelete(accountId, calendarId) {
      const index = editingObjectForPrivate.value.meetingRooms.findIndex(
        (m) => m.accountId === accountId && m.calendarId === calendarId
      )
      if (index >= 0) {
        const clone = cloneDeep(editingObjectForPrivate.value)
        clone.meetingRooms.splice(index, 1)
        updateCondition(clone)
      }
    }

    const description = computed(() => editingObject.value.description)
    function handleDescriptionChange(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.description = newValue
      updateEditingObject(clone)
    }

    const visibilityItems = ['default', 'private', 'public'].map((v) => ({
      key: v,
      label: i18n.t(`forms.event.visibility.${v}`).toString()
    }))
    const visibility = computed(() => editingObject.value.visibility)
    function handleVisibilityInput(newValue) {
      const clone = cloneDeep(editingObject.value)
      clone.visibility = newValue
      updateEditingObject(clone)
    }

    return {
      isPersonalType,
      isTeamType,
      onlineMeetingToolsForPersonal,
      onlineMeetingForPersonal,
      handleOnlineMeetingChangeForPersonal,
      onlineMeetingsForTeam,
      handleOnlineMeetingsChangeForTeam,
      location,
      handlelocationInput,
      selectedResourceCalendarTypes,
      resourceCalendarList,
      handleResourceCalendarAdd,
      handleResourceCalendarDelete,
      description,
      handleDescriptionChange,
      visibilityItems,
      visibility,
      handleVisibilityInput
    }
  }
})
