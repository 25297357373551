import { render, staticRenderFns } from "./ConfirmSkeltonCandidateBlockDate.vue?vue&type=template&id=13ce5a3c&scoped=true&"
import script from "./ConfirmSkeltonCandidateBlockDate.vue?vue&type=script&lang=ts&"
export * from "./ConfirmSkeltonCandidateBlockDate.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmSkeltonCandidateBlockDate.vue?vue&type=style&index=0&id=13ce5a3c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ce5a3c",
  null
  
)

export default component.exports