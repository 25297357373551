














import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CalendarItemLayout extends Vue {
  get hasRightSlot() {
    return !!this.$slots.right
  }
}
