import { FloatingMenuHandlers } from '@/components/layout/types'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'
import AvailabilityListModule from '@/store/modules/availabilityPatternList'
import TempEventModule from '@/store/modules/tempEvent'
import { CreateEvent, FullCalendarEvent } from '@/types'
import { computed, ComputedRef, nextTick, Ref, ref } from '@vue/composition-api'

type FloatingMenuActions = {
  floatingMenuRef: Ref<FloatingMenuHandlers | null>
  additionalEvents: ComputedRef<FullCalendarEvent[]>
  addCandidate: ({ start, end, allDay, jsEvent, accountId, calendarId }: CreateEvent) => Promise<void>
  resetTempEvent: () => void
}
export const useFloatingMenuActions = (): FloatingMenuActions => {
  const { sendSignal } = useSendSignal()
  const floatingMenuRef = ref<FloatingMenuHandlers | null>(null)
  const additionalEvents = computed(() => {
    return TempEventModule.getTempEvent ? [TempEventModule.getTempEvent] : AvailabilityListModule.fetchedCandidates
  })
  function resetTempEvent() {
    TempEventModule.RESET()
  }
  async function addCandidate({ start, end, allDay, jsEvent, accountId, calendarId }: CreateEvent) {
    await sendSignal(SignalType.DRAGCALENDAR)
    if (!floatingMenuRef.value) return
    nextTick(() => {
      TempEventModule.setTempEvent({ start, end, allDay, accountId, calendarId })
      floatingMenuRef.value.showMenu(jsEvent)
    })
    const appElement = document.getElementById('app')
    appElement.click()
  }
  return {
    floatingMenuRef,
    additionalEvents,
    addCandidate,
    resetTempEvent
  }
}
