











import { defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'

export default defineComponent({
  components: {
    ListItemSpacer
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
})
