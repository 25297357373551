









import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import queryString from 'query-string'
import { OnlineMeetingTool } from '../onlineMeeting'
import Button from '@/components/ui/Button.vue'
import { useModal } from '@/composables/useModal'
import UserModule from '@/store/modules/user'
import { LOCAL_STORAGE_KEY_FOR_CALLBACK_ZOOM } from '@/types'
import { useRoute } from 'vue2-helpers/vue-router'

export default defineComponent({
  name: 'ZoomDropdownItem',
  components: {
    Button
  },
  props: {
    tool: {
      type: Object as PropType<OnlineMeetingTool>, // serviceType is only 'zoom'
      required: true
    },
    onZoomDisconnect: {
      type: Function as PropType<() => void>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { tool, onZoomDisconnect } = toRefs(props)
    const { openOnlineMeetingToolInfoModalModal, openSignInToZoomConfirmationModal } = useModal()
    const route = useRoute()

    function handleSettingClick(event) {
      event.stopPropagation()
      openOnlineMeetingToolInfoModalModal({
        props: {
          accountName: tool.value.accountName
        },
        onClickDisconnectionButton: async () => {
          await UserModule.disconnectZoom()
          onZoomDisconnect.value()
        }
      })
    }

    function handleConnectClick() {
      openSignInToZoomConfirmationModal({
        confirm() {
          window.localStorage.setItem(LOCAL_STORAGE_KEY_FOR_CALLBACK_ZOOM, route.name)
          const params = {
            /* eslint-disable @typescript-eslint/camelcase */
            client_id: process.env.VUE_APP_ZOOM_CLIENT_ID,
            redirect_uri: `${window.location.origin}/callback-from-zoom`,
            response_type: 'code'
          }
          const url = `https://zoom.us/oauth/authorize?${queryString.stringify(params)}`
          window.location.replace(url)
        }
      })
    }

    return {
      handleSettingClick,
      handleConnectClick
    }
  }
})
