




















import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarColumn from './CalendarColumn.vue'
import { ResourceColumn, ResourceEvent } from '@/types'
import { startOfDay, addDays } from '@/lib/utils/timezone'
import { EventBus, EVENTS } from '@/lib/eventBus'
import Header from '../../header/Index.vue'

@Component({
  components: {
    CalendarColumn,
    Header
  }
})
export default class DailyViewColumns extends Vue {
  @Prop() columns: ResourceColumn[]
  @Prop() gridHeight: string
  @Prop() events: ResourceEvent[]
  @Prop() fullWidthEvents: ResourceEvent[]
  @Prop() eventDate: Date
  @Prop() selectable: boolean

  mounted() {
    const calendarBody = this.$refs.calendarBody as HTMLElement
    if (calendarBody) {
      calendarBody.addEventListener('scroll', this.syncScroll)
    }
    EventBus.on(EVENTS.RESOURCE_TO_SCROLL, this.scrollToPrevHeight)
  }
  beforeDestroy() {
    const calendarBody = this.$refs.calendarBody as HTMLElement
    if (calendarBody) {
      calendarBody.removeEventListener('scroll', this.syncScroll)
    }
    EventBus.off(EVENTS.RESOURCE_TO_SCROLL, this.scrollToPrevHeight)
  }

  get allEvents() {
    return this.events.filter((event) => !event.allDay)
  }
  columnDate(column: ResourceColumn) {
    if (!column.date) {
      return this.eventDate
    }
    return startOfDay(column.date)
  }
  syncScroll(e) {
    EventBus.emit(EVENTS.RESOURCE_VIEW_SCROLL, e.srcElement)
  }
  eventsByCalendar(columnKey) {
    return this.allEvents.filter((event) => event.columnKey === columnKey)
  }
  fullWidthEventsByCalendar(columnKey) {
    return this.fullWidthEvents.filter((event) => event.columnKey === columnKey)
  }
  scrollToPrevHeight(height) {
    const calendarBody = this.$refs.calendarBody as HTMLElement
    if (calendarBody) {
      calendarBody.scrollTo(0, height)
    }
  }

  createEvent({ columnKey, allDay, start, end, jsEvent }) {
    const payload: any = {
      columnKey,
      start,
      end,
      allDay,
      jsEvent
    }
    if (allDay) {
      payload.allDay = true
      payload.start = startOfDay(this.eventDate)
      payload.end = startOfDay(addDays(this.eventDate, 1))
    }
    this.$emit('createEvent', payload)
  }
}
