
















import { CardItemTip, FrontSupportLanguage } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CardBodyItem',
  props: {
    icon: {
      type: String
    },
    multiLine: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String as PropType<FrontSupportLanguage>
    },
    tip: {
      type: Object as PropType<CardItemTip>,
      default: () => ({ type: 'none' })
    }
  },
  setup(props) {
    const isTipVisible = computed((): boolean => {
      return props.tip.type === 'visible'
    })
    const tipText = computed((): string => (props.tip.type === 'visible' ? props.tip.text : ''))
    return {
      isTipVisible,
      tipText
    }
  }
})
