





























import SidePanelBodyFrame from '@/components/ui/page/SidePanelBodyFrame.vue'
import SidePanelBox from '@/components/ui/page/SidePanelBox.vue'
import SidePanelFooterFrame from '@/components/ui/page/SidePanelFooterFrame.vue'
import SidePanelHeaderFrame from '@/components/ui/page/SidePanelHeaderFrame.vue'
import SidePanelFrame from '@/components/ui/page/SidePanelFrame.vue'
import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import { useRouter } from 'vue2-helpers/vue-router'
import ScheduleConfirmSuggestSidePanelBox from '@/components/sidePanels/confirm/schedule/ScheduleConfirmSuggestSidePanelBox.vue'
import { ScheduleModel } from '@/models/data/schedule'
import { SpirUser, FullCalendarEvent } from '@/types'
import SuggestAlternativeCandidateModule from '@/store/modules/suggestAlternativeCandidate'
import SuggestCandidateFooterButtons from '@/components/ui/domain/footer/SuggestCandidateFooterButtons.vue'

export default defineComponent({
  name: 'ConfirmScheduleSuggestDateSidePanel',
  components: {
    SidePanelFrame,
    SidePanelHeaderFrame,
    SidePanelFooterFrame,
    SidePanelBox,
    SidePanelBodyFrame,
    ClosingPageheadTitleBox,
    ScheduleConfirmSuggestSidePanelBox,
    SuggestCandidateFooterButtons
  },
  props: {
    schedule: {
      type: Object as PropType<ScheduleModel>
    },
    authorInfo: {
      type: Object as PropType<SpirUser>
    },
    isSyncing: {
      type: Boolean
    },
    isNarrow: {
      type: Boolean
    },
    additionalEvents: {
      type: Array as PropType<Array<FullCalendarEvent>>
    },
    onEventDelete: {
      type: Function as PropType<(eventId: string) => void>
    },
    isSignIn: {
      type: Boolean
    },
    onConfirmButtonClick: {
      type: Function as PropType<() => void>
    },
    onEventUpdate: {
      type: Function as PropType<() => void>
    }
  },
  setup(props) {
    const { isSyncing } = props
    const { schedule } = toRefs(props)
    const router = useRouter()
    const existsSuggestingCandidate = computed(() => SuggestAlternativeCandidateModule.alternativeCandidates.length > 0)
    const isConfirmDisabled = computed(() => isSyncing || !existsSuggestingCandidate.value)

    function handleClose() {
      router.push({ name: 'ConfirmSchedule', params: { id: schedule.value.id } })
    }

    return {
      isConfirmDisabled,
      existsSuggestingCandidate,
      handleClose
    }
  }
})
