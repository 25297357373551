


















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ConfirmationForm } from '@/models/data/availability'
import TextArea from '@/components/newForm/confirmationForms/TextArea.vue'

@Component({
  components: {
    TextArea
  }
})
export default class ConfirmationForms extends Vue {
  @PropSync('formData', { type: Object }) formDataSynced!: any
  @Prop() confirmationForm: ConfirmationForm

  get orderedForms() {
    return this.confirmationForm['x-spir-properties-order']
  }
  propertyByKey(key) {
    return this.confirmationForm.properties[key]
  }
  formType(key) {
    const property = this.confirmationForm.properties[key]
    if (!property) {
      return null
    }
    switch (property.widget) {
      case 'textarea':
        return TextArea
      default:
        return TextArea
    }
  }
  isRequired(key) {
    return this.confirmationForm.required?.indexOf(key) >= 0
  }
  formDataByKey(key) {
    return this.formDataSynced[key]
  }
  updatedProperty(key, newValue) {
    this.$set(this.formDataSynced, key, newValue) // make reactive
  }
}
