


























import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarItemMenuButton from '@/components/calendarList/molecules/CalendarItemMenuButton.vue'
import CalendarListItem from '@/components/calendarList/molecules/CalenderListItem.vue'
import { ICalendar } from '@/types'

@Component({
  components: {
    CalendarItemMenuButton,
    CalendarListItem
  }
})
export default class PersonalCalendarListItem extends Vue {
  @Prop() calendar: ICalendar
  @Prop() visible: boolean
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: false }) willShowMenuBottom: boolean
  @Prop() renderingMenu: boolean
  @Prop() onCalendarBackgroundColorChange: (payload: { accountId: string; calendarId: string; color: string }) => void

  private isMenuActive = false

  onToggleCalendar() {
    if (!this.isMenuActive) {
      this.$emit('onToggleCalendar')
    }
  }
  handleMenuActivityChange(dest: boolean) {
    this.isMenuActive = dest
  }

  handleColorSelect(backgroundColor: string) {
    this.$emit('onCalendarBackgroundColorChange', {
      accountId: this.calendar.accountId,
      calendarId: this.calendar.id,
      backgroundColor
    })
  }
}
