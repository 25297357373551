import { parseISO } from 'date-fns'
import { diffDaysByUserTimezone, addDays } from '@/lib/utils/timezone'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export default function (start: string, end: string, allDay?: boolean) {
  if (!start || !end) {
    return
  }
  const firstDate = parseISO(start)
  const lastDate = parseISO(end)
  if (allDay) {
    const allDayFormat = spirDateFormatTypes.mdWeekday
    const diffDays = diffDaysByUserTimezone(lastDate, firstDate) - 1 // 終日EventのStartとEndの差は日数+1のため、1 を引く
    if (diffDays > 0) {
      return `${spirDateFormat(firstDate, allDayFormat)} - ${spirDateFormat(
        addDays(firstDate, diffDays),
        allDayFormat
      )}`
    }
    return `${spirDateFormat(firstDate, spirDateFormatTypes.mdWeekday)}`
  }
  return `${spirDateFormat(firstDate, spirDateFormatTypes.mdWeekdayHourMin)} - ${spirDateFormat(
    lastDate,
    spirDateFormatTypes.hourMin
  )}`
}
