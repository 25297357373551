














import { Vue, Prop, Component } from 'vue-property-decorator'
import InputField from '@/components/newForm/molecules/InputField.vue'

@Component({
  components: {
    InputField
  }
})
export default class ConfirmationFormTextArea extends Vue {
  @Prop() label: string
  @Prop() description: string
  @Prop() icon: string
  @Prop() required: boolean
  @Prop() value: string

  currenValue = null
  created() {
    this.currenValue = this.value
  }
  get validationRule() {
    const defaultRule = 'max:500'
    return this.required ? `required|${defaultRule}` : defaultRule
  }
}
