






























import { useEventStyleStore } from '@/composables/calendar/useEventStyleStore'
import { useTimeAxis } from '@/composables/calendar/useTimeAxis'
import { useModeClasses } from '@/composables/calendar/useModeClasses'
import { useTranslation } from '@/composables/useTranslation'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { spirDateFormatCustom, spirDateFormatCustomTypes } from '@/lib/utils/dateFormat'
import { CalendarBodyModeUtil } from '@/lib/utils'
import CalendarsModule from '@/store/modules/calendars'
import {
  CalendarBodyMode,
  FullCalendarDragEvent,
  FullCalendarEvent,
  FullCalendarEventStyle,
  ScheduleSource
} from '@/types'
import { SLOT_DURATION } from '@/types/constants'
import jaLocale from '@fullcalendar/core/locales/ja'
import VueFullCalendar, { CalendarApi, CalendarOptions, EventContentArg, SlotLabelContentArg } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import luxonPlugin from '@fullcalendar/luxon'
import timeGridPlugin from '@fullcalendar/timegrid'
import { captureException } from '@sentry/vue'
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@vue/composition-api'
import { addHours, differenceInMinutes } from 'date-fns'
import { throttle } from 'lodash'
import DailyCalendar from './DailyCalendar.vue'

const successColor = '#0085C7'
const expiredStyle = (): FullCalendarEventStyle => ({
  textColor: 'white',
  borderColor: 'lightgrey',
  backgroundColor: 'lightgrey',
  classNames: ['calendar-event', 'declined-event']
})
const defaultStyleMap: Partial<Record<ScheduleSource, (event: FullCalendarEvent) => FullCalendarEventStyle>> = {
  candidate: () => ({
    textColor: 'white',
    borderColor: '#1E366A', // primary
    backgroundColor: '#1E366A', // primary
    classNames: ['candidate-event', 'highlightable']
  }),
  calendarEvent: () => ({
    textColor: 'white',
    borderColor: 'lightgrey',
    backgroundColor: 'lightgrey',
    classNames: ['calendar-event']
  }),
  schedule: (event) => {
    const calendarColor = event.backgroundColor
    delete event.borderColor
    return {
      textColor: calendarColor || successColor,
      borderColor: calendarColor || successColor,
      classNames: ['calendar-event unconfirmed-schedule']
    }
  },
  pending: () => ({
    textColor: 'royalblue',
    borderColor: 'royalblue',
    backgroundColor: 'white',
    classNames: ['pending-event', 'highlightable']
  }),
  confirmer: () => ({
    textColor: 'white',
    borderColor: successColor,
    backgroundColor: successColor,
    classNames: ['confirmer-event', 'highlightable']
  }),
  confirmerUnderThirty: () => ({
    textColor: 'white',
    borderColor: successColor,
    backgroundColor: successColor,
    classNames: ['confirmer-event under-thirty', 'highlightable']
  }),
  confirmed: () => ({
    textColor: 'black',
    borderColor: 'lightgreen',
    backgroundColor: 'lightgreen'
  }),
  editingEvent: () => ({
    textColor: 'white',
    borderColor: 'royalblue',
    backgroundColor: '#194e7c',
    classNames: ['editing-event', 'highlightable']
  }),
  poll: (event) => {
    const calendarColor = event.backgroundColor
    delete event.borderColor
    return {
      textColor: calendarColor || successColor,
      borderColor: calendarColor || successColor,
      classNames: ['unconfirmed-poll']
    }
  },
  pollAnswerNo: () => ({
    textColor: successColor,
    borderColor: successColor,
    backgroundColor: 'white',
    classNames: ['poll-event', 'highlightable']
  }),
  pollAnswerYes: () => ({
    textColor: 'white',
    borderColor: successColor,
    backgroundColor: successColor,
    classNames: ['poll-event', 'highlightable']
  }),
  exceptionExclude: () => ({
    textColor: '#e03f3f',
    borderColor: '#e03f3f',
    backgroundColor: 'white',
    classNames: ['dotted', 'highlightable']
  }),
  exceptionInclude: () => ({
    textColor: successColor,
    borderColor: successColor,
    backgroundColor: 'white',
    classNames: ['dotted', 'highlightable']
  }),
  holidayExclude: () => ({
    textColor: '#e03f3f',
    borderColor: '#e03f3f',
    backgroundColor: 'white',
    classNames: ['dotted', 'highlightable']
  }),
  holidayObservedExclude: () => ({
    textColor: '#e03f3f',
    borderColor: '#e03f3f',
    backgroundColor: 'white',
    classNames: ['dotted', 'highlightable']
  }),
  templateCandidate: () => ({
    textColor: successColor,
    borderColor: successColor,
    backgroundColor: 'white',
    classNames: ['dotted', 'highlightable']
  }),
  rejected: () => ({
    classNames: ['calendar-event', 'rejected-event']
  }),
  tentative: () => ({
    classNames: ['calendar-event', 'tentative-event']
  }),
  declined: expiredStyle,
  expired: expiredStyle,
  expiredPoll: expiredStyle
}
export default defineComponent({
  name: 'CalendarBody',
  components: {
    VueFullCalendar,
    DailyCalendar
  },
  props: {
    selectable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    allEvents: {
      type: Array as PropType<FullCalendarEvent[]>,
      default: () => []
    },
    calendarHeight: {
      type: Number,
      required: true
    },
    theme: {
      type: String as PropType<'highlight' | ''>,
      default: ''
    },
    getCurrentView: {
      type: Number as PropType<1 | 3 | 7>,
      default: 7
    },
    showDailyView: {
      type: Boolean,
      default: false
    },
    eventDate: {
      type: Object as PropType<{ start: Date; end: Date }>
    },
    timezoneButtonAsLabel: {
      type: Boolean,
      default: false
    },
    lastCalendarScrollPosition: {
      type: Number
    },
    mode: {
      type: Object as PropType<CalendarBodyMode>,
      default: () => ({ type: 'normal' })
    }
  },
  setup(props, { emit }) {
    const {
      allEvents,
      selectable,
      editable,
      eventDate,
      calendarHeight,
      getCurrentView,
      timezoneButtonAsLabel,
      lastCalendarScrollPosition,
      mode
    } = toRefs(props)
    const { timezoneForDisplay, timeAxisHeader, timeAxisBody } = useTimeAxis()
    const { modeClasses } = useModeClasses({ mode })
    const i18n = useTranslation()
    const { saveInset, getInset } = useEventStyleStore()
    const fullCalendar = ref<{ getApi: Function } | null>(null)
    const jaCalendarLocale = ref(jaLocale)
    const isDragging = ref(false)
    const scrollListnerThrottle = ref(null)

    const currentView = computed((): string => {
      return `timeGrid${getCurrentView.value}Day`
    })
    const primaryTimezone = computed(() => {
      return timezoneForDisplay.value[0].key
    })
    const primaryCalendars = computed(() => {
      return CalendarsModule.primaryCalendars
    })
    const defaultDate = computed(() => {
      if (eventDate.value.start) {
        return eventDate.value.start
      }
      return new Date()
    })
    const fullCalendarEvents = computed((): FullCalendarEvent[] => {
      return allEvents.value.map((evt) => {
        const defaultStyle = defaultStyleMap[
          evt.extendedProps && (evt.extendedProps.underDuration ? 'expired' : evt.extendedProps.source)
        ]
          ? defaultStyleMap[
              evt.extendedProps && (evt.extendedProps.underDuration ? 'expired' : evt.extendedProps.source)
            ](evt)
          : {}
        const classForLayer = CalendarBodyModeUtil.classForLayer(mode.value, evt.extendedProps?.source)
        const allDayCls = evt.allDay ? 'all-day' : ''
        return {
          ...defaultStyle,
          ...evt,
          classNames: (defaultStyle.classNames ?? []).concat([classForLayer, allDayCls])
        }
      })
    })
    function dateFormat(dateObject): { html: string } {
      const day = spirDateFormatCustom(dateObject.date, spirDateFormatCustomTypes.d, {
        timeZone: primaryTimezone.value
      })
      const weekday = spirDateFormatCustom(dateObject.date, spirDateFormatCustomTypes.ccc, {
        timeZone: primaryTimezone.value
      })
      return {
        html: `<div style="display: flex; flex-direction: column; width: 100%; padding-top: 8px;"><span class="week">${weekday}</span><div><span class="day">${day}</span></div><div style="flex: 1; width: 100%; border-right: solid 1px #ddd;" /></div>`
      }
    }

    function scrollerElement(): Element | null {
      const scroller = document.getElementsByClassName('fc-scroller-liquid-absolute')
      return scroller ? scroller[0] : null
    }

    function scrollToCurrentHeight() {
      if (scrollerElement()) {
        const element = scrollerElement() as HTMLElement
        if (element) {
          element.scrollTo({
            top: lastCalendarScrollPosition.value
          })
        }
      }
    }
    function handleRender() {
      scrollToCurrentHeight()
    }
    function handleEventsRender(eventObj: EventContentArg): { html: string } {
      const event = eventObj.event
      if (differenceInMinutes(event.end, event.start) <= 30) {
        eventObj.timeText = eventObj.timeText.split(' ')[0]
      }
      const eventSource = event.extendedProps?.source
      if (eventSource === 'exceptionExclude') {
        return {
          html:
            `<span class="tag is-danger is-small" style="padding-left: 6px; padding-right: 4px;">
            <span>
              <span class="icon is-small" style="padding-right: 2px;">
                <i class="mdi mdi-cancel"></i>
              </span>
              ${i18n.t('availabilitySharing.form.exception.exclude')}
            </span>
          </span>
          <br>` + (eventObj.timeText || '')
        }
      }
      if (eventSource === 'holidayExclude' || eventSource === 'holidayObservedExclude') {
        const title =
          eventSource === 'holidayExclude'
            ? event.title
            : `${event.title}<br />(${i18n.t('availabilitySharing.form.exception.holidayObserved')})`
        return {
          html: `
          <span class="tag is-danger is-small" style="padding-left: 6px; padding-right: 4px;">
            <span>
              <span class="icon is-small" style="padding-right: 2px;">
                <i class="mdi mdi-cancel"></i>
              </span>
              ${i18n.t('availabilitySharing.form.exception.holidayExclude')}
            </span>
          </span><br>
          <div class="holiday-exclude-frame">
            <div class="holiday-exclude-title">
              ${title}
            </div>
          </div>
        `
        }
      }
      if (eventSource === 'exceptionInclude') {
        return {
          html:
            `<span class="tag is-success is-small" style="padding-left: 6px; padding-right: 4px;">
            <span>
              <span class="icon is-small" style="padding-right: 2px;">
                <i class="mdi mdi-plus-circle"></i>
              </span>
              ${i18n.t('availabilitySharing.form.exception.include')}
            </span>
          </span>
          <br>` + (eventObj.timeText || '')
        }
      }
      if (eventSource === 'confirmer' || eventSource === 'confirmerUnderThirty') {
        return {
          html: (eventObj.timeText || '') + `&nbsp;<span>${i18n.t('buttons.select')}</span>`
        }
      }
    }
    function handleEventDidMount(eventObj) {
      const event = eventObj.event
      const parentElement: HTMLElement = eventObj.el.parentElement
      const style = parentElement.style
      const inset = style.getPropertyValue('inset')
      const eventSource = event.extendedProps?.source
      const calendarId = event.extendedProps?.calendarId
      const eventStyleId = `${event.id}_${calendarId}`
      if (CalendarBodyModeUtil.shouldSaveRegisteredEventStyle(mode.value, eventSource)) {
        saveInset(eventStyleId, inset)
      } else if (CalendarBodyModeUtil.shouldUpdateLayeredStyle(mode.value)) {
        const csses = CalendarBodyModeUtil.getLayerStyles(mode.value, eventStyleId, eventSource, getInset)
        csses.forEach((cls) => style.setProperty(cls.prop, cls.value))
      }
      // hack. confirm用のEventはFullWidthで表示する必要がある。
      if (eventSource === 'confirmer' || eventSource === 'confirmerUnderThirty') {
        try {
          if (inset) {
            // desktop
            const insetSplite = inset.split(' ')
            insetSplite[3] = '0%'
            style.setProperty('inset', insetSplite.join(' '))
          } else {
            const left = style.getPropertyValue('left')
            if (left) {
              // safari
              style.setProperty('left', '0%')
            }
          }
          style.setProperty('width', '100%')
          style.setProperty('z-index', '99')
        } catch (e) {
          captureException(e)
        }
      }
      return eventObj
    }

    function handleDateClick({ date }) {
      emit('dateClick', date)
    }

    function changeCalendarView(method, params = null) {
      if (fullCalendar.value) {
        const api: CalendarApi = fullCalendar.value.getApi()
        api[method](params)
      }
    }

    function handleSelect(e: FullCalendarDragEvent) {
      const { start, end, allDay, jsEvent } = e
      emit('onDragToCreate', { start, end, allDay, jsEvent })
      changeCalendarView('unselect', null)
    }

    // let's control from parent side
    function handleEventClick(event) {
      emit('handleEventClick', event)
    }
    // todo: have to change. confirm event should be created when user has clicked.
    function hanldeEventDropAndResize(eventDropInfo) {
      isDragging.value = false
      emit('onUpdate', eventDropInfo)
      changeCalendarView('unselect', null)
    }
    function handleDragStart() {
      // hack. in order to prevent swipe event in dragging.
      isDragging.value = true
    }
    function handleMouseEnter(event) {
      EventBus.emit(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, event)
    }
    function handleMouseLeave(event) {
      EventBus.emit(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, event)
    }
    function allDayContent() {
      return { domNodes: [timeAxisHeader(timezoneButtonAsLabel.value)] }
    }

    function handleSlotLabelContent(event: SlotLabelContentArg) {
      const hour = Number(event.text)
      const currentDateWithHour = addHours(eventDate.value.start, hour)
      return {
        domNodes: [timeAxisBody(currentDateWithHour)]
      }
    }
    const fullCalendarOptions = computed((): CalendarOptions => {
      return {
        plugins: [dayGridPlugin, luxonPlugin, timeGridPlugin, interactionPlugin],
        views: {
          timeGrid1Day: {
            type: 'timeGrid',
            duration: { days: 1 }
          },
          timeGrid3Day: {
            type: 'timeGrid',
            duration: { days: 3 }
          },
          timeGrid7Day: {
            type: 'timeGrid',
            duration: { days: 7 }
          }
        },
        allDaySlot: true,
        initialView: currentView.value,
        dayHeaderContent: dateFormat,
        selectable: selectable.value,
        editable: editable.value,
        headerToolbar: false,
        height: calendarHeight.value,
        datesSet: handleRender,
        initialDate: defaultDate.value,
        slotDuration: `00:${SLOT_DURATION}:00`,
        slotLabelInterval: '01:00',
        longPressDelay: 200,
        nowIndicator: true,
        fixedWeekCount: false,
        handleWindowResize: true,
        locale: jaCalendarLocale.value,
        timeZone: primaryTimezone.value,
        eventContent: handleEventsRender,
        eventDidMount: handleEventDidMount,
        dateClick: handleDateClick,
        select: handleSelect,
        eventClick: handleEventClick,
        eventDrop: hanldeEventDropAndResize,
        eventResize: hanldeEventDropAndResize,
        eventDragStart: handleDragStart,
        eventMouseEnter: handleMouseEnter,
        eventMouseLeave: handleMouseLeave,
        events: fullCalendarEvents.value,
        allDayContent: allDayContent,
        slotLabelContent: handleSlotLabelContent,
        slotLabelFormat: 'H' // hack. time labelを表示するさいに、addHoursとして使うため時間だけにする
      }
    })

    function setScrollListner() {
      const scrollElement = scrollerElement()
      if (scrollElement) {
        scrollElement.addEventListener('scroll', scrollListnerThrottle.value)
      }
    }

    function scrollListner() {
      const scrollElement = scrollerElement()
      if (scrollElement) {
        emit('calendarScroll', scrollElement.scrollTop)
      }
    }

    function handleScrollSync(scrollTop) {
      emit('calendarScroll', scrollTop)
    }
    function handleCreateEvent(payload) {
      emit('onDragToCreate', payload)
    }
    function swipeHandlerLeft() {
      if (isDragging.value) {
        return
      }
      emit('swipe', 'next')
    }
    function swipeHandlerRight() {
      if (isDragging.value) {
        return
      }
      emit('swipe', 'prev')
    }
    watch(
      eventDate,
      () => {
        nextTick(() => {
          changeCalendarView('gotoDate', eventDate.value.start)
        })
      },
      { immediate: true, deep: true }
    )

    watch(getCurrentView, () => {
      nextTick(() => {
        changeCalendarView('changeView', currentView.value)
      })
    })
    onMounted(() => {
      scrollListnerThrottle.value = throttle(scrollListner, 100)
      scrollToCurrentHeight()
      setScrollListner()
    })
    onBeforeUnmount(() => {
      const scrollElement = scrollerElement()
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', scrollListner)
      }
    })
    return {
      fullCalendar,
      modeClasses,
      fullCalendarEvents,
      fullCalendarOptions,
      primaryCalendars,
      handleScrollSync,
      handleCreateEvent,
      handleEventClick,
      hanldeEventDropAndResize,
      swipeHandlerLeft,
      swipeHandlerRight
    }
  }
})
