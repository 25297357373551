import axiosInstance from '../axios'
import store from '@/store'
import { ProfileRequest } from '@spirinc/contracts'

export const getProfile = (id?: string) => {
  if (store.getters['User/isSignIn']) {
    return axiosInstance.get(!id ? 'me' : `profile/${id}`)
  }
  return axiosInstance.get(!id ? 'me' : `public/profile/${id}`)
}

export const updateBasicProfile = (payload: ProfileRequest) => {
  return axiosInstance.put('me', payload)
}
