














import { Component, PropSync, Vue } from 'vue-property-decorator'
import { ConfirmationForm } from '@/models/data/availability'
import FromGroup from '@/components/newForm/atoms/FormGroup.vue'
import Message from '@/components/patterns/conformationForm/Message.vue'

@Component({
  components: {
    FromGroup,
    Message
  }
})
export default class PatternSidePanelConfirmationForm extends Vue {
  @PropSync('confirmationForm', { type: Object }) confirmationFormSynced!: ConfirmationForm
  get propertyKeysWithOrder() {
    return this.confirmationFormSynced['x-spir-properties-order'] || Object.keys(this.confirmationFormSynced.properties)
  }
  updateObject() {
    this.$emit('updateObject')
  }
  isRequired(key) {
    return this.confirmationFormSynced.required?.indexOf(key) >= 0
  }
  toggleRequired(key: string, newValue: boolean) {
    const findIndex = this.confirmationFormSynced.required?.indexOf(key)
    if (newValue) {
      if (findIndex < 0) {
        this.confirmationFormSynced.required.push(key)
      }
    } else {
      if (findIndex >= 0) {
        this.confirmationFormSynced.required.splice(findIndex, 1)
      }
    }
    this.updateObject()
  }
}
