




















import ConfirmCandidateBlockDate from '../atoms/ConfirmCandidateBlockDate.vue'
import ConfirmCandidateDateUL from '../atoms/ConfirmCandidateDateUL.vue'
import ConfirmCandidateDateLI from '../atoms/ConfirmCandidateDateLI.vue'
import ConfirmCandidateDateRowUL from '../atoms/ConfirmCandidateDateRowUL.vue'
import ConfirmCandidateDateRowLI from '../atoms/ConfirmCandidateDateRowLI.vue'
import SuggestAlternativeDateRow from './SuggestAlternativeDateEditRow.vue'
import { groupBy, sortBy } from 'lodash'
import { endOfDay } from '@/lib/utils/timezone'
import { FullCalendarEvent } from '@/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { DatetimeSlotSavePayload } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'

export default defineComponent({
  components: {
    ConfirmCandidateBlockDate,
    ConfirmCandidateDateUL,
    ConfirmCandidateDateLI,
    ConfirmCandidateDateRowUL,
    ConfirmCandidateDateRowLI,
    SuggestAlternativeDateRow
  },
  props: {
    candidates: {
      type: Array as PropType<Array<FullCalendarEvent>>,
      required: true
    },
    timezone: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    onCandidateSelect: {
      type: Function as PropType<(event: FullCalendarEvent) => Promise<void>>
    },
    scheduleDuration: {
      type: Number,
      required: true
    },
    onEventUpdate: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>
    },
    onEventDelete: {
      type: Function as PropType<(eventId: string) => void>
    }
  },
  setup(props) {
    const { candidates } = toRefs(props)
    const duration = computed(() => props.scheduleDuration)
    const groupedCandidatesByDate = computed((): Record<string, FullCalendarEvent[]> => {
      const sortedByDate = sortBy(candidates.value, (candidate) => candidate.start)
      const grouped = groupBy(sortedByDate, (candidate) => endOfDay(candidate.start).toISOString())
      return grouped
    })
    return {
      duration,
      groupedCandidatesByDate
    }
  }
})
