
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PullDownForm extends Vue {
  @Prop({ default: true }) showFooter: boolean
  @Prop({ default: true }) showHeader: boolean
}
