

















import { defineComponent, computed, toRefs, PropType } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirSelectBox, { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'
import SpirDropdownButton from '@/components/ui/spir/SpirDropdown/SpirDropdownButton.vue'
import SpirDatePicker from '@/components/ui/spir/SpirDatePicker.vue'
import { StartSelects, startSelects } from '@/lib/utils/peekCandidates'
import { useTranslation } from '@/composables/useTranslation'
import { parseISO, isValid } from 'date-fns'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export default defineComponent({
  name: 'FormStartDateSelectBoxItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    SpirSelectBox,
    SpirDropdownButton,
    SpirDatePicker
  },
  props: {
    value: {
      type: String as PropType<StartSelects | string>,
      required: true
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    onInput: {
      type: Function as PropType<(value: StartSelects | string) => void>,
      required: true
    },
    minDate: {
      type: Date,
      default: () => new Date()
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { value } = toRefs(props)
    const startSelectItems: SelectItem[] = [...startSelects, 'customStart'].map((startSelect) => {
      return {
        key: startSelect,
        label: i18n.t(`peekCandidates.dateOptions.${startSelect}`).toString()
      }
    })
    const valueAsDateFormat = computed(() => parseISO(value.value))

    const startSelectValue = computed({
      get: () => {
        if (isValid(valueAsDateFormat.value)) {
          return 'customStart'
        }
        return value.value
      },
      set: (newValue) => {
        const considerCustom = newValue === 'customStart' ? new Date().toISOString() : newValue
        props.onInput(considerCustom)
      }
    })
    const showDatePicker = computed(() => isValid(valueAsDateFormat.value))
    const formatedDate = computed(
      () => spirDateFormat(valueAsDateFormat.value, spirDateFormatTypes.yyyymmddweekday) //'yyyy/MM/dd(EEE)'
    )
    const handleUpdated = (newDate) => {
      props.onInput(newDate)
    }
    return {
      startSelectItems,
      startSelectValue,
      showDatePicker,
      valueAsDateFormat,
      formatedDate,
      handleUpdated
    }
  }
})
