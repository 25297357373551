












import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmModal from './Confirm.vue'
import { CandidateForVote } from '@/store/modules/vote'

@Component({
  components: {
    ConfirmModal
  }
})
export default class PollConfirm extends Vue {
  @Prop() item: CandidateForVote

  get voteCount() {
    return this.$t('message.confirm.confirmWithPollCount', { yesCount: this.item.yesCount, noCount: this.item.noCount })
  }
  get showDateRange() {
    return this.$options.filters['oneDateRange']({ startDate: this.item.start, endDate: this.item.end })
  }
}
