
















import { defineComponent, PropType } from '@vue/composition-api'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  name: 'FormItem',
  components: {
    InlineSvg
  },
  props: {
    type: {
      type: String as PropType<'icon' | 'svg'>,
      default: 'icon'
    },
    src: {
      type: String,
      required: true
    }
  }
})
