













import { defineComponent, toRefs, PropType, computed } from '@vue/composition-api'
import { useAfterConfirmSetup } from '@/composables/useAfterConfirmSetup'
import AfterConfirm from '../Index.vue'
import { AfterConfirmModel } from '@/models/data/afterConfirm'
import { getConfirmedEvent } from '@/lib/api/schedule'
import { getPublicSchedule } from '@/lib/api/scheduleTeam'
import { ScheduleModelTeam } from '@/models/data/schedule'
import DisplayTitle from '@/components/newDisplay/Title.vue'
import DateWithTimezone from '@/components/newDisplay/DateWithTimezone.vue'
import OnlineMeeting from '@/components/newDisplay/OnlineMeeting.vue'
import Attendees from '@/components/newDisplay/Attendees.vue'
import Description from '@/components/newDisplay/Descrpition.vue'
import Location from '@/components/newDisplay/Location.vue'
import { AfterConfirmModelFactoryForSchedule } from '@/models/data/AfterConfirmModelFactory/schedule'

export default defineComponent({
  components: {
    AfterConfirm,
    DisplayTitle,
    DateWithTimezone,
    OnlineMeeting,
    Attendees,
    Description,
    Location
  },
  props: {
    id: {
      type: String,
      required: true
    },
    teamId: {
      type: String
    },
    teamOfPrivate: {
      type: String as PropType<'team' | 'private'>
    }
  },
  setup(props) {
    const { teamId, teamOfPrivate } = toRefs(props)
    const fetchConfirmedInfo = async () => {
      if (teamOfPrivate.value === 'private') {
        const response = await getConfirmedEvent(props.id)
        return AfterConfirmModelFactoryForSchedule.create(response.schedule, response.event)
      } else {
        const response = await getPublicSchedule(teamId.value, props.id)
        const model = new ScheduleModelTeam(teamId.value, response)
        return AfterConfirmModel.createByScheduleTeamModel(model)
      }
    }
    const properties = computed(() => {
      return {
        type: 'schedule',
        id: props.id,
        teamId: teamId ? teamId.value : undefined
      }
    })
    const { afterConfirmModel, isLoading } = useAfterConfirmSetup({
      fetchConfirmedInfo,
      properties
    })
    return {
      isLoading: isLoading || afterConfirmModel,
      afterConfirmModel
    }
  }
})
