




















import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import AvatarWithName from './_AvatarWithName.vue'
import { personalId, PersonalInfo, PersonalTeamValue, TeamInfo } from './personalTeamInfo'

export default defineComponent({
  name: 'PersonalTeamSelectDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem,
    AvatarWithName
  },
  props: {
    personal: {
      type: Object as PropType<PersonalInfo>,
      required: true
    },
    teams: {
      type: Array as PropType<TeamInfo[]>,
      required: true
    },
    value: {
      type: String as PropType<PersonalTeamValue>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { personal, teams, value } = toRefs(props)

    const selectedInfo = computed(() =>
      value.value === personalId ? personal.value : teams.value.find((t) => t.id === value.value)
    )

    function handleChange(newValue: string) {
      emit('change', newValue)
    }

    return {
      selectedInfo,
      handleChange
    }
  }
})
