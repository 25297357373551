type nextAction = (shouldStayHere: () => Promise<boolean>) => Promise<void>
type goToNextPage = () => void

type NextActionInitial = {
  goToNextPage: goToNextPage
}
export const useNextActionInitializer = () => {
  return {
    initialize: ({
      goToNextPage
    }: NextActionInitial): {
      getNextAction
    } => {
      function getNextAction(overwriteAction?: nextAction) {
        return async function (shouldStayHere: () => Promise<boolean>) {
          if (overwriteAction) {
            await overwriteAction(shouldStayHere)
          } else {
            goToNextPage()
          }
        }
      }
      return { getNextAction }
    }
  }
}
export const useNextAction = ({
  goToNextPage
}: NextActionInitial): {
  getNextAction
} => {
  const { initialize } = useNextActionInitializer()
  return initialize({ goToNextPage })
}
