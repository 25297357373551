



















import { Prop } from 'vue-property-decorator'
import HeaderTitle from './Title.vue'
import Body from './Body.vue'
import { ResourceColumn, ResourceEvent } from '@/types'
import { EventBus, EVENTS } from '@/lib/eventBus'
import Component, { mixins } from 'vue-class-component'
import TimeAxisMixin from '@/components/calendar/TimeAxis'

@Component({
  components: {
    HeaderTitle,
    Body
  }
})
export default class ResourceViewHeader extends mixins(TimeAxisMixin) {
  @Prop() columns: ResourceColumn[]
  @Prop() gridHeight: string
  @Prop() events: ResourceEvent[]
  @Prop() hideAllday: boolean
  @Prop() timezoneButtonAsLabel: boolean

  $refs: {
    resourceViewAllDayHeader: HTMLElement
    headerLabels: HTMLElement
  }
  mounted() {
    EventBus.on(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
    this.setHeader()
  }
  beforeDestroy() {
    EventBus.off(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  syncScroll(srcElement) {
    if (this.$refs.headerLabels) {
      this.$refs.headerLabels.scroll({
        left: srcElement.scrollLeft
      })
    }
  }
  get allEvents() {
    return this.events.filter((event) => !!event.allDay)
  }
  setHeader() {
    if (this.hideAllday || !this.$refs.resourceViewAllDayHeader) {
      return
    }
    this.$refs.resourceViewAllDayHeader.appendChild(this.timeAxisHeader(this.timezoneButtonAsLabel))
  }
  eventsByCalendar(columnKey) {
    return this.allEvents.filter((event) => event.columnKey === columnKey)
  }
  handleCreateAllDayEvent(event, columnKey) {
    this.$emit('select', {
      columnKey,
      allDay: true,
      jsEvent: event
    })
  }
}
