
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ListType } from '@/types'
import { ScheduleModel } from '@/models/data'
import { EventBus, EVENTS } from '@/lib/eventBus'
import AppModule from '@/store/modules/app'
import ConfirmedCard from '@/components/card/molecules/ConfirmedCard.vue'
import ConfirmedScheduleCardBodyForPrivate from '@/components/card/ConfirmedScheduleCardBodyForPrivate.vue'

@Component({
  components: {
    ConfirmedCard,
    ConfirmedScheduleCardBodyForPrivate
  }
})
export default class PrivateConfirmedScheduleDateItems extends Vue {
  @Prop() date: string
  @Prop() items: ScheduleModel[]

  isDisplayedPastSchedules = false
  today: Date = new Date()

  get isSchedule() {
    return (schedule: ScheduleModel) => {
      if (schedule.type === ListType.SCHEDULE) {
        return true
      }
      return false
    }
  }

  async showItem(item: ScheduleModel) {
    AppModule.closeQuickView()
    EventBus.emit(EVENTS.SHOW_EVENT, {
      eventId: item.confirmedInfo?.eventId,
      accountId: item.accountId,
      calendarId: item.calendarId,
      moveDate: true
    })
  }
}
