



















import Divider from '@/components/atoms/Divider.vue'
import FormDescriptionTextareaItem from '@/components/ui/domain/item/FormDescriptionTextareaItem.vue'
import TeamScheduleBasicInfoSection from '@/components/ui/domain/section/arrangement/BasicInfoSection/TeamScheduleBasicInfoSection.vue'
import OthersInfoSection from '@/components/ui/domain/section/arrangement/OthersInfoSection.vue'
import EditablePeekCandidatesSection from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/EditablePeekCandidatesSection.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { defineComponent, computed } from '@vue/composition-api'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    Divider,
    FormDescriptionTextareaItem,
    TeamScheduleBasicInfoSection,
    OthersInfoSection,
    EditablePeekCandidatesSection
  },
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      isAutoCandidatesNoticeVisible: computed(() => ProfileModule.getLanguage === 'ja')
    }
  }
})
