
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModelTeam } from '@/models/data'
import ScheduleCardBodyForTeam from '@/components/card/ScheduleCardBodyForTeam.vue'
import OnWaitingOperationBox from '@/components/card/molecules/OnWaitingOperationBox.vue'
import { AllRouteNames } from '@/router'
import { SignalType } from '@/lib/analytics/LogEntry'
import ShareTextModal from '@/components/modal/shareText/ShareTextModal.vue'

@Component({
  components: {
    OnWaitingOperationBox
  }
})
export default class TeamUnconfirmedScheduleItem extends Vue {
  @Prop() item: ScheduleModelTeam

  get cardType() {
    return ScheduleCardBodyForTeam
  }
  isExpiredItem(item: ScheduleModelTeam) {
    return item.status === 'expired'
  }
  isSuggestedItem(item: ScheduleModelTeam) {
    return item.status === 'suggestedByConfirmer'
  }
  handleConfirm(item: ScheduleModelTeam) {
    this.$router.push({ name: AllRouteNames.TeamScheduleConfirm, params: { id: item.teamId, scheduleId: item.id } })
  }
  handleEdit(item: ScheduleModelTeam) {
    this.$router.push({ name: AllRouteNames.TeamScheduleEdit, params: { id: item.teamId, scheduleId: item.id } })
  }
  async handleUrlCopy(item: ScheduleModelTeam) {
    await this.$analytics.send(SignalType.CLICK_HTML_SHARE_ON_CANDIDATE_MODAL, { type: 'schedule' })
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ShareTextModal,
      hasModalCard: true,
      canCancel: true,
      props: { schedule: item },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
  handleDelete(item: ScheduleModelTeam) {
    this.$emit('deleteSchedule', { teamId: item.teamId, scheduleId: item.id })
  }
}
