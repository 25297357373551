import { useTranslation } from '@/composables/useTranslation'
import { getCandidates } from '@/lib/api/peekCandidates'
import { FromStartAndEnd } from '@/lib/utils/converters'
import { FromHolidays } from '@/lib/utils/converters/fromHolidays'
import PeekCandidateModule from '@/store/modules/peekCandidate'
import { FullCalendarEvent, ListType } from '@/types'
import { PeekCandidatesPostRequest } from '@spirinc/contracts'
import { reactive, Ref, ref } from '@vue/composition-api'
import { nanoid } from 'nanoid'

export type FetchCandidatesConditionParameter = Omit<
  PeekCandidatesPostRequest,
  'excludeScheduleCandidates' | 'excludeGroupPollCandidates' | 'excludeAllDays'
>

type Props = {
  listType: ListType
}
export const usePeekCandidates = ({ listType }: Props) => {
  const i18n = useTranslation()
  const isCandidateLoading = ref(false)
  const tempCandidates: Ref<FullCalendarEvent[]> = ref([])
  const holidayEvents: Ref<FullCalendarEvent[]> = ref([])
  const createNewFullCalendarEvent = (start: Date, end: Date): FullCalendarEvent => {
    return {
      id: nanoid(),
      start,
      end,
      title: `${i18n.t('labels.candidate').toString()}`,
      editable: false,
      extendedProps: {
        source: 'templateCandidate'
      }
    }
  }
  // 投票型用
  function toPolls(temps: Array<{ start: string; end: string }>, duration: number): FullCalendarEvent[] {
    const merged = FromStartAndEnd.convertToMerged({
      temps,
      mapper: (tc) => createNewFullCalendarEvent(tc.start, tc.end),
      duration
    })
    const splited = FromStartAndEnd.convertToSplittedByDuration({
      temps: merged,
      duration
    })
    return splited.map((tc) => createNewFullCalendarEvent(tc.start, tc.end))
  }
  // 確定型用
  function toMerged(temps: Array<{ start: string; end: string }>, duration: number): FullCalendarEvent[] {
    const merged = FromStartAndEnd.convertToMerged({
      temps,
      mapper: (tc) => createNewFullCalendarEvent(tc.start, tc.end),
      duration
    })
    return merged
  }
  const fetchCandidates = async (peekCondition: FetchCandidatesConditionParameter): Promise<FullCalendarEvent[]> => {
    try {
      isCandidateLoading.value = true
      const response = await PeekCandidateModule.fetchCandidates(() =>
        getCandidates({
          ...peekCondition,
          excludeScheduleCandidates: true,
          excludeGroupPollCandidates: false,
          excludeAllDays: true
        })
      )
      const timeSpans = response.timespans
      const temporaries =
        listType === ListType.POLL
          ? toPolls(timeSpans, peekCondition.duration)
          : toMerged(timeSpans, peekCondition.duration)
      const holidays = FromHolidays.convertToFullCalendarEvents(response.holidays)
      tempCandidates.value = temporaries
      holidayEvents.value = holidays
      return temporaries
    } catch (e) {
      tempCandidates.value = []
      return []
    } finally {
      isCandidateLoading.value = false
    }
  }
  return reactive({
    isCandidateLoading,
    tempCandidates,
    holidayEvents,
    fetchCandidates
  })
}
