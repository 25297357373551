import { Module, VuexModule, Action, getModule } from 'vuex-module-decorators'
import * as SettingApi from '../../lib/api/settings'
import store from '@/store'
import AutoCandidateModule from './autoCandidate'
import { ZoomSettings } from '@/types'

@Module({
  dynamic: true,
  name: 'Settings',
  namespaced: true,
  store
})
class Settings extends VuexModule {
  @Action
  async fetchSettings() {
    const settings = await SettingApi.getSettings()
    AutoCandidateModule.SET_AUTO_CANDIDATE(settings.autoCandidates)
  }

  @Action
  async updateZoomSettings(payload: ZoomSettings) {
    await SettingApi.updateSettings(SettingApi.SettingType.ZOOM, payload)
  }
}

export default getModule(Settings)
