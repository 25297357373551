
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModelTeam } from '@/models/data'
import ConfirmedScheduleCardBodyForTeam from '@/components/card/ConfirmedScheduleCardBodyForTeam.vue'
import ConfirmedScheduleOperationBox from '@/components/card/molecules/ConfirmedScheduleOperationBox.vue'
import ConfirmedCard from '@/components/card/molecules/ConfirmedCard.vue'
import AppModule from '@/store/modules/app'
import ConfirmedScheduleEventModule from '@/store/modules/confirmedScheduleEvent'
import EventFormModal from '@/components/modal/EventForm.vue'

@Component({
  components: {
    ConfirmedCard,
    ConfirmedScheduleCardBodyForTeam,
    ConfirmedScheduleOperationBox
  }
})
export default class TeamConfirmedScheduleDateItems extends Vue {
  @Prop() date: string
  @Prop() items: ScheduleModelTeam[]

  async showItem(item: ScheduleModelTeam) {
    AppModule.closeQuickView()
    const confirmedEvent = await ConfirmedScheduleEventModule.getConfirmedEvent({
      teamId: item.teamId,
      scheduleId: item.id
    })
    this.$buefy.modal.open({
      parent: this,
      component: EventFormModal,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props: {
        currentEvent: confirmedEvent,
        hideControls: true
      }
    })
  }
}
