





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { isAfter, isBefore, isEqual } from 'date-fns'
import ResouceView from './resouceView/Index.vue'
import { ResourceColumn } from '@/types'
import DailyViewModule from '@/store/modules/dailyView'
import PollAndSchedule from '@/store/modules/pollAndSchedule'
import { FullCalendarEvent, ICalendar, ITypeCalendarListForUI, CreateEvent } from '@/types'
import { sortBy, filter } from 'lodash'
import UserModule from '@/store/modules/user'
import { startOfDay, addDays } from '@/lib/utils/timezone'
import { encodeCalendarKeyByAccountIdAndCalendarId } from '@/store/modules/calendars'

const GOOGLE_GROUP_CALENDAR = /@group.v.calendar.google.com$/

@Component({
  components: {
    ResouceView
  }
})
export default class DailyCalendar extends Vue {
  @Prop() primaryCalendars: ITypeCalendarListForUI[]
  @Prop({ default: true }) selectable!: boolean
  @Prop() calendarHeight: number
  @Prop() fullCalendarEvents: FullCalendarEvent[]
  @Prop() eventDate: Date
  @Prop() lastScrollHeight: number
  @Prop({ default: false }) timezoneButtonAsLabel: boolean

  get isSignIn() {
    return UserModule.isSignIn
  }
  get calendarsForDailyView() {
    return DailyViewModule.calendarsForDailyView
  }
  get editingSchedule() {
    return PollAndSchedule.editingForm
  }
  get today() {
    return startOfDay(this.eventDate)
  }
  get tomorrow() {
    return startOfDay(addDays(this.today, 1))
  }
  get isDailyViewLoading() {
    return DailyViewModule.isDailyViewLoading
  }
  get eventsForResourceView() {
    return this.fullCalendarEvents.filter((event) => event.id !== 'confirmEvent')
  }
  get enabledBlankCalendar(): boolean {
    return this.$route.name === 'scheduleConfirm' && !this.isSignIn
  }
  get fullWidthEventsForResourceView() {
    return this.fullCalendarEvents
      .filter((event) => event.id === 'confirmEvent')
      .map((e) => {
        return {
          ...e,
          columnKey: encodeCalendarKeyByAccountIdAndCalendarId(e.extendedProps.accountId, e.extendedProps.calendarId)
        }
      })
  }
  get primaryCalendarByObject() {
    return this.primaryCalendars.reduce((a, c: ITypeCalendarListForUI) => {
      a[c.accountId] = c
      return a
    }, {})
  }
  /*
  In daily view, we need to merge group events to primary calendar's events
  */
  get mergedEvents() {
    const filteredEvents = filter(this.eventsForResourceView, (event) => {
      return (
        (isAfter(event.start, this.today) || isEqual(event.start, this.today)) &&
        (isBefore(event.end, this.tomorrow) || isEqual(event.end, this.tomorrow))
      )
    })
    return sortBy(filteredEvents, (event) => event.start).map((event: FullCalendarEvent) => {
      const newEvent = {
        ...event
      }
      if (event.extendedProps.calendarId && event.extendedProps.calendarId.search(GOOGLE_GROUP_CALENDAR) >= 0) {
        const primaryCalendar = this.primaryCalendarByObject[event.extendedProps.accountId]
        if (primaryCalendar) {
          newEvent.extendedProps.calendarId = primaryCalendar.calendarId
        }
      }
      newEvent['columnKey'] = encodeCalendarKeyByAccountIdAndCalendarId(
        event.extendedProps.accountId,
        event.extendedProps.calendarId
      )
      return newEvent
    })
  }
  get targetCalendars(): ResourceColumn {
    if (this.enabledBlankCalendar) {
      const eventForConfirm =
        this.eventsForResourceView && this.eventsForResourceView.length > 0 ? this.eventsForResourceView[0] : null
      const {
        extendedProps: { accountId, calendarId }
      } = eventForConfirm
      return [
        {
          columnKey: encodeCalendarKeyByAccountIdAndCalendarId(accountId, calendarId),
          accountId: accountId,
          backgroundColor: '#6769cc',
          foregroundColor: '#3e4043',
          id: calendarId,
          owner: true,
          primary: true,
          title: 'Guest Calendar',
          type: 'normal',
          visible: true,
          writable: true
        }
      ]
    }
    return this.calendarsForDailyView
      .sort((a: ICalendar, b: ICalendar) => {
        if (!this.editingSchedule) {
          if (a.type === 'normal' && b.type !== 'normal') {
            return -1
          } else if (b.type === 'normal' && a.type !== 'normal') {
            return 1
          }
          return 0
        }
        if (a.accountId === this.editingSchedule.accountId && b.accountId === this.editingSchedule.accountId) {
          if (a.primary) {
            return -1
          }
          if (b.primary) {
            return 1
          }
          if (a.type === 'normal' && b.type !== 'normal') {
            return -1
          } else if (b.type === 'normal' && a.type !== 'normal') {
            return 1
          }
        } else if (a.accountId === this.editingSchedule.accountId && b.accountId !== this.editingSchedule.accountId) {
          return -1
        }
        return 0
      })
      .map((c) => ({ ...c, columnKey: encodeCalendarKeyByAccountIdAndCalendarId(c.accountId, c.id) }))
  }
  getCalendarById(accountId, calendarId) {
    return this.targetCalendars.find((c) => c.accountId === accountId && c.id === calendarId)
  }
  handleCreateEvent(event) {
    const targetCalendar = this.targetCalendars.find((c) => c.columnKey === event.columnKey)
    if (!targetCalendar) {
      return
    }
    if (
      !targetCalendar.writable ||
      (this.editingSchedule && (!targetCalendar.primary || this.editingSchedule.accountId !== targetCalendar.accountId))
    ) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: `${this.$t('message.notAllowedCalendar')}`
      })
      return
    }
    const payloadForCreateEvent: CreateEvent = {
      ...event,
      accountId: targetCalendar.accountId,
      calendarId: targetCalendar.id
    }

    this.$emit('createEvent', payloadForCreateEvent)
  }
}
