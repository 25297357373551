import { addDays, startOfDay } from '@/lib/utils/timezone'
import { addMinutes, differenceInMinutes, isAfter, isBefore, isEqual, subMinutes } from 'date-fns'
import { FullCalendarEvent } from '@/types/schedule'

export const isSameEvent = (baseEvent: { start: Date; end: Date }, targetEvent: { start: Date; end: Date }) => {
  return isEqual(targetEvent.start, baseEvent.start) && isEqual(targetEvent.end, baseEvent.end)
}

/**
 *
 * @param baseEvent
 * @param targetEvent
 * targetEventが一部でもbaseEventとかぶっているかどうかをチェックする
 * @return {boolean}
 *  true: かぶっている
 *  false: かぶっていない
 */
export const checkIntersectionEvents = (
  leftEvent: { start: Date; end: Date },
  rightEvent: { start: Date; end: Date }
): boolean => {
  return (
    isSameEvent(leftEvent, rightEvent) ||
    (isAfter(rightEvent.end, leftEvent.start) && isBefore(rightEvent.start, leftEvent.end))
  )
}

const SPLIT_MIN = 30
export const extractAndConstructCandidates = (
  candidates: FullCalendarEvent[],
  model: { duration: number },
  splitMin: number = SPLIT_MIN
): FullCalendarEvent[] => {
  return candidates
    .filter(
      (event) =>
        event.id !== 'confirmEvent' &&
        event.extendedProps?.source !== 'confirmer' &&
        event.extendedProps?.source !== 'confirmerUnderThirty'
    )
    .map((event) => {
      const lastFittingStartTime = subMinutes(event.end, model.duration)
      const amountOf30minSlotsStartTimes = Math.ceil(differenceInMinutes(lastFittingStartTime, event.start) / 30) + 1
      return Array(amountOf30minSlotsStartTimes)
        .fill(null)
        .map((_, i) => {
          let startTime = addMinutes(event.start, i * splitMin)
          let endTime = addMinutes(event.start, i * splitMin + model.duration)
          if (isAfter(endTime, event.end)) {
            startTime = subMinutes(event.end, model.duration)
            endTime = event.end
          }
          return {
            ...event,
            start: startTime,
            end: endTime
          }
        })
    })
    .flat()
}

export const validateCandidate = (event: FullCalendarEvent): boolean => {
  if (!event.extendedProps || !event.extendedProps.source) {
    true
  }
  switch (event.extendedProps.source) {
    case 'expired':
    case 'rejected':
    case 'declined':
      return false
  }
  return !event.extendedProps.underDuration
}

type CandidateEventForCheck = {
  id?: string
  start: Date
  end: Date
}
type CurrentEventForCheck = {
  id: string
  start: Date
  end: Date
}
export const hasIntersectedEvents = (candidate: CandidateEventForCheck, events: CurrentEventForCheck[]) => {
  return events.some((event) => {
    return (
      (candidate.id ? event.id !== candidate.id : true) &&
      checkIntersectionEvents({ start: event.start, end: event.end }, { start: candidate.start, end: candidate.end })
    )
  })
}

export const getAllDayStartDate = (startDate: Date, allDayFlag: boolean, timeZone?: string): Date => {
  const startOfDayInUserTimezone = startOfDay(startDate, timeZone)
  if (allDayFlag) {
    return startOfDayInUserTimezone
  }
  return startDate
}

export const getAlldayEndDate = (endDate: Date, allDayFlag: boolean, timeZone?: string): Date => {
  const startOfDayInUserTimezone = startOfDay(endDate, timeZone)
  if (!allDayFlag) {
    return endDate
  }
  if (isEqual(startOfDayInUserTimezone, endDate)) {
    return endDate
  }
  return addDays(startOfDayInUserTimezone, 1)
}

export const updateEndDateConsiderAllDay = (
  oldAllDayFlag: boolean,
  newAllDayFlag: boolean,
  end: string,
  timeZone?: string
): string => {
  if (!oldAllDayFlag || oldAllDayFlag === newAllDayFlag) {
    return end
  }
  const endDate = new Date(end)
  const startOfDayEndDate = startOfDay(endDate, timeZone)
  if (!isEqual(startOfDayEndDate, endDate)) {
    return end
  }
  return subMinutes(startOfDayEndDate, 15).toISOString()
}
