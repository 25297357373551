





















import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdown',
  props: {
    value: {
      type: String
    },
    expanded: {
      type: Boolean,
      default: true
    },
    mobileModal: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    position: {
      type: String as PropType<'bottom' | 'top' | 'bottom-left'>,
      default: 'bottom'
    },
    maxHeight: {
      type: Number,
      default: 250
    }
  },
  setup(props, { emit }) {
    function handleChange(newValue: string) {
      emit('change', newValue)
    }
    function handleActiveChange(newValue: string) {
      emit('active-change', newValue)
    }
    const buefyPos = computed(() => {
      switch (props.position) {
        case 'top':
          return 'is-top-right'
        case 'bottom-left':
          return 'is-bottom-left'
        default:
          return 'is-bottom-right'
      }
    })

    return {
      buefyPos,
      handleChange,
      handleActiveChange
    }
  },
  methods: {
    toggle() {
      const dd: any = this.$refs.dropdown // eslint-disable-line
      dd.toggle()
    }
  }
})
