




















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import CalendarsModule from '@/store/modules/calendars'
import UserBadge from '@/components/molecules/UserBadge.vue'
import { ICalendar } from '@/types'

@Component({
  components: {
    UserBadge
  }
})
export default class FormCalendarSelector extends Vue {
  @Prop() accountId: string
  @Prop({ default: false }) noLabel: boolean
  @PropSync('calendarId', { type: String }) calendarIdSynced!: string
  @PropSync('accountId', { type: String }) accountIdSynced!: string
  @Prop({ default: false }) disableCalendarPicker: boolean
  @Prop({ default: true }) primaryCalendarOnly: boolean
  @Prop({ default: false }) updateHeightWhenDropdownIsActive

  isDropdownActive = false

  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }
  get accountWithcalendars() {
    return CalendarsModule.getAccountWithcalendars
  }
  get currentAccountWithCalendar() {
    return this.accountWithcalendars.find((a) => a.accountId === this.accountIdSynced)
  }
  get currentCalendar() {
    return this.currentAccountWithCalendar?.calendars.find((c) => c.id === this.calendarIdSynced)
  }
  calendarByAccount(calendars: ICalendar[]) {
    return calendars.filter((c) => c.primary || (!this.primaryCalendarOnly && c.writable && c.type !== 'resource'))
  }

  isActive(accountId: string, calendarId: string) {
    return this.accountIdSynced === accountId && this.calendarIdSynced === calendarId
  }
  selectCalendar(accountId: string, calendarId: string, email: string) {
    this.accountIdSynced = accountId
    this.calendarIdSynced = calendarId
    this.$emit('input', email)
  }
  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
  }
}
