import axios, { AxiosError } from 'axios'
import firebase from 'firebase/app'
import * as Sentry from '@sentry/vue'
import Router, { RouteNames } from '@/router'
import { APIError } from './sdk/error'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})
axiosInstance.defaults.timeout = 90000 // 90秒
axiosInstance.interceptors.request.use(
  async (request) => {
    const user = firebase.auth().currentUser
    if (user) {
      const idToken = await user.getIdToken()
      request.headers = {
        ...request.headers,
        'X-Spir-User-Id': user.uid,
        Authorization: `Bearer ${idToken}`
      }
    }
    return Promise.resolve(request)
  },
  (error: AxiosError) => {
    Sentry.captureException(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError) => {
    Sentry.captureException(error)
    if (error.response && error.response.status === 403) {
      const signOutRouteName: RouteNames = 'SignOut'
      if (Router.app.$route.name !== signOutRouteName) {
        Router.push({ name: signOutRouteName })
      }
    }
    // if there is data, make it to API Error
    if (error.response && error.response.data) {
      return Promise.reject(new APIError(error.response.status, error.response.data, error.response))
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
