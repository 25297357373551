












import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserVote } from '@/types'
import AttendeeIcons from './AttendeeIcons.vue'
import VoteCountWithIcon from './VoteCountWithIcon.vue'

@Component({
  components: {
    AttendeeIcons,
    VoteCountWithIcon
  }
})
export default class VoteStatus extends Vue {
  @Prop() votes: UserVote[]
  @Prop() yesCount: number
  @Prop() noCount: number

  get isVotesExist() {
    return this.votes.length > 0
  }
  get votesAttendees() {
    if (!this.isVotesExist) {
      return []
    }
    return this.votes.map((v) => {
      return v.attendee
    })
  }
  isAbsent(vote: UserVote) {
    return vote.answer === 'no'
  }
}
