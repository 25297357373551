




















import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import { JoiningTeam } from '@spirinc/contracts'
import Layout from '../atoms/AsideMenuItemLayout.vue'

@Component({
  components: {
    Layout,
    Avatar
  }
})
export default class AsideMenuItemTeam extends Vue {
  @Prop() expanded: boolean
  @Prop() team: JoiningTeam
}
