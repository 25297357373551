
























import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import { OnlineMeetingType } from '@/types'
import { useTranslation } from '@/composables/useTranslation'
import { OnlineMeetingTool } from '../onlineMeeting'
import ZoomDropdownItem from './_ZoomDropdownItem.vue'

export default defineComponent({
  name: 'OnlineMeetingDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem,
    ZoomDropdownItem
  },
  props: {
    value: {
      type: String as PropType<OnlineMeetingType>,
      required: true
    },
    onlineMeetingTools: {
      type: Array as PropType<OnlineMeetingTool[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)
    const i18n = useTranslation()

    function serviceName(serviceType: OnlineMeetingType) {
      return i18n.t(`onlineMeetings.${serviceType.toString()}`).toString()
    }

    function isActiveService(tool: OnlineMeetingTool) {
      return tool.isConnected ?? true
    }

    function handleZoomDisconnect() {
      if (value.value === OnlineMeetingType.zoom) {
        emit('change', OnlineMeetingType.none)
      }
    }

    function handleChange(newValue: string) {
      emit('change', newValue)
    }

    return {
      serviceName,
      isActiveService,
      handleZoomDisconnect,
      handleChange
    }
  }
})
