




import { Component, Prop, Vue } from 'vue-property-decorator'
import { FullCalendarMouseEvent } from '@/types'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { isMobile } from '@/lib/utils'

@Component
export default class PollAttendeeIcons extends Vue {
  @Prop() onMounseEnter: (payload: FullCalendarMouseEvent) => void
  @Prop() onMounseLeave: (payload: FullCalendarMouseEvent) => void

  mounted() {
    if (!isMobile()) {
      EventBus.on(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, this.onMounseEnter)
      EventBus.on(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, this.onMounseLeave)
    }
  }
  beforeDestroy() {
    if (!isMobile()) {
      EventBus.off(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, this.onMounseEnter)
      EventBus.off(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, this.onMounseLeave)
    }
  }
}
