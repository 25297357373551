import { TeamId } from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import {
  useFormAttendingEmailsInitializer,
  useFormConfirmationEmailTargetsItemInitializer,
  useFormDescriptionTextareaItemInitializer,
  useFormDurationSelectBoxItemInitializer,
  useFormLocationInputFormInitializer,
  useFormOnlineMeetingDropdownItemInitializer,
  useFormOrganizerDropdownItemInitializer,
  useFormTitleInputItemInitializer,
  useFormVisibilitySelectBoxItemInitializer,
  useOrganizerAndOnlineMeetingsConnectInitializer
} from '@/components/ui/domain/item/formItemComposables'
import { OnlineMeetingTool } from '@/components/ui/domain/item/FormOnlineMeetingDropdownItem/onlineMeeting'
import { usePeekCandidatesSectionSetupInitializer } from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/usePeekCandidatesSectionSetup'
import {
  DatetimeSlotDeletePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { useSendSignal } from '@/composables/useSendSignal'
import { useTeamScheduleMembers } from '@/composables/useTeamScheduleMembers'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import queryParams from '@/lib/queryParams'
import { ScheduleDuration, ScheduleModelTeam } from '@/models/data/schedule'
import { AllRouteNames } from '@/router'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import { MAX_CANDIDATE_COUNT, TeamScheduleUpdateResult } from '@/store/modules/editScheduleTeam'
import UserModule from '@/store/modules/user'
import { Candidate, FullCalendarEvent, ListType, OnlineMeetingType, VISIBILITY } from '@/types'
import { computed, reactive, ref } from '@vue/composition-api'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { usePeekCandidatesFormInit } from '../peekCandidatesSectionComposables/usePeekCandidatesFormInit'
import { CommonCandidatesControls, TeamCandidatesControls } from './logics/candidates'
import { OverwritingData, TeamScheduleFormData, TitleSuggestionSetup, UpdateTeamScheduleForms } from './types'
import { useCandidatesMaxOverWatcherInitializer } from './_useCandidatesMaxOverWatcher'
import { useNextActionInitializer } from './_useNextAction'
import { useOptionalCalendarOpenInitializer } from './_useOptionalCalendarOpen'
import { useTeamSchedulePageDataControlInitializer } from './_useTeamSchedulePageDataControl'

type UseTeamScheduleFormData = {
  teamModel: ScheduleModelTeam
  titleSuggestions: string[]
  saveTitleSuggestion: (title: string) => void
  isEditMode?: boolean
  goToNext?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  cancelEditing?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  openCalendar?: () => void
}

export const useTeamScheduleFormData = () => {
  const { sendSignal } = useSendSignal()
  const route = useRoute()
  const router = useRouter()
  const i18n = useTranslation()
  const { openInfoBottomToast } = useToast()
  const titleformInitializer = useFormTitleInputItemInitializer()
  const durationFormInitializer = useFormDurationSelectBoxItemInitializer()
  const organizerFormInitializer = useFormOrganizerDropdownItemInitializer()
  const attendeesFormInitializer = useFormAttendingEmailsInitializer()
  const confirmationEmailTargetsInitializer = useFormConfirmationEmailTargetsItemInitializer()
  const descriptionFormInitializer = useFormDescriptionTextareaItemInitializer()
  const onlineMeetingFormInitializer = useFormOnlineMeetingDropdownItemInitializer()
  const organizerAndOnlineMeetingsConnectInitializer = useOrganizerAndOnlineMeetingsConnectInitializer()
  const locationFormInitializer = useFormLocationInputFormInitializer()
  const visibilityFormInitializer = useFormVisibilitySelectBoxItemInitializer()
  const peekCandidatesSectionSetupInitializer = usePeekCandidatesSectionSetupInitializer()
  const teamSchedulePageDataControlInitializer = useTeamSchedulePageDataControlInitializer()
  const candidatesMaxOverWatcherInitializer = useCandidatesMaxOverWatcherInitializer()
  const { peekCandidates, peekCandidatesConditionForm, copyTemporaryCandidatesToCandidates } =
    usePeekCandidatesFormInit({ listType: ListType.SCHEDULE })
  const optionalCalendarOpenInitializer = useOptionalCalendarOpenInitializer()
  const nextActionInitializer = useNextActionInitializer()
  const { toFormAllActiveMembers, toFormAllMembers, toTeamAttendees } = useTeamScheduleMembers()
  return {
    initializeTeam: ({
      teamModel,
      titleSuggestions,
      saveTitleSuggestion,
      isEditMode = false,
      goToNext,
      cancelEditing,
      openCalendar
    }: UseTeamScheduleFormData) => {
      const durationForm = durationFormInitializer.initialize(teamModel.duration)
      const organizerForm = organizerFormInitializer.initialize({ organizerMemberId: teamModel.organizerMemberId })
      const titleForm = titleformInitializer.initialize(teamModel.title)
      function goToUnconfirmedList() {
        const query = { 'team-id': teamModel.teamId }
        router.push({ name: AllRouteNames.UnconfirmedList, query }).catch(GuardExceptionHandlers.noopAgainstAbortion)
      }
      const { getNextAction } = nextActionInitializer.initialize({ goToNextPage: goToUnconfirmedList })
      const toNext = getNextAction(goToNext)
      const toCancelEditing = getNextAction(cancelEditing)

      const allActiveMembers = computed(() => toFormAllActiveMembers({ scheduleModelTeam: teamModel }))
      const allMembers = computed(() =>
        toFormAllMembers({
          formAllActiveMembers: allActiveMembers.value,
          organizerMemberId: organizerForm.organizerMemberId
        })
      )
      const memberCalendars = computed(() => allActiveMembers.value.map((calendar) => calendar.member))
      const attendeesForm = attendeesFormInitializer.initialize({
        attendeeEmails: toTeamAttendees({ scheduleModelTeam: teamModel, allMembers: allMembers.value })
      })
      const confirmationEmailTargetsForm = confirmationEmailTargetsInitializer.initialize({
        emails: teamModel.notifications.map((notification) => notification.email)
      })
      const descriptionForm = descriptionFormInitializer.initialize(teamModel.description)
      const onlineMeetingForm = onlineMeetingFormInitializer.initialize({ type: teamModel.onlineMeeting.type })
      const { handleCalendarOpen } = optionalCalendarOpenInitializer.initialize({ openCalendar })
      const organizerWithMemberInfo = computed(() => {
        return teamModel.allTeamMembers.find((m) => m.id === organizerForm.organizerMemberId)
      })
      function getDefaultOnlineMeetingTool(
        previousOnlineMeetingType: OnlineMeetingType
      ) {
        const availablemeetings = organizerWithMemberInfo.value.availableOnlineMeetings || []
        let defaultOnlineMeetingType: OnlineMeetingType
        if (availablemeetings.length === 0) {
          defaultOnlineMeetingType = OnlineMeetingType.none
        } else if (previousOnlineMeetingType === OnlineMeetingType.none) {
          defaultOnlineMeetingType = OnlineMeetingType.none
        } else if ((availablemeetings.includes('zoom')) && (UserModule.isConnectedZoom)) {
          defaultOnlineMeetingType = OnlineMeetingType.zoom
        } else if (availablemeetings.includes('googleMeet')) {
          defaultOnlineMeetingType = OnlineMeetingType.googleMeet
        } else if (availablemeetings.includes('microsoftTeams')) {
          defaultOnlineMeetingType = OnlineMeetingType.microsoftTeams
        } else {
          defaultOnlineMeetingType = OnlineMeetingType.none
        }
        return {
          serviceType: defaultOnlineMeetingType
        }
      }
      function getAvailableOnlineMeetings() {
        const meetings = organizerWithMemberInfo.value?.availableOnlineMeetings || []
        const availableMeetings = meetings.reduce(
          (a: OnlineMeetingTool[], c) => {
            a.push({
              serviceType: c
            })
            return a
          },
          [
            {
              serviceType: OnlineMeetingType.none
            }
          ]
        )
        return availableMeetings
      }
      const { availableOnlineMeetings, handleOrganizerUpdate } = organizerAndOnlineMeetingsConnectInitializer({
        organizerForm,
        onlineMeetingForm,
        getDefaultOnlineMeetingTool,
        getAvailableOnlineMeetings
      })
      const locationForm = locationFormInitializer.initialize({ location: teamModel.location || '' })
      const visibilityForm = visibilityFormInitializer.initialize({ visibility: teamModel.visibility })
      function generateGotoConfirmation(event: FullCalendarEvent) {
        return () => {
          sendSignal(SignalType.CLICK_CONFIRM_ON_EDIT_PAGE)
          router.push({
            name: AllRouteNames.TeamScheduleConfirm,
            params: {
              id: route.params.id,
              scheduleId: route.params.scheduleId
            },
            query: {
              [queryParams.QUERY_PARAM_CONFIRMED_DATE]: event.start.toISOString(),
              [queryParams.CANDIDATE_ID]: event.id
            }
          })
        }
      }
      const candidates = ref<Candidate[]>(teamModel.candidates)
      function replaceAllCandidates(newCandidates: { start: string; end: string; id: string }[]) {
        candidates.value = newCandidates
      }
      const peekCandidatesSectionSetup = peekCandidatesSectionSetupInitializer.initialize({
        saveCandidate: async (payload: { start: Date; end: Date; id?: string }) => {
          const newCandidates = await TeamCandidatesControls.addCandidate({
            payload: { start: payload.start, end: payload.end, candidateId: payload.id },
            candidates: candidates.value,
            duration: durationForm.duration
          })
          candidates.value = newCandidates
        },
        deleteCandidate: (id: string) => {
          const newCandidates = TeamCandidatesControls.removeCandidateById({
            candidateId: id,
            candidates: candidates.value
          })
          candidates.value = newCandidates
        },
        getFullCalendarEvents: () =>
          TeamCandidatesControls.getEditingEventByCalendarFormat({
            candidates: candidates.value,
            title: titleForm.title,
            status: teamModel.status
          }),
        generateGotoConfirmation,
        clearAllCandidates: () => (candidates.value = [])
      })

      const currentSchedule = computed((): Partial<ScheduleModelTeam> => {
        const attendingMembers = allMembers.value
          .filter((member) => {
            const x = attendeesForm.selectedEmails.find((emailType) => emailType.email === member.member.email)
            return !!x
          })
          .map((member) => ({ memberId: member.member.id }))
        const teamSchedule = {
          organizerMemberId: organizerForm.organizerMemberId,
          title: titleForm.title,
          duration: durationForm.duration,
          location: locationForm.location,
          description: descriptionForm.description,
          visibility: visibilityForm.visibility as VISIBILITY,
          onlineMeeting: { type: onlineMeetingForm.onlineMeetingType },
          candidates: candidates.value,
          attendingMembers,
          notifications: confirmationEmailTargetsForm.confirmationEmailTargets.map((email) => ({ email }))
        }
        return teamSchedule
      })
      const { clearDirty, shouldStayHere, pageDataState, handlers } = teamSchedulePageDataControlInitializer.initialize(
        {
          teamId: teamModel.teamId,
          pageData: currentSchedule,
          saveTitleSuggestion,
          isEditMode,
          goToNext: toNext,
          cancelEditing: toCancelEditing
        }
      )
      // Auto peek candidates watcher
      candidatesMaxOverWatcherInitializer.initialize({
        maxCount: MAX_CANDIDATE_COUNT,
        getOverFlag: () => peekCandidates.tempCandidates.length > MAX_CANDIDATE_COUNT,
        clearOverFlag: () => {
          peekCandidates.tempCandidates = peekCandidates.tempCandidates.slice(0, MAX_CANDIDATE_COUNT)
        }
      })
      // Hand candidates watcher
      candidatesMaxOverWatcherInitializer.initialize({
        maxCount: MAX_CANDIDATE_COUNT,
        getOverFlag: () => candidates.value.length > MAX_CANDIDATE_COUNT,
        clearOverFlag: () => {
          candidates.value = candidates.value.slice(0, MAX_CANDIDATE_COUNT)
        }
      })

      function updateForms(data: Partial<UpdateTeamScheduleForms>) {
        if (data.title) titleForm.handleUpdate(data.title)
        if (data.duration) durationForm.handleUpdate(data.duration)
        if (data.organizerMemberId) organizerForm.handleUpdate(data.organizerMemberId)
        if (data.attendeeEmails) attendeesForm.handleReplace(data.attendeeEmails)
        if (data.confirmationEmailTargets) confirmationEmailTargetsForm.handleReplace(data.confirmationEmailTargets)
        if (data.description !== undefined) descriptionForm.handleUpdate(data.description)
        if (data.onlineMeeting) onlineMeetingForm.handleUpdate(data.onlineMeeting.type)
        if (data.location !== undefined) locationForm.handleUpdate(data.location)
        if (data.visibility) visibilityForm.handleUpdate(data.visibility)
        if (data.candidates) candidates.value = data.candidates
      }
      return {
        getCurrentTeamId: () => teamModel.teamId,
        updateTeamScheduleForms: updateForms,
        updateTeamScheduleFormData(overwrite?: OverwritingData) {
          const overwritingCandidates = overwrite?.candidates ?? candidates.value
          const nextCandidates = overwritingCandidates.map((candidate) => ({
            id: candidate.id,
            start: candidate.start.toISOString(),
            end: candidate.end.toISOString()
          }))
          updateForms({
            title: overwrite?.title ?? titleForm.title,
            duration: overwrite?.duration ?? durationForm.duration,
            description: overwrite?.description ?? descriptionForm.description,
            location: overwrite?.location ?? locationForm.location,
            visibility: overwrite?.visibility ?? visibilityForm.visibility,
            candidates: nextCandidates
          })
        },
        getTeamScheduleFormData(): TeamScheduleFormData {
          return reactive({
            clearDirty,
            shouldStayHere,
            temporaryCandidatesInfo: {
              candidates: peekCandidates.tempCandidates
            },
            peekCandidatesForm: {
              isCandidateLoading: peekCandidates.isCandidateLoading,
              updateForms: peekCandidatesConditionForm.updateForms,
              allConditions: peekCandidatesConditionForm.allConditions,
              calendarsAutoCompleteForm: peekCandidatesConditionForm.calendarsAutoCompleteForm,
              weekdayForm: peekCandidatesConditionForm.weekdayForm,
              durationForm: peekCandidatesConditionForm.durationForm,
              startForm: peekCandidatesConditionForm.startForm,
              endForm: peekCandidatesConditionForm.endForm,
              timeBufferForm: peekCandidatesConditionForm.timeBufferForm,
              timezoneForm: peekCandidatesConditionForm.timezoneForm,
              countriesForm: peekCandidatesConditionForm.countriesForm,
              mixTemporariesAndOthers: (): FullCalendarEvent[] => {
                return peekCandidates.tempCandidates.concat(peekCandidates.holidayEvents)
              },
              fetchCandidates: peekCandidates.fetchCandidates,
              applyAutoPeekCandidates: () =>
                copyTemporaryCandidatesToCandidates(peekCandidates.tempCandidates, replaceAllCandidates)
            },
            pageDataState,
            pageDataControl: handlers,
            candidatesInfo: {
              hasAtLeastOneCandidate: peekCandidatesSectionSetup.hasAtLeastOneCandidate,
              candidates: peekCandidatesSectionSetup.candidates,
              addNewCandidate: peekCandidatesSectionSetup.addNewCandidate,
              removeCandidate: peekCandidatesSectionSetup.removeCandidate
            },
            basicInfo: {
              title: {
                value: titleForm.title,
                suggestions: titleSuggestions,
                handleChange: titleForm.handleUpdate
              },
              duration: {
                value: durationForm.duration,
                items: durationForm.items,
                handleChange: async (newDuration: ScheduleDuration) => {
                  durationForm.handleUpdate(newDuration)
                  const { remained, removed } = CommonCandidatesControls.separateUnderNewDurationCandidatesOrNot({
                    candidates: candidates.value,
                    duration: durationForm.duration
                  })
                  candidates.value = remained
                  if (removed.length > 0) {
                    openInfoBottomToast({
                      message: i18n.t(`message.${TeamScheduleUpdateResult.removedOverDurationCandidate}`).toString()
                    })
                  }
                }
              },
              organizer: {
                value: organizerForm.organizerMemberId,
                disabled: isEditMode,
                calendarCollections: memberCalendars,
                handleChange: handleOrganizerUpdate
              },
              attendees: {
                values: attendeesForm.selectedEmails,
                availableMemberList: allMembers,
                handleCalendarAdd: attendeesForm.handleAdd,
                handleCalendarDelete: attendeesForm.handleDelete
              },
              confirmationEmailTargets: {
                values: confirmationEmailTargetsForm.confirmationEmailTargets,
                handleEmailAdd: confirmationEmailTargetsForm.handleNewConfirmationEmailTargetAdd,
                handleEmailDelete: confirmationEmailTargetsForm.handleConfirmationEmailTargetDelete
              }
            },
            peekCandidatesSection: {
              isEditMode,
              dateSlots: peekCandidatesSectionSetup.dateSlots,
              durationToSplit: durationForm.duration,
              onAllDelete: peekCandidatesSectionSetup.clearCandidates,
              onSave: peekCandidatesSectionSetup.addNewCandidate,
              onDatetimeDelete: (data: DatetimeSlotDeletePayload) => {
                peekCandidatesSectionSetup.removeCandidate(data.id)
              },
              onDatetimeUpdate: (data: DatetimeSlotUpdatePayload) => {
                peekCandidatesSectionSetup.addNewCandidate(data, null)
              },
              onCalendarOpen: handleCalendarOpen
            },
            othersInfo: {
              description: {
                value: descriptionForm.description,
                handleChange: descriptionForm.handleUpdate
              },
              onlineMeeting: {
                value: onlineMeetingForm.onlineMeetingType,
                tools: availableOnlineMeetings,
                handleChange: onlineMeetingForm.handleUpdate
              },
              location: {
                value: locationForm.location,
                handleChange: locationForm.handleUpdate
              },
              visibility: {
                value: visibilityForm.visibility,
                items: visibilityForm.items,
                handleChange: visibilityForm.handleUpdate
              }
            }
          })
        }
      }
    }
  }
}

export const useTeamScheduleFormsData = (
  initializer: (teamId: string) => Promise<ScheduleModelTeam>,
  getTeamSchedules: (teamId: string) => TitleSuggestionSetup,
  goToNext?: (shouldStayHere: () => Promise<boolean>) => Promise<void>,
  cancelEditing?: (shouldStayHere: () => Promise<boolean>) => Promise<void>,
  openCalendar?: () => void
): { getTeam; clearTeamsData } => {
  const { initializeTeam } = useTeamScheduleFormData()
  const teams: Record<TeamId, { updateTeamScheduleFormData; getTeamScheduleFormData }> = {}

  async function selectOrCreateTeam(id: TeamId) {
    let team = teams[id]
    if (!team) {
      const initializedTeam = await initializer(id)
      const { suggestions, save } = getTeamSchedules(id)
      teams[id] = initializeTeam({
        teamModel: initializedTeam,
        goToNext,
        openCalendar,
        cancelEditing,
        titleSuggestions: suggestions,
        saveTitleSuggestion: save
      })
      team = teams[id]
    }
    return team
  }

  async function getTeam(id: TeamId): Promise<{ updateTeamScheduleFormData; getTeamScheduleFormData }> {
    const team = await selectOrCreateTeam(id)
    return team
  }
  function clearTeamsData(overwrite: OverwritingData) {
    Object.keys(teams).forEach((id) => {
      const team = teams[id]
      team.updateTeamScheduleFormData(overwrite)
    })
  }
  return {
    getTeam,
    clearTeamsData
  }
}
