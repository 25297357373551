import { useGAuth } from '../useGAuth'
import { APIError } from '@/lib/sdk/error'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { register } from '@/lib/api/calendar'
import { authErrorTypes, AuthErrorTypes } from '@/types/authError'
import TimezonesModule from '@/store/modules/timezones'
import ProfileModule from '@/store/modules/profile'

export type GetAuthcodeSuccess = {
  result: 'success' | 'cancel'
  authcode?: string
}
export type GetAuthcodeError = {
  result: 'error'
  errorCode: AuthErrorTypes
}
export type GetAuthcodeResult = Promise<GetAuthcodeSuccess | GetAuthcodeError>

export type RegisterAuthcodeSuccess = {
  result: 'success'
}
export type RegisterAuthcodeError = {
  result: 'error'
  errorCode: AuthErrorTypes
}
export type RegisterAuthcodeResult = Promise<RegisterAuthcodeSuccess | RegisterAuthcodeError>

export const useAuthAuthcode = () => {
  const gAuth = useGAuth()
  const getGoogleAuthCodeByRedirect = () => {
    return gAuth.getAuthCodeWithRedirect()
  }
  const registerAuthcode = async (
    authCode: string,
    type: 'google' | 'microsoft' = 'google'
  ): RegisterAuthcodeResult => {
    try {
      await register(authCode, type, TimezonesModule.localTimezoneInfo.key, ProfileModule.getLanguage)
      return {
        result: 'success'
      }
    } catch (e) {
      if (e instanceof APIError) {
        if (e.code === ERROR_CODE.NOT_ENOUGH_AUTHORITY) {
          return {
            result: 'error',
            errorCode: authErrorTypes.notEnoughScope
          }
        }
        if (e.code === ERROR_CODE.ACCOUNT_DUPLICATED) {
          return {
            result: 'error',
            errorCode: authErrorTypes.accountDuplicated
          }
        }
      }
      throw e
    }
  }
  return {
    registerAuthcode,
    getGoogleAuthCodeByRedirect
  }
}
