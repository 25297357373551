
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { flatten } from 'lodash'
import Avatar from 'vue-avatar'
import { ITypeCalendarListForUI } from '@/types'
import { Calendar, MemberCalendarRequest } from '@spirinc/contracts'
import DropdownWithList from '@/components/newForm/molecules/DropdownWithList.vue'
import { PersonalCalendar } from '@spirinc/contracts'

@Component({
  components: {
    Avatar,
    DropdownWithList
  }
})
export default class MainCalendarSelect extends Vue {
  @Prop() selectedCalendar: MemberCalendarRequest
  @Prop({ default: [] }) calendarGroups: PersonalCalendar[]
  @Prop({ default: false }) isLoading: boolean

  get flattenCalendars(): ITypeCalendarListForUI[] {
    return flatten(this.calendarGroups.map(({ calendars }) => calendars))
  }
  get selectedCalendarWithInfo(): Calendar {
    if (!this.selectedCalendar) {
      return null
    }
    return this.flattenCalendars.find(
      (fc) => fc.accountId === this.selectedCalendar.accountId && fc.id === this.selectedCalendar.calendarId
    )
  }
  isSelectedCalendar(calendar: ITypeCalendarListForUI) {
    if (!this.selectedCalendar) {
      return false
    }
    return calendar.accountId === this.selectedCalendar.accountId && calendar.id === this.selectedCalendar.calendarId
  }
  onChangeValue(calendar: Calendar) {
    this.$emit('change', calendar)
  }
}
