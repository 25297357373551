import axiosInstance from '../axios'
import { Candidate, ScheduleStatusUpdateActionName } from '@/types'
import { ScheduleModel } from '@/models/data'
import store from '@/store'
import {
  ScheduleConfirmer,
  ScheduleConfirmPostRequest,
  SchedulesScheduleIdConfirmedEventGetResponse
} from '@spirinc/contracts'
import { ScheduleStatus } from '@/types'
import qs from 'qs'
import { CancelToken } from 'axios'
export type GetScheduleListPayload = {
  start?: string
  end?: string
  status?: ScheduleStatus[]
  cancelToken?: CancelToken
}
export const getList = async (payload?: GetScheduleListPayload) => {
  const queryParams = { ...payload }
  return (
    await axiosInstance.get('schedules', {
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      cancelToken: payload?.cancelToken
    })
  ).data
}

export const getScheduleById = async (scheduleId) => {
  if (store.getters['User/isSignIn']) {
    return (await axiosInstance.get(`schedules/${scheduleId}`)).data
  }
  return (await axiosInstance.get(`public/schedules/${scheduleId}`)).data
}

export const create = async (schedule: ScheduleModel) => {
  return (await axiosInstance.post('schedules', schedule.payload)).data
}

export const deleteSchedule = async (scheduleId: string) => {
  return axiosInstance.delete(`schedules/${scheduleId}`)
}

export const sendNewCandidates = async (scheduleId: string, calendarId: string, candidates: Candidate[]) => {
  return (await axiosInstance.put(`schedules/${scheduleId}`, { calendarId, candidates })).data
}

export const confirm = async (payload: ScheduleConfirmPostRequest, scheduleId: string) => {
  if (store.getters['User/isSignIn']) {
    return (
      await axiosInstance.put(`schedules/${scheduleId}/confirm`, {
        email: payload.email,
        start: payload.start,
        candidateId: payload.candidateId,
        duration: payload.duration,
        timezone: payload.timeZone,
        attendees: payload.attendees
      })
    ).data
  }
  return (
    await axiosInstance.put(`public/schedules/${scheduleId}/confirm`, {
      email: payload.email,
      name: payload.name,
      start: payload.start,
      candidateId: payload.candidateId,
      duration: payload.duration,
      timezone: payload.timeZone,
      attendees: payload.attendees,
      language: payload.language
    })
  ).data
}

export const allScheduleDelete = () => {
  return axiosInstance.delete('schedules/all')
}

export const updateSchedule = async (schedule: ScheduleModel) => {
  const scheduleId = schedule.id
  delete schedule.id

  return (await axiosInstance.put(`schedules/${scheduleId}`, schedule.payload)).data
}

export const executeScheduleStatusUpdateAction = async (
  scheduleId: string,
  action: ScheduleStatusUpdateActionName,
  newCandidates: Candidate[] | null,
  confirmer?: ScheduleConfirmer
) => {
  const payload = {}
  if (newCandidates != null) {
    payload['candidates'] = newCandidates
  }

  if (confirmer) {
    payload['confirmer'] = confirmer
  }

  if (store.getters['User/isSignIn']) {
    return axiosInstance.post(`schedules/${scheduleId}/action/${action}`, payload)
  }
  return axiosInstance.post(`public/schedules/${scheduleId}/action/${action}`, payload)
}

export const deleteCandidate = async (scheduleId: string, candidateId: string) => {
  return axiosInstance.delete(`schedules/${scheduleId}/candidates/${candidateId}`)
}

export const getConfirmedEvent = async (scheduleId: string): Promise<SchedulesScheduleIdConfirmedEventGetResponse> => {
  return (await axiosInstance.get(`public/schedules/${scheduleId}/confirmedEvent`)).data
}
