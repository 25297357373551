import { render, staticRenderFns } from "./_DateSlotListItem.vue?vue&type=template&id=97966856&scoped=true&"
import script from "./_DateSlotListItem.vue?vue&type=script&lang=ts&"
export * from "./_DateSlotListItem.vue?vue&type=script&lang=ts&"
import style0 from "./_DateSlotListItem.vue?vue&type=style&index=0&id=97966856&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97966856",
  null
  
)

export default component.exports