


















































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import ZoomSignInButton from '@/components/atoms/ZoomSigninButton.vue'
import { ZoomPasswordSettings, ZoomPasswordSetting } from '@/types/user'
import SettingsModule from '@/store/modules/settings'
import UserModule from '@/store/modules/user'
import { SignalType } from '@/lib/analytics'

@Component({
  components: {
    ZoomSignInButton
  }
})
export default class ZoomIntegrationRow extends Vue {
  @Prop() accountName?: string
  @Prop({ default: false }) isConnected: boolean
  @Prop({ default: ZoomPasswordSettings.conformity }) zoomPasswordSetting: ZoomPasswordSetting
  private selectedZoomPasswordSetting?: ZoomPasswordSetting
  private passwordOptionNotes = ''
  private isSaveButtonLoading = false
  private isDisconnectButtonLoading = false

  private created() {
    this.onChangedSetting()
  }

  private get passwordOptions() {
    return [
      {
        value: ZoomPasswordSettings.conformity,
        title: this.$t('settings.integrations.passwordOptionConformity'),
        notes: this.$t('settings.integrations.passwordOptionConformityNotes')
      },
      {
        value: ZoomPasswordSettings.auto,
        title: this.$t('settings.integrations.passwordOptionAuto')
      }
    ]
  }

  @Watch('zoomPasswordSetting')
  private onChangedSetting() {
    this.selectedZoomPasswordSetting = this.zoomPasswordSetting
    this.onInputedSetting()
  }

  private onInputedSetting() {
    this.passwordOptionNotes = this.passwordOptions
      .find((option) => option.value === this.selectedZoomPasswordSetting)
      .notes?.toString()
  }

  private async handleSave() {
    const payload = { passwordSetting: this.selectedZoomPasswordSetting }
    try {
      this.isSaveButtonLoading = true
      await SettingsModule.updateZoomSettings(payload)
    } catch (e) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-top',
        message: this.$t('message.errorCommon').toString()
      })
    } finally {
      this.isSaveButtonLoading = false
    }
  }

  private async handleDisconnect() {
    try {
      this.isDisconnectButtonLoading = true
      await UserModule.disconnectZoom()
      await this.$analytics.send(SignalType.UNCONNECT_ZOOM)
    } finally {
      this.isDisconnectButtonLoading = false
    }
  }
}
