import { ScheduleDuration } from '@/models/data/schedule'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { PollStatus } from './poll'
/**
 * Spir API data types
 * TODO: merge API and client type definitions into one
 */

export interface SpirUser {
  id: string
  name: string
  fullName?: string
  photoURL: string
}

export interface OrganizerInfo {
  fullName?: string
  userId?: string
  photoURL?: string
}

export type Attendee = {
  email?: string
  fullName?: string
  id?: string
  userId?: string
  photoURL?: string
  responseStatus?: SpirAttendeeStatus
  organizer?: boolean
}
export const CandidateStatus = {
  suggestedByOrganizer: 'suggestedByOrganizer',
  suggestedByConfirmer: 'suggestedByConfirmer',
  rejected: 'rejected',
  expired: 'expired',
  expiredPoll: 'expiredPoll',
  confirmed: 'confirmed',
  deleted: 'deleted',
  open: 'open'
} as const

export type CandidateStatus = keyof typeof CandidateStatus

export const isActiveStatus = (status: CandidateStatus): boolean => {
  return (
    status !== CandidateStatus.deleted &&
    status !== CandidateStatus.rejected &&
    status !== CandidateStatus.expiredPoll &&
    status !== CandidateStatus.expired
  )
}

export interface ICandidateBase {
  id?: string
  start: string
  end: string
  status?: CandidateStatus
  eventId?: string
  attendees?: IAttendee[]
}
export interface Candidate extends ICandidateBase {
  isExpired?: boolean
  processed?: boolean
  underDuration?: boolean
  addedDate?: Date
  updateDate?: Date
}

export interface CandidateForShare {
  start: string
  end: string
}

// If I could get this from IScheduleBase interface but I couldn't
export const SCHEDULE_ATTRS = [
  'id',
  'title',
  'duration',
  'calendarId',
  'location',
  'description',
  'candidates',
  'onlineMtg',
  'onlineMeeting',
  'attendees'
]
export interface IScheduleBase {
  id?: string
  title: string
  duration: ScheduleDuration
  accountId: string
  calendarId: string
  location?: string
  description?: string
  visibility: VISIBILITY
  onlineMtg?: boolean
  onlineMeeting: IOnlineMeeting
  start?: string
  end?: string
  processed: boolean
  createdAt: string
  attendees?: Attendee[]
}
export interface ISchedulePollCommon extends IScheduleBase {
  author?: SpirUser
  calendarId: string
  candidates: Array<ICandidateBase>
  confirmedInfo?: {
    confirmedUser?: {
      name: string
    }
    eventId: string
    start: string
    end: string
  }
  status: ScheduleStatus | PollStatus
}
// Schedule, poll のBackendとのInterface
export interface ISchedule extends ISchedulePollCommon {
  candidates: Array<Candidate>
  deleted?: boolean
  actions: ScheduleStatusUpdateAction[]
  status: ScheduleStatus
  fetchCalendarService: boolean
}
/**
 * Spir web client data types
 */

export type CalendarCandidate = {
  id: string
  start: Date
  end: Date
}

const scheduleSources = [
  'schedule', // event (aka schedule) stored in firebase db
  'poll', // candidate of Poll
  'calendarEvent', // google events
  'editingEvent',
  'pending', // schedule candidates pending to be confirmed
  'confirmer', // the dummy event on full-calendar to act as confirmer
  'confirmerUnderThirty', // the dummy event on full-calendar to act as confirmer under thirty minutes
  'confirmed', // confirmed schedule
  'candidate', // candidates created in memory
  'declined', // attendee declined event
  'expired', // expired candidate
  'expiredPoll',
  'rejected', // rejected candidate by confirmer
  'newCandidate',
  'pollAnswerNo',
  'pollAnswerYes',
  'exceptionExclude',
  'exceptionInclude',
  'holidayExclude',
  'holidayObservedExclude',
  'templateCandidate',
  'tentative'
] as const

export type ScheduleSource = typeof scheduleSources[number]

export interface CreateEvent {
  id?: string
  accountId: string
  calendarId: string
  allDay?: boolean
  start: Date
  end: Date
  jsEvent: MouseEvent
}
/**
 * full-calendar library data types
 */

export interface FullCalendarEventExtendedProps {
  source: ScheduleSource
  candidateId?: string
  eventId?: string
  calendarId?: string
  accountId?: string
  scheduleId?: string
  status?: CandidateStatus
  canConfirm?: boolean
  underDuration?: boolean
  teamInfo?: {
    teamId: string
    memberId: string
    fullName?: string
    photoUrl?: string
  }
  teamId?: string
  visibility?: VISIBILITY
  attendeeFlag?: SpirAttendeeStatus
  attendees?: IAttendee[]
  onlineMeeting?: IOnlineMeeting
  countryCode?: FrontSupportCountryCode
}

export interface FullCalendarDragEvent {
  start: Date
  startStr: string
  end: Date
  endStr: string
  allDay: boolean
  jsEvent: MouseEvent
}

export interface FullCalendarEventStyle {
  textColor?: string
  borderColor?: string
  border?: string
  backgroundColor?: string
  classNames?: Array<string>
}

export type FullCalendarEvent = {
  id: string
  title: string
  start: Date
  end: Date
  allDay?: boolean
  extendedProps?: FullCalendarEventExtendedProps
  startEditable?: boolean
  durationEditable?: boolean
  classNames?: string[]
  clickable?: boolean
  editable?: boolean
  description?: string
} & FullCalendarEventStyle

/**
 * Google data types
 */
const calendarType = ['normal', 'resource', 'group'] as const

export type CalendarType = typeof calendarType[number]
export interface ICalendar {
  id: string // IDはemailアドレスではないので、決してemail代わりに使わないように！！！
  accountId: string
  title: string
  primary: boolean
  backgroundColor: string
  foregroundColor: string
  writable: boolean
  visible: boolean
  owner: boolean
  type: CalendarType
  invitationAddress?: string
  availableOnlineMeetings: OnlineMeetingType[]
}
export interface ITypeCalendarListForUI extends ICalendar {
  key: string
  calendarId: string
  accountName: string
  email: string
}
export interface ICalendarListForUI {
  [key: string]: ITypeCalendarListForUI
}
export type Resource = {
  fullName?: string
  resource?: boolean
  responseStatus?: SpirAttendeeStatus
  email: string
}
export type IAttendee = Resource & {
  id?: string
  photoURL?: string
  organizer?: boolean
  isLoading?: boolean
}
export type Organizer = {
  email: string
  displayName?: string
  type: CalendarType
}
export type GoogleEvent = {
  id?: string
  accountId?: string
  calendarId?: string
  summary: string | null
  location?: string | null
  description?: string | null
  deleted?: boolean
  start: string
  end: string
  attendees?: IAttendee[]
  resources?: Resource[]
  attendeeFlag: SpirAttendeeStatus
  writable?: boolean
  allDay?: boolean
  visibility: VISIBILITY
  onlineMtg?: boolean
  onlineMtgUrl?: string
  confirmed?: boolean
  onlineMeeting?: IOnlineMeeting
  scheduleId?: string
  pollId?: string
  availabilityId?: string
  freeBusyStatus?: FreeBusyStatus
  organizer?: Organizer
  teamInfo?: {
    teamId: string
    memberId: string
    fullName?: string
    photoUrl?: string
  }
}
export enum SpirAttendeeStatus {
  NEEDS_ACTION = 'needsAction',
  DECLIEND = 'declined',
  TENTATIVE = 'tentative',
  ACCEPTED = 'accepted'
}
export enum VISIBILITY {
  DEFAULT = 'default',
  PUBLIC = 'public',
  PRIVATE = 'private',
  CONFIDENTIAL = 'confidential'
}

export const FreeBusyStatus = {
  free: 'free',
  busy: 'busy'
} as const

export type FreeBusyStatus = keyof typeof FreeBusyStatus

export interface INewSchedulePayload {
  title: string
  duration: number
  calendarId: string
}

export const ScheduleStatus = {
  suggestedByOrganizer: 'suggestedByOrganizer',
  suggestedByConfirmer: 'suggestedByConfirmer',
  requestedByConfirmer: 'requestedByConfirmer',
  requestedByOrganizer: 'requestedByOrganizer',
  expired: 'expired',
  confirmed: 'confirmed',
  deleted: 'deleted',
  open: 'open'
} as const

export type ScheduleStatus = keyof typeof ScheduleStatus

export const ScheduleStatusUpdateActionName = {
  confirm: 'confirm',
  confirmerRequest: 'confirmerRequest',
  organizerRequest: 'organizerRequest',
  organizerResponse: 'organizerResponse',
  confirmerResponse: 'confirmerResponse',
  organizerSuggest: 'organizerSuggest',
  confirmerSuggest: 'confirmerSuggest',
  delete: 'delete',
  expire: 'expire'
} as const

export type ScheduleStatusUpdateActionName = keyof typeof ScheduleStatusUpdateActionName

export interface ScheduleStatusUpdateAction {
  name: ScheduleStatusUpdateActionName
}

export const OnlineMeetingType = {
  none: 'none',
  googleMeet: 'googleMeet',
  zoom: 'zoom',
  microsoftTeams: 'microsoftTeams'
} as const

export type OnlineMeetingType = typeof OnlineMeetingType[keyof typeof OnlineMeetingType]

export interface IOnlineMeeting {
  type: OnlineMeetingType
  info?: any
}

export const workdayKeys = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const
export type WorkDayKey = typeof workdayKeys[number]

export type ProfileOnOverview = {
  fullName: string
  photoURL?: string
  email?: string
  userId?: string
  isAuthor: boolean
  attendanceStatus?: SpirAttendeeStatus
  isLoading?: boolean
}
export enum ListType {
  POLL = 'poll',
  SCHEDULE = 'schedule'
}
export type AfterConfirmQueryParams = {
  type: 'availability' | 'schedule' | 'group-poll'
  't-or-p': 'team' | 'private'
  id: string
  'e-id'?: string
  't-id'?: string
}
