











import Vue from 'vue'
import Component from 'vue-class-component'
import SettingsSection from '@/components/organisms/settings/Section.vue'

@Component({
  components: {
    SettingsSection
  }
})
export default class AccountLinkSection extends Vue {
  toAccountLink() {
    this.$router.push({ name: 'AccountLink' })
  }
}
