







import { computed, defineComponent, onMounted, onUnmounted, watch } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import AvailabilitySharingListPage from '@/components/page/AvailabilitySharingListPage/index.vue'
import AvailabilitySharingModule from '@/store/modules/availabilityPatternList'
import queryParams from '@/lib/queryParams'
import TeamRecordModule from '@/store/modules/teamRecord'
import { SignalType } from '@/lib/analytics/LogEntry'
import { useAnalytics } from '@/composables/useAnalytics'

export default defineComponent({
  components: {
    AvailabilitySharingListPage
  },
  setup() {
    const route = useRoute()
    const analytics = useAnalytics()
    const teamId = computed(() => route.query[queryParams.TEAM_ID] as string | undefined)
    const privateAvailabilitySharingList = computed(() => AvailabilitySharingModule.privateList)
    const teamAvailabilitySharingLists = computed(() => AvailabilitySharingModule.teamLists)

    function updateList(teamId?: string) {
      analytics.send(SignalType.SHOW_PUBLIC_URL_LIST, { type: teamId ? 'team' : 'private', teamId })
      AvailabilitySharingModule.fetchPatterns(teamId)
      if (teamId) {
        TeamRecordModule.fetchTeam({ teamId })
      }
    }

    onMounted(() => updateList(teamId.value))
    onUnmounted(() => AvailabilitySharingModule.SET_CANDIDATE_MODEL(null))

    watch(teamId, (newVal) => updateList(newVal))

    return {
      privateAvailabilitySharingList,
      teamAvailabilitySharingLists
    }
  }
})
