import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { differenceInMinutes, addMinutes, isAfter, isBefore, parseISO } from 'date-fns'
import store from '@/store'
import CalendarControl from './calendarControl'
import * as SettingApi from '../../lib/api/settings'
import SettingModule from './settings'
import * as utilAPI from '../../lib/api/util'
import { Candidate } from '@/types'
import {
  startOfDay,
  endOfDay,
  addWeeks,
  endOfMonth,
  endOfWeek,
  roundToNextNearestMinutes,
  addDays
} from '@/lib/utils/timezone'
import { adjustCandidatesByDuration } from '../lib/utils'
import { nanoid } from 'nanoid'

const MODULE_NAME = 'AutoCandidate'

// copied from Functions. we need to use only one type difinition.
export interface ITimeRange {
  title: string
  startTime: {
    hour: number
    min: number
  }
  endTime: {
    hour: number
    min: number
  }
  selected: boolean
}

export const workdayKeys = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const
export type WorkDayKey = typeof workdayKeys[number]
export interface IWeekday {
  key: WorkDayKey
  enabled: boolean
}

export const periods = ['thisWeekend', 'nextWeekend', 'nextMonth', 'custom'] as const
export type Period = typeof periods[number]
export type SettingForAutoCandidate = {
  initialTime: number
  period: Period
  customPeriod?: {
    start: Date
    end: Date
  }
  timeRanges: ITimeRange[]
  weekday: IWeekday
}
type SettingForAutoCandidateForUI = SettingForAutoCandidate

export type AutoCandidateResponse = Array<{ start: string; end: string }>

const filterCandidateAfterNow = (candidates: Array<{ start: string; end: string }>, duration: number) => {
  const now = new Date()
  const filteredCandidates = candidates.filter((candidate) => isAfter(parseISO(candidate.end), now))
  if (filteredCandidates.length > 0 && isBefore(parseISO(filteredCandidates[0].start), now)) {
    // 12:11 => 12:15
    const ROUND_MIN = 15
    let roundedNow = roundToNextNearestMinutes(now, { nearestTo: ROUND_MIN })
    // when 12:05, return of roundToNearestMinutes is 12:00
    if (isBefore(roundedNow, now)) {
      roundedNow = addMinutes(roundedNow, ROUND_MIN)
    }
    if (differenceInMinutes(parseISO(filteredCandidates[0].end), roundedNow) >= duration) {
      filteredCandidates[0].start = roundedNow.toISOString()
    } else {
      filteredCandidates.shift()
    }
  }
  return filteredCandidates
}
const getTargetDateForAutoCandiates = (autoCandidateSetting: SettingForAutoCandidateForUI) => {
  const now = startOfDay(new Date())
  const startWeekDay = CalendarControl.startWeek
  switch (autoCandidateSetting.period) {
    case 'thisWeekend':
      return {
        timeMin: now.toISOString(),
        timeMax: endOfWeek(new Date(), { startWeekDay }).toISOString()
      }
    case 'nextWeekend':
      return {
        timeMin: now.toISOString(),
        timeMax: endOfWeek(addWeeks(new Date(), 1), { startWeekDay }).toISOString()
      }
    case 'nextMonth':
      return {
        timeMin: now.toISOString(),
        timeMax: endOfMonth(now).toISOString()
      }
    case 'custom':
      return {
        timeMin: startOfDay(autoCandidateSetting.customPeriod.start).toISOString(),
        timeMax: endOfDay(autoCandidateSetting.customPeriod.end).toISOString()
      }
    default:
      return {
        timeMin: now.toISOString(),
        timeMax: addDays(now, 7)
      }
  }
}
const getPayloadForGetAutoCandidate = (autoCandidateSetting: SettingForAutoCandidateForUI, duration: number) => {
  const targetDates = getTargetDateForAutoCandiates(autoCandidateSetting)
  return {
    ...targetDates,
    duration
  }
}
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class AutoCandidate extends VuexModule {
  private settingForAutoCandiates: SettingForAutoCandidate | null = null
  private loading = false
  private autoCandidateEnabled = false
  private generatedCandidates: Candidate[] = []

  get isLoading() {
    return this.loading
  }
  get isAutoCandidateEnabled() {
    return this.autoCandidateEnabled
  }
  get autoCandidatesSettings(): SettingForAutoCandidateForUI {
    return {
      ...this.settingForAutoCandiates
    }
  }
  get autoGenratedCandidates(): Candidate[] {
    return this.generatedCandidates
  }
  @Action
  async fetchAutoCandidate({
    duration,
    separateCandidateByDuration
  }: {
    duration: number
    separateCandidateByDuration: boolean
  }) {
    this.SET_LOADING(true)
    try {
      const payload = getPayloadForGetAutoCandidate(this.autoCandidatesSettings, duration)
      const response = await utilAPI.getAutoCandidates(payload)
      // filter after now!
      const candidates = filterCandidateAfterNow(response, payload.duration)
      this.SET_CANDIDATES({
        newCandidates: candidates,
        duration: duration,
        separateCandidateByDuration: separateCandidateByDuration
      })
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async updateAutoCandidatesSettings(data: {
    setting: SettingForAutoCandidate
    duration: number
    separateCandidateByDuration: boolean
  }) {
    this.SET_LOADING(true)
    try {
      this.SET_AUTO_CANDIDATE(data.setting)
      const payload = {
        ...data.setting
      }
      delete payload.customPeriod
      await SettingApi.updateSettings(SettingApi.SettingType.AUTO_CANDIDATES, payload)
      // すべてParameterとして渡すように修正したら、同時に実行できる
      await this.fetchAutoCandidate({
        duration: data.duration,
        separateCandidateByDuration: data.separateCandidateByDuration
      })
    } finally {
      this.SET_LOADING(false)
      await SettingModule.fetchSettings()
    }
  }
  @Mutation
  SET_AUTO_CANDIDATE(newAutoCandidateSetting: SettingForAutoCandidate) {
    this.settingForAutoCandiates = newAutoCandidateSetting
    if (newAutoCandidateSetting.period === 'custom' && !this.settingForAutoCandiates.customPeriod) {
      this.settingForAutoCandiates.customPeriod = {
        start: new Date(),
        end: addDays(new Date(), 7)
      }
    }
  }
  @Mutation
  SET_LOADING(status: boolean) {
    this.loading = status
  }
  @Mutation
  async SET_CANDIDATES(data: {
    newCandidates: AutoCandidateResponse
    duration: number
    separateCandidateByDuration: boolean
  }) {
    if (data.separateCandidateByDuration) {
      this.generatedCandidates = adjustCandidatesByDuration(data.newCandidates, data.duration)
    } else {
      this.generatedCandidates = data.newCandidates.map((a) => ({ ...a, id: nanoid() }))
    }
  }
  @Mutation
  SET_AUTO_CANDIDATE_FLAG(newFlag?: boolean) {
    if (newFlag === undefined) {
      this.autoCandidateEnabled = !this.autoCandidateEnabled
    } else {
      this.autoCandidateEnabled = newFlag
    }
  }
}
export default getModule(AutoCandidate)
