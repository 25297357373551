





















































import Component, { mixins } from 'vue-class-component'
import PrivateScheduleConfirmMixin from './PrivateScheduleConfirmMixin'
import ScheduleMixin from '../../ScheduleMixin'
import EventToConfirmModule from '@/store/modules/eventToConfirm'
import UserModule from '@/store/modules/user'
import { FullCalendarMouseEvent, FullCalendarEvent, ScheduleStatus } from '@/types'
import ConfirmSchedule from '@/components/modal/confirm/ConfirmSchedule.vue'
import { addMinutes } from 'date-fns'
import EditScheduleModule from '@/store/modules/editSchedule'
import Tooltip from '@/components/molecules/Tooltip.vue'
import Common from '../../../Common.vue'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { inConfirmationPage, isMobile } from '@/lib/utils'
import { ScheduleModel } from '@/models/data/schedule'
import { AfterConfirmQueryParams } from '@/types'
import ScheduleConfirmSidePanelBox from '@/components/sidePanels/confirm/schedule/ScheduleConfirmSidePanelBox.vue'
import ConfirmMobileCalendarFooter from '@/components/schedule/ConfirmMobileCalendarFooter.vue'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { SpirUserConfirmer, GuestConfirmer } from '@/models/data/'

@Component({
  components: {
    Tooltip,
    Common,
    ConfirmPageHeader,
    ScheduleConfirmSidePanelBox,
    ConfirmMobileCalendarFooter
  }
})
export default class ScheduleConfirm extends mixins(PrivateScheduleConfirmMixin, ScheduleMixin) {
  TOAST_DURATION = 5000

  async mounted() {
    if (!isMobile()) {
      EventBus.on(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, this.handleEventMouseEnter)
      EventBus.on(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, this.handleEventMouseLeave)
    }
    try {
      this.showToastIfScheduleIsSyncing()
      this.initConfirmPage()
    } catch (e) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
      this.$router.push({ name: 'Main' })
    }
  }
  beforeDestroy() {
    EventBus.off(EVENTS.CALENDAR_EVENT_MOUSE_ENTER, this.handleEventMouseEnter)
    EventBus.off(EVENTS.CALENDAR_EVENT_MOUSE_LEAVE, this.handleEventMouseLeave)
  }
  get isLoading() {
    return !this.editingSchedule || EditScheduleModule.getIsLoading
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get currentUser() {
    return UserModule.currentUser
  }
  get isEditingAlternativeCandidatesSuggestions() {
    return EventToConfirmModule.isEditingAlternativeCandidatesSuggestions
  }
  get eventToConfirm() {
    return EventToConfirmModule.eventToConfirm
  }
  get additionalEvents(): FullCalendarEvent[] {
    const returnEvents = []
    if (this.eventToConfirm) {
      returnEvents.push(this.eventToConfirm)
    }
    return returnEvents.concat(
      this.getEditingEventByCalendarFormat.map((event: FullCalendarEvent) => {
        if (!this.isValidCandidate(event)) {
          return event
        }
        return {
          ...event,
          extendedProps: { ...event.extendedProps, source: 'pending' }
        }
      })
    )
  }
  get isOrganizer() {
    return EditScheduleModule.isMine
  }
  get showConfirmButton(): boolean {
    return isMobile() && !!this.eventToConfirm
  }
  get editingSchedule(): ScheduleModel {
    return EditScheduleModule.editingSchedule
  }
  get showCalendarFooter() {
    return this.showOtherOptionButton && (!this.isNarrow || !this.isScheduleEventListOpen)
  }
  // todo: editingScheduleが単純なObjectのため、状態を判断するロジックをかけないからいろんなところで似ているロジックを書くしか無い
  // editingScheduleをModel化してすべてModel内に持つべき
  get showOtherOptionButton() {
    if (this.isOrganizer) {
      return (
        this.editingSchedule.status === ScheduleStatus.suggestedByConfirmer ||
        this.editingSchedule.status === ScheduleStatus.requestedByConfirmer
      )
    } else {
      return (
        this.editingSchedule.status === ScheduleStatus.suggestedByOrganizer ||
        this.editingSchedule.status === ScheduleStatus.requestedByOrganizer
      )
    }
  }
  startEditingAlternativeCandidatesSuggestions() {
    return EventToConfirmModule.startEditingAlternativeCandidatesSuggestions()
  }
  initConfirmPage() {
    const startDate =
      this.confirmDateFromQueryParam ||
      inConfirmationPage.getClosestStartingCandidate(this.getEditingEventByCalendarFormat)?.start
    this.initCommonProcess({ startDate })
    // get query parameter
    return this.confirmIfThereIsQueryParam()
  }
  get confirmDateFromQueryParam(): null | Date {
    return inConfirmationPage.getConfirmDateFromQueryParam(this.$route)
  }
  async confirmIfThereIsQueryParam() {
    const startDate = this.confirmDateFromQueryParam
    if (!startDate || this.noConnectedAccount) {
      return
    }
    const { duration, accountId, calendarId } = this.editingSchedule
    const endDate: Date = addMinutes(startDate, duration)
    const candidate = this.getCandidateByStartAndEnd(startDate, endDate)
    if (!candidate) {
      const toastOption = inConfirmationPage.getInvalidCandidateToastOption({ duration: this.TOAST_DURATION })
      this.$buefy.toast.open(toastOption)
      return
    }
    await this.updateConfirmEvent(startDate, endDate, candidate.id, accountId, calendarId)
    this.showOverview = false
    this.onScheduleConfirmButtonClick({ cancelBtn: this.$t('scheduleSharingText.confirmButton').toString() })
  }
  isValidCandidate(event: FullCalendarEvent) {
    if (!event.extendedProps || !event.extendedProps.source) {
      true
    }
    switch (event.extendedProps.source) {
      case 'expired':
      case 'rejected':
      case 'declined':
        return false
    }
    return !event.extendedProps.underDuration
  }
  startEditingAlternativeCanditates() {
    this.startEditingAlternativeCandidatesSuggestions()
  }
  onScheduleConfirmButtonClick(params?: { cancelBtn?: string }) {
    this.showConfirmModal(ConfirmSchedule, params)
  }
  showConfirmModal(modalComponent, params?: { cancelBtn?: string }) {
    const parameter = {
      candidateStartDate: this.eventToConfirm.start,
      candidateEndDate: this.eventToConfirm.end,
      isOrganizer: this.isOrganizer,
      schedule: this.editingSchedule,
      ...params
    }
    const modal = this.$buefy.modal.open({
      parent: this,
      component: modalComponent,
      hasModalCard: true,
      onCancel: () => {
        EventToConfirmModule.resetEventToConfirm()
      },
      events: {
        close: async () => {
          await EventToConfirmModule.resetEventToConfirm()
          modal.close()
        },
        register: ({ email, name, attendees }) => {
          modal.close()
          this.confirm(email, name, attendees)
        }
      },
      props: parameter
    })
  }
  async confirm(email: string, name: string, attendees?: { name: string; email: string }[]) {
    const {
      start,
      extendedProps: { candidateId }
    } = this.eventToConfirm
    const confirmer = UserModule.isSignIn
      ? new SpirUserConfirmer({ email, user: ProfileModule.myProfile })
      : new GuestConfirmer({
          email,
          name,
          timeZone: TimezoneModule.userTimezoneInfo.key,
          language: ProfileModule.getLanguage
        })
    const { duration } = this.editingSchedule
    try {
      await EditScheduleModule.confirmSchedule({
        scheduleId: this.editingSchedule.id,
        candidateId,
        start: start.toISOString(),
        duration,
        attendees,
        confirmer
      })
      await this.$analytics.send(SignalType.CONFIRM_SCHEDULING, {
        id: this.scheduleId,
        authorUserId: this.editingSchedule.author.id
      })
      const queryParam: AfterConfirmQueryParams = {
        type: 'schedule',
        't-or-p': 'private',
        id: EditScheduleModule.editingSchedule.id
      }
      return this.$router.push({ name: 'AfterConfirm', query: queryParam })
    } catch (err) {
      let errorMessage = null
      if (err instanceof RangeError) {
        errorMessage = this.$t('message.errorCommon').toString()
      } else {
        errorMessage = this.$t('message.error.noCandidateInRange').toString()
      }
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: errorMessage,
        duration: this.TOAST_DURATION
      })
    }
  }
  async handleClickedCandidate({
    event,
    jsEvent,
    el
  }: {
    event: FullCalendarEvent
    jsEvent: MouseEvent
    el: HTMLElement
  }) {
    await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_CALENDAR, { id: this.scheduleId })
    this.updateConfirmEventByMouseEvent(this.editingSchedule.duration, event, jsEvent, el)
  }
  getCandidateByStartAndEnd(start: Date, end: Date) {
    return inConfirmationPage.getCandidateByStartAndEnd(this.editingSchedule.candidates, start, end)
  }
  async handleUpdateEvent({ event, revert }: { event: FullCalendarEvent; revert: Function }) {
    if (!this.eventToConfirm) {
      return
    }
    const candidate = this.getCandidateByStartAndEnd(event.start, event.end)
    if (!candidate) {
      return revert()
    }
    await this.$analytics.send(SignalType.CLICK_CANDIDATE_ON_CALENDAR, { id: this.scheduleId })
    this.updateConfirmEvent(
      event.start,
      event.end,
      candidate.id,
      event.extendedProps.accountId,
      event.extendedProps.calendarId
    )
  }

  checkStatusAndGoToSuggest() {
    if (!this.isOrganizer && this.editingSchedule && this.editingSchedule.status === 'suggestedByConfirmer') {
      this.$buefy.toast.open({
        type: 'is-success',
        position: 'is-top',
        message: this.$t('alternativeCandidates.inRequestingMessage').toString()
      })
      this.$router.push({ name: 'SuggestAlternativeDate', params: { id: this.editingSchedule.id } })
    }
  }
  handleDeleteConfirmer() {
    EventToConfirmModule.resetEventToConfirm()
  }
  async handleEventClick(payload: { start: Date; end: Date; candidateId: string }) {
    await this.updateConfirmEvent(
      payload.start,
      payload.end,
      payload.candidateId,
      this.editingSchedule.accountId,
      this.editingSchedule.calendarId
    )
    this.onScheduleConfirmButtonClick()
  }
  async handleEventMouseEnter(payload: FullCalendarMouseEvent) {
    this.handleEventHover(this.editingSchedule.duration, payload)
  }
  handleEventMouseLeave(payload: FullCalendarMouseEvent) {
    this.handleMouseLeave(payload)
  }
  async handleEventHoverOnPanel(payload) {
    await this.updateConfirmEvent(
      payload.start,
      payload.end,
      payload.candidateId,
      this.editingSchedule.accountId,
      this.editingSchedule.calendarId
    )
  }
}
