






import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from '@/components/newDisplay/molecules/Item.vue'
import CollapseText from './atoms/CollapseText.vue'

@Component({
  components: {
    DisplayItem,
    CollapseText
  }
})
export default class ConfirmDescription extends Vue {
  @Prop({ required: true }) text: string
}
