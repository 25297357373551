
































import FormAttendingEmails from '@/components/ui/domain/item/FormAttendingEmails.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import { defineComponent } from '@vue/composition-api'
import CommonBasicInfoSection from './_CommonBasicInfoSection.vue'
import FormNotificationSettingsCollapseButtonItem from '@/components/ui/domain/item/FormNotificationSettingsCollapseButtonItem.vue'
import FormConfirmationEmailTargetsItem from '@/components/ui/domain/item/FormConfirmationEmailTargetsItem/index.vue'
import FormOrganizerMemberDropdownItem from '@/components/ui/domain/item/FormOrganizerMemberDropdownItem.vue'
export default defineComponent({
  components: {
    CommonBasicInfoSection,
    ListItemSpacer,
    FormAttendingEmails,
    FormNotificationSettingsCollapseButtonItem,
    FormConfirmationEmailTargetsItem,
    FormOrganizerMemberDropdownItem
  },
  props: {
    basicInfo: {
      type: Object,
      required: true
    }
  }
})
