














import { Component, Prop, Vue } from 'vue-property-decorator'
import TimezoneLabel from '@/components/molecules/TimezoneLabel.vue'
import TimezoneModule from '@/store/modules/timezones'

@Component({
  components: {
    TimezoneLabel
  }
})
export default class ShowConfirmedDate extends Vue {
  @Prop() subTitle: string
  @Prop() formattedDate: string

  get userTimezoneInfo() {
    return TimezoneModule.userTimezoneInfo
  }
}
