import { UserInfoModel } from '../userInfo'
import { GuestConfirmer } from './guestUser'

export class SpirUserConfirmer {
  readonly type: 'user' = 'user'
  readonly email: string
  readonly timeZone: string
  private readonly user: UserInfoModel

  constructor({ email, user }: { email: string; user: UserInfoModel }) {
    this.email = email
    this.user = user
    this.timeZone = user.timeZones.primary.key
  }

  get userId() {
    return this.user.id
  }

  get language() {
    return this.user.language
  }

  getEmail() {
    return this.email
  }

  getId() {
    return this.userId
  }
}

export type Confirmer = SpirUserConfirmer | GuestConfirmer
