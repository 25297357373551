import {
  ArrangementTypeFormData,
  getEmptyCommonFormData,
  OverwritingData,
  TitleSuggestionSetups
} from '@/components/ui/domain/sections/arrangement/composables/types'
import { usePersonalPollFormData } from '@/components/ui/domain/sections/arrangement/composables/usePersonalPollFormData'
import { usePersonalScheduleFormData } from '@/components/ui/domain/sections/arrangement/composables/usePersonalScheduleFormData'
import { useTeamScheduleFormsData } from '@/components/ui/domain/sections/arrangement/composables/useTeamScheduleFormsData'
import { usePeekConditionAndFetchCandidates } from '@/composables/peekCandidates/usePeekConditionAndFetchCandidates'
import { useModal } from '@/composables/useModal'
import { useNextTimeCheckModal } from '@/composables/useNextTimeCheckModal'
import { TeamOverview } from '@/composables/usePersonalTeamSelectDropdownSetup'
import { genNewCandidateFromTempEvent } from '@/lib/utils/candidate'
import EditPollModule from '@/store/modules/editPoll'
import EditScheduleModule from '@/store/modules/editSchedule'
import { computed, ref } from '@vue/composition-api'
import {
  getOverwriteData,
  initEditScheduleTeamModule,
  initPersonalEditingModule,
  initPersonalPollModule,
  isArrangementTypeFormDataAndNotInitializing,
  selectInitialFormRequest
} from './logics'
import {
  ArrangementFormRequest,
  ArrangementFormStateTypes,
  ArrangementPeekCandidatesInitializingWay,
  CurrentAllData
} from './types'

type Props = {
  formRequest: ArrangementFormRequest
  setLastFormRequest: (formRequest: ArrangementFormRequest) => void
  canShowAutoCandidateInfo: boolean
  saveAutoPeekCandidatesInfoNextTimeCheck: (doNotShowNextTime: boolean) => void
  getMyTeams: () => TeamOverview[]
  candidatesInitializingWay: ArrangementPeekCandidatesInitializingWay
  suggestionSetups: TitleSuggestionSetups
  goToNext?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  cancelEditing?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  openCalendar?: () => void
}
export const useNewArrangementsFormData = ({
  formRequest = { type: 'personalSchedule' },
  setLastFormRequest,
  canShowAutoCandidateInfo,
  saveAutoPeekCandidatesInfoNextTimeCheck,
  getMyTeams,
  candidatesInitializingWay,
  suggestionSetups,
  goToNext,
  cancelEditing,
  openCalendar
}: Props) => {
  const { openAutoPeekCandidatesInfoModal } = useModal()
  const selectedInitialFormRequest = selectInitialFormRequest(formRequest, getMyTeams)
  const currentFormRequest = ref<ArrangementFormRequest>(selectedInitialFormRequest)
  initPersonalEditingModule()
  initPersonalPollModule()
  const currentAllData = ref<CurrentAllData>(getEmptyCommonFormData)
  const { updatePersonalScheduleFormData, getPersonalScheduleFormData } = usePersonalScheduleFormData({
    scheduleModel: EditScheduleModule.editingSchedule,
    titleSuggestions: suggestionSetups.personalScheduleSuggestionSetup.suggestions,
    saveTitleSuggestion: suggestionSetups.personalScheduleSuggestionSetup.save,
    goToNext,
    cancelEditing,
    openCalendar
  })
  const { isLoading, getConditionAndFetchCandidates } = usePeekConditionAndFetchCandidates()
  const { openNextTimeCheckModalIfNeeded } = useNextTimeCheckModal({
    isModalNeeded: candidatesInitializingWay.type === 'from_auto',
    openModal: openAutoPeekCandidatesInfoModal,
    storage: { canShow: canShowAutoCandidateInfo, save: saveAutoPeekCandidatesInfoNextTimeCheck }
  })
  const { getTeam, clearTeamsData } = useTeamScheduleFormsData(
    initEditScheduleTeamModule,
    suggestionSetups.getTeamScheduleSuggestionSetup,
    goToNext,
    cancelEditing,
    openCalendar
  )
  const { updatePersonalPollFormData, getPersonalPollFormData } = usePersonalPollFormData({
    pollModel: EditPollModule.editingPoll,
    titleSuggestions: suggestionSetups.personalPollSuggestionSetup.suggestions,
    saveTitleSuggestion: suggestionSetups.personalPollSuggestionSetup.save,
    goToNext,
    cancelEditing,
    openCalendar
  })

  async function selectFormDataFunctions(formRequest: ArrangementFormRequest): Promise<{
    updateAllData
    getAllData
  }> {
    if (formRequest.type === ArrangementFormStateTypes.personalSchedule) {
      return {
        updateAllData: updatePersonalScheduleFormData,
        getAllData: getPersonalScheduleFormData
      }
    } else if (formRequest.type === ArrangementFormStateTypes.teamSchedule) {
      const team = await getTeam(formRequest.id)
      return {
        updateAllData: team.updateTeamScheduleFormData,
        getAllData: team.getTeamScheduleFormData
      }
    } else if (formRequest.type === ArrangementFormStateTypes.personalPoll) {
      return {
        updateAllData: updatePersonalPollFormData,
        getAllData: getPersonalPollFormData
      }
    } else {
      throw new Error('You must not reach here')
    }
  }

  function getFormDataFunctions(): { getFormRequest; getAllData } {
    return {
      getFormRequest: () => {
        return currentFormRequest.value
      },
      getAllData: () => {
        return currentAllData.value()
      }
    }
  }
  function clearAllData() {
    const overwrite: OverwritingData = {
      title: '',
      description: '',
      location: '',
      candidates: []
    }
    updatePersonalScheduleFormData(overwrite)
    updatePersonalPollFormData(overwrite)
    clearTeamsData(overwrite)
  }
  async function getCandidatesAndSetToForm(formData: ArrangementTypeFormData) {
    await openNextTimeCheckModalIfNeeded()
    await getConditionAndFetchCandidates(formData.basicInfo.duration.value, formData.peekCandidatesForm.fetchCandidates)
    await formData.peekCandidatesForm.applyAutoPeekCandidates()
  }
  async function changeFormRequest(formRequest: ArrangementFormRequest) {
    const formData = currentAllData.value()
    formData.clearDirty()
    setLastFormRequest(formRequest)
    const overwrite = getOverwriteData({
      from: currentFormRequest.value,
      getCurrentAllData: currentAllData.value,
      to: formRequest.type
    })
    const formDataFunctions = await selectFormDataFunctions(formRequest)
    formDataFunctions.updateAllData(overwrite)
    currentFormRequest.value = formRequest
    currentAllData.value = formDataFunctions.getAllData
  }
  async function initSideFormData(formRequest: ArrangementFormRequest) {
    await changeFormRequest(formRequest)
    const formData = currentAllData.value()
    if (!isArrangementTypeFormDataAndNotInitializing(formData)) {
      return
    }
    switch (candidatesInitializingWay.type) {
      case 'from_dragged_event': {
        const { start, end } = genNewCandidateFromTempEvent({
          event: candidatesInitializingWay.event,
          duration: formData.basicInfo.duration.value
        })
        await formData.candidatesInfo.addNewCandidate({ start, end }, null)
        formData.clearDirty()
        break
      }
      case 'from_auto':
        await getCandidatesAndSetToForm(formData)
        formData.clearDirty()
        break
      default:
        formData.clearDirty()
    }
  }
  initSideFormData(currentFormRequest.value)
  return {
    isLoading,
    isCandidatesLoading: computed(() => currentAllData.value().peekCandidatesForm.isCandidateLoading),
    changeFormRequest,
    getFormDataFunctions,
    clearAllData
  }
}
