


















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CalendarItem',
  props: {
    identification: { type: String, required: true },
    title: { type: String, required: true },
    backgroundColor: { type: String, default: '' }
  }
})
