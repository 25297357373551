











import { Component, Prop, Vue } from 'vue-property-decorator'
import copy from 'clipboard-copy'
import { isIOS } from '@/lib/utils'

@Component
export default class DescriptionForInAppBrowser extends Vue {
  @Prop() descriptionKey: string
  @Prop({ default: '' }) boldDescriptionPart: string

  copyUrl() {
    copy(location.href)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }

  // AndroidのWebViewでクリップボードcopyが権限エラーで動かないことに対応して
  get isIOS() {
    return isIOS()
  }
}
