








import { useTranslation } from '@/composables/useTranslation'
import { defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { FormStateChangeAlertType } from './type'

export default defineComponent({
  name: 'FormStateChangeAlert',
  props: {
    alertType: {
      type: String as PropType<FormStateChangeAlertType>,
      default: 'none'
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { alertType } = toRefs(props)
    const open = ref<boolean>(false)
    const title = ref<string>('')
    const getTitle = (): string => {
      switch (props.alertType) {
        case 'dragToAddCandidates':
          return i18n.t('message.dragToAddCandidates').toString()
        case 'tapToCheckEvents':
          return i18n.t('message.tapToCheckEvents').toString()
        case 'cancelToCheckEvents':
          return i18n.t('message.cancelToCheckEvents').toString()
        default:
          return ''
      }
    }

    watch(alertType, (newType) => {
      if (newType !== 'none') {
        title.value = getTitle()
        if (!open.value) {
          open.value = true
        }
        setTimeout(() => {
          open.value = false
        }, 1200)
      }
    })
    return {
      open,
      title
    }
  }
})
