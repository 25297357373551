













import { defineComponent, computed } from '@vue/composition-api'
import FullPageLayout from '@/components/layout/FullPage.vue'
import queryParams from '@/lib/queryParams'
import UserModule from '@/store/modules/user'
import { useRoute } from 'vue2-helpers/vue-router'
import { useTranslation } from '@/composables/useTranslation'

export default defineComponent({
  components: {
    FullPageLayout
  },
  setup() {
    const route = useRoute()
    const i18n = useTranslation()
    const notFoundType = computed(() => {
      const queryPage = route.query.page
      switch (queryPage) {
        case queryParams.QUERY_NOT_FOUND_AVAILABILITY:
          return 'availabilitySharing'
        default:
          return 'default'
      }
    })
    return {
      title: computed(() => i18n.t(`notFound.${notFoundType.value}.title`)),
      desc: computed(() => i18n.t(`notFound.${notFoundType.value}.desc`)),
      isSignIn: computed(() => UserModule.isSignIn)
    }
  }
})
