











import { Component, Prop, Vue } from 'vue-property-decorator'
import { Editor, EditorContent } from 'tiptap'
import { Link } from 'tiptap-extensions'
import { text2link } from '@/lib/utils'
import MapOpenButton from '@/components/atoms/MapOpenButton.vue'

@Component({
  components: {
    EditorContent,
    MapOpenButton
  }
})
export default class LocationRead extends Vue {
  @Prop() location: string
  @Prop({ default: 'text' }) mapOpenButtonType: 'text' | 'icon'

  get isMapOpenButtonTextType(): boolean {
    return this.mapOpenButtonType === 'text'
  }

  get showMapBtn() {
    return this.formattedSelectedLocationEditor.getHTML().indexOf('<a') === -1
  }

  get formattedSelectedLocationEditor() {
    return new Editor({
      content: text2link(this.location),
      extensions: [new Link()],
      editable: false
    })
  }
}
