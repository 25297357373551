
























import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarMenu from '@/components/calendarList/CalendarMenu.vue'
import GuestAsideHeader from '@/components/calendarList/organisms/GuestAsideHeader.vue'
import CalendarSyncInfo from '@/components/molecules/CalendarSyncInfo.vue'
import AppScreenModule from '@/store/modules/appScreenControl'

@Component({
  components: {
    CalendarMenu,
    GuestAsideHeader,
    CalendarSyncInfo
  }
})
export default class CalendarListIndex extends Vue {
  @Prop({ default: false }) isPreview: boolean

  get isSignIn() {
    return this.$store.getters['User/isSignIn']
  }
  hideList() {
    AppScreenModule.SET_SHOW_CALENDAR_LIST(false)
  }
}
