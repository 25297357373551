












import { defineComponent } from '@vue/composition-api'
import Button from '@/components/ui/Button.vue'

export default defineComponent({
  components: {
    Button
  }
})
