














import { Component, Vue } from 'vue-property-decorator'
import ConfirmCommon from '@/views/calendar/schedule/ConfirmCommon.vue'
import EditScheduleTeamModule from '@/store/modules/editScheduleTeam'
import { Confirmer } from '@/models/data'

@Component({
  components: {
    ConfirmCommon
  }
})
export default class TeamScheduleConfirm extends Vue {
  get targetModel() {
    return EditScheduleTeamModule.editingSchedule
  }
  get getEditingEventByCalendarFormat() {
    return this.targetModel.getEditingEventByCalendarFormat
  }
  get organizer() {
    return this.targetModel.organizerInfo
  }
  get isOrganizer() {
    return this.targetModel.amIOrganizer
  }
  get isLoading() {
    return EditScheduleTeamModule.isLoading
  }
  get isSyncing() {
    return !EditScheduleTeamModule.areAllEventsSynced
  }
  handleConfirmSchedule(option: {
    candidateId: string
    start: Date
    attendees: { name: string; email: string }[]
    confirmer: Confirmer
  }) {
    return EditScheduleTeamModule.confirm(option)
  }
}
