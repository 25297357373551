






import { Component, Prop, Vue } from 'vue-property-decorator'
import LinkMessage from '@/components/atoms/LinkMessage.vue'

@Component({
  components: { LinkMessage }
})
export default class ChangeAuthRoute extends Vue {
  @Prop() desc
  @Prop() linkName
}
