import { DayOfTheWeekRules } from '@spirinc/contracts'
import { DisplayWeekDay } from '@/models/data'
import { reactive, ref, computed } from '@vue/composition-api'
import { startOfDay, addDays, startOfWeekByUserStartWeekday } from '@/lib/utils/timezone'
import { weekDays } from '@/lib/utils/calendars'
import CalendarControl from '@/store/modules/calendarControl'
import { parseFromTime, PickDayOfWeekDefaultEvent } from '@/lib/utils/peekCandidates'

export type FormWeekdayConditionItemSetup = {
  value: DayOfTheWeekRules
  displayWeekday: DisplayWeekDay[]
  handleUpdate: (newRules: DayOfTheWeekRules) => void
}
export const useFormWeekdayConditionItem = (initial?: DayOfTheWeekRules): FormWeekdayConditionItemSetup => {
  const dayOfTheWeekRules = ref(initial || {})
  return reactive({
    value: dayOfTheWeekRules,
    displayWeekday: computed(() => {
      const startDay = startOfWeekByUserStartWeekday(new Date())
      return weekDays(CalendarControl.startWeek).map((weekday, i) => {
        const currentDate = addDays(startOfDay(startDay), i)
        return {
          key: weekday,
          check: dayOfTheWeekRules.value[weekday]?.rules?.length > 0,
          timesEvents:
            dayOfTheWeekRules.value[weekday]?.rules?.map((rule) => {
              return PickDayOfWeekDefaultEvent(
                parseFromTime(rule.start, currentDate),
                parseFromTime(rule.end, currentDate),
                weekday
              )
            }) || []
        }
      })
    }),
    handleUpdate: (newRules: DayOfTheWeekRules) => {
      dayOfTheWeekRules.value = newRules
    }
  })
}
