





import { defineComponent } from '@vue/composition-api'
import SpirCheckbox from '@/components/ui/spir/SpirCheckbox.vue'

export default defineComponent({
  name: 'FormDurationSelectBoxItem',
  components: {
    SpirCheckbox
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function
    },
    label: {
      type: String
    }
  }
})
