










import AvailabilitySharingEditPage from '@/components/page/availabilitySharing/AvailabilitySharingEditPage.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useModal } from '@/composables/useModal'
import queryParams from '@/lib/queryParams'
import { GenAvailabilitySharingTitleSuggestionSetup } from '@/lib/utils'
import { AllRouteNames } from '@/router'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import { computed, defineComponent } from '@vue/composition-api'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

export default defineComponent({
  components: {
    AvailabilitySharingEditPage
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const teamId = computed(() => route.params.id)
    const availabilitySharingId = computed(() => route.params.availabilitySharingId)
    const { openDiscardConfirmationModal } = useModal()
    const { isEditingObjectDirty } = useAvailabilitySharingForm()
    const setups = GenAvailabilitySharingTitleSuggestionSetup.genSuggestionSetups()

    function goListPage() {
      router
        .push({ name: AllRouteNames.AvailabilityList, query: { [queryParams.TEAM_ID]: teamId.value } })
        .catch(GuardExceptionHandlers.noopAgainstAbortion)
    }

    function guard(next) {
      if (isEditingObjectDirty.value) {
        openDiscardConfirmationModal({
          confirm: () => next(),
          cancel: () => next(false)
        })
      } else {
        next()
      }
    }

    return {
      setup: setups.getTeamSetup(teamId.value),
      teamId,
      availabilitySharingId,
      goListPage,
      guard
    }
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next)
  }
})
