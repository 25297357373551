

















import { useAnalytics } from '@/composables/useAnalytics'
import { SignalType } from '@/lib/analytics'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

const type = ['basic', 'primary', 'danger', 'text'] as const
export type ButtonType = typeof type[number]

const size = ['small', 'medium'] as const
export type ButtonSize = typeof size[number]

const icon = ['plus', 'eye', 'delete', 'close', 'setting', 'up', 'down'] as const
export type ButtonIcon = typeof icon[number]

export type Analytics = {
  signal: SignalType
  payload?: { [key: string]: string | number | boolean }
}

/**
 * https://www.figma.com/file/dNOPuiscfO6H4VEfKJ7wvo/Spir-Components?node-id=172%3A0
 */
export default defineComponent({
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'basic'
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'medium'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String as PropType<ButtonIcon | undefined>
    },
    expanded: {
      type: Boolean,
      default: false
    },
    analytics: {
      type: [String, Object] as PropType<SignalType | Analytics>
    },
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  setup(props, context) {
    const { type, size, analytics } = toRefs(props)
    const analyticsSender = useAnalytics()

    const buefyType = computed(() => {
      switch (type.value) {
        case 'basic':
          return ''
        case 'primary':
          return 'is-primary'
        case 'danger':
          return 'is-danger'
        case 'text':
          return 'is-text'
      }
    })
    const buefySize = computed(() => {
      switch (size.value) {
        case 'small':
          return 'is-small'
        case 'medium':
          return ''
      }
    })
    const buefyIconLeft = computed(() => {
      switch (props.icon) {
        case 'plus':
          return 'plus'
        case 'eye':
          return 'eye'
        case 'delete':
          return 'delete'
        case 'close':
          return 'close'
        case 'setting':
          return 'cog'
        case 'up':
          return 'chevron-up'
        case 'down':
          return 'chevron-down'
        default:
          return ''
      }
    })
    const buefyOutlined = computed(() => {
      return props.type === 'danger'
    })

    const handleClick = (event) => {
      if (typeof analytics.value === 'string') {
        analyticsSender.send(analytics.value)
      } else if (typeof analytics.value === 'object') {
        analyticsSender.send(analytics.value.signal, analytics.value.payload)
      }
      context.emit('click', event)
    }

    return {
      buefyType,
      buefySize,
      buefyIconLeft,
      buefyOutlined,
      handleClick
    }
  }
})
