import firebase from 'firebase/app'
import { AuthErrorTypes } from '@/types/authError'
import { useAuthSessionStorage } from './useAuthSessionStorage'

export type SignInSuccess = {
  result: 'success' | 'cancel'
}
export type SignInErrors = {
  result: 'error'
  errorCode: AuthErrorTypes
}

export type SignInResult = Promise<SignInSuccess | SignInErrors>

export const useAuthSignIn = () => {
  const { setSession } = useAuthSessionStorage()
  const authWithMicrosoft = async () => {
    const msProvider = new firebase.auth.OAuthProvider('microsoft.com')
    msProvider.setCustomParameters({
      prompt: 'select_account'
    })
    return firebase.auth().signInWithRedirect(msProvider)
  }
  const authWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    googleProvider.setCustomParameters({
      prompt: 'select_account'
    })
    return firebase.auth().signInWithRedirect(googleProvider)
  }
  const signInWithGoogle = () => {
    setSession({ action: 'SignIn', type: 'Google' })
    return authWithGoogle()
  }
  const signInWithMicrosoft = () => {
    setSession({ action: 'SignIn', type: 'Microsoft' })
    return authWithMicrosoft()
  }
  return {
    signInWithMicrosoft,
    signInWithGoogle
  }
}
