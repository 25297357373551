









import { Component, Prop, Vue } from 'vue-property-decorator'
import { CalendarType } from '@/types'
@Component
export default class DailyViewCalendarHeader extends Vue {
  @Prop() title: string
  @Prop() type: CalendarType // todo
  @Prop({ default: false }) hasEvent: boolean
  get icon() {
    if (this.type === 'normal') {
      return 'account-outline'
    }
    return 'office-building'
  }
}
