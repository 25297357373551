


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { CandidateForVote, IVote } from '@/store/modules/vote'
import ConfirmTimeRangeTitle from '../atoms/ConfirmTimeRangeTitle.vue'
import YesOrNoSwitch from '@/components/groupPoll/YesOrNoSwitch.vue'
import VoteStatus from '@/components/groupPoll/VoteStatus.vue'
import { PollAnswer } from '@/types'

@Component({
  components: {
    ConfirmTimeRangeTitle,
    YesOrNoSwitch,
    VoteStatus
  }
})
export default class PollVoteCandidateDateBox extends Vue {
  @Prop({ required: true }) candidate: CandidateForVote
  @Prop({ required: true }) vote: IVote
  @Prop() timeZone: string
  @Prop({ default: 0 }) maxYesCount: number
  @Prop({ default: false }) isSyncing: boolean
  @Prop({ required: true }) onAnswerUpdate: ({ candidateId: string, answer: PollAnswer }) => Promise<void>

  get isDisabled() {
    return this.isSyncing || this.candidate.isDisabled
  }

  get isMaxYesReached() {
    if (this.maxYesCount === 0) return false
    return this.candidate.yesCount >= this.maxYesCount
  }

  async handleAnswerUpdate(newAnswer: PollAnswer) {
    await this.onAnswerUpdate({ candidateId: this.candidate.id, answer: newAnswer })
  }
}
