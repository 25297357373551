







import { defineComponent, PropType } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import OnlineMeetingDropdown from './_OnlineMeetingDropdown/index.vue'
import { OnlineMeetingTool } from './onlineMeeting'

export default defineComponent({
  name: 'FormOnlineMeetingDropdownItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    OnlineMeetingDropdown
  },
  props: {
    value: {
      type: String,
      required: true
    },
    onlineMeetingTools: {
      type: Array as PropType<OnlineMeetingTool[]>,
      required: true
    },
    onChange: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  }
})
