
















import CopyBoxBodyFrame from '@/components/copyToShare/copyBoxParts/CopyBoxBodyFrame.vue'
import CopyBoxHeader from '@/components/copyToShare/copyBoxParts/CopyBoxHeader.vue'
import CopyBoxLinkText from '@/components/copyToShare/copyBoxParts/CopyBoxLinkText.vue'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { copyClipboard } from '@/lib/utils/functions'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxForUrl',
  components: {
    CopyBoxHeader,
    CopyBoxBodyFrame,
    CopyBoxLinkText
  },
  props: {
    boxHeaderTitle: {
      type: String,
      required: true
    },
    buttonTitle: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    onAnalyticsSend: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { openSuccessTopToast } = useToast()
    async function copyURLText() {
      await props.onAnalyticsSend()
      copyClipboard(props.url)
      openSuccessTopToast({
        message: i18n.t('message.copiedToClipBoard').toString()
      })
    }
    return {
      copyURLText
    }
  }
})
