import { useTranslation } from '@/composables/useTranslation'
import { adjustDurationUnit } from '@/lib/utils'
import { AVAILABLE_DURATIONS } from '@/lib/utils/durations'
import { endSelects, startSelects } from '@/lib/utils/peekCandidates'
import ProfileModule from '@/store/modules/profile'
import { EditablePickCandidatesCondition } from '@/types/peekCandidates'
import { Country as CountryCode, PeekCandidatesPostRequest } from '@spirinc/contracts'
import { computed, reactive, Ref, ref } from '@vue/composition-api'
import {
  FormCalendarsAutoCompleteItemSetup,
  useFormCalendarsAutoCompleteItem
} from './_useFormCalendarsAutoCompleteItem'
import { FormWeekdayConditionItemSetup, useFormWeekdayConditionItem } from './_useFormWeekdayConditionItem'

export type AllPeekCandidatesConditionForms = {
  updateForms: Function
  calendarsAutoCompleteForm: FormCalendarsAutoCompleteItemSetup
  weekdayForm: FormWeekdayConditionItemSetup
  durationForm: FormDurationItemSetup
  startForm: FormStartItemSetup
  endForm: FormEndItemSetup
  timeBufferForm: FormTimeBufferItemSetup
  timezoneForm: FormTimezoneItemSetup
  countriesForm: FormCountryHolidaysItemSetup
  allConditions: {
    duration: FormDurationItemSetup['value']
    start: FormStartItemSetup['value']
    end: FormEndItemSetup['value']
    dayOfTheWeekRules: FormWeekdayConditionItemSetup['value']
    calendars: PeekCandidatesPostRequest['calendars']
    timeBuffer: FormTimeBufferItemSetup['value']
    timeZone: FormTimezoneItemSetup['value']
    countries: FormCountryHolidaysItemSetup['value']
    excludeScheduleCandidates: false
    excludeGroupPollCandidates: false
    excludeAllDays: false
  }
}

export type FormDurationItemSetup = {
  value: PeekCandidatesPostRequest['duration']
  items: Array<{ key: number; label: string }>
  handleInput: (newDuration: PeekCandidatesPostRequest['duration']) => void
}
export const useFormDurationItem = (
  initialValue: PeekCandidatesPostRequest['duration'] = 60
): FormDurationItemSetup => {
  const i18n = useTranslation()
  const duration = ref(initialValue)
  return reactive({
    value: duration,
    items: AVAILABLE_DURATIONS.map((n) => {
      const displayDuration = adjustDurationUnit(n)
      if (displayDuration.type === 'hour') {
        return {
          label: i18n.t('forms.event.durationHour', { hour: displayDuration.duration }).toString(),
          key: n
        }
      } else {
        return {
          label: i18n.t('forms.event.duration', { min: displayDuration.duration }).toString(),
          key: n
        }
      }
    }),
    handleInput: (newDuration: PeekCandidatesPostRequest['duration']) => (duration.value = newDuration)
  })
}

export type FormStartItemSetup = {
  value: PeekCandidatesPostRequest['start']
  items: Array<{ key: string; label: string }>
  handleInput: (newDuration: PeekCandidatesPostRequest['start']) => void
}
export const useFormStartItem = (initial: PeekCandidatesPostRequest['start'] = 'tomorrow'): FormStartItemSetup => {
  const i18n = useTranslation()
  const startValue = ref(initial)
  return reactive({
    value: startValue,
    items: startSelects.map((sKey) => ({
      key: sKey,
      label: i18n.t(`peekCandidates.dateOptions.${sKey}`).toString()
    })),
    handleInput: (newValue: PeekCandidatesPostRequest['start']) => (startValue.value = newValue)
  })
}
export type FormEndItemSetup = {
  value: PeekCandidatesPostRequest['end']
  items: Array<{ key: string; label: string }>
  handleInput: (newDuration: PeekCandidatesPostRequest['end']) => void
}
export const useFormEndItem = (initial: PeekCandidatesPostRequest['end'] = 'two_weeks'): FormEndItemSetup => {
  const i18n = useTranslation()
  const startValue = ref(initial || 'two_weeks')
  return reactive({
    value: startValue,
    items: endSelects.map((sKey) => ({
      key: sKey,
      label: i18n.t(`peekCandidates.dateOptions.${sKey}`).toString()
    })),
    handleInput: (newValue: string) => (startValue.value = newValue)
  })
}
export type FormTimeBufferItemSetup = {
  value: PeekCandidatesPostRequest['timeBuffer']
  items: Array<{ key: PeekCandidatesPostRequest['timeBuffer']; label: string }>
  handleInput: (newTimeBuffer: PeekCandidatesPostRequest['timeBuffer']) => void
}
export const useFormTimeBufferItem = (
  initial: PeekCandidatesPostRequest['timeBuffer'] = 0
): FormTimeBufferItemSetup => {
  const i18n = useTranslation()
  const timeBuffer = ref(initial)
  return reactive({
    value: timeBuffer,
    items: [0, 15, 30, 45, 60, 90, 120].map((sKey) => ({
      key: sKey as PeekCandidatesPostRequest['timeBuffer'],
      label: i18n.t('forms.event.duration', { min: sKey }).toString()
    })),
    handleInput: (newTimeBuffer: PeekCandidatesPostRequest['timeBuffer']) => {
      timeBuffer.value = newTimeBuffer as PeekCandidatesPostRequest['timeBuffer']
    }
  })
}
export type FormTimezoneItemSetup = {
  value: string
  handleInput: (newTimezoneKey: string) => void
}
export const useFormTimezoneItem = (initial?: string): FormTimezoneItemSetup => {
  const timezoneKey = ref(initial || ProfileModule.primaryTimezoneKey)
  return reactive({
    value: timezoneKey,
    handleInput: (newTimezoneKey: string) => (timezoneKey.value = newTimezoneKey)
  })
}
export type FormCountryHolidaysItemSetup = {
  value: CountryCode[]
  handleCountryCodeAdd: (newCode: CountryCode['code']) => void
  handleCountryCodeRemove: (code: CountryCode['code']) => void
}
export const useFormCountryHolidaysItem = (initial?: CountryCode[]): FormCountryHolidaysItemSetup => {
  const holidayKeys: Ref<CountryCode[]> = ref(initial || [])
  return reactive({
    value: holidayKeys,
    handleCountryCodeAdd: (newCode: CountryCode['code']) => {
      holidayKeys.value = [...holidayKeys.value, { code: newCode }]
    },
    handleCountryCodeRemove: (code: CountryCode['code']) =>
      (holidayKeys.value = holidayKeys.value.filter((k) => k.code !== code))
  })
}

export const usePeekCandidatesConditionForm = () => {
  const calendarsAutoCompleteForm = useFormCalendarsAutoCompleteItem()
  const weekdayForm = useFormWeekdayConditionItem()
  const durationForm = useFormDurationItem()
  const startForm = useFormStartItem()
  const endForm = useFormEndItem()
  const timeBufferForm = useFormTimeBufferItem()
  const timezoneForm = useFormTimezoneItem()
  const countriesForm = useFormCountryHolidaysItem()
  const updateForms = (newValues: EditablePickCandidatesCondition) => {
    weekdayForm.value = newValues.dayOfTheWeekRules
    startForm.value = newValues.start
    endForm.value = newValues.end
    timeBufferForm.value = newValues.timeBuffer
    timezoneForm.value = newValues.timeZone
    countriesForm.value = newValues.countries
  }
  const allConditions = computed((): AllPeekCandidatesConditionForms['allConditions'] => {
    return {
      duration: durationForm.value,
      start: startForm.value,
      end: endForm.value,
      dayOfTheWeekRules: weekdayForm.value,
      calendars: calendarsAutoCompleteForm.asParameter,
      timeBuffer: timeBufferForm.value,
      timeZone: timezoneForm.value,
      countries: countriesForm.value,
      excludeScheduleCandidates: false,
      excludeGroupPollCandidates: false,
      excludeAllDays: false
    }
  })
  return reactive({
    updateForms,
    calendarsAutoCompleteForm,
    weekdayForm,
    durationForm,
    startForm,
    endForm,
    timeBufferForm,
    timezoneForm,
    countriesForm,
    allConditions
  })
}
