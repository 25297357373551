import VueRouter from 'vue-router'
import { noop } from '@/lib/utils'

const { isNavigationFailure, NavigationFailureType } = VueRouter

export const GuardExceptionHandlers = {
  noopAgainstAbortion(failure) {
    if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
      noop()
    } else {
      throw failure
    }
  },
  noopAgainstRedirection(failure: any): void {
    if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
      noop()
    } else {
      throw failure
    }
  }
}
