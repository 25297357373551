








import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplayItem from '@/components/newDisplay/molecules/Item.vue'
import LocationRead from '@/components/molecules/LocationRead.vue'

@Component({
  components: {
    DisplayItem,
    LocationRead
  }
})
export default class ConfirmMap extends Vue {
  @Prop({ required: true }) location!: string
}
