import { ProfileOnOverview, SpirUser } from '@/types'

export const FromSpirUser = {
  convertToProfileOnOverview(spirUser: SpirUser, overrideOption?: Partial<ProfileOnOverview>): ProfileOnOverview {
    return {
      fullName: overrideOption?.fullName || spirUser.fullName || spirUser.name,
      photoURL: overrideOption?.photoURL || spirUser.photoURL,
      email: overrideOption?.email,
      userId: overrideOption?.userId || spirUser.id,
      isAuthor: overrideOption?.isAuthor,
      attendanceStatus: overrideOption?.attendanceStatus,
      isLoading: overrideOption?.isLoading || false
    }
  }
}
