




















import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmCandidateBlockDate from '../atoms/ConfirmCandidateBlockDate.vue'
import PollVoteCandidateDateBox from './PollVoteCandidateDateBox.vue'
import ConfirmCandidateDateUL from '../atoms/ConfirmCandidateDateUL.vue'
import ConfirmCandidateDateLI from '../atoms/ConfirmCandidateDateLI.vue'
import ConfirmCandidateDateRowUL from '../atoms/ConfirmCandidateDateRowUL.vue'
import ConfirmCandidateDateRowLI from '../atoms/ConfirmCandidateDateRowLI.vue'
import { CandidateForVote, IVote } from '@/store/modules/vote'
import { groupBy, sortBy } from 'lodash'
import { endOfDay } from '@/lib/utils/timezone'
import { PollAnswer } from '@/types'

type CandidateForVoteWithVote = CandidateForVote & {
  vote: IVote
}

@Component({
  components: {
    ConfirmCandidateBlockDate,
    PollVoteCandidateDateBox,
    ConfirmCandidateDateUL,
    ConfirmCandidateDateLI,
    ConfirmCandidateDateRowUL,
    ConfirmCandidateDateRowLI
  }
})
export default class PollVoteCandidateListBox extends Vue {
  @Prop() candidates: CandidateForVote[]
  @Prop() votes: IVote[]
  @Prop() timeZone: string
  @Prop({ default: 0 }) maxYesCount: number
  @Prop({ default: false }) isSyncing: boolean
  @Prop({ required: true }) onAnswerUpdate: ({ candidateId: string, answer: PollAnswer }) => Promise<void>

  get groupedCandidatesByDate(): Record<string, CandidateForVoteWithVote[]> {
    const sortedByDate = sortBy(this.candidates, (candidate) => candidate.start)
    const withVote = sortedByDate.map((candidate) => ({ ...candidate, vote: this.voteById(candidate.id) }))
    const grouped = groupBy(withVote, (candidate) => endOfDay(candidate.start).toISOString())
    return grouped
  }

  voteById(candidateId: string): IVote {
    return this.votes.find((v) => v.candidateId === candidateId)
  }
  async handleAnswerUpdate(data: { candidateId: string; answer: PollAnswer }) {
    await this.onAnswerUpdate(data)
  }
}
