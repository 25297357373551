



















import ButtonToWrapIcon from '@/components/atoms/ButtonToWrapIcon.vue'
import VoteStatus from '@/components/groupPoll/VoteStatus.vue'
import SpirButton from '@/components/ui/Button.vue'
import { defineComponent, PropType } from '@vue/composition-api'
import { VotingDatetimeSlot } from './datetimeSlot'
import DatetimeSlotEditor from './DatetimeSlotEditor.vue'

export default defineComponent({
  name: 'VotingDatetimeSlotListItem',
  components: {
    ButtonToWrapIcon,
    DatetimeSlotEditor,
    SpirButton,
    VoteStatus
  },
  props: {
    datetimeSlot: {
      type: Object as PropType<VotingDatetimeSlot>,
      required: true
    }
  }
})
