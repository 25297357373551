











import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'
import { defaultScheduleMenus, MenuInfo } from '@/lib/menus'
import { defineComponent, PropType } from '@vue/composition-api'
import EventSection from './sections/Event.vue'
import SchedulesSection from './sections/Schedules.vue'

export default defineComponent({
  name: 'CreateButtonBody',
  components: {
    SchedulesSection,
    EventSection
  },
  props: {
    isShownOnCalendar: {
      type: Boolean,
      default: false
    },
    scheduleMenus: {
      type: Array as PropType<MenuInfo[]>,
      default: () => defaultScheduleMenus
    }
  },
  setup(props) {
    const { sendSignal } = useSendSignal()
    return {
      handleClickRoute(routeName: string) {
        return sendSignal(SignalType.GO_FROM_MENU, {
          from: props.isShownOnCalendar ? 'calendar' : 'menu',
          to: routeName
        })
      }
    }
  }
})
