




import ConfirScheduleSuggestCandidatePage from '@/components/page/ScheduleConfirmPage/SuggestDate/index.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirScheduleSuggestCandidatePage
  }
})
