




















import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import { JoiningTeam } from '@spirinc/contracts'
import TeamInvitedSlideLayout from '@/components/teamSettings/slides/TeamInvitedSlideLayout.vue'
import Divider from '@/components/atoms/Divider.vue'

@Component({
  components: {
    Avatar,
    TeamInvitedSlideLayout,
    Divider
  }
})
export default class TeamInvitedSettingIntroSlide extends Vue {
  @Prop() team: JoiningTeam

  next() {
    this.$emit('next')
  }
}
