export class Polling {
  #id: ReturnType<typeof setTimeout> | null = null
  #interval: number
  #stopped = false

  constructor(interval = 2000) {
    this.#interval = interval
  }
  async start<T>(data: { doSomething: (params: T) => Promise<void>; getParams: () => Promise<T> }) {
    if (this.isStopped) return this.clearStopped()
    try {
      const params = await data.getParams()
      await data.doSomething(params)
    } catch (e) {
      this.cancel()
      throw e
    }
    if (this.isStopped) return this.clearStopped()
    await new Promise((resolve) => {
      this.#id = setTimeout(() => {
        resolve(true)
      }, this.#interval)
    })
    this.clearId()
    if (this.isStopped) return this.clearStopped()
    return await this.start(data)
  }

  private get isStopped(): boolean {
    return this.#stopped
  }
  private stop() {
    this.#stopped = true
  }
  private clearStopped() {
    this.#stopped = false
  }
  private clearId() {
    this.#id = null
  }
  private clearTimeout() {
    if (this.#id) {
      clearTimeout(this.#id)
      this.clearId()
    }
  }
  cancel() {
    this.stop()
    this.clearTimeout()
  }
  reset() {
    this.clearId()
    this.clearStopped()
  }
}
