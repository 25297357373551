







import { Component, Prop, Vue } from 'vue-property-decorator'
import { PollAnswer } from '@/types'

@Component
export default class VoteCountWithIcon extends Vue {
  @Prop() answerType: PollAnswer
  @Prop() count: number

  get currentObj() {
    const iconAndTitle = {
      ['yes']: {
        icon: 'check',
        title: this.$t('labels.vote.yes')
      },
      ['no']: {
        icon: 'block-helper',
        title: this.$t('labels.vote.no')
      }
    }
    return iconAndTitle[this.answerType]
  }
}
