import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { computed } from '@vue/composition-api'
import UserModule from '@/store/modules/user'
import EditAvailabilitySharingForm from '@/store/modules/editAvailabilitySharingForm'
import TempEventModule from '@/store/modules/tempEvent'
import { AvailabilityModel } from '@/models/data'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { FormMode, FormType } from '@/types/availabilitySharingForm'
import { useAnalytics } from './useAnalytics'
import { SignalType } from '@/lib/analytics'
import { hasIntersectedEvents } from '@/lib/utils'
import { useToast } from './useToast'
import { useTranslation } from './useTranslation'
import { DatetimeSlotUpdatePayload } from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'

export const useAvailabilitySharingForm = () => {
  const i18n = useTranslation()
  const analytics = useAnalytics()
  const { openDangerBottomToast } = useToast()

  const formMode = computed(() => EditAvailabilitySharingForm.formMode)
  const personalTeamValue = computed(() => EditAvailabilitySharingForm.personalTeamValue)
  const editingObject = computed(() => EditAvailabilitySharingForm.model)
  const editingObjectForPrivate = computed(() => EditAvailabilitySharingForm.modelForPrivate)
  const editingObjectForTeam = computed(() => EditAvailabilitySharingForm.modelForTeam)
  const editingObjectType = computed(() => EditAvailabilitySharingForm.type)
  const isEditingObjectDirty = computed(() => EditAvailabilitySharingForm.isDirty)
  const isLoading = computed(() => EditAvailabilitySharingForm.isLoading)

  const eventsFromEditingObject = computed(() => {
    const exceptionsWithDateFormat = editingObject.value?.exceptionsAsCalendarFormat || []
    const holidaysWithDateFormat = editingObject.value?.holidaysAsCalendarFormat || []
    const candidatesByMerged = EditAvailabilitySharingForm.candidatesByMerged
    const tempEvent = TempEventModule.getTempEvent ? [TempEventModule.getTempEvent] : []
    return [...tempEvent, ...exceptionsWithDateFormat, ...holidaysWithDateFormat, ...candidatesByMerged]
  })

  async function setupEditingObject({
    mode,
    type,
    availabilitySharingId,
    teamId
  }: {
    mode: FormMode
    type: FormType
    availabilitySharingId?: string
    teamId?: string
  }) {
    await UserModule.fetchIntegrations(true)
    if (mode === 'create') {
      await EditAvailabilitySharingForm.newAvailability({ type, teamId })
    } else if (mode === 'edit' && availabilitySharingId) {
      await EditAvailabilitySharingForm.fetchAvailability({ type, availabilitySharingId, teamId })
    } else {
      throw new Error(`Unexpected params. ${JSON.stringify({ mode, type, availabilitySharingId, teamId })}`)
    }
    await EditAvailabilitySharingForm.fetchCandidatesByParam()
  }

  function changeFormType({ type, teamId }: { type: FormType; teamId?: string }) {
    EditAvailabilitySharingForm.changeFormType({ type, teamId })
  }

  async function updateEditingObject(newEditingObject: AvailabilityModel) {
    EditAvailabilitySharingForm.updateModel(newEditingObject)
  }

  async function updateCondition(newEditingObject: AvailabilityModel) {
    await updateEditingObject(newEditingObject)
    await EditAvailabilitySharingForm.fetchCandidatesByParam()
  }

  async function checkIntersection<T extends { id?: string; start: Date; end: Date }>(
    event: T,
    onSuccess: (exception: T) => Promise<void>
  ) {
    const hasIntersections = hasIntersectedEvents(
      event,
      editingObject.value.exceptions.map((e) => ({ id: e.id, start: new Date(e.start), end: new Date(e.end) }))
    )
    if (hasIntersections) {
      openDangerBottomToast({ message: i18n.t('message.error.candidateError.intersection').toString() })
    } else {
      await onSuccess(event)
    }
  }

  async function addException(exception: { start: Date; end: Date; allDay?: boolean }) {
    await checkIntersection(exception, async (e) => {
      const clonedEditingObject = cloneDeep(editingObject.value)
      clonedEditingObject.exceptions.push({
        id: nanoid(),
        start: e.start.toISOString(),
        end: e.end.toISOString(),
        allDay: e.allDay,
        type: 'exclude'
      })
      await updateCondition(clonedEditingObject)
    })
  }

  async function updateException(payload: DatetimeSlotUpdatePayload) {
    await checkIntersection(payload, async (exception) => {
      const clone = cloneDeep(editingObject.value)
      const targetException = clone.exceptions.find((e) => e.id === exception.id)
      targetException.start = exception.start.toISOString()
      targetException.end = exception.end.toISOString()
      await updateCondition(clone)
    })
  }

  async function removeException({ id }: { id: string }) {
    const clonedEditingObject = cloneDeep(editingObject.value)
    const findIndex = clonedEditingObject.exceptions.findIndex((e) => e.id === id)
    if (findIndex >= 0) {
      clonedEditingObject.exceptions.splice(findIndex, 1)
      await updateCondition(clonedEditingObject)
    }
  }

  async function addHolidayException(countryCode: FrontSupportCountryCode) {
    const clonedEditingObject = cloneDeep(editingObject.value)
    clonedEditingObject.countries.push({ code: countryCode })
    await updateCondition(clonedEditingObject)
  }

  async function removeHolidayException(countryCode: FrontSupportCountryCode) {
    const { countries, holidays } = editingObject.value.getCountriesAndHolidaysRemoved(countryCode)
    const clonedEditingObject = cloneDeep(editingObject.value)
    clonedEditingObject.countries = countries
    clonedEditingObject.holidays = holidays
    await updateCondition(clonedEditingObject)
  }

  async function confirmEditingObject(mode: FormMode): Promise<string> {
    switch (mode) {
      case 'create': {
        const id = await EditAvailabilitySharingForm.create()
        analytics.send(SignalType.CREATE_PUBLICURL, { id, type: editingObjectType.value })
        return id
      }
      case 'edit': {
        const id = await EditAvailabilitySharingForm.update()
        analytics.send(SignalType.EDIT_PUBLICURL, { id, type: editingObjectType.value })
        return id
      }
    }
  }

  function resetEditingObject() {
    EditAvailabilitySharingForm.reset()
  }

  return {
    formMode,
    personalTeamValue,
    editingObject,
    editingObjectForPrivate,
    editingObjectForTeam,
    editingObjectType,
    isEditingObjectDirty,
    isLoading,
    eventsFromEditingObject,
    setupEditingObject,
    changeFormType,
    updateEditingObject,
    updateCondition,
    addException,
    updateException,
    removeException,
    addHolidayException,
    removeHolidayException,
    confirmEditingObject,
    resetEditingObject
  }
}
