import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { MemberCalendarRequest } from '@spirinc/contracts'
import { checkValidToken, getPublicTeam } from '@/lib/api/team'
import { acceptInvitation } from '@/lib/sdk/team'
import { PublicTeamDetailModel } from '@/models/data/team'
import store from '@/store'
import UserModule from '@/store/modules/user'
import ProfileModule from './profile'
import CalendarsModule from './calendars'

const MODULE_NAME = 'TeamInvited'

type TeamInvitedProps = {
  team: PublicTeamDetailModel | null
  isLoading: boolean
}

export interface IModuleTeamInvited {
  _invitedTeam: TeamInvited
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TeamInvited extends VuexModule {
  _teamInvited: TeamInvitedProps = {
    team: null,
    isLoading: false
  }

  get team() {
    return this._teamInvited.team
  }
  get isLoading() {
    return this._teamInvited.isLoading
  }
  @Mutation
  SET_LOADING({ isLoading }: { isLoading: boolean }) {
    this._teamInvited = {
      ...this._teamInvited,
      isLoading
    }
  }

  @Mutation
  SET_TEAM({ team }: { team: PublicTeamDetailModel }) {
    this._teamInvited = {
      ...this._teamInvited,
      team
    }
  }
  @Action
  async checkValidInvitationCode(payload: { teamId: string; invitationId: string; token: string }) {
    return checkValidToken(payload.teamId, payload.invitationId, payload.token)
  }
  @Action
  async fetchTeam(teamId: string) {
    this.SET_LOADING({ isLoading: true })
    try {
      const data = await getPublicTeam(teamId)
      const model = new PublicTeamDetailModel(data)
      this.SET_TEAM({ team: model })
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING({ isLoading: false })
    }
  }

  @Action
  async acceptInvitation({
    teamId,
    invitationId,
    token,
    calendars
  }: {
    teamId: string
    invitationId: string
    token: string
    calendars: MemberCalendarRequest[]
  }) {
    if (!UserModule.isSignIn) {
      return
    }
    this.SET_LOADING({ isLoading: true })
    try {
      await acceptInvitation(teamId, invitationId, token, calendars)
      await Promise.all([ProfileModule.fetchMyProfile(), CalendarsModule.fetchCalendars()])
    } finally {
      this.SET_LOADING({ isLoading: false })
    }
  }
}

export default getModule(TeamInvited)
