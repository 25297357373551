



















import { Component, Vue, Prop } from 'vue-property-decorator'
import FormItemLayout from './FormItemLayout.vue'

@Component({
  components: {
    FormItemLayout
  }
})
export default class FormItem extends Vue {
  @Prop({ default: null }) icon: string
  @Prop({ default: null }) label: string
  @Prop({ default: null }) help: string
  @Prop({ default: false }) required: boolean
}
