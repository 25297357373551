






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IAttendee, SpirAttendeeStatus } from '@/types'
import CalendarsModule from '@/store/modules/calendars'

@Component
export default class AttendeeStatus extends Vue {
  @Prop() editingEvent

  get attendee(): IAttendee {
    const accountInfo = CalendarsModule.accountInfo(this.editingEvent.accountId)
    if (accountInfo) {
      return this.editingEvent.attendees.find((e) => e.email == accountInfo.email)
    }

    return null
  }

  get isInAttendees(): boolean {
    return this.attendee && this.attendee.responseStatus != null
  }
  async updateAttendeeStatus(value) {
    this.$emit('updateAttendeeStatus')
  }
  get attendeeStatus() {
    return this.attendee?.responseStatus || ''
  }
  set attendeeStatus(value) {
    if (this.attendee) {
      if (value === SpirAttendeeStatus.ACCEPTED.valueOf()) {
        this.attendee.responseStatus = SpirAttendeeStatus.ACCEPTED
      } else if (value === SpirAttendeeStatus.DECLIEND.valueOf()) {
        this.attendee.responseStatus = SpirAttendeeStatus.DECLIEND
      } else if (value === SpirAttendeeStatus.TENTATIVE.valueOf()) {
        this.attendee.responseStatus = SpirAttendeeStatus.TENTATIVE
      } else {
        this.attendee.responseStatus = SpirAttendeeStatus.NEEDS_ACTION
      }
    }
  }
}
