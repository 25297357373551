import { FormStateChangeAlertType } from '@/components/ui/domain/alert/arrangement/type'
import CalendarControlModule from '@/store/modules/calendarControl'
import { activeLayerNames, CalendarBodyMode } from '@/types'
import { computed, ref, watch } from '@vue/composition-api'
import { CalendarBodyModeSwitch } from './_logics'
import { ArrangementsPageState, arrangementsPageStates, bottomNavHeightStates } from './_type'

export const useLayoutChangeSetup = () => {
  const calendarTheme = ref<'highlight' | ''>('')
  const calendarBodyMode = ref<CalendarBodyMode>(CalendarBodyModeSwitch.initialLayerMode())
  const currentPageState = ref<ArrangementsPageState>(arrangementsPageStates.SELECTING_MENU)
  const formStateChangeAlertType = ref<FormStateChangeAlertType>('none')
  // PageState
  const isSelectingMenu = computed(() => currentPageState.value === arrangementsPageStates.SELECTING_MENU)
  const isFormStarting = computed(
    () =>
      currentPageState.value === arrangementsPageStates.STARTING_FORM ||
      currentPageState.value === arrangementsPageStates.EDITING_FORM_WITH_OVERVIEW ||
      currentPageState.value === arrangementsPageStates.EDITING_FORM_WITH_DETAIL ||
      currentPageState.value === arrangementsPageStates.CHECKING_EVENTS
  )
  // heightState
  const heightState = computed(() => {
    switch (currentPageState.value) {
      case arrangementsPageStates.STARTING_FORM:
        return bottomNavHeightStates.INITIAL
      case arrangementsPageStates.EDITING_FORM_WITH_OVERVIEW:
        return bottomNavHeightStates.SHRINKED
      case arrangementsPageStates.EDITING_FORM_WITH_DETAIL:
        return bottomNavHeightStates.EXPANDED
      case arrangementsPageStates.CHECKING_EVENTS:
        return bottomNavHeightStates.CRUSHED
      default:
        return bottomNavHeightStates.INITIAL
    }
  })
  // ModeSwitch
  const isModeSwitchActive = computed(() => CalendarBodyModeSwitch.isModeSwitchButtonActive(calendarBodyMode.value))
  const isModeSwitchVisible = computed(() => CalendarControlModule.getCurrentView !== 1 && isFormStarting.value)

  function resetLayout() {
    currentPageState.value = arrangementsPageStates.SELECTING_MENU
    calendarTheme.value = ''
    calendarBodyMode.value = CalendarBodyModeSwitch.initialLayerMode()
    formStateChangeAlertType.value = 'none'
  }
  function startEditing() {
    currentPageState.value = arrangementsPageStates.STARTING_FORM
    calendarTheme.value = 'highlight'
    calendarBodyMode.value = CalendarBodyModeSwitch.activateCandidate()
  }
  function toggleLayerMode() {
    const nextMode = CalendarBodyModeSwitch.toggle(calendarBodyMode.value)
    calendarBodyMode.value = nextMode
    formStateChangeAlertType.value =
      nextMode.type === 'layer' && nextMode.activeLayerName === activeLayerNames.registered
        ? 'tapToCheckEvents'
        : 'dragToAddCandidates'
    currentPageState.value =
      formStateChangeAlertType.value === 'dragToAddCandidates'
        ? arrangementsPageStates.EDITING_FORM_WITH_OVERVIEW
        : arrangementsPageStates.CHECKING_EVENTS
  }
  function toggleOverviewAndDetailForms() {
    currentPageState.value =
      currentPageState.value !== arrangementsPageStates.EDITING_FORM_WITH_DETAIL
        ? arrangementsPageStates.EDITING_FORM_WITH_DETAIL
        : arrangementsPageStates.EDITING_FORM_WITH_OVERVIEW
  }
  function toOverviewForm() {
    currentPageState.value = arrangementsPageStates.EDITING_FORM_WITH_OVERVIEW
  }
  function showCancelAlertIfNeeded() {
    if (currentPageState.value === arrangementsPageStates.CHECKING_EVENTS) {
      formStateChangeAlertType.value = 'cancelToCheckEvents'
      currentPageState.value = arrangementsPageStates.EDITING_FORM_WITH_OVERVIEW
      calendarBodyMode.value = CalendarBodyModeSwitch.activateCandidate()
    }
  }
  function addTempCandidate() {
    calendarBodyMode.value = CalendarBodyModeSwitch.activateTemp()
  }
  watch(isModeSwitchVisible, (isVisible) => {
    if (!isVisible) {
      showCancelAlertIfNeeded()
    }
  })
  return {
    currentPageState,
    calendarTheme,
    calendarBodyMode,
    isModeSwitchActive,
    isModeSwitchVisible,
    isSelectingMenu,
    formStateChangeAlertType,
    heightState,
    resetLayout,
    startEditing,
    toggleLayerMode,
    toggleOverviewAndDetailForms,
    toOverviewForm,
    showCancelAlertIfNeeded,
    addTempCandidate
  }
}
