







import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleModel } from '@/models/data/schedule'
import OwnerInfoCardBodyItem from './atoms/OwnerInfoCardBodyItem.vue'
import CardBodyFrame from './atoms/CardBodyFrame.vue'
import ConfirmedScheduleTimeInfoCardBodyItem from './atoms/ConfirmedScheduleTimeInfoCardBodyItem.vue'

@Component({
  components: {
    OwnerInfoCardBodyItem,
    CardBodyFrame,
    ConfirmedScheduleTimeInfoCardBodyItem
  }
})
export default class ConfirmedScheduleCardBodyForPrivate extends Vue {
  @Prop() item: ScheduleModel | null

  get timeRange() {
    return { start: this.item.confirmedInfo?.start, end: this.item.confirmedInfo?.end }
  }
  get calendarName() {
    return this.item.calendarInfo?.calendarName
  }
  get backgroundColor() {
    return this.item.calendarInfo?.backgroundColor
  }
}
