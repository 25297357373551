


























import { Component, Prop, Vue } from 'vue-property-decorator'
import OtherCalendarPullDownForm from './OtherCalendarPullDownForm.vue'

@Component({
  components: {
    OtherCalendarPullDownForm
  }
})
export default class OtherCalendarRegistButton extends Vue {
  @Prop({ default: true }) updateHeightWhenDropdownIsActive
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false

  get getIsDropdownActive() {
    return this.isDropdownActive
  }
  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }

  toggle() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropdownRef = this.$refs.dropdown as any
    dropdownRef.toggle()
  }
  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm(email: string) {
    this.$emit('confirm', email)
  }
}
