












import { Component, Watch, Vue } from 'vue-property-decorator'
import AvailabilityAfterConfirmForPersonal from '@/components/page/afterConfirm/availability/Personal.vue'
import AvailabilityAfterConfirmForTeam from '@/components/page/afterConfirm/availability/Team.vue'
import ScheduleAfterConfirm from '@/components/page/afterConfirm/schedule/Index.vue'
import GroupPollAfterConfirm from '@/components/page/afterConfirm/poll/Index.vue'
import { AfterConfirmQueryParams } from '@/types'
import { channelTalkService } from '@/lib/externalServices/channelTalk'

/**
 * this page can be used in Schedule, Group poll, availavaility sharing.
 */
@Component({
  components: {
    ScheduleAfterConfirm,
    GroupPollAfterConfirm
  }
})
export default class AfterConfirm extends Vue {
  queryParams: AfterConfirmQueryParams = null
  @Watch('$route')
  updatedRouter() {
    this.setQuery()
  }
  mounted() {
    this.setQuery()
    channelTalkService.hideChannelButton()
  }

  get aftetConfirmComponent() {
    switch (this.queryParams.type) {
      case 'availability':
        if (this.teamOfPrivate === 'team') {
          return AvailabilityAfterConfirmForTeam
        }
        return AvailabilityAfterConfirmForPersonal
      case 'schedule':
        return ScheduleAfterConfirm
      case 'group-poll':
        return GroupPollAfterConfirm
      default: {
        return this.$router.push({ name: 'NotFound' })
      }
    }
  }
  get teamOfPrivate() {
    return this.queryParams['t-or-p']
  }
  get id() {
    const id = this.queryParams.id
    if (!id) {
      return this.$router.push({ name: 'NotFound' })
    }
    return id
  }
  get confirmedId() {
    return this.queryParams['e-id']
  }
  get teamId() {
    const teamId = this.queryParams['t-id']
    if (this.teamOfPrivate === 'team' && !teamId) {
      return this.$router.push({ name: 'NotFound' })
    }
    return this.queryParams['t-id']
  }
  setQuery() {
    if (!this.$route.query) {
      this.queryParams = null
    }
    this.queryParams = {
      ...this.$route.query
    } as AfterConfirmQueryParams
  }
}
