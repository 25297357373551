






































import ConfirmDeleteModal from '@/components/modal/ConfirmDelete.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import queryParams from '@/lib/queryParams'
import { copyClipboard } from '@/lib/utils'
import getErrorMessage from '@/lib/utils/getErrorMessage'
import { AvailabilityModel } from '@/models/data'
import SharingModule from '@/store/modules/availabilityPatternList'
import ProfileModule from '@/store/modules/profile'
import { FrontSupportLanguage } from '@/types'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import AvailabilitySharingLinkShareModal from './_AvailabilitySharingLinkShareModal.vue'
import AvailabilitySharingListItem from './_AvailabilitySharingListItem/index.vue'

@Component({
  components: {
    Draggable,
    AvailabilitySharingListItem
  }
})
export default class AvailabilitySharingList extends Vue {
  @Prop({ default: false }) isLoading: boolean
  @Prop({ default: () => [] }) patterns: Array<AvailabilityModel>
  @Prop() isTeamList: boolean
  @Prop({ required: true }) language: FrontSupportLanguage

  items: Array<AvailabilityModel> = []
  changingOrder = false

  @Watch('patterns', { immediate: true })
  updateItems() {
    this.items = [...this.patterns]
  }
  get noItem() {
    return !this.isLoading && this.items.length === 0
  }
  get isDraggable() {
    return !this.changingOrder
  }
  get showEmpty() {
    if (this.isLoading && this.patterns.length === 0) {
      return true
    }
    return false
  }
  get skeletonItems() {
    return new Array(3).fill({})
  }
  // fixme: we need to know that thes items are for private for teams, but if I put that flag as props,
  // that can make more complexly. so I use query params for that. but this is not so cool.
  get teamId() {
    return this.$route.query[queryParams.TEAM_ID] as string
  }

  async handlePublishToggle(pattern: AvailabilityModel) {
    try {
      await SharingModule.togglePublish(pattern)
      await this.$analytics.send(SignalType.TOGGLE_PUBLICURL, { id: pattern.id, isPublic: !pattern.isPublished })
    } catch (e) {
      const message = getErrorMessage(e, {
        handleKeys: [
          {
            errorCode: ERROR_CODE.EXCEED_MAX_COUNT,
            i18nKey: 'availabilitySharing.messages.error.EXCEED_MAX_COUNT',
            parameter: {
              count: ProfileModule.myProfile.availabilitySharingMaxCount
            }
          },
          {
            errorCode: ERROR_CODE.NOT_SUBSCRIPTION_USER,
            i18nKey: 'availabilitySharing.messages.error.EXCEED_MAX_PUBLISHED_COUNT',
            parameter: {
              count: ProfileModule.myProfile.availabilitySharingMaxPublishedCount
            }
          },
          {
            errorCode: ERROR_CODE.DISCONNECTED_ZOOM_INTEGRATION,
            i18nKey: 'availabilitySharing.messages.error.DISCONNECTED_ZOOM_INTEGRATION'
          }
        ]
      })
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message
      })
    }
  }
  async changedOrder() {
    try {
      const sharingIds = this.items.filter((pattern) => pattern.id).map((pattern) => pattern.id)
      this.changingOrder = true
      await SharingModule.order({ sharingIds, teamId: this.teamId })
    } catch (e) {
      const errorMessage = this.$t('message.errorCommon').toString()
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: errorMessage
      })
    } finally {
      this.changingOrder = false
    }
  }
  handleEdit(pattern: AvailabilityModel) {
    this.$router.push(pattern.editPath)
  }
  handleDelete(pattern: AvailabilityModel) {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDeleteModal,
      hasModalCard: true,
      events: {
        confirm: async () => {
          await SharingModule.deletePattern(pattern)
          this.$analytics.send(SignalType.DELETE_PUBLICURL, { id: pattern.id, type: pattern.type })
        }
      }
    })
  }
  async handleCandidatesShow(pattern: AvailabilityModel) {
    await SharingModule.fetchCandidatesByModel(pattern)
  }
  handleShareModalShow(pattern: AvailabilityModel) {
    this.$buefy.modal.open({
      parent: this,
      component: AvailabilitySharingLinkShareModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        availabilitySharing: pattern,
        language: this.language
      }
    })
  }
  handleLinkCopy(pattern: AvailabilityModel) {
    copyClipboard(pattern.confirmURL)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }
}
