



































import SpirDropdown from '@/components/ui/spir/SpirDropdown/SpirDropdown.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  name: 'TitleSuggestionDropdownInput',
  components: {
    SpirDropdown,
    SpirDropdownItem,
    SpirTextInput,
    InlineSvg
  },
  props: {
    position: {
      type: String as PropType<'bottom' | 'top'>,
      default: 'bottom'
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array as PropType<string[]>,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    }
  },
  setup(props, { emit }) {
    const dropdown = ref<{ toggle: () => void } | null>(null)
    const input = ref<{ focus: () => void } | null>(null)
    function handleInput(newTitle: string) {
      emit('input', newTitle)
    }
    function handleChange(newTitle: string) {
      input.value.focus()
      handleInput(newTitle)
    }
    const model = computed({
      get: () => props.value,
      set: (newTitle: string) => {
        handleInput(newTitle)
      }
    })
    return {
      dropdown,
      input,
      model,
      handleChange,
    }
  }
})
