






import AuthPageLayout from '@/components/layout/auth/AuthPageLayout.vue'
import SignInFormBox from '@/components/auth/SignInFormBox.vue'
import { defineComponent } from '@vue/composition-api'
import { useAuthSignIn } from '@/composables/auth/useAuthSignInWithRedirect'

export default defineComponent({
  components: {
    AuthPageLayout,
    SignInFormBox
  },
  setup() {
    const { signInWithMicrosoft, signInWithGoogle } = useAuthSignIn()
    const handleSigninWithMicrosoft = () => {
      signInWithMicrosoft()
    }
    const handleSignInWithGoogle = () => {
      signInWithGoogle()
    }
    return {
      handleSigninWithMicrosoft,
      handleSignInWithGoogle
    }
  }
})
