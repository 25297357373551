






























import {
  personalId,
  PersonalInfo,
  PersonalTeamValue,
  TeamInfo
} from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { useTranslation } from '@/composables/useTranslation'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import Avatar from 'vue-avatar'
import { getArrangementTypeKey, selectHost } from './_logics'

export default defineComponent({
  name: 'OverviewBox',
  components: {
    Avatar
  },
  props: {
    personalTeamValue: {
      type: String as PropType<PersonalTeamValue>,
      required: true
    },
    personal: {
      type: Object as PropType<PersonalInfo>,
      required: true
    },
    teams: {
      type: Array as PropType<TeamInfo[]>,
      required: true
    },
    formRequest: {
      type: Object as PropType<ArrangementFormRequest>,
      required: true
    },
    formData: {
      type: Object as PropType<ArrangementTypeFormData>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()

    const arrangementType = computed(() => {
      const key = getArrangementTypeKey(props.formRequest)
      return i18n.t(`forms.common.arrangementType.${key}`).toString()
    })
    const avatar = computed(() => {
      if (props.personalTeamValue === personalId) {
        return { url: props.personal.photoURL, name: props.personal.name }
      } else {
        const team = props.teams.find((team) => team.id === props.personalTeamValue)
        return { url: team?.photoURL, name: team?.name }
      }
    })
    const host = computed((): { backgroundColor?: string; url?: string; name?: string } => {
      const h = selectHost(props.formData)
      const shortenName = h?.name?.length > 8 ? `${h?.name.substr(0, 8)}...` : h?.name
      return { ...h, name: shortenName }
    })

    const onlineMeetingIcon = computed(() => {
      return require(`@/assets/icons/${props.formData.othersInfo.onlineMeeting.value}.png`)
    })
    return {
      arrangementType,
      avatar,
      host,
      onlineMeetingIcon
    }
  }
})
