import UserModule from '@/store/modules/user'
import { RouteNames } from '@/router'

const PUBLIC_PAGE_NAMES: RouteNames[] = [
  'ConfirmSchedule',
  'ProfileDetails',
  'VotePoll',
  'AvailabilityConfirm',
  'TeamAvailabilitySharingConfirm',
  'TeamMemberInvited',
  'TeamMemberTokenExpired',
  'AfterConfirm',
  'TeamScheduleConfirm'
]
const isPublic = (name: RouteNames) => {
  return PUBLIC_PAGE_NAMES.indexOf(name) >= 0
}
export default (to, _, next) => {
  const isSignedIn = UserModule.isSignIn
  if (isPublic(to.name) || isSignedIn) {
    next()
  } else {
    next({
      path: '/auth/signup',
      query: { redirect: to.fullPath }
    })
  }
}
