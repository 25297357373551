






























import ButtonToWrapIcon from '@/components/atoms/ButtonToWrapIcon.vue'
import { defineComponent, inject, PropType, ref } from '@vue/composition-api'
import { DatetimeSlot, DatetimeSlotEditorSetting, DatetimeSlotSavePayload } from './datetimeSlot'
import DatetimeSlotEditor from './DatetimeSlotEditor.vue'
import SpirButton from '@/components/ui/Button.vue'

export default defineComponent({
  name: 'DatetimeSlotListItem',
  components: {
    ButtonToWrapIcon,
    DatetimeSlotEditor,
    SpirButton
  },
  props: {
    datetimeSlot: {
      type: Object as PropType<DatetimeSlot>,
      required: true
    },
    onDelete: {
      type: Function as PropType<(id: string) => void>,
      required: true
    },
    onUpdate: {
      type: Function as PropType<(payload: DatetimeSlotSavePayload) => void>,
      required: true
    }
  },
  setup(props) {
    const isEditing = ref(false)
    const { durationToSplit, isDurationFixed, isPastDisabled, minInterval } = inject(DatetimeSlotEditorSetting)
    function closeEditor() {
      isEditing.value = false
    }
    return {
      isEditing,
      durationToSplit,
      isDurationFixed,
      isPastDisabled,
      minInterval,
      handleEdit: () => {
        isEditing.value = true
      },
      handleDelete: () => {
        props.onDelete(props.datetimeSlot.id)
      },
      handleCancel: () => {
        closeEditor()
      },
      handleUpdate: (payload: DatetimeSlotSavePayload) => {
        props.onUpdate(payload)
        closeEditor()
      }
    }
  }
})
