








import candidateDates from '@/filters/candidateDates'
import { adjustDurationUnit, CardItem } from '@/lib/utils'
import { CardItemTip } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardBodyItem from './CardBodyItem.vue'

@Component({
  components: {
    CardBodyItem
  }
})
export default class TimeInfoCardBodyItem extends Vue {
  @Prop() duration: number
  @Prop() canDisplayDate: boolean
  @Prop() timeRange: { start?: string; end?: string }

  get displayTime() {
    const displayDuration = adjustDurationUnit(this.duration)
    let message =
      displayDuration.type === 'hour'
        ? `${this.$t('schedule.list.durationHour.message', { hour: displayDuration.duration })}`
        : `${this.$t('schedule.list.duration.message', { minute: displayDuration.duration })}`
    if (this.canDisplayDate) {
      const datesOfCandidate = candidateDates(this.timeRange)
      if (datesOfCandidate) {
        message += `・${candidateDates(this.timeRange)}`
      }
    }
    return message
  }
  get tipInfo(): CardItemTip {
    return CardItem.convertToCardItemTip(true, this.$t('labels.dateTime').toString())
  }
}
