



















import { Component, Vue, Prop } from 'vue-property-decorator'
import AttendeeItem from '@/components/forms/AttendeeItem.vue'

type Item = {
  email: string
  name: string
}

@Component({
  components: {
    AttendeeItem
  }
})
export default class AttendeeInput extends Vue {
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  items: Item[] = []

  get getItems() {
    return this.items
  }
  handleConfirm(item: Item) {
    this.items = [...this.items, item]
    this.$emit('confirm', item)
  }
  handleDelete(index: number) {
    this.items = [...this.items.slice(0, index), ...this.items.slice(index + 1, this.items.length)]
    this.$emit('delete', index)
  }
}
