import { NOTIFICATION_MESSAGE } from '@spirinc/message-catalog'

type Mapping = {
  [key: string]: any
}

export type NotificationTextMessage = {
  code: NOTIFICATION_MESSAGE
  mapping: Mapping
}

export type NotificationMessageDoc = {
  id: string
  photoURL?: string
  isChecked: boolean
  main: NotificationTextMessage
  sub?: NotificationTextMessage
  timestampMs: number
  lastConfirmedTimestampMs?: number
}

export class NotificationMessage {
  id: string
  photoURL?: string
  isChecked: boolean
  main: NotificationTextMessage
  sub?: NotificationTextMessage
  timestampMs: number
  lastConfirmedTimestampMs?: number

  constructor(data: {
    id: string
    photoURL?: string
    isChecked: boolean
    main: NotificationTextMessage
    sub?: NotificationTextMessage
    timestampMs: number
    lastConfirmedTimestampMs?: number
  }) {
    this.id = data.id
    this.photoURL = data.photoURL
    this.isChecked = data.isChecked
    this.main = data.main
    this.sub = data.sub
    this.timestampMs = data.timestampMs
    this.lastConfirmedTimestampMs = data.lastConfirmedTimestampMs
  }

  static createFromDoc(doc: any): NotificationMessage {
    // TODO
    if (!doc?.id) {
      throw new Error()
    }
    if (doc?.isChecked == null) {
      throw new Error()
    }
    return new NotificationMessage(doc)
  }
}
