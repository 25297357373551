import { useAnalytics } from './useAnalytics'
import { SignalType } from '@/lib/analytics/LogEntry'

export const useSendSignal = () => {
  const analytics = useAnalytics()
  const sendSignal = (signalType: SignalType, customPayload?: { [key: string]: string | number | boolean }) => {
    return analytics.send(signalType, customPayload)
  }
  return {
    sendSignal
  }
}
