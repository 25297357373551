

































import { Component, Prop, Vue } from 'vue-property-decorator'
import DropdownWithList from '@/components/newForm/molecules/DropdownWithList.vue'

@Component({
  components: {
    DropdownWithList
  }
})
export default class CalendarShareVisibilitySelect extends Vue {
  @Prop({ default: '' }) value: false | 'readAll' | 'freeBusyOnly'
  @Prop({ default: 'is-bottom-left' }) position: 'is-top-right' | 'is-top-left' | 'is-bottom-left'
  @Prop({ default: false }) canSelectFalse: boolean
  @Prop({ default: false }) disabled
  @Prop() accountId: string
  @Prop() calendarId: string
  @Prop() onMyCalendarUpdate: Function

  get options() {
    return [
      {
        label: this.$t('teamVisibilityMenu.readAll.label'),
        sublabel: this.$t('teamVisibilityMenu.readAll.description'),
        value: 'readAll'
      },
      {
        label: this.$t('teamVisibilityMenu.freeBusyOnly.label'),
        sublabel: this.$t('teamVisibilityMenu.freeBusyOnly.description'),
        value: 'freeBusyOnly'
      },
      {
        label: this.$t('teamVisibilityMenu.notShare.label'),
        sublabel: this.$t('teamVisibilityMenu.notShare.description'),
        value: false
      }
    ]
  }

  get currentLabel() {
    const hit = this.options.find((option) => option.value === this.value)
    if (!hit) {
      return ''
    }

    return hit.label
  }
  itemDisabled(value: false | 'readAll' | 'freeBusyOnly') {
    return !this.canSelectFalse && value === false
  }
  onChangeValue(visibility: false | 'readAll' | 'freeBusyOnly') {
    this.onMyCalendarUpdate(this.accountId, this.calendarId, visibility)
  }
}
