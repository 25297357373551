









import ConfirmedCandidateBlockTitle from '@/components/sidePanels/confirm/atoms/ConfirmCandidateBlockTitle.vue'
import Button from '@/components/ui/Button.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmedCandidateBlockTitle,
    Button
  },
  props: {
    text: {
      type: String,
      required: true
    },
    hasCalendarOpenButton: {
      type: Boolean,
      default: false
    },
    onCalendarOpen: {
      type: Function,
      required: true
    }
  }
})
