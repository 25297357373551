import { Attendee, ProfileOnOverview } from '@/types'

export const FromAttendee = {
  convertToProfileOnOverview(attendee: Attendee, overrideOption?: Partial<ProfileOnOverview>): ProfileOnOverview {
    return {
      fullName: overrideOption?.fullName || attendee.fullName,
      photoURL: overrideOption?.photoURL || attendee.photoURL,
      email: overrideOption?.email || attendee.email,
      userId: overrideOption?.userId || attendee.userId || attendee.id,
      isAuthor: overrideOption?.isAuthor || !!attendee.organizer,
      attendanceStatus: overrideOption?.attendanceStatus || attendee.responseStatus,
      isLoading: overrideOption?.isLoading || false
    }
  }
}
