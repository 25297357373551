




import { defineComponent, onMounted } from '@vue/composition-api'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { useAddCalendar } from '@/composables/auth/useAddMSCalenarWithRedirect'
import { authErrorTypes, AuthErrorTypes } from '@/types/authError'
import { LOCAL_STORAGE_KEY_FOR_CALLBACK_MS } from '@/types'
import queryParams from '@/lib/queryParams'
import { useAuthErrorToast } from '@/composables/auth/useAuthErrorToast'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'

export default defineComponent({
  components: {
    LoadingSpinner
  },
  setup() {
    const { sendSignal } = useSendSignal()
    const route = useRoute()
    const router = useRouter()
    const { addMicrosoftCalendar } = useAddCalendar()
    const { showAuthErrorToast } = useAuthErrorToast()
    const redirect = async (errorCode?: AuthErrorTypes) => {
      const prevPath = window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_CALLBACK_MS)
      if (prevPath) {
        if (errorCode) {
          showAuthErrorToast(errorCode)
        }
        return router.push({ path: prevPath, query: { errorCode } })
      }
      if (errorCode) {
        await sendSignal(SignalType.SYNC_ACCOUNT_FAILURE, { type: 'google', errorCode: errorCode })
        return router.push({ name: 'AddCalendar', query: { [queryParams.ERROR_CODE]: errorCode } })
      } else {
        sendSignal(SignalType.SYNC_ACCOUNT_SUCCESS, { type: 'microsoft' })
        return router.push({ name: 'Main' }).catch(GuardExceptionHandlers.noopAgainstRedirection)
      }
    }
    onMounted(async () => {
      const authCode = route.query?.code as AuthErrorTypes
      if (!authCode) {
        return redirect(authErrorTypes.notEnoughScope)
      }
      let errorCode = null
      try {
        const response = await addMicrosoftCalendar(authCode)

        if (response.result === 'error') {
          errorCode = response.errorCode
        }
      } catch (e) {
        errorCode = authErrorTypes.unknown
      } finally {
        redirect(errorCode)
      }
    })
    return {}
  }
})
