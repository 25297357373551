import TimezoneModule from '@/store/modules/timezones'
import i18n from '@/i18n'
import { DateTime } from 'luxon'
import { FrontSupportLanguage, frontSupportLanguages } from '@/types'
import { diffDaysByUserTimezone } from '.'
import { addDays } from 'date-fns'

export const spirDateFormatTypes = {
  hourMin: 'hourMin',
  yyyymmddweekday: 'yyyymmddweekday',
  mdHourMin: 'mdHourMin',
  md: 'md',
  mdWeekday: 'mdWeekday',
  mdWeekdayHourMin: 'mdWeekdayHourMin',
  yyyymmddweekdayHourMin: 'yyyymmddweekdayHourMin'
} as const
export type SpirDateFormatTypes = keyof typeof spirDateFormatTypes

type LocaleAndDateTypes = {
  [key in FrontSupportLanguage]: {
    [key in SpirDateFormatTypes]: string
  }
}

// ここでのキーは今後必ずしも言語と一致しない可能性もその時Keyを変更する
const DateFormatsByLocal: LocaleAndDateTypes = {
  [frontSupportLanguages.en]: {
    hourMin: 'hh:mm a',
    yyyymmddweekday: 'EEE, MMM dd yyyy',
    mdHourMin: 'MMM d hh:mm a',
    md: 'MMM d',
    mdWeekday: 'EEE, MMM d',
    mdWeekdayHourMin: 'EEE, MMM d hh:mm a',
    yyyymmddweekdayHourMin: 'EEE, MMM dd yyyy hh:mm a'
  },
  [frontSupportLanguages.ja]: {
    hourMin: 'HH:mm',
    yyyymmddweekday: 'yyyy/MM/dd(EEE)',
    mdHourMin: 'M/d HH:mm',
    md: 'M/d',
    mdWeekday: 'M/d(ccc)',
    mdWeekdayHourMin: 'M/d(ccc) HH:mm',
    yyyymmddweekdayHourMin: 'yyyy/MM/dd(ccc) HH:mm'
  }
}

export const spirDateFormat = (
  date: Date,
  format: SpirDateFormatTypes,
  option?: { timeZone?: string; locale?: string }
) => {
  const userTimezone = option?.timeZone || TimezoneModule.userTimezoneKey
  const userLocale = option?.locale || i18n.locale // dateFormat時の言語 ex. 月曜日 or Monday
  const targetFormat = DateFormatsByLocal[userLocale][format]
  return DateTime.fromJSDate(date, { zone: userTimezone, locale: userLocale }).toFormat(targetFormat)
}

export const spirDateFormatCustomTypes = {
  yyyy: 'yyyy',
  MMM: 'MMM',
  d: 'd',
  ccc: 'ccc',
  cccc: 'cccc',
  H: 'H',
  ['HH:mm']: 'HH:mm' // for 24 hours format
} as const
export type SpirDateFormatCustomTypes = keyof typeof spirDateFormatCustomTypes

// https://moment.github.io/luxon/#/formatting?id=table-of-tokens
export const spirDateFormatCustom = (
  date: Date,
  format: SpirDateFormatCustomTypes,
  option?: { timeZone?: string; locale?: string }
) => {
  const userTimezone = option?.timeZone || TimezoneModule.userTimezoneKey
  const userLocale = option?.locale || i18n.locale // dateFormat時の言語 ex. 月曜日 or Monday
  return DateTime.fromJSDate(date, { zone: userTimezone, locale: userLocale }).toFormat(format)
}

export const getHourByUserTimezone = (date: Date) => {
  return Number(spirDateFormatCustom(date, spirDateFormatCustomTypes.H))
}

export const getAllDayListItemTitle = ({
  event,
  allDayLabel = i18n.t('labels.allDay').toString(),
  option
}: {
  event: { start: Date; end: Date }
  allDayLabel?: string
  option?: {
    timeZone?: string
    locale?: FrontSupportLanguage
  }
}): string => {
  const diffDays = diffDaysByUserTimezone(event.end, event.start) - 1
  const formattedStartDate = spirDateFormat(event.start, spirDateFormatTypes.md, option)
  const formattedDate =
    diffDays === 0
      ? formattedStartDate
      : `${formattedStartDate}-${spirDateFormat(addDays(event.start, diffDays), spirDateFormatTypes.md, option)}`
  return `${formattedDate}(${allDayLabel})`
}
