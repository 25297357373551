




import { Component, Vue } from 'vue-property-decorator'
import ScheduleListPanel from '@/components/schedule/list/ScheduleListPanel.vue'

@Component({
  components: {
    ScheduleListPanel
  }
})
export default class ScheduleList extends Vue {}
