























import { Component, Prop, Vue } from 'vue-property-decorator'
import PoweredBySpir from '@/components/sidePanels/confirm/atoms/PoweredBySpir.vue'
import SignInButton from '@/components/molecules/SignInButton.vue'
import RequestOtherOptionButton from '@/components/schedule/RequestOtherOptionButton.vue'
import { ScheduleModel } from '@/models/data'

@Component({
  components: {
    RequestOtherOptionButton,
    PoweredBySpir,
    SignInButton
  }
})
export default class ConfirmMobileCalendarFooter extends Vue {
  @Prop() schedule: ScheduleModel
  @Prop({ default: true }) isOtherRequestOpen: boolean
  @Prop() isOrganizer: boolean
  @Prop({ default: false }) isSyncing: boolean
  @Prop({ default: false }) isConfirmButtonShowed: boolean
  @Prop({ default: false }) isSignIn: boolean
  @Prop() onScheduleConfirmButtonClick: () => void
}
