




















import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmCandidateBlockDate from './atoms/ConfirmCandidateBlockDate.vue'
import ConfirmCandidateSelectRow from './ConfirmCandidateSelectRow.vue'
import ConfirmCandidateDateUL from './atoms/ConfirmCandidateDateUL.vue'
import ConfirmCandidateDateLI from './atoms/ConfirmCandidateDateLI.vue'
import ConfirmCandidateDateRowUL from './atoms/ConfirmCandidateDateRowUL.vue'
import ConfirmCandidateDateRowLI from './atoms/ConfirmCandidateDateRowLI.vue'
import { groupBy, sortBy } from 'lodash'
import { endOfDay } from '@/lib/utils/timezone'
import { FullCalendarEvent } from '@/types'

@Component({
  components: {
    ConfirmCandidateBlockDate,
    ConfirmCandidateDateUL,
    ConfirmCandidateDateLI,
    ConfirmCandidateDateRowUL,
    ConfirmCandidateDateRowLI,
    ConfirmCandidateSelectRow
  }
})
export default class ConfirmCandidateListBox extends Vue {
  @Prop() candidates: FullCalendarEvent[]
  @Prop() timeZone: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) isPreview: boolean
  @Prop() onCandidateSelect: (event: FullCalendarEvent) => Promise<void>
  @Prop() onCandidateHover: (event: FullCalendarEvent) => Promise<void>

  get groupedCandidatesByDate(): Record<string, FullCalendarEvent[]> {
    const sortedByDate = sortBy(this.candidates, (candidate) => candidate.start)
    const grouped = groupBy(sortedByDate, (candidate) => endOfDay(candidate.start).toISOString())
    return grouped
  }

  async handleEventClick(candidate: FullCalendarEvent) {
    await this.onCandidateSelect(candidate)
  }

  async handleMouseHover(candidate: FullCalendarEvent) {
    await this.onCandidateHover(candidate)
  }
}
