






import Component, { mixins } from 'vue-class-component'
import timezoneButtonMixin from './updateAllTimezone'

@Component
export default class TimezoneButton extends mixins(timezoneButtonMixin) {}
