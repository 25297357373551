
























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import TimeZoneSelector from '@/components/organisms/timezoneSelector/TimezoneSelector.vue'
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import TimezoneModule from '@/store/modules/timezones'
import { TimeZoneInfo } from '@/models/data/userInfo'

@Component({
  components: {
    TimeZoneSelector,
    WithValidationInput
  }
})
export default class TimezoneSelectorAndDisplayName extends Vue {
  @PropSync('timezoneInfo', { type: Object }) timezoneInfoSynced: TimeZoneInfo
  @Prop({ default: false }) showDelete: boolean
  @Prop() order: string

  get timeZoneByKey() {
    return TimezoneModule.timezoneByKey({ key: this.timezoneInfoSynced.key })
  }
  onChangeTimeZone(newKey) {
    this.timezoneInfoSynced.key = newKey
    this.timezoneInfoSynced.displayName = ''
    this.$emit('updateTimezone')
  }
  updatedName() {
    this.$emit('updateTimezone')
  }
  deleteTimezone() {
    this.$emit('deleteTimezone')
  }
}
