






import { defineComponent, PropType } from '@vue/composition-api'
import { Calendar } from './types'

export default defineComponent({
  name: 'CalendarAvatarWithName',
  props: {
    calendar: {
      type: Object as PropType<Calendar>,
      required: true
    }
  }
})
