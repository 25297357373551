













import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import CalendarSettingItemCalendar from '@/components/calendar/CalendarSettingItemCalendar.vue'
import { AvailabilityCalendarType } from '@spirinc/contracts'
import CalendarModule from '@/store/modules/calendars'

@Component({
  components: {
    FormItem,
    CalendarSettingItemCalendar
  }
})
export default class FormDisplayAttendees extends Vue {
  @Prop() icon: string
  @Prop() label: string
  @Prop() attendees: AvailabilityCalendarType[]
  @Prop() baseList: AvailabilityCalendarType[]

  getCalendar(attendee: AvailabilityCalendarType) {
    return CalendarModule.getCalendar(attendee)
  }
  onToggleCalendar(attendee: AvailabilityCalendarType) {
    this.$emit('onToggleCalendar', attendee)
  }
  isCheckedCalendar(attendee: AvailabilityCalendarType) {
    return this.baseList.some((c) => c.accountId === attendee.accountId && c.calendarId === attendee.calendarId)
  }
}
