
















// サイドメニューの上の段
import { useAnalytics } from '@/composables/useAnalytics'
import { SignalType } from '@/lib/analytics/LogEntry'
import { RouteNames } from '@/router'
import { defineComponent } from '@vue/composition-api'
import AsideMenuItemPlusButton from './molecules/AsideMenuItemPlusButton.vue'
import AvailabilitySharingListButton from './molecules/AvailabilitySharingListButton.vue'
import ConfirmedListButton from './molecules/ConfirmedListButton.vue'
import NotificationButton from './molecules/NotificationButton.vue'
import UnconfirmedListButton from './molecules/UnconfirmedListButton.vue'

export default defineComponent({
  components: {
    AsideMenuItemPlusButton,
    NotificationButton,
    AvailabilitySharingListButton,
    UnconfirmedListButton,
    ConfirmedListButton
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const analytics = useAnalytics()
    return {
      clickedRouter: (routerName: RouteNames) => {
        return analytics.send(SignalType.CLICK_NAV_LIST, { list: routerName })
      }
    }
  }
})
