import { CalendarBodyMode } from '@/types'
import { ref, Ref, watch } from '@vue/composition-api'

export const useModeClasses = ({ mode }: { mode: Ref<CalendarBodyMode> }) => {
  const modeClasses = ref<string[]>([])
  const timeoutId = ref<ReturnType<typeof setTimeout> | null>(null)
  watch(mode, (newMode) => {
    clearTimeout(timeoutId.value)
    if (newMode.type === 'layer') {
      modeClasses.value = ['layered', 'animating']
      timeoutId.value = setTimeout(() => {
        modeClasses.value = ['layered']
      }, 200)
    }
  })
  return {
    modeClasses
  }
}
