

















import { Component, Vue, Prop } from 'vue-property-decorator'
import ConfirmModalLayout from '@/components/layout/ConfirmedModal.vue'
import { ScheduleStatus } from '@/types'

@Component({
  components: {
    ConfirmModalLayout
  }
})
export default class RequestAlternativeCompleteModal extends Vue {
  @Prop() nextStatus: ScheduleStatus

  get strings() {
    switch (this.nextStatus) {
      case 'requestedByConfirmer':
        return {
          title: this.$t('alternativeCandidates.modal.requestedByConfirmer.title'),
          detail: this.$t('alternativeCandidates.modal.requestedByConfirmer.detail'),
          annotation: this.$t('alternativeCandidates.modal.confirmedAnnotation')
        }
    }
    return {
      title: this.$t('alternativeCandidates.modal.suggestedByOrganizer.title'),
      detail: this.$t('alternativeCandidates.modal.suggestedByOrganizer.detail'),
      annotation: this.$t('alternativeCandidates.modal.confirmedAnnotation')
    }
  }
  handleBackToHome() {
    // @ts-ignore
    this.$parent.close()
    this.$router.push({ name: 'Main' })
  }
}
