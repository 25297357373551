import { SelectedEmailType } from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { ScheduleModelTeam } from '@/models/data'
import TeamCalendarModule from '@/store/modules/teamCalendar'
import UserModule from '@/store/modules/user'
import { MemberResponse } from '@spirinc/contracts'
import { useTranslation } from './useTranslation'

type FormActiveMember = { teamId: string; member: MemberResponse & { fullName: string; backgroundColor: string } }

export const useTeamScheduleMembers = () => {
  const i18n = useTranslation()
  function toFormAllActiveMembers({ scheduleModelTeam }: { scheduleModelTeam: ScheduleModelTeam }): FormActiveMember[] {
    return scheduleModelTeam.allActiveTeamMembers.map((member) => ({
      teamId: scheduleModelTeam.teamId,
      member: {
        ...member,
        fullName: UserModule.myId === member.userId ? i18n.t('myInfo.mine').toString() : member.fullName,
        backgroundColor:
          TeamCalendarModule.memberCalendarByTeamAndMemberId(scheduleModelTeam.teamId, member.id)?.backgroundColor ||
          'gray'
      }
    }))
  }
  function toFormAllMembers({
    formAllActiveMembers,
    organizerMemberId
  }: {
    formAllActiveMembers: FormActiveMember[]
    organizerMemberId: string
  }): FormActiveMember[] {
    return formAllActiveMembers.filter((calendar) => calendar.member.id !== organizerMemberId)
  }

  function toTeamAttendees({
    scheduleModelTeam,
    allMembers
  }: {
    scheduleModelTeam: ScheduleModelTeam
    allMembers: FormActiveMember[]
  }): SelectedEmailType[] {
    return scheduleModelTeam.attendingMembers.map((attendee) => {
      const member = allMembers.find((member) => member.member.id === attendee.memberId)
      return { type: 'email', email: member.member.email }
    })
  }

  return {
    toFormAllActiveMembers,
    toFormAllMembers,
    toTeamAttendees
  }
}
