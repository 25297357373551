export const personalId = 'personal'
type PersonalId = typeof personalId
export type TeamId = string
export type PersonalTeamValue = PersonalId | TeamId

export type PersonalInfo = {
  id: PersonalId
  name: string
  photoURL?: string
}

export type TeamInfo = {
  id: TeamId
  name: string
  photoURL?: string
  disabled?: boolean
}
