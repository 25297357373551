import { useAuthAuthcode } from './useAuthAuthcode'
import queryString from 'query-string'
import CalendarsModule from '@/store/modules/calendars'
import { AuthErrorTypes } from '@/types/authError'
import { useAuthSessionStorage } from './useAuthSessionStorage'

export type AddCalendarSuccess = {
  result: 'success' | 'cancel'
}
export type AddCalendarError = {
  result: 'error'
  errorCode: AuthErrorTypes
}
export type AddCalendarResult = AddCalendarSuccess | AddCalendarError

export type ReturnValueFromGoogleAuth = {
  code: string
  id_token: string
  prompt: 'consent'
  scope: Array<string>
  error?: 'access_denied'
}
type ParseGoogleAuthResultOk = {
  result: 'ok'
  parseResult: ReturnValueFromGoogleAuth
}
type ParseGoogleAuthResultError = {
  result: 'error'
  errorCode: AuthErrorTypes
}
type ParseGoogleAuthResultCancel = {
  result: 'cancel'
}
export type ParseGoogleAuthResult = ParseGoogleAuthResultOk | ParseGoogleAuthResultError | ParseGoogleAuthResultCancel

const GOOGLE_AUTH_SCOPE = [
  'email',
  'profile',
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events'
]

export const useAddGoogleCalenarWithRedirect = () => {
  const { setSession } = useAuthSessionStorage()
  const { getGoogleAuthCodeByRedirect, registerAuthcode } = useAuthAuthcode()
  const parseGoogleAuthResult = (fullPath: string): ParseGoogleAuthResult => {
    const parseURL = queryString.parseUrl(decodeURIComponent(fullPath), { parseFragmentIdentifier: true })
    const parsedReturnValue: ReturnValueFromGoogleAuth = queryString.parse(
      parseURL.fragmentIdentifier
    ) as ReturnValueFromGoogleAuth
    if (parsedReturnValue.error) {
      return {
        result: 'cancel'
      }
    }
    if (!parsedReturnValue.code || !parsedReturnValue.id_token || !parsedReturnValue.scope) {
      return {
        result: 'error',
        errorCode: 'unknown'
      }
    }
    if (GOOGLE_AUTH_SCOPE.some((s) => parsedReturnValue.scope.indexOf(s) < 0)) {
      return {
        result: 'error',
        errorCode: 'notEnoughScope'
      }
    }
    return {
      result: 'ok',
      parseResult: parsedReturnValue
    }
  }
  const getGoogleAuthCode = () => {
    setSession({ action: 'AddCalendar', type: 'Google' })
    return getGoogleAuthCodeByRedirect()
  }
  const registerGoogleCalendar = async (fullPath: string): Promise<AddCalendarResult> => {
    const parseFullPath = parseGoogleAuthResult(fullPath)
    if (parseFullPath.result !== 'ok') {
      return parseFullPath
    }
    const registerResponse = await registerAuthcode(parseFullPath.parseResult.code)
    if (registerResponse.result !== 'success') {
      return registerResponse
    }
    await CalendarsModule.fetchAfterAddCalendar()
    return {
      result: 'success'
    }
  }
  return {
    parseGoogleAuthResult,
    getGoogleAuthCode,
    registerGoogleCalendar
  }
}
