














import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import { ErrorState } from '@/components/ui/spir/textErrorState'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import TitleSuggestionDropdownInput from './_TitleSuggestionDropdownInput.vue'

export default defineComponent({
  name: 'TitleDropdown',
  components: {
    TitleSuggestionDropdownInput,
    SpirTextInput
  },
  props: {
    position: {
      type: String as PropType<'bottom' | 'top'>,
      default: 'bottom'
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array as PropType<string[]>,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorState: {
      type: Object as PropType<ErrorState>,
      default: () => ({
        type: 'no'
      })
    },
    onInput: {
      type: Function as PropType<(value: string) => void>
    }
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: (newTitle: string) => {
        if (props.onInput) {
          props.onInput(newTitle)
        } else {
          emit('input', newTitle)
        }
      }
    })
    const hasSuggestions = computed((): boolean => props.suggestions.length > 0)
    return {
      model,
      hasSuggestions
    }
  }
})
