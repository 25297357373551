





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SpirLogo extends Vue {}
