






























import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarItemLayout from '@/components/calendarList/atoms/CalendarItemLayout.vue'

@Component({
  components: {
    CalendarItemLayout
  }
})
export default class CalendarListItem extends Vue {
  @Prop() isActive: boolean
  @Prop() backgroundColor: string
  @Prop({ default: false }) isLoading: boolean
  @Prop() isMenuActive: boolean

  onClick() {
    if (this.isLoading) {
      return
    }
    this.$emit('onClick')
  }
}
