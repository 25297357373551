












































import Vue from 'vue'
import { Component, PropSync, Prop } from 'vue-property-decorator'
import { OnlineMeetingType } from '../../types'
import UserModule from '@/store/modules/user'
import OnlineMeetingToolInfoModal from '@/components/modal/OnlineMeetingToolInfo.vue'
import ZoomSignInButton from '@/components/atoms/ZoomSigninButton.vue'

interface IOnlineMeetingItemParams {
  serviceType: OnlineMeetingType
  isConnected?: boolean
  accountName?: string
}

@Component({
  components: {
    ZoomSignInButton
  }
})
export default class OnlineMeetingSelector extends Vue {
  @PropSync('selectedService', { default: OnlineMeetingType.none }) selectedServiceSynced!: OnlineMeetingType
  @Prop() readonly
  @Prop() availableOnlineMeetings: OnlineMeetingType[]
  @Prop({ default: 'spir-label' }) labelClass: string
  private get onlineMeetingTools(): IOnlineMeetingItemParams[] {
    const tools: IOnlineMeetingItemParams[] = [
      {
        serviceType: OnlineMeetingType.none
      }
    ]
    this.availableOnlineMeetings.map((onlineMeeting) => {
      tools.push({
        serviceType: onlineMeeting
      })
    })
    return tools
  }
  get zoomInfo() {
    return {
      serviceType: OnlineMeetingType.zoom,
      isConnected: UserModule.isConnectedZoom,
      accountName: UserModule.integrations.zoomInfo?.email
    }
  }

  private serviceName(serviceType: OnlineMeetingType) {
    return this.$te(`onlineMeetings.${serviceType}`)
      ? this.$t(`onlineMeetings.${serviceType}`)
      : this.$t('onlineMeetings.none')
  }

  private buttonState(isConnected: boolean) {
    if (isConnected) {
      return {
        type: '',
        title: this.$t('buttons.disconnect')
      }
    }

    return {
      type: 'is-primary',
      title: this.$t('buttons.connect')
    }
  }

  private isSelected(item: IOnlineMeetingItemParams) {
    return item.serviceType === this.selectedServiceSynced
  }

  private canSelect(item: IOnlineMeetingItemParams) {
    // NOTE: 連携情報の無いサービスは常に選択可能
    return item.isConnected == null ? true : item.isConnected
  }

  private onClickItem(item: IOnlineMeetingItemParams) {
    if (!this.canSelect(item)) {
      return
    }
    this.selectedServiceSynced = item.serviceType
    this.$emit('input')
  }

  private onClickSettingIcon(item: IOnlineMeetingItemParams) {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: OnlineMeetingToolInfoModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        accountName: item.accountName
      },
      events: {
        onClickDisconnectionButton: async () => {
          await UserModule.disconnectZoom()
          if (this.selectedServiceSynced === OnlineMeetingType.zoom) {
            this.selectedServiceSynced = OnlineMeetingType.none
          }
          modal.close()
        }
      }
    })
  }
}
