











import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import { defineComponent } from '@vue/composition-api'
import AutoCandidatesNotice from './_AutoCandidatesNotice.vue'
import SpirButton from '@/components/ui/Button.vue'
import { useCalendarMixin } from '@/composables/useCalendarMixin'

export default defineComponent({
  name: 'CommonHeaderParts',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    AutoCandidatesNotice,
    SpirButton
  },
  props: {
    isAutoCandidatesNoticeVisible: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { toggleScheduleEventsList } = useCalendarMixin()
    return {
      handleClick: () => toggleScheduleEventsList(false)
    }
  }
})
