






















import { useNewArrangementFormPanel } from '@/components/ui/domain/sidePanel/arrangement/NewArrangementPanel/useNewArrangementFormPanel'
import {
  CreateArrangementSidePanelInfo,
  PersonalTeamInfoGetters
} from '@/components/ui/domain/sidePanel/arrangement/types'
import { computed, defineComponent, inject, PropType, toRefs } from '@vue/composition-api'
import BottomFormCollapse from './_BottomFormCollapse.vue'
import NewArrangementsFormPanel from './_NewArrangementsFormPanel.vue'
import { BottomNavHeightState, bottomNavHeightStates } from './_type'

export default defineComponent({
  name: 'BottomForm',
  components: {
    BottomFormCollapse,
    NewArrangementsFormPanel
  },
  props: {
    heightState: {
      type: String as PropType<BottomNavHeightState>,
      default: bottomNavHeightStates.INITIAL
    },
    isPeekCandidateSectionOpened: {
      type: Boolean,
      default: false
    },
    onToggle: {
      type: Function as PropType<() => void>,
      required: true
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const { heightState } = toRefs(props)
    const isPanelShrinked = computed(() => heightState.value !== bottomNavHeightStates.EXPANDED)
    const { changeFormRequest, getFormDataFunctions } = inject(CreateArrangementSidePanelInfo)
    const { getMyProfile, getMyTeams } = inject(PersonalTeamInfoGetters)
    const {
      currentArrangementType,
      formRequest,
      formData,
      arrangementTypeSectionState,
      selectedPersonalTeamValue,
      personalInfo,
      teamInfos,
      handlePersonalTeamIdChange,
      handleArrangementTypeChange,
      handleSave,
      handlePreviewOpen
    } = useNewArrangementFormPanel({ changeFormRequest, getFormDataFunctions, getMyProfile, getMyTeams })
    return {
      currentArrangementType,
      formRequest,
      formData,
      arrangementTypeSectionState,
      selectedPersonalTeamValue,
      personalInfo,
      teamInfos,
      handlePersonalTeamIdChange,
      handleArrangementTypeChange,
      handleSave,
      handlePreviewOpen,
      handleFormToggle: () => {
        if (isPanelShrinked.value) {
          const fullH = window.innerHeight
          const vh = fullH * 0.01
          document.documentElement.style.setProperty('--bottomNavVh', `${vh}px`)
        }
        props.onToggle()
      }
    }
  }
})
