import { render, staticRenderFns } from "./SidePanelHeaderFrame.vue?vue&type=template&id=1ef26914&scoped=true&"
import script from "./SidePanelHeaderFrame.vue?vue&type=script&lang=ts&"
export * from "./SidePanelHeaderFrame.vue?vue&type=script&lang=ts&"
import style0 from "./SidePanelHeaderFrame.vue?vue&type=style&index=0&id=1ef26914&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef26914",
  null
  
)

export default component.exports