import { useToast } from '@/composables/useToast'
import i18n from '@/i18n'
import queryParams from '@/lib/queryParams'
import { PollModel, ScheduleModel } from '@/models/data'
import { AllRouteNames } from '@/router'
import CalendarsModule from '@/store/modules/calendars'
import ListPanelModule from '@/store/modules/listPanel'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import {
  ConfirmedPrivateTabItemState,
  ConfirmedTabsState,
  ConfirmedTeamTabItemState,
  FilteringCalendarInfo,
  ITypeCalendarListForUI,
  UnconfirmedPrivateTabItemState,
  UnconfirmedTabsState,
  UnconfirmedTeamTabItemState
} from '@/types'
import { computed, Ref, ref, watch } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'

type Props = {
  personalTeamValue?: Ref<string>
  getPrivateList: () => Array<PollModel | ScheduleModel>
}

export const useAdjustmentTabsSetup = <T extends 'unconfirmed' | 'confirmed'>({
  personalTeamValue,
  getPrivateList
}: Props) => {
  const router = useRouter()
  const { openDangerBottomToast } = useToast()
  const privateTab = 'private'
  const currentTab = computed(() => {
    const teamId = personalTeamValue.value
    return teamId || privateTab
  })
  const visibleCalendars = ref([])
  const writableCalendars = computed(() => CalendarsModule.writableCalendars)
  const privateSchedules = computed(() => {
    return getPrivateList().filter((model) => visibleCalendars.value.indexOf(model.calendarKey) >= 0)
  })

  const getFilteringCalendars = (
    writableCalendars: ITypeCalendarListForUI[],
    visibleCalendars: string[]
  ): FilteringCalendarInfo[] => {
    return writableCalendars.map((calendar) => {
      const index = visibleCalendars.findIndex((visibleCalendar) => visibleCalendar === calendar.key)
      const active = index >= 0
      return {
        key: calendar.key,
        title: calendar.title,
        backgroundColor: calendar.backgroundColor,
        active,
        handleClick: () => {
          if (active) {
            visibleCalendars.splice(index, 1)
          } else {
            visibleCalendars.push(calendar.key)
          }
        }
      }
    })
  }

  type PrivateTabItemState = T extends 'unconfirmed' ? UnconfirmedPrivateTabItemState : ConfirmedPrivateTabItemState
  function getPrivateTabInfo(getPrivateCardTypes): PrivateTabItemState {
    const cards = getPrivateCardTypes(privateSchedules.value)
    const myProfile = ProfileModule.myProfile
    const myTab = {
      key: privateTab,
      avatar: { fullName: myProfile.fullName, photoURL: myProfile.photoURL },
      filteringCalendars: getFilteringCalendars(writableCalendars.value, visibleCalendars.value),
      content: {
        loading: ListPanelModule.isLoading,
        cards
      }
    }
    return myTab
  }

  type TeamTabItemState = T extends 'unconfirmed' ? UnconfirmedTeamTabItemState[] : ConfirmedTeamTabItemState[]
  function getTeamTabInfos(
    getTeamList,
    getTeamCardTypes,
    getTeamLoading: (model: { isLoading: true }) => boolean
  ): TeamTabItemState {
    const myTeams = TeamModule.myTeams.map((team) => {
      const key = team.id
      const avatar = { fullName: team.name, photoURL: team.photoUrl }
      const teamScheduleList = getTeamList(team.id)
      const unconfirmedSchedules = teamScheduleList?.models || []
      const cards = getTeamCardTypes(unconfirmedSchedules)
      return {
        key,
        avatar,
        content: { loading: getTeamLoading(teamScheduleList), cards }
      }
    })
    return myTeams
  }

  function setVisibleCalendars() {
    visibleCalendars.value = writableCalendars.value.map((c) => c.key)
  }

  // Writableカレンダーが更新された時（アカウントが追加された時
  watch(writableCalendars, () => setVisibleCalendars())
  return {
    handleMounted: async () => {
      setVisibleCalendars()
      if (currentTab.value !== privateTab) {
        await TeamScheduleListModule.fetchAllTypeSchedules({ teamId: currentTab.value, hideLoader: false })
      }
    },
    currentTab,
    getPrivateTabInfo,
    getTeamTabInfos,
    handleClose: () => {
      router.push({ name: AllRouteNames.Main })
    },
    handleTabChange: async (tab: string) => {
      if (tab == privateTab) {
        router.push({ query: {} })
      } else {
        try {
          router.push({ query: { [queryParams.TEAM_ID]: tab } })
          await TeamScheduleListModule.fetchAllTypeSchedules({ teamId: tab, hideLoader: false })
        } catch (e) {
          openDangerBottomToast({
            message: i18n.t('message.errorCommon').toString()
          })
        }
      }
    },
    getTabsClass(tabState: UnconfirmedTabsState | ConfirmedTabsState): 'no-team' | '' {
      return tabState.items.length === 1 ? 'no-team' : ''
    }
  }
}
