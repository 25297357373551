



















import { Component, PropSync, Vue } from 'vue-property-decorator'
import TimezoneSelectorAndDisplayName from '@/components/organisms/timezoneSelector/TimezoneSelectorAndDisplayName.vue'
import { TimeZones } from '@/models/data/userInfo'
import { localTimezone } from '@/lib/timezone'

@Component({
  components: {
    TimezoneSelectorAndDisplayName
  }
})
export default class TimezoneEdit extends Vue {
  @PropSync('timeZones', { type: Object }) timezoneInfoSynced: TimeZones
  timeZoneOrders = [
    {
      key: 'primary',
      canDelete: false
    },
    {
      key: 'secondary',
      canDelete: true
    },
    {
      key: 'tertiary',
      canDelete: true
    }
  ]
  get canNotAddNewTimezone() {
    return !!this.timezoneInfoSynced.tertiary
  }
  handleUpdateTimezone() {
    this.$emit('updateTimezone')
  }
  deleteTimezone(objectKey) {
    delete this.timezoneInfoSynced[objectKey]
    // 削除後なので、tertiaryが存在するパターンはsecondaryが削除された場合のみ。
    if (this.timezoneInfoSynced.tertiary) {
      this.timezoneInfoSynced.secondary = this.timezoneInfoSynced.tertiary
      delete this.timezoneInfoSynced['tertiary']
    }
    this.timezoneInfoSynced = {
      ...this.timezoneInfoSynced
    }
    this.$emit('updateTimezone')
  }
  addTimezone() {
    if (this.timezoneInfoSynced.secondary) {
      this.timezoneInfoSynced = {
        ...this.timezoneInfoSynced,
        tertiary: {
          key: localTimezone()
        }
      }
    } else {
      this.timezoneInfoSynced = {
        ...this.timezoneInfoSynced,
        secondary: {
          key: localTimezone()
        }
      }
    }
    this.$emit('updateTimezone')
  }
}
