









import { useTranslation } from '@/composables/useTranslation'
import { adjustDurationUnit, CardItem } from '@/lib/utils'
import { DisplayWeekDay, TimeBuffer } from '@/models/data'
import { CardItemTip } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import CardBodyItem from './CardBodyItem.vue'

export default defineComponent({
  name: 'WeekdayInfoCardBodyItem',
  components: {
    CardBodyItem
  },
  props: {
    displayWeekDays: {
      type: Array as PropType<DisplayWeekDay[]>,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    timeBuffer: {
      type: Number as PropType<TimeBuffer>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()

    const displayTime = computed(() => {
      const displayDuration = adjustDurationUnit(props.duration)
      const durationLabel =
        displayDuration.type === 'hour'
          ? `${i18n.t('forms.event.durationHour', { hour: displayDuration.duration })}`
          : `${i18n.t('forms.event.duration', { min: displayDuration.duration })}`

      const timeBuffer = `(${i18n.t('availabilitySharing.span')}:${i18n.t('forms.event.duration', {
        min: props.timeBuffer
      })})`

      const displayWeekday = props.displayWeekDays
        .filter((s) => s.check)
        .map((weekday) => i18n.t(`availabilitySharing.weekdays.${weekday.key}`))
        .join(', ')

      return `${durationLabel}${timeBuffer}・${displayWeekday}`
    })

    const tipInfo = computed((): CardItemTip => {
      return CardItem.convertToCardItemTip(true, i18n.t('labels.dateTime').toString())
    })

    return {
      displayTime,
      tipInfo
    }
  }
})
