import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { defaultWeekRule } from '@/lib/utils/peekCandidates'
import { PeekCandidatesSettingsGetResponse, PeekCandidatesSettingResponse } from '@spirinc/contracts'
import ProfileModule from '@/store/modules/profile'
import { EditablePickCandidatesCondition } from '@/types/peekCandidates'

const MODULE_NAME = 'PeekCandidate'

const PEEK_CONDITION_DEFAULT: EditablePickCandidatesCondition = {
  duration: 30,
  start: 'now',
  end: 'one_week',
  dayOfTheWeekRules: defaultWeekRule(),
  timeBuffer: 0,
  countries: [],
  timeZone: ProfileModule.primaryTimezoneKey
}
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class PeekCandidate extends VuexModule {
  condition: EditablePickCandidatesCondition | undefined = undefined

  //当分は1個目の条件を使う
  get editableCondition(): EditablePickCandidatesCondition {
    return this.condition
  }
  @Action
  async fetchCondition(fetchFunction: () => Promise<PeekCandidatesSettingsGetResponse>) {
    const response: PeekCandidatesSettingsGetResponse = await fetchFunction()
    // 保存された値がなかったらDefault値をセットする。
    const condition = response.length > 0 ? response[0] : PEEK_CONDITION_DEFAULT
    this.SET_CONDITION(condition)
    return condition
  }
  @Action
  async fetchCandidates(fetchFunction: () => Promise<any>) {
    return fetchFunction()
  }
  @Action
  async updateCondition(updateFunction: () => Promise<PeekCandidatesSettingResponse>) {
    const response: PeekCandidatesSettingResponse = await updateFunction()
    this.SET_CONDITION(response)
  }
  @Mutation
  SET_CONDITION(newCondition: EditablePickCandidatesCondition) {
    this.condition = newCondition
  }
}
export default getModule(PeekCandidate)
