


















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ICalendar } from '@/types'
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import CalendarSelector from '@/components/newForm/atoms/CalendarSelector.vue'
import SelectedCalendarList from '@/components/newForm/atoms/SelectedCalendarList.vue'
// fixme: 一旦Interfaceは公開URLのAttendeeにする。他のところで使われるようになって変える必要があったらその時また修正する
import { AvailabilityCalendarType } from '@spirinc/contracts'

@Component({
  components: {
    FormItem,
    CalendarSelector,
    SelectedCalendarList
  }
})
export default class FormAttendeeSelector extends Vue {
  @PropSync('selectedAttendee', { type: Array }) selectedAttendeeSynced: AvailabilityCalendarType[]
  @Prop() availableAttendeeList: ICalendar[]

  get filteredAvailableAttendeeList() {
    return this.availableAttendeeList.filter(
      (c) => !this.selectedAttendeeSynced.some((a) => a.accountId === c.accountId && a.calendarId === c.id)
    )
  }

  handleAddCalendar(addedCalendar: AvailabilityCalendarType) {
    this.selectedAttendeeSynced.push(addedCalendar)
    this.$emit('input')
    this.$emit('added', addedCalendar)
  }
  handleDeleteCalendar(deletedCalendar: AvailabilityCalendarType) {
    const findIndex = this.selectedAttendeeSynced.findIndex(
      (s) => s.accountId === deletedCalendar.accountId && s.calendarId === deletedCalendar.calendarId
    )
    if (findIndex >= 0) {
      this.selectedAttendeeSynced.splice(findIndex, 1)
      this.$emit('input')
      this.$emit('deleted', deletedCalendar)
    }
  }
}
