


























import { AvailabilityModel } from '@/models/data'
import { defineComponent, PropType } from '@vue/composition-api'
import Button from '@/components/ui/Button.vue'

export default defineComponent({
  name: 'SettingMenu',
  components: {
    Button
  },
  props: {
    pattern: {
      type: Object as PropType<AvailabilityModel>,
      required: true
    },
    onPublishToggle: {
      type: Function as PropType<(pattern: AvailabilityModel) => Promise<void>>,
      required: true
    },
    onEdit: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    },
    onDelete: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    }
  }
})
