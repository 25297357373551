















import { defineComponent, PropType } from '@vue/composition-api'
import Avatar from 'vue-avatar'
import { CalendarCollection } from './types'

export default defineComponent({
  name: 'CalendarCollectionAvatarWithName',
  components: {
    Avatar
  },
  props: {
    calendarCollection: {
      type: Object as PropType<CalendarCollection>,
      required: true
    }
  }
})
