

































import { Component, Prop, Vue } from 'vue-property-decorator'
import copy from 'clipboard-copy'
import { IOnlineMeeting, OnlineMeetingType } from '@/types/schedule'

@Component
export default class MeetingRoomInfo extends Vue {
  @Prop() onlineMeeting: IOnlineMeeting

  private copyLink() {
    copy(this.onlineMeeting.info.url)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }

  private get serviceName() {
    if (this.onlineMeeting.type === OnlineMeetingType.googleMeet) {
      return this.$t('onlineMeetings.googleMeet')
    } else if (this.onlineMeeting.type === OnlineMeetingType.zoom) {
      return this.$t('onlineMeetings.zoom')
    } else if (this.onlineMeeting.type === OnlineMeetingType.microsoftTeams) {
      return this.$t('onlineMeetings.microsoftTeams')
    }
    return ''
  }
  get showOnlineMeetingInfo() {
    return this.onlineMeeting.type !== 'none'
  }
}
