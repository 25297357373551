














import SpirButton from '@/components/ui/Button.vue'
import PageheadCloseButton from '@/components/ui/page/pagehead/PageheadCloseButton.vue'
import PageheadCloseButtonFrame from '@/components/ui/page/pagehead/PageheadCloseButtonFrame.vue'
import PageheadFrame from '@/components/ui/page/pagehead/PageheadFrame.vue'
import PageheadTitle from '@/components/ui/page/pagehead/PageheadTitle.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { BottomNavHeightState, bottomNavHeightStates } from './_type'

export default defineComponent({
  name: 'FormHeader',
  components: {
    PageheadFrame,
    PageheadTitle,
    PageheadCloseButton,
    PageheadCloseButtonFrame,
    SpirButton
  },
  props: {
    heightState: {
      type: String as PropType<BottomNavHeightState>,
      required: true
    },
    onDetailToggle: {
      type: Function as PropType<() => void>,
      required: true
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const expandButtonIcon = computed(() => (props.heightState === bottomNavHeightStates.EXPANDED ? 'down' : 'up'))
    const size = computed(() => (expandButtonIcon.value === 'down' ? 'm' : 's'))
    const disabled = computed(() => props.heightState === bottomNavHeightStates.CRUSHED)
    return {
      expandButtonIcon,
      size,
      disabled
    }
  }
})
