











import { Component, Prop, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class SelectedCountryItem extends Vue {
  @Prop() label: TranslateResult
  @Prop() onDelete: () => void

  handleDelete() {
    this.onDelete()
  }
}
