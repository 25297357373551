import {
  MemberResponse,
  TeamInvitationResponse,
  TeamsTeamIdMembersSelfGetResponse,
  TeamCalendar as TeamCalendarResponse,
  MemberCalendar,
  MemberCalendarResponse,
  TeamsTeamIdGetResponse,
  PublicTeamsTeamIdGetResponse
} from '@spirinc/contracts'
export class TeamDetailModel {
  id: string
  name: string
  photoUrl?: string
  members?: Array<MemberResponse>
  invitations?: Array<TeamInvitationResponse>
  constructor(data: TeamsTeamIdGetResponse) {
    this.id = data.id
    this.name = data.name
    this.photoUrl = data.photoUrl
    this.members = data.members
    this.invitations = data.invitations
  }
}

export class PublicTeamDetailModel {
  id: string
  name: string
  photoUrl?: string

  constructor(data: PublicTeamsTeamIdGetResponse) {
    this.id = data.id
    this.name = data.name
    this.photoUrl = data.photoUrl
  }
}

export class TeamMemberSelfModel {
  id: string
  userId: string
  fullName?: string
  photoUrl?: string
  authority: 'administrator' | 'member'
  status: 'active' | 'invalid' | 'deleted' | 'invitationExpired' | 'invited'
  calendars: Array<MemberCalendarResponse>

  constructor(data: TeamsTeamIdMembersSelfGetResponse) {
    this.id = data.id
    this.userId = data.userId
    this.fullName = data.fullName || ''
    this.photoUrl = data.photoUrl || ''
    this.authority = data.authority
    this.status = data.status
    this.calendars = data.calendars
  }
}

export type MemberCalendarCheckboxItem = MemberCalendar & { isLoading?: boolean }

export class TeamMemberCalendar {
  id: string
  name: string
  photoUrl?: string
  self: TeamsTeamIdMembersSelfGetResponse
  memberCalendars: Array<MemberCalendarCheckboxItem>
  constructor(data: TeamCalendarResponse) {
    this.id = data.id
    this.name = data.name
    this.photoUrl = data.photoUrl
    this.self = data.self
    this.memberCalendars = data.memberCalendars
  }
}
