

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import TimeLabelWithInterval from '@/components/atoms/TimeLabelWithInterval.vue'

@Component({
  components: {
    TimeLabelWithInterval
  }
})
export default class TimezoneItem extends Vue {
  @Prop() timezoneInfo!: TimezoneDetailWithTimeLabel
  @Prop() icon: string
  @Prop({ default: false }) hideSubLabel
  @Prop() isActive: boolean
  @Prop() selectedTimezone: string
  @Prop({ default: 'medium' }) size: 'small' | 'medium'

  get isSelectedTimeZone() {
    return this.selectedTimezone === this.timezoneInfo.key
  }
  selectTimeZone() {
    this.$emit('selectTimeZone', this.timezoneInfo.key)
  }
}
