import { PollModel } from '@/models/data/poll'
import { ScheduleModel } from '@/models/data/schedule'
import store from '@/store'
import UserModule from '@/store/modules/user'
import { orderBy } from 'lodash'
/**
 * リストPanel表示用
 */
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'
import { ISchedulePollCommon } from '../../types'
import EditScheduleModule from './editSchedule'
import PollListModule, { GetPollListFunction } from './pollList'
import ScheduleListModule, { GetScheduleListFunction } from './scheduleList'

export enum ListType {
  POLL = 'poll',
  SCHEDULE = 'schedule'
}

export interface IScheduleForUI extends Omit<ISchedulePollCommon, 'createdAt'> {
  backgroundColor: string
  foregroundColor: string
  type: ListType
  createdAt: Date
}

const MODULE_NAME = 'ListPanel'
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class ListPanel extends VuexModule {
  // scheduleとPollをあわせて、 それぞれのnextTokenを保存する
  get listForListPanel(): Array<PollModel | ScheduleModel> {
    const scheduleList = ScheduleListModule.getScheduleModelList
    const pollList = PollListModule.getPollModelList
    const listForUI = [...scheduleList, ...pollList]
    return orderBy(listForUI, 'createdAt', 'desc')
  }
  get confirmedList(): Array<PollModel | ScheduleModel> {
    const scheduleList = ScheduleListModule.getConfirmedScheduleList
    const pollList = PollListModule.getConfirmedPollModelList
    const listForUI = [...scheduleList, ...pollList]
    return orderBy(listForUI, 'createdAt', 'desc')
  }
  get isLoading() {
    return ScheduleListModule.isScheduleLoading || PollListModule.getIsPollListLoading
  }
  get isConfirmListLoading() {
    return ScheduleListModule.isConfirmedScheduleLoading || PollListModule.getIsConfirmedPollListLoading
  }
  @Action
  async fetchList(option?: { sequantial: boolean }) {
    if (!UserModule.isSignIn) {
      return
    }
    if (option?.sequantial) {
      await ScheduleListModule.fetchScheduleList()
      await PollListModule.fetchPollList()
    } else {
      ScheduleListModule.fetchScheduleList()
      PollListModule.fetchPollList()
      return Promise.resolve()
    }
  }
  @Action
  async fetchConfirmedList(getScheduleList: GetScheduleListFunction, getPollList: GetPollListFunction) {
    const confirmedFetchingList = [
      PollListModule.fetchConfirmedPollList({ isPast: false, getList: getPollList }),
      ScheduleListModule.fetchConfirmedScheduleList({ isPast: false, getList: getScheduleList })
    ]
    await Promise.all(confirmedFetchingList)
  }
  @Action
  fetchPastList() {
    ScheduleListModule.fetchConfirmedScheduleList({ isPast: true })
    PollListModule.fetchConfirmedPollList({ isPast: true })
  }
  @Action
  async getItemById({ itemId, type }: { itemId: string; type: ListType }) {
    switch (type) {
      case 'schedule': {
        const schedule = await ScheduleListModule.getScheduleByScheduleId(itemId)
        if (!schedule?.fetchCalendarService) {
          return EditScheduleModule.fetchSchedule({ scheduleId: itemId })
        }
        return schedule
      }
      case 'poll':
        return PollListModule.getPollByPollId(itemId)
    }
  }
  @Action
  deleteSchedule(id) {
    const selectedSchedule = this.listForListPanel.find((s) => s.id === id)
    if (!selectedSchedule) {
      return
    }
    if (selectedSchedule.type === 'schedule') {
      return ScheduleListModule.deleteSchedule(id)
    } else {
      return PollListModule.deletePoll(id)
    }
  }
  @Action
  RESET_STATE() {
    ScheduleListModule.RESET_STATE()
    PollListModule.RESET_STATE()
  }
}

export default getModule(ListPanel)
