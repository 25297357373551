













import { Component, Prop, Vue } from 'vue-property-decorator'
import ProfileContentModal from '@/components/modal/ProfileContent.vue'
import { IAttendee } from '../../types/schedule'
import AttendeeIcon from '@/components/atoms/AttendeeIcon.vue'

@Component({
  components: {
    AttendeeIcon
  }
})
export default class ProfileBadge extends Vue {
  @Prop() attendee: IAttendee
  @Prop() iconSize: number

  get userName() {
    return this.attendee.fullName || this.attendee.email
  }
  get userPhoto() {
    return this.attendee.photoURL
  }
  openCreatorProfile() {
    if (!this.attendee.id) {
      return
    }
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ProfileContentModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        id: this.attendee.id
      },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
}
