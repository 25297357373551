









import { Component, Prop, Vue } from 'vue-property-decorator'
import UserIcon from '@/components/atoms/UserIcon.vue'

@Component({
  components: {
    UserIcon
  }
})
export default class UserBadge extends Vue {
  @Prop() userPhoto?: string
  @Prop() userName?: string
  @Prop({ default: 35 }) iconWidth?: number

  handleClick(event) {
    this.$emit('clicked')
    event.stopPropagation()
  }
}
