


















import { defineComponent, computed, toRefs, PropType } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirSelectBox, { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'
import SpirDropdownButton from '@/components/ui/spir/SpirDropdown/SpirDropdownButton.vue'
import SpirDatePicker from '@/components/ui/spir/SpirDatePicker.vue'
import { endSelects, EndSelects } from '@/lib/utils/peekCandidates'
import { useTranslation } from '@/composables/useTranslation'
import { parseISO, isValid } from 'date-fns'
import { DateTime } from 'luxon'
import HelpParagraph from '@/components/ui/domain/item/_HelpParagraph.vue'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export default defineComponent({
  name: 'FormOffsetFromStartSelectBoxItem',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    SpirSelectBox,
    SpirDropdownButton,
    SpirDatePicker,
    HelpParagraph
  },
  props: {
    value: {
      type: String as PropType<EndSelects | string>, // one_dayなどと一緒にDateのStringがありえる。
      required: true
    },
    onInput: {
      type: Function as PropType<(value: EndSelects | string) => void>,
      required: true
    },
    minEndDate: {
      type: Date,
      default: () => new Date()
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { value } = toRefs(props)
    const offsetItems: SelectItem[] = [...endSelects, 'custom'].map((endSelect) => {
      return {
        key: endSelect,
        label: i18n.t(`peekCandidates.dateOptions.${endSelect}`).toString()
      }
    })
    const valueAsDateFormat = computed(() => parseISO(value.value))
    const offsetValue = computed({
      get: () => {
        if (isValid(valueAsDateFormat.value)) {
          return 'custom'
        }
        return value.value
      },
      set: (newValue) => {
        const considerCustom = newValue === 'custom' ? new Date().toISOString() : newValue
        props.onInput(considerCustom)
      }
    })
    const showDatePicker = computed(() => isValid(valueAsDateFormat.value))
    const formatedDate = computed(() =>
      spirDateFormat(
        DateTime.fromJSDate(valueAsDateFormat.value).minus({ seconds: 1 }).toJSDate(),
        spirDateFormatTypes.yyyymmddweekday
      )
    )
    const handleUpdated = (newDate) => {
      props.onInput(DateTime.fromISO(newDate).minus({ seconds: 1 }).toISO())
    }
    return {
      offsetItems,
      offsetValue,
      showDatePicker,
      valueAsDateFormat,
      formatedDate,
      handleUpdated
    }
  }
})
