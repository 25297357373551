




















import UnconfirmedListCard from '@/components/adjustmentList/UnconfirmedListCard.vue'
import LastUpdateInfoCardBodyItem from '@/components/card/atoms/LastUpdateInfoCardBodyItem.vue'
import OrganizerInfoCardBodyItem from '@/components/card/atoms/OrganizerInfoCardBodyItem.vue'
import TimeInfoCardBodyItem from '@/components/card/atoms/TimeInfoCardBodyItem.vue'
import AttendeesCardBodyItem from '@/components/card/molecules/AttendeesCardBodyItem.vue'
import CardTitle from '@/components/ui/CardTitle.vue'
import UnconfirmedStatusOperationBox from '@/components/ui/UnconfirmedStatusOperationBox.vue'
import { CardTitleActionItem, UnconfirmedScheduleCardStatus, UnconfirmedTeamCardInfo } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    CardTitle,
    UnconfirmedListCard,
    OrganizerInfoCardBodyItem,
    TimeInfoCardBodyItem,
    AttendeesCardBodyItem,
    UnconfirmedStatusOperationBox,
    LastUpdateInfoCardBodyItem
  },
  props: {
    info: {
      type: Object as PropType<UnconfirmedTeamCardInfo>,
      required: true
    },
    status: {
      type: Object as PropType<UnconfirmedScheduleCardStatus>,
      required: true
    },
    actionItems: {
      type: Array as PropType<Array<CardTitleActionItem>>,
      required: true
    }
  }
})
