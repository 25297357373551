




import { Component, Prop, Vue } from 'vue-property-decorator'
import HtmlDisplay from '@/components/molecules/HtmlDisplay.vue'

@Component({
  components: {
    HtmlDisplay
  }
})
export default class ConfirmParagraph extends Vue {
  @Prop({ required: true }) text: string
}
