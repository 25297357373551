











import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class SettingsSection extends Vue {
  @Prop({ default: '' }) title
}
