/* eslint-disable @typescript-eslint/camelcase */
import { SendUserInfoToExternalService } from '@/models/data/userInfo'

class KarteService {
  sendUserInfo(userInfo?: SendUserInfoToExternalService) {
    if (userInfo) {
      window.tracker.track('identity', {
        ...userInfo,
        user_id: userInfo.id
      })
    } else {
      window.tracker.track('identity', null)
    }
  }
  shutdown() {
    window.tracker.user({})
  }
}

export const karteService = new KarteService()
