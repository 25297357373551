






















































import { defineComponent, toRefs, computed } from '@vue/composition-api'
import AppModule from '@/store/modules/app'
import TodayButton from '@/components/calendar/header/TodayButton.vue'
import NavigationButton from '@/components/calendar/header/NavigationButton.vue'
import DatepickerButton from '@/components/calendar/header/DatepickerButton.vue'
import TimezoneIconButton from '@/components/organisms/timezoneButton/TimezoneIconButton.vue'
import HelpButton from '@/components/calendar/header/HelpButton.vue'
import ViewButton from '@/components/calendar/header/ViewButton.vue'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import CalendarControlModule from '@/store/modules/calendarControl'
import { fromtDateToCalendarHeader } from '@/lib/utils/converters'
import ProfileModule from '@/store/modules/profile'

export default defineComponent({
  name: 'CalendarHeader',
  components: {
    NavigationButton,
    TodayButton,
    DatepickerButton,
    HelpButton,
    ViewButton,
    TimezoneIconButton
  },
  props: {
    hideController: {
      type: Boolean
    },
    eventDate: {
      type: Object
    },
    currentView: {
      type: Number
    },
    hideTimezone: {
      type: Boolean,
      default: false
    },
    isSignIn: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { eventDate, isSignIn } = toRefs(props)
    const isNarrow = computed(() => AppModule.isNarrow)
    const isShowCalendarList = computed(() => AppScreenControlModule.showCalendarList)
    const headerDateFormat = computed(() => {
      if (!eventDate.value.start) {
        return ''
      }
      return fromtDateToCalendarHeader(new Date(), eventDate.value.start, CalendarControlModule.nextPeriodDate)
    })
    const isShowHelpButton = computed(() => {
      if (isNarrow.value || isSignIn.value || ProfileModule.getLanguage !== 'ja') {
        return false
      }
      return true
    })
    return {
      headerDateFormat,
      isCalendarLockMode: computed(() => AppScreenControlModule.calendarLockMode),
      isNarrow,
      isShowHelpButton,
      isShowCalendarList,
      handleShowQuickView: () => AppModule.ON_SHOW_QUICK_VIEW(),
      handleDisplayCalendarList: () => AppScreenControlModule.SET_SHOW_CALENDAR_LIST(true),
      handleMoveCurrentDate: (type) => emit('moveCurrentDate', type),
      handleSetCurrentDate: (newDate) => emit('setCurrentDate', newDate),
      handleSetCurrentView: (days) => emit('setCurrentView', days)
    }
  }
})
