import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators'
import firebase from 'firebase/app'
import store from '@/store'
import { setEnabledFeatures } from 'vue-feature-flipping'
import { TogglableFeature, TogglableFeatures, AbTestingTogglableFeatures } from '@/types/feature'

@Module({
  dynamic: true,
  name: 'FeatureToggler',
  namespaced: true,
  store
})
export class FeatureToggler extends VuexModule {
  @Action
  initialize() {
    const values = firebase.remoteConfig().getAll()
    const setFeatureToggle = (features: { [key: string]: TogglableFeature }) => {
      const enabledFeatures = Object.values(features)
        .filter((feature) => !!values[feature.remoteConfigKeyName] && values[feature.remoteConfigKeyName].asBoolean())
        .map((feature) => feature.featureName)
      setEnabledFeatures(enabledFeatures)
    }
    setFeatureToggle(TogglableFeatures)
    setFeatureToggle(AbTestingTogglableFeatures)
  }
}
export default getModule(FeatureToggler)
