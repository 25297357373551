import { computed, onMounted, ref } from '@vue/composition-api'

export const useDeferredFeature = (count = 10) => {
  const displayPriority = ref(0)

  function runDisplayPriority() {
    const step = () => {
      requestAnimationFrame(() => {
        displayPriority.value += 1
        if (displayPriority.value < count) {
          step()
        }
      })
    }
    step()
  }

  function defer(priority) {
    return displayPriority.value >= priority
  }

  onMounted(() => {
    runDisplayPriority()
  })

  return {
    defer
  }
}
