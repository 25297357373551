














// サイドメニューの上の段
import { Component, Prop, Vue } from 'vue-property-decorator'
import TeamModule from '@/store/modules/team'
import AsideMenuItemTeam from './molecules/AsideMenuItemTeam.vue'
import { RouteNames } from '@/router'
import { SignalType } from '@/lib/analytics/LogEntry'

type IconMenu = {
  icon: string
  label: string
  routeName: RouteNames
}

@Component({
  components: {
    AsideMenuItemTeam
  }
})
export default class NavMenuTeam extends Vue {
  @Prop({ default: false }) expanded: boolean

  iconMenus: IconMenu[] = []
  mounted() {
    this.iconMenus = [
      {
        icon: 'calendar',
        label: this.$t('globalNavigation.list').toString(),
        routeName: 'ScheduleList'
      },
      {
        icon: 'link',
        label: this.$t('globalNavigation.availabilityList').toString(),
        routeName: 'AvailabilityList'
      }
    ]
  }

  get teams() {
    return TeamModule.myTeams || []
  }
  clickedRouter(teamId) {
    return this.$analytics.send(SignalType.SHOW_TEAM_SETTING, { teamId: teamId })
  }
}
