






















import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'

@Component({
  components: {
    CommonLayoutModal: () => Promise.resolve(CommonLayoutModal) // solving circular dependency
  }
})
export default class ConfirmModal extends Vue {
  @Prop({ default: null }) header: string
  @Prop() body: string
  @Prop({ default: '' }) cancelBtn: string
  @Prop({ default: '' }) confirmBtn: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: true }) closeWhenButtonClicked: boolean
  @Prop({ default: 'default-type' }) btnType: 'is-danger' | 'default-type'

  get cancelButtonTitle() {
    return this.cancelBtn || this.$t('buttons.cancel').toString()
  }
  get confirmButtonTitle() {
    return this.confirmBtn || this.$t('buttons.confirm').toString()
  }

  handleCancel() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('cancel')
  }
  handleConfirm() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('confirm')
  }
}
