




















import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from '../CommonLayout.vue'
import TimeZoneSelector from '@/components/organisms/timezoneSelector/TimezoneSelector.vue'
import TimeZoneMaster from '../../../store/modules/timezones'

@Component({
  components: {
    CommonLayoutModal,
    TimeZoneSelector
  }
})
export default class TimeZoneKeySelectorModal extends Vue {
  @Prop() timezoneKey: string
  @Prop() isUpdateStore: boolean

  timeZoneForEdit = ''

  created() {
    this.timeZoneForEdit = this.timezoneKey
  }
  onChangeTimeZone(code: string) {
    this.timeZoneForEdit = code
  }
  async selectTimezone() {
    this.$emit('updateTimezoneInfo', this.timeZoneForEdit)
    if (this.isUpdateStore) {
      TimeZoneMaster.UpdateTimezoneForInterrupt(this.timeZoneForEdit)
    }
  }
}
