











import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmedScheduleOperationBox extends Vue {
  handleEdit(e) {
    e.stopPropagation()
    this.$emit('edit')
  }
  handleUrlCopy(e) {
    e.stopPropagation()
    this.$emit('copy')
  }
  handleDelete(e) {
    e.stopPropagation()
    this.$emit('delete')
  }
}
