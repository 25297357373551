






















import FormAttendingEmails from '@/components/ui/domain/item/FormAttendingEmails.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import { defineComponent } from '@vue/composition-api'
import CommonBasicInfoSection from './_CommonBasicInfoSection.vue'
import FormOrganizerDropdownItem from '@/components/ui/domain/item/FormOrganizerDropdownItem.vue'

export default defineComponent({
  components: {
    CommonBasicInfoSection,
    ListItemSpacer,
    FormOrganizerDropdownItem,
    FormAttendingEmails
  },
  props: {
    basicInfo: {
      type: Object,
      required: true
    }
  }
})
