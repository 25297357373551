











































import { CalendarAlertState } from '@/components/layout/types'
import CreateCandidatesControls from '@/components/schedule/ScheduleCreateCandidatesControls.vue'
import Skeleton from '@/components/sidePanels/edit/Skeleton.vue'
import ArrangementPreviewModal from '@/components/ui/domain/modal/arrangement/ArrangementPreviewModal.vue'
import { useMobileCalendarFooterSetup } from '@/components/ui/domain/section/arrangement/composables/useMobileCalendarFooterSetup'
import MobileCalendarCandidatesControls from '@/components/ui/domain/section/arrangement/MobileCalendarCandidatesControls.vue'
import MobileCalendarFooter from '@/components/ui/domain/section/arrangement/MobileCalendarFooter.vue'
import {
  ArrangementTypeFormData,
  PeekCandidatesForm,
  TitleSuggestionSetups
} from '@/components/ui/domain/sections/arrangement/composables/types'
import NewArrangementPanel from '@/components/ui/domain/sidePanel/arrangement/NewArrangementPanel/index.vue'
import {
  ArrangementFormRequest,
  ArrangementPeekCandidatesInitializingWay,
  CreateArrangementSidePanelInfo,
  PersonalTeamInfoGetters
} from '@/components/ui/domain/sidePanel/arrangement/types'
import {
  PeekCandidatesControl,
  usePeekCandidatesPanelHandler
} from '@/components/ui/domain/sidePanel/arrangement/useArrangementPeekCandidatesSidePanel'
import { useNewArrangementsFormData } from '@/components/ui/domain/sidePanel/arrangement/useNewArrangementsFormData'
import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { useDeviceInfo } from '@/composables/useDeviceInfo'
import { useHolidayCountryRemover } from '@/composables/useHolidayCountryRemover'
import { useTranslation } from '@/composables/useTranslation'
import { ScheduleDuration } from '@/models/data/schedule'
import ProfileModule from '@/store/modules/profile'
import TeamModule from '@/store/modules/team'
import TempEventModule from '@/store/modules/tempEvent'
import { CreateEvent, FullCalendarEvent } from '@/types'
import Common from '@/views/calendar/Common.vue'
import { computed, defineComponent, onMounted, PropType, provide } from '@vue/composition-api'

export default defineComponent({
  components: {
    Common,
    Skeleton,
    NewArrangementPanel,
    ArrangementPreviewModal,
    CreateCandidatesControls,
    MobileCalendarFooter,
    MobileCalendarCandidatesControls
  },
  props: {
    initialFormRequest: {
      type: Object as PropType<ArrangementFormRequest>,
      required: true
    },
    titleSuggestionSetups: {
      type: Object as PropType<TitleSuggestionSetups>,
      required: true
    },
    onLastFormRequestSave: {
      type: Function as PropType<(formRequest: ArrangementFormRequest) => void>,
      required: true
    },
    canShowAutoCandidateInfo: {
      type: Boolean,
      required: true
    },
    onAutoPeekCandidatesInfoNextTimeCheckSave: {
      type: Function as PropType<(doNotShowNextTime: boolean) => void>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { toggleScheduleEventsList, isScheduleEventListOpen } = useCalendarMixin()
    const { isNarrow } = useDeviceInfo()
    const personalTeamInfoGetters = {
      getMyProfile: () => ProfileModule.myProfile,
      getMyTeams: () => TeamModule.myTeams
    }
    const { getHolidayCountryRemover } = useHolidayCountryRemover()
    provide(PersonalTeamInfoGetters, personalTeamInfoGetters)
    const getArrangementPeekCandidatesInitializingWay = (): ArrangementPeekCandidatesInitializingWay => {
      if (TempEventModule.getTempEvent) {
        const draggedEvent = { ...TempEventModule.getTempEvent }
        TempEventModule.RESET()
        return {
          type: 'from_dragged_event',
          event: draggedEvent
        }
      }
      if (ProfileModule.arrangementSettings?.peekCandidatesEnabled && !isNarrow.value) {
        return {
          type: 'from_auto'
        }
      }
      return {
        type: 'from_blank'
      }
    }
    const arrangementPeekCandidatesInitializingWay = getArrangementPeekCandidatesInitializingWay()
    const calendarAlertState: CalendarAlertState =
      arrangementPeekCandidatesInitializingWay.type === 'from_blank'
        ? { type: 'flashOpen', title: i18n.t('message.dragToAddCandidates').toString() }
        : { type: 'closed' }
    const { changeFormRequest, getFormDataFunctions, isLoading, isCandidatesLoading } = useNewArrangementsFormData({
      formRequest: props.initialFormRequest,
      suggestionSetups: props.titleSuggestionSetups,
      setLastFormRequest: props.onLastFormRequestSave,
      getMyTeams: personalTeamInfoGetters.getMyTeams,
      canShowAutoCandidateInfo: props.canShowAutoCandidateInfo,
      saveAutoPeekCandidatesInfoNextTimeCheck: props.onAutoPeekCandidatesInfoNextTimeCheckSave,
      candidatesInitializingWay: arrangementPeekCandidatesInitializingWay
    })
    provide(CreateArrangementSidePanelInfo, { changeFormRequest, getFormDataFunctions })
    const formDataFunctions = computed(() => getFormDataFunctions())
    const formData = computed((): ArrangementTypeFormData => formDataFunctions.value.getAllData())
    const formRequest = computed(() => formDataFunctions.value.getFormRequest())
    provide(PeekCandidatesControl, { formData })
    const { isPeekCandidateSectionOpened, candidatesControls, openPanel } = usePeekCandidatesPanelHandler({ formData })
    const candidates = computed(() => candidatesControls.value.candidates)
    const handleUpdate = computed(() => candidatesControls.value.handleUpdate)
    const handleDelete = computed(() => candidatesControls.value.handleDelete)
    const isSidePanelVisible = computed(() => isScheduleEventListOpen.value)
    const { canPreview, handlePreview, handleFormCancel, handleCalendarClose } = useMobileCalendarFooterSetup({
      formData
    })

    function handleHolidayExceptionRemoveWithConfirmationModal(event: FullCalendarEvent) {
      const peekCandidatesForm: PeekCandidatesForm = formData.value.peekCandidatesForm
      const remover = getHolidayCountryRemover(peekCandidatesForm.countriesForm.handleCountryCodeRemove)
      remover(event)
    }

    onMounted(() => {
      toggleScheduleEventsList(true)
    })

    return {
      isLoading,
      isPeekCandidateSectionOpened,
      isCandidatesLoading,
      isSidePanelVisible,
      isSelectable: true,
      calendarAlertState,
      formData,
      formRequest,
      candidates: candidates,
      canPreview,
      handlePreview,
      handleFormCancel,
      handleCalendarClose,
      handlePeekCandidatesPanelOpen: () => {
        toggleScheduleEventsList(true)
        openPanel()
      },
      handleDurationUpdate: (newDuration: ScheduleDuration) => {
        formData.value.basicInfo.duration.handleChange(newDuration)
      },
      handleEventUpdate: ({ event, revert }: { event: FullCalendarEvent; revert: Function }) => {
        handleUpdate.value(event, revert)
      },
      handleCandidateClicked: (id: string) => {
        handleDelete.value(id)
      },
      handleEventAdd: (data: CreateEvent) => {
        handleUpdate.value(data)
      },
      handleHolidayExceptionRemoveWithConfirmationModal
    }
  }
})
