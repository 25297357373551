import { NavigationGuard } from 'vue-router'

export const useArrangementChangeGuard = () => {
  return {
    guard: async (shouldStayHere: () => Promise<boolean>, leaveGuard: Parameters<NavigationGuard>) => {
      const [from, to, next] = leaveGuard // eslint-disable-line
      const noLeave = await shouldStayHere()
      if (noLeave) return next(false)
      else next()
    }
  }
}
