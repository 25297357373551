import {
  ArrangementTypeFormData,
  FormDataForInitializing
} from '@/components/ui/domain/sections/arrangement/composables/types'
import { FullCalendarEvent } from '@/types'

export const CreateArrangementSidePanelInfo = 'CreateArrangementSidePanelInfo'
export const PersonalTeamInfoGetters = 'PersonalTeamInfoGetters'

export const ArrangementFormStateTypes = {
  personalSchedule: 'personalSchedule',
  teamSchedule: 'teamSchedule',
  personalPoll: 'personalPoll'
} as const

export type ArrangementFormStateType = keyof typeof ArrangementFormStateTypes

export type ArrangementFormRequest =
  | { type: 'personalSchedule' }
  | { type: 'teamSchedule'; id: string }
  | { type: 'personalPoll' }

export type CurrentAllData = () => (ArrangementTypeFormData & { isForInitializing?: false }) | FormDataForInitializing
export type ArrangementPeekCandidatesInitializingWay =
  | { type: 'from_auto' }
  | { type: 'from_dragged_event'; event: FullCalendarEvent }
  | { type: 'from_blank' }
