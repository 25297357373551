




import SignInPage from '@/components/page/SignInPage.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    SignInPage
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'index,follow'
        }
      ]
    }
  }
})
