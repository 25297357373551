




import { Component, Prop, Vue } from 'vue-property-decorator'
import { copyClipboard } from '@/lib/utils'
import UserModule from '@/store/modules/user'

@Component
export default class ShareButton extends Vue {
  @Prop() fullName: string

  get currentUser() {
    return UserModule.currentUser
  }

  share() {
    const profileUrl = `${process.env.VUE_APP_SITE_URL}${this.$route.path}/${this.currentUser.uid}`
    copyClipboard(profileUrl)
    this.$buefy.toast.open({
      message: this.$t('message.profileUrlCopied').toString()
    })
  }
}
