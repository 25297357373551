



















import { Component, Vue } from 'vue-property-decorator'
import ListPanelLayout from '@/components/layout/ListPanel.vue'
import TeamModule from '@/store/modules/team'
import ProfileModule from '@/store/modules/profile'
import TeamSelectorTabWithQueryParamsSync from '@/components/molecules/TeamSelectorTab/TeamSelectorTabWithQueryParamsSync.vue'
import PrivateSchedulePanel from './panel/PrivateSchedulePanel.vue'
import TeamSchedulePanel from './panel/TeamSchedulePanel.vue'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'

@Component({
  components: {
    ListPanelLayout,
    TeamSelectorTabWithQueryParamsSync,
    PrivateSchedulePanel,
    TeamSchedulePanel
  }
})
export default class ScheduleListPanel extends Vue {
  get myProfile() {
    return ProfileModule.myProfile
  }
  get teams() {
    return TeamModule.myTeams
  }
  get hasTeam() {
    return this.teams?.length > 0
  }
  async handleUpdatedTab(newValue: 'private' | string) {
    // only team
    if (newValue !== 'private') {
      try {
        await TeamScheduleListModule.fetchAllTypeSchedules({ teamId: newValue, hideLoader: false })
      } catch (e) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: this.$t('message.errorCommon').toString()
        })
      }
    }
  }
}
