






import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ButtonToWrapIcon extends Vue {
  @Prop({ required: true }) onClick: () => void
  @Prop({ default: false }) disabled: boolean
  handleClick() {
    this.onClick()
  }
}
