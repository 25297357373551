import {
  DateSlot,
  DatetimeSlot,
  toNoConfirmationDatetimeSlot
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { validateCandidate } from '@/lib/utils'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { CandidateError } from '@/models/data/error'
import { FullCalendarEvent } from '@/types'
import { computed, getCurrentInstance, reactive } from '@vue/composition-api'
import { chain } from 'lodash'

type UsePeekCandidatesSectionSetup = {
  saveCandidate: <C extends { start: Date; end: Date; id?: string }>(paload: C) => Promise<void>
  clearAllCandidates: () => void
  deleteCandidate: (id: string) => void
  getFullCalendarEvents: () => FullCalendarEvent[]
  generateGotoConfirmation: (event: FullCalendarEvent) => () => void
  isEditMode?: boolean
}
export type UsePeekCandidatesSection = {
  candidates: FullCalendarEvent[]
  dateSlots: DateSlot[]
  hasAtLeastOneCandidate: boolean
  addNewCandidate: <T extends { start: Date; end: Date; id?: string }>(payload: T, revert: Function | null) => void
  clearCandidates: () => void
  removeCandidate: (candidateId: string) => void
}
export const usePeekCandidatesSectionSetupInitializer = () => {
  const { openDangerBottomToast } = useToast()
  const i18n = useTranslation()
  const currentInstance = getCurrentInstance()

  return {
    initialize: ({
      saveCandidate,
      deleteCandidate,
      clearAllCandidates,
      getFullCalendarEvents,
      generateGotoConfirmation,
      isEditMode = false
    }: UsePeekCandidatesSectionSetup): UsePeekCandidatesSection => {
      const candidates = computed<FullCalendarEvent[]>(() => getFullCalendarEvents())
      const hasAtLeastOneCandidate = computed(() => candidates.value.length > 0)
      function clearCandidates() {
        clearAllCandidates()
      }
      // Fixme: ロジックはuseScheduleMixinから流用
      async function addNewCandidate<T extends { start: Date; end: Date; id?: string }>(
        payload: T,
        revert: Function | null = null
      ) {
        try {
          await saveCandidate(payload)
        } catch (e) {
          let errorMessageKey = 'message.errorCommon'
          if (e instanceof CandidateError) {
            if (currentInstance.proxy.$te(e.errorMessageKey)) {
              errorMessageKey = e.errorMessageKey
            }
          }
          openDangerBottomToast({
            message: i18n.t(errorMessageKey).toString()
          })
          revert ? revert() : ''
        }
      }
      function removeCandidate(id: string) {
        deleteCandidate(id)
      }

      function getDateSlots(cs: FullCalendarEvent[]): DateSlot[] {
        const filtered = cs.filter(
          (event) =>
            event.id !== 'confirmEvent' &&
            event.extendedProps.source !== 'confirmer' &&
            event.extendedProps.source !== 'confirmerUnderThirty'
        )
        const dateSlots = chain(filtered)
          .sortBy('start')
          .groupBy((event) => spirDateFormat(event.start, spirDateFormatTypes.yyyymmddweekday))
          .map((fullCalendarEvents, key) => {
            const datetimeSlots: DatetimeSlot[] = fullCalendarEvents.map((event: FullCalendarEvent) => {
              const noConfirmation: Omit<DatetimeSlot, 'isValidCandidate'> = toNoConfirmationDatetimeSlot(event)
              const isValidCandidate = validateCandidate(event)
              const base = { ...noConfirmation, isValidCandidate }
              return isEditMode && event.extendedProps?.canConfirm
                ? { ...base, confirmation: { gotoConfirmation: generateGotoConfirmation(event) } }
                : base
            })
            return { id: key, title: key, datetimeSlots }
          })
          .value()
        return dateSlots
      }
      const dateSlots = computed<DateSlot[]>(() => getDateSlots(candidates.value))

      return reactive({
        candidates,
        dateSlots,
        hasAtLeastOneCandidate,
        addNewCandidate,
        clearCandidates,
        removeCandidate
      })
    }
  }
}
export const usePeekCandidatesSectionSetup = (initial: UsePeekCandidatesSectionSetup) => {
  const { initialize } = usePeekCandidatesSectionSetupInitializer()
  return initialize(initial)
}
