














import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirCheckbox',
  props: {
    value: {
      type: Boolean
    },
    onClick: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const isIndeterminate = computed(() => props.value === undefined)
    return {
      isIndeterminate
    }
  }
})
