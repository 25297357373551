










import ConfirmedScheduleTimeInfoCardBodyItem from '@/components/card/atoms/ConfirmedScheduleTimeInfoCardBodyItem.vue'
import OrganizerInfoCardBodyItem from '@/components/card/atoms/OrganizerInfoCardBodyItem.vue'
import CardTitle from '@/components/ui/CardTitle.vue'
import { ConfirmedTeamCardInfo } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    CardTitle,
    OrganizerInfoCardBodyItem,
    ConfirmedScheduleTimeInfoCardBodyItem
  },
  props: {
    info: {
      type: Object as PropType<ConfirmedTeamCardInfo>,
      required: true
    }
  }
})
