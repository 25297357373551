









import { Component, Vue } from 'vue-property-decorator'
import Vue100vh from 'vue-div-100vh'
import ProfileModule from '@/store/modules/profile'

@Component({
  components: {
    Vue100vh
  }
})
export default class ProfileLayout extends Vue {
  get isLoading() {
    return ProfileModule.isLoading
  }
}
