import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { FrontSupportLanguage, FullCalendarEvent, ScheduleSource } from '@/types'
import * as pollAPI from '../../lib/api/poll'
import { sortBy } from 'lodash'
import { parseISO, isBefore } from 'date-fns'
import EditPollModule from './editPoll'
import UserModule from './user'
import i18n from '@/i18n'
import { PollAnswer, CandidateVote } from '@/types/poll'
import TimezoneModule from './timezones'
import { Confirmer } from '@/models/data/'

export type CandidateForVote = CandidateVote & {
  yesCount: number
  noCount: number
  isDisabled: boolean
}
export type VoteStatusProps = Pick<CandidateForVote, 'votes' | 'yesCount' | 'noCount'>

export type IVote = {
  candidateId: string
  answer: PollAnswer
}

const toggleAnswer = (answer: PollAnswer): PollAnswer => {
  if (answer === 'yes') {
    return 'no'
  }
  return 'yes'
}

const MODULE_NAME = 'Vote'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Vote extends VuexModule {
  private votes: IVote[] = []
  private isLoading = false
  private maxYesCount = 0
  private currentVotes: CandidateForVote[] = []

  get isAllAnswerNo() {
    return !this.votes.some((v) => v.answer === 'yes')
  }
  get getVotes() {
    return this.votes
  }
  get myVotes() {
    return this.votes
  }
  get getisLoading() {
    return this.isLoading
  }
  get getCandidatesAsCalendarFormat(): FullCalendarEvent[] {
    return EditPollModule.getEditingEventByCalendarFormat.map((event) => {
      const myAnswer = this.votes.find((v) => v.candidateId === event.id)
      const source: ScheduleSource =
        event.extendedProps.source === 'expiredPoll'
          ? 'expiredPoll'
          : myAnswer && myAnswer.answer === 'yes'
            ? 'pollAnswerYes'
            : 'pollAnswerNo'
      return {
        ...event,
        title: i18n.t('groupPoll.candidate').toString(),
        extendedProps: { ...event.extendedProps, source, editable: false },
        editable: false
      }
    })
  }
  get getCurrentVotes() {
    return this.currentVotes
  }
  get getMaxYesCount() {
    return this.maxYesCount
  }
  @Action
  initVotes() {
    const myId = UserModule.currentUser?.uid
    const didIVote = myId ? EditPollModule.editingPoll.attendees.find((a) => a.id === myId) : false
    const myVotes: IVote[] = []
    let maxYesCount = 0 // Yesが一番多いところに色を変えるために必要
    const currentVotes: CandidateForVote[] = sortBy(EditPollModule.getExistCandidates, ['start']).map(
      (c: CandidateVote) => {
        myVotes.push({
          candidateId: c.id,
          answer: didIVote ? c.votes.find((v) => v.attendee.id === myId).answer : 'no'
        })
        const yesCount = c.votes.filter((v) => v.answer === 'yes').length
        const noCount = c.votes.filter((v) => v.answer === 'no').length
        if (yesCount > maxYesCount) {
          maxYesCount = yesCount
        }
        return {
          id: c.id,
          start: parseISO(c.start),
          end: parseISO(c.end),
          yesCount,
          noCount,
          votes: c.votes,
          isDisabled: isBefore(parseISO(c.start), new Date())
        }
      }
    )
    this.SET_VOTES(myVotes)
    this.SET_CURRENT_VOTES(currentVotes)
    this.SET_MAX_YES_COUNT(maxYesCount)
  }
  @Action
  updateAnswer({ candidateId, newAnswer }: { candidateId: string; newAnswer?: PollAnswer }) {
    const voteIndexByCandidateId = this.votes.findIndex((v) => v.candidateId === candidateId)
    if (voteIndexByCandidateId >= 0) {
      const newVotes = [...this.votes]
      newVotes[voteIndexByCandidateId].answer = newAnswer
        ? newAnswer
        : toggleAnswer(newVotes[voteIndexByCandidateId].answer)
      this.SET_VOTES(newVotes)
    }
  }
  @Action
  async vote({ confirmer }: { confirmer: Confirmer }) {
    this.SET_LOADING(true)
    const votesPayload = {
      ...confirmer,
      votes: this.getVotes,
    }
    try {
      const pollId = EditPollModule.editingPoll.id
      await pollAPI.vote(pollId, votesPayload)
      EditPollModule.setPollAsEditingPoll({ pollId: pollId })
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async updateVote({ email, name, language }: { email: string; name?: string; language?: FrontSupportLanguage }) {
    this.SET_LOADING(true)
    const votesPayload = {
      email,
      name,
      votes: this.getVotes,
      timeZone: TimezoneModule.userTimezoneKey,
      language
    }

    try {
      const pollId = EditPollModule.editingPoll.id
      await pollAPI.updateVote(pollId, votesPayload)
      EditPollModule.setPollAsEditingPoll({ pollId: pollId })
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Mutation
  SET_VOTES(votes) {
    this.votes = votes
  }
  @Mutation
  SET_LOADING(flag: boolean) {
    this.isLoading = flag
  }
  @Mutation
  SET_CURRENT_VOTES(currentVotes: CandidateForVote[]) {
    this.currentVotes = currentVotes
  }
  @Mutation
  SET_MAX_YES_COUNT(maxCount: number) {
    this.maxYesCount = maxCount
  }
}

export default getModule(Vote)
