





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ScheduleTab extends Vue {
  @Prop() unconfirmedSchedulesCount: number

  activeTab = 0

  get isVisibleUnconfirmedSchedulesLabel(): boolean {
    return this.unconfirmedSchedulesCount > 0
  }
}
