















import AuthPageLayout from '@/components/layout/auth/AuthPageLayout.vue'
import SignUpFormBox from '@/components/auth/SignUpFormBox.vue'
import { defineComponent } from '@vue/composition-api'
import { useSignUpSetup } from '@/composables/auth/useSignUpSetup'
import GoogleSignUpFormBox from '@/components/auth/GoogleSignUpFormBox.vue'
import { useAuthSignUp } from '@/composables/auth/useAuthSignUpWithRedirect'

export default defineComponent({
  components: {
    AuthPageLayout,
    SignUpFormBox,
    GoogleSignUpFormBox
  },
  setup() {
    const { isGoogleSelected, handleGoogleSelect, handleGoogleSignUpFailed } = useSignUpSetup()
    const { signUpWithMicrosoft, signUpWithGoogle } = useAuthSignUp()
    const handleSignUpWithMicrosoft = () => {
      return signUpWithMicrosoft()
    }
    const handleSignUpAndAddCalendarToGoogle = () => {
      return signUpWithGoogle()
    }
    return {
      isGoogleSelected,
      handleGoogleSelect,
      handleGoogleSignUpFailed,
      handleSignUpWithMicrosoft,
      handleSignUpAndAddCalendarToGoogle
    }
  }
})
