import Vue from 'vue'
import Component from 'vue-class-component'
import ScheduleDetailModal from '@/components/modal/ScheduleDetailModal.vue'
import ProfileModule from '@/store/modules/profile'
import CalendarsModule from '@/store/modules/calendars'
@Component
export default class ScheduleCreateMixin extends Vue {
  showInputProfileModal = null

  beforeDestroy() {
    if (this.showInputProfileModal) {
      clearTimeout(this.showInputProfileModal)
    }
  }

  get myProfile() {
    return ProfileModule.myProfile
  }
  get hasPrimaryCalendar() {
    return CalendarsModule.primaryCalendars?.length > 0
  }
  get primaryCalendars() {
    return CalendarsModule.primaryCalendars
  }
  get allWritableCalendarsButNotResource() {
    return CalendarsModule.allWritableCalendarsButNotResource
  }

  showDetails(schedule) {
    if (!schedule) return

    this.$buefy.modal.open({
      parent: this,
      component: ScheduleDetailModal,
      hasModalCard: true,
      props: {
        schedule
      }
    })
  }
}
