













































import firebase from 'firebase/app'
import Compress from 'client-compress'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { IProfileBasic } from '@/types'
import ImageUploader from '@/components/profile/ImageUploader.vue'
import UserModule from '@/store/modules/user'

type ImageType = 'photo' | 'backgroundPhoto'

type CompressOptions = {
  targetSize: number // in MB
  quality: number
  maxWidth: number
  maxHeight: number
}

const bucketNameMap: Record<ImageType, string> = {
  photo: 'photo',
  backgroundPhoto: 'backgroundPhoto'
}
const imageFieldNameMap: Record<ImageType, string> = {
  photo: 'photoURL',
  backgroundPhoto: 'backgroundPhotoURL'
}

const defaultBackgroundCompressOptions: CompressOptions = {
  targetSize: Infinity,
  quality: 0.75,
  maxWidth: 1200,
  maxHeight: 1000
}

const defaultAvatarCompressOptions: CompressOptions = {
  targetSize: Infinity,
  quality: 0.75,
  maxWidth: 250,
  maxHeight: 250
}

const defaultCompressOptions: Record<ImageType, CompressOptions> = {
  photo: defaultAvatarCompressOptions,
  backgroundPhoto: defaultBackgroundCompressOptions
}

@Component({
  components: {
    ImageUploader
  }
})
export default class ProfileHeader extends Vue {
  get currentUser() {
    return UserModule.currentUser
  }

  @Prop({ default: true })
  isViewOnly: boolean

  @Prop()
  id: Pick<IProfileBasic, 'id'>

  @Prop()
  fullName: Pick<IProfileBasic, 'fullName'>

  @Prop()
  headLine: Pick<IProfileBasic, 'headLine'>

  @PropSync('photoURL', { type: String }) photoURLSynced!: string
  @PropSync('backgroundPhotoURL', { type: String })
  backgroundPhotoURLSynced!: string

  imageSizeLimitKB = 12000

  get isProfileOwner() {
    return this.currentUser && this.currentUser.uid === this.id
  }

  async uploadImage(imageType: ImageType, file: File) {
    const compress = new Compress(defaultCompressOptions[imageType])

    const fileName = file.name
    const bucketName = bucketNameMap[imageType]
    const imageFieldName = imageFieldNameMap[imageType]
    const uid = this.currentUser.uid
    const storagePath = `users/${uid}/${bucketName}/${fileName}`

    const {
      photo: { data }
    } = (await compress.compress([file]))[0]

    const fileData = await firebase.storage().ref(storagePath).put(data)
    this[`${imageFieldName}Synced`] = await fileData.ref.getDownloadURL()
  }
}
