



























import LanguageDropdown from '@/components/ui/domain/dropdown/LanguageDropdown/index.vue'
import Divider from '@/components/atoms/Divider.vue'
import SettingsSection from '@/components/organisms/settings/Section.vue'
import SpirButton from '@/components/ui/Button.vue'
import TimeZoneSettingForm from '@/components/ui/domain/section/settings/TimeZoneSettingForm.vue'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { TimeZones } from '@/models/data/userInfo'
import { FrontSupportLanguage } from '@/types'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'LocalizationAndTimezoneSection',
  components: {
    LanguageDropdown,
    SettingsSection,
    SpirButton,
    Divider,
    TimeZoneSettingForm
  },
  props: {
    language: {
      type: String as PropType<FrontSupportLanguage>,
      required: true
    },
    onLanguageUpdate: {
      type: Function as PropType<(lang: FrontSupportLanguage) => Promise<void>>,
      required: true
    },
    timeZones: {
      type: Object as PropType<TimeZones>,
      required: true
    },
    onTimeZonesUpdate: {
      type: Function as PropType<(timeZones: TimeZones) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const { openPrimaryTopToast, openDangerTopToast } = useToast()
    const i18n = useTranslation()
    const isLanguageDirty = ref<boolean>(false)
    const isLanguageLoading = ref<boolean>(false)
    const currentLanguage = ref<FrontSupportLanguage>(props.language)
    async function handleLanguageUpdate() {
      try {
        isLanguageLoading.value = true
        await props.onLanguageUpdate(currentLanguage.value)
        openPrimaryTopToast({ message: i18n.t('message.success.saved').toString() })
        isLanguageDirty.value = false
      } catch (e) {
        openDangerTopToast({
          message: i18n.t('message.error.serverError').toString()
        })
      } finally {
        isLanguageLoading.value = false
      }
    }
    watch(currentLanguage, () => {
      isLanguageDirty.value = true
    })
    return {
      currentLanguage,
      isLanguageDirty,
      isLanguageLoading,
      handleLanguageUpdate
    }
  }
})
