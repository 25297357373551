
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ScheduleStatus } from '@/types'

@Component
export default class OnWaitingOperationBox extends Vue {
  @Prop({ required: true }) isSuggestedItem: boolean
  @Prop({ required: true }) isExpiredItem: boolean
  @Prop({ required: true }) scheduleStatus: ScheduleStatus

  handleConfirmation() {
    this.$emit('confirm')
  }
  handleEdit() {
    this.$emit('edit')
  }
  handleUrlCopy() {
    this.$emit('copy')
  }
  handleDelete() {
    this.$emit('delete')
  }
  get getTagInfo() {
    switch (this.scheduleStatus) {
      case 'expired':
        return {
          type: 'is-danger',
          icon: 'alert',
          label: this.$t('scheduleTabs.listItem.expired').toString()
        }
      case 'requestedByConfirmer':
        return {
          type: 'is-primary',
          icon: 'bell-alert',
          label: this.$t('scheduleTabs.listItem.requestedByConfirmer').toString()
        }
      case 'suggestedByConfirmer':
        return {
          type: 'is-primary',
          icon: 'bell-alert',
          label: this.$t('scheduleTabs.listItem.suggestedByConfirmer').toString()
        }
      default:
        return null
    }
  }
}
