



















import { useAnalytics } from '@/composables/useAnalytics'
import { SignalType } from '@/lib/analytics'
import { AvailabilityModel } from '@/models/data'
import { defineComponent, PropType } from '@vue/composition-api'
import Button from '@/components/ui/Button.vue'
import { isMobile } from '@/lib/utils'

export default defineComponent({
  name: 'ActionButtons',
  components: {
    Button
  },
  props: {
    pattern: {
      type: Object as PropType<AvailabilityModel>,
      required: true
    },
    isFetchingModel: {
      type: Boolean
    },
    isCandidatesFetching: {
      type: Boolean
    },
    onCandidatesShow: {
      type: Function as PropType<(pattern: AvailabilityModel) => Promise<void>>,
      required: true
    },
    onLinkCopy: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    },
    onShareModalShow: {
      type: Function as PropType<(pattern: AvailabilityModel) => void>,
      required: true
    }
  },
  setup(props) {
    const analytics = useAnalytics()

    function handleCandidatesShow() {
      analytics.send(SignalType.SHOW_AVAILABILITY_SHARING_CANDIDATES, {
        id: props.pattern.id,
        type: props.pattern.type
      })
      props.onCandidatesShow(props.pattern)
    }

    function handleLinkCopy() {
      analytics.send(SignalType.CLICK_PUBLIC_URL_LINK, {
        id: props.pattern.id,
        type: props.pattern.type
      })
      props.onLinkCopy(props.pattern)
    }

    function handleShareModalShow() {
      analytics.send(SignalType.SEE_PUBLIC_HTML_URL_SHARE_MODAL, {
        id: props.pattern.id,
        type: props.pattern.type
      })
      props.onShareModalShow(props.pattern)
    }

    return {
      isMobile: isMobile(),
      handleCandidatesShow,
      handleLinkCopy,
      handleShareModalShow
    }
  }
})
