






























import CopyBoxForHtml from '@/components/copyToShare/CopyBoxForHtml.vue'
import CopyBoxForUrl from '@/components/copyToShare/CopyBoxForUrl.vue'
import { useSendSignal } from '@/composables/useSendSignal'
import { useTranslation } from '@/composables/useTranslation'
import messages from '@/i18n/messages'
import { SignalType } from '@/lib/analytics/LogEntry'
import { getShareScheduleHtmlText, getShareSchedulePlainText, ShareSchedulePlainTextArgs } from '@/lib/utils/functions'
import { CandidateForShare, FrontSupportLanguage, ListType } from '@/types'
import { computed, defineComponent, onMounted, PropType, ref, toRefs, watch } from '@vue/composition-api'

type Analytics = {
  signal: SignalType
  payload: { [key: string]: string | number | boolean }
}

type UrlCopyBoxInfo = {
  title: string
  headerTitle: string
  analytics: Analytics
}
type HtmlCopyBoxInfo = {
  analytics: Analytics
}

export default defineComponent({
  name: 'CopyBoxForUrlAndHtml',
  components: {
    CopyBoxForUrl,
    CopyBoxForHtml
  },
  props: {
    isHtmlLoading: {
      type: Boolean,
      default: false
    },
    candidates: {
      type: Array as PropType<CandidateForShare[]>,
      required: true
    },
    language: {
      type: String as PropType<FrontSupportLanguage>,
      required: true
    },
    urlCopyBoxInfo: {
      type: Object as PropType<UrlCopyBoxInfo>,
      required: true
    },
    htmlCopyBoxInfo: {
      type: Object as PropType<HtmlCopyBoxInfo>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    scheduleType: {
      type: String as PropType<ListType | 'availabilitySharing'>,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    },
    canHtmlCopy: {
      type: Boolean,
      required: true
    },
    urlForConfirm: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const i18n = useTranslation()
    const { timeZone, language, candidates, title, duration, htmlCopyBoxInfo, urlCopyBoxInfo, urlForConfirm } =
      toRefs(props)
    const { sendSignal } = useSendSignal()
    i18n.locale = language.value

    const timeZoneCache = ref<string>(timeZone.value)
    const htmlText = ref<string>('')
    const text = ref<string>('')

    const currentTimeZone = computed(() => {
      return timeZoneCache.value ? timeZoneCache.value : timeZone.value
    })

    function setHtmlText() {
      const ShareScheduleTextOption: ShareSchedulePlainTextArgs = {
        activeCandidates: candidates.value,
        title: title.value,
        duration: duration.value,
        url: urlForConfirm.value,
        i18n,
        timezone: currentTimeZone.value
      }
      const html = getShareScheduleHtmlText(ShareScheduleTextOption)
      htmlText.value = html
      const plainText = getShareSchedulePlainText(ShareScheduleTextOption)
      text.value = plainText
    }
    async function handleAnalyticsSend(): Promise<void> {
      await sendSignal(urlCopyBoxInfo.value.analytics.signal, urlCopyBoxInfo.value.analytics.payload)
    }
    async function handleHtmlCopyAnalyticsSend(): Promise<void> {
      await sendSignal(htmlCopyBoxInfo.value.analytics.signal, htmlCopyBoxInfo.value.analytics.payload)
    }
    function handleTimeZoneChange(code: string) {
      timeZoneCache.value = code
      emit('changedTimeZone', code)
    }
    function handleLanguageChange(lang: FrontSupportLanguage) {
      i18n.locale = lang
      setHtmlText()
    }

    watch(timeZoneCache, () => {
      setHtmlText()
    })

    watch(candidates, () => {
      setHtmlText()
    })

    onMounted(() => {
      setHtmlText()
    })
    return {
      htmlText,
      text,
      currentTimeZone,
      handleAnalyticsSend,
      handleHtmlCopyAnalyticsSend,
      handleTimeZoneChange,
      handleLanguageChange
    }
  },
  i18n: {
    sharedMessages: messages
  }
})
