

















import ScheduleListEmptyMessage from './ScheduleListEmptyMessage.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ScheduleListEmptyMessage
  }
})
