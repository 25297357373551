import { useModal } from '@/composables/useModal'
import { useSendSignal } from '@/composables/useSendSignal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import * as scheduleAPI from '@/lib/api/scheduleTeam'
import { ScheduleModelTeam } from '@/models/data'
import TeamScheduleLocalStorage from '@/models/localStorage/TeamSchedule'
import EditScheduleTeamModule, { localStorageKey } from '@/store/modules/editScheduleTeam'
import { computed, reactive, ref } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { PageDataControl, UsePageDataControlCommon } from './types'
import { useDirtyCheckerInitializer } from './_useDirtyChecker'
import { usePreviewStateInitializer } from './_usePreviewState'

export const useTeamSchedulePageDataControlInitializer = () => {
  const route = useRoute()
  const { sendSignal } = useSendSignal()
  const i18n = useTranslation()
  const { openDangerBottomToast, openPrimaryTopToast } = useToast()
  const { openSyncedDetailModal } = useModal()
  const dirtyCheckerInitializer = useDirtyCheckerInitializer()
  const previewStateInitializer = usePreviewStateInitializer()
  return {
    initialize: ({
      teamId,
      pageData,
      saveTitleSuggestion,
      isEditMode = false,
      goToNext,
      cancelEditing
    }: {
      teamId: string
    } & UsePageDataControlCommon): PageDataControl => {
      const isSyncing = ref(false)

      function getRefreshedScheduleModel(): ScheduleModelTeam {
        const current = EditScheduleTeamModule.editingSchedule
        const newModel = current.updateByTeamScheduleFormData(pageData.value)
        return newModel
      }

      function saveToLocalStorage() {
        const localStorageSaver = new TeamScheduleLocalStorage(localStorageKey(teamId))
        localStorageSaver.saveToLocalStorage(pageData.value)
      }

      async function updateSchedule(teamScheduleModel: ScheduleModelTeam) {
        try {
          await scheduleAPI.updateSchedule(teamScheduleModel)
          saveToLocalStorage()
          openPrimaryTopToast({ message: `${i18n.t('message.success.savedSchedule')}` })
        } catch (e) {
          openDangerBottomToast({ message: `${i18n.t('message.errorCommon')}` })
        }
      }
      async function update() {
        isSyncing.value = true
        const teamScheduleModel = getRefreshedScheduleModel()
        await updateSchedule(teamScheduleModel)
        saveTitleSuggestion(teamScheduleModel.title)
        isSyncing.value = false
        await sendSignal(SignalType.CLICK_EDIT_ON_EDIT_PAGE)
      }

      async function create() {
        isSyncing.value = true
        try {
          const teamScheduleModel = getRefreshedScheduleModel()
          const response = await scheduleAPI.create(teamId, teamScheduleModel)
          const newModel = new ScheduleModelTeam(teamId, response)
          saveToLocalStorage()
          saveTitleSuggestion(newModel.title)
          openPrimaryTopToast({ message: `${i18n.t('message.success.savedSchedule')}` })
          await sendSignal(SignalType.CREATE_SCHEDULE, {
            from: route.query.from as string,
            id: newModel.id
          })
          isSyncing.value = false
          openSyncedDetailModal({ model: newModel })
        } catch (e) {
          openDangerBottomToast({ message: `${i18n.t('message.errorCommon')}` })
        }
      }

      const { isPreviewOpen, isPreviewDisabled, preview } = previewStateInitializer.initialize(pageData)
      const previewData = computed(() => {
        const model = getRefreshedScheduleModel()
        model.title = model.title || i18n.t('labels.notInputted').toString()
        return { model, candidates: model.getPreviewEventByCalendarFormat() }
      })
      const { clearDirty, shouldStayHere, selectPageDataControl } = dirtyCheckerInitializer.initialize({
        current: pageData,
        isEditMode,
        update,
        create,
        goToNext,
        cancelEditing
      })
      const { confirm, cancel } = selectPageDataControl()
      return {
        clearDirty,
        shouldStayHere,
        pageDataState: reactive({ isSyncing, isPreviewOpen, isPreviewDisabled, previewData }),
        handlers: {
          confirm,
          cancel,
          preview
        }
      }
    }
  }
}
