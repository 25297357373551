













import { defineComponent, PropType } from '@vue/composition-api'
import TipMarkBase from '@/components/ui/tipMark/TipMarkBase.vue'
import TipMarkParagraph from '@/components/ui/tipMark/TipMarkParagraph.vue'
import { TipInfo } from '@/components/ui/form/tipInfo'

export default defineComponent({
  name: 'FormItemTitle',
  components: {
    TipMarkBase,
    TipMarkParagraph
  },
  props: {
    title: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    tip: {
      type: Object as PropType<TipInfo>,
      default: () => ({ type: 'none' })
    }
  }
})
