













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CalendarEvent from './Event.vue'
import { EventPosition } from './EventTree'

const PADDING_FOR_DRAG = 14

@Component({
  components: {
    CalendarEvent
  }
})
export default class CalendarEventList extends Vue {
  @Prop() events: EventPosition[]
  @Prop() parentHeight: number
  @Prop() parentWidth: number
  @Prop() oneSlotPixel: number

  tempEvents: EventPosition[] = []
  @Watch('events', { deep: true })
  resetEvents() {
    this.tempEvents = []
    this.$nextTick(() => {
      this.tempEvents = this.events
    })
  }
  created() {
    this.tempEvents = this.events
  }
  get widthWidthPadding() {
    return this.parentWidth - PADDING_FOR_DRAG
  }
}
