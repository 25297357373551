









import { defineComponent, PropType } from '@vue/composition-api'
import FormSelectBoxItem from '@/components/ui/form/item/FormSelectBoxItem.vue'
import { SelectItem } from '@/components/ui/spir/SpirSelectBox.vue'

export default defineComponent({
  name: 'FormVisibilitySelectBoxItem',
  components: {
    FormSelectBoxItem
  },
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  }
})
