import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { config } from 'vuex-module-decorators'
import { IModuleAppScreenControl } from './modules/appScreenControl'
import { IModuleCalendar } from './modules/calendars'
import { IModuleEvents } from './modules/events'
import { IModuleProfile } from './modules/profile'
import { IModuleTimezone } from './modules/timezones'
import { IModuleTeam } from './modules/team'
import { IModuleTeamRecord } from './modules/teamRecord'
import { IModuleTeamInvited } from './modules/teamInvited'
import { IModuleTeamCalendar } from './modules/teamCalendar'
import { IModuleTeamScheduleList } from './modules/teamScheduleList'

config.rawError = true
const SPIR_VUEX_VERSION_OLD = 'spir0119'

export const SPIR_VUEX_VERSION = 'spir0701'
export const removePersistence = () => {
  window.localStorage.removeItem(SPIR_VUEX_VERSION)
}
window.localStorage.removeItem(SPIR_VUEX_VERSION_OLD)

export interface IRootState {
  AppScreenControl: IModuleAppScreenControl
  Calendars: IModuleCalendar
  Events: IModuleEvents
  Profile: IModuleProfile
  Locales: IModuleTimezone
  Teams: IModuleTeam
  TeamRecord: IModuleTeamRecord
  TeamInvited: IModuleTeamInvited
  TeamCalendar: IModuleTeamCalendar
  TeamScheduleList: IModuleTeamScheduleList
}

const vuexLocal = new VuexPersistence<IRootState>({
  storage: window.localStorage,
  key: SPIR_VUEX_VERSION,
  reducer: (state) => ({
    AppScreenControl: state.AppScreenControl,
    Calendars: state.Calendars,
    Events: state.Events,
    Profile: state.Profile,
    Locales: state.Locales,
    Teams: state.Teams,
    teamRecord: state.TeamRecord,
    teamInvited: state.TeamInvited,
    TeamCalendar: state.TeamCalendar,
    TeamScheduleList: state.TeamScheduleList
  }),
  saveState(key, state, storage) {
    storage[key] = JSON.stringify(state)
  },
  async restoreState(key: string): Promise<IRootState> {
    const state: IRootState = JSON.parse(window.localStorage.getItem(key))
    return state
  },
  asyncStorage: true
})

Vue.use(Vuex)
export default new Vuex.Store<IRootState>({
  plugins: [vuexLocal.plugin]
})
