






































import { Component, Vue } from 'vue-property-decorator'
import HelpModal from '@/components/help/HelpModal.vue'

@Component({
  components: {
    HelpModal: () => Promise.resolve(HelpModal)
  }
})
export default class AboutAutoCandidateExtractionModal extends Vue {}
