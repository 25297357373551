import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { JoiningTeam } from '@spirinc/contracts'
import store from '@/store'
import ProfileModule from './profile'
import AvailabilityPatternListModule from './availabilityPatternList'
import TeamScheduleListModule from './teamScheduleList'
import TeamRecordModule from './teamRecord'

const MODULE_NAME = 'Teams'

export interface IModuleTeam {
  _teams: JoiningTeam[]
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Team extends VuexModule {
  _teams: JoiningTeam[] = []

  get myTeams() {
    return this._teams
  }
  get isLoading() {
    return ProfileModule.isLoading
  }
  get getMyTeamById() {
    return (teamId: string) => {
      return this.myTeams.find((team) => team.id === teamId)
    }
  }

  @Mutation
  SET_TEAMS(teams?: JoiningTeam[]) {
    this._teams = teams
  }

  @Action
  async setTeams(teams?: JoiningTeam[]) {
    this.SET_TEAMS(teams)
    if (!teams || teams.length === 0) {
      return
    }

    // todo: sequentialにすべき、複数チームの人はすくないので今は対応しない。
    teams.forEach(async (team) => {
      await TeamRecordModule.fetchTeam({ teamId: team.id })
      AvailabilityPatternListModule.fetchPatterns(team.id)
      TeamScheduleListModule.fetchAllTypeSchedules({ teamId: team.id })
    })
  }
}

export default getModule(Team)
