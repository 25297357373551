















import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api'

export type SkeltonCandidateSelectButtonInfo = {
  type: 'skelton'
}

export type NormalCandidateSelectButtonInfo = {
  type: 'normal'
  disabled: boolean
  onClick: () => Promise<void>
  onHover: () => Promise<void>
}

type CandidateCandidateSelectButtonInfo = SkeltonCandidateSelectButtonInfo | NormalCandidateSelectButtonInfo

export default defineComponent({
  props: {
    info: {
      type: Object as PropType<CandidateCandidateSelectButtonInfo>,
      required: true
    }
  },
  setup(props) {
    const { info } = toRefs(props)

    const dataParams = computed(() => {
      if (info.value.type === 'normal') {
        return {
          disabled: info.value.disabled,
          handleHover: info.value.onHover,
          handleClick: info.value.onClick
        }
      } else {
        const noop = () => null
        return {
          disabled: true,
          handleHover: noop,
          handleClick: noop
        }
      }
    })

    return {
      disabled: dataParams.value.disabled,
      handleHover: dataParams.value.handleHover,
      handleClick: dataParams.value.handleClick
    }
  }
})
