















import { defineComponent, PropType } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'
import Section from '../atoms/Section.vue'

export default defineComponent({
  components: {
    Section
  },
  props: {
    onClick: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const handleClick = () => {
      router.push({ name: 'ConfirmedList' })
      props.onClick()
    }
    return {
      handleClick
    }
  }
})
