










import FormFooterButtons from '@/components/ui/form/FormFooterButtons.vue'
import PoweredBySpir from '@/components/sidePanels/confirm/atoms/PoweredBySpir.vue'
import SpirButton from '@/components/ui/Button.vue'
import SignInButton from '@/components/molecules/SignInButton.vue'
import { useTranslation } from '@/composables/useTranslation'
import { defineComponent, PropType } from '@vue/composition-api'
import SidePanelFooterFrame from '@/components/ui/page/SidePanelFooterFrame.vue'
import ConfirmSidePanelFooterButtonsFrame from '@/components/sidePanels/confirm/atoms/ConfirmSidePanelFooterButtonsFrame.vue'

export default defineComponent({
  name: 'SuggestCandidateFooterButtons',
  components: {
    FormFooterButtons,
    SignInButton,
    PoweredBySpir,
    SpirButton,
    SidePanelFooterFrame,
    ConfirmSidePanelFooterButtonsFrame
  },
  props: {
    onConfirm: {
      type: Function as PropType<() => void>,
      required: true
    },
    isConfirmDisabled: {
      type: Boolean
    },

    isSignIn: {
      type: Boolean
    }
  },
  setup(props) {
    const { onConfirm } = props
    const i18n = useTranslation()
    return {
      buttonType: 'primary',
      confirmTitle: i18n.t('buttons.confirm').toString(),
      confirmOfOnclick: () => {
        onConfirm()
      }
    }
  }
})
