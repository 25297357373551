






import { defineComponent, PropType } from '@vue/composition-api'
type TopSpaceName = 't16' | 't12' | 't8'
type BottomSpaceName = 'b16' | 'b8'
type VertcalSpaceName = 'v16' | 'v8'

type SpaceName = TopSpaceName | BottomSpaceName | VertcalSpaceName
export default defineComponent({
  name: 'FormItemSpacer',
  props: {
    spaceName: {
      type: String as PropType<SpaceName>,
      required: true
    }
  }
})
