
















import { defineComponent, Ref, ref } from '@vue/composition-api'
import SpirCheckbox from '@/components/ui/spir/SpirCheckbox.vue'
import { LOCAL_STORAGE_KEY_FOR_TRANSLATE } from '@/i18n'

export default defineComponent({
  components: {
    SpirCheckbox
  },
  setup() {
    const translationMode: Ref<boolean> = ref(window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_TRANSLATE) === 'true')
    const handleUpdateTranslationMode = () => {
      translationMode.value = !translationMode.value
    }
    const handleSaveTranslateMode = () => {
      window.localStorage.setItem(LOCAL_STORAGE_KEY_FOR_TRANSLATE, String(translationMode.value))
      window.location.reload()
    }
    return {
      translationMode,
      handleSaveTranslateMode,
      handleUpdateTranslationMode
    }
  }
})
