















import { Component, Prop, Vue } from 'vue-property-decorator'
import UserBadge from '@/components/molecules/UserBadge.vue'

@Component({
  components: {
    UserBadge
  }
})
export default class PreviewButton extends Vue {
  @Prop({ default: false }) disabled: false
  @Prop() click?: () => void
}
