import PeekCandidateModule from '@/store/modules/peekCandidate'
import { computed, ref } from '@vue/composition-api'
import {
  getPeekCandidatesConditions,
  saveNewPeekCandidatesConditions,
  udpatePeekCandidatesConditions
} from '@/lib/api/peekCandidates'
import { PeekCandidatesPostRequest } from '@spirinc/contracts'

export const usePeekCandidatesCondition = () => {
  const isConditionLoading = ref(false)
  const conditionsForPeekCandidate = computed(() => PeekCandidateModule.editableCondition)

  const fetchCondition = async () => {
    isConditionLoading.value = true
    try {
      return await PeekCandidateModule.fetchCondition(getPeekCandidatesConditions)
    } finally {
      isConditionLoading.value = false
    }
  }
  const saveCondition = async (newCondition: PeekCandidatesPostRequest) => {
    const saveValue = {
      duration: newCondition.duration,
      start: newCondition.start,
      end: newCondition.end,
      dayOfTheWeekRules: newCondition.dayOfTheWeekRules || {},
      timeBuffer: newCondition.timeBuffer,
      timeZone: newCondition.timeZone,
      countries: newCondition.countries
    }
    let updateMethod = undefined
    if (conditionsForPeekCandidate.value.id) {
      updateMethod = () => udpatePeekCandidatesConditions(conditionsForPeekCandidate.value.id, saveValue)
    } else {
      updateMethod = () => saveNewPeekCandidatesConditions(saveValue)
    }
    await PeekCandidateModule.updateCondition(updateMethod)
  }
  const getCondition = async () => {
    if (conditionsForPeekCandidate.value) {
      return Promise.resolve(conditionsForPeekCandidate.value)
    }
    return fetchCondition()
  }
  return {
    getCondition,
    saveCondition,
    conditionsForPeekCandidate,
    isConditionLoading
  }
}
