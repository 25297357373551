import { render, staticRenderFns } from "./TagWithIconAndTitle.vue?vue&type=template&id=0cc76bdc&scoped=true&"
import script from "./TagWithIconAndTitle.vue?vue&type=script&lang=ts&"
export * from "./TagWithIconAndTitle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc76bdc",
  null
  
)

export default component.exports