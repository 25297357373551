

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PatternFormAndOrSelector extends Vue {
  @Prop() value

  updatedSelector(selectedValue) {
    this.$emit('input', selectedValue)
  }
}
