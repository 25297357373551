


























































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AppModule from '@/store/modules/app'
import EventToConfirmModule from '@/store/modules/eventToConfirm'
import CalendarLayout from '@/components/layout/CalendarForPreview.vue'
import Tooltip from '@/components/molecules/Tooltip.vue'
import RequestOtherOptionButton from '@/components/schedule/RequestOtherOptionButton.vue'
import ConfirmPageHeader from '@/components/schedule/ConfirmPageHeader.vue'
import { FullCalendarEvent, ISchedule, SpirUser } from '@/types'
import PreviewModule from '@/store/modules/preview'
import { getStartAndEndDateFromClickedPosition } from '@/lib/utils'
import { SLOT_DURATION } from '@/types/constants'
import { getHourByUserTimezone } from '@/lib/utils/dateFormat'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import ScheduleConfirmSidePanelBox from '@/components/sidePanels/confirm/schedule/ScheduleConfirmSidePanelBox.vue'

@Component({
  components: {
    CalendarLayout,
    Tooltip,
    RequestOtherOptionButton,
    ConfirmPageHeader,
    ScheduleConfirmSidePanelBox
  }
})
export default class BasePreviewScheduleModal extends Vue {
  @PropSync('open', { type: Boolean }) openSynced!: boolean
  @Prop({ default: true }) isSignIn: boolean
  @Prop() schedule: ISchedule
  @Prop() candidates: FullCalendarEvent[]
  @Prop() authorInfo: SpirUser

  isPreview = true

  mounted() {
    PreviewModule.initPreview(this.candidates[0].start)
  }
  beforeDestroy() {
    EventToConfirmModule.resetEventToConfirm()
  }
  get getFirstHour() {
    if (this.candidates.length === 0) {
      return 0
    }
    return getHourByUserTimezone(this.candidates[0].start)
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  get eventToConfirm() {
    return EventToConfirmModule.eventToConfirm
  }
  get isSelectingEvent(): boolean {
    return !!this.eventToConfirm
  }
  get editingSchedule(): ISchedule {
    return this.schedule
  }
  get isScheduleEventListOpen() {
    return AppScreenControlModule.isScheduleEventListOpen
  }
  get showCalendarFooter() {
    return !this.isNarrow || !this.isScheduleEventListOpen
  }
  get additionalEvents(): FullCalendarEvent[] {
    const returnEvents = []
    if (this.eventToConfirm) {
      returnEvents.push(this.eventToConfirm)
    }
    return returnEvents.concat(
      this.candidates.map((event: FullCalendarEvent) => {
        return {
          ...event,
          extendedProps: { ...event.extendedProps, source: 'pending' }
        }
      })
    )
  }
  onScheduleConfirmButtonClick() {
    this.$buefy.toast.open({
      type: 'is-danger',
      position: 'is-bottom',
      message: this.$t('message.preview.notAllowedConfirm').toString()
    })
  }
  handleClose() {
    this.openSynced = false
  }
  handleClickedCandidate({ event, jsEvent, el }: { event: FullCalendarEvent; jsEvent: MouseEvent; el: HTMLElement }) {
    const { duration } = this.editingSchedule
    const { id, start, end } = event
    const clickedTime = getStartAndEndDateFromClickedPosition(
      duration,
      start,
      end,
      duration < SLOT_DURATION ? duration : SLOT_DURATION,
      jsEvent,
      el
    )
    this.updateEventToConfirm({
      id: `confirmEvent`,
      title: this.editingSchedule.title,
      start: clickedTime.start,
      durationEditable: false,
      end: clickedTime.end,
      extendedProps: {
        source: 'confirmer',
        candidateId: id,
        accountId: this.editingSchedule.accountId,
        calendarId: this.editingSchedule.calendarId
      }
    })
  }
  handleUpdateEvent({ event, revert }: { event: FullCalendarEvent; revert: Function }) {
    if (!this.eventToConfirm) {
      return
    }
    const [candidate] = this.editingSchedule.candidates.filter((s) => {
      return event.start >= new Date(s.start) && event.end <= new Date(s.end)
    })
    if (!candidate) {
      return revert()
    }
    // todo: I must not use null
    this.updateEventToConfirm({
      ...this.eventToConfirm,
      start: event.start,
      end: event.end,
      extendedProps: {
        source: 'confirmer',
        candidateId: candidate.id,
        accountId: this.editingSchedule.accountId,
        calendarId: this.editingSchedule.calendarId
      }
    })
  }
  updateEventToConfirm(event) {
    return EventToConfirmModule.updateEventToConfirm(event)
  }
  handleDeleteConfirmer() {
    EventToConfirmModule.resetEventToConfirm()
  }
}
