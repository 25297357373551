
















import ConfirmedListTabs from '@/components/adjustmentList/ConfirmedListTabs.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import { useAdjustmentTabsSetup } from '@/composables/useAdjustmentTabsSetup'
import { useConfirmedConverters } from '@/composables/useConfirmedConverter'
import i18n from '@/i18n'
import { BrowserVisibilityDetector } from '@/lib/utils/browserVisibilityDetector'
import { Polling } from '@/lib/utils/polling'
import ListPanelModule from '@/store/modules/listPanel'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import { ConfirmedTabItemState, ConfirmedTabsState, ISchedule } from '@/types'
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, toRefs } from '@vue/composition-api'
import { INTERVAL_SEC } from '@/lib/utils'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import * as scheduleAPI from '@/lib/api/schedule'
import * as pollAPI from '@/lib/api/poll'
import { GetScheduleListPayload } from '@/lib/api/schedule'
import { GetPollListPayload } from '@/lib/api/poll'

export default defineComponent({
  components: {
    ClosingPageheadTitleBox,
    ConfirmedListTabs
  },
  props: {
    personalTeamValue: {
      type: String as PropType<string>,
      default: 'private'
    }
  },
  setup(props) {
    const { personalTeamValue } = toRefs(props)
    const pageTitle = i18n.t('globalNavigation.confirmedList')

    const { getPrivateCardTypes, getTeamCardTypes, getTeamLoading } = useConfirmedConverters()

    const getPrivateList = () => ListPanelModule.confirmedList
    const getTeamList = (teamId: string) => TeamScheduleListModule.confirmedScheduleListByTeamId(teamId)
    const {
      handleMounted,
      currentTab,
      getPrivateTabInfo,
      getTeamTabInfos,
      handleClose,
      handleTabChange,
      getTabsClass
    } = useAdjustmentTabsSetup<'confirmed'>({ personalTeamValue, getPrivateList })

    const privateTabInfo = computed((): ConfirmedTabItemState => getPrivateTabInfo(getPrivateCardTypes))
    const teamTabInfos = computed((): ConfirmedTabItemState[] =>
      getTeamTabInfos(getTeamList, getTeamCardTypes, getTeamLoading)
    )

    const tabs = computed((): ConfirmedTabsState => {
      return { items: [privateTabInfo.value, ...teamTabInfos.value] }
    })

    const tabsClass = computed(() => getTabsClass(tabs.value))
    const polling = new Polling(INTERVAL_SEC)
    const source = ref<CancelTokenSource>(axios.CancelToken.source())
    const doSomething = async (cancelToken: CancelToken) => {
      const getScheduleList = async (payload: GetScheduleListPayload): Promise<Array<ISchedule>> => {
        try {
          return await scheduleAPI.getList({ ...payload, cancelToken })
        } catch (e) {
          if (!axios.isCancel(e)) throw e
          else return []
        }
      }
      const getPollList = async (payload: GetPollListPayload): Promise<pollAPI.PollList> => {
        try {
          return await pollAPI.getList({ ...payload, cancelToken })
        } catch (e) {
          if (!axios.isCancel(e)) throw e
          else return { limit: 0, groupPolls: [] }
        }
      }
      ListPanelModule.fetchConfirmedList(getScheduleList, getPollList)
    }
    const getParams = async (): Promise<CancelToken> => {
      source.value = axios.CancelToken.source()
      return Promise.resolve(source.value.token)
    }
    async function startPollPersonalConfirmedList() {
      polling.reset()
      await polling.start<CancelToken>({ doSomething, getParams })
    }
    function cancelPollPersonalConfirmedList() {
      source.value.cancel()
      polling.cancel()
    }

    const detector = new BrowserVisibilityDetector({
      play: async () => {
        await startPollPersonalConfirmedList()
      },
      pause: async () => {
        cancelPollPersonalConfirmedList()
      }
    })
    onMounted(() => {
      startPollPersonalConfirmedList()
      ListPanelModule.fetchPastList()
      handleMounted()
      detector.on()
    })
    onUnmounted(() => {
      cancelPollPersonalConfirmedList()
      detector.off()
    })
    return {
      pageTitle,
      currentTab,
      tabs,
      handleClose,
      handleTabChange,
      tabsClass
    }
  }
})
