



















import PanelHeaderButton from '@/components/atoms/PanelHeaderButton.vue'
import AvailabilitySharingForm from '@/components/patterns/sidePanels/AvailabilitySharingForm.vue'
import SidePanelLayout from '@/components/sidePanels/edit/Layout.vue'
import Skeleton from '@/components/sidePanels/edit/Skeleton.vue'
import PersonalTeamSelectDropdown from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/index.vue'
import { personalId } from '@/components/ui/domain/dropdown/PersonalTeamSelectDropdown/personalTeamInfo'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useHolidayCountryRemover } from '@/composables/useHolidayCountryRemover'
import { useScheduleEventListPanel } from '@/composables/useScheduleEventListPanel'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import getErrorMessage from '@/lib/utils/getErrorMessage'
import ProfileModule from '@/store/modules/profile'
import { FullCalendarEvent } from '@/types'
import {
  AvailabilitySharingTitleSuggestionSetup,
  AvailabilitySharingTitleSuggestionSetups
} from '@/types/availabilitySharingForm'
import Common from '@/views/calendar/Common.vue'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { defineComponent, onMounted, onUnmounted, PropType, ref, toRefs, watch } from '@vue/composition-api'
import SidePanel from './_SidePanel/index.vue'

export default defineComponent({
  name: 'AvailabilitySharingCreatePage',
  components: {
    Common,
    Skeleton,
    SidePanelLayout,
    PanelHeaderButton,
    AvailabilitySharingForm,
    PersonalTeamSelectDropdown,
    SidePanel
  },
  props: {
    teamId: {
      type: String
    },
    suggestionSetups: {
      type: Object as PropType<AvailabilitySharingTitleSuggestionSetups>,
      required: true
    },
    onTeamIdUpdate: {
      type: Function as PropType<(teamId?: string) => void>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { teamId } = toRefs(props)
    useScheduleEventListPanel()
    const { getHolidayCountryRemover } = useHolidayCountryRemover()
    const i18n = useTranslation()
    const { openPrimaryTopToast, openDangerBottomToast } = useToast()
    const {
      personalTeamValue,
      editingObject,
      isLoading,
      eventsFromEditingObject,
      setupEditingObject,
      updateEditingObject,
      updateCondition,
      addException,
      removeException,
      addHolidayException,
      removeHolidayException,
      confirmEditingObject,
      resetEditingObject
    } = useAvailabilitySharingForm()
    function selectSuggestSetup(teamId?: string): AvailabilitySharingTitleSuggestionSetup {
      return teamId ? props.suggestionSetups.getTeamSetup(teamId) : props.suggestionSetups.personalSetup
    }
    const suggestSetup = ref(selectSuggestSetup(teamId.value))

    function removeHolidayExceptionWithConfirmationModal(event: FullCalendarEvent) {
      const remover = getHolidayCountryRemover(removeHolidayException)
      remover(event)
    }

    async function handleConfirm() {
      try {
        // copy title, candidateTitle and teamId because confirmEditingObject('create') resets them.
        const title = editingObject.value.title
        const candidateTitle = editingObject.value.candidateTitle
        const currentTeamId = teamId.value
        await confirmEditingObject('create')
        // need to select again because watch(personalTeamValue, ...) updates suggestSetup
        const currentSetup = selectSuggestSetup(currentTeamId)
        currentSetup.save({
          privateTitle: title,
          candidateTitle: candidateTitle
        })
        openPrimaryTopToast({
          message: `${i18n.t('message.success.created', { type: i18n.t('availabilitySharing.title').toString() })}`
        })
        emit('leavePageByConfirm')
      } catch (e) {
        const message = getErrorMessage(e, {
          handleKeys: [
            {
              errorCode: ERROR_CODE.EXCEED_MAX_COUNT,
              i18nKey: 'availabilitySharing.messages.error.EXCEED_MAX_COUNT',
              parameter: {
                count: ProfileModule.myProfile.availabilitySharingMaxCount
              }
            },
            {
              errorCode: ERROR_CODE.NOT_SUBSCRIPTION_USER,
              i18nKey: 'availabilitySharing.messages.error.EXCEED_MAX_COUNT',
              parameter: {
                count: ProfileModule.myProfile.availabilitySharingMaxCount
              }
            },
            {
              errorCode: ERROR_CODE.DISCONNECTED_ZOOM_INTEGRATION,
              i18nKey: 'availabilitySharing.messages.error.DISCONNECTED_ZOOM_INTEGRATION'
            }
          ]
        })
        openDangerBottomToast({ message })
      }
    }

    function handleCancel() {
      emit('leavePageByCancel')
    }

    watch(teamId, (newTeamId) => {
      suggestSetup.value = selectSuggestSetup(newTeamId)
    })
    onMounted(async () => {
      const editingObject = props.teamId
        ? ({ mode: 'create', type: 'team', teamId: props.teamId } as const)
        : ({ mode: 'create', type: 'personal' } as const)
      await setupEditingObject(editingObject)
      watch(personalTeamValue, (newValue) => props.onTeamIdUpdate(newValue === personalId ? undefined : newValue))
    })
    onUnmounted(() => resetEditingObject())

    return {
      suggestSetup,
      editingObject,
      isLoading,
      eventsFromEditingObject,
      addException,
      updateEditingObject,
      updateCondition,
      removeException,
      addHolidayException,
      removeHolidayExceptionWithConfirmationModal,
      handleConfirm,
      handleCancel
    }
  }
})
