


































import Avatar from 'vue-avatar'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CalendarItemMenuButton from '@/components/calendarList/molecules/CalendarItemMenuButton.vue'
import CalendarListItem from '@/components/calendarList/molecules/CalenderListItem.vue'
import { MemberCalendarCheckboxItem } from '@/models/data/team'

@Component({
  components: {
    CalendarListItem,
    CalendarItemMenuButton,
    Avatar
  }
})
export default class MemberCheckBoxAndMenu extends Vue {
  @Prop() member: MemberCalendarCheckboxItem
  @Prop({ default: false }) isMobile: boolean
  @Prop() renderingMenu: boolean
  @Prop() onMemberCalendarToggle: (memberId: string) => void
  @Prop() onMemberCalendarColorChange: (payload: { memberId: string; color: string }) => void

  isMenuActive = false

  handleToggleMember() {
    if (!this.isMenuActive) {
      this.onMemberCalendarToggle(this.member.id)
    }
  }

  handleMenuActivityChange(isMenuActive: boolean) {
    this.isMenuActive = isMenuActive
  }

  handleColorSelect(color: string) {
    this.onMemberCalendarColorChange({ memberId: this.member.id, color })
  }
}
