








import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import ProfileContent from '@/components/profile/ProfileContent.vue'
import { getProfile } from '@/lib/api/profile'

@Component({
  components: {
    ProfileContent,
    CommonLayoutModal
  }
})
export default class ProfileContentModal extends Vue {
  @Prop() id: string
  profile = null

  mounted() {
    getProfile(this.id).then(({ data }) => {
      this.profile = data
    })
  }
}
