














import PoweredBySpir from '@/components/sidePanels/confirm/atoms/PoweredBySpir.vue'
import RequestOtherOptionButton from '@/components/schedule/RequestOtherOptionButton.vue'
import ConfirmSidePanelFooterButtonsFrame from '../atoms/ConfirmSidePanelFooterButtonsFrame.vue'
import SignInButton from '@/components/molecules/SignInButton.vue'
import { ScheduleModel } from '@/models/data'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmSidePanelFooterButtonsFrame,
    PoweredBySpir,
    SignInButton,
    RequestOtherOptionButton
  },
  props: {
    isSignIn: {
      type: Boolean,
      default: false
    },
    isOtherRequestOpen: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    isOrganizer: {
      type: Boolean,
      default: false
    },
    schedule: {
      type: Object as PropType<ScheduleModel>,
      default: null
    }
  }
})
