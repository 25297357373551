













































import PreviewPrivateScheduleModal from '@/components/modal/preview/PreviewPrivateScheduleModal.vue'
import Skeleton from '@/components/sidePanels/edit/Skeleton.vue'
import { useMobileCalendarFooterSetup } from '@/components/ui/domain/section/arrangement/composables/useMobileCalendarFooterSetup'
import MobileCalendarCandidatesControls from '@/components/ui/domain/section/arrangement/MobileCalendarCandidatesControls.vue'
import MobileCalendarFooter from '@/components/ui/domain/section/arrangement/MobileCalendarFooter.vue'
import {
  PeekCandidatesForm,
  PersonalScheduleFormData
} from '@/components/ui/domain/sections/arrangement/composables/types'
import { usePersonalScheduleFormData } from '@/components/ui/domain/sections/arrangement/composables/usePersonalScheduleFormData'
import {
  PeekCandidatesControl,
  usePeekCandidatesPanelHandler
} from '@/components/ui/domain/sidePanel/arrangement/useArrangementPeekCandidatesSidePanel'
import { useEditPageInitializing } from '@/components/ui/domain/sidePanel/arrangement/useEditPageInitializing'
import { useHolidayCountryRemover } from '@/composables/useHolidayCountryRemover'
import { useScheduleMixin } from '@/composables/useScheduleMixin'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { ScheduleDuration } from '@/models/data/schedule'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import CalendarControlModule from '@/store/modules/calendarControl'
import EditScheduleModule from '@/store/modules/editSchedule'
import UserModule from '@/store/modules/user'
import { CreateEvent, FullCalendarEvent, isActiveStatus } from '@/types'
import Common from '@/views/calendar/Common.vue'
import { computed, defineComponent, PropType, provide, ref, watch } from '@vue/composition-api'
import { parseISO } from 'date-fns'
import { useRouter } from 'vue2-helpers/vue-router'
import { PersonalScheduleEditSidePanelInfo } from './_personalScheduleEditPage'
import SidePanel from './_SidePanel.vue'

export default defineComponent({
  components: {
    Common,
    SidePanel,
    Skeleton,
    PreviewPrivateScheduleModal,
    MobileCalendarFooter,
    MobileCalendarCandidatesControls
  },
  props: {
    titleSuggestions: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    saveTitleSuggestion: {
      type: Function as PropType<(title: string) => void>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const { openDangerBottomToast } = useToast()
    const i18n = useTranslation()
    const { showToastIfScheduleIsSyncing } = useScheduleMixin()

    const firstDate = ref(new Date())
    const editingSchedule = computed(() => EditScheduleModule.editingSchedule)

    const { updatePersonalScheduleForms, getPersonalScheduleFormData } = usePersonalScheduleFormData({
      scheduleModel: editingSchedule.value,
      titleSuggestions: props.titleSuggestions,
      saveTitleSuggestion: props.saveTitleSuggestion,
      isEditMode: true,
      candidatesFilterForMixing: (candidate) => candidate.extendedProps.status === 'rejected'
    })
    const { getHolidayCountryRemover } = useHolidayCountryRemover()
    provide(PersonalScheduleEditSidePanelInfo, { getPersonalScheduleFormData })
    const formData = computed((): PersonalScheduleFormData => getPersonalScheduleFormData())
    const { isPeekCandidateSectionOpened, candidatesControls, openPanel } = usePeekCandidatesPanelHandler({ formData })
    const candidates = computed(() => candidatesControls.value.candidates)
    const handleUpdate = computed(() => candidatesControls.value.handleUpdate)
    const handleDelete = computed(() => candidatesControls.value.handleDelete)
    provide(PeekCandidatesControl, { formData })

    const showLoaderOnCalendar = computed(() => formData.value.peekCandidatesForm.isCandidateLoading)
    const { canPreview, handlePreview, handleFormCancel, handleCalendarClose } = useMobileCalendarFooterSetup({
      formData
    })
    function setCurrentDateToFirstCandidate() {
      const firstStartTime = editingSchedule.value.candidates.filter((c) => isActiveStatus(c.status))[0]?.start
      if (firstStartTime === undefined) {
        return
      }
      firstDate.value = parseISO(firstStartTime)
      CalendarControlModule.setCurrentDate(firstDate.value)
    }
    async function setEditingSchedule() {
      try {
        if (!EditScheduleModule.isMine) {
          throw 'noSchedule'
        }
        if (EditScheduleModule._editingSchedule.status === 'confirmed') {
          throw 'confirmed'
        }
        if (EditScheduleModule._editingSchedule.status === 'deleted') {
          throw 'deleted'
        }
        setCurrentDateToFirstCandidate()
        showToastIfScheduleIsSyncing()
      } catch (e) {
        let errorMessage = i18n.t('message.errorCommon').toString()
        let redirectPath = 'Main'
        switch (e) {
          case 'deleted':
            errorMessage = i18n.t('message.error.deleted').toString()
            break
          case 'noSchedule':
            errorMessage = i18n.t('message.error.noSchedule').toString()
            break
          case 'confirmed':
            errorMessage = i18n.t('message.error.confiemd').toString()
            redirectPath = 'ConfirmSchedule'
            break
        }
        openDangerBottomToast({ message: errorMessage })
        router.push({ name: redirectPath }).catch(GuardExceptionHandlers.noopAgainstRedirection)
      }
    }
    const { initializing, isSidePanelVisible, openSidePanel } = useEditPageInitializing({
      setupEditPage: async () => {
        await UserModule.fetchIntegrations(true)
        await setEditingSchedule()
      }
    })

    function handleHolidayExceptionRemoveWithConfirmationModal(event: FullCalendarEvent) {
      const peekCandidatesForm: PeekCandidatesForm = formData.value.peekCandidatesForm
      const remover = getHolidayCountryRemover(peekCandidatesForm.countriesForm.handleCountryCodeRemove)
      remover(event)
    }

    watch(editingSchedule, (newScheduleModel, oldScheduleModel) => {
      if (newScheduleModel && newScheduleModel.id !== oldScheduleModel.id) {
        updatePersonalScheduleForms({
          title: newScheduleModel.title,
          duration: newScheduleModel.duration,
          author: {
            accountId: newScheduleModel.accountId,
            calendarId: newScheduleModel.calendarId
          },
          attendeeEmails: newScheduleModel.attendees.map((attendee) => ({ type: 'email', email: attendee.email })),
          description: newScheduleModel.description,
          onlineMeeting: newScheduleModel.onlineMeeting,
          location: newScheduleModel.location || '',
          visibility: newScheduleModel.visibility
        })
      }
    })
    return {
      showLoaderOnCalendar: showLoaderOnCalendar,
      initializing,
      isPeekCandidateSectionOpened,
      candidates,
      isSidePanelVisible,
      formData,
      canPreview,
      handlePreview,
      handleFormCancel,
      handleCalendarClose,
      handlePeekCandidatesPanelOpen: () => {
        openSidePanel()
        openPanel()
      },
      handleDurationUpdate: (newDuration: ScheduleDuration) => {
        formData.value.basicInfo.duration.handleChange(newDuration)
      },
      handleEventUpdate: ({ event, revert }: { event: FullCalendarEvent; revert: Function }) => {
        handleUpdate.value(event, revert)
      },
      handleCandidateClicked: (id: string) => {
        handleDelete.value(id)
      },
      handleEventAdd: (data: CreateEvent) => {
        handleUpdate.value(data)
      },
      handleHolidayExceptionRemoveWithConfirmationModal
    }
  }
})
