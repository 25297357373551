









import AvailabilitySharingCreatePage from '@/components/page/availabilitySharing/AvailabilitySharingCreatePage.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useModal } from '@/composables/useModal'
import queryParams from '@/lib/queryParams'
import { GenAvailabilitySharingTitleSuggestionSetup } from '@/lib/utils'
import { AllRouteNames } from '@/router'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import { defineComponent } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'

export default defineComponent({
  name: 'CreateAvailabilitySharing',
  components: {
    AvailabilitySharingCreatePage
  },
  setup() {
    const router = useRouter()
    const { openDiscardConfirmationModal } = useModal()
    const { isEditingObjectDirty } = useAvailabilitySharingForm()
    const suggestionSetups = GenAvailabilitySharingTitleSuggestionSetup.genSuggestionSetups()

    function handleTeamIdUpdate(newTeamId?: string) {
      if (newTeamId) {
        router.push({ query: { [queryParams.TEAM_ID]: newTeamId } })
      } else {
        router.push({ query: {} })
      }
    }

    function goListPage() {
      router.push({ name: AllRouteNames.AvailabilityList }).catch(GuardExceptionHandlers.noopAgainstAbortion)
    }

    function guard(next) {
      if (isEditingObjectDirty.value) {
        openDiscardConfirmationModal({
          confirm: () => next(),
          cancel: () => next(false)
        })
      } else {
        next()
      }
    }

    return {
      teamIdQueryParams: queryParams.TEAM_ID,
      suggestionSetups,
      handleTeamIdUpdate,
      goListPage,
      guard
    }
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next)
  }
})
