












import { adjustDurationUnit } from '@/lib/utils'
import { AVAILABLE_DURATIONS } from '@/lib/utils/durations'
import { Vue, Component, PropSync } from 'vue-property-decorator'

@Component
export default class DurationInputButton extends Vue {
  @PropSync('duration', { default: 0 }) durationSynced!: number

  get durationItems() {
    return AVAILABLE_DURATIONS.map((n) => {
      const displayDuration = adjustDurationUnit(n)
      if (displayDuration.type === 'hour') {
        return {
          title: this.$t('forms.event.durationHour', { hour: displayDuration.duration }).toString(),
          value: n
        }
      } else {
        return {
          title: this.$t('forms.event.duration', { min: displayDuration.duration }).toString(),
          value: n
        }
      }
    })
  }
  get selectedDuration() {
    const selectecItem = this.durationItems.find((i) => i.value === this.durationSynced)
    if (!selectecItem) {
      return null
    }
    return selectecItem.title
  }

  handleUpdateDuration(duration) {
    this.$emit('updateDuration', duration)
  }
}
