




import ConfirmedListPage from '@/components/page/list/ConfirmedListPage.vue'
import queryParams from '@/lib/queryParams'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ConfirmedListPage
  },
  setup() {
    return {
      teamIdQueryKey: queryParams.TEAM_ID
    }
  }
})
