import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { LocalStorage } from '@/models/localStorage/localStorage'

const LAST_ARRANGEMENT_FORM_REQUEST = 'LAST_ARRANGEMENT_FORM_REQUEST'

export class LastArrangementFormRequest extends LocalStorage {
  constructor() {
    super(LAST_ARRANGEMENT_FORM_REQUEST, ['id', 'type'])
  }

  loadFromLocalStorage(): ArrangementFormRequest {
    const data = super.loadFromLocalStorage()
    if ('type' in data) {
      return data
    } else {
      return {
        type: 'personalSchedule'
      }
    }
  }
}
