























import PreviewPollScheduleModal from '@/components/modal/preview/PreviewPollScheduleModal.vue'
import PreviewPrivateScheduleModal from '@/components/modal/preview/PreviewPrivateScheduleModal.vue'
import PreviewTeamScheduleModal from '@/components/modal/preview/PreviewTeamScheduleModal.vue'
import { ArrangementFormRequest } from '@/components/ui/domain/sidePanel/arrangement/types'
import { ArrangementTypeFormData } from '@/components/ui/domain/sections/arrangement/composables/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ArrangementPreviewModal',
  components: {
    PreviewPollScheduleModal,
    PreviewPrivateScheduleModal,
    PreviewTeamScheduleModal
  },
  props: {
    formRequest: {
      type: Object as PropType<ArrangementFormRequest>,
      required: true
    },
    formData: {
      type: Object as PropType<ArrangementTypeFormData>,
      required: true
    }
  }
})
