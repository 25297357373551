




import { Component, Prop, Vue } from 'vue-property-decorator'
import sanitizeHtml from 'sanitize-html'
import { text2link } from '@/lib/utils'

@Component
export default class HtmlDisplay extends Vue {
  @Prop() description: string

  get descriptionWithLink() {
    return text2link(this.description)
  }
  get sanitizedHtml() {
    return sanitizeHtml(this.descriptionWithLink)
  }
}
