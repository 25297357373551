











import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { holidayExclusion } from '@/lib/utils'

export default defineComponent({
  name: 'SelectedHolidayCountryItem',
  props: {
    code: {
      type: String as PropType<FrontSupportCountryCode>,
      required: true
    }
  },
  setup(props) {
    const { code } = toRefs(props)
    const label = computed(() => holidayExclusion.getI18nCountryName(code.value))
    return {
      label
    }
  }
})
