import { render, staticRenderFns } from "./_OrganizerInfoCardBodyItemFrame.vue?vue&type=template&id=92c5fa92&scoped=true&"
import script from "./_OrganizerInfoCardBodyItemFrame.vue?vue&type=script&lang=ts&"
export * from "./_OrganizerInfoCardBodyItemFrame.vue?vue&type=script&lang=ts&"
import style0 from "./_OrganizerInfoCardBodyItemFrame.vue?vue&type=style&index=0&id=92c5fa92&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92c5fa92",
  null
  
)

export default component.exports