




































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ViewButton extends Vue {
  @Prop() currentView

  setCurrentView(days: number) {
    this.$emit('setCurrentView', days)
  }
}
