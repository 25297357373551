






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ICalendar, ITypeCalendarListForUI } from '@/types'
import UserBadge from '@/components/molecules/UserBadge.vue'
import CalendarSettingItemCalendar from '@/components/calendar/CalendarSettingItemCalendar.vue'
import { AvailabilityCalendarType } from '@spirinc/contracts'

type AccountWithCalendarsItem = {
  accountId: string
  userName: string
  ownerCalendars: ITypeCalendarListForUI[]
  notOwnerCalendars: ITypeCalendarListForUI[]
}

@Component({
  components: {
    CalendarSettingItemCalendar,
    UserBadge
  }
})
export default class CalendarListWithCheckStatus extends Vue {
  @Prop({ default: () => [] }) accountWithCalendars: AccountWithCalendarsItem[]
  @Prop({ default: () => [] }) checkedCalendars: AvailabilityCalendarType[]

  isCheckedCalendar(calendar: ICalendar) {
    const checked = this.checkedCalendars.some((checkedCalendar) => checkedCalendar.calendarId === calendar.id)
    return !!checked
  }
  onToggleCalendar(calendar: ICalendar) {
    this.$emit('onToggleCalendar', calendar)
  }
  onAddCalendar(calendar: ICalendar) {
    this.$emit('onAddCalendar', calendar)
  }
}
