










































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITypeCalendarListForUI } from '@/types'
import vClickOutside from 'v-click-outside'

@Component({
  directives: {
    clickOutside: vClickOutside.directive
  }
})
export default class CalendarFilter extends Vue {
  @Prop() visibleCalendars: string[]
  @Prop() writableCalendars: ITypeCalendarListForUI[]

  isVisibleList = false

  get isSelectedAllCalendars(): boolean {
    return this.writableCalendars.every((c) => this.isSelectedCalendar(c))
  }

  onClickHeader() {
    this.isVisibleList = !this.isVisibleList
  }

  isSelectedCalendar(calendar: ITypeCalendarListForUI): boolean {
    return this.visibleCalendars.indexOf(calendar.key) >= 0
  }

  onSelectCalendar(calendar) {
    this.$emit('onCalendarToggle', calendar.key)
  }

  closeVisibleList(event) {
    if (this.isVisibleList) {
      this.isVisibleList = false
      event.stopPropagation()
    }
  }
}
