/* eslint-disable @typescript-eslint/camelcase */
import axiosInstance from '../axios'
import store from '@/store'

export const changeToPublicPath = (path) => {
  if (store.getters['User/isSignIn']) {
    return path
  }
  return `public/${path}`
}

export const searchAddress = async (searchQuery: string, key: string = null) => {
  const options = {
    params: {
      q: searchQuery,
      session_key: key
    }
  }
  return (await axiosInstance.get('utils/search-address', options)).data
}

export const getAutoCandidates = async (payload) => {
  return (await axiosInstance.get('utils/auto-candidates', { params: payload })).data
}
