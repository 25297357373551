






















import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { defineComponent, PropType, computed } from '@vue/composition-api'
import ConditionIncludedCalendarsAndMembersAutocomplete from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/index.vue'
import {
  SelectedEmailType,
  ListSetForUI,
  AvailableAutocompleteListType,
  AutocompleteCalendar,
  AutocompleteMember,
  MemberList
} from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'
import { ICalendar } from '@/types'
import { useAutocompleteFromCalendarsAndMembers } from '@/components/ui/domain/autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/useAutocompleteFromCalendarsAndMembers'

export default defineComponent({
  name: 'FormAttendingEmails',
  components: {
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    ConditionIncludedCalendarsAndMembersAutocomplete
  },
  props: {
    selectedCalendarTypes: {
      type: Array as PropType<SelectedEmailType[]>,
      default: () => []
    },
    availableAttendeeList: {
      type: Array as PropType<ICalendar[]>,
      default: () => []
    },
    availableMemberList: {
      type: Array as PropType<MemberList[]>,
      default: () => []
    },
    emailInputAllowed: {
      type: Boolean,
      default: true
    },
    onCalendarAdd: {
      type: Function as PropType<(selectedEmail: string) => void>,
      required: true
    },
    onCalendarDelete: {
      type: Function as PropType<(deletedEmail: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { getEmailFromAvailableAutocompleteListType } = useAutocompleteFromCalendarsAndMembers()
    const listSetForUI = computed((): ListSetForUI => {
      const calendarItems: AutocompleteCalendar[] = props.availableAttendeeList
        .filter((al) => !props.selectedCalendarTypes.find((im) => im.email === al.invitationAddress))
        .map((item) => ({
          type: 'calendar',
          listItem: item
        }))
      const memberItems: AutocompleteMember[] = props.availableMemberList
        .filter((al) => !props.selectedCalendarTypes.find((im) => im.email === al.member.email))
        .map((item) => ({
          type: 'member',
          teamId: item.teamId,
          listItem: item.member
        }))
      return {
        selectedCalendars: props.selectedCalendarTypes.map((item) => {
          const foundCalendar = props.availableAttendeeList.find((al) => al.invitationAddress === item.email)
          if (foundCalendar) {
            return {
              type: 'calendar',
              listItem: foundCalendar
            }
          }
          const foundMember = props.availableMemberList.find((listItem) => listItem.member.email === item.email)
          if (foundMember) {
            return {
              type: 'member',
              teamId: foundMember.teamId,
              listItem: foundMember.member
            }
          }
          return {
            type: 'email',
            email: item.email
          }
        }),
        targetCalendars: [...calendarItems, ...memberItems]
      }
    })
    const handleCalendarAdd = (addedItem: AvailableAutocompleteListType) => {
      props.onCalendarAdd(getEmailFromAvailableAutocompleteListType(addedItem))
    }
    const handleCalendarDelete = (deletedItem: AvailableAutocompleteListType) => {
      props.onCalendarDelete(getEmailFromAvailableAutocompleteListType(deletedItem))
    }
    return {
      listSetForUI,
      handleCalendarAdd,
      handleCalendarDelete
    }
  }
})
