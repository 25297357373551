














import { defineComponent } from '@vue/composition-api'
import SignInButtons from '@/components/auth/SignInButtons.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import { useAuthSignIn } from '@/composables/auth/useAuthSignInWithRedirect'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'

export default defineComponent({
  components: {
    AuthFormSpacer,
    SignInButtons
  },
  setup(_, { emit }) {
    const { signInWithMicrosoft, signInWithGoogle } = useAuthSignIn()
    const { sendSignal } = useSendSignal()
    const handleSignInToGoogle = async () => {
      await sendSignal(SignalType.CLICK_SIGNIN_TEAM_INVITED)
      return signInWithGoogle()
    }
    const handleSignInToMicrosoft = async () => {
      await sendSignal(SignalType.CLICK_SIGNIN_TEAM_INVITED)
      return signInWithMicrosoft()
    }
    const goToSignUp = () => {
      emit('goToSignUp')
    }
    return {
      goToSignUp,
      handleSignInToGoogle,
      handleSignInToMicrosoft
    }
  }
})
