























import FormTextInputItem from '@/components/ui/form/item/FormTextInputItem.vue'
import { useVeeValidationProviderSlot } from '@/composables/useVeeValidationProviderSlot'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'

/**
 * AvailabilitySharingの相手に見えるタイトル
 */
export default defineComponent({
  name: 'FormCandidateTitleInputItem',
  components: {
    ValidationProvider,
    FormTextInputItem
  },
  props: {
    suggestions: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { value, onInput } = toRefs(props)
    const { makeErrorMessage } = useVeeValidationProviderSlot()

    const model = computed({
      get: () => value.value,
      set: (newValue: string) => {
        onInput.value(newValue)
      }
    })

    return {
      model,
      makeErrorMessage
    }
  }
})
