



























import SpirButton from '@/components/ui/Button.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import SpirTextInput from '@/components/ui/spir/SpirTextInput.vue'
import { useVeeValidationProviderSlot } from '@/composables/useVeeValidationProviderSlot'
import { defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import EmailDeleteActionBox from './_EmailDeleteActionBox.vue'

export default defineComponent({
  name: 'ConfirmationEmailInput',
  components: {
    ValidationObserver,
    ValidationProvider,
    FormItemSpacer,
    SpirTextInput,
    EmailDeleteActionBox,
    SpirButton
  },
  props: {
    emails: {
      type: Array as PropType<string[]>,
      default: []
    },
    onNewEmailAdd: {
      type: Function as PropType<(email: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { makeErrorMessage } = useVeeValidationProviderSlot()
    const { onNewEmailAdd } = toRefs(props)
    const email = ref('')

    function reset() {
      email.value = ''
    }

    function addEmail() {
      onNewEmailAdd.value(email.value)
      reset()
    }

    return {
      makeErrorMessage,
      email,
      addEmail
    }
  }
})
