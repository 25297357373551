




































import { Component, Vue } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import TeamInvitedModule from '@/store/modules/teamInvited'
import TeamInvitedSlideLayout from '@/components/teamSettings/slides/TeamInvitedSlideLayout.vue'
import Divider from '@/components/atoms/Divider.vue'

@Component({
  components: {
    Avatar,
    TeamInvitedSlideLayout,
    Divider
  }
})
export default class TeamInvitedStartSlide extends Vue {
  get team() {
    return TeamInvitedModule.team
  }

  next() {
    this.$emit('next')
  }
}
