













import ConfirmOperationFrame from '@/components/sidePanels/confirm/atoms/ConfirmOperationFrame.vue'
import ConfirmSidePanelFrame from '@/components/sidePanels/confirm/atoms/ConfirmSidePanelFrame.vue'
import AvailabilitySharingConfirmOperationBox, {
  AvailabilitySharingListState,
  OverviewState
} from '@/components/sidePanels/confirm/availabilitySharing/AvailabilitySharingConfirmOperationBox.vue'
import { FullCalendarEvent } from '@/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    AvailabilitySharingConfirmOperationBox,
    ConfirmSidePanelFrame,
    ConfirmOperationFrame
  },
  props: {
    overviewState: {
      type: Object as PropType<OverviewState>,
      required: true
    },
    availabilitySharingListState: {
      type: Object as PropType<AvailabilitySharingListState>,
      required: true
    },
    onCandidateSelect: {
      type: Function as PropType<(payload: FullCalendarEvent) => Promise<void>>,
      required: true
    },
    onCandidateHover: {
      type: Function as PropType<(payload: FullCalendarEvent) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    return {
      handleCandidateSelect: props.onCandidateSelect,
      handleCandidateHover: props.onCandidateHover
    }
  }
})
