








import { Component, Vue } from 'vue-property-decorator'
import DisplayItem from '@/components/newDisplay/molecules/Item.vue'
import ConfirmText from './atoms/ConfirmText.vue'

@Component({
  components: {
    DisplayItem,
    ConfirmText
  }
})
export default class ConfirmToolLink extends Vue {
  get text(): string {
    return this.$t('confirms.link.publishLater').toString()
  }
}
