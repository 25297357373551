









import { computed, defineComponent, PropType } from '@vue/composition-api'
import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import TeamMemberDropdown from '@/components/ui/domain/dropdown/TeamMemberDropdown/index.vue'
import { MemberCalendar } from '@spirinc/contracts'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'FormOrganizerMemberDropdownItem',
  components: {
    ValidationProvider,
    FormItem,
    FormItemTitle,
    FormItemSpacer,
    TeamMemberDropdown
  },
  props: {
    organizerMemberId: {
      type: String
    },
    memberCalendars: {
      type: Array as PropType<MemberCalendar[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function as PropType<(newOrganizerMemberId: string) => void>,
      required: true
    }
  },
  setup(props) {
    const model = computed({
      get: () => props.organizerMemberId,
      set: (newValue: string) => {
        props.onChange(newValue)
      }
    })

    return {
      model
    }
  }
})
