import axiosInstance from '../axios'

export enum SettingType {
  AUTO_CANDIDATES = 'auto-candidates',
  ZOOM = 'zoom'
}

export const getSettings = async () => {
  return (await axiosInstance.get('settings')).data
}

export const updateSettings = async (type: SettingType, payload) => {
  return (await axiosInstance.put(`settings/${type}`, { payload })).data
}
