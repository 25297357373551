












import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'FormCollapseButton',
  props: {
    title: {
      type: String,
      required: true
    },
    collapseId: {
      type: String,
      required: true
    }
  },
  setup() {
    const open = ref(false)
    return {
      open
    }
  }
})
