









import { useTranslation } from '@/composables/useTranslation'
import { defineComponent, PropType } from '@vue/composition-api'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  name: 'ModelSwitchButton',
  components: {
    InlineSvg
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup() {
    const i18n = useTranslation()
    return {
      title: i18n.t('labels.event').toString()
    }
  }
})
