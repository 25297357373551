



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MemberResponse as MemberInfo } from '@spirinc/contracts'
import CalendarMemberAvatar from '@/components/patterns/form/molecules/CalendarMemberAvatar.vue'

@Component({
  components: {
    CalendarMemberAvatar
  }
})
export default class FormMemberSelector extends Vue {
  @Prop({ default: () => [] }) allMembers: MemberInfo[]
  @Prop({ default: false }) disabled: boolean
  @Prop() placeholder: string

  inputedValue = ''

  $refs: {
    autocomplete: any
  }

  get filtetedList() {
    return this.allMembers.filter((t) => {
      return t.fullName?.toLowerCase().indexOf(this.inputedValue.toLowerCase()) >= 0
    })
  }

  selected(selectedItem: MemberInfo) {
    if (!selectedItem) {
      return
    }
    this.$emit('addMember', selectedItem.id)
    // hack: 選択後、リストの一番目のDataが選択されるBugを回避するため。
    if (this.$refs.autocomplete) {
      this.$refs.autocomplete.isActive = false
    }
  }
  async onBlur() {
    setTimeout(() => {
      this.inputedValue = ''
    }, 200)
  }
}
