var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollapseWithChevronHeader',{attrs:{"disabled":_vm.isDisabled,"collapseId":_vm.collapseId},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('CalendarAvatarAndName',{attrs:{"photoUrl":_vm.account.userPhoto,"disabled":_vm.isDisabled,"title":_vm.account.userName,"showMenu":_vm.showMenu}},[(_vm.canRemove)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.handleRemoveCalendar}},[_vm._v(" "+_vm._s(_vm.$t('buttons.removeCalendar'))+" ")]):_vm._e(),(_vm.isDisabled)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_c('CalendarAddButton',{attrs:{"onlyConnectTo":_vm.accountType}},[_vm._v(" "+_vm._s(_vm.$t('buttons.accountReconnect'))+" ")])],1):_vm._e()],1)]},proxy:true}])},[(!_vm.isDisabled)?[_c('CollapseWithChevronHeader',{attrs:{"initStatus":false,"collapseId":_vm.collapseId + '_myCalendar'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('labels.myCalendars')))])]},proxy:true}],null,false,2385089780)},_vm._l((_vm.myCalendars),function(calendar,index){return _c('PersonalCalendarListItem',{key:calendar.id,attrs:{"calendar":calendar,"visible":calendar.visible,"isLoading":calendar.isLoading,"willShowMenuBottom":true,"renderingMenu":_vm.defer(index)},on:{"onToggleCalendar":function($event){return _vm.$emit('onToggleCalendar', {
            accountId: _vm.account.accountId,
            calendarId: calendar.id,
            visible: !calendar.visible
          })},"onCalendarBackgroundColorChange":_vm.handleCalendarBackgroundColorChange}})}),1),(_vm.allOtherCalendars && _vm.allOtherCalendars.length > 0)?_c('CollapseWithChevronHeader',{attrs:{"initStatus":false,"collapseId":_vm.collapseId + '_others'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('labels.otherCalendars')))])]},proxy:true}],null,false,3990848740)},[_c('b-input',{staticClass:"search-field mb-2",attrs:{"placeholder":_vm.$t('input.placeholder.search'),"type":"search","icon":"magnify"},model:{value:(_vm.otherCalendarsSearch),callback:function ($$v) {_vm.otherCalendarsSearch=$$v},expression:"otherCalendarsSearch"}}),_vm._l((_vm.otherCalendars),function(calendar,index){return _c('PersonalCalendarListItem',{key:calendar.id,attrs:{"calendar":calendar,"visible":calendar.visible,"isLoading":calendar.isLoading,"renderingMenu":_vm.defer(index)},on:{"onToggleCalendar":function($event){return _vm.$emit('onToggleCalendar', {
            accountId: _vm.account.accountId,
            calendarId: calendar.id,
            visible: !calendar.visible
          })},"onCalendarBackgroundColorChange":_vm.handleCalendarBackgroundColorChange}})})],2):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }