










import FormItem from '@/components/ui/form/FormItem.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import RegisteredEmailList from '@/components/ui/domain/item/FormConfirmationEmailTargetsItem/_RegisteredEmailList.vue'
import ConfirmationEmailInput from '@/components/ui/domain/item/FormConfirmationEmailTargetsItem/_ConfirmationEmailInput.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FormConfirmationEmailTargetsItem',
  components: {
    FormItemTitle,
    FormItemSpacer,
    FormItem,
    RegisteredEmailList,
    ConfirmationEmailInput
  },
  props: {
    emails: {
      type: Array as PropType<string[]>,
      required: true
    },
    onInput: {
      type: Function as PropType<(email: string) => void>,
      required: true
    },
    onDelete: {
      type: Function as PropType<(email: string) => void>,
      required: true
    }
  }
})
