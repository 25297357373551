



















import { Component, Vue } from 'vue-property-decorator'
import AppModule from '@/store/modules/app'
import AsideMenus from '@/components/calendarList/Index.vue'

@Component({
  components: {
    AsideMenus
  }
})
export default class LayoutBase extends Vue {
  get isQuickViewVisible() {
    return AppModule.isQuickViewVisible
  }
  closeQuickView() {
    AppModule.ON_CLOSE_QUICK_VIEW()
  }
}
