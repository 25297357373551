


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MemberResponse } from '@spirinc/contracts'
import MemberItem from './atoms/MemberItem.vue'
import ChevronButton from '@/components/atoms/ChevronButton.vue'
import DropdownWithList from '@/components/newForm/molecules/DropdownWithList.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'

type Authority = Pick<MemberResponse, 'authority'> & {
  label: string
  description: string
}

@Component({
  components: {
    MemberItem,
    ChevronButton,
    DropdownWithList,
    ConfirmModal
  }
})
export default class TeamMemberItem extends Vue {
  @Prop() member: MemberResponse
  @Prop() amIAdmin: boolean
  @Prop() currentUserId: string
  @Prop() updatedAuthorityMemberId: string | null
  @Prop() deleteTeamMemberId: string | null

  authorities: Authority[] = []
  mounted() {
    this.authorities = [
      {
        authority: 'administrator',
        label: this.$t('teamMenu.authority.administrator').toString(),
        description: this.$t('teamMenu.authority.admin-description').toString()
      },
      {
        authority: 'member',
        label: this.$t('teamMenu.authority.member').toString(),
        description: this.$t('teamMenu.authority.member-description').toString()
      }
    ]
  }

  get disableForMemberAuthority() {
    return !this.amIAdmin || this.currentUserId === this.member.userId
  }

  get showDotsButton() {
    return this.amIAdmin && this.currentUserId !== this.member.userId
  }

  get currentLabel() {
    const currentAuthority = this.authorities.find((authority) => this.member.authority === authority.authority)
    return currentAuthority?.label
  }

  get isUpdateAuthority() {
    return this.updatedAuthorityMemberId !== null && this.updatedAuthorityMemberId === this.member.id
  }

  public updateMemberAuthority(authority: 'administrator' | 'member'): void {
    this.member.authority = authority
    this.$emit('updateMemberAuthority', this.member)
  }

  public deleteTeamMember() {
    this.$emit('deleteMemberItemClicked', this.member)
  }
}
