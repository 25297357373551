import { useAppScreenInfo } from '@/composables/useAppScreenInfo'
import { useDeviceInfo } from '@/composables/useDeviceInfo'

export const useScheduleEventListPanel = () => {
  const { isNarrow } = useDeviceInfo()
  const { toggleScheduleEventsList } = useAppScreenInfo()

  const checkMobileAndShowPanel = () => {
    if (isNarrow.value) {
      toggleScheduleEventsList(true)
    }
  }

  checkMobileAndShowPanel()
}
