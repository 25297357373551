export const frontSupportCountryCodes = [
  'JP',
  'US',
  'CN',
  'KR',
  'TW',
  'HK',
  'IN',
  'SG',
  'TH',
  'AU',
  'GB',
  'CA',
  'BR',
  'FR',
  'DE'
] as const
export type FrontSupportCountryCode = typeof frontSupportCountryCodes[number]
