import * as availabilityAPI from '@/lib/api/availabilityTeam'
import { AvailabilityModelForTeam, PatternCandidateModel } from '@/models/data'
import store from '@/store'
import { FullCalendarEvent, Holiday } from '@/types'
import { cloneDeep } from 'lodash'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import AvailabilityPatternListModule from './availabilityPatternList'
import TimezoneModule from './timezones'

const MODULE_NAME = 'EditAvailabilityTeam'

// const savedAvailability = new AvailabilityStorage()
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class EditAvailabilityTeam extends VuexModule {
  _editModel: AvailabilityModelForTeam | null = null
  _patternCandidate: PatternCandidateModel | null = null
  _isLoading = false
  _isCandidateLoading = false
  _isDirty = false

  get getIsLoading() {
    return this._isLoading
  }
  get getIsCandidateLoading() {
    return this._isCandidateLoading
  }
  get getModel(): AvailabilityModelForTeam {
    return this._editModel
  }
  get isDirty() {
    return this._isDirty
  }
  get candidatesInfo() {
    return this._patternCandidate
  }
  get candidatesByConditionAsCalendarFormat(): FullCalendarEvent[] {
    // confirmer
    if (!this.candidatesInfo) {
      return []
    }
    return this._patternCandidate.candidates
  }
  get candidatesByMerged(): FullCalendarEvent[] {
    if (!this.candidatesInfo) {
      return []
    }
    return this._patternCandidate.mergedCandidates
  }
  get showMemberCalendars(): string[] | undefined {
    return this.getModel?.showMemberCalendars
  }
  @Action({ commit: 'SET_MODEL' })
  newAvailabilityForTeam(teamId) {
    const newModel = new AvailabilityModelForTeam(teamId)
    return newModel
  }
  @Action({ commit: 'SET_MODEL' })
  async fetchAvailability({ teamId, id }: { teamId: string; id: string }) {
    this.SET_LOADING(true)
    try {
      const availability = await availabilityAPI.getAvailability(teamId, id)
      const newModel = new AvailabilityModelForTeam(teamId, availability)
      return newModel
    } catch (e) {
      return null
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action({ commit: 'SET_CANDIDATES' })
  async fetchCandidatesByParam() {
    if (!this.getModel.isValidToFetchCandidates) {
      return Promise.resolve(null)
    }
    try {
      this.SET_CANDIDATE_LOADING(true)
      const response = await availabilityAPI.fetchCandidatesByParam(
        this.getModel.teamId,
        this.getModel.parameterForFetchCandidates
      )
      this.SET_HOLIDAYS(response.holidays)
      return {
        ...response,
        candidates: response.timespans
      }
    } catch (e) {
      return null
    } finally {
      this.SET_CANDIDATE_LOADING(false)
    }
  }
  @Action
  async create(): Promise<string> {
    try {
      this.SET_LOADING(true)
      const response = await availabilityAPI.create(this._editModel.teamId, this._editModel.parameterForCreate)
      AvailabilityPatternListModule.fetchPatterns(this._editModel.teamId)
      this.reset()
      return response.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async update(): Promise<string> {
    try {
      this.SET_LOADING(true)
      const response = await availabilityAPI.updatePattern(
        this._editModel.teamId,
        this._editModel.id,
        this._editModel.parameterForCreate
      )
      const newModel = new AvailabilityModelForTeam(this._editModel.teamId, response)
      AvailabilityPatternListModule.UPDATE_TEAM_LIST({ teamId: this._editModel.teamId, id: newModel.id, newModel })
      this.reset()
      return response.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  updateModel(newModel: AvailabilityModelForTeam) {
    newModel.updateEndDate()
    this.SET_MODEL(newModel)
    this.SET_DIRTY(true)
  }
  @Action
  reset() {
    this.SET_MODEL(null)
    this.SET_CANDIDATES(null)
    this.SET_DIRTY(false)
  }
  @Mutation
  SET_DIRTY(dirtyFlag: boolean) {
    this._isDirty = dirtyFlag
  }
  @Mutation
  SET_MODEL(model: AvailabilityModelForTeam | null) {
    this._editModel = cloneDeep(model)
    TimezoneModule.UpdateTimezoneForInterrupt(model?.timeZone)
  }
  @Mutation
  SET_LOADING(isLoading) {
    this._isLoading = isLoading
  }
  @Mutation
  SET_CANDIDATES(patternCandidate) {
    if (patternCandidate) {
      this._patternCandidate = new PatternCandidateModel(patternCandidate)
    } else {
      this._patternCandidate = null
    }
  }
  @Mutation
  SET_CANDIDATE_LOADING(loadingStatus: boolean) {
    this._isCandidateLoading = loadingStatus
  }
  @Mutation
  SET_HOLIDAYS(holidays: Holiday[]) {
    if (this._editModel) {
      const newObject = cloneDeep(this._editModel)
      newObject.holidays = holidays
      this._editModel = newObject
    }
  }
}

export default getModule(EditAvailabilityTeam)
