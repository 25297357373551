
























import { Component, Prop, Vue } from 'vue-property-decorator'

const COLLAPSE_PREFIX = 'COLLAPSE_STATUS_'

@Component
export default class ComponentName extends Vue {
  @Prop({ default: null }) collapseId: string
  @Prop({ default: true }) initStatus: boolean
  @Prop({ default: false }) isBodyAlignedLeft: boolean
  @Prop({ default: false }) disabled: boolean

  isOpen = true
  created() {
    this.isOpen = this.initStatus
  }
  mounted() {
    if (!this.collapseKey) {
      return
    }
    const collapseStatus = this.$ls.get(this.collapseKey)
    if (typeof collapseStatus === 'boolean') {
      this.isOpen = collapseStatus
    }
  }
  get collapseKey() {
    return this.collapseId ? `${COLLAPSE_PREFIX}${this.collapseId}` : null
  }

  toggle() {
    this.isOpen = !this.isOpen
    if (!this.collapseKey) {
      return
    }
    this.$ls.set(this.collapseKey, this.isOpen)
  }
}
