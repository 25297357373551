











import { Component, Prop, Vue } from 'vue-property-decorator'
import Card from '@/components/card/molecules/Card.vue'

@Component({
  components: {
    Card
  }
})
export default class OnWaitingCard extends Vue {
  @Prop() expired: boolean
  @Prop() iconName: string
  @Prop() title: string
}
