




































import CalendarLayout from '@/components/layout/CalendarCommon.vue'
import { CalendarAlertState, CalendarCommonHandlers } from '@/components/layout/types'
import CandidateDetail from '@/components/modal/CandidateDetail.vue'
import EventFormModal from '@/components/modal/EventForm.vue'
import ScheduleForm from '@/components/modal/ScheduleForm.vue'
import ScheduleCreateFooterButtons from '@/components/schedule/ScheduleCreateFooterButtons.vue'
import { useAnalytics } from '@/composables/useAnalytics'
import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { useToast } from '@/composables/useToast'
import i18n from '@/i18n'
import { SignalType } from '@/lib/analytics/LogEntry'
import { EventBus, EVENTS } from '@/lib/eventBus'
import queryParams from '@/lib/queryParams'
import { getHourByUserTimezone } from '@/lib/utils/dateFormat'
import { PollModel } from '@/models/data/poll'
import { ScheduleModelCommon, ScheduleModelTeam } from '@/models/data/schedule'
import AppModule from '@/store/modules/app'
import CalendarControlModule from '@/store/modules/calendarControl'
import EditEventModule from '@/store/modules/editEvent'
import EditPollModule from '@/store/modules/editPoll'
import EditScheduleModule from '@/store/modules/editSchedule'
import EventForModalModule from '@/store/modules/eventForModal'
import ListPanelModule, { ListType } from '@/store/modules/listPanel'
import PollListModule from '@/store/modules/pollList'
import ScheduleListModule from '@/store/modules/scheduleList'
import TeamScheduleListModule from '@/store/modules/teamScheduleList'
import { ApiFetchEvent, CalendarBodyMode, CreateEvent, FullCalendarEvent, GoogleEvent, ICandidateBase } from '@/types'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@vue/composition-api'
import { parseISO } from 'date-fns'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

export default defineComponent({
  name: 'Common',
  components: {
    CalendarLayout,
    ScheduleCreateFooterButtons
  },
  props: {
    showLoaderOnCalendar: {
      type: Boolean,
      default: false
    },
    showCalendarFooter: {
      type: Boolean,
      default: false
    },
    showSidePanel: {
      type: Boolean,
      default: false
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    firstDate: {
      type: Date
    },
    theme: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isConfirm: {
      type: Boolean,
      default: false
    },
    additionalEvents: {
      type: Array,
      default: () => []
    },
    timezoneButtonAsLabel: {
      type: Boolean,
      default: false
    },
    calendarAlertState: {
      type: Object as PropType<CalendarAlertState>,
      default: () => ({ type: 'closed' })
    },
    calendarBodyMode: {
      type: Object as PropType<CalendarBodyMode>,
      default: () => ({ type: 'normal' })
    }
  },
  setup(props, context) {
    const { firstDate, isLoading } = toRefs(props)
    const calendarLayoutRef = ref<CalendarCommonHandlers | null>(null)
    const analytics = useAnalytics()
    const route = useRoute()
    const router = useRouter()
    const { openDangerBottomToast, openPrimaryTopToast } = useToast()
    const { showModal, deleteEventItem, confirmDeleteItem } = useCalendarMixin()

    const showLoader = computed(() => {
      return isLoading.value || AppModule.isLoading
    })
    function setDate() {
      const confirmedDate = route.query[queryParams.QUERY_PARAM_CONFIRMED_DATE] as string
      if (confirmedDate) {
        try {
          const currentDate = parseISO(confirmedDate)
          CalendarControlModule.setCurrentDate(currentDate)
        } catch {}
      }
    }
    async function gotoScheduleEditPage(scheduleId, type: ListType) {
      let nextRouteName = 'EditSchedule'
      if (type === 'poll') {
        nextRouteName = 'EditPoll'
      }
      try {
        await router.push({ name: nextRouteName, params: { id: scheduleId } })
      } catch (e) {} // hack. prevent NavigationDuplicated error.
    }

    function setCalendarScroll() {
      if (!firstDate.value) {
        return
      }
      CalendarControlModule.setScrollToFirstHour(getHourByUserTimezone(firstDate.value))
    }
    async function gotoEditEvent(event: GoogleEvent) {
      await EditEventModule.setEventAsEditingEvent({
        eventId: event.id,
        accountId: event.accountId,
        calendarId: event.calendarId
      })
      router
        .push({
          name: 'EditEvent',
          params: { id: event.id }
        })
        .catch()
    }
    async function showEvent(displayEvent: GoogleEvent) {
      const event: GoogleEvent = displayEvent
      await analytics.send(SignalType.OPEN_EVENTDETAIL)
      const modal = showModal(
        EventFormModal,
        {
          editEvent: async () => {
            modal.close()
            await analytics.send(SignalType.EDIT_EVENT)
            gotoEditEvent(event)
          },
          deleteItem: async (payload) => {
            modal.close()
            await analytics.send(SignalType.DELETE_EVENT)
            deleteEventItem({ accountId: payload.accountId, calendarId: payload.calendarId, eventId: payload.id })
          }
        },
        {
          currentEvent: event
        }
      )
    }
    function editScheduleListener({ scheduleId, type }) {
      return gotoScheduleEditPage(scheduleId, type)
    }
    async function findEventAndShowEvent(payload: ApiFetchEvent) {
      if (payload.teamInfo) {
        await EventForModalModule.getTeamEventByEventId(payload)
      } else {
        await EventForModalModule.getEventByEventId(payload)
      }
      const event = EventForModalModule.getTemporaryEvent
      if (!event) {
        openDangerBottomToast({ message: i18n.t('message.errorCommon').toString() })
        return
      }
      if (payload.moveDate) {
        CalendarControlModule.setCurrentDate(new Date(event.start))
      }
      showEvent(event)
    }

    function gotoConfirm(id, candidate: ICandidateBase, type: ListType) {
      const pageData = {
        name: type === ListType.SCHEDULE ? 'ConfirmSchedule' : 'EditPoll',
        params: { id }
      }
      if (candidate) {
        if (type === ListType.SCHEDULE) {
          pageData['query'] = { [queryParams.QUERY_PARAM_CONFIRMED_DATE]: candidate.start }
        } else {
          pageData['query'] = { [queryParams.QUERY_PARAM_CONFIRMED_ID]: candidate.id }
        }
      }
      router.push(pageData)
    }
    async function deleteCandidate(
      schedule: ScheduleModelCommon | PollModel,
      candidateId: string,
      type: ListType,
      teamId?: string
    ) {
      const isLastCandidate = schedule.validCandidates.length === 1
      let customMessage: string, customTitle: string, resultString: string
      const typeString = i18n.t(`labels.${type}`).toString()
      if (isLastCandidate) {
        customMessage = i18n.t('message.deleteConfirm', { type: typeString }).toString()
        customTitle = i18n.t('message.confirmDeleteWholeTitle', { type: typeString }).toString()
        resultString = i18n.t('message.success.deleted', { type: typeString }).toString()
      } else {
        customMessage = i18n.t('message.deleteCandidateConfirm', { type: typeString }).toString()
        customTitle = i18n.t('message.confirmDeleteCandidateTitle').toString()
        resultString = i18n.t('message.success.deleted', { type: i18n.t('labels.candidate').toString() }).toString()
      }
      const deleteResponse = await confirmDeleteItem({ customMessage, customTitle })
      if (!deleteResponse) return false
      try {
        if (type === 'schedule') {
          if (teamId) {
            await TeamScheduleListModule.deleteCandidate({ teamId, scheduleId: schedule.id, candidateId })
          } else {
            if (isLastCandidate) {
              await ScheduleListModule.deleteSchedule(schedule.id)
            } else {
              await EditScheduleModule.deleteCandidate({ scheduleId: schedule.id, candidateId })
            }
          }
        } else {
          if (isLastCandidate) {
            await PollListModule.deletePoll(schedule.id)
          } else {
            await EditPollModule.deleteCandidate({ scheduleId: schedule.id, candidateId })
          }
        }
        openPrimaryTopToast({ message: resultString })
      } catch (e) {
        openDangerBottomToast({ message: i18n.t('message.errorCommon').toString() })
      }
      return true
    }
    async function showScheduleModal(schedule: any, candidateId: string, type: ListType) {
      await analytics.send(SignalType.OPEN_CANDIDATE, { type })
      const modal = showModal(
        ScheduleForm,
        {
          editSchedule: async () => {
            modal.close()
            await analytics.send(SignalType.CLICK_EDIT_ON_CANDIDATE_MODAL, { type })
            return gotoScheduleEditPage(schedule.id, type)
          },
          deleteCandidate: async () => {
            await analytics.send(SignalType.CLICK_DELETE_ON_CANDIDATE_MODAL, { type })
            if (await deleteCandidate(schedule, candidateId, type)) {
              modal.close()
            }
          },
          confirmSchedule: async () => {
            modal.close()
            await analytics.send(SignalType.CLICK_CONFIRM_ON_CANDIDATE_MODAL, { type })
            const selecttedCandidate: ICandidateBase = schedule.candidates.find((c) => c.id === candidateId)
            if (selecttedCandidate) {
              gotoConfirm(schedule.id, selecttedCandidate, type)
            } else {
              gotoConfirm(schedule.id, null, type)
            }
          }
        },
        {
          currentSchedule: schedule,
          candidateId: candidateId,
          type
        }
      )
    }
    async function showPollCandidateModal(pollId: string, candidateId: string) {
      const poll = await ListPanelModule.getItemById({ itemId: pollId, type: ListType.POLL })
      if (!poll) {
        return
      }
      showScheduleModal(poll, candidateId, ListType.POLL)
    }

    async function showTeamScheduleModal(schedule: ScheduleModelTeam, candidateId: string) {
      await analytics.send(SignalType.OPEN_CANDIDATE, { type: 'teamSchedule' })
      const modal = showModal(
        CandidateDetail,
        {
          editSchedule: async () => {
            modal.close()
            await analytics.send(SignalType.CLICK_EDIT_ON_CANDIDATE_MODAL, { type: 'teamSchedule' })
            await router.push({
              name: 'TeamScheduleEdit',
              params: { id: schedule.teamId, scheduleId: schedule.id }
            })
          },
          deleteCandidate: async () => {
            await analytics.send(SignalType.CLICK_DELETE_ON_CANDIDATE_MODAL, { type: 'teamSchedule' })
            if (await deleteCandidate(schedule, candidateId, ListType.SCHEDULE, schedule.teamId)) {
              modal.close()
            }
          },
          confirmSchedule: async () => {
            modal.close()
            await analytics.send(SignalType.CLICK_CONFIRM_ON_CANDIDATE_MODAL, { type: 'teamSchedule' })
            const selecttedCandidate: ICandidateBase = schedule.candidates.find((c) => c.id === candidateId)
            await router.push({
              name: 'TeamScheduleConfirm',
              params: { id: schedule.teamId, scheduleId: schedule.id },
              query: {
                [queryParams.QUERY_PARAM_CONFIRMED_DATE]: selecttedCandidate?.start
              }
            })
          }
        },
        {
          currentSchedule: schedule,
          candidateId: candidateId
        }
      )
    }

    async function findScheduleAndShowSchedule(payload: {
      scheduleId: string
      candidateId: string
      teamInfo?: { teamId: string }
    }) {
      const { scheduleId, candidateId, teamInfo } = payload
      if (teamInfo) {
        const schedule = await TeamScheduleListModule.scheduleModelByTeamIdScheduleId(teamInfo.teamId, scheduleId)
        if (!schedule) {
          return
        }
        showTeamScheduleModal(schedule, candidateId)
      } else {
        const schedule = await ListPanelModule.getItemById({ itemId: scheduleId, type: ListType.SCHEDULE })
        if (!schedule) {
          return
        }
        showScheduleModal(schedule, candidateId, ListType.SCHEDULE)
      }
    }
    watch(firstDate, () => {
      setCalendarScroll()
    })
    EventBus.on(EVENTS.EDIT_SCHEDULE, editScheduleListener)
    EventBus.on(EVENTS.SHOW_EVENT, findEventAndShowEvent)
    setDate()
    onBeforeUnmount(() => {
      EventBus.off(EVENTS.EDIT_SCHEDULE, editScheduleListener)
      EventBus.off(EVENTS.SHOW_EVENT, findEventAndShowEvent)
    })
    onMounted(() => {
      setCalendarScroll()
    })

    return {
      calendarLayoutRef,
      showLoader,
      handleAddEvent: async (payload: CreateEvent) => {
        context.emit('addEvent', payload)
      },
      handleUpdateCandidate: async ({ event, revert }: { event: FullCalendarEvent; revert: Function }) => {
        context.emit('updateEvent', { event, revert })
      },
      handleDateClick: (date) => {
        context.emit('dateClick', date)
      },

      handleEventClick: ({
        event,
        jsEvent,
        el
      }: {
        event: FullCalendarEvent
        jsEvent: MouseEvent
        el: HTMLElement
      }) => {
        const {
          id,
          extendedProps: { source, accountId, scheduleId, calendarId, teamInfo, visibility, candidateId }
        } = event
        switch (source) {
          // confirm用のEventは何もしない。
          case 'confirmer':
          case 'confirmerUnderThirty':
            context.emit('clickConfirmer')
            break
          case 'rejected':
          case 'editingEvent':
            break
          case 'templateCandidate':
            context.emit('clickedCandidate', { event, jsEvent, el })
            break
          case 'candidate':
            context.emit('clickedCandidate', id)
            break
          case 'expired':
          case 'schedule':
            findScheduleAndShowSchedule({
              scheduleId,
              candidateId: candidateId || id,
              teamInfo
            })
            break
          case 'expiredPoll':
          case 'poll':
            showPollCandidateModal(scheduleId, id)
            break
          case 'pending':
          case 'pollAnswerNo':
          case 'pollAnswerYes':
            context.emit('clickedCandidate', { event, jsEvent, el })
            break
          case 'exceptionExclude':
          case 'exceptionInclude':
            context.emit('clickedException', event)
            break
          case 'holidayExclude':
          case 'holidayObservedExclude':
            context.emit('clickedHolidayExclude', event)
            break
          default:
            findEventAndShowEvent({ eventId: id, accountId, calendarId, teamInfo, visibility })
            break
        }
      },
      updateCalendarHeight: () => {
        if (calendarLayoutRef.value) {
          calendarLayoutRef.value.updateHeight()
        }
      }
    }
  }
})
