









import AvailabilitySharingEditPage from '@/components/page/availabilitySharing/AvailabilitySharingEditPage.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import { useModal } from '@/composables/useModal'
import { GenAvailabilitySharingTitleSuggestionSetup } from '@/lib/utils'
import { AllRouteNames } from '@/router'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import { defineComponent } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'

export default defineComponent({
  components: {
    AvailabilitySharingEditPage
  },
  setup() {
    const router = useRouter()
    const { openDiscardConfirmationModal } = useModal()
    const { isEditingObjectDirty } = useAvailabilitySharingForm()
    const setups = GenAvailabilitySharingTitleSuggestionSetup.genSuggestionSetups()

    function goListPage() {
      router.push({ name: AllRouteNames.AvailabilityList }).catch(GuardExceptionHandlers.noopAgainstAbortion)
    }

    function guard(next) {
      if (isEditingObjectDirty.value) {
        openDiscardConfirmationModal({
          confirm: () => next(),
          cancel: () => next(false)
        })
      } else {
        next()
      }
    }

    return {
      setup: setups.personalSetup,
      goListPage,
      guard
    }
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next)
  }
})
