









import { Component, Prop, Vue } from 'vue-property-decorator'
import { SignalType } from '@/lib/analytics/LogEntry'

@Component
export default class AsideMenuItemLogo extends Vue {
  @Prop() expanded: boolean

  get imageSource() {
    return this.expanded ? require('@/assets/images/logo_l.png') : require('@/assets/images/logo_s.png')
  }
  async goHome() {
    await this.$analytics.send(SignalType.CLICK_NAV_LOGO)
    if (this.$route.name !== 'Main') {
      this.$router.push({ name: 'Main' })
    }
  }
}
