































import HomeIconButton from '@/components/atoms/HomeIconButton.vue'
import LoggedInUserBadge from '@/components/NavMenu/UserBadge/Index.vue'
import ProfileModule from '@/store/modules/profile'
import InlineSvg from 'vue-inline-svg'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    HomeIconButton,
    LoggedInUserBadge,
    InlineSvg
  }
})
export default class BottomNavMenu extends Vue {
  get myProfile() {
    return ProfileModule.myProfile
  }
}
