







import { Component, Vue } from 'vue-property-decorator'
import TermsAndPolicy from '@/components/atoms/TermsAndPolicy.vue'
import FullPageLayout from '@/components/layout/FullPage.vue'

@Component({
  components: {
    FullPageLayout,
    TermsAndPolicy
  }
})
export default class AuthLayout extends Vue {}
