






















import { defaultEventMenu, MenuInfo } from '@/lib/menus'
import { defineComponent, PropType } from '@vue/composition-api'
import Section from '../atoms/Section.vue'
import IconButton from '../molecules/IconButton.vue'

export default defineComponent({
  name: 'Event',
  components: {
    Section,
    IconButton
  },
  props: {
    eventInfo: {
      type: Object as PropType<MenuInfo>,
      default: () => defaultEventMenu
    }
  }
})
