import {
  useFormAttendingEmails,
  useFormAuthorDropdownItem,
  useFormDurationSelectBoxItem,
  useFormTitleInputItem
} from '@/components/ui/domain/item/formItemComposables'
import { usePeekCandidatesSectionSetup } from '@/components/ui/domain/section/arrangement/PeekCandidatesSection/usePeekCandidatesSectionSetup'
import { usePersonalOthersInfoSectionSetup } from '@/components/ui/domain/section/arrangement/personalOthersInfoSectionComposable'
import {
  DatetimeSlotDeletePayload,
  DatetimeSlotUpdatePayload
} from '@/components/ui/form/FormDatetimeSlotsBox/datetimeSlot'
import { useModal } from '@/composables/useModal'
import { useSendSignal } from '@/composables/useSendSignal'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { SignalType } from '@/lib/analytics'
import queryParams from '@/lib/queryParams'
import { filterByPrimaryCalendar } from '@/lib/utils'
import { ScheduleDuration, ScheduleModel } from '@/models/data/schedule'
import { AllRouteNames } from '@/router'
import { GuardExceptionHandlers } from '@/router/guard-exception-handlers'
import CalendarsModule from '@/store/modules/calendars'
import EditScheduleModule, { MAX_CANDIDATE_COUNT, UpdateResult } from '@/store/modules/editSchedule'
import { FullCalendarEvent, ISchedule, ListType, VISIBILITY } from '@/types'
import { computed, reactive } from '@vue/composition-api'
import { differenceInMinutes } from 'date-fns'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { usePeekCandidatesFormInit } from '../peekCandidatesSectionComposables/usePeekCandidatesFormInit'
import { FetchCandidatesConditionParameter } from '../peekCandidatesSectionComposables/_usePeekCandidates'
import { OverwritingData, PersonalScheduleFormData, UpdatePersonalScheduleForms } from './types'
import { useCandidatesMaxOverWatcher } from './_useCandidatesMaxOverWatcher'
import { useNextAction } from './_useNextAction'
import { useOptionalCalendarOpen } from './_useOptionalCalendarOpen'
import { usePersonalSchedulePageDataControl } from './_usePersonalSchedulePageDataControl'
import { useAuthorAndOnlineMeetingsConnect } from './_useSetupBetweenSections'

const TOAST_DURATION = 5000

type UsePersonalScheduleFormData = {
  scheduleModel: ScheduleModel
  titleSuggestions: string[]
  saveTitleSuggestion: (title: string) => void
  isEditMode?: boolean
  candidatesFilterForMixing?: (candidate: FullCalendarEvent) => boolean
  goToNext?: (shouldStayHere: () => Promise<boolean>) => void
  cancelEditing?: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  openCalendar?: () => void
}
export const usePersonalScheduleFormData = ({
  scheduleModel,
  titleSuggestions,
  saveTitleSuggestion,
  isEditMode = false,
  candidatesFilterForMixing = () => false,
  goToNext,
  cancelEditing,
  openCalendar
}: UsePersonalScheduleFormData): {
  updatePersonalScheduleForms: (data: Partial<UpdatePersonalScheduleForms>) => void
  updatePersonalScheduleFormData: (overwrite?: OverwritingData) => void
  getPersonalScheduleFormData
} => {
  const { sendSignal } = useSendSignal()
  const route = useRoute()
  const router = useRouter()
  const titleForm = useFormTitleInputItem(scheduleModel.title)
  const durationForm = useFormDurationSelectBoxItem(scheduleModel.duration)
  const authorForm = useFormAuthorDropdownItem({
    accountId: scheduleModel.accountId,
    calendarId: scheduleModel.calendarId
  })
  const { openOverDurationCandidatesRemovingConfirmModal } = useModal()
  const { openInfoBottomToast } = useToast()
  const i18n = useTranslation()
  const { handleCalendarOpen } = useOptionalCalendarOpen({ openCalendar })
  function goToUnconfirmedList() {
    router.push({ name: AllRouteNames.UnconfirmedList }).catch(GuardExceptionHandlers.noopAgainstAbortion)
  }
  const { getNextAction } = useNextAction({ goToNextPage: goToUnconfirmedList })
  const toNext = getNextAction(goToNext)
  const toCancelEditing = getNextAction(cancelEditing)

  const { peekCandidates, peekCandidatesConditionForm, copyTemporaryCandidatesToCandidates } =
    usePeekCandidatesFormInit({ listType: ListType.SCHEDULE })
  const availableAttendeeList = computed(() => {
    return CalendarsModule.allCalendarsButNotResource.filter(
      (c) =>
        c.invitationAddress &&
        !(c.accountId === authorForm.value.accountId && c.calendarId === authorForm.value.calendarId)
    )
  })
  const attendeesForm = useFormAttendingEmails({
    attendeeEmails: scheduleModel.attendees.map((attendee) => ({ type: 'email', email: attendee.email }))
  })
  const {
    descriptionForm,
    onlineMeetingForm,
    locationForm,
    visibilityForm,
    getDefaultOnlineMeetingTool,
    getAvailableOnlineMeetings
  } = usePersonalOthersInfoSectionSetup({
    description: scheduleModel.description,
    onlineMeetingType: scheduleModel.onlineMeeting.type,
    location: scheduleModel.location || '',
    visibility: scheduleModel.visibility
  })

  function generateGotoConfirmation(event: FullCalendarEvent) {
    return () => {
      sendSignal(SignalType.CLICK_CONFIRM_ON_EDIT_PAGE)
      router.push({
        name: AllRouteNames.ConfirmSchedule,
        params: {
          id: route.params.id
        },
        query: {
          [queryParams.QUERY_PARAM_CONFIRMED_DATE]: event.start.toISOString(),
          [queryParams.CANDIDATE_ID]: event.id
        }
      })
    }
  }
  function replaceAllCandidates(newCandidates: { start: string; end: string; id: string }[]) {
    EditScheduleModule.replaceAllCandidates(newCandidates)
  }
  const peekCandidatesSectionSetup = usePeekCandidatesSectionSetup({
    saveCandidate: EditScheduleModule.updateOrAddEventStartAndEnd,
    deleteCandidate: EditScheduleModule.removeCandidate,
    clearAllCandidates: EditScheduleModule.clearCandidates,
    getFullCalendarEvents: () => EditScheduleModule.getEditingEventByCalendarFormat,
    generateGotoConfirmation
  })

  const { availableOnlineMeetings, handleOrganizerUpdate } = useAuthorAndOnlineMeetingsConnect({
    authorForm,
    onlineMeetingForm,
    getDefaultOnlineMeetingTool,
    getAvailableOnlineMeetings
  })

  const currentSchedule = computed((): Partial<ISchedule> => {
    const personalSchedule = {
      title: titleForm.title,
      duration: durationForm.duration,
      accountId: authorForm.value.accountId,
      calendarId: authorForm.value.calendarId,
      location: locationForm.location,
      description: descriptionForm.description,
      visibility: visibilityForm.visibility as VISIBILITY,
      onlineMeeting: { type: onlineMeetingForm.onlineMeetingType },
      attendees: attendeesForm.selectedEmails.map((emailType) => ({ email: emailType.email })),
      candidates: EditScheduleModule.editingSchedule.candidates
    }
    return personalSchedule
  })
  function updateScheduleModel(partialModel: Partial<ISchedule>): UpdateResult {
    return EditScheduleModule.update(partialModel)
  }
  const { clearDirty, shouldStayHere, pageDataState, handlers } = usePersonalSchedulePageDataControl({
    pageData: currentSchedule,
    updateScheduleModel,
    saveTitleSuggestion,
    isEditMode,
    goToNext: toNext,
    cancelEditing: toCancelEditing
  })

  function getUnderDurationCandidates(newDuration: number): FullCalendarEvent[] {
    return peekCandidatesSectionSetup.candidates?.filter(
      (c: FullCalendarEvent) => differenceInMinutes(c.end, c.start) < newDuration
    )
  }
  function mixTemporariesAndOthers(): FullCalendarEvent[] {
    return peekCandidates.tempCandidates
      .concat(peekCandidatesSectionSetup.candidates.filter(candidatesFilterForMixing))
      .concat(peekCandidates.holidayEvents)
  }

  function updateForms(data: Partial<UpdatePersonalScheduleForms>) {
    if (data.title) titleForm.handleUpdate(data.title)
    if (data.duration) durationForm.handleUpdate(data.duration)
    if (data.author) authorForm.handleUpdate(data.author)
    if (data.attendeeEmails) attendeesForm.handleReplace(data.attendeeEmails)
    if (data.description !== undefined) descriptionForm.handleUpdate(data.description)
    if (data.onlineMeeting) onlineMeetingForm.handleUpdate(data.onlineMeeting.type)
    if (data.location !== undefined) locationForm.handleUpdate(data.location)
    if (data.visibility) visibilityForm.handleUpdate(data.visibility)
  }

  // Auto peek candidates watcher
  useCandidatesMaxOverWatcher({
    maxCount: MAX_CANDIDATE_COUNT,
    getOverFlag: () => peekCandidates.tempCandidates.length > MAX_CANDIDATE_COUNT,
    clearOverFlag: () => {
      peekCandidates.tempCandidates = peekCandidates.tempCandidates.slice(0, MAX_CANDIDATE_COUNT)
    }
  })
  // Hand candidates watcher
  useCandidatesMaxOverWatcher({
    maxCount: MAX_CANDIDATE_COUNT,
    getOverFlag: () => EditScheduleModule.isOverMaxCandidate,
    clearOverFlag: () => EditScheduleModule.SET_OVER_MAX_CANDIDATE_COUNT(false)
  })

  return {
    updatePersonalScheduleForms: updateForms,
    updatePersonalScheduleFormData(overwrite?: OverwritingData) {
      updateForms({
        title: overwrite?.title ?? titleForm.title,
        duration: overwrite?.duration ?? durationForm.duration,
        description: overwrite?.description ?? descriptionForm.description,
        location: overwrite?.location ?? locationForm.location,
        visibility: overwrite?.visibility ?? visibilityForm.visibility
      })
      copyTemporaryCandidatesToCandidates(overwrite?.candidates ?? [], replaceAllCandidates)
      updateScheduleModel({ ...currentSchedule.value })
    },
    getPersonalScheduleFormData(): PersonalScheduleFormData {
      return reactive({
        clearDirty,
        shouldStayHere,
        pageDataState,
        pageDataControl: handlers,
        temporaryCandidatesInfo: {
          candidates: peekCandidates.tempCandidates
        },
        candidatesInfo: {
          hasAtLeastOneCandidate: peekCandidatesSectionSetup.hasAtLeastOneCandidate,
          candidates: peekCandidatesSectionSetup.candidates,
          addNewCandidate: peekCandidatesSectionSetup.addNewCandidate,
          removeCandidate: peekCandidatesSectionSetup.removeCandidate
        },
        basicInfo: {
          title: {
            value: titleForm.title,
            suggestions: titleSuggestions,
            handleChange: titleForm.handleUpdate
          },
          duration: {
            value: durationForm.duration,
            items: durationForm.items,
            handleChange: (newDuration: ScheduleDuration) => {
              const oldDuration = durationForm.duration
              durationForm.handleUpdate(newDuration)
              const underDurationCandidates = getUnderDurationCandidates(newDuration)
              if (oldDuration != newDuration && underDurationCandidates.length > 0) {
                openOverDurationCandidatesRemovingConfirmModal({
                  confirm: () => {
                    updateScheduleModel(currentSchedule.value)
                    openInfoBottomToast({
                      message: i18n.t('message.removedOverDurationCandidate').toString(),
                      duration: TOAST_DURATION
                    })
                  },
                  cancel: () => {
                    durationForm.handleUpdate(oldDuration)
                  }
                })
              } else {
                durationForm.handleUpdate(newDuration)
                updateScheduleModel(currentSchedule.value)
              }
            }
          },
          author: {
            value: authorForm.value,
            disabled: isEditMode,
            calendarCollections: filterByPrimaryCalendar(CalendarsModule.getAccountWithcalendars),
            handleChange: handleOrganizerUpdate
          },
          attendees: {
            values: attendeesForm.selectedEmails,
            availableAttendeeList,
            handleReplace: attendeesForm.handleReplace,
            handleCalendarAdd: attendeesForm.handleAdd,
            handleCalendarDelete: attendeesForm.handleDelete
          }
        },
        peekCandidatesSection: {
          isEditMode,
          dateSlots: peekCandidatesSectionSetup.dateSlots,
          durationToSplit: durationForm.duration,
          onAllDelete: peekCandidatesSectionSetup.clearCandidates,
          onSave: peekCandidatesSectionSetup.addNewCandidate,
          onDatetimeDelete: (data: DatetimeSlotDeletePayload) => {
            peekCandidatesSectionSetup.removeCandidate(data.id)
          },
          onDatetimeUpdate: (data: DatetimeSlotUpdatePayload) => {
            peekCandidatesSectionSetup.addNewCandidate(data, null)
          },
          onCalendarOpen: handleCalendarOpen
        },
        othersInfo: {
          description: {
            value: descriptionForm.description,
            handleChange: descriptionForm.handleUpdate
          },
          onlineMeeting: {
            value: onlineMeetingForm.onlineMeetingType,
            tools: availableOnlineMeetings,
            handleChange: onlineMeetingForm.handleUpdate
          },
          location: {
            value: locationForm.location,
            handleChange: locationForm.handleUpdate
          },
          visibility: {
            value: visibilityForm.visibility,
            items: visibilityForm.items,
            handleChange: visibilityForm.handleUpdate
          }
        },
        peekCandidatesForm: {
          isCandidateLoading: peekCandidates.isCandidateLoading,
          updateForms: peekCandidatesConditionForm.updateForms,
          allConditions: peekCandidatesConditionForm.allConditions,
          calendarsAutoCompleteForm: peekCandidatesConditionForm.calendarsAutoCompleteForm,
          weekdayForm: peekCandidatesConditionForm.weekdayForm,
          durationForm: peekCandidatesConditionForm.durationForm,
          startForm: peekCandidatesConditionForm.startForm,
          endForm: peekCandidatesConditionForm.endForm,
          timeBufferForm: peekCandidatesConditionForm.timeBufferForm,
          timezoneForm: peekCandidatesConditionForm.timezoneForm,
          countriesForm: peekCandidatesConditionForm.countriesForm,
          mixTemporariesAndOthers,
          fetchCandidates: async (peekCondition: FetchCandidatesConditionParameter): Promise<FullCalendarEvent[]> => {
            const condition =
              isEditMode && scheduleModel.id ? { ...peekCondition, scheduleId: scheduleModel.id } : peekCondition
            return await peekCandidates.fetchCandidates(condition)
          },
          applyAutoPeekCandidates: () => {
            const candidates = peekCandidates.tempCandidates.concat(
              peekCandidatesSectionSetup.candidates.filter(candidatesFilterForMixing)
            )
            copyTemporaryCandidatesToCandidates(candidates, replaceAllCandidates)
          }
        }
      })
    }
  }
}
