






import CalendarsModule from '@/store/modules/calendars'
import { SignalType } from '@/lib/analytics/LogEntry'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import { useAnalytics } from '@/composables/useAnalytics'
import { useModal } from '@/composables/useModal'

export default defineComponent({
  props: {
    onlyConnectTo: {
      type: String as PropType<'google' | 'microsoft'>
    }
  },
  setup(props) {
    const analytics = useAnalytics()
    const { onlyConnectTo } = toRefs(props)
    const { openAddCalendarModal } = useModal()
    async function addCalendar() {
      await analytics.send(SignalType.SYNC_ACCOUNT, {
        currentCount: CalendarsModule.getAccountWithcalendars.length
      })
      openAddCalendarModal({ onlyConnectTo: onlyConnectTo.value })
    }
    return {
      addCalendar
    }
  }
})
