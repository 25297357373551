var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":"max:500"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('FormTextareaItem',{attrs:{"icon":{ type: 'icon', src: 'text' },"title":{
      required: false,
      title: _vm.$t('availabilitySharing.form.candidateDescription.label'),
      tip: {
        type: 'visible',
        position: 'top',
        messages: [_vm.$t('availabilitySharing.form.candidateDescription.help')],
        width: 220
      }
    },"disabled":false,"rows":5,"placeholder":_vm.$t('availabilitySharing.form.candidateDescription.placeholder'),"errorState":_vm.makeErrorMessage(v)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }