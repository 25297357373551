














import { Component, Prop, Vue } from 'vue-property-decorator'
import Input from '../atoms/Input.vue'
import FormItem from '@/components/newForm/atoms/FormItem.vue'

@Component({
  components: {
    FormItem,
    Input
  }
})
export default class FormHeaderTitle extends Vue {
  @Prop() label: string
  @Prop() icon: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: null }) help: string
  @Prop({ default: true }) required

  get rules() {
    return this.required ? 'required' : null
  }
}
