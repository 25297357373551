



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { isSameDay } from 'date-fns'
import ConfirmWithEmailAndName from './molecules/ConfirmWithEmailAndName.vue'
import UserModule from '@/store/modules/user'
import oneDateRange from '@/filters/oneDateRange'
import dateRange from '@/filters/dateRange'
import AttendeeInput from './molecules/AttendeeInput.vue'

export type ConfirmData = {
  email: string
  name: string
  attendees: { email: string; name: string }[]
}

@Component({
  components: {
    ConfirmWithEmailAndName,
    AttendeeInput
  }
})
export default class ConfirmSchedule extends Vue {
  @Prop() candidateStartDate: Date
  @Prop() candidateEndDate: Date
  @Prop() isOrganizer: boolean
  @Prop() timeZone: string
  @Prop() cancelBtn: string

  confirmData: ConfirmData = {
    email: '',
    name: '',
    attendees: []
  }
  get formattedDate() {
    if (this.candidateStartDate && this.candidateEndDate) {
      if (isSameDay(this.candidateStartDate, this.candidateEndDate)) {
        return oneDateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
      } else {
        return dateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
      }
    }

    return null
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get subTitle() {
    if (this.isSignIn) {
      return this.$t('message.confirm.desc').toString()
    }
    return this.$t('message.confirm.notSignIn').toString()
  }
  get getTimeZone() {
    return this.timeZone
  }
  get emails() {
    const emails = this.confirmData.attendees.map((attendee) => attendee.email)
    if (this.confirmData.email) {
      emails.push(this.confirmData.email)
    }
    return emails
  }
  addAttendee(attendee: { email: string; name: string }) {
    this.confirmData.attendees = [...this.confirmData.attendees, attendee]
  }
  deleteAttendee(index: number) {
    this.confirmData.attendees = [
      ...this.confirmData.attendees.slice(0, index),
      ...this.confirmData.attendees.slice(index + 1, this.confirmData.attendees.length)
    ]
  }
  register() {
    this.$emit('register', {
      email: this.confirmData.email,
      name: this.confirmData.name,
      attendees: this.confirmData.attendees
    })
    this.$emit('close')
  }
}
