import { useToast } from '@/composables/useToast'
import { BModalComponent } from 'buefy/types/components'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import EventModule from '@/store/modules/event'
import { computed } from '@vue/composition-api'
import { useModal } from './useModal'
import i18n from '@/i18n'

export const useCalendarMixin = () => {
  const { openDiscardItemConfirmationModal, openDeleteItemModal, openSpecifiedBodyModal } = useModal()
  const { openPrimaryTopToast, openWarningTopToast } = useToast()

  const isScheduleEventListOpen = computed(() => AppScreenControlModule.isScheduleEventListOpen)

  async function confirmDeleteItem(data: { customTitle?: string; customMessage?: string }): Promise<boolean> {
    return new Promise((resolve) => {
      openDeleteItemModal({ ...data, resolve })
    })
  }

  return {
    confirmDiscardItem: async () => {
      return new Promise((resolve) => {
        openDiscardItemConfirmationModal({ resolve })
      })
    },
    isScheduleEventListOpen,
    toggleScheduleEventsList: (param?) => {
      return AppScreenControlModule.toggleScheduleEventsList(param)
    },
    confirmDeleteItem,
    // eslint-disable-next-line
    showModal: (BodyComponent: any, events, props: any): BModalComponent => {
      return openSpecifiedBodyModal({ BodyComponent, events, props })
    },
    deleteEventItem: async (payload: { accountId: string; calendarId: string; eventId: string }) => {
      const deleteResponse = await confirmDeleteItem({})
      const { accountId, calendarId, eventId } = payload
      if (!deleteResponse) return Promise.resolve(null)
      try {
        await EventModule.deleteEvent({
          accountId,
          calendarId,
          id: eventId
        })
        openPrimaryTopToast({ message: i18n.t('message.success.deleteEvent').toString() })
        return true
      } catch (e) {
        openWarningTopToast({ message: i18n.t('message.errorCommon').toString() })
        return null
      }
    }
  }
}
