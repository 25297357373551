




















import { defineComponent, computed, readonly } from '@vue/composition-api'
import TimezoneModule from '@/store/modules/timezones'
import { addDays } from '@/lib/utils/timezone'
import { getStartWeekDayForVueCalendar } from '@/lib/utils/calendars'
import ProfileModule from '@/store/modules/profile'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'

export default defineComponent({
  name: 'SpirDatePicker',
  props: {
    inputDate: {
      type: Date
    },
    minDate: {
      type: Date
    },
    inclusive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const language = computed(() => ProfileModule.getLanguage)
    const value = computed(() => {
      if (props.inclusive) {
        return addDays(props.inputDate, -1)
      }
      return props.inputDate
    })
    const formatedDate = computed(() => spirDateFormat(value.value, spirDateFormatTypes.yyyymmddweekday)) //'yyyy/MM/dd(EEE)'
    const timeZone = computed(() => TimezoneModule.userTimezoneKey)
    const firstDayOfWeek = computed(() => getStartWeekDayForVueCalendar())
    const getMinDate = () => {
      if (!props.minDate) {
        return
      }
      if (props.inclusive) {
        return addDays(props.minDate, -1)
      }
      return props.minDate
    }
    const updatedDate = (event) => {
      if (event.isDisabled) {
        return
      }
      const date = new Date(event.date)
      const targetDate = props.inclusive ? addDays(date, 1) : date
      emit('dateUpdated', targetDate.toISOString())
    }
    return {
      value,
      attrs: readonly([
        {
          key: 'today',
          highlight: {
            contentClass: 'today'
          },
          dates: new Date()
        }
      ]),
      language,
      formatedDate,
      timeZone,
      firstDayOfWeek,
      getMinDate,
      updatedDate
    }
  }
})
