











import { channelTalkService } from '@/lib/externalServices/channelTalk'
import { defineComponent, PropType } from '@vue/composition-api'
import Section from '../atoms/Section.vue'

export default defineComponent({
  name: 'ETC',
  components: {
    Section
  },
  props: {
    onSettingItemClick: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    return {
      handleSupportOpen: () => {
        channelTalkService.showMessenger()
        props.onSettingItemClick()
      }
    }
  }
})
