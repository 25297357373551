






/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import queryString from 'query-string'
import ConfirmModal from '@/components/modal/Confirm.vue'
import { LOCAL_STORAGE_KEY_FOR_CALLBACK_ZOOM } from '@/types'
@Component
export default class ZoomSignInButton extends Vue {
  signInToZoom() {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: this.$t('settings.integrations.signInToZoom'),
        body: this.$t('settings.integrations.messageForSignInToZoom')
      },
      events: {
        close: () => {
          modal.close()
        },

        confirm: () => {
          this.redirectToZoom()
        }
      }
    })
  }
  redirectToZoom() {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_FOR_CALLBACK_ZOOM, this.$route.name)
    const params = {
      client_id: process.env.VUE_APP_ZOOM_CLIENT_ID,
      redirect_uri: `${window.location.origin}/callback-from-zoom`,
      response_type: 'code'
    }
    const url = `https://zoom.us/oauth/authorize?${queryString.stringify(params)}`
    window.location.replace(url)
  }
}
