









/* eslint-disable @typescript-eslint/camelcase */
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import AppUpdateAvailableSnackbar from '@/components/functional/AppUpdateAvailableSnackbar.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import UserAuthModal from '@/components/modal/UserAuth.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { EventBus, EVENTS } from '@/lib/eventBus'
import AppModule from '@/store/modules/app'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule, { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import UserModule from '@/store/modules/user'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AllRouteNames } from './router'
import { channelTalkService } from '@/lib/externalServices/channelTalk'
import { frontSupportLanguages } from './types'
import SpirBetaLabel from '@/components/ui/spir/SpirBetaLabel.vue'

const INTERVAL_SEND_SIGNAL = 1000 * 60 * 5 // 5min

@Component({
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  },
  components: {
    LoadingSpinner,
    AppUpdateAvailableSnackbar,
    SpirBetaLabel
  }
})
export default class App extends Vue {
  init = false
  intervalIdForSendingSignal = null

  @Watch('isIdle')
  changedIdleStatus(newStatus: boolean) {
    // active
    if (!newStatus) {
      // send signal and start interval
      this.sendActiveSignalAndSetInterval()
    } else {
      // idle
      // stop interval
      this.stopSendSignalInterval()
    }
  }
  @Watch('currentLanguage')
  changedLanguage() {
    this.updateLanguage()
  }
  @Watch('isNarrow')
  changedWidth(newVal: boolean) {
    // widthをスマホサイズに減らし、現在のPathがRootの場合は、公開URLにリダイレクトする
    if (newVal && this.$route.name === 'Main') {
      this.$router.push({ name: AllRouteNames.AvailabilityList })
    }
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  get isPageLoading(): boolean {
    return AppModule.isPageLoading
  }
  get isIdle(): boolean {
    return this.$store.state.idleVue.isIdle
  }
  get currentLanguage() {
    return ProfileModule.getLanguage
  }
  get isEnglishUsed(): boolean {
    return this.currentLanguage === frontSupportLanguages.en
  }
  async created() {
    try {
      this.sendActiveSignalAndSetInterval()
      await this.updateTimezoneIfItIsNeeded()
      this.init = true
      window.showSpirAuthModal = () => {
        EventBus.emit(EVENTS.SHOW_AUTH_MODAL_FROM_OUTSIDE)
      }
      EventBus.on(EVENTS.SHOW_AUTH_MODAL_FROM_OUTSIDE, this.showAuthModal)
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: `Failed to fetch Spir schedules: ${err.message}`
        })
      }
    }
    if (UserModule.isRedirectedAfterSignIn === 'error') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
    }
    if (UserModule.isRedirectedAfterSignIn === 'noUser') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.error.auth.noUser').toString()
      })
    }
    if (UserModule.isRedirectedAfterSignIn === 'userExists') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.error.accountExistsInAnotherCredential').toString(),
        duration: 5000
      })
    }
    AppModule.setWindowWidth()
    window.addEventListener('resize', AppModule.setWindowWidth)
    ProfileModule.setLanguage()
    this.updateLanguage()
  }

  beforeDestroy() {
    window.removeEventListener('resize', AppModule.setWindowWidth)
    this.stopSendSignalInterval()
    EventBus.off(EVENTS.SHOW_AUTH_MODAL_FROM_OUTSIDE, this.showAuthModal)
  }

  async showAuthModal() {
    this.$router.push({ query: { from: 'karte' } })
    await this.$analytics.send(SignalType.SHOW_AUTH_MODAL_FROM_KARTE)
    const modal = this.$buefy.modal.open({
      parent: this,
      component: UserAuthModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        closeModal: () => {
          modal.close()
        }
      }
    })
  }
  sendActiveSignalAndSetInterval() {
    if (this.intervalIdForSendingSignal) {
      return
    }
    this.$analytics.send(SignalType.ACTIVE)
    this.intervalIdForSendingSignal = setInterval(() => {
      this.$analytics.send(SignalType.ACTIVE)
    }, INTERVAL_SEND_SIGNAL)
  }
  stopSendSignalInterval() {
    if (this.intervalIdForSendingSignal) {
      clearInterval(this.intervalIdForSendingSignal)
      this.intervalIdForSendingSignal = null
    }
  }
  updateLanguage() {
    this.$i18n.locale = this.currentLanguage
    if (this.currentLanguage === 'en') {
      channelTalkService.hideChannelButton()
    } else {
      channelTalkService.showChannelButton()
    }
  }
  async updateTimezoneIfItIsNeeded() {
    const needToChangeTimezone = await TimezoneModule.needToChangeTimezone()
    if (needToChangeTimezone) {
      return new Promise((resolve) => {
        const displayTimezone = (timezoneInfo: TimezoneDetailWithTimeLabel) => {
          return `${timezoneInfo.label}(${timezoneInfo.abbreviation})`
        }
        const localTimezone = TimezoneModule.localTimezoneInfo
        const userTimezone = TimezoneModule.userTimezoneInfo
        this.$buefy.modal.open({
          parent: this,
          component: ConfirmModal,
          hasModalCard: true,
          canCancel: true,
          props: {
            header: this.$t('timezone.updateTimezone.header').toString(),
            body: this.$t('timezone.updateTimezone.body', {
              userTimezone: displayTimezone(userTimezone),
              localTimezone: displayTimezone(localTimezone)
            }).toString(),
            confirmBtn: this.$t('timezone.updateTimezone.confirmButton').toString(),
            cancelBtn: this.$t('timezone.updateTimezone.cancelButton').toString()
          },
          events: {
            confirm: async () => {
              try {
                await TimezoneModule.updatePrimaryTimezone(localTimezone.key)
                this.$buefy.toast.open({
                  type: 'is-primary',
                  position: 'is-top',
                  message: this.$t('message.success.updated', { type: this.$t('timezone.label') }).toString()
                })
              } catch (_) {
                this.$buefy.toast.open({
                  type: 'is-danger',
                  position: 'is-bottom',
                  message: this.$t('message.errorCommon').toString()
                })
              } finally {
                resolve(true)
              }
            },
            cancel: () => {
              TimezoneModule.saveCurrentTimezoneToLocalStorage()
              resolve(true)
            }
          }
        })
      })
    } else {
      return Promise.resolve()
    }
  }
}
