

























































import SettingLayout from '@/components/layout/Setting.vue'
import AccountLinkSection from '@/components/settings/AccountLinkSection.vue'
import ArrangementSection from '@/components/settings/ArrangementSection.vue'
import CalendarSettingSection from '@/components/settings/CalendarSettingSection.vue'
import DevelopmentSettingsSection from '@/components/settings/DevelopmentSettingsSection.vue'
import EmailChangeSection from '@/components/settings/EmailChangeSection.vue'
import IntegrationsSection from '@/components/settings/IntegrationsSection.vue'
import SubscriptionSection from '@/components/settings/SubscriptionSection.vue'
import LocalizationAndTimezoneSection from '@/components/ui/domain/section/settings/LocalizationAndTimeZoneSection.vue'
import CalendarControleModule from '@/store/modules/calendarControl'
import ProfileModule from '@/store/modules/profile'
import SubscriptionModule from '@/store/modules/subscription'
import TimezoneModule from '@/store/modules/timezones'
import { TimeZones } from '@spirinc/contracts'
import { computed, defineComponent } from '@vue/composition-api'
import { useLanguageSetting } from '@/composables/useLanguageSetting'

export default defineComponent({
  name: 'Settings',
  components: {
    SettingLayout,
    IntegrationsSection,
    SubscriptionSection,
    AccountLinkSection,
    EmailChangeSection,
    CalendarSettingSection,
    ArrangementSection,
    DevelopmentSettingsSection,
    LocalizationAndTimezoneSection
  },
  setup() {
    const languageSetting = useLanguageSetting()

    const userLanguage = computed(() => {
      return languageSetting.currentLanguage.value
    })
    const myProfile = computed(() => {
      return ProfileModule.myProfile
    })
    const subscription = computed(() => {
      return SubscriptionModule.subscription
    })
    const startWeek = computed(() => {
      return CalendarControleModule.startWeek
    })
    const isDevelopmentMode = computed(() => {
      return process.env.VUE_APP_MODE === 'development' || process.env.VUE_APP_MODE === 'local'
    })

    async function handleTimeZonesUpdate(timeZones: TimeZones) {
      await TimezoneModule.updateTimezone(timeZones)
    }

    SubscriptionModule.refreshSubscription()
    return {
      isDevelopmentMode,
      userLanguage,
      myProfile,
      subscription,
      startWeek,
      handleLanguageUpdate: languageSetting.handleChangeLanguage,
      handleTimeZonesUpdate
    }
  }
})
