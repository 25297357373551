



























import Divider from '@/components/atoms/Divider.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import AddCalendarBox from '@/components/auth/addCalendar/AddCalendarFormBox.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SpirModalFrame from '@/components/ui/spir/SpirModal/SpirModalFrame.vue'
import { useAddGoogleCalenarWithRedirect } from '@/composables/auth/useAddGoogleCalenarWithRedirect'
import { useAddCalendar } from '@/composables/auth/useAddMSCalenarWithRedirect'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import firebase from 'firebase/app'
import 'firebase/auth'

export default defineComponent({
  name: 'AddCalendar',
  components: {
    Divider,
    ClosingPageheadTitleBox,
    SpirModalFrame,
    ListItemSpacer,
    AddCalendarBox,
    LoadingSpinner
  },
  props: {
    closeModal: {
      type: Function as PropType<() => void>
    },
    isShowCloseButton: {
      type: Boolean,
      default: true
    },
    isShowSignOut: {
      type: Boolean,
      default: false
    },
    onlyConnectTo: {
      type: String as PropType<'google' | 'microsoft'>
    }
  },
  setup(props) {
    const { sendSignal } = useSendSignal()
    const isLoading = ref(false)
    const { getMicrosoftAuthCode } = useAddCalendar()
    const { getGoogleAuthCode } = useAddGoogleCalenarWithRedirect()
    const handleAddGoogleCalendar = async () => {
      await sendSignal(SignalType.CLICK_ADD_CALENDAR, { type: 'google' })
      return getGoogleAuthCode()
    }
    const handleAddMicrosoftCalendar = async () => {
      await sendSignal(SignalType.CLICK_ADD_CALENDAR, { type: 'microsoft' })
      return getMicrosoftAuthCode()
    }
    const signOut = () => {
      firebase.auth().signOut()
      props.closeModal()
    }
    return {
      isLoading,
      signOut,
      handleAddMicrosoftCalendar,
      handleAddGoogleCalendar
    }
  }
})
