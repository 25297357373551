




import SignInWithPasswordPage from '@/components/page/SignInWithPasswordPage.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    SignInWithPasswordPage
  }
})
