import { AvailableAutocompleteListType } from './types'

export const useAutocompleteFromCalendarsAndMembers = () => {
  return {
    getEmailFromAvailableAutocompleteListType: (item: AvailableAutocompleteListType) => {
      switch (item.type) {
        case 'calendar':
          return item.listItem.invitationAddress
        case 'email':
          return item.email
        case 'member':
          return item.listItem.email
      }
    }
  }
}
