















import CreateButtons from '@/components/CreateMenu/CreateMenu.vue'
import FloatingMenuLayout from '@/components/layout/floatingMenu.vue'
import { FloatingMenuHandlers } from '@/components/layout/types'
import { defaultScheduleMenus, MenuInfo } from '@/lib/menus'
import { defineComponent, onUnmounted, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'MenuSelectSection',
  components: {
    CreateButtons,
    FloatingMenuLayout
  },
  props: {
    onCandidateReset: {
      type: Function as PropType<() => void>,
      required: true
    },
    onMenuClick: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const floatingMenuRef = ref<FloatingMenuHandlers | null>(null)
    const scheduleMenusWithOnClick: MenuInfo[] = defaultScheduleMenus.map((m) => {
      return {
        ...m,
        onMenuClick: m.key === 'arrangements' ? props.onMenuClick : undefined
      }
    })
    const allScheduleMenus: MenuInfo[] = scheduleMenusWithOnClick.map((m) => {
      return {
        ...m,
        hideOnMobile: false,
        hideOnCalendar: false
      }
    })
    function removeFromPlus() {
      document.body.removeAttribute('data-from-plus')
    }
    onUnmounted(() => {
      removeFromPlus()
    })
    return {
      floatingMenuRef,
      scheduleMenusWithOnClick,
      allScheduleMenus,
      handlePlusButtonToggle: (status) => {
        if (status) {
          // CSSでbuefyのDropdownのポジションを変更するためのPlusButtonを押したとき専用のセレクターToggle
          document.body.setAttribute('data-from-plus', 'set')
        } else {
          removeFromPlus()
        }
      },
      hideMenuWithNoEmit: () => {
        floatingMenuRef.value && floatingMenuRef.value.hideMenuWithNoEmit()
      },
      hideMenu: () => {
        floatingMenuRef.value && floatingMenuRef.value.hideMenu()
      },
      showMenu: (jsEvent: MouseEvent) => {
        floatingMenuRef.value && floatingMenuRef.value.showMenu(jsEvent)
      }
    }
  }
})
