




import { Component, Vue } from 'vue-property-decorator'
import TeamGuard from '@/router/team-guard'
import { NavigationGuardNext, Location } from 'vue-router'

Component.registerHooks(['beforeRouteUpdate'])

@Component
export default class TeamIdRoot extends Vue {
  async beforeRouteUpdate(to: Location, from: Location, next: NavigationGuardNext) {
    if (to.params?.id === from.params?.id) {
      return next()
    }
    TeamGuard(to, from, next)
  }
}
