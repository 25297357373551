






import { Component, Prop, Vue } from 'vue-property-decorator'
import { FullCalendarEvent } from '@/types'
import { EventBus, EVENTS } from '@/lib/eventBus'

@Component
export default class AllDayEvent extends Vue {
  @Prop() event: FullCalendarEvent

  get eventStyle() {
    return {
      backgroundColor: this.event.backgroundColor,
      color: this.event.textColor || '#ffffff'
    }
  }
  get amIActive() {
    return this.event.extendedProps && this.event.extendedProps.source === 'editingEvent'
  }
  eventClicked(e) {
    if (!this.amIActive) {
      EventBus.emit(EVENTS.CLICKED_EVENT, { event: this.event })
    }
    e.stopPropagation()
    return false
  }
}
