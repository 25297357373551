

























import PeekCandidatesFormSection from '@/components/ui/domain/section/arrangement/PeekCandidatesFormSection.vue'
import PeekCandidatesFormForSaveSection from '@/components/ui/domain/section/arrangement/PeekCandidatesFormForSave.vue'
import { defineComponent, PropType } from '@vue/composition-api'
import Divider from '@/components/atoms/Divider.vue'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SpirCheckbox from '@/components/ui/domain/item/FormCheckbox.vue'
import { AllPeekCandidatesConditionForms } from '@/components/ui/domain/sections/arrangement/peekCandidatesSectionComposables/_usePeekCandidatesConditionForm'

export default defineComponent({
  props: {
    peekCandidatesForm: {
      type: Object as PropType<AllPeekCandidatesConditionForms>,
      required: true
    },
    isConditionSaveOnConfirm: {
      type: Boolean,
      required: true
    },
    onIsConditionSaveOnConfirmToggle: {
      type: Function,
      required: true
    }
  },
  components: {
    PeekCandidatesFormSection,
    PeekCandidatesFormForSaveSection,
    Divider,
    ListItemSpacer,
    SpirCheckbox
  }
})
