















































import { cloneDeep } from 'lodash'
import { computed, defineComponent } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import SidePanelFormSectionTitle from '@/components/ui/SidePanelFormSectionTitle.vue'
import AttendingTypeRadioButton from '@/components/ui/domain/radioButton/AttendingTypeRadioButton.vue'
import FormOrganizerMemberDropdownItem from '@/components/ui/domain/item/FormOrganizerMemberDropdownItem.vue'
import FormAttendingMemberItem from '@/components/ui/domain/item/FormAttendingMemberItem.vue'
import FormAttendingCandidateMemberItem from '@/components/ui/domain/item/FormAttendingCandidateMemberItem.vue'
import FormCandidateTargetCalendarsSettingsCollapseButtonItem from '@/components/ui/domain/item/FormCandidateTargetCalendarsSettingsCollapseButtonItem.vue'
import FormTargetCalendarsSelectItem from '@/components/ui/domain/item/FormTargetCalendarsSelectItem/index.vue'
import FormNotificationSettingsCollapseButtonItem from '@/components/ui/domain/item/FormNotificationSettingsCollapseButtonItem.vue'
import FormConfirmationEmailTargetsItem from '@/components/ui/domain/item/FormConfirmationEmailTargetsItem/index.vue'
import { useAvailabilitySharingForm } from '@/composables/useAvailabilitySharingForm'
import TeamCalendarModule from '@/store/modules/teamCalendar'
import { AutocompleteMember } from '../../autocomplete/ConditionIncludedCalendarsAndMembersAutocomplete/types'

export default defineComponent({
  name: 'OrganaizerAndAttendeeSectionForPersonal',
  components: {
    ListItemSpacer,
    SidePanelFormSectionTitle,
    AttendingTypeRadioButton,
    FormOrganizerMemberDropdownItem,
    FormAttendingMemberItem,
    FormAttendingCandidateMemberItem,
    FormCandidateTargetCalendarsSettingsCollapseButtonItem,
    FormTargetCalendarsSelectItem,
    FormNotificationSettingsCollapseButtonItem,
    FormConfirmationEmailTargetsItem
  },
  setup() {
    const { editingObjectForTeam, updateEditingObject, updateCondition } = useAvailabilitySharingForm()

    const attendingType = computed(() => editingObjectForTeam.value.attendingType)
    function handleAttendingTypeClick(newValue) {
      const clone = cloneDeep(editingObjectForTeam.value)
      clone.attendingType = newValue
      clone.organizerMemberId = ''
      clone.memberIds = []
      updateCondition(clone)
    }

    const organizerMemberId = computed(() => editingObjectForTeam.value.organizerMemberId)
    const memberCalendars = computed(() => {
      return editingObjectForTeam.value.allActiveTeamMembers.map((member) => ({
        ...member,
        backgroundColor:
          TeamCalendarModule.memberCalendarByTeamAndMemberId(editingObjectForTeam.value.teamId, member.id)
            ?.backgroundColor || 'gray'
      }))
    })
    function handleOrganizerMemberChange(newOrganizerMemberId) {
      const clone = cloneDeep(editingObjectForTeam.value)
      const findIndex = editingObjectForTeam.value.memberIds.indexOf(editingObjectForTeam.value.organizerMemberId)
      if (findIndex >= 0) {
        clone.memberIds.splice(findIndex, 1)
      }
      clone.organizerMemberId = newOrganizerMemberId
      if (!editingObjectForTeam.value.memberIds.some((id) => id === newOrganizerMemberId)) {
        clone.memberIds.push(newOrganizerMemberId)
      }
      updateCondition(clone)
    }

    const attendingMemberTypes = computed(() => {
      return editingObjectForTeam.value.memberIds.map((memberId) => {
        return { type: 'member', teamId: editingObjectForTeam.value.teamId, memberId: memberId }
      })
    })
    const availableMemberList = computed(() => {
      return editingObjectForTeam.value.allActiveTeamMembers
        .filter((m) => m.id !== editingObjectForTeam.value.organizerMemberId)
        .map((member) => ({
          teamId: editingObjectForTeam.value.teamId,
          member: {
            ...member,
            backgroundColor:
              TeamCalendarModule.memberCalendarByTeamAndMemberId(editingObjectForTeam.value.teamId, member.id)
                ?.backgroundColor || 'gray'
          }
        }))
    })
    function handleAttendingMemberAdd(selectedMember: AutocompleteMember) {
      if (!editingObjectForTeam.value.memberIds.some((id) => id === selectedMember.listItem.id)) {
        const clone = cloneDeep(editingObjectForTeam.value)
        clone.memberIds.push(selectedMember.listItem.id)
        updateCondition(clone)
      }
    }
    function handleAttendingMemberDelete(deletedMember: AutocompleteMember) {
      const index = editingObjectForTeam.value.memberIds.findIndex((id) => id === deletedMember.listItem.id)
      if (index >= 0) {
        const clone = cloneDeep(editingObjectForTeam.value)
        clone.memberIds.splice(index, 1)
        updateCondition(clone)
      }
    }

    const confirmationEmailTargets = computed(() => editingObjectForTeam.value.notifications.map((n) => n.email))
    function handleNewConfirmationEmailTargetAdd(newEmail: string) {
      const clone = cloneDeep(editingObjectForTeam.value)
      clone.notifications.push({ email: newEmail })
      updateEditingObject(clone)
    }
    function handleConfirmationEmailTargetDelete(email: string) {
      const index = editingObjectForTeam.value.notifications.findIndex((n) => n.email === email)
      if (index >= 0) {
        const clone = cloneDeep(editingObjectForTeam.value)
        clone.notifications.splice(index, 1)
        updateEditingObject(clone)
      }
    }

    return {
      attendingType,
      handleAttendingTypeClick,
      attendingMemberTypes,
      organizerMemberId,
      memberCalendars,
      handleOrganizerMemberChange,
      availableMemberList,
      handleAttendingMemberAdd,
      handleAttendingMemberDelete,
      confirmationEmailTargets,
      handleNewConfirmationEmailTargetAdd,
      handleConfirmationEmailTargetDelete
    }
  }
})
