import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/remote-config'

const init = async () => {
  firebase.initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASERMENT_ID,
    appId: process.env.VUE_APP_APP_ID
  })
  // app.functions().useFunctionsEmulator('http://localhost:5000')
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

  const remoteConfig = firebase.remoteConfig()
  if (process.env.VUE_APP_MODE !== 'staging' && process.env.VUE_APP_MODE !== 'production') {
    remoteConfig.settings.minimumFetchIntervalMillis = 0
    remoteConfig.setLogLevel('debug')
  }
  await new Promise((resolve) => {
    remoteConfig
      .fetchAndActivate()
      .then(() => resolve())
      .catch(() => {
        // NOTE: Strategy 3 https://firebase.google.com/docs/remote-config/loading?authuser=0
        // 最新情報を取得することに失敗した場合は、前回取得結果のactivateは待つが値の更新は待たない
        remoteConfig.activate().then(() => {
          remoteConfig.fetch()
          resolve()
        })
      })
  })
}
export default {
  init
}
