














import { defineComponent } from '@vue/composition-api'
import SignUpButtons from '@/components/auth/SignUpButtons.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import { useAuthSignUp } from '@/composables/auth/useAuthSignUpWithRedirect'
import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'

export default defineComponent({
  components: {
    AuthFormSpacer,
    SignUpButtons
  },
  setup(_, { emit }) {
    const { signUpWithGoogle, signUpWithMicrosoft } = useAuthSignUp()
    const { sendSignal } = useSendSignal()
    const handleSignUpToGoogle = async () => {
      await sendSignal(SignalType.CLICK_SIGNUP_TEAM_INVITED)
      return signUpWithGoogle()
    }
    const handleSignUpToMicrosoft = async () => {
      await sendSignal(SignalType.CLICK_SIGNUP_TEAM_INVITED)
      return signUpWithMicrosoft()
    }
    const goToSignIn = () => {
      emit('goToSignIn')
    }
    return {
      goToSignIn,
      handleSignUpToGoogle,
      handleSignUpToMicrosoft
    }
  }
})
