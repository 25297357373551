
















import SettingsSection from '@/components/organisms/settings/Section.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'
import CalendarSelector from '@/components/molecules/Select.vue'
import { WeekDay } from '@/models/data/userInfo'
import ProfileModule from '@/store/modules/profile'

@Component({
  components: {
    SettingsSection,
    CalendarSelector
  }
})
export default class CalendarSettingSection extends Vue {
  @Prop() startWeek: WeekDay

  availableOptions = [WeekDay.saturday, WeekDay.sunday, WeekDay.monday]
  get weekOptions() {
    return this.availableOptions.map((weekdayInt) => {
      return {
        label: this.$t(`settings.calendar.weekSelector.${WeekDay[weekdayInt]}`),
        value: weekdayInt
      }
    })
  }

  get isChanged() {
    return this.startWeek !== this.startWeekDay
  }

  created() {
    this.startWeekDay = this.startWeek
  }

  startWeekDay = null
  updating = false

  async saveStartWeekDay() {
    this.updating = true
    try {
      const startWeekDay = WeekDay[this.startWeekDay]
      await ProfileModule.updateStartWeek(startWeekDay)
      this.$buefy.toast.open({
        message: this.$t('message.success.saved').toString(),
        type: 'is-success'
      })
    } catch (e) {
      this.$buefy.toast.open({
        message: this.$t('message.errorCommon').toString(),
        type: 'is-danger'
      })
    } finally {
      this.updating = false
    }
  }
}
