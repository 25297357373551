import { useCalendarMixin } from '@/composables/useCalendarMixin'
import { ComputedRef, ref, watch } from '@vue/composition-api'

export const useDirtyCheckerInitializer = () => {
  const { confirmDiscardItem } = useCalendarMixin()
  return {
    initialize: (data: {
      isEditMode: boolean
      current: ComputedRef
      update: () => Promise<void>
      create: () => Promise<void>
      goToNext: (shouldStayHere: () => Promise<boolean>) => Promise<void>
      cancelEditing: (shouldStayHere: () => Promise<boolean>) => Promise<void>
    }) => {
      const isDirty = ref(false)
      function clearDirty() {
        isDirty.value = false
      }
      watch(data.current, () => {
        if (!isDirty.value) {
          isDirty.value = true
        }
      })
      async function shouldStayHere(): Promise<boolean> {
        if (isDirty.value) {
          const ok = await confirmDiscardItem()
          if (!ok) return true
          else {
            clearDirty()
            return false
          }
        }
        return false
      }
      const confirm = data.isEditMode ? data.update : data.create
      function selectPageDataControl() {
        return {
          confirm: async () => {
            await confirm()
            clearDirty()
            await data.goToNext(shouldStayHere)
          },
          cancel: async () => {
            await data.cancelEditing(shouldStayHere)
          }
        }
      }
      return {
        clearDirty,
        shouldStayHere,
        selectPageDataControl
      }
    }
  }
}
export const useDirtyChecker = (data: {
  isEditMode: boolean
  current: ComputedRef
  update: () => Promise<void>
  create: () => Promise<void>
  goToNext: (shouldStayHere: () => Promise<boolean>) => Promise<void>
  cancelEditing: (shouldStayHere: () => Promise<boolean>) => Promise<void>
}) => {
  const { initialize } = useDirtyCheckerInitializer()
  return initialize(data)
}
