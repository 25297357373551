

















import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import AvatarOnTabHeader from './AvatarOnTabHeader.vue'
import { UserInfoModel } from '@/models/data/userInfo'
import { JoiningTeam } from '@spirinc/contracts'

@Component({
  components: {
    AvatarOnTabHeader
  }
})
export default class TeamSelectorTab extends Vue {
  @Prop() myProfile: UserInfoModel
  @Prop() teams: JoiningTeam[]
  @PropSync('currentTab') currentTabValue: string
}
