import { useCalendarMixin } from '@/composables/useCalendarMixin'

type UseOptionalCalendarOpen = {
  openCalendar?: () => void
}
export const useOptionalCalendarOpenInitializer = () => {
  const { toggleScheduleEventsList } = useCalendarMixin()
  return {
    initialize: ({
      openCalendar
    }: UseOptionalCalendarOpen): {
      handleCalendarOpen
    } => {
      function handleCalendarOpen() {
        if (openCalendar) {
          openCalendar()
        } else {
          toggleScheduleEventsList(false)
        }
      }
      return { handleCalendarOpen }
    }
  }
}
export const useOptionalCalendarOpen = ({
  openCalendar
}: UseOptionalCalendarOpen): {
  handleCalendarOpen
} => {
  const { initialize } = useOptionalCalendarOpenInitializer()
  return initialize({ openCalendar })
}
