




























import { Component, Watch, Vue } from 'vue-property-decorator'
import ProfileLayout from '@/components/layout/Profile.vue'
import ProfileHeader from '@/components/profile/ProfileHeader.vue'
import ProfileMobileNav from '@/components/profile/ProfileMobileNav.vue'
import ProfileBasicEditor from '@/components/profile/ProfileBasicEditor.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import ProfileModule from '@/store/modules/profile'
import FooterPanel from '@/components/layout/FooterPanel.vue'
import { SignalType } from '@/lib/analytics/LogEntry'
import { UserInfoModel } from '../../models/data/userInfo'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    ProfileLayout,
    ProfileHeader,
    ProfileMobileNav,
    ProfileBasicEditor,
    FooterPanel
  }
})
export default class ProfileEdit extends Vue {
  localProfile: UserInfoModel = null

  get isLoading() {
    return ProfileModule.isLoading
  }
  get canUpdate() {
    const { fullName } = this.localProfile
    return !!fullName
  }
  get profile() {
    return ProfileModule.myProfile
  }

  async fetchMyProfile() {
    return ProfileModule.fetchMyProfile()
  }
  async updateBasicProfile(payload) {
    return ProfileModule.updateBasicProfile(payload)
  }

  mounted() {
    this.fetchMyProfile()
  }

  @Watch('profile')
  onProfileFetched(newVal: UserInfoModel) {
    this.localProfile = cloneDeep(newVal)
  }

  async updateProfile() {
    if (this.isLoading) {
      return
    }
    await this.updateBasicProfile(this.localProfile)
    await this.$analytics.send(SignalType.EDIT_EVENT)
    this.$buefy.toast.open({
      type: 'is-primary',
      position: 'is-top',
      message: this.$t('message.success.saved').toString()
    })
    this.$router.push('/profile')
  }

  close() {
    let hasUnsavedChange = false
    for (const prop in this.localProfile) {
      if (this.localProfile[prop] !== this.profile[prop]) {
        hasUnsavedChange = true
        break
      }
    }
    if (hasUnsavedChange) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        canCancel: false,
        props: {
          header: this.$t('profile.unsavedCancel.header'),
          body: this.$t('profile.unsavedCancel.body')
        },
        events: {
          close: () => {
            modal.close()
          },

          confirm: () => {
            this.$router.push('/profile')
          }
        }
      })
    } else {
      this.$router.push('/profile')
      // this.$router.back()
    }
  }
}
