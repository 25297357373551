



















































import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api'
import Avatar from 'vue-avatar'
import { TeamMemberCalendarSetting } from '@/components/teamSettings/slides/TeamInvitedSettingSlide.vue'
import { PersonalCalendar } from '@spirinc/contracts'
import Divider from '@/components/atoms/Divider.vue'
import CalendarShareVisibilitySelect from '@/components/teamSettings/selects/CalendarShareVisibilitySelect.vue'
import Section from './atoms/Section.vue'

export default defineComponent({
  props: {
    primaryAccountAndCalendarId: {
      type: Object as PropType<{ accountId: string; calendarId: string }>,
      required: true
    },
    writableCalendarGroups: {
      type: Array as PropType<PersonalCalendar[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    selectedCalendars: {
      type: Array as PropType<TeamMemberCalendarSetting[]>,
      required: true
    },
    onMyCalendarUpdate: {
      type: Function as PropType<(pattern: any) => void>,
      required: true
    }
  },
  components: {
    Section,
    Avatar,
    Divider,
    CalendarShareVisibilitySelect
  },
  setup(props) {
    const { primaryAccountAndCalendarId, selectedCalendars, onMyCalendarUpdate, writableCalendarGroups } = toRefs(props)

    const isPrimary = (accountId: string, calendarId: string) => {
      return (
        accountId === primaryAccountAndCalendarId.value.accountId &&
        calendarId === primaryAccountAndCalendarId.value.calendarId
      )
    }
    const isActiveCalendar = (accountId: string, calendarId: string) => {
      return selectedCalendars.value.find((sc) => sc.accountId === accountId && sc.calendarId === calendarId)
    }
    const shareVisibility = (accountId: string, calendarId: string) => {
      const isActive = isActiveCalendar(accountId, calendarId)
      if (!isActive) {
        return false
      }
      return isActive.visibility
    }
    const teamCalendarGroups = computed(() =>
      writableCalendarGroups.value.map((group) => {
        return {
          ...group,
          calendars: group.calendars.map((calendar) => {
            return {
              ...calendar,
              isPrimary: isPrimary(group.accountId, calendar.id),
              isActiveCalendar: isActiveCalendar(group.accountId, calendar.id),
              visibilityForShare: shareVisibility(group.accountId, calendar.id),
              disabled: props.isLoading || group.isAccessTokenInvalid
            }
          })
        }
      })
    )
    const handleMyCalendarUpdate = (accountId, calendarId, value) => {
      onMyCalendarUpdate.value({ accountId: accountId, calendarId: calendarId, visibility: value })
    }
    return {
      teamCalendarGroups,
      handleMyCalendarUpdate
    }
  }
})
