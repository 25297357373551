








import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdownSection',
  props: {
    title: {
      type: String
    }
  }
})
