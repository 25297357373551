







































import { defineComponent, PropType, toRefs, computed } from '@vue/composition-api'
import ListItemSpacer from '@/components/ui/ListItemSpacer.vue'
import FormWeekdayConditionItem from '@/components/ui/domain/item/FormWeekdayConditionItem/FormWeekdayConditionItem.vue'
import FormOffsetFromStartSelectBoxItem from '@/components/ui/domain/item/FormOffsetFromStartSelectBoxItem.vue'
import FormStartDateSelectBoxItem from '@/components/ui/domain/item/FormStartDateSelectBoxItem.vue'
import FormBufferTimeSelectBoxItem from '@/components/ui/domain/item/FormBufferTimeSelectBoxItem.vue'
import FormTimezoneSelectItem from '@/components/ui/domain/item/FormTimezoneSelectItem.vue'
import FormHolidaysSelectItem from '@/components/ui/domain/item/FormHolidaysSelectItem/index.vue'
import {
  FormStartItemSetup,
  FormEndItemSetup,
  FormTimeBufferItemSetup,
  FormTimezoneItemSetup,
  FormCountryHolidaysItemSetup
} from '@/components/ui/domain/sections/arrangement/peekCandidatesSectionComposables/_usePeekCandidatesConditionForm'
import { FormWeekdayConditionItemSetup } from '@/components/ui/domain/sections/arrangement/peekCandidatesSectionComposables/_useFormWeekdayConditionItem'

export default defineComponent({
  name: 'PeekCandidatesFormSection',
  props: {
    weekdayConditionInfo: {
      type: Object as PropType<FormWeekdayConditionItemSetup>,
      required: true
    },
    startInfo: {
      type: Object as PropType<FormStartItemSetup>,
      required: true
    },
    endInfo: {
      type: Object as PropType<FormEndItemSetup>,
      required: true
    },
    timeBufferInfo: {
      type: Object as PropType<FormTimeBufferItemSetup>,
      required: true
    },
    timezoneInfo: {
      type: Object as PropType<FormTimezoneItemSetup>,
      required: true
    },
    holidaysInfo: {
      type: Object as PropType<FormCountryHolidaysItemSetup>,
      required: true
    }
  },
  components: {
    ListItemSpacer,
    FormWeekdayConditionItem,
    FormStartDateSelectBoxItem,
    FormOffsetFromStartSelectBoxItem,
    FormBufferTimeSelectBoxItem,
    FormTimezoneSelectItem,
    FormHolidaysSelectItem
  },
  setup(props) {
    const { holidaysInfo } = toRefs(props)
    return {
      selectedHolidayCodes: computed(() => holidaysInfo.value.value.map((hc) => hc.code))
    }
  }
})
