























import { Component, Prop, Vue } from 'vue-property-decorator'
import ChevronButton from '@/components/atoms/ChevronButton.vue'

@Component({
  components: {
    ChevronButton
  }
})
export default class FormDropdownWithList extends Vue {
  @Prop({ default: false }) mobileModal: boolean
  @Prop({ default: true }) expanded: boolean
  @Prop({ default: 'is-bottom-right' }) position: string
  @Prop() disabled: boolean
  @Prop() value: string

  handleChange(changedValue) {
    this.$emit('changed', changedValue)
  }
}
