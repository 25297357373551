















import PreviewButton from '@/components/molecules/PreviewButton.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'MobileCalendarFooter',
  components: {
    PreviewButton
  },
  props: {
    canPreview: {
      type: Boolean,
      default: false
    },
    onPreview: {
      type: Function as PropType<() => void>,
      required: true
    },
    onFormCancel: {
      type: Function as PropType<() => void>,
      required: true
    },
    onCalendarClose: {
      type: Function as PropType<() => void>,
      required: true
    }
  }
})
