














import { Component, Prop, Vue } from 'vue-property-decorator'
import AppScreenControlModule from '@/store/modules/appScreenControl'
import Button from '@/components/ui/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class ConfirmPageHeader extends Vue {
  @Prop() candidates
  @Prop() title

  get headerTitle() {
    return this.title || this.$t('message.pleaseSelectCandidates')
  }
  toggleScheduleEventsList(param) {
    return AppScreenControlModule.toggleScheduleEventsList(param)
  }
}
