























/**
 * 今後Formがカスタムできるようになったらこのコンポネントは使われなくなる。
 * 臨時的に使われる
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ConfirmationFormProperty } from '@/models/data/availability'
import FormItem from '@/components/newForm/atoms/FormItem.vue'

type Active = 'active' | 'inactive'
type Required = 'required' | 'optional'

@Component({
  components: {
    FormItem
  }
})
export default class ConfirmationFormTextArea extends Vue {
  @PropSync('property', { type: Object }) propertySynced!: ConfirmationFormProperty
  @Prop() isRequired: boolean

  activeOptions: { key: Active; label: string }[] = []
  requiredOptions: { key: Required; label: string }[] = []

  mounted() {
    this.activeOptions = [
      {
        key: 'active',
        label: this.$t('availabilitySharing.form.confirmationForm.active.on').toString()
      },
      {
        key: 'inactive',
        label: this.$t('availabilitySharing.form.confirmationForm.active.off').toString()
      }
    ]
    this.requiredOptions = [
      {
        key: 'required',
        label: this.$t('availabilitySharing.form.confirmationForm.required.on').toString()
      },
      {
        key: 'optional',
        label: this.$t('availabilitySharing.form.confirmationForm.required.off').toString()
      }
    ]
  }
  get icon() {
    return this.propertySynced.icon
  }
  get description() {
    return this.propertySynced.description
  }
  get tooltip() {
    return this.propertySynced.tooltip
  }
  get activeValue() {
    return this.propertySynced.isActive ? 'active' : 'inactive'
  }
  get requiredValue() {
    return this.isRequired ? 'required' : 'optional'
  }
  updateActive(newValue: Active) {
    if (newValue === 'active') {
      this.propertySynced.isActive = true
      this.$emit('updated')
    } else {
      this.propertySynced.isActive = false
      this.$emit('toggleRequire', false)
    }
  }
  updateRequired(newValue: Required) {
    if (newValue === 'required') {
      this.$emit('toggleRequire', true)
    } else {
      this.$emit('toggleRequire', false)
    }
  }
}
