















import { Component, Prop, Vue } from 'vue-property-decorator'
import { isSameDay } from 'date-fns'
import ConfirmWithEmailAndName from './molecules/ConfirmWithEmailAndName.vue'
import UserModule from '@/store/modules/user'
import oneDateRange from '@/filters/oneDateRange'
import dateRange from '@/filters/dateRange'
import { ConfirmData } from './ConfirmSchedule.vue'

@Component({
  components: {
    ConfirmWithEmailAndName
  }
})
export default class ConfirmSchedule extends Vue {
  @Prop() candidateStartDate: Date
  @Prop() candidateEndDate: Date
  @Prop() isOrganizer: boolean
  @Prop() timeZone: string
  @Prop({ default: false }) isVote: boolean

  confirmData: ConfirmData = {
    email: '',
    name: '',
    attendees: []
  }
  get formattedDate() {
    if (this.candidateStartDate && this.candidateEndDate) {
      if (isSameDay(this.candidateStartDate, this.candidateEndDate)) {
        return oneDateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
      } else {
        return dateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
      }
    }

    return null
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get subTitle() {
    if (this.isSignIn) {
      return this.$t('message.confirm.desc').toString()
    }
    return this.$t('message.confirm.notSignIn').toString()
  }
  get getTimeZone() {
    return this.timeZone
  }
  register() {
    this.$emit('register', {
      email: this.confirmData.email,
      name: this.confirmData.name
    })
    this.$emit('close')
  }
}
