
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ListType } from '@/types'
import { copyClipboard } from '@/lib/utils'
import { SignalType } from '@/lib/analytics/LogEntry'
import { EventBus, EVENTS } from '@/lib/eventBus'
import AppModule from '@/store/modules/app'
import { ScheduleModel } from '@/models/data'
import ScheduleCardBodyForPrivate from '@/components/card/ScheduleCardBodyForPrivate.vue'
import PollCardBodyForPrivate from '@/components/card/PollCardBodyForPrivate.vue'
import OnWaitingOperationBox from '@/components/card/molecules/OnWaitingOperationBox.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import ShareTextModal from '@/components/modal/shareText/ShareTextModal.vue'

@Component({
  components: {
    ScheduleCardBodyForPrivate,
    PollCardBodyForPrivate,
    OnWaitingOperationBox
  }
})
export default class PrivateUnconfirmedScheduleItem extends Vue {
  @Prop() item: ScheduleModel

  get isSchedule() {
    return (schedule: ScheduleModel) => {
      if (schedule.type === ListType.SCHEDULE) {
        return true
      }
      return false
    }
  }
  isSuggestedItem(item: ScheduleModel) {
    return item.status === 'suggestedByConfirmer'
  }
  goToConfirmPage(item: ScheduleModel) {
    AppModule.closeQuickView()
    this.showConfirmationModal(item)
  }
  async showItem({ item }) {
    await this.$analytics.send(SignalType.GO_FROM_LIST, { to: item.type })
    AppModule.closeQuickView()
    EventBus.emit(EVENTS.EDIT_SCHEDULE, { scheduleId: item.id, type: item.type })
  }
  isExpiredItem(item: ScheduleModel) {
    return item.status === 'expired'
  }
  cardType(item: ScheduleModel) {
    return item.type === ListType.SCHEDULE ? ScheduleCardBodyForPrivate : PollCardBodyForPrivate
  }
  showConfirmationModal(item) {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        header: this.$t('alternativeCandidates.confirmationModal.title').toString(),
        body: this.$t('alternativeCandidates.confirmationModal.body').toString(),
        confirmBtn: this.$t('buttons.showConfirmation').toString()
      },
      events: {
        confirm: () => {
          const isInTargetConfirmPage = this.$route.name === 'ConfirmSchedule' && this.$route.params.id === item.id
          if (!isInTargetConfirmPage) {
            const openPage = {
              name: 'ConfirmSchedule',
              params: { id: item.id }
            }
            const routeData = this.$router.resolve(openPage)
            window.open(routeData.href, '_blank')
          }
        }
      }
    })
  }
  handleUrlCopy(schedule: ScheduleModel) {
    if (this.isSchedule(schedule)) {
      this.openShareModal(schedule)
    } else {
      this.copyUrl(schedule)
    }
  }
  async copyUrl(schedule: ScheduleModel) {
    await this.$analytics.send(SignalType.CLICK_URL_SHARE_ON_CANDIDATE_MODAL, { type: schedule.type })
    copyClipboard(schedule.urlForConfirmer)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }
  async openShareModal(schedule: ScheduleModel) {
    await this.$analytics.send(SignalType.CLICK_HTML_SHARE_ON_CANDIDATE_MODAL, { type: schedule.type })
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ShareTextModal,
      hasModalCard: true,
      canCancel: true,
      props: { schedule },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
}
